import React from "react";
import { Link, NavLink } from "react-router-dom";
import './header.css'
import Cookie from 'universal-cookie';

const Header = () => {
    const cookie = new Cookie();

    let aux = cookie.get('usuarioR');
    const actualUrl = window.location.href;
    //console.log(actualUrl);

    const cerrarSesion = (ruta) => {

        if (ruta === '#/') {
            cookie.remove('usuarioR', { path: "/" });
        }
        //window.location.href = ruta;
        var anchor = document.createElement('a');
        anchor.href = "#/";
        anchor.click();
    }

    return (
        <>
            {/*
            <header className="container-fluid bg-primary d-flex justify-content-center">
                <p className='text-light mb-0 p-2'>Contactanos 1-(305)-725-1000</p>
            </header>
            */}

            <nav
                className="navbar navbar-expand-lg bg-light justify-content-between"
                id="menu"
            >
                <div className="container-fluid">
                    <NavLink to={'/'} className={({ isActive }) => isActive ? 'active' : ''}>
                        <a href="#">
                            <img src="imgs/logo.svg" alt="#" className="img-fluid" width={100} />
                        </a>

                    </NavLink>
                    {
                        //<a className="navbar-brand" href="#">GEOMarketing</a>
                    }
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse justify-content-end"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                            <NavLink to={"/about"} className={({ isActive }) => isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" aria-current="page">
                                        Acerca de
                                    </a>
                                </li>
                            </NavLink>

                            <NavLink to={"/clientes"} className={({ isActive }) => isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" href="#">
                                        Soluciones
                                    </a>
                                </li>
                            </NavLink>


                            <NavLink to={"/contacto"} className={({ isActive }) => isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" href="#">
                                        Contacto
                                    </a>
                                </li>
                            </NavLink>
                            <NavLink to={"/Precios"} className={({ isActive }) => isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" href="#">
                                        Ver precios
                                    </a>
                                </li>
                            </NavLink>
                            <NavLink to={"/Registro"} className={({ isActive }) => isActive ? 'active' : ''}>
                                <li className="nav-item">
                                    <a className="nav-link itemH" href="#">
                                        Registro
                                    </a>
                                </li>
                            </NavLink>
                            {
                                aux !== undefined && (
                                    <NavLink to={"/mapa"} className={({ isActive }) => isActive ? 'active' : ''}>
                                        <li className="nav-item">
                                            <a className="nav-link itemH" href="#">
                                                Geomarketing
                                            </a>
                                        </li>
                                    </NavLink>
                                )
                            }

                            {
                                aux !== undefined ? (

                                    <div id='link' className={({ isActive }) => isActive ? 'active2' : ''} onClick={() => cerrarSesion('#/')}>

                                        {
                                            aux !== undefined ? (
                                                <img src={aux.avatar} alt="User" className="img-fluid userI" />
                                            ) : (
                                                <img src="imgs/personas.png" alt="User" className=" img-fluid userI" />
                                            )
                                        }


                                    </div>
                                ) : (
                                    <NavLink to={"/login"} id='link' className={({ isActive }) => isActive ? 'active2' : ''}>
                                        <div>
                                            <a href="#">
                                                {
                                                    aux !== undefined ? (
                                                        <img src={aux.avatar} alt="User" className=" img-fluid userI" />
                                                    ) : (
                                                        <>
                                                            <div>
                                                                <img src="imgs/personas.png" alt="User" className=" img-fluid userI" />
                                                            </div>
                                                        </>

                                                    )
                                                }

                                            </a>
                                        </div>
                                    </NavLink>


                                )
                            }







                        </ul>
                    </div>
                </div>
            </nav>
            {
                aux !== undefined && (
                    <div className="row align-items-end">
                        <div className="col-md-12 align-self-end usuario">
                            Usuario: <strong>{aux.nombre}</strong>
                        </div>
                    </div>

                )
            }

        </>
    );
};

export default Header;
