import React, { useEffect,useState} from 'react'
import Cookie from 'universal-cookie';
import axios from 'axios'
import Swal from 'sweetalert2'
import $ from "jquery";


const cookie = new Cookie();


const Conformacion = () => {
   

    useEffect(() => {
        registrarCuenta();
    }, []);

    const registrarCuenta = async () => {
        console.log("Se registra el pago y el usuario")


        let aux = cookie.get('usuario')
        aux.pagado = 1;
        aux.cuendis = 1;
        aux.plan = "Estandar"

        let msj = await axios.post('http://localhost:4000/api/usuarios/guardar', aux)
        console.log(msj.status);
        if (msj.status === 200) {
            /*
            let userR = {};
            userR.nombre = aux.paterno + " " + aux.materno + " " + aux.nombre;
            userR.avatar = aux.avatar
            cookie.set('usuarioR', userR, { path: "/" });
            */
            //cookie.set('usuario', aux, { path: "/" });
            //se registro correctamente el usuario
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Usuario Registrado correctamente',
                showConfirmButton: false,
                timer: 1500
            })
            setTimeout(() => {
                console.log("1 Segundo esperado")
                var anchor = document.createElement('a');
                anchor.href = "#/login";
                anchor.click();
            }, 1600);
        } else {
            //el usuario no se registro correctamente
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Error en el registro',
                showConfirmButton: false,
                timer: 1500
            })
        }


    }


    return (
        <div>Confirmacion</div>
    )
}

export default Conformacion