import React, { useEffect, useState } from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    PieChart,
    Pie,
    Cell,
    Legend,
    ResponsiveContainer,
    ScatterChart,
    Label,
    ZAxis,
    Scatter,
    ComposedChart,
    Line,
    Sector
} from "recharts";
import Loading from "../Loader/Loader";

const Graficos2 = ({ tipo, d, variable, nombre, opcion, consumo, name, aux, aux2 }) => {
    //console.log(aux2);
    //console.log(d);

    const [colores, setColores] = useState([]);
    const [loadingg, setLoadingg] = useState(false);

    let header = Object.keys(d[0]);
    //console.log(header)
    const c2 = ["#2e1f54", "#52057f", "#bf033b", "#598c14", "#f00a36", "#b0a696", "#ed3b21", "#0eb24e", "#ff6908", "#ffc719", "#335238", "#4a8594", "#051736", "#000000", "#f38020", "#bb1e10"]

    useEffect(() => {
        let c1 = getRandomArbitrary(0, 6);
        setColores(c2);
        cambiaEstadoLoading();
    }, [aux]);

    useEffect(() => {
        let c1 = getRandomArbitrary(0, 6);
        setColores(c2);
        cambiaEstadoLoading();
    }, [aux2]);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };


    const getRandomArbitrary = (min, max) => {
        return Math.floor(Math.random() * (max - min)) + min;
    };

    const cambiaEstadoLoading = () => {
        setLoadingg(true);
        setTimeout(() => {
            setLoadingg(false);
        }, 1000);
    };

    if (loadingg) {
        return <Loading></Loading>
    } else {
        return (
            <>
                {
                    tipo === "barras" ? (
                        <div className="ContenedorGrafica">
                            <ResponsiveContainer width="100%" aspect={2 / 1}>
                                <BarChart

                                    width={650}
                                    height={400}
                                    data={d}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid stroke="#f5f5f5" />
                                    <XAxis dataKey={variable} />
                                    <YAxis />
                                    <Tooltip />
                                    {
                                        header.map((item, index) => {

                                            if (item !== variable) {
                                                return (
                                                    <Bar dataKey={`${item}`} fill={`${c2[index]}`} />
                                                );
                                            }
                                        })}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    ) : (
                        tipo === "pie" ? (
                            <>
                                <ResponsiveContainer width="100%" aspect={2 / 1}>
                                    <PieChart width={400} height={400}>
                                        <Pie
                                            data={d}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >

                                            {d.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colores[index % colores.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>

                            </>
                        ) : (
                            tipo === "compuesta" && (
                                <>
                                    <ResponsiveContainer width="100%" aspect={2 / 1}>
                                        <ComposedChart
                                            width={500}
                                            height={400}
                                            data={d}
                                            margin={{
                                                top: 20,
                                                right: 20,
                                                bottom: 20,
                                                left: 20,
                                            }}
                                        >
                                            <CartesianGrid stroke="#f5f5f5" />
                                            <XAxis dataKey={header[0]} scale="band" />
                                            <YAxis />
                                            <Tooltip />
                                            {
                                                //<Legend />
                                                //<Bar dataKey={header[2]} barSize={20} fill="#257b1c" />
                                                //<Line type="monotone" dataKey={header[4]} stroke="#7b1c5d" />
                                                //<Bar dataKey={header[1]} barSize={20} fill="#413ea0" />
                                            }

                                            {
                                                header !== undefined && (
                                                    header.map((item, index) => {
                                                        
                                                        if ((index === 1 || index === 2) && index!==0) {
                                                            return (
                                                                <Bar dataKey={item} barSize={20} fill={colores[index]} />
                                                            )

                                                        } else {
                                                            if (index > 2) {
                                                                //console.log(index+ " " +colores[index])
                                                                return (
                                                                    <Line type="monotone" dataKey={item} stroke={colores[index]} />
                                                                )

                                                            }
                                                        }
                                                    })

                                                )
                                            }



                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </>
                            )
                        )
                    )
                }
            </>


        )
    }

}

export default Graficos2