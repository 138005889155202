import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import About from './pages/About/About';
import Clientes from './pages/Clientes/Clientes';
import Mapa from './pages/Mapa/Mapa'
import Visualizador from './pages/Visualizador/Visualizador'
import Contacto from './pages/Contacto/Contacto';
import Graficas from './pages/Graficas/Graficas'
import Trabajo from './pages/Ntrabajo/Trabajo';
import Pago from './pages/Pago/Pago';
import PlanEstandar from './pages/Pago/PlanEstandar';
import PlanAvanzado from './pages/Pago/PlanAvanzado';
import PlanPlus from './pages/Pago/PlanPlus';
import Confirmacion from './pages/Pago/ConfirmacionEstandar'
import Forgot from './pages/Password/Forgot'
import Registro from './pages/Registro/Registro'
import Reportes from './pages/Reportes/Reportes'
import Precios from './pages/Precios/Precios'

import 'bootstrap/dist/css/bootstrap.min.css';
import './css/leaflet-sidebar.css'
import './css/estilosG.scss';
import Modelo from './pages/Modelo/Modelo';

//"homepage": "https://geomark.com.mx/",
/*
function App() {
  const [params, setParams] = useState(null);
  const location = useLocation();
  //const history = useHistory();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const singleValue = queryParams.get('opcion');
    setParams(singleValue);
    //if (!singleValue) //console.log("")
    //setParams(singleValue);
  }, []);

  return (
    <div className="principal">
      <div className="header" >
        <div className="row">
          <div className="col-3 img-fluid">
            <img src="images/LogoConagua.svg" width="210px" height="65px"></img>
          </div>
          <div className="col-7">
          </div>
          <div className="col-2">
            <a href="https://sinav30.conagua.gob.mx:8080/">
              <img src="images/sina.png" height="54px" width="230px"></img>
            </a>
          </div>
        </div>
      </div>
      <MapView opcion={params}></MapView>
    </div>
  );
}



  
          
          
          <Route path={`/Reportes`}>
            <Route index element={<Reportes></Reportes>}></Route>
          </Route>
          
         
         
          
          
         
          
          
          

*/


function App() {
  return (
    <div className="container-fluid">
      <Routes>
        <Route path='/'>
          <Route index element={<Home></Home>}></Route>
        </Route>
        <Route path={`/login`}>
          <Route index element={<Login></Login>} />
        </Route>
        <Route path={`/about`}>
            <Route index element={<About></About>} />
          </Route>
          <Route path={`/clientes`}>
            <Route index element={<Clientes></Clientes>} />
          </Route>
          <Route path={`/mapa`}>
            <Route index element={<Mapa></Mapa>}></Route>
          </Route>
          <Route path={`/Visualizador`}>
            <Route index element={<Visualizador></Visualizador>}></Route>
          </Route>
          <Route path={`/contacto`}>
            <Route index element={<Contacto></Contacto>} />
          </Route>
          <Route path={`/Graficas`}>
            <Route index element={<Graficas></Graficas>}></Route>
          </Route>
          <Route path={`/ntrabajo`}>
            <Route index element={<Trabajo></Trabajo>} />
          </Route>
          <Route path={`/Pago`}>
            <Route index element={<Pago></Pago>}></Route>
          </Route>
          <Route path={`/PEstandar`}>
            <Route index element={<PlanEstandar></PlanEstandar>}></Route>
          </Route>
          <Route path={`/PAvanzado`}>
            <Route index element={<PlanAvanzado></PlanAvanzado>}></Route>
          </Route>
          <Route path={`/PPlus`}>
            <Route index element={<PlanPlus></PlanPlus>}></Route>
          </Route>
          <Route path={`/Confirmacion`}>
            <Route index element={<Confirmacion></Confirmacion>}></Route>
          </Route>
          <Route path={`/Forgot`}>
            <Route index element={<Forgot></Forgot>}></Route>
          </Route>
          <Route path={`/Registro`}>
            <Route index element={<Registro></Registro>}></Route>
          </Route>
          <Route path={`/Reportes`}>
            <Route index element={<Reportes></Reportes>}></Route>
          </Route>
          <Route path={`/Precios`}>
            <Route index element={<Precios></Precios>}></Route>
          </Route>
          <Route path={`/Modelo`}>
            <Route index element={<Modelo></Modelo>}></Route>
          </Route>



      </Routes>
    </div>
  );
}
export default App;
