
import React from 'react'
import './login.css'
import Header from "../../componentes/Header";
import { Link, NavLink } from "react-router-dom";
import { useState } from 'react';
import md5 from 'md5';
import Cookie from 'universal-cookie';
import axios from 'axios'
import Constantes from '../../Constantes';

const baseurl = 'http://localhost:8080/usuarios/login/';

const Login = () => {
  const cookie = new Cookie();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [mensaje, setMensaje] = useState("");


  const handleChange = (e) => {
    if (e !== null) {
      if (e.target.name === "email") {
        setUsername(e.target.value);
      } else {
        //setPassword(e.target.value);
        setPassword(md5(e.target.value));
      }
    }
  }


  const iniciarSesion = async e => {
    //console.log("Email " + username);
    //console.log("password " + password);

    if (username === "Comecyt" && password === "1694ddc25742802b07747481d6b72b61") {
      let usAux = {}
      usAux.nombre = "COMECYT";
      usAux.avatar = "https://ui-avatars.com/api/?name=Comecyt&background=0D8ABC&color=fff&size=128";
      usAux.email = "comecyt@gmail.com"
      cookie.set('usuarioR', usAux, { path: "/" });

      var anchor = document.createElement('a');
      anchor.href = "#/mapa";
      anchor.click();

    } else {
      if (username === "jlopezf" && password === "13349278cc5d742777ba877211570ba8") {
        let usAux = {}
        usAux.nombre = "Josue Aldair Lopez Flores";
        usAux.avatar = "https://ui-avatars.com/api/?name=Josue+Lopez&background=0D8ABC&color=fff&size=128";
        cookie.set('usuarioR', usAux, { path: "/" });

        var anchor = document.createElement('a');
        anchor.href = "#/mapa";
        anchor.click();

      } else {

        const respuesta = await fetch(`${Constantes.RUTA_API}/obtenerUsuario.php?username=${username}&paswd=${password}`);
        const usuarioFinal = await respuesta.json();
        //console.log(usuarioFinal)
        if (usuarioFinal !== false) {
          let userR = {};
          userR.nombre = usuarioFinal.paterno + " " + usuarioFinal.materno + " " + usuarioFinal.nombre;
          userR.avatar = usuarioFinal.avatar
          userR.email = usuarioFinal.email
          //console.log(userR)
          cookie.set('usuarioR', userR, { path: "/" });

          var anchor = document.createElement('a');
          anchor.href = "#/mapa";
          anchor.click();

        }

      }

    }

  }

  return (
    <div className="container-fluid">
      <Header></Header>
      <br />
      <section className="vh-99">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-md-9 col-lg-6 col-xl-5">
              <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                className="img-fluid" alt="Sample image" />
            </div>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form>

                <div className="form-outline mb-4">
                  <input type="email" name="email" id="form3Example3" className="form-control form-control-lg"
                    placeholder="Ingresa un email valido" onChange={handleChange} />
                  <label className="form-label" for="form3Example3">Correo electrónico</label>
                </div>


                <div className="form-outline mb-3">
                  <input type="password" name="password" id="form3Example4" className="form-control form-control-lg"
                    placeholder="Ingresa password" onChange={handleChange} />
                  <label className="form-label" for="form3Example4">Password</label>
                </div>

                <div className="d-flex justify-content-between align-items-center">


                  <Link to={'/Forgot'}>
                    <a href="#!" className="text-body">Olvide mi contraseña</a>
                  </Link>
                  {
                    /*
                    <div className="form-check mb-0">
                    <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                    <label className="form-check-label" for="form2Example3">
                      Recuerdame
                    </label>
                  </div>
 <Link to={'/mapa'}>
                    <a href="#!" className="text-body">Visualizador</a>
                  </Link>
                    */
                  }


                </div>

                <div className="text-center text-lg-start mt-4 pt-2">
                  <button type="button" className="btn btn-primary btn-lg estilo" onClick={iniciarSesion}>Ingresar</button>
                  <p className="small fw-bold mt-2 pt-1 mb-0">¿No tienes una cuenta?
                    <Link to={'/Registro'}>
                      <a href="#!"
                        className="link-danger"> Registro</a>
                    </Link>
                  </p>

                </div>

              </form>
            </div>
          </div>
        </div >
        {
          /*
          <div
          className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 bg-primary">
        
          <div className="text-white mb-3 mb-md-0">
            Copyright © 2020. All rights reserved.
          </div>
        
        
        
          <div>
            <a href="#!" className="text-white me-4">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#!" className="text-white me-4">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#!" className="text-white me-4">
              <i className="fab fa-google"></i>
            </a>
            <a href="#!" className="text-white">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        
        </div>
        
          */

        }

      </section >
    </div >
  )
}

export default Login