import { ConnectingAirportsOutlined } from "@mui/icons-material";

class Precipitacion {

    agruparMes(datos) {
        const header = Object.keys(datos[0]);
        //console.log(header);
        //console.log(datos);

        let mes = [];

        for (let aux = 2; aux < header.length; aux++) {
            let reg = {}
           
            for (let index = 0; index < datos.length; index++) {
                reg.mes = header[aux];
                reg[datos[index][header[1]]]=datos[index][header[2]]
            }
            mes.push(reg);

        }
        //console.log(mes);
        return mes;
    }


    getLimpiar(data){
        //console.log(data)
        let aux=[]
        data.map(item=>{
            let op={}
            op.anio=item.anio;
            op.anual=item.anual;
            aux.push(op);
        })
        //console.log(aux);
        return aux;

    }

   

}

export default Precipitacion;