export const aliasTablas = [
    {
        capa: "m01_gradopresion",
        alias: [
            { field: 'id', headerName: '#', width: 50 },
            { field: 'claverha', headerName: 'Clave RHA', width: 150 },
            { field: 'nomrha', headerName: 'RHA', width: 150 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'voltotalaguconc', headerName: 'Volumen total', width: 100 },
            { field: 'aguarenovmedia', headerName: 'Agua Media', width: 100 },
            { field: 'agradopresion', headerName: 'Grado de Presión', width: 100 },
            { field: 'grado', headerName: 'Grado', width: 100 }
        ]
    },
    {
        capa: "m00_estados",
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: "m00_acuiferos",
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'fechadof', headerName: 'Fecha_dof', width: 100 },
            { field: 'disponibilidad', headerName: 'Disponibilidad', width: 100 },
            { field: 'dmanegativa', headerName: 'Dmanera_negativa', width: 150 },
            { field: 'situacion', headerName: 'Situacion', window: 200 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm00_consejocuencas',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'fechainst', headerName: 'Fecha_inst', width: 100 },
            { field: 'inicioper', headerName: 'Inicio_per', width: 100 },
            { field: 'finper', headerName: 'Fin_per', width: 150 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm00_conurbaciones',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 100 },
            { field: 'localidad', headerName: 'Localidad', width: 200 },
            { field: 'municipio', headerName: 'Municipio', width: 100 },
            { field: 'ambito', headerName: 'Ambito', width: 150 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'zonametropolitana', headerName: 'Zona_Metropolitana', width: 200 }]
    },
    {
        capa: 'm00_cuencas',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clvcuenca', headerName: 'Clave de la cuenca', width: 150 },
            { field: 'cuenca', headerName: 'Cuenca', width: 300 },
            { field: 'area', headerName: 'Área (km²)', width: 200 },
            { field: 'subregion', headerName: 'Subregión', width: 200 },
            { field: 'claverh', headerName: 'Clave región hidrológica', width: 200 },
            { field: 'nombrerh', headerName: 'Región hidrológica', width: 200 },
            { field: 'claverha', headerName: 'Clave RHA', width: 200 },
            { field: 'nombrerha', headerName: 'RHA', width: 200 }]

    },
    {
        capa: 'm00_cuerpos_FS',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nombre', headerName: 'Nombre', width: 200 }]
    },
    {
        capa: 'm00_cuerposaguaY',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'fc', headerName: 'Fc', width: 150 },
            { field: 'condicion', headerName: 'Condicion', width: 200 }]
    },
    {
        capa: 'm00_distritosriego',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 200 }]
    },
    {
        capa: 'm00_lagosprincipales',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nombre', headerName: 'Nombre', width: 200 }]
    },
    {
        capa: 'm00_localidadespoligonales',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'cveentidad', headerName: 'Cve_Entidad', width: 150 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'clavemun', headerName: 'Clave_mun', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 100 },
            { field: 'claveloc', headerName: 'Clave_loc', width: 150 },
            { field: 'localidad', headerName: 'Localidad', width: 100 },
            { field: 'ambito', headerName: 'Ambito', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'clvgeo', headerName: 'Clave_geo', width: 100 }]
    },
    {
        capa: 'm00_municipios',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'idm', headerName: 'Cve_Entidad', width: 150 },
            { field: 'cvemunicipal', headerName: 'Clave_municipal', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'cveentidad', headerName: 'Cve_Entidad', width: 100 },
            { field: 'entidad', headerName: 'Entidad', width: 100 },
            { field: 'clvgeo', headerName: 'Clave_geo', width: 100 }]
    },
    {
        capa: 'm00_nucleosagrarios',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nombre', headerName: 'Nombre', width: 400 },
            { field: 'folmatriz', headerName: 'Folmatriz', width: 200 },
            { field: 'tipo', headerName: 'Tipo', width: 250 },
            { field: 'programa', headerName: 'Programa', width: 250 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm00_nucleosagrariosY',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nombre_nuc', headerName: 'Nombre_nuc', width: 400 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'clave_unic', headerName: 'Clave_unic', width: 100 },
            { field: 'fol_matriz', headerName: 'Fol_matriz', width: 100 },
            { field: 'tipo', headerName: 'Tipo', width: 150 },
            { field: 'clave', headerName: 'Clave', width: 150 },
            { field: 'clave_esta', headerName: 'Clave_estado', width: 150 },
            { field: 'clave_muni', headerName: 'Clave_municipo', width: 150 },
            { field: 'clave_nucl', headerName: 'Clave_nucl', width: 150 },
            { field: 'programa', headerName: 'Programa', width: 100 }]
    },
    {

        capa: 'm00_rh',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nombre', headerName: 'Nombre', width: 350 },
            { field: 'exten', headerName: 'Exten', width: 150 },
            { field: 'precn8110', headerName: 'Precn8110', width: 150 },
            { field: 'enmstotal', headerName: 'Enmstotal', width: 150 },
            { field: 'importexport', headerName: 'Importexport', width: 100 },
            { field: 'enmsinter', headerName: 'enmsinter', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm00_rha',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 250 },
            { field: 'nombre', headerName: 'Nombre', width: 350 },
            { field: 'nummunicipio', headerName: 'Nummunicipios', width: 150 },
            { field: 'sede', headerName: 'Sede', width: 150 },
            { field: 'exten', headerName: 'Exten', width: 250 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'zona', headerName: 'Zona', width: 100 }]
    },
    {
        capa: 'm00_riosprincipales',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nombre', headerName: 'Nombre', width: 400 },
            { field: 'vertiente', headerName: 'Vertiente', width: 200 },
            { field: 'escurrimiento', headerName: 'Escurrimiento', width: 150 },
            { field: 'orden', headerName: 'Orden', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm00_sistemacuencas',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nombre', headerName: 'Nombre', width: 400 },
            { field: 'rh', headerName: 'Rh', width: 300 },
            { field: 'tratado', headerName: 'Tratado', width: 150 },
            { field: 'frontera', headerName: 'Frontera', width: 100 },
            { field: 'exten', headerName: 'Exten', width: 100 }]
    },
    {
        capa: 'm00_unidadesplaneacion',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 250 },
            { field: 'nombre', headerName: 'Nombre', width: 400 }]
    },
    {
        capa: 'm01_aguasresiduales',//
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedo', headerName: 'Clave Estado', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 400 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 350 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 350 },
            { field: 'corterepda', headerName: 'Año', width: 100 },
            { field: 'Agropecuario', headerName: 'Agropecuario', width: 250 },
            { field: 'Agua Potable', headerName: 'Agua Potable', width: 150 },
            { field: 'Ecológico', headerName: 'Ecológico', width: 150 },
            { field: 'Hidroeléctricas', headerName: 'Hidroeléctricas', width: 150 },
            { field: 'Industrial Integrado', headerName: 'Industrial Integrado', width: 200 },
            { field: 'Termoeléctricas', headerName: 'Termoeléctricas', width: 250 }]
    },
    {
        capa: 'm01_aprovechamientosAR',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 150 },
            { field: 'nonmbremun', headerName: 'Municipio', width: 300 },
            { field: 'claveestado', headerName: 'Clave Estado', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 250 },
            { field: 'claverha', headerName: 'Clave RHA', width: 100 },
            { field: 'nomrha', headerName: 'RHA', width: 200 },
            { field: 'titulo', headerName: 'Titulo', width: 150 },
            { field: 'titular', headerName: 'Titular', width: 400 },
            { field: 'numapro', headerName: 'Aprovechamientos', width: 170 },
            { field: 'uso', headerName: 'Uso', width: 250 },
            { field: 'volanual', headerName: 'Volumen Anual', width: 170 },
            { field: 'corterepda', headerName: 'Año', width: 100 },
            { field: 'sciansector', headerName: 'Sector SCIAN', width: 280 },
            { field: 'sciansubrama', headerName: 'Subrama SCIAN', width: 280 }]
    },
    {

        capa: 'm01_aprovechamientosSB',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 150 },
            { field: 'nonmbremun', headerName: 'Municipio', width: 300 },
            { field: 'claveestado', headerName: 'Clave Estado', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 350 },
            { field: 'claverha', headerName: 'Clave RHA', width: 100 },
            { field: 'nomrha', headerName: 'RHA', width: 200 },
            { field: 'titulo', headerName: 'Titulo', width: 200 },
            { field: 'titular', headerName: 'Titular', width: 400 },
            { field: 'claveacuif', headerName: 'Clave acuifero', width: 150 },
            { field: 'nombreacuif', headerName: 'Acuifero', width: 200 },
            { field: 'numapro', headerName: 'Aprovechamientos', width: 170 },
            { field: 'uso1', headerName: 'Uso 1', width: 200 },
            { field: 'vol1', headerName: 'Volumen 1', width: 100 },
            { field: 'uso2', headerName: 'Uso 2', width: 200 },
            { field: 'vol2', headerName: 'Volumen 2', width: 100 },
            { field: 'uso3', headerName: 'Uso 3', width: 200 },
            { field: 'vol3', headerName: 'Volumen 3', width: 100 },
            { field: 'voltot', headerName: 'Volumen Total', width: 150 },
            { field: 'gastoma', headerName: 'Gasto Máximo', width: 200 },
            { field: 'corterepda', headerName: 'Año', width: 100 },
            { field: 'sciansector', headerName: 'Sector SCIAN', width: 300 },
            { field: 'sciansubrama', headerName: 'Subrama SCIAN', width: 300 },]
    },
    {
        capa: 'm01_aprovechamientosSP',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 150 },
            { field: 'nonmbremun', headerName: 'Municipio', width: 300 },
            { field: 'claveestado', headerName: 'Clave Estado', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 350 },
            { field: 'claverha', headerName: 'Clave RHA', width: 100 },
            { field: 'nomrha', headerName: 'RHA', width: 200 },
            { field: 'titulo', headerName: 'Titulo', width: 200 },
            { field: 'titular', headerName: 'Titular', width: 400 },
            { field: 'numapro', headerName: 'Aprovechamientos', width: 170 },
            { field: 'uso1', headerName: 'Uso 1', width: 200 },
            { field: 'vol1', headerName: 'Volumen 1', width: 100 },
            { field: 'uso2', headerName: 'Uso 2', width: 200 },
            { field: 'vol2', headerName: 'Volumen 2', width: 100 },
            { field: 'uso3', headerName: 'Uso 3', width: 200 },
            { field: 'vol3', headerName: 'Volumen 3', width: 100 },
            { field: 'voltot', headerName: 'Volumen Total', width: 150 },
            { field: 'gastoma', headerName: 'Gasto Máximo', width: 200 },
            { field: 'corterepda', headerName: 'Año', width: 100 },
            { field: 'sciansector', headerName: 'Sector SCIAN', width: 300 },
            { field: 'sciansubrama', headerName: 'Subrama SCIAN', width: 300 }]
    },
    {
        capa: 'm01_aprovechamientosZF',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 150 },
            { field: 'nonmbremun', headerName: 'Municipio', width: 400 },
            { field: 'claveestado', headerName: 'Clave Estado', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 250 },
            { field: 'claverha', headerName: 'Clave RHA', width: 100 },
            { field: 'nomrha', headerName: 'RHA', width: 200 },
            { field: 'titulo', headerName: 'Titulo', width: 200 },
            { field: 'titular', headerName: 'Titular', width: 400 },
            { field: 'numapro', headerName: 'Aprovechamientos', width: 170 },
            { field: 'uso1', headerName: 'Uso 1', width: 200 },
            { field: 'area', headerName: 'Area 1', width: 200 },
            { field: 'uso2', headerName: 'Uso 2', width: 200 },
            { field: 'area2', headerName: 'Area 2', width: 200 },
            { field: 'uso3', headerName: 'Uso 3', width: 200 },
            { field: 'area3', headerName: 'Area 3', width: 200 },
            { field: 'superftot', headerName: 'Superficie Total', width: 200 },
            { field: 'corterepda', headerName: 'Año', width: 110 },
            { field: 'sciansector', headerName: 'Sector SCIAN', width: 300 },
            { field: 'sciansubrama', headerName: 'Subrama SCIAN', width: 300 }]
    },
    {
        capa: 'm01_aprovsubt',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedo', headerName: 'Clave Estado', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 250 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 100 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 300 },
            { field: 'corterepda', headerName: 'Año', width: 300 },
            { field: 'Agropecuario', headerName: 'Agroprecuario', width: 250 },
            { field: 'Agua Potable', headerName: 'Agua Potable', width: 300 },
            { field: 'Ecológico', headerName: 'Ecológico', width: 500 },
            { field: 'Hidroeléctricas', headerName: 'Hidroeléctricas', width: 200 },
            { field: 'Industrial Integrado', headerName: 'Industrial Integrado', width: 100 },
            { field: 'Termoeléctricas', headerName: 'Termoeléctricas', width: 200 }]
    },
    {
        capa: 'm01_aprovsuper',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedo', headerName: 'Clave Estado', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 100 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 300 },
            { field: 'corterepda', headerName: 'Año', width: 300 },
            { field: 'Agropecuario', headerName: 'Agroprecuario', width: 250 },
            { field: 'Agua Potable', headerName: 'Agua Potable', width: 200 },
            { field: 'Ecológico', headerName: 'Ecológico', width: 200 },
            { field: 'Hidroeléctricas', headerName: 'Hidroeléctricas', width: 200 },
            { field: 'Industrial Integrado', headerName: 'Industrial Integrado', width: 100 },
            { field: 'Termoeléctricas', headerName: 'Termoeléctricas', width: 200 }]
    },
    {
        capa: 'm01_concesionesAR',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedo', headerName: 'Clave Estado', width: 110 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 120 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 200 },
            { field: 'corte', headerName: 'Año', width: 100 },
            { field: 'total', headerName: 'Concesiones', width: 110 },
            { field: 'voltot', headerName: 'Volumen (hm³)', width: 120 }]
    },
    {
        capa: 'm01_consesionesSB',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedo', headerName: 'Clave Estado', width: 120 },
            { field: 'estado', headerName: 'Estado', width: 300 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 120 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 300 },
            { field: 'corte', headerName: 'Año', width: 100 },
            { field: 'total', headerName: 'Concesiones', width: 150 },
            { field: 'voltot', headerName: 'Volumen (hm³)', width: 120 }]
    },
    {
        capa: 'm01_consesionesSP',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedo', headerName: 'Clave Estado', width: 120 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 120 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 300 },
            { field: 'corte', headerName: 'Año', width: 100 },
            { field: 'total', headerName: 'Concesiones', width: 150 },
            { field: 'voltot', headerName: 'Volumen (hm³)', width: 120 }]
    },
    {
        capa: 'm01_consesionesZF',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedo', headerName: 'Clave Estado', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 120 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 300 },
            { field: 'corte', headerName: 'Año', width: 100 },
            { field: 'total', headerName: 'Concesiones', width: 150 },
            { field: 'area', headerName: 'Área (m²)', width: 150 }]
    },
    {
        capa: 'm01_vwaprovaraguasnacionales_',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'titularan', headerName: 'Titularan', width: 200 },
            { field: 'volanualar', headerName: 'Volanualar', width: 200 },
            { field: 'uso', headerName: 'Uso', width: 350 },
            { field: 'registro', headerName: 'Registro', width: 350 },
            { field: 'vencimien', headerName: 'Vencimien', width: 150 },
            { field: 'usuario', headerName: 'Usuario', width: 200 },
            { field: 'sciansecto', headerName: 'Sciansecto', width: 400 },
            { field: 'subrama', headerName: 'Subrama', width: 200 },
            { field: 'dentronac', headerName: 'Dentronac', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'municipio', headerName: 'Municipio', width: 250 },
            { field: 'londecimal', headerName: 'londecimal', width: 250 },
            { field: 'latdecimal', headerName: 'Latdecimal', width: 250 },
            { field: 'tipo', headerName: 'Tipo', width: 300 },
            { field: 'coord_merc', headerName: 'Coord_merc', width: 200 },
            { field: 'locacion', headerName: 'Locacion', width: 200 },
            { field: 'tabla', headerName: 'Table', width: 200 },
            { field: 'busqueda', headerName: 'Busqueda', width: 300 },
            { field: 'nombre', headerName: 'Nombre', width: 200 }]
    },
    {
        capa: 'm01_vwaprovexmateriales_',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'folioem', headerName: 'Folioem', width: 150 },
            { field: 'titularem', headerName: 'Titularem', width: 200 },
            { field: 'supem', headerName: 'Supem', width: 200 },
            { field: 'volconem', headerName: 'Volconem', width: 250 },
            { field: 'usodesem', headerName: 'Usodesem', width: 250 },
            { field: 'vencimien', headerName: 'Vencimien', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'tipomatext', headerName: 'tipomatext', width: 250 },
            { field: 'londecimal', headerName: 'londecimal', width: 200 },
            { field: 'latdecimal', headerName: 'Latdecimal', width: 250 },
            { field: 'dentronac', headerName: 'Dentronac', width: 100 }]
    },
    {
        capa: 'm01_vwaprovsbaguasnacionales_',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'titularan', headerName: 'Titularan', width: 200 },
            { field: 'voltotal', headerName: 'Voltotal', width: 200 },
            { field: 'uso', headerName: 'Uso', width: 250 },
            { field: 'uso1', headerName: 'Uso_1', width: 250 },
            { field: 'uso2', headerName: 'Uso_2', width: 250 },
            { field: 'uso3', headerName: 'Uso_3', width: 250 },
            { field: 'registro', headerName: 'Registro', width: 250 },
            { field: 'vencimien', headerName: 'Vencimien', width: 150 },
            { field: 'usuario', headerName: 'Usuario', width: 200 },
            { field: 'sciansecto', headerName: 'Scisnsecto', width: 200 },
            { field: 'subrama', headerName: 'Subrama', width: 100 },
            { field: 'dentronac', headerName: 'Dentronac', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 250 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'londecimal', headerName: 'londecimal', width: 200 },
            { field: 'latdecimal', headerName: 'Latdecimal', width: 250 }]
    },
    {
        capa: 'm01_vwaprovsbdistritosderiego_',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'titulardr', headerName: 'Titularan', width: 200 },
            { field: 'supriegodr', headerName: 'Supriegodr', width: 200 },
            { field: 'volconsbdr', headerName: 'Volconsbdr', width: 200 },
            { field: 'uso', headerName: 'Uso', width: 350 },
            { field: 'feregisdr', headerName: 'Feregisdr', width: 250 },
            { field: 'vencimidr', headerName: 'Vencimidr', width: 250 },
            { field: 'estado', headerName: 'Estado', width: 350 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'londecimal', headerName: 'londecimal', width: 200 },
            { field: 'latdecimal', headerName: 'Latdecimal', width: 250 },
            { field: 'dentronac', headerName: 'Dentronac', width: 250 }]

    },

    {
        capa: 'm01_vwaprovzbzonaslibrealum_',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'titularzla', headerName: 'Titularzla', width: 200 },
            { field: 'volconszla', headerName: 'Volconszla', width: 200 },
            { field: 'uso1', headerName: 'Uso_1', width: 350 },
            { field: 'uso2', headerName: 'Uso_2', width: 350 },
            { field: 'uso3', headerName: 'Uso_3', width: 350 },
            { field: 'iniciovigz', headerName: 'Iniciovigz', width: 250 },
            { field: 'vencimien', headerName: 'Vencimien', width: 150 },
            { field: 'usuario', headerName: 'Usuario', width: 200 },
            { field: 'sciansecto', headerName: 'Scisnsecto', width: 200 },
            { field: 'subrama', headerName: 'Subrama', width: 100 },
            { field: 'dentronac', headerName: 'Dentronac', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 350 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'londecimal', headerName: 'londecimal', width: 200 },
            { field: 'latdecimal', headerName: 'Latdecimal', width: 250 },
            { field: 'dentronac', headerName: 'Dentronac', width: 100 }]
    },
    {
        capa: 'm01_vwaprovspaguasnacionales_',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'titularan', headerName: 'Titularan', width: 400 },
            { field: 'voltotal', headerName: 'Voltotal', width: 200 },
            { field: 'uso', headerName: 'Uso', width: 250 },
            { field: 'uso1', headerName: 'Uso_1', width: 250 },
            { field: 'uso2', headerName: 'Uso_2', width: 250 },
            { field: 'uso3', headerName: 'Uso_3', width: 250 },
            { field: 'registro', headerName: 'Registro', width: 250 },
            { field: 'vencimien', headerName: 'Vencimien', width: 150 },
            { field: 'usuario', headerName: 'Usuario', width: 200 },
            { field: 'sciansecto', headerName: 'Scisnsecto', width: 300 },
            { field: 'subrama', headerName: 'Subrama', width: 100 },
            { field: 'dentronac', headerName: 'Dentronac', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 250 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'londecimal', headerName: 'londecimal', width: 200 },
            { field: 'latdecimal', headerName: 'Latdecimal', width: 250 }]
    },
    {
        capa: 'm01_vwaprovzfaguasnacionales_',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'titularan', headerName: 'Titularan', width: 400 },
            { field: 'areatotal', headerName: 'Areatotal', width: 200 },
            { field: 'uso1', headerName: 'Uso_1', width: 250 },
            { field: 'uso2', headerName: 'Uso_2', width: 250 },
            { field: 'uso3', headerName: 'Uso_3', width: 250 },
            { field: 'registro', headerName: 'Registro', width: 250 },
            { field: 'vencimien', headerName: 'Vencimien', width: 150 },
            { field: 'usuario', headerName: 'Usuario', width: 200 },
            { field: 'sciansecto', headerName: 'Scisnsecto', width: 200 },
            { field: 'subrama', headerName: 'Subrama', width: 100 },
            { field: 'dentronac', headerName: 'Dentronac', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 250 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'londecimal', headerName: 'londecimal', width: 200 },
            { field: 'latdecimal', headerName: 'Latdecimal', width: 250 }]
    },
    {
        capa: 'm01_zonasfederales',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedo', headerName: 'Clave Estado', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'clavemun', headerName: 'Clave Municipio', width: 100 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 300 },
            { field: 'corterepda', headerName: 'Año', width: 100 },
            { field: 'Agropecuario', headerName: 'Agropecuario', width: 100 },
            { field: 'Agua Potable', headerName: 'Agua Potable', width: 100 },
            { field: 'Ecológico', headerName: 'Ecológico', width: 100 },
            { field: 'Hidroeléctricas', headerName: 'Hidroeléctricas', width: 100 },
            { field: 'Industrial Integrado', headerName: 'Industrial Integrado', width: 150 },
            { field: 'Termoeléctricas', headerName: 'Termoeléctricas', width: 150 }]
    },
    {
        capa: 'm02_contaminantesbiologicos',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'cvemunicipio', headerName: 'Cvemunicipio', width: 150 },
            { field: 'cveentidad', headerName: 'Cveentidad', width: 100 },
            { field: 'entidad', headerName: 'Entidad', width: 100 },
            { field: 'claverha', headerName: 'Claverha', width: 200 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'subtipo', headerName: 'Subtipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'dbo_tot', headerName: 'Dbo_tot', width: 100 },
            { field: 'dbo_totl', headerName: 'Dbo_totl', width: 100 },
            { field: 'tox_d48_fon_ut', headerName: 'Tox_d48_fon_ut', width: 200 },
            { field: 'tox_d_48_fon_utl', headerName: 'Tox_d_48_fon_utl', width: 200 },
            { field: 'e_coli', headerName: 'E_coli', width: 100 },
            { field: 'e_colil', headerName: 'E_colil', width: 100 },
            { field: 'tox_fis_fon_15_ut', headerName: 'Tox_fis_fon_15_ut', width: 100 },
            { field: 'tox_fis_fon_15_utl', headerName: 'Tox_fis_fon_15_utl', width: 150 },
            { field: 'tox_v_15_ut', headerName: 'Tox_v_15_ut', width: 100 },
            { field: 'tox_v_15_utl', headerName: 'Tox_v_15_utl', width: 100 },
            { field: 'tox_d_48_sup_ut', headerName: 'Tox_d_48_sup_ut', width: 100 },
            { field: 'tox_d_48_sup_utl', headerName: 'Tox_d_48_sup_utl', width: 100 },
            { field: 'coli_fecsb', headerName: 'Coli_fecsb', width: 100 },
            { field: 'coli_fecsub1', headerName: 'coli_fecsub1', width: 100 },
            { field: 'tox_d_48_ut', headerName: 'Tox_d_48_ut', width: 100 },
            { field: 'tox_d_48_utl', headerName: 'Tox_d_48_utl', width: 100 },
            { field: 'tox_fis_sup_15_ut', headerName: 'Tox_fis_sup_15_ut', width: 100 },
            { field: 'tox_fis_sup_15_utl', headerName: 'Tox_fis_sup_15_utl', width: 150 },
            { field: 'enteroc_fec', headerName: 'Enteroc_fec', width: 100 },
            { field: 'enteroc_fec1', headerName: 'Enteroc_fec1', width: 100 },
            { field: 'dqo_tot', headerName: 'Dqo_tot', width: 100 },
            { field: 'dqo_tot1', headerName: 'Dqo_tot1', width: 200 },
            { field: 'coli_fecsu', headerName: 'Coli_fecsu', width: 100 },
            { field: 'coli_fecsup1', headerName: 'Coli_fecsup1', width: 500 }]
    },
    {
        capa: 'm02_contaminantesfisicos',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 300 },
            { field: 'cvemunicipal', headerName: 'Cvemunicipal', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 250 },
            { field: 'cveentidad', headerName: 'Cveentidad', width: 150 },
            { field: 'entidad', headerName: 'Entidad', width: 250 },
            { field: 'claverha', headerName: 'Claverha', width: 150 },
            { field: 'nombrerha', headerName: 'Nombrerha', width: 200 },
            { field: 'tipo', headerName: 'Tipo', width: 300 },
            { field: 'subtipo', headerName: 'Subtipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 350 },
            { field: 'sdt_agricola', headerName: 'Sdt_agricola', width: 100 },
            { field: 'sdt_agricola1', headerName: 'Sdt_agricola1', width: 100 },
            { field: 'dur_tot', headerName: 'Dur_tot', width: 150 },
            { field: 'dur_tot1', headerName: 'Dur_tot1', width: 150 },
            { field: 'sst', headerName: 'Sst', width: 150 },
            { field: 'sst1', headerName: 'Sst1', width: 250 },
            { field: 'conduc_cam', headerName: 'Conduc_cam', width: 150 },
            { field: 'conduc_cam1', headerName: 'Conduc_cam1', width: 150 }]
    },
    {
        capa: 'm02_contaminantesquimicos',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'cvemunicipio', headerName: 'Cvemunicipio', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'cveentidad', headerName: 'Cveentidad', width: 150 },
            { field: 'entidad', headerName: 'Entidad', width: 200 },
            { field: 'claverha', headerName: 'Claverha', width: 200 },
            { field: 'nombrerha', headerName: 'Nombrerha', width: 200 },
            { field: 'tipo', headerName: 'Tipo', width: 400 },
            { field: 'subtipo', headerName: 'Subtipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'cd_tot', headerName: 'Cd_tot', width: 100 },
            { field: 'cd_tot1', headerName: 'Cd_tot1', width: 100 },
            { field: 'mn_tot', headerName: 'Mn_tot', width: 200 },
            { field: 'mn_tot1', headerName: 'Mn_tot1', width: 300 },
            { field: 'od_porc_su', headerName: 'Od_porc_su', width: 100 },
            { field: 'od_porc_su1', headerName: 'Od_porc_su1', width: 150 },
            { field: 'sdt_m', headerName: 'Sdt_m', width: 100 },
            { field: 'sdt_m1', headerName: 'Sdt_m1', width: 150 },
            { field: 'od_porc_me', headerName: 'Od_porc_me', width: 100 },
            { field: 'od_porc_me1', headerName: 'Od_porc_me1', width: 100 },
            { field: 'pb_tot', headerName: 'Pb_tot', width: 100 },
            { field: 'pb_tot1', headerName: 'Pb_tot1', width: 100 },
            { field: 'n_no3', headerName: 'N_no3', width: 100 },
            { field: 'n_no31', headerName: 'N_no31', width: 100 },
            { field: 'fe_tot', headerName: 'Fe_tot', width: 100 },
            { field: 'fe_tot1', headerName: 'Fe_tot1', width: 100 },
            { field: 'hg_tot', headerName: 'Hg_tot', width: 100 },
            { field: 'hg_tot1', headerName: 'Hg_tot1', width: 150 },
            { field: 'as_tot', headerName: 'As_tot', width: 100 },
            { field: 'as_tot1', headerName: 'As_tot1', width: 100 },
            { field: 'cr_tot', headerName: 'Cr_tot', width: 100 },
            { field: 'cr_tot1', headerName: 'Cr_tot1', width: 200 },
            { field: 'od_porc', headerName: 'Od_porc', width: 100 },
            { field: 'od_porc1', headerName: 'Od_porc1', width: 500 },
            { field: 'alc_tot', headerName: 'Alc_tot', width: 100 },
            { field: 'alc_tot1', headerName: 'Alc_tot1', width: 100 },
            { field: 'od_porc_fo', headerName: 'Od_porc_fo', width: 100 },
            { field: 'od_porc_fo1', headerName: 'Od_porc_fo1', width: 200 },
            { field: 'fluoruros_tot', headerName: 'Fluoruros_tot', width: 100 },
            { field: 'fluoruros_tot1', headerName: 'Fluoruros_tot1', width: 200 }]
    },
    {
        capa: 'm02_costeros',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 150 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'subtipo', headerName: 'Subtipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'semaforo	', headerName: 'Semaforo', width: 100 },
            { field: 'color', headerName: 'Color', width: 100 },
            { field: 'tox_fis_fon_15_ut', headerName: 'Tox_fis_fon_15_ut', width: 200 },
            { field: 'tox_fis_sup_15_ut1', headerName: 'Tox_fis_sup_15_ut1', width: 100 },
            { field: 'enteroc_fec', headerName: 'Enteroc_fec', width: 100 },
            { field: 'enteroc_fec1', headerName: 'Enteroc_fec1', width: 100 },
            { field: 'coli_fecsu', headerName: 'Coli_fecsu', width: 100 },
            { field: 'coli_fecsu1', headerName: 'Coli_fecsu1', width: 100 },
            { field: 'sst', headerName: 'Sst', width: 100 },
            { field: 'sst1', headerName: 'Sst1', width: 200 },
            { field: 'od_porc_su', headerName: 'Od_porc_su', width: 100 },
            { field: 'od_porc_su1', headerName: 'Od_porc_su1', width: 300 },
            { field: 'od_porc_me', headerName: 'Od_porc_me', width: 100 },
            { field: 'od_porc_me1', headerName: 'Od_porc_me1', width: 250 },
            { field: 'od_porc_fo', headerName: 'Od_porc_fo', width: 100 },
            { field: 'od_porc_fol', headerName: 'Od_porc_fol', width: 300 }]
    },
    {
        capa: 'm02_costerosV',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 150 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'semaforo	', headerName: 'Semaforo', width: 100 },
            { field: 'color', headerName: 'Color', width: 100 },
            { field: 'coli_fec', headerName: 'Coli_fec', width: 100 },
            { field: 'coli_fecl', headerName: 'Coli_fec1', width: 100 },
            { field: 'enteroc_fec', headerName: 'Enteroc_fec', width: 100 },
            { field: 'enteroc_fec1', headerName: 'Enteroc_fec1', width: 100 },
            { field: 'OD_%_FON', headerName: 'OD_%_FON', width: 100 },
            { field: 'OD_%_FON1', headerName: 'OD_%_FON1', width: 100 },
            { field: 'OD_%_MED', headerName: 'OD_%_MED', width: 100 },
            { field: 'OD_%_MED1	', headerName: 'OD_%_MED1	', width: 100 },
            { field: 'OD_%_SUP', headerName: 'OD_%_SUP', width: 100 },
            { field: 'OD_%_SUP1', headerName: 'OD_%_SUP1', width: 100 },
            { field: 'sst', headerName: 'Sst', width: 100 },
            { field: 'sst1', headerName: 'Sst1', width: 200 },
            { field: 'tox_fis_sup_15_ut', headerName: 'Tox_fis_sup_15_ut', width: 100 },
            { field: 'tox_fis_sup_15_ut1', headerName: 'Tox_fis_sup_15_ut1', width: 300 },
            { field: 'tox_fis_fon_15_ut', headerName: 'Tox_fis_fon_15_ut', width: 100 },
            { field: 'tox_fis_fon_15_ut1', headerName: 'Tox_fis_fon_15_ut1', width: 250 }]
    },
    {
        capa: 'm02_lentico',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 150 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'subtipo', headerName: 'Subipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'semaforo	', headerName: 'Semaforo', width: 100 },
            { field: 'color', headerName: 'Color', width: 100 },
            { field: 'dbo_tot', headerName: 'Dbo_tot', width: 100 },
            { field: 'dbo_tot1', headerName: 'Dbo_tot1', width: 200 },
            { field: 'dqo_tot', headerName: 'Dqo_tot', width: 100 },
            { field: 'dqo_tot1', headerName: 'Dqo_tot1', width: 200 },
            { field: 'sst', headerName: 'Sst', width: 100 },
            { field: 'sst1', headerName: 'Sst1', width: 200 },
            { field: 'coli_fecsu', headerName: 'Coli_fecsu', width: 100 },
            { field: 'coli_fecsu1', headerName: 'Coli_fecsu1', width: 100 },
            { field: 'e_coli', headerName: 'E_coli', width: 100 },
            { field: 'e_coli1', headerName: 'E_coli1', width: 100 },
            { field: 'od_porc_su', headerName: 'Od_porc_su', width: 100 },
            { field: 'od_porc_su1', headerName: 'Od_porc_su1', width: 100 },
            { field: 'od_porc_me', headerName: 'Od_porc_me', width: 100 },
            { field: 'od_porc_me1', headerName: 'Od_porc_me1', width: 100 },
            { field: 'od_porc_fo', headerName: 'Od_porc_fo', width: 100 },
            { field: 'od_porc_fo1', headerName: 'Od_porc_fo1', width: 100 },
            { field: 'tox_d_48_sup_ut', headerName: 'Tox_d_48_sup_ut', width: 200 },
            { field: 'tox_d_48_sup_ut1', headerName: 'Tox_d_48_sup_ut1', width: 100 },
            { field: 'tox_d_48_fon_ut', headerName: 'Tox_d_48_fon_ut', width: 300 },
            { field: 'tox_fis_sup_15_ut', headerName: 'Tox_fis_sup_15_ut', width: 100 },
            { field: 'tox_fis_sup_15_ut1', headerName: 'Tox_fis_sup_15_ut1', width: 250 },
            { field: 'tox_fis_fon_15_ut', headerName: 'Tox_fis_fon_15_ut', width: 100 },
            { field: 'tox_fis_fon_15_ut1', headerName: 'Tox_fis_fon_15_ut1', width: 250 }]
    },
    {
        capa: 'm02_lenticosV',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 150 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'semaforo	', headerName: 'Semaforo', width: 100 },
            { field: 'color', headerName: 'Color', width: 100 },
            { field: 'coli_fec', headerName: 'Coli_fec', width: 100 },
            { field: 'coli_fec1', headerName: 'Coli_fec1', width: 100 },
            { field: 'dbo_tot', headerName: 'Dbo_tot', width: 100 },
            { field: 'dbo_tot1', headerName: 'Dbo_tot1', width: 200 },
            { field: 'dqo_tot', headerName: 'Dqo_tot', width: 100 },
            { field: 'dqo_tot1', headerName: 'Dqo_tot1', width: 200 },
            { field: 'e_coli', headerName: 'E_coli', width: 100 },
            { field: 'e_coli1', headerName: 'E_coli1', width: 100 },
            { field: 'OD_%_FON', headerName: 'OD_%_FON', width: 100 },
            { field: 'OD_%_FON1', headerName: 'OD_%_FON1', width: 100 },
            { field: 'OD_%_MED', headerName: 'OD_%_MED', width: 100 },
            { field: 'OD_%_MED1	', headerName: 'OD_%_MED1	', width: 100 },
            { field: 'OD_%_SUP', headerName: 'OD_%_SUP', width: 100 },
            { field: 'OD_%_SUP1', headerName: 'OD_%_SUP1', width: 100 },
            { field: 'sst', headerName: 'Sst', width: 100 },
            { field: 'sst1', headerName: 'Sst1', width: 100 },
            { field: 'tox_d_48_fon_ut', headerName: 'Tox_d_48_fon_ut', width: 200 },
            { field: 'tox_d_48_fon_ut1', headerName: 'Tox_d_48_fon_ut1', width: 100 },
            { field: 'tox_d_48_sup_ut', headerName: 'Tox_d_48_sup_ut', width: 200 },
            { field: 'tox_d_48_sup_ut1', headerName: 'Tox_d_48_sup_ut1', width: 100 },
            { field: 'tox_fis_fon_15_ut', headerName: 'Tox_fis_fon_15_ut', width: 300 },
            { field: 'tox_fis_fon_15_ut1', headerName: 'Tox_fis_fon_15_ut1', width: 250 },
            { field: 'tox_fis_sup_15_ut', headerName: 'Tox_fis_sup_15_ut', width: 100 },
            { field: 'tox_fis_sup_15_ut1', headerName: 'Tox_fis_sup_15_ut1', width: 250 }]
    },
    {
        capa: 'm02_loticos',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 150 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'subtipo', headerName: 'Subtipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'semaforo	', headerName: 'Semaforo', width: 100 },
            { field: 'color', headerName: 'Color', width: 100 },
            { field: 'dbo_tot', headerName: 'Dbo_tot', width: 100 },
            { field: 'dbo_tot1', headerName: 'Dbo_tot1', width: 200 },
            { field: 'dqo_tot', headerName: 'Dqo_tot', width: 100 },
            { field: 'dqo_tot1', headerName: 'Dqo_tot1', width: 200 },
            { field: 'sst', headerName: 'Sst', width: 100 },
            { field: 'sst1', headerName: 'Sst1', width: 100 },
            { field: 'coli_fecsu', headerName: 'Coli_fecsu', width: 100 },
            { field: 'coli_fecsu1', headerName: 'Coli_fecsu1', width: 100 },
            { field: 'e_coli', headerName: 'E_coli', width: 100 },
            { field: 'e_coli1', headerName: 'E_coli1', width: 100 },
            { field: 'od_porc', headerName: 'Od_porc', width: 100 },
            { field: 'od_porc1', headerName: 'Od_porc1', width: 100 },
            { field: 'tox_d_48_ut', headerName: 'Tox_d_48_ut', width: 200 },
            { field: 'tox_d_48_ut1', headerName: 'Tox_d_48_ut1', width: 100 },
            { field: 'tox_v_15_ut', headerName: 'Tox_v_15_ut', width: 200 },
            { field: 'tox_v_15_ut1', headerName: 'Tox_v_15_ut1', width: 100 }]
    },
    {
        capa: 'm02_loticosV',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 150 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'semaforo	', headerName: 'Semaforo', width: 100 },
            { field: 'color', headerName: 'Color', width: 100 },
            { field: 'coli_fec', headerName: 'Coli_fec', width: 100 },
            { field: 'coli_fec1', headerName: 'Coli_fec1', width: 100 },
            { field: 'e_coli', headerName: 'E_coli', width: 100 },
            { field: 'e_coli1', headerName: 'E_coli1', width: 100 },
            { field: 'dbo_tot', headerName: 'Dbo_tot', width: 100 },
            { field: 'dbo_tot1', headerName: 'Dbo_tot1', width: 200 },
            { field: 'dqo_tot', headerName: 'Dqo_tot', width: 100 },
            { field: 'dqo_tot1', headerName: 'Dqo_tot1', width: 200 },
            { field: 'tox_d_48_ut', headerName: 'Tox_d_48_ut', width: 200 },
            { field: 'tox_d_48_ut1', headerName: 'tox_d_48_ut1', width: 100 },
            { field: 'tox_v_15_ut', headerName: 'Tox_d_48_sup_ut', width: 200 },
            { field: 'tox_v_15_ut1', headerName: 'Tox_v_15_ut1', width: 100 },
            { field: 'OD_%', headerName: 'OD_%', width: 100 },
            { field: 'OD_%1', headerName: 'OD_%1', width: 100 },
            { field: 'sst', headerName: 'Sst', width: 100 },
            { field: 'sst1', headerName: 'Sst1', width: 100 }]
    },
    {
        capa: 'm02_subterraneos',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 350 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'semaforo	', headerName: 'Semaforo', width: 100 },
            { field: 'color', headerName: 'Color', width: 100 },
            { field: 'fluoruros_tot', headerName: 'Fluoruros_tot', width: 250 },
            { field: 'fluoruros_tot1', headerName: 'Fluoruros_tot1', width: 250 },
            { field: 'coli_fecsb', headerName: 'Coli_fecsb', width: 100 },
            { field: 'coli_fecsb1', headerName: 'Coli_fecsb1', width: 100 },
            { field: 'n_no3', headerName: 'N_no3', width: 100 },
            { field: 'n_no31', headerName: 'N_no31', width: 100 },
            { field: 'as_tot', headerName: 'As_tot', width: 100 },
            { field: 'as_tot1', headerName: 'As_tot1', width: 200 },
            { field: 'cd_tot', headerName: 'Cd_tot', width: 100 },
            { field: 'cd_tot1', headerName: 'Cd_tot1', width: 200 },
            { field: 'cr_tot', headerName: 'Cr_tot', width: 200 },
            { field: 'cr_tot1', headerName: 'Cr_tot1', width: 100 },
            { field: 'hg_tot', headerName: 'Hg_tot', width: 200 },
            { field: 'hg_tot1', headerName: 'Hg_tot1', width: 100 },
            { field: 'pb_tot', headerName: 'Pb_tot', width: 100 },
            { field: 'pb_tot1', headerName: 'Pb_tot1', width: 100 },
            { field: 'alc_tot', headerName: 'Alc_tot', width: 100 },
            { field: 'alc_tot1', headerName: 'Alc_tot1', width: 100 },
            { field: 'conduc_cam', headerName: 'Conduc_cam', width: 100 },
            { field: 'conduc_cam1', headerName: 'Conduc_cam1', width: 300 },
            { field: 'dur_tot', headerName: 'Dur_tot', width: 100 },
            { field: 'dur_tot1', headerName: 'Dur_tot1', width: 200 },
            { field: 'sdt_agricola', headerName: 'Sdt_agricola', width: 100 },
            { field: 'sdt_agricola1', headerName: 'Sdt_agricola1', width: 200 },
            { field: 'sdt_m', headerName: 'Sdt_m', width: 200 },
            { field: 'sdt_m1', headerName: 'Sdt_m1', width: 200 },
            { field: 'mn_tot', headerName: 'Mn_tot', width: 100 },
            { field: 'mn_tot1', headerName: 'Mn_tot1', width: 200 },
            { field: 'fe_tot', headerName: 'Fe_tot', width: 100 },
            { field: 'fe_tot1', headerName: 'Fe_tot1', width: 200 }]
    },
    {
        capa: 'm02_subterraneosV',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavesitio', headerName: 'Clavesitio', width: 150 },
            { field: 'nombre', headerName: 'Nombre', width: 350 },
            { field: 'tipo', headerName: 'Tipo', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'semaforo	', headerName: 'Semaforo', width: 100 },
            { field: 'color', headerName: 'Color', width: 100 },
            { field: 'alc_tot', headerName: 'Alc_tot', width: 250 },
            { field: 'alc_tot1', headerName: 'Alc_tot1', width: 250 },
            { field: 'as_tot', headerName: 'As_tot', width: 100 },
            { field: 'as_tot1', headerName: 'As_tot1', width: 100 },
            { field: 'cd_tot', headerName: 'Cd_tot', width: 200 },
            { field: 'cd_tot1', headerName: 'Cd_tot1', width: 200 },
            { field: 'coli_fec', headerName: 'Coli_fec', width: 100 },
            { field: 'coli_fec1', headerName: 'Coli_fec1', width: 200 },
            { field: 'conduc_campo', headerName: 'Conduc_campo', width: 100 },
            { field: 'conduc_campo1', headerName: 'Conduc_campo1', width: 100 },
            { field: 'cr_tot', headerName: 'Cr_tot', width: 100 },
            { field: 'cr_tot1', headerName: 'Cr_tot1', width: 100 },
            { field: 'dur_tot', headerName: 'Dur_tot', width: 100 },
            { field: 'dur_tot1', headerName: 'Dur_tot1', width: 100 },
            { field: 'fe_tot', headerName: 'Fe_tot', width: 300 },
            { field: 'fe_tot1', headerName: 'Fe_tot1', width: 100 },
            { field: 'fluoruros_tot', headerName: 'Fluoruros_tot', width: 200 },
            { field: 'fluoruros_tot1', headerName: 'Fluoruros_tot1', width: 100 },
            { field: 'hg_tot', headerName: 'Hg_tot', width: 200 },
            { field: 'hg_tot1', headerName: 'Hg_tot1', width: 200 },
            { field: 'n_no3', headerName: 'N_no3', width: 200 },
            { field: 'n_no31', headerName: 'N_no31', width: 300 },
            { field: 'pb_tot', headerName: 'Pb_tot', width: 200 },
            { field: 'pb_tot1', headerName: 'pb_tot1', width: 300 },
            { field: 'sdt_m', headerName: 'sdt_m', width: 60 },
            { field: 'sdt_m1', headerName: 'sdt_m1', width: 200 }]
    },
    {
        capa: 'm04_censo2000',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clavedo', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 500 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'pobtot', headerName: 'Pobtot', width: 200 },
            { field: 'pobmas', headerName: 'Pobmas', width: 250 },
            { field: 'pobfem', headerName: 'Pobfem', width: 200 }]
    },
    {
        capa: 'm04_censo2010',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clavedo', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'pobtot', headerName: 'Pobtot', width: 200 },
            { field: 'pobmas', headerName: 'Pobmas', width: 250 },
            { field: 'pobfem', headerName: 'Pobfem', width: 200 }]
    },
    {
        capa: 'm04_censo2020',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clavedo', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'pobtot', headerName: 'Pobtot', width: 200 },
            { field: 'pobmas', headerName: 'Pobmas', width: 250 },
            { field: 'pobfem', headerName: 'Pobfem', width: 200 }]
    },
    {
        capa: 'm04_censomun2000',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'cvemunicip', headerName: 'Cvemunicip', width: 100 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'cveentidad', headerName: 'Cveentidad', width: 100 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'clvgeo', headerName: 'Clvgeo', width: 100 },
            { field: 'pobtot', headerName: 'Pobtot', width: 100 },
            { field: 'pobmas', headerName: 'Pobmas', width: 150 },
            { field: 'pobfem', headerName: 'Pobfem', width: 100 }]
    },
    {
        capa: 'm04_censomun2010',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'cvemunicip', headerName: 'Cvemunicip', width: 100 },
            { field: 'municipio', headerName: 'Municipio', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'cveentidad', headerName: 'Cveentidad', width: 100 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'clvgeo', headerName: 'Clvgeo', width: 100 },
            { field: 'pobtot', headerName: 'Pobtot', width: 200 },
            { field: 'pobmas', headerName: 'Pobmas', width: 250 },
            { field: 'pobfem', headerName: 'Pobfem', width: 200 }]
    },
    {
        capa: 'm04_censomun2020',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'cvemunicip', headerName: 'Cvemunicip', width: 100 },
            { field: 'municipio', headerName: 'Municipio', width: 300 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'cveentidad', headerName: 'Cveentidad', width: 100 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'clvgeo', headerName: 'Clvgeo', width: 100 },
            { field: 'pobtot', headerName: 'Pobtot', width: 200 },
            { field: 'pobmas', headerName: 'Pobmas', width: 250 },
            { field: 'pobfem', headerName: 'Pobfem', width: 200 }]
    },
    {
        capa: 'm04_economia',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'cveent', headerName: 'Cveent', width: 150 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'clavemun', headerName: 'Clavemun', width: 200 },
            { field: 'municipio', headerName: 'Municipio', width: 100 },
            { field: 'pea', headerName: 'Pea', width: 100 },
            { field: 'pea_f', headerName: 'Pea_f', width: 100 },
            { field: 'pea_m', headerName: 'Pea_m', width: 350 },
            { field: 'pe_inac', headerName: 'Pe_inac', width: 250 },
            { field: 'pe_inac_f', headerName: 'Pe_inac_f', width: 100 },
            { field: 'pe_inac_m', headerName: 'Pe_inac_m', width: 100 },
            { field: 'pocupada', headerName: 'Pocupada', width: 200 },
            { field: 'pocupada_f', headerName: 'Pocupada_f', width: 200 },
            { field: 'pocupada_m', headerName: 'Pocupada_m', width: 100 },
            { field: 'pdesocup', headerName: 'Pdesocup', width: 200 },
            { field: 'pdesocup_f', headerName: 'Pdesocup_f', width: 100 },
            { field: 'pdesocup_m', headerName: 'Pdesocup_m', width: 100 },
            { field: 'anio_eco', headerName: 'Año_eco', width: 100 }]
    },
    {
        capa: 'm04_etnicidad',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'cveent', headerName: 'Cveent', width: 150 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'clavemun', headerName: 'Clavemun', width: 100 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'p3ym_hli', headerName: 'P3ym_hli', width: 100 },
            { field: 'p3ym_hli_f', headerName: 'P3ym_hli_f', width: 100 },
            { field: 'p3ym_hli_m', headerName: 'P3ym_hli_m', width: 100 },
            { field: 'p3hlinhe', headerName: 'P3hlinhe', width: 100 },
            { field: 'p3hlinhe_f', headerName: 'P3hlinhe_f', width: 100 },
            { field: 'p3hlinhe_m', headerName: 'P3hlinhe_m', width: 100 },
            { field: 'p3hli_he', headerName: 'P3hli_he', width: 100 },
            { field: 'p3hli_he_f', headerName: 'P3hli_he_f', width: 100 },
            { field: 'p3hli_he_m', headerName: 'P3hli_he_m', width: 100 },
            { field: 'p5_hli', headerName: 'p5_hli', width: 100 },
            { field: 'p5_hli_nhe', headerName: 'P5_hli_nhe', width: 100 },
            { field: 'p5_hli_he', headerName: 'p5_hli_he', width: 100 },
            { field: 'phog_ind', headerName: 'Phog_ind', width: 100 },
            { field: 'pob_afro', headerName: 'Pob_afro', width: 100 },
            { field: 'pob_afro_f', headerName: 'Pob_afro_f', width: 100 },
            { field: 'pob_afro_m', headerName: 'Pob_afro_m', width: 100 },
            { field: 'anio_etni', headerName: 'anio_etni', width: 100 }]
    },
    {
        capa: 'm04_indicemarginacion',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'cveloc', headerName: 'Cveloc', width: 100 },
            { field: 'localidad', headerName: 'Localidad', width: 200 },
            { field: 'pobtotaled', headerName: 'pobtotaled', width: 100 },
            { field: 'totalmascu', headerName: 'Totalmascu', width: 100 },
            { field: 'totalfemen', headerName: 'Totalfemen', width: 100 },
            { field: 'analf', headerName: 'Analf', width: 100 },
            { field: 'sbasc', headerName: 'Sbasc', width: 100 },
            { field: 'ovsde', headerName: 'Ovsde', width: 100 },
            { field: 'ovsee', headerName: 'Ovsee', width: 100 },
            { field: 'ovsae', headerName: 'Ovsae', width: 100 },
            { field: 'ovpt', headerName: 'Ovpt', width: 100 },
            { field: 'ovhac', headerName: 'Ovhac', width: 100 },
            { field: 'im_2020', headerName: 'Im_2020', width: 100 },
            { field: 'gm_2020', headerName: 'Gm_2020', width: 100 },
            { field: 'imn_2020', headerName: 'imn_2020', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm04_zonasatencionprioritaria',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'cvemun', headerName: 'Clave Municipal', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'margi_2015', headerName: 'Margi_2015', width: 100 },
            { field: 'rezago_201', headerName: 'Rezago_201', width: 200 },
            { field: 'por_pobl_p', headerName: 'Por_pobl_p', width: 100 },
            { field: 'cat_indi', headerName: 'Cat_indi', width: 100 },
            { field: 'descrip', headerName: 'Descrip', width: 200 },
            { field: 'niv_delic_', headerName: 'Niv_delic_', width: 200 },
            { field: 'dens_urb_2', headerName: 'Dens_urb_2', width: 200 },
            { field: 'anio', headerName: 'Año', width: 200 }]
    },
    {
        capa: 'm04_indicemarginacion',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'entidad', headerName: 'Entidad', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'cveloc', headerName: 'Cveloc', width: 100 },
            { field: 'localidad', headerName: 'Localidad', width: 200 },
            { field: 'pobtotaled', headerName: 'pobtotaled', width: 100 },
            { field: 'totalmascu', headerName: 'Totalmascu', width: 100 },
            { field: 'totalfemen', headerName: 'Totalfemen', width: 100 },
            { field: 'analf', headerName: 'Analf', width: 100 },
            { field: 'sbasc', headerName: 'Sbasc', width: 100 },
            { field: 'ovsde', headerName: 'Ovsde', width: 100 },
            { field: 'ovsee', headerName: 'Ovsee', width: 100 },
            { field: 'ovsae', headerName: 'Ovsae', width: 100 },
            { field: 'ovpt', headerName: 'Ovpt', width: 100 },
            { field: 'ovhac', headerName: 'Ovhac', width: 100 },
            { field: 'im_2020', headerName: 'Im_2020', width: 100 },
            { field: 'gm_2020', headerName: 'Gm_2020', width: 100 },
            { field: 'imn_2020', headerName: 'imn_2020', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm05_cuencas_FS',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'fechadof', headerName: 'Fechadof', width: 100 },
            { field: 'fechacorte', headerName: 'Fechacorte', width: 100 },
            { field: 'subregion', headerName: 'Subregion', width: 100 },
            { field: 'cp', headerName: 'Cp', width: 100 },
            { field: 'ar', headerName: 'Ar', width: 100 },
            { field: 'uc', headerName: 'Uc', width: 100 },
            { field: 'R', headerName: 'R', width: 100 },
            { field: 'im', headerName: 'Im', width: 100 },
            { field: 'ex', headerName: 'Ex', width: 100 },
            { field: 'ev', headerName: 'Ev', width: 100 },
            { field: 'av', headerName: 'Av', width: 100 },
            { field: 'ab', headerName: 'Ab', width: 100 },
            { field: 'rxy', headerName: 'Rxy', width: 100 },
            { field: 'd', headerName: 'D', width: 100 },
            { field: 'disponibilidadrelativa', headerName: 'Disponibilidadrelativa', width: 100 },
            { field: 'idcatclasificacion', headerName: 'idcatclasificacion', width: 100 },
            { field: 'clasificacion', headerName: 'clasificacion', width: 400 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm05_cuencas_disponibilidad',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave de la cuenca', width: 200 },
            { field: 'nombre', headerName: 'Cuenca', width: 200 },
            { field: 'claverha', headerName: 'Clave RHA', width: 100 },
            { field: 'fechadof', headerName: 'Fecha DOF', width: 100 },
            { field: 'fechacorte', headerName: 'Fecha corte', width: 100 },
            { field: 'subregion', headerName: 'Subregión', width: 100 },
            { field: 'cp', headerName: 'Cp', width: 100 },
            { field: 'ar', headerName: 'Ar', width: 100 },
            { field: 'uc', headerName: 'Uc', width: 100 },
            { field: 'r', headerName: 'R', width: 100 },
            { field: 'im', headerName: 'Im', width: 100 },
            { field: 'ex', headerName: 'Ex', width: 100 },
            { field: 'ev', headerName: 'Ev', width: 100 },
            { field: 'av', headerName: 'Av', width: 100 },
            { field: 'ab', headerName: 'Ab', width: 100 },
            { field: 'rxy', headerName: 'Rxy', width: 100 },
            { field: 'd', headerName: 'D', width: 100 },
            { field: 'disponibilidadrelativa', headerName: 'Disponibilidad relativa', width: 200 },
            { field: 'clasificacion', headerName: 'Clasificación', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 },]
    },
    {
        capa: 'm05_zonaspago',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clvcuenca', headerName: 'Clave', width: 100 },
            { field: 'cuenca', headerName: 'Nombre', width: 200 },
            { field: 'claverha', headerName: 'RHA', width: 100 },
            { field: 'zona', headerName: 'Zona', width: 100 },
            { field: 'fechadof', headerName: 'Fecha de DOF de Zona de Cobro', width: 250 },
            { field: 'fechacorte', headerName: 'Fecha de corte de zona de cobro', width: 250 },
            { field: 'Acuacultura', headerName: 'Acuacultura', width: 100 },
            { field: 'Agropecuario, sin exceder concesión', headerName: 'Agropecuario', width: 100 },
            { field: 'Agropecuario, por cada m3 que exceda del concesionado', headerName: 'Agropecuario, por cada m³ que exceda del concesionado', width: 400 },
            { field: 'Agua potable, consumo igual o inferior a 300 l/hab/día', headerName: 'Agua potable, consumo igual o inferior a 300 l/hab/día', width: 400 },
            { field: 'Agua potable, consumo mayor a 300 l/hab/día', headerName: 'Agua potable, consumo mayor a 300 l/hab/día', width: 400 },
            { field: 'Balnearios y centros recreativos', headerName: 'Balnearios y centros recreativos', width: 300 },
            { field: 'Generación Hidroeléctrica', headerName: 'Generación Hidroeléctrica', width: 200 },
            { field: 'Régimen General', headerName: 'Régimen General', width: 200 }]
    },
    {
        capa: 'm05_ordenamientos',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavecuenca', headerName: 'Clave de la cuenca', width: 150 },
            { field: 'nombrecuenca', headerName: 'Cuenca', width: 200 },
            { field: 'claverh', headerName: 'Clave de la región hidrológica', width: 200 },
            { field: 'nombrerh', headerName: 'Región hidrológica', width: 300 },
            { field: 'claverha', headerName: 'Clave RHA', width: 100 },
            { field: 'nombrerha', headerName: 'RHA', width: 250 },
            { field: 'totalveda', headerName: 'Número de veda', width: 150 },
            { field: 'dofvedvig', headerName: 'Fecha de DOF veda vigente', width: 200 },
            { field: 'nomvedvig', headerName: 'Nombre veda vigente', width: 1600 },
            { field: 'usoreservatipo', headerName: 'Reserva', width: 300 },
            { field: 'dofpubur', headerName: 'Fecha DOF reserva público urbano', width: 250 },
            { field: 'dofmedamb', headerName: 'Fecha DOF reserva medio ambiente', width: 250 },
            { field: 'dofgener', headerName: 'Fecha DOF reserva generación de energía', width: 250 },
            { field: 'reglamento', headerName: 'Reglamento', width: 150 },
            { field: 'dofregla', headerName: 'Fecha DOF reglamento', width: 200 },]
    },
    {
        capa: 'm05_cuencasvedas',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavecuenca', headerName: 'Clave de la cuenca', width: 150 },
            { field: 'nombrecuenca', headerName: 'Cuenca', width: 200 },
            { field: 'claverh', headerName: 'Clave de la región hidrológica', width: 200 },
            { field: 'nombrerh', headerName: 'Región hidrológica', width: 300 },
            { field: 'claverha', headerName: 'Clave RHA', width: 100 },
            { field: 'nombrerha', headerName: 'RHA', width: 250 },
            { field: 'vedavig', headerName: 'Veda vigente', width: 150 },
            { field: 'dofvedvig', headerName: 'Fecha de DOF veda vigente', width: 200 },
            { field: 'nomvedvig', headerName: 'Nombre veda vigente', width: 1600 }]
    },
    {
        capa: 'm06_acuiferos_disponibilidad',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'fechacorte', headerName: 'Fechacorte', width: 100 },
            { field: 'fechapub', headerName: 'Fechapub', width: 100 },
            { field: 'r', headerName: 'R', width: 100 },
            { field: 'dnc', headerName: 'Dnc', width: 100 },
            { field: 'vcas', headerName: 'Vcas', width: 100 },
            { field: 'vextet', headerName: 'Vextet', width: 100 },
            { field: 'veala', headerName: 'Veala', width: 100 },
            { field: 'vaptyr', headerName: 'Vaptyr', width: 100 },
            { field: 'vaprh', headerName: 'Vaprh', width: 100 },
            { field: 'veas', headerName: 'Veas', width: 100 },
            { field: 'dmanegativa', headerName: 'Dmanegativa', width: 100 },
            { field: 'situacion', headerName: 'Situacion', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'idrha', headerName: 'Idrha', width: 100 },]
    },
    {
        capa: 'm06_acuiferos_disponibilidad',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'fechacorte', headerName: 'Fechacorte', width: 100 },
            { field: 'fechapub', headerName: 'Fechapub', width: 100 },
            { field: 'r', headerName: 'R', width: 100 },
            { field: 'dnc', headerName: 'Dnc', width: 100 },
            { field: 'vcas', headerName: 'Vcas', width: 100 },
            { field: 'vextet', headerName: 'Vextet', width: 100 },
            { field: 'veala', headerName: 'Veala', width: 100 },
            { field: 'vaptyr', headerName: 'Vaptyr', width: 100 },
            { field: 'vaprh', headerName: 'Vaprh', width: 100 },
            { field: 'veas', headerName: 'Veas', width: 100 },
            { field: 'dmanegativa', headerName: 'Dmanegativa', width: 100 },
            { field: 'situacion', headerName: 'Situacion', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'idrha', headerName: 'Idrha', width: 100 },]
    },
    {
        capa: 'm06_acuiferos_condición',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'condicion', headerName: 'Condición', width: 200 },
            { field: 'fechapub', headerName: 'Fecha de publicación en DOF', width: 200 }]
    },
    {
        capa: 'm06_acuiferos_disponibilidad_FS',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 200 },
            { field: 'fechacorte', headerName: 'Fechacorte', width: 100 },
            { field: 'fechapub', headerName: 'Fechapub', width: 100 },
            { field: 'r', headerName: 'R', width: 100 },
            { field: 'dnc', headerName: 'Dnc', width: 100 },
            { field: 'vcas', headerName: 'Vcas', width: 100 },
            { field: 'vextet', headerName: 'Vextet', width: 100 },
            { field: 'veala', headerName: 'Veala', width: 100 },
            { field: 'vaptyr', headerName: 'Vaptyr', width: 100 },
            { field: 'vaprh', headerName: 'Vaprh', width: 100 },
            { field: 'veas', headerName: 'Veas', width: 100 },
            { field: 'dmapositiva', headerName: 'Dmapositiva', width: 100 },
            { field: 'dmanegativa', headerName: 'Dmanegativa', width: 100 },
            { field: 'situacion', headerName: 'Situacion', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm07_distirosriego_anioagricola',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clavedr', headerName: 'clavedr', width: 100 },
            { field: 'nombredr', headerName: 'Nombredr', width: 400 },
            { field: 'estado', headerName: 'Estado', width: 100 },
            { field: 'rh', headerName: 'Rh', width: 100 },
            { field: 'rha', headerName: 'Rha', width: 100 },
            { field: 'anioagricola', headerName: 'Anioagricola	', width: 100 },
            { field: 'usuarios', headerName: 'Usuarios', width: 100 },
            { field: 'suptotal', headerName: 'Suptotal', width: 100 },
            { field: 'supregadatotal', headerName: 'Supregadatotal', width: 100 },
            { field: 'conaguasp', headerName: 'Conaguasp', width: 100 },
            { field: 'supregadasb', headerName: 'Supregadasb', width: 100 },
            { field: 'volumenaguatotal', headerName: 'volumenaguatotal', width: 100 },
            { field: 'volumenaguasp', headerName: 'volumenaguasp', width: 100 }]
    },
    {
        capa: 'm07_unidadesriego_rha',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'claverha', headerName: 'Claverha', width: 100 },
            { field: 'rha', headerName: 'Rha', width: 400 },
            { field: 'anioagricola', headerName: 'Anioagricola', width: 100 },
            { field: 'supcosechada', headerName: 'Supcosechada', width: 100 },
            { field: 'produccion', headerName: 'Produccion', width: 100 },
            { field: 'rendimiento', headerName: 'Rendimiento', width: 100 }]
    },
    {
        capa: 'm08_cobroderechos',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'idrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'Rha', width: 200 },
            { field: 'Uso o aprovechamiento de aguas nacionales', headerName: 'Uso o aprovechamiento de aguas nacionales', width: 100 },
            { field: 'Suministro de agua en bloque a centros urbanos e industriales', headerName: 'Suministro de agua en bloque a centros urbanos e industriales', width: 100 },
            { field: 'Servicio de Riego', headerName: 'Servicio de Riego', width: 100 },
            { field: 'Extracción de materiales', headerName: 'Extracción de Materiales', width: 100 },
            { field: 'Uso de cuerpo receptor', headerName: 'Uso de Cuerpo Receptor', width: 100 },
            { field: 'Uso de zonas federales', headerName: 'Uso de Zonas federales', width: 100 },
            { field: 'Diversos (servicios de trámite, regularización y multas, etc.', headerName: 'Diversos', width: 150 },
            { field: 'Descargas de aguas residuales', headerName: 'Descargas de Aguas Residuales', width: 100 },
            { field: 'Créditos fiscales', headerName: 'Créditos Fiscales', width: 100 },
            { field: 'Programa ponte al corriente', headerName: 'Programa Ponte al Corriente', width: 100 },
            { field: 'Trasvase de aguas nacionales', headerName: 'Trasvase de Aguas Nacionales', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm08_recaudacionuso',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'idrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'RHA', width: 200 },
            { field: 'Acuacultura', headerName: 'Acuacultura', width: 100 },
            { field: 'Balnearios y centros recreativos', headerName: 'Balnearios y centros recreativos', width: 100 },
            { field: 'Hidroeléctricas', headerName: 'Hidroeléctricas', width: 100 },
            { field: 'Público Urbano', headerName: 'Público Urbano', width: 100 },
            { field: 'Régimen General', headerName: 'Régimen General', width: 100 },
            { field: 'Agropecuario', headerName: 'Agropecuario', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm10_distribucionnacional',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave Estado', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 400 },
            { field: 'ciclo', headerName: 'Año', width: 100 },
            { field: 'mdp', headerName: 'Presupuesto Ejercido (mdp)', width: 200 }]
    },
    {
        capa: 'm10_distribucionporentidad',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clave', headerName: 'Clave Estado', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'ppgrupo', headerName: 'Ppgrupo', width: 100 },
            { field: 'ciclo', headerName: 'Año', width: 100 },
            { field: 'mdp', headerName: 'Presupuesto Ejercido (mdp)', width: 200 }]
    },
    {
        capa: 'm10_vwpresupejercidorham',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
            { field: 'rha', headerName: 'Región Hidrológico-Administrativa', width: 300 },
            { field: 'unidad', headerName: 'Unidad', width: 400 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'mes', headerName: 'Mes', width: 100 },
            { field: 'monto', headerName: 'Presupuesto ejercido acumulado (mdp)', width: 300 }]
    },
    {
        capa: 'm10_vwpresupejercidoedom',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'clvedo', headerName: 'Clave estado', width: 100 },
            { field: 'estado', headerName: 'Estado', width: 300 },
            { field: 'unidad', headerName: 'Unidad', width: 400 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'mes', headerName: 'Mes', width: 100 },
            { field: 'monto', headerName: 'Presupuesto ejercido acumulado (mdp)', width: 300 }]
    },
    {
        capa: 'm10_presupuestoporregion',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'region', headerName: 'Región', width: 200 },
            { field: 'ciclo', headerName: 'Año', width: 100 },
            { field: 'mdp', headerName: 'Presupuesto Ejercido (mdp)', width: 200 }]
    },
    {
        capa: 'm11_prestadores',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'rfcprestador', headerName: 'Rfcprestador', width: 150 },
            { field: 'claveprestador', headerName: 'Claveprestador', width: 100 },
            { field: 'nombreprestador', headerName: 'Nombreprestador', width: 700 },
            { field: 'abreviaturaprestador', headerName: 'Abreviaturaprestador', width: 200 },
            { field: 'tipoprestador', headerName: 'Tipoprestador', width: 200 },
            { field: 'rango', headerName: 'Rango', width: 200 },
            { field: 'tomasmiles', headerName: 'Tomasmiles', width: 200 },
            { field: 'habitantesmiles', headerName: 'Habitantesmiles', width: 200 },
            { field: 'acuarating', headerName: 'Acuarating', width: 200 },
            { field: 'clavemun', headerName: 'Clavemun', width: 100 },
            { field: 'nombremun', headerName: 'Nombremun', width: 300 },
            { field: 'cveentidad', headerName: 'cveentidad', width: 100 },
            { field: 'entidad', headerName: 'entidad', width: 200 }]
    }, {
        capa: "m02_parametros",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clavesitio', headerName: 'Clave de Sitio', width: 150 },
            { field: 'nombre', headerName: 'Sitio', width: 450 },
            { field: 'parametro', headerName: 'Parametro', width: 150 },
            { field: 'descripcion', headerName: 'Descripción', width: 250 },
            { field: 'tipo', headerName: 'Tipo de Sitio', width: 150 },
            { field: 'valor', headerName: 'Valor', width: 150 },
            { field: 'clasificacion', headerName: 'Clasificación', width: 150 },
        ]
    },
    {
        capa: "m07_agricoladrnumcultivos",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clvdr', headerName: 'Clave DR', width: 150 },
            { field: 'dr', headerName: 'Distrito de Riego', width: 250 },
            { field: 'anioagricola', headerName: 'Año Agricola', width: 150 },
            { field: 'numcultivo', headerName: 'Número de Cultivos', width: 250 },
            { field: 'spsembrada', headerName: 'Superficie Sembrada (ha)', width: 200 },
            { field: 'spcosechada', headerName: 'Superficie Cosechada (ha)', width: 200 }
        ]
    },
    {
        capa: "m07_agricoladrspsembradaedoscul",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clvedo', headerName: 'Clave Estado', width: 150 },
            { field: 'edo', headerName: 'Estado', width: 250 },
            { field: 'anioagricola', headerName: 'Año agrícola', width: 150 },
            { field: 'spsembrada', headerName: 'Superficie Sembrada (ha)', width: 250 }
        ]
    },
    {
        capa: "m07_agricoladrproductividadedoscul",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clvedo', headerName: 'Clave Estado', width: 150 },
            { field: 'edo', headerName: 'Estado', width: 250 },
            { field: 'anioagricola', headerName: 'Año Agrícola', width: 150 },
            { field: 'rendimiento', headerName: 'Rendimiento (ton/ha)', width: 250 }
        ]
    },
    {
        //clvedo 	edo 	anioagricola 	totalgeneral
        capa: "m07_supregadadportipoaprovedom",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clvedo', headerName: 'Clave Estado', width: 150 },
            { field: 'edo', headerName: 'Estado', width: 250 },
            { field: 'anioagricola', headerName: 'Año Agrícola', width: 150 },
            { field: 'totalgeneral', headerName: 'Superficie Regada (ha)', width: 250 }
        ]
    },
    {
        capa: "m07_agricoladrvalor",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clvdr', headerName: 'Clave DR', width: 100 },
            { field: 'dr', headerName: 'Distrito de Riego', width: 250 },
            { field: 'anioagricola', headerName: 'Año Agricola', width: 150 },
            { field: 'spsembrada', headerName: 'Superficie Sembrada (ha)', width: 200 },
            { field: 'spcosechada', headerName: 'Superficie Cosechada (ha)', width: 200 },
            { field: 'producciont', headerName: 'Producción (ton)', width: 200 },
            { field: 'valproduccion', headerName: 'Valor de la Producción (miles de pesos)', width: 300 }
        ]
    },
    {
        capa: "m07_supregadaresumentotal",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clvdr', headerName: 'Clave DR', width: 100 },
            { field: 'dr', headerName: 'Distrito de Riego', width: 250 },
            { field: 'anioagricola', headerName: 'Año Agrícola', width: 150 },
            { field: 'numusuarios', headerName: 'Número de Usuarios', width: 200 },
            { field: 'uncultivo', headerName: 'Un solo cultivo', width: 200 },
            { field: 'doscultivos', headerName: 'Dos cultivos', width: 200 },
            { field: 'total', headerName: 'Total', width: 200 }
        ]
    },
    {
        capa: "m07_supregadaresumenvolumen",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clvdr', headerName: 'Clave DR', width: 100 },
            { field: 'dr', headerName: 'Distrito de Riego', width: 250 },
            { field: 'anioagricola', headerName: 'Año Agrícola', width: 150 },
            { field: 'numusuarios', headerName: 'Número de Usuarios', width: 200 },
            { field: 'voldistribuido', headerName: 'Volumen Distribuido (miles de m³)', width: 300 },
            { field: 'laminabruta', headerName: 'Lámina Bruta (cm)', width: 200 }
        ]
    },
    {
        capa: "m12_estacionesclimatologicas",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'claveclimatologica', headerName: 'Clave de estación', width: 100 },
            { field: 'nombreclimatologica', headerName: 'Nombre de estación', width: 100 },
            { field: 'condicion', headerName: 'Estatus', width: 250 },
            { field: 'estado', headerName: 'Estado', width: 150 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'rha', headerName: 'RHA', width: 200 },
            { field: 'cuenca', headerName: 'Cuenca', width: 200 }
        ]
    },
    {
        capa: "m12_estacioneshidrometricas",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 },
            { field: 'cveehidro', headerName: 'Clave', width: 100 },
            { field: 'nombre', headerName: 'Nombre', width: 250 },
            { field: 'descestacion', headerName: 'Tipo de estación', width: 150 },
            { field: 'nombrecorriente', headerName: 'Corriente', width: 200 },
            { field: 'claverha', headerName: 'Clave RHA', width: 200 },
            { field: 'rha', headerName: 'RHA', width: 200 },
            { field: 'cuenca', headerName: 'Cuenca', width: 200 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'gastomaxanual', headerName: 'Gasto máximo anual', width: 200 },
            { field: 'gastominanual', headerName: 'Gasto mínimo anual', width: 200 },
            { field: 'volanualesc', headerName: 'Volumen anual', width: 200 },
            { field: 'valormetadato4', headerName: 'Responsable', width: 200 }
        ]
    },
    {
        capa: "m12_bandas",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'clavebandas', headerName: 'Clave de estación', width: 100 },
            { field: 'stationnam', headerName: 'Estación', width: 100 },
            { field: 'secondaryn', headerName: 'Corriente', width: 250 },
            { field: 'rh', headerName: 'Región hidrológica', width: 150 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'cuenca', headerName: 'Cuenca', width: 200 },
            { field: 'subcuenca', headerName: 'Subcuenca', width: 200 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'inicio', headerName: 'Inicio de datos', width: 200 },
            { field: 'fin', headerName: 'Término de datos', width: 200 }

        ]
    },
    {
        capa: "m04_censoLocalidad",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'cveentidad', headerName: 'Clave entidad', width: 100 },
            { field: 'entidad', headerName: 'Entidad', width: 200 },
            { field: 'cvemun', headerName: 'Clave municipal', width: 100 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'cveloc', headerName: 'Clave localidad', width: 250 },
            { field: 'localidad', headerName: 'Localidad', width: 250 },
            { field: 'cvegeo', headerName: 'Clave geoestadística', width: 150 },
            { field: 'pobtot', headerName: 'Población total', width: 200 },
            { field: 'pobmas', headerName: 'Población masculina', width: 200 },
            { field: 'pobfem', headerName: 'Población femenina', width: 200 },
            { field: 'anio', headerName: 'Año', width: 200 },
            { field: 'clasificacion', headerName: 'Clasificación', width: 200 },
            { field: 'nom_zm', headerName: 'zona metropolitana', width: 200 },
            { field: 'rha_mun', headerName: 'RHA', width: 200 }

        ]
    },
    {
        capa: "Presas",
        alias: [
            { field: 'id', headerName: '#', width: 100 },
            { field: 'fechamonitoreo', headerName: 'Fecha de Monitoreo', width: 200 },
            { field: 'clavesih', headerName: 'Clave SIH', width: 150 },
            { field: 'nombreoficial', headerName: 'Nombre oficial', width: 200 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'llenano', headerName: '% de Llenado', width: 150 }

        ]
    },
    {
        capa: 'm16_PrecipitacionAnual',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'rango', headerName: 'Rango anual', width: 200 },
            { field: 'anio', headerName: 'Año', width: 200 },
            { field: 'ene', headerName: 'Enero', width: 100 },
            { field: 'feb', headerName: 'Febrero', width: 100 },
            { field: 'mar', headerName: 'Marzo', width: 100 },
            { field: 'abr', headerName: 'Abril', width: 100 },
            { field: 'may', headerName: 'Mayo', width: 100 },
            { field: 'jun', headerName: 'Junio', width: 100 },
            { field: 'jul', headerName: 'Julio', width: 100 },
            { field: 'ago', headerName: 'Agosto', width: 100 },
            { field: 'sep', headerName: 'Septiembre', width: 100 },
            { field: 'oct', headerName: 'Octubre', width: 100 },
            { field: 'nov', headerName: 'Noviembre', width: 100 },
            { field: 'dic', headerName: 'Diciembre', width: 100 },
            { field: 'anual', headerName: 'Anual', width: 100 }]
    },
    {
        capa: 'm16_PrecipitacionNormal',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'rango', headerName: 'Rango anual', width: 200 },
            { field: 'anio', headerName: 'Año', width: 200 },
            { field: 'ene', headerName: 'Enero', width: 100 },
            { field: 'feb', headerName: 'Febrero', width: 100 },
            { field: 'mar', headerName: 'Marzo', width: 100 },
            { field: 'abr', headerName: 'Abril', width: 100 },
            { field: 'may', headerName: 'Mayo', width: 100 },
            { field: 'jun', headerName: 'Junio', width: 100 },
            { field: 'jul', headerName: 'Julio', width: 100 },
            { field: 'ago', headerName: 'Agosto', width: 100 },
            { field: 'sep', headerName: 'Septiembre', width: 100 },
            { field: 'oct', headerName: 'Octubre', width: 100 },
            { field: 'nov', headerName: 'Noviembre', width: 100 },
            { field: 'dic', headerName: 'Diciembre', width: 100 },
            { field: 'anual', headerName: 'Anual', width: 100 }]
    },
    {
        capa: 'm18_ciclonestropicales',
        alias: [
            { field: 'id', headerName: 'ID', width: 100 },
            { field: 'nombreciclon', headerName: 'Ciclón', width: 100 },
            { field: 'catimpacto', headerName: 'Categoría de impacto', width: 200 },
            { field: 'edosafectados', headerName: 'Estados afectados', width: 600 },
            { field: 'inicio', headerName: 'Fecha de inicio', width: 150 },
            { field: 'final', headerName: 'Fecha final', width: 150 },
            { field: 'fechaimpacto', headerName: 'Fecha de impacto', width: 200 },
            { field: 'vientoimpacto', headerName: 'Velocidad de viento en impacto (km/h)', width: 300 },
            { field: 'litoral', headerName: 'Litoral', width: 100 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm18_trayectoriasciclones',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nombreciclon', headerName: 'Nombre', width: 200 },
            { field: 'inicio', headerName: 'Fecha de inicio', width: 200 },
            { field: 'final', headerName: 'Fecha final', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },
    {
        capa: 'm20_vwmpresas',
        alias: [
            { field: 'id', headerName: '#', width: 60 },
            { field: 'nomcomun', headerName: 'Nombre Comun', width: 200 },
            { field: 'nomoficial', headerName: 'Nombre Oficial', width: 200 },
            { field: 'estado', headerName: 'Estado', width: 200 },
            { field: 'municipio', headerName: 'Municipio', width: 200 },
            { field: 'anio', headerName: 'Año', width: 100 }]
    },

]
//{ field: 'link', headerName: 'Descarga de datos diarios', width: 200 }


