import React, { useState } from "react";
import Footer from '../../componentes/Footer'
import Header from '../../componentes/Header'
import './home.css'
import emailjs from '@emailjs/browser'
import $ from "jquery"
import { Link, NavLink } from "react-router-dom";

import Swal from 'sweetalert2'
import { Button } from "react-bootstrap";

const Home = () => {

    const [imagenM, setImagenM] = useState("");
    function imagenMostrar(opcion) {
        //console.log(opcion)
        setImagenM(opcion)
    }

    function enviarCorreo() {



        let nombre = document.getElementById("fulln").value
        let tel = document.getElementById("tel").value
        let mail = document.getElementById("mail").value
        let msj = document.getElementById("msj").value

        //emailjs.sendForm('service_rmirwkh','template_i8cly1n')
        var data = {
            service_id: 'service_rmirwkh',
            template_id: 'template_i8cly1n',
            user_id: 'k_H8JPp1YXq_MO2SB',
            template_params: {
                'nombre': nombre,
                'tel': tel,
                'mail': mail,
                'msj': msj,
            }
        }

        $.ajax('https://api.emailjs.com/api/v1.0/email/send', {
            type: 'POST',
            data: JSON.stringify(data),
            contentType: 'application/json'
        }).done(function (response) {
            if (response === "OK") {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Mensaje enviado correctamente',
                    showConfirmButton: false,
                    timer: 1500
                })
                document.getElementById("fulln").value = ""
                document.getElementById("tel").value = ""
                document.getElementById("mail").value = ""
                document.getElementById("msj").value = ""
            }

        }).fail(function (error) {
            console.log(error)
        });

    }


    return (
        <div className="container-fluid">
            <Header></Header>
            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="imgs/1.png" className="d-block w-100" alt="imagen 1" />
                        <div className="carousel-caption d-none d-md-block">
                            <h3></h3>
                            <p></p>
                            <div>
                                <Link to={'/Precios'}>
                                    <button type="button" className="btn btn-secondary">Ver Precios</button>
                                </Link>
                                <Link to={'/login'}>
                                    <button type="button" className="btn btn-secondary">Iniciar Sesión</button>
                                </Link>
                                
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="imgs/2.png" className="d-block w-100" alt="imagen 2" />
                        <div className="carousel-caption d-none d-md-block">
                            <h3></h3>
                            <p></p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src="imgs/3.png" className="d-block w-100" alt="imagen 3" />
                        <div className="carousel-caption d-none d-md-block">
                            <h3></h3>
                            <p></p>
                        </div>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <div id="about" className="about" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/ab_bg.png'})` }}>
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-7">
                            <div className="titlepage">
                                <h2>ACERCA DE</h2>
                                <span></span>
                                <p><strong>GeoMark</strong> Geomark es una herramienta que permite el uso y análisis de datos geocodificados para simplificar procesos y tomar decisiones inteligentes sobre: la planificación del mercado, la evaluación de sitios potenciales de expansión, recorridos comerciales óptimos, aunado a la localización de clientes y competidores, entre otros. Además, posee un sitio para análisis financiero a través de un tablero de control.</p>
                                <p>Con GeoMark podrás combinar datos geoespaciales demográficos, económicos, sociales, entre otros de forma fácil y accesible, orientando a tu empresa o negocio hacia el conocimiento global del cliente (visión 360°), sus necesidades y comportamientos que ayuden a los tomadores de decisiones a evaluar y analizar las condiciones y oportunidades para la efectiva planeación comercial.</p>
                                <p>GeoMark funge como una ventaja competitiva rentable.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="about_img img-fluid">
                                <figure><img src="imgs/img/1.png" alt="#" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                /*
                <div id="mobile" className="mobile" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/mo_bg.jpg'})` }}>
                            <div className="container">
                                <div className="row d_flex">
                                    <div className="col-md-5">
                                        <div className="mobile_img">
                                            <figure><img src="imgs/mobile.png" alt="#" /></figure>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="titlepage">
                                            <h2>Mobile App Development</h2>
                                            <span></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
                                            <a className="read_more">Read More <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                
                
                */
            }



            <div class="clients">
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>Soluciones</h2>
                                <p>Al adquirir <strong>GeoMark</strong> se tiene acceso a novedosas y potenciales herramientas que permiten:</p>

                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9 offset-md-1">
                            <div className="titlepage">
                                <h2>Visualice su información en un solo sitio</h2>
                                <span></span>
                                <p>Sintetiza y analiza cantidades de datos, al adquirir GeoMark se tiene acceso a novedosas y potenciales herramientas que permiten:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">

                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" onClick={() => imagenMostrar("conoce")}>
                                            Conoce el perfil micro-espacial, local o global de tus clientes para tomar decisiones estratégicas oportunas.
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Además, identifica a tus competidores directos, a través, de herramientas estadísticas y de sistemas de información geográfica, llevando a cabo un análisis de la competencia de forma ágil, realista y visualizando el mercado no atendido.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" onClick={() => imagenMostrar("mayor")}>
                                            Análisis empresarial unificado de sus datos financieros
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Convierta datos sin procesar en informes y graficas o paneles detallados. A través de un panel de control interactivo para mejorar la eficiencia y calidad del trabajo, manteniendo el nivel de productividad de la organización con el fin de acelerar resultados y obtener ventajas competitivas en el mercado., garantizando la toma de decisiones certeras y oportunas.  GeoMark garantiza la protección y la administración continua de sus datos.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" onClick={() => imagenMostrar("ayuda")}>
                                            Bases de datos demográficos, sociales, económicos actualizadas
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            A través de una página web que no requiere descargas ni actualizaciones, podrás acceder a información sociodemográfica y económica a nivel manzana, localidad, municipio, entidad, o de una zona de influencia especifica, análisis de redes, hotspot de clientes, áreas potenciales de expansión, entre otras.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour" onClick={() => imagenMostrar("visualizacion")}>
                                            Visualización de datos de manera grafica
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Al utilizar elementos visuales las herramientas de visualización de datos proporcionan una fácil y sin complicaciones, para comprender tendencias, valores atípicos y patrones en los datos. Estos los podrás descargar fácilmente en diferentes formatos.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive" onClick={() => imagenMostrar("conoce")}>
                                            Conoce en profundidad las condiciones del mercado actual
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Concede a la empresa la información que necesita para apuntar a las audiencias con las que quiere conectar, permite a las empresas tomar decisiones bien informadas, eliminar las conjeturas y canalizar recursos en ideas y proyectos que tienen el mayor potencial.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingSix">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix" onClick={() => imagenMostrar("reconoce")}>
                                            Reconoce puntos de localización de clientes
                                        </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            El geoposicionamiento ayuda a determinar o eliminar la posición geográfica en este caso de la competencia y posibles clientes, abre puertas de personalización de estrategias de marketing y conseguir que el cliente nos encuentre por su emplazamiento y necesidades concretas.
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-5">
                            {
                                imagenM === "" ? (
                                    <img src="imgs/img/2.png" alt="Soluciones" className="img-fluid imgSolu" />
                                ) : (
                                    imagenM === "conoce" ? (
                                        <img src="imgs/img/3.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                    ) : (
                                        imagenM === "mayor" ? (
                                            <img src="imgs/img/4.png" alt="Mayor visibilidad" className="img-fluid imgSolu" />
                                        ) : (
                                            imagenM === "ayuda" ? (
                                                <img src="imgs/img/5.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                            ) : (
                                                imagenM === "visualizacion" ? (
                                                    <img src="imgs/img/6.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                ) : (
                                                    imagenM === "conoce" ? (
                                                        <img src="imgs/img/7.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                    ) : (
                                                        imagenM === "reconoce" && (
                                                            <img src="imgs/img/8.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )

                                )
                            }


                        </div>
                    </div>
                </div>
            </div>

            {
                //Contact us
            }

            <div id="contact" className="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>Solicitar una devolución de llamada</h2>
                            </div>
                        </div>
                        <div className="col-md-6 offset-md-3">
                            <div id="request" class="main_form">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <input className="contactus" placeholder="Nombre completo" type="type" name="Full Name" id="fulln" />
                                    </div>
                                    <div className="col-md-12">
                                        <input className="contactus" id="tel" placeholder="Número de teléfono" type="type" name="Phone Number" />
                                    </div>
                                    <div className="col-md-12">
                                        <input className="contactus" id="mail" placeholder="Email" type="type" name="Email" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea className="contactus" id="msj" placeholder="Mensaje" type="type" Message="Name"></textarea>
                                    </div>
                                    <div className="col-sm-12">
                                        <button className="send_btn" onClick={enviarCorreo}>Enviar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                //footer
            }
            <Footer></Footer>

        </div>
    )
}

export default Home