import React from 'react'
import Header from "../../componentes/Header";
import { Link, NavLink } from "react-router-dom";
import './mapa.css'

const Mapa = () => {
    return (
        <div className="container-fluid">
            <Header></Header>
            <section className="vh-99">
                <div className="container-fluid h-custom">
                    <div className="row justify-content-end">
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Modelo'}>
                                <button type="button" className="btn btn-secondary">M. Evaluación Financiera</button>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Reportes'}>
                                <button type="button" className="btn btn-secondary">Panel de Control</button>
                            </Link>
                        </div>

                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Visualizador'}>
                                <button type="button" className="btn btn-secondary">Mapa</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center h-100 video">
                        <div className="col-md-12 col-lg-12 col-xl-12 ">
                            <video src="imgs/video.mp4" width={"100%"} height={"750"} autoPlay loop></video>
                        </div>
                        <div className="marca hit-the-floor">

                        </div>
                    </div>
                </div >


            </section >
        </div >
    )
}

export default Mapa