class Recaudacion {

    getDatosReporte1(datos) {
        
        let anios = [];
        // eslint-disable-next-line array-callback-return
        datos.map(item => {
            if (anios.length === 0) {
                anios.push(item['anio']);
            } else {
                if (!anios.includes(item['anio'])) {
                    anios.push(item['anio']);
                }
            }
        });
        //console.log();
        anios.sort()
        // anios.sort((unNumero, otroNumero) => otroNumero - unNumero);
        const header = Object.keys(datos[0]);

        let reporte1 = [];
        for (let index = 0; index < anios.length; index++) {
            let filtrado = {};
            filtrado.anio = anios[index];
            filtrado["Uso o aprovechamiento de aguas nacionales"] = 0;
            filtrado["Suministro de agua en bloque a centros urbanos e industriales"] = 0;
            filtrado["Servicio de Riego"] = 0;
            filtrado["Extracción de materiales"] = 0;
            filtrado["Uso de cuerpo receptor"] = 0;
            filtrado["Uso de zonas federales"] = 0;
            filtrado["Diversos ( servicios de trámite, regularización y multas entre otros )"] = 0;
            filtrado["Descargas"] = 0;
            filtrado["Créditos fiscales"] = 0;
            filtrado["Programa ponte al corriente"] = 0;
            filtrado["Trasvase de aguas nacionales"] = 0;
            // eslint-disable-next-line array-callback-return
            datos.map(item => {
                if (item.anio === anios[index]) {
                    filtrado["Uso o aprovechamiento de aguas nacionales"] = filtrado["Uso o aprovechamiento de aguas nacionales"] + Number(item[header[3]]);
                    filtrado["Suministro de agua en bloque a centros urbanos e industriales"] = filtrado["Suministro de agua en bloque a centros urbanos e industriales"] + Number(item[header[4]]);
                    filtrado["Servicio de Riego"] = filtrado["Servicio de Riego"] + Number(item[header[5]]);
                    filtrado["Extracción de materiales"] = filtrado["Extracción de materiales"] + Number(item[header[6]]);
                    filtrado["Uso de cuerpo receptor"] = filtrado["Uso de cuerpo receptor"] + Number(item[header[7]]);
                    filtrado["Uso de zonas federales"] = filtrado["Uso de zonas federales"] + Number(item[header[8]]);
                    filtrado["Diversos ( servicios de trámite, regularización y multas entre otros )"] = filtrado["Diversos ( servicios de trámite, regularización y multas entre otros )"] + Number(item[header[9]]);
                    filtrado["Descargas"] = filtrado["Descargas"] + Number(item[header[10]]);
                    filtrado["Créditos fiscales"] = filtrado["Créditos fiscales"] + Number(item[header[11]]);
                    filtrado["Programa ponte al corriente"] = filtrado["Programa ponte al corriente"] + Number(item[header[12]]);
                    filtrado["Trasvase de aguas nacionales"] = filtrado["Trasvase de aguas nacionales"] + Number(item[header[13]]);
                }
            });
            reporte1.push(filtrado);

        }
        const header1 = Object.keys(reporte1[0]);

        // eslint-disable-next-line array-callback-return
        reporte1.map(item => {
            let total = 0;
            for (let index = 1; index < header1.length; index++) {
                total = total + item[header1[index]];
            }
            item.Total = total;
        });
        const header11 = Object.keys(reporte1[0]);

        let finalR = [];
        for (let i = 1; i < header11.length; i++) {
            let aux = {};
            aux['tipo'] = header11[i];
            aux['Total'] = reporte1[i][header11[12]]
            for (let k = 0; k < reporte1.length; k++) {
                aux["A" + anios[k]] = reporte1[k][header11[i]];
            }
            finalR.push(aux);
        }

        
        return finalR;
    }

    getDatosReporte2(datos) {
        let anios = [];
        // eslint-disable-next-line array-callback-return
        datos.map(item => {
            if (anios.length === 0) {
                anios.push(item['anio']);
            } else {
                if (!anios.includes(item['anio'])) {
                    anios.push(item['anio']);
                }
            }
        });
        //anios.sort((unNumero, otroNumero) => otroNumero - unNumero);
        anios.sort();
        const header = Object.keys(datos[0]);
        let reporte2 = [];
        for (let index = 0; index < anios.length; index++) {
            let filtrado = {};
            filtrado.anio = anios[index];
            filtrado["Acuacultura"] = 0;
            filtrado["Balnearios y centros recreativos"] = 0;
            filtrado["Hidroeléctricas"] = 0;
            filtrado["Público Urbano"] = 0;
            filtrado["Régimen General"] = 0;
            filtrado["Agropecuario"] = 0;
            // eslint-disable-next-line array-callback-return
            datos.map(item => {
                if (item.anio === anios[index]) {
                    filtrado["Acuacultura"] = filtrado["Acuacultura"] + Number(item[header[3]]);
                    filtrado["Balnearios y centros recreativos"] = filtrado["Balnearios y centros recreativos"] + Number(item[header[4]]);
                    filtrado["Hidroeléctricas"] = filtrado["Hidroeléctricas"] + Number(item[header[5]]);
                    filtrado["Público Urbano"] = filtrado["Público Urbano"] + Number(item[header[6]]);
                    filtrado["Régimen General"] = filtrado["Régimen General"] + Number(item[header[7]]);
                    filtrado["Agropecuario"] = filtrado["Agropecuario"] + Number(item[header[8]]);
                }
            });
            reporte2.push(filtrado);
        }

        const header13 = Object.keys(reporte2[0]);

        // eslint-disable-next-line array-callback-return
        reporte2.map(item => {
            let total = 0;
            for (let index = 1; index < header13.length; index++) {
                total = total + item[header13[index]];
            }
            item.Total = total;
        });

        const header1 = Object.keys(reporte2[0]);
        let finalR = [];
        for (let i = 1; i < header1.length; i++) {
            let aux = {};
            aux['tipo'] = header1[i];
            for (let k = 0; k < reporte2.length; k++) {
                aux["A" + anios[k]] = reporte2[k][header1[i]];
            }
            finalR.push(aux);
        }
        return finalR;
    }

    getDatosGrafica1(datos) {
        let anios = [];
        // eslint-disable-next-line array-callback-return
        datos.map(item => {
            if (anios.length === 0) {
                anios.push(item['anio']);
            } else {
                if (!anios.includes(item['anio'])) {
                    anios.push(item['anio']);
                }
            }
        });
        //anios.sort((unNumero, otroNumero) => otroNumero - unNumero);
        anios.sort();
        const header = Object.keys(datos[0]);

        let reporte1 = [];
        for (let index = 0; index < anios.length; index++) {
            let filtrado = {};
            filtrado.anio = anios[index];
            filtrado["Uso o aprovechamiento de aguas nacionales"] = 0;
            filtrado["Resto de conceptos"] = 0;
            // eslint-disable-next-line array-callback-return
            datos.map(item => {
                if (item.anio === anios[index]) {
                    filtrado["Uso o aprovechamiento de aguas nacionales"] = filtrado["Uso o aprovechamiento de aguas nacionales"] + Number(item[header[3]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[4]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[5]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[6]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[7]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[8]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + 0;//Number(item[header[9]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[10]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[11]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[12]]);
                    filtrado["Resto de conceptos"] = filtrado["Resto de conceptos"] + Number(item[header[13]]);
                }
            });
            reporte1.push(filtrado);
        }

        let headers = Object.keys(reporte1[0])
        reporte1.map(item=>{
            headers.map(campo=>{
                if(campo!=="anio"){
                    item[campo]= item[campo].toFixed(2);
                }
            })
        })
        //console.log(reporte1);
        return reporte1;
    }


    getDatosGraficas2(datos) {

        let anios = [];
        // eslint-disable-next-line array-callback-return
        datos.map(item => {
            if (anios.length === 0) {
                anios.push(item['anio']);
            } else {
                if (!anios.includes(item['anio'])) {
                    anios.push(item['anio']);
                }
            }
        });
        //anios.sort((unNumero, otroNumero) => otroNumero - unNumero);
        anios.sort();
        const header = Object.keys(datos[0]);

        let reporte2 = [];
        for (let index = 0; index < anios.length; index++) {
            let filtrado = {};
            filtrado.anio = anios[index];
            filtrado["Acuacultura"] = 0;
            filtrado["Balnearios y centros recreativos"] = 0;
            filtrado["Hidroeléctricas"] = 0;
            filtrado["Público Urbano"] = 0;
            filtrado["Régimen General"] = 0;
            filtrado["Agropecuario"] = 0;
            // eslint-disable-next-line array-callback-return
            datos.map(item => {
                if (item.anio === anios[index]) {
                    filtrado["Acuacultura"] = filtrado["Acuacultura"] + Number(item[header[3]]);
                    filtrado["Balnearios y centros recreativos"] = filtrado["Balnearios y centros recreativos"] + Number(item[header[4]]);
                    filtrado["Hidroeléctricas"] = filtrado["Hidroeléctricas"] + Number(item[header[5]]);
                    filtrado["Público Urbano"] = filtrado["Público Urbano"] + Number(item[header[6]]);
                    filtrado["Régimen General"] = filtrado["Régimen General"] + Number(item[header[7]]);
                    filtrado["Agropecuario"] = filtrado["Agropecuario"] + Number(item[header[8]]);
                }
            });
            reporte2.push(filtrado);
        }

        let headers = Object.keys(reporte2[0])
        reporte2.map(item=>{
            headers.map(campo=>{
                if(campo!=="anio"){
                    item[campo]= item[campo].toFixed(2);
                }
                

            })
        })
        //console.log(reporte2);
        return reporte2;
    }



}

export default Recaudacion;