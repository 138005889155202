import React, { useState, useEffect } from 'react'
import Header from "../../componentes/Header";
import { Link } from "react-router-dom";
import './reporte.css'
import {
    faFileCsv,
    faDownload
} from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'
import Graficos2 from '../../componentes/Graficas2/Graficos2';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { tabla1, tabla2 } from '../../json/data';
import { useRef } from 'react';
import Select from 'react-select';
import V2 from '../../json/variables.json'
import * as XLSX from 'xlsx';
import * as htmlToImage from 'html-to-image';
import jsPDF from "jspdf";
import Swal from "sweetalert2";
import Cookie from 'universal-cookie';
import Constantes from '../../Constantes';

var ft1, ct1;
var f = 0, c = 1;

var ft2, ct2;
var f2 = 0, c2 = 1;
const cookie = new Cookie();


const Reportes = () => {
    const [filasT1, setFilasT1] = useState(0);
    const [columnasT1, setColumnasT1] = useState(0);
    const [filasT2, setFilasT2] = useState(0);
    const [columnasT2, setColumnasT2] = useState(4);
    const [variables, setVariables] = useState([])
    const [datos, setDatos] = useState()
    const [datos2, setDatos2] = useState()
    const [totales, setTotales] = useState([]);
    const [header, setHeader] = useState([])
    const [header2, setHeader2] = useState([])

    //var header = Object.keys(tabla1[0]);
    //var header2 = Object.keys(tabla2[0]);

    const hotTableComponent1 = useRef(null);

    useEffect(() => {
        actualizarTablas();
        obtenerVariables();
    }, []);

    async function obtenerVariables() {
        const respuesta = await fetch(`${Constantes.RUTA_API}/obtenerVariables.php`);
        const res = await respuesta.json()
        setVariables(res)

    }

    async function actualizarTablas() {
        let aux = cookie.get('usuarioR');

        const respuesta = await fetch(`${Constantes.RUTA_API}/obtenerDatosUsuario.php?email=${aux.email}`);
        const res = await respuesta.json()
        //console.log(res);

        if (res !== undefined) {
            let tabla11 = JSON.parse(res.datos1);
            let tabla22 = JSON.parse(res.datos2);
            //console.table(tabla11)
            //console.table(tabla22)

            let t = obtenerTotales(tabla11)
            setTotales(t)
            setDatos(tabla11);
            setDatos2(tabla22);
            obtenerDGrafcia(tabla22)
            setHeader(Object.keys(tabla11[0]))
            setHeader2(Object.keys(tabla22[0]))
            //console.log(Object.keys(tabla22[0]))

            ft1 = tabla11.length;
            ct1 = Object.keys(tabla11[0]).length;
            ft2 = tabla22.length;
            ct2 = Object.keys(tabla22[0]).length;
            setFilasT1(tabla11.length);
            setColumnasT1(Object.keys(tabla11[0]).length)
            setFilasT2(tabla22.length);
            setColumnasT2(Object.keys(tabla22[0]).length)
        }
    }


    function agregarFila() {
        //console.log("Se agrega fila")
        let aux = {}
        header.map(item => {
            aux[item] = ""
        })

        datos.push(aux);
        setDatos(datos)
        ft1 = filasT1 + 1
        setFilasT1(filasT1 + 1)

    }

    function agregarColumna() {
        //console.log("Se agrega columna")
        let num = columnasT1 + 1;
        datos.map(item => {
            item[`columna${num}`] = ""
        })
        //header = Object.keys(datos[0]);
        setHeader(Object.keys(datos[0]));

        ct1 = columnasT1 + 1
        setDatos(datos)
        setColumnasT1(columnasT1 + 1)
    }

    const guardarDatos1 = async () => {
        //actualizarColT1()

        //console.log(datos);
        //console.log(datosG2)
        const baseurl = 'http://localhost:4000/api/usuarios/';

        let aux = cookie.get('usuarioR');

        let datos1 = {}
        datos1.email = aux.email
        datos1.json = datos;
        datos1.json2 = datos2;
        //console.log(datos1)

        const cargaUtil = JSON.stringify(datos1);
        const respuesta = await fetch(`${Constantes.RUTA_API}/updateDatos.php`, {
            method: "PUT",
            body: cargaUtil,
        });
        //console.log(respuesta);



        //let msj = await axios.post('http://localhost:4000/api/usuarios/updateDatos', datos1)
        // console.log(msj);

        if (respuesta.status === 200) {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Datos guardados correctamente',
                showConfirmButton: false,
                timer: 1500
            })

        }

        actualizarTablas()

    }

    function agregarFila2() {
        //console.log("seagregaFila")
        let aux = {}
        header2.map(item => {
            aux[item] = ""
        })

        datos2.push(aux);
        setDatos2(datos2)
        ft2 = filasT2 + 1
        setFilasT2(filasT2 + 1)

    }

    function agregarColumna2() {
        // console.log("Se agrega columna")
        let num = columnasT2 + 1;
        datos2.map(item => {
            item[`columna${num}`] = ""
        })
        setHeader2(Object.keys(datos2[0]));
        console.log(datos2)
        ct2 = columnasT2 + 1
        setDatos2(datos2)
        setColumnasT2(columnasT2 + 1)
    }



    const [auxT, setAuxT] = useState(false)
    function actualizar() {
        //console.log("se actualiza tabla")
        let header = Object.keys(datos[0]);
        let obtenerDato = document.getElementById("tabla1")
        let filas = obtenerDato.getElementsByTagName("tr");
        let columnas = filas[filas.length - 1].getElementsByTagName("td");
        let n = filas[1].childNodes.length


        if (filas[0].childNodes[columnas.length - 1].firstChild.tagName === "INPUT") {

            //se actualiza la ultima columna 
            //se actualizan los valores de la ultima columna agregada
            let num = 0;
            let index;
            for (index = 1; index < filas.length; index++) {
                datos[num][header[header.length - 1]] = filas[index].childNodes[n - 1].childNodes[0].value
                num++;
            }
            f++;

        } else {
            if (columnas[0].childNodes[0].textContent !== undefined) {
                //con este ciclo recorremos las columnas de la fila se actualizan los valores de la ultima fila agregada
                for (let index = 0; index < columnas.length; index++) {
                    if (columnas[index].childNodes[0].value !== undefined) {
                        datos[datos.length - 1][header[index]] = columnas[index].childNodes[0].value
                    }
                }
                c++;
            } else {
                //se actualizan los valores de la ultima columna agregada
                let num = 0;
                let index;
                for (index = 1; index < filas.length; index++) {
                    datos[num][header[header.length - 1]] = filas[index].childNodes[n - 1].childNodes[0].value
                    num++;
                }
                f++;
            }

        }
        //console.table(datos)
        let t = obtenerTotales(datos)
        if (c === ct1 || f === ft1) {
            f = 0; c = 1;
            setTotales(t)
            setDatos(datos)
            setHeader(Object.keys(datos[0]))
            setAuxT(!auxT)
        }

    }


    function actualizarT2(e) {
        //console.log("Se actualiza tabla 2")
        let resume_table = document.getElementById("tabla2")
        let headers2 = []
        for (let index = 0; index < resume_table.rows[0].childNodes.length - 1; index++) {
            headers2.push(resume_table.rows[0].childNodes[index].innerText)
        }
        if (resume_table.rows[0].childNodes[resume_table.rows[1].childNodes.length - 1].childNodes[0].value !== undefined) {
            headers2.push(resume_table.rows[0].childNodes[resume_table.rows[1].childNodes.length - 1].childNodes[0].value)
        } else {
            headers2.push(resume_table.rows[0].childNodes[resume_table.rows[1].childNodes.length - 1].childNodes[0].textContent)
        }

        let dd2 = [];

        for (var i = 1, row; row = resume_table.rows[i]; i++) {
            let i = 1;
            let t2 = {}
            let colu = row.childNodes;
            t2.VARIABLE = colu[0].textContent;
            for (var j = 1; j < colu.length; j++) {
                t2[headers2[i]] = colu[j].childNodes[0].valueAsNumber;
                i++;
            }
            dd2.push(t2);
        }
        //console.log(dd2)
        obtenerDGrafcia(dd2);
        setDatos2(dd2);

    }
    const [auxT2, setAuxT2] = useState(false)
    const [datosG2, setDatosG2] = useState([])

    function obtenerDGrafcia(data) {
        let header = Object.keys(data[0]);

        let d = []
        for (let index = 1; index < header.length; index++) {
            let d2 = {}
            d2.variable = header[index]
            for (let j = 0; j < data.length; j++) {
                d2[data[j][header[0]]] = data[j][header[index]];
            }
            d.push(d2);

        }
        setDatosG2(d)
        setAuxT2(!auxT2)
    }



    function obtenerTotales(data) {
        let header1 = Object.keys(data[0]);
        let opt = data[0].length;
        let arr = [];

        for (let index = 1; index < header1.length; index++) {
            let totales = {};
            totales.name = [header1[index]][0];
            let sum = 0;
            data.map(item => {
                sum = sum + Number(item[header1[index]]);
            })
            totales.value = sum;
            arr.push(totales)
        }
        return arr;
    }


    function actualizarVariable(e) {
        datos[datos.length - 1].Variable = e.label
        //console.log(datos)
        setDatos(datos)
        setDatos(datos)
    }
    function actualizarVariable2(e) {
        datos2[datos2.length - 1].VARIABLE = e.label
        //console.log(datos2)
        setDatos2(datos2)
        setDatos2(datos2)
    }

    const descargarArchivo = () => {
        const pluginDescarga = hotTableComponent1.current.hotInstance.getPlugin("exportFile")
        pluginDescarga.downloadFile("csv", {
            filename: "tabla1",
            fileExtendion: "csv",
            mimeType: "text/csv",
            columnHeaders: true,
        });
    }

    function actualizarColT2() {

        let resume_table = document.getElementById("tabla2")
        let headers2 = []
        for (let index = 0; index < resume_table.rows[1].childNodes.length - 1; index++) {
            headers2.push(resume_table.rows[1].childNodes[index].innerText)
        }
        headers2.push(resume_table.rows[1].childNodes[resume_table.rows[1].childNodes.length - 1].childNodes[0].value)

        let dd2 = [];

        for (var i = 1, row; row = resume_table.rows[i]; i++) {
            let i = 1;
            let t2 = {}
            let colu = row.childNodes;
            t2.VARIABLE = colu[0].textContent;
            for (var j = 1; j < colu.length; j++) {
                t2[headers2[i]] = colu[j].childNodes[0].valueAsNumber;
                i++;
            }
            dd2.push(t2);
        }
        //console.table(dd2)
        //setHeader2(Object.keys(dd2[0]))
        obtenerDGrafcia(dd2);
        setDatos2(dd2);
    }


    function actualizarColT1() {
        let resume_table = document.getElementById("tabla1")
        let headers = []
        for (let index = 0; index < resume_table.rows[0].childNodes.length - 1; index++) {
            headers.push(resume_table.rows[0].childNodes[index].innerText)
        }
        headers.push(resume_table.rows[0].childNodes[resume_table.rows[0].childNodes.length - 1].childNodes[0].value)

        let dd2 = [];

        for (var i = 1, row; row = resume_table.rows[i]; i++) {
            let i = 1;
            let t2 = {}
            let colu = row.childNodes;
            t2.Variable = colu[0].textContent;
            for (var j = 1; j < colu.length; j++) {
                t2[headers[i]] = colu[j].childNodes[0].textContent;
                i++;
            }
            dd2.push(t2);

        }
        //setHeader(Object.keys(dd2[0]))
        //console.log(dd2)
        setDatos(dd2);

    }



    function prueba() {
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(datos),
            ws1 = XLSX.utils.json_to_sheet(datos2);

        XLSX.utils.book_append_sheet(wb, ws, "Tabla 1");
        XLSX.utils.book_append_sheet(wb, ws1, "Tabla 2");

        XLSX.writeFile(wb, "Reportes.xlsx");
    }

    function getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpeg");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }

    function crearPDF() {
        Swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'Generando Documento',
            showConfirmButton: false,
            timer: 3000
        })
        console.log("Se crea PDF")
        document.getElementById('encabezado').style.display = 'inline';
        document.getElementById('filas2').style.display = 'none';
        document.getElementById('filas1').style.display = 'none';
        document.getElementById('botonera').style.display = 'none';

        htmlToImage
            .toJpeg(document.getElementById("reporteP"), { quality: 0.95 })
            .then(function (dataUrl) {
                var doc = new jsPDF({ orientation: "l", format: "letter" });
                var img = new Image();
                img.onload = function () {
                    var dataURI2 = getBase64Image(img);
                    return dataURI2;
                };

                setTimeout(() => {
                    console.log("1 Segundo esperado");
                    img.src = "imgs/encabezado.jpg";
                    //doc.addImage(img.onload(), "JPEG", 10, 5, 190, 20);

                    doc.addImage(dataUrl, "JPEG", 10, 5, 260, 200);

                    if (
                        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                            navigator.userAgent
                        )
                    ) {
                        doc.save("Reporte");
                    } else {
                        doc.save("Reporte");
                    }
                }, 2000);

                setTimeout(() => {
                    console.log("2 Segundo esperado");
                    document.getElementById('filas2').style.display = 'flex';
                    document.getElementById('filas1').style.display = 'flex';
                    document.getElementById('botonera').style.display = 'flex';
                    document.getElementById('encabezado').style.display = 'none';

                }, 2000);

                //const link = document.createElement("a");
                //link.download = "Reporte.jpeg";
                //link.href = dataUrl;
                //link.click();
            })
            .catch(function (error) {
                console.error("oops, something went wrong!", error);
            });


    }

    var IDTABLA;
    function editarEncabezados(myID) {
        IDTABLA = myID;
        let tabla = document.getElementById(myID);
        //console.log(tabla)
        if (tabla !== null) {
            let filaEncabezados = tabla.getElementsByTagName("thead")[0].getElementsByTagName("tr")[0];

            // Iterar a través de los encabezados y reemplazarlos con inputs
            for (let i = 1; i < filaEncabezados.children.length; i++) {
                let th = filaEncabezados.children[i];
                let input = document.createElement("input");
                input.type = "text";
                input.value = th.textContent;
                th.textContent = ""; // Limpiar el contenido del th
                th.appendChild(input);

            }
            // Crear un botón de "Guardar" para confirmar los cambios
            var guardarBoton = document.createElement("button");
            guardarBoton.textContent = "Guardar";
            guardarBoton.onclick = guardarEncabezados;
            tabla.appendChild(guardarBoton);
        }

    }

    function guardarEncabezados() {
        //console.log(IDTABLA);
        let tabla = document.getElementById(IDTABLA);
        if (tabla !== null) {
            let filaEncabezados = tabla.getElementsByTagName("thead")[0].getElementsByTagName("tr")[0];

            // Iterar a través de los inputs y actualizar los encabezados
            for (let i = 1; i < filaEncabezados.children.length; i++) {
                let th = filaEncabezados.children[i];
                let input = th.firstChild;
                th.textContent = input.value;
            }

            // Eliminar el botón de "Guardar" una vez que se hayan guardado los cambios
            tabla.removeChild(tabla.lastChild);

            //actualizar()
            convertirTablaAJSON(IDTABLA)
        }
    }

    function convertirTablaAJSON(myId) {
        //console.log("----")
        let tabla = document.getElementById(myId);
        let filas = tabla.getElementsByTagName("tbody")[0].getElementsByTagName("tr");
        let encabezados = tabla.getElementsByTagName("thead")[0].getElementsByTagName("th");

        let datosP = [];

        for (let i = 0; i < filas.length; i++) {
            let fila = filas[i];
            let filaData = {};

            for (let j = 0; j < encabezados.length; j++) {
                let encabezado = encabezados[j].textContent;
                let celda;
                if (myId === "tabla1") {
                    celda = fila.getElementsByTagName("td")[j].textContent;
                    filaData[encabezado] = celda;
                } else {
                    if (j === 0) {
                        //console.log(fila.getElementsByTagName("td")[j].textContent)
                        celda = fila.getElementsByTagName("td")[j].textContent;
                        filaData[encabezado] = celda;
                    } else {
                        //console.log(fila.getElementsByTagName("td")[j])
                        celda = fila.getElementsByTagName("td")[j].childNodes[0].value;
                        filaData[encabezado] = celda;
                    }

                }

            }

            datosP.push(filaData);
        }

        //let jsonResult = JSON.stringify(datosP, null, 2);
        //console.log(datosP)
        if (myId === "tabla1") {
            setDatos(datosP)
            setHeader(Object.keys(datosP[0]))

        } else {
            setDatos2(datosP)
            obtenerDGrafcia(datosP)
            setHeader2(Object.keys(datosP[0]))
        }
        //console.log(jsonResult)
        //document.getElementById("jsonResult").textContent = jsonResult;
    }


    return (
        <div className="container-fluid">
            <Header></Header>
            <br />
            <section className="vh-99">
                <div className="container-fluid h-custom">
                    <div className="row justify-content-end">
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Modelo'}>
                                <button type="button" className="btn btn-secondary">M. Evaluación Financiera</button>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Reportes'}>
                                <button type="button" className="btn btn-secondary">Panel de Control</button>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Visualizador'}>
                                <button type="button" className="btn btn-secondary">Mapa</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <h2 className="titulo">Reportes</h2>

                        <div className="col-md-12 col-lg-12 col-xl-12 offset-xl-12" id="reporteP">
                            <div className="container-fluid shadows">
                                <div className='row' id="encabezado">
                                    <img src="imgs/encabezado.png" alt="" />
                                    <br />
                                </div>
                                <div className='row'>
                                    <div className="col-md-12 col-lg-12 col-xl-12">
                                        {
                                            datos !== undefined && (
                                                <table className="table table-hove" id="tabla1">
                                                    <thead>
                                                        <tr>
                                                            {
                                                                header.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                item.match(/column.*/) ? (
                                                                                    <>
                                                                                        <th key={`h${index}`} scope="col"><input id={`cT${index}`} type="text" defaultValue={item} onBlur={actualizarColT1} /></th>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <th key={`h${index}`} scope="col">{item}</th>
                                                                                    </>
                                                                                )
                                                                            }

                                                                        </>

                                                                    )
                                                                })
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            datos.map((item, index) => {
                                                                return (
                                                                    <tr key={`t${index}`} >
                                                                        {
                                                                            header.map((itemh, index) => {
                                                                                if (index === 0) {
                                                                                    if (item.Variable !== "") {
                                                                                        return (
                                                                                            <td scope="row" className='TH'>{item[itemh]}</td>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <td scope="row">
                                                                                                <Select
                                                                                                    className="basic-single"
                                                                                                    classNamePrefix="select"
                                                                                                    isLoading={true}
                                                                                                    isClearable={true}
                                                                                                    isSearchable={true}
                                                                                                    name="variable"
                                                                                                    options={variables}
                                                                                                    onChange={actualizarVariable}
                                                                                                />
                                                                                            </td>

                                                                                        )
                                                                                    }
                                                                                } else {
                                                                                    if (item[itemh] !== "" && item[itemh] !== undefined) {
                                                                                        return (
                                                                                            <td className='celda'>{item[itemh]}</td>
                                                                                        )


                                                                                    } else {
                                                                                        return (
                                                                                            <td className='celda'>
                                                                                                <input id={`number3${index}`} type="number" onBlur={() => actualizar()} />
                                                                                            </td>

                                                                                        )
                                                                                    }

                                                                                }
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='row' id="filas1">
                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                        <button type="button" className="btn btn-secondary" onClick={agregarFila}>Agregar Fila</button>
                                    </div>
                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                        <button type="button" className="btn btn-secondary" onClick={agregarColumna}>Agregar Columna</button>
                                    </div>
                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                        <button type="button" className="btn btn-secondary" onClick={() => editarEncabezados("tabla1")}>Editar Encabezados</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                        <div className='row'>
                                            <div className='col-12'>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                {
                                                    (datos !== undefined && datos.length > 0) && (
                                                        <Graficos2 d={datos} tipo={"barras"} variable={"Variable"} aux={auxT}></Graficos2>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-xl-6">
                                        <div className='row'>
                                            <div className='col-12'>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12'>
                                                {
                                                    (totales !== undefined && totales.length > 0) && (
                                                        <>
                                                            <Graficos2 d={totales} tipo={"pie"} variable={"variable"}></Graficos2>
                                                        </>

                                                    )
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <br />
                                <br />
                                <div className='row'>

                                    <div className="col-md-8 col-lg-8 col-xl-8">

                                        {
                                            datos2 !== undefined && (
                                                <>
                                                    <table class="table table-striped" id="tabla2">
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    header2.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    item.match(/column.*/) ? (
                                                                                        <th className='celda'><input id={`cT${index}`} type="text" defaultValue={item} onBlur={actualizarColT2} /></th>
                                                                                    ) : (
                                                                                        <th key={`p${index}`} scope="col">{item}</th>
                                                                                    )
                                                                                }

                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                datos2.map((item, index) => {
                                                                    //console.log(datos2)
                                                                    return (
                                                                        <tr key={`n${index}`} >
                                                                            {
                                                                                header2.map((itemh, index) => {
                                                                                    if (index === 0) {
                                                                                        if (item.VARIABLE !== "") {
                                                                                            return (
                                                                                                <td className="TH" scope="row">{item[header2[0]]}</td>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <td scope="row">
                                                                                                    <Select
                                                                                                        className="basic-single"
                                                                                                        classNamePrefix="select"
                                                                                                        isLoading={true}
                                                                                                        isClearable={true}
                                                                                                        isSearchable={true}
                                                                                                        name="variable"
                                                                                                        options={variables}
                                                                                                        onChange={actualizarVariable2}
                                                                                                    />
                                                                                                </td>

                                                                                            )
                                                                                        }

                                                                                    } else {
                                                                                        if (item[itemh] !== "") {
                                                                                            return (
                                                                                                <td className='celda'><input id={`number1${index}`} type="number" defaultValue={item[itemh]} onBlur={actualizarT2} /></td>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <td className='celda'><input id={`number1${index}`} type="number" defaultValue={item[itemh]} onBlur={actualizarT2} /></td>
                                                                                            )

                                                                                        }
                                                                                    }
                                                                                })
                                                                                //  <th scope="row">{item[header2[0]]}</th>
                                                                                /*
                                                                                <td className='celda'><input id={`number1${index}`} type="number" defaultValue={item[header2[1]]} onBlur={actualizarT2} /></td>
                                                                            <td className='celda'><input id={`number1${index}`} type="number" defaultValue={item[header2[2]]} onBlur={actualizarT2} /></td>
                                                                            <td className='celda'><input id={`number2${index}`} type="number" defaultValue={item[header2[3]]} onBlur={actualizarT2} /></td>
                                                                            <td className='celda'><input id={`number3${index}`} type="number" defaultValue={item[header2[4]]} onBlur={actualizarT2} /></td>
                                                                                */
                                                                            }



                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </>
                                            )
                                        }

                                    </div>
                                    <div className="col-md-4 col-lg-4 col-xl-4">
                                        <div className='row'>
                                            <div className='col-12'>
                                                {
                                                    (datosG2 !== undefined && datosG2.length > 0) && (
                                                        <Graficos2 d={datosG2} tipo={"compuesta"} aux2={auxT2} variable={"variable"}></Graficos2>
                                                    )
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className='row' id="filas2">
                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                        <button type="button" class="btn btn-secondary" onClick={agregarFila2}>Agregar Fila</button>
                                    </div>
                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                        <button type="button" class="btn btn-secondary" onClick={agregarColumna2}>Agregar Columna</button>
                                    </div>
                                    <div className="col-md-3 col-lg-3 col-xl-3">
                                        <button type="button" className="btn btn-secondary" onClick={() => editarEncabezados("tabla2")}>Editar Encabezados</button>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <div className="row botonera" id="botonera">
                                    <div className='col-md-1 col-lg-1 col-xl-1 icono'>
                                        <FontAwesomeIcon
                                            icon={faDownload}
                                            size="2x" />
                                    </div>
                                    <div className='col-md-2 col-lg-2 col-xl-2'>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" class="btn btn-secondary" onClick={() => crearPDF()}>PDF</button>
                                            <button type="button" class="btn btn-secondary" onClick={() => prueba()}>Excel</button>
                                            <button type="button" class="btn btn-secondary" onClick={() => guardarDatos1()}>Guardar</button>

                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <br />






                </div >
            </section >
        </div >
    )
}

export default Reportes