/* eslint-disable array-callback-return */
class Prestadores {

    getDatosReporteTipo(data) {
       //console.log(data);
        let aux = [];
        data.map(item => {
            if (item.nombreprestador !== "") {
                let valores = {};
                valores.id=item.id;
                valores.prestador = item.nombreprestador;
                valores.abreviatura = item.abreviaturaprestador;
                valores.habitantes = item.habitantesmiles;
                valores.tipoprestador = item.tipoprestador;
                valores.entidad = item.entidad;
                aux.push(valores);
            }
        })
        aux.sort();
        //console.log(aux);
        return aux;
    }

   


    getDatosReporteMun(data){
        //console.log(data);
        let aux = [];
        data.map(item => {
            if (item.nombreprestador !== "") {
                let valores = {};
                valores.id=item.id;
                valores.prestador = item.nombreprestador;
                valores.abreviatura = item.abreviaturaprestador;
                valores.habitantes = item.habitantesmiles;
                valores.tipoprestador = item.tipoprestador;
                valores.tomasmiles = item.tomasmiles;
                valores.habitantesmiles = item.habitantesmiles;
                valores.entidad = item.nombremun;
                aux.push(valores);
            }
        })
        //aux.sort().reverse();
        this.sortJSON(aux, 'tipoprestador', 'desc');
        //console.log(aux);
        return aux;
        
    }

    getDatosTam(data){
        //console.log(data);
        let header = Object.keys(data[0]);
        data.map(item=>{
            for (let index = 0; index < header.length; index++) {
                if(item[header[index]]==="null"){
                    item[header[index]]='';
                }                
            }

        });
        data.sort().reverse();
        return data;
    }

    sortJSON(data, key, orden) {
        return data.sort(function (a, b) {
            var x = a[key],
            y = b[key];
    
            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
    
            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }

}

export default Prestadores;