import { ConnectingAirportsOutlined } from "@mui/icons-material";

class Cuencas {

    getDatosRHA(data){
       //console.log(data)

        let finales =[]
        data.map(item=>{
            let aux={}
            aux['claverha']=item.claverha;
            if(item.con_disponibilidad!==null){
                aux['con_disponibilidad']=parseInt(item.con_disponibilidad, 10);
            }else{
                aux['con_disponibilidad']=0;
            }

            if(item.sin_disponibilidad!==null){
                aux['sin_disponibilidad']=parseInt(item.sin_disponibilidad, 10);
            }else{
                aux['sin_disponibilidad']=0
            }
            
            
            finales.push(aux);
        })
        //this.sortJSON(finales,'claverha','asc')
        //this.ordenarRomanos(finales,'claverha')
        //console.log(finales);
        return this.ordenarRomanos(finales,'claverha');

    }

    getDatosRH(data){
        //console.log(data)

        let finales =[]
        data.map(item=>{
            let aux={}
            aux['claverh']=item.claverh;
            if(item.con_disponibilidad!==null){
                aux['con_disponibilidad']=parseInt(item.con_disponibilidad, 10);
            }else{
                aux['con_disponibilidad']=0;
            }

            if(item.sin_disponibilidad!==null){
                aux['sin_disponibilidad']=parseInt(item.sin_disponibilidad, 10);
            }else{
                aux['sin_disponibilidad']=0
            }
            
            
            finales.push(aux);
        })
        //console.log(finales);
        return  finales;//this.ordenarRomanos(finales,'claverh');

    }

    getDatosOrdenamiento(data){

        //console.log(data)

        let finales =[]
        data.map(item=>{
            let aux={}
            aux['tipoordenamiento']=item.tipoordenamiento;
            aux['totalcuencas']=item.totalcuencas;
            
            
            
            finales.push(aux);
        })
        return finales;
    }

    quitarNull(data){
        let header = Object.keys(data[0]);
        data.map(item=>{
            for (let index = 0; index < header.length; index++) {
                if(item[header[index]]==="NULL"){
                    item[header[index]]='';
                }                
            }

        });
        //data.sort().reverse();
        //this.sortJSON(data, 'idppgrupo', 'desc');
        return data;

    }

    sortJSON(data, key, orden) {
        return data.sort(function (a, b) {
            var x = a[key],
            y = b[key];
    
            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
    
            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }

    ordenarRomanos(data,key){
        //console.log(data)
        let valores=["I","II","III","IV","V","VI","VII","VIII","IX","X","XI","XII","XIII"];
        let v1,v2;
        
        let aux=[];
        for (let index = 0; index < valores.length; index++) {
            // eslint-disable-next-line no-loop-func
            data.map(item=>{
                v1=item[key];
                v2=valores[index];
                if(v1===v2){
                    aux.push(item);
                }
            })
            
        }

        //console.log(aux)
        return aux;
    }



}

export default Cuencas;
