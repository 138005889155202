class DistritosRiego {

    getDatosG1(data) {
        let estados = [];
        // eslint-disable-next-line array-callback-return
        data.map(item => {
            if (estados.length === 0) {
                estados.push(item['edo']);
                //anios.push(item['anio']);
            } else {
                if (!estados.includes(item['edo'])) {
                    estados.push(item['edo']);
                }

            }
        });
        let final = [];

        for (let index = 0; index < estados.length; index++) {
            let aux = {};
            aux.riego = 0;
            aux.temporal = 0;
            for (let index2 = 0; index2 < data.length; index2++) {
                if (data[index2]['edo'] === estados[index]) {
                    aux.estado = estados[index];

                    if (data[index2]['modalidadc'] === "Riego") {
                        aux.riego = data[index2]['spcosechada']
                    } else {
                        if (data[index2]['modalidadc'] === "Temporal") {
                            aux.temporal = data[index2]['spcosechada']
                        }
                    }
                }
            }
            final.push(aux)

        }

        final.map(item => {
            item['riego'] = Number(item['riego'].toFixed(2));
            item['temporal'] = Number(item['temporal'].toFixed(2));
        });
        //console.log(final);
        return final;
    }

    getDatosG2(data) {
        let estados = [];
        // eslint-disable-next-line array-callback-return
        data.map(item => {
            if (estados.length === 0) {
                estados.push(item['anioagricola']);
                //anios.push(item['anio']);
            } else {
                if (!estados.includes(item['anioagricola'])) {
                    estados.push(item['anioagricola']);
                }

            }
        });

        let final = [];

        for (let index = 0; index < estados.length; index++) {
            let aux = {};
            aux.riego = 0;
            aux.temporal = 0;
            for (let index2 = 0; index2 < data.length; index2++) {
                if (data[index2]['anioagricola'] === estados[index]) {
                    aux.anio = estados[index];
                    //aux.riego=0;
                    //aux.temporal=0;
                    if (data[index2]['modalidadc'] === "Riego") {
                        aux.riego = data[index2]['spcosechada']
                    } else {
                        if (data[index2]['modalidadc'] === "Temporal") {
                            aux.temporal = data[index2]['spcosechada']
                        }
                    }
                }
            }
            final.push(aux)
        }


        final.map(item => {
            item['riego'] = Number(item['riego'].toFixed(2));
            item['temporal'] = Number(item['temporal'].toFixed(2));
        });

        //console.log(final);
        return final;
    }

}

export default DistritosRiego;