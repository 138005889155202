/* eslint-disable no-multi-str */
import React, { useState, useEffect, useContext } from "react";

import {
  MapContainer,
  ScaleControl,
  LayersControl,
  TileLayer,
  ZoomControl,
  WMSTileLayer,
  useMap,
  FeatureGroup
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw"
import 'leaflet-draw/dist/leaflet.draw.css'
import {
  faFileCsv,
  faTrashAlt,
  faTable,
  faClone,
  faGlobeAmericas,
  faInfo,
  faShapes,
  faMoneyCheck,
  faMoneyBillAlt,
  faDollarSign,
  faLeaf,
  faVial,
  faArrowUp,
  faArrowCircleUp,
  faTint,
  faCloudRain,
  faIndustry,
  faHotTub,
  faMagnet,
  faCompressArrowsAlt,
  faBorderStyle,
  faFileAlt,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import {
  Form,
  Button,
  OverlayTrigger,
  Card,
  Accordion,
  Table,
  Modal,
} from "react-bootstrap";
import {
  DragDropContext,
  Droppable,
  Draggable as DraggableDnd,
  resetServerContext,
} from "react-beautiful-dnd";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowRestore } from "@fortawesome/free-regular-svg-icons";
import Draggable from "react-draggable";
import ModalDialog from "react-bootstrap/ModalDialog";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  CartesianGrid,
  PieChart,
  Pie,
  Sector,
  Cell,
  Legend,
  Label,
  ReferenceLine,
  Brush,
  ScatterChart,
  Scatter,
} from "recharts";
import Select from "react-select";
import Swal from "sweetalert2";
import { ContextoCreadoFeature } from '../componentes/context/contextoFeatureGroupDibujadas'
import $ from "jquery";
import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import "leaflet/dist/leaflet.css";
import "./jquery-sidebar.js";
import "./leaflet-sidebar.js";
import estilosC from "../json/estilos";
import categorias from "../json/categoriasLeyendas";
import datosM from "../json/datosM";
import * as turf from "@turf/turf";
import Repda from "./Clases/Repda";
import Calidad from "./Clases/Calidad";
import Poblacion from "./Clases/Poblacion";
import parametro from "../json/parametros";
import colores from "../json/colores";
import reportePDF from "./ReportePDF";
import pdf from "./DocumentoPDF";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet-geosearch/dist/geosearch.css";
//----------------------JSON----------------------
import Recaudacion from "./Clases/Recaudacion.js";
import Simbologia from "./Clases/Simbologia.js";
import prestadores from "./Clases/Prestadores";
import Presup from "./Clases/Presupuesto";
import TableData from "../componentes/datatabe/Datatable";
import Distritos from "./Clases/DistritosRiego"
import Imagen from "./Clases/Imagen"
import {
  reportePresupuestoC,
  reporteCapitulosT,
  reporteRerionT,
  reporteRecPreT,
  prestadoresEntidad,
  sihbandas,
  prestadoresTamanioT,
  prestadoresMunicipios,
  estados,
  concentradoRHA,
  concentradoDR,
  supSembrada,
  cuencaRHA,
  cuencaRH,
  reportePresAcu,
  compoenentesSC,
  caudales,
  volumenes,
  precipitacionNM,
  precipitacionN,
  precipitacionRHA,
  precipitacionRHAAnual,
  propuestaOcavam,
  ciclonesMexico,
  ciclonesCosta,
  PresupuestoAcumulado,
  repObras,
  repinversion
} from "../json/dataSource";
import { aliasDescargaCSV } from '../json/aliasDescargaCSV'
import Graficas from "../componentes/graficas/Grafica";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import BloodtypeOutlinedIcon from "@mui/icons-material/BloodtypeOutlined";
import HouseboatOutlinedIcon from "@mui/icons-material/HouseboatOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";

import Generales from "./Clases/Generales";
import ciclo from "../json/ciclo.json";
import paramCapas from "../json/ParametrosCapas.json";
import cuerpos from "../json/cuerpos.json";
import Capas from "./Clases/Capas";
import { Collapse, CardBody } from "reactstrap";
import aniosCenso from "../json/aniosCensos.json";
import cultivos from "../json/cultivos.json"
import participantes from "../json/aquarating.json"
import dr from '../json/distritos.json';
import grupoPres from '../json/presupuesto.json';
import "../css/opciones/style.css";


import {
  aniosPob,
  aniosPresupuesto,
  anioscuencas,
  anioscuencasZP,
  anioscuencasOV,
  aniosDistritos,
  aniosacuif,
  aniosCalidad2,
  aniosSIH,
  mesesSIH,
  aniosRecaudacion,
  aniosgrafPresup,
  aniosCutzamala,
  aniosprecanual,
  aniosprecnormal,
  aniosciclones,
  dias,
  MesPresupuesto,
  aniosPresupuestoMesual,
  aniosmodernizacion
} from "../json/anios";
//cambio
import { Spinner } from 'spin.js';
import InfoCapas from "./Clases/PopUpCapas";
import { aliasTablas } from "../json/atributos";
import Cuencas from "./Clases/Cuencas.js";
import consumoG from "./../json/consumoG.json"
import propuesta from "./../json/propuesta.json"
import Precipitacion from "./Clases/Precipitacion.js";
import Ciclones from "./Clases/Ciclones.js"
import acumulados from '../json/Acumulado'
import mensual from '../json/PresMensual.json'
import ejercidoG from '../json/ejercidoGraf.json'
import modernizacion from '../json/Modernizacion/presas.json'
import inversionEstadoG from '../json/Modernizacion/m20_vwginversionestado.json'
import obrasrehabilitadasG from '../json/Modernizacion/m20_vwgobrasrehabilitadas.json'
import inversionEstador from '../json/Modernizacion/m20_vwrinversionestado.json'
import obrasrehabilitadasR from '../json/Modernizacion/m20_vwrobrasrehabilitadas.json'

import estadosD from '../json/entidades.json'
import municipios2 from '../json/municipios.json'

var opts = {
  lines: 20, // The number of lines to draw
  length: 72, // The length of each line
  width: 12, // The line thickness
  radius: 41, // The radius of the inner circle
  scale: 1.1, // Scales overall size of the spinner
  corners: 0.9, // Corner roundness (0..1)
  speed: 1.4, // Rounds per second
  rotate: 48, // The rotation offset
  animation: 'spinner-line-fade-more', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  color: '#ffff00', // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  top: '49%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  shadow: '0 0 1px transparent', // Box-shadow for the lines
  zIndex: 2000000000, // The z-index (defaults to 2e9)
  className: 'spinner', // The CSS class to assign to the spinner
  position: 'absolute', // Element positioning
};



const { BaseLayer } = LayersControl;
var colorF = 0;
var featureSeleccion = [];
var zIndexCapas = 5;
var numeroIndex = 300;
var info, _div;
var ColorSeleccion = "#eff91d";
var bordeSeleccion = "#fbe907";
//https://geosinav30.conagua.gob.mx:8080/geoserver/Sina/ows
//const root =  "https://geosinav30.conagua.gob.mx:8080/geoserver/gwc/service/wms/geoserver/Sina/ows";
const root = 'https://geosinav30.conagua.gob.mx:8080/geoserver/Sina/ows';
//const root1 = 'https://localhost:8080/geoserver/Sina/ows'
//const root = "https://201.116.60.73:8080/geoserver/gwc/service/wms"; //GeoWebCache
//const rootApi = "http://172.29.149.73:8080/SINA3/"; // url de servidor de desarrollo
const rootApi = "http://localhost:8081/"; //url de local
//const rootApi = "https://sinav30.conagua.gob.mx:8080/SINA32/"; //url de servidor de produccion
const rootApi1 = "https://sinav30.conagua.gob.mx:8080/ReporteSIH/";
const rootApi2 = "https://sinav30.conagua.gob.mx:8080/SINA31/";

const provider = new OpenStreetMapProvider();

const searchControl = new GeoSearchControl({
  provider: provider,
  style: 'bar',
  showMarker: true, // optional: true|false  - default true
  showPopup: false, // optional: true|false  - default false
  marker: {
    //optional: L.Marker(),    //- default L.Icon.Default
    icon: L.icon({
      iconUrl: "imgs/markers.png",
      iconSize: [30, 30],
    }),
    draggable: false,
  },
});

let defaultParameters = {
  service: "WFS",
  version: "1.0.0",
  request: "GetFeature",
  outputFormat: "application/json",
  srsName: "EPSG:4326",
};
var opcion;
var Sim = new Simbologia();
var cap = new Capas();
var pobla = new Poblacion();
var infoCapas = new InfoCapas();
var imagen = new Imagen();
var ciclon = new Ciclones();
var myAPIKey = "e638c501956b485b8d3938247d2579d0";
var marker = null;


var MarkerOptions = {
  radius: 1,
  fillColor: colorF,
  color: "#000000",
  weight: 1,
  opacity: 1,
  fillOpacity: 0.8,
};

function MapView(props) {
  opcion = "base";

  //Parte del codigo que hace referencia al mapa
  const [mapaReferencia, setmapaReferencia] = useState(null);
  //Para guardar las capas que se van a mostrar
  const [capasVisualizadas, setCapasVisualizadas] = useState([]);
  const [featuresBuffer, setFeaturesBuffer] = useState([]);
  const [leyVisualizadas, setLeyVisualizadas] = useState([]);
  const [sublayerSeleccionada, setSublayerSeleccionada] = useState([]);
  const [entidades, setEntidades] = useState([]);
  const [showModalGraficas, setShowModalGraficas] = useState(false);
  //funciones para leyendasVisualizadas
  var leyendasVisualizadas = [];
  //Para guardar los atributos
  const [atributos, setAtributos] = useState([]);
  //Para mostrar el modal de atributos 

  const [showModalAtributos, setShowModalAtributos] = useState(false);
  const [showModalPDF, setShowModalPDF] = useState(false);
  const [tipoCalidad, setTipoCalidad] = useState([
    { value: "1", label: "Por parametro" },
    { value: "2", label: "Por tipo de cuerpo" },
  ]);
  const [tipoCalidad1, setTipoCalidad1] = useState();

  const featureContexto = useContext(ContextoCreadoFeature)

  //const [aniosRecaudacion, setaniosRecaudacion] = useState();
  //Para guardar las referencias del mapa
  useEffect(() => {

    if (mapaReferencia !== null) {
      setEntidadSelect(null);
      L.control.sidebar("sidebar").addTo(mapaReferencia);
      mapaReferencia.addControl(searchControl);
      info = L.control();
      _div = L.DomUtil.create("div", "info"); // create a div with a class "info"
      //var point = turf.point([-90.548630, 14.616599]);
      //var buffered = turf.buffer(point, 500);
      //var bufferedLayer = L.geoJSON(buffered, { interactive: false });
      //bufferedLayer.setZIndex = 0;
      //mapaReferencia.addLayer(bufferedLayer)


      setmapaReferencia(mapaReferencia);
      if (colapsarMonitoreo) {
        fecha();
      }
      if (opcion === "base") {
        setColapsarCB(true);
      }
      if (opcion === "repda") {
        fetch(`${rootApi}repda/anios`)
          .then((response) => response.json())
          .then((data) => {
            let aux = [];
            data.map((item, index) => {
              let obt = {};
              obt.id = index;
              obt.value = item;
              obt.label = item;
              aux.push(obt);
            });
            setAniosRepda(aux);
          });
        setColapsarRepda(true);
      }
      if (opcion === "calidad") {
        setColapsarCalidad(true);
      }
      if (opcion === "poblacion") {
        setColapsarPob(true);
      }
      if (opcion === "cuencas") {
        setColapsarCuencas(true);
      }
      if (opcion === "acuiferos") {
        setColapsarAcuiferos(true);
      }
      if (opcion === "distritosr") {
        setColapsarDistritos(true);
      }
      if (opcion === "recaudacion") {
        setColapsarRecaudacion(true);
      }
      if (opcion === "monitoreo") {
        var hoy = new Date();
        if (hoy.getHours() < 13) {
          let aux = sumarDias(hoy, -1);
          setFechaConsulta(aux)
          //console.log(formatDate(aux));
        } else {
          setFechaConsulta(formatDate(hoy))
          //console.log(formatDate(hoy));
        }

        setColapsarMonitoreo(true);
      }
      if (opcion === "presupuesto") {
        setColapsarPresupuesto(true);
      }
      if (opcion === "prestadores") {
        setColapsarPrestadores(true);
      }
      if (opcion === "SIH") {
        setColapsarSIH(true);
      }
      if (opcion === "cutzamala") {
        setColapsarCutzamala(true);
      }
      if (opcion === "precipitacion") {
        setColapsarPrec(true);
      }
      if (opcion === "ciclones") {
        setColapsarCiclones(true);
      }
      if (opcion === "modernizacion") {
        setAnioModernizacion([])
        setColapsarK111(true);
      }
      //se hace la peticion para cargar el select de estados
      fetch(`${rootApi}capasBase/estados?anio=2020`)
        .then((response) => response.json())
        .then((data) => setEntidades(data));
      //cargarCombos();
      setParamCapa([]);
    }
  }, [mapaReferencia]);


  useEffect(() => {
    let lDrLo = L.drawLocal;
    lDrLo.draw.toolbar.buttons.polyline = "Dibujar una linea"
    lDrLo.draw.toolbar.buttons.polygon = "Dibujar un poligono"
    lDrLo.draw.toolbar.buttons.marker = "Dibujar un marcador"
    lDrLo.draw.toolbar.buttons.rectangle = "Dibujar un rectangulo";
    lDrLo.draw.handlers.rectangle.tooltip.start = "Mantener clic y arrastrar para dibujar";
    lDrLo.draw.handlers.circle.tooltip.start = "Mantener clic y arrastrar para identificar capas";
    lDrLo.draw.handlers.circle.radius = "Radio";
    lDrLo.draw.handlers.simpleshape.tooltip.end = "Dejar de hacer clic para finalizar";
    lDrLo.draw.handlers.polyline.error = "<strong>¡Error:</strong> las esquinas de la forma no se pueden cruzar!"
    lDrLo.draw.toolbar.actions.title = "Cancelar dibujo"
    lDrLo.draw.toolbar.actions.text = "Cancelar"
    lDrLo.draw.toolbar.finish.title = "Finalizar dibujo"
    lDrLo.draw.toolbar.finish.text = "Finalizar"
    lDrLo.draw.toolbar.undo.title = "Borrar último punto dibujado"
    lDrLo.draw.toolbar.undo.text = "Borrar último punto"
    lDrLo.draw.handlers.marker.tooltip.start = "Clic en el mapa para poner un marcador"
    lDrLo.draw.handlers.polygon.tooltip.start = "Clic para empezar a dibujar una forma"
    lDrLo.draw.handlers.polygon.tooltip.cont = "Clic para continuar dibujando la forma"
    lDrLo.draw.handlers.polygon.tooltip.end = "Clic en el primer punto para cerrar esta forma"
    lDrLo.draw.handlers.polyline.tooltip.start = "Clic para empezar a dibujar una linea"
    lDrLo.draw.handlers.polyline.tooltip.cont = "Clic para continuar dibujando la linea"
    lDrLo.draw.handlers.polyline.tooltip.end = "Clic en el último punto para finalizar la linea"
    lDrLo.edit.toolbar.actions.save.title = "Guardar cambios"
    lDrLo.edit.toolbar.actions.save.text = "Guardar"
    lDrLo.edit.toolbar.actions.cancel.title = "Cancelar edición, descartar todos los cambios"
    lDrLo.edit.toolbar.actions.cancel.text = "Cancelar"
    lDrLo.edit.toolbar.actions.clearAll.title = "Limpiar todo"
    lDrLo.edit.toolbar.actions.clearAll.text = "Limpiar todo"
    lDrLo.edit.toolbar.buttons.edit = "Editar polígonos"
    lDrLo.edit.toolbar.buttons.editDisabled = "No hay elementos para editar"
    lDrLo.edit.toolbar.buttons.remove = "Limpiar mapa"
    lDrLo.edit.toolbar.buttons.removeDisabled = "No hay elementos para eliminar"
    lDrLo.edit.handlers.edit.tooltip.text = "Arrastre controladores o marcadores para editar características"
    lDrLo.edit.handlers.edit.tooltip.subtext = "Clic en cancelar para deshacer los cambios"
    lDrLo.edit.handlers.remove.tooltip.text = "Clic en la figura para borrarla"

  }, [])

  const [sigueEjecutando, setSigueEjecutando] = useState(true)

  function grupoDibujos(e) {

    if (e != null && sigueEjecutando === true) {
      setSigueEjecutando(false)
      setTimeout(() => {
        featureContexto.setValorFeature(e)
      }, 5000)
    }
  }



  //funcion para cargar capas Base 
  //const checkServerWithSugar = async (url) => {
  //const mostrarCapasBase = async (name, titulo, filtro) => {

  const [cerrarMensaje, setCerrarMensaje] = useState(false);





  function buscarCapa(name) {
    //1- encontrada 0-noencontrada
    let siesta = null;
    if (capasVisualizadas !== undefined) {
      //let siesta = null;
      capasVisualizadas.forEach((element, index) => {
        if (element.nombre === name) {
          siesta = index;
        }
      });
      return siesta;
    } else {
      return siesta;
    }

  }

  function highlightFeature(e) {
    var layer = e.target;
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    let aux = buscarAlias(layer.feature);
    actualizarLeyenda(aux, layer.feature.nombre_capa);
    update(aux);
  }

  function update(props) { }

  function actualizarLeyenda(props, layer) {
    info.onAdd = function (map) {
      let str = "";
      if (props !== undefined) {
        let aux = Object.keys(props);
        str = "";
        aux.map((item, index) => {
          str = str + "<b>" + item + ": </b>" + props[aux[index]] + "<br />";
        });
      }
      _div.innerHTML =
        "<div id=" + layer + "><h4>Información</h4>" + str + "</div>";
      return _div;
    };

    info.addTo(mapaReferencia);
  }

  /**Funcion para buscar el alias de los campos a mostrar */
  function buscarAlias(data) {
    let nombre = data.id.split(".");
    let index = buscarIndex(nombre[0]);
    let longitud = Object.keys(datosM[index]).length;
    let proper = {};
    let datos = datosM[index];
    for (let i = 1; i < longitud; i++) {
      let f1 = datos[`campo${i}`].split("-");
      proper[f1[1]] = data.properties[f1[0]];
    }
    return proper;
  }

  //Funcion para buscar el index de una capa
  function buscarIndex(capa) {
    let index = -1;
    datosM.map((capaB, i) => {
      if (capaB.capa === capa) {
        index = i;
      }
    });
    return index;
  }

  //Cuando se renderiza el lado del servidor (SSR). Garantiza que el estado del contexto no persista en varias representaciones en el servidor, lo que provocaría discrepancias en las marcas de cliente / servidor después de que se presenten varias solicitudes en el servidor
  resetServerContext();
  //Funcion para ordenar los nuevos datos
  function handleOnDragEnd(result) {
    // Si el destino existe, esto es para evitar cuando se arrastra fuera del contenedor
    if (!result.destination) {
      return;
    }
    // Se crea una copia de capasVisualizadas
    let items = Array.from(capasVisualizadas);
    // Lo eliminamos de acuerdo al index que le pasa
    let [reorderedItem] = items.splice(result.source.index, 1);
    // Se usa destination.index para agregar ese valor a su nuevo destino
    items.splice(result.destination.index, 0, reorderedItem);

    //Para reordenar el z index de las capas
    items.map((valor, index) => {
      mapaReferencia.getPane(valor.layer.options.pane).style.zIndex =
        numeroIndex + items.length - index - 1;
    });

    // Actualizamos datos entidades
    setCapasVisualizadas(items);
  }

  /**Funcion que habilita o deshabilita las capas */
  function cambiaCheck({ target }) {
    let capasActualizadas = capasVisualizadas.map((capa) => {
      if (capa.nombre === target.value) {
        if (capa.habilitado) {
          capa.habilitado = false;
          mapaReferencia.removeLayer(capa.layer);
          quitarLeyenda(capa);
          return capa;
        } else {
          capa.habilitado = true;
          mapaReferencia.addLayer(capa.layer);
          desplegarLeyendasActivas(capa, capa.funcion);
          return capa;
        }
      } else {
        return capa;
      }
    });
    setCapasVisualizadas(capasActualizadas);
  }
  /*Funciones para el modal */
  //Para agregar la funcionalidad de mover modal
  function DraggableModalDialog(props) {
    return (
      <Draggable handle=".modal-movible">
        <ModalDialog {...props} />
      </Draggable>
    );
  }

  //Funcionalidad de minimizar el modal
  function minimizaModal(e) {
    let modalCompleto = $(e.target).closest(".modal");
    $(modalCompleto).toggleClass("modal-min");
    if ($(modalCompleto).hasClass("modal-min")) {
      $(modalCompleto)
        .find(".modal-content")
        .removeClass("modal-redimensionable");
      $(modalCompleto).find(".modal-header").css("pointer-events", "none");
    } else {
      $(modalCompleto).find(".modal-content").addClass("modal-redimensionable");
      $(modalCompleto).find(".modal-header").css("pointer-events", "initial");
    }
  }

  //Cambia la transparencia de las capas
  const transparenciaCapas = ({ target }) => {
    //Hace copia a otro arreglo para volver a sobreescribir capasVisualizadas
    let capasVisualisadasActualizado = capasVisualizadas.map((valor) => {
      //Si es igual a la entidad que se envia, se cambia la transparencia
      if (valor.titulo === target.name) {
        valor.transparencia = target.value;
        if (
          valor.tipo === "wfs" ||
          valor.tipo === "kml" ||
          valor.tipo === "json"
        ) {
          valor.layer.setStyle({
            opacity: valor.transparencia,
            fillOpacity: valor.transparencia,
          });
        } else if (valor.tipo === "wms") {
          valor.layer.setOpacity(valor.transparencia);
        }
        return valor;
      }
      // Si no es igual a la entidad que se envia, se envia con los mismos valores
      else {
        return valor;
      }
    });
    setCapasVisualizadas(capasVisualisadasActualizado);
  };

  function elimiarCapa(capa) {
    mapaReferencia.removeLayer(capa.layer);
    let arrTemp = capasVisualizadas.filter(
      (capaArr) => capaArr.titulo !== capa.titulo
    );
    //Para reordenar el z index de las capas
    arrTemp.map((valor, index) => {
      mapaReferencia.getPane(valor.layer.options.pane).style.zIndex =
        numeroIndex + arrTemp.length - index - 1;
    });
    setCapasVisualizadas(arrTemp);

    let cont = validarCapas(capa)
    //console.log(cont);
    if (cont > 1) {
      let paneRemove = mapaReferencia.getPane(capa.layer.options.pane);
      paneRemove.remove();
      //quitarLeyenda(capa);
    } else {
      let paneRemove = mapaReferencia.getPane(capa.layer.options.pane);
      paneRemove.remove();
      quitarLeyenda(capa);
    }



  }

  function validarCapas(capa) {
    //console.log(capa)
    let cont = 0;

    capasVisualizadas.map(item => {
      if (capa.leyenda === item.leyenda && item.habilitado === true) {
        cont++;
      }
    })

    return cont;


  }

  function infoP(capa) {
    let targetURL = "https://sinav30.conagua.gob.mx:8080/Presas/";
    let newURL = document.createElement("a");
    newURL.href = targetURL;
    document.body.appendChild(newURL);
    newURL.click();
  }

  function copiarFeatures(data) {

    let aux1 = [];
    data.map(item => {
      let d = {};
      for (let key in item) {
        d[key] = item[key];
      }
      aux1.push(d);
    })
    let headers
    //console.log(aux1[0])
    if (aux1[0].properties !== undefined) {
      headers = Object.keys(aux1[0].properties);

      aux1.map(item => {
        headers.map(campo => {

          if (isNaN(item.properties[campo])) {
            //console.log("No es numero");
          } else {
            if (item.properties[campo] - Math.floor(item.properties[campo]) === 0) {
              if (omitirFormato(campo) === 1) {
                //item.properties[campo] = item.properties[campo];//formatCurrency.format(item.properties[campo])//
              }
            } else {
              //console.log("Es un numero decimal");
              if (omitirFormato(campo) === 0) {
                item.properties[campo] = Number(item.properties[campo]).toFixed(2);//formatCurrency.format(item.properties[campo].toFixed(2))//item.properties[campo].toFixed(2);//new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.properties[campo].toFixed(2));//item.properties[campo].toFixed(2);
              }
            }
          }
        });
      });
    } else {
      headers = aux1[0];
      aux1.map(item => {
        //console.log(item);
      })
    }





    return aux1;
  }

  function datosDescargaCVS(data) {
    let aux1 = [];
    data.map(item => {
      let d = {};
      for (let key in item.properties) {
        d[key] = item.properties[key];
      }
      aux1.push(d);
    })

    //console.log(aux1);
    return aux1;

  }

  function omitirFormato(campo) {
    if (campo !== 'anio' && campo !== 'id' && campo !== "cvemunicipal" && campo !== 'clave' && campo !== 'inicioper' && campo !== 'finper'
      && campo !== 'cveentidad' && campo !== 'clavemun' && campo !== 'claveloc' && campo !== 'clvgeo' && campo !== 'idm' && campo !== 'cvemunicipal' && campo !== 'clave_unic' && campo !== 'clave_esta' && campo !== 'clave_muni'
      && campo !== 'clave_nucl' && campo !== 'clavedo' && campo !== 'corterepda' && campo !== 'claveestado' && campo !== 'clavelocal' && campo !== 'latitud' && campo !== 'longitud' && campo !== 'idestado' && campo !== 'idmunicipio' && campo !== 'londecimal' && campo !== 'latdecimal' && campo !== 'ciclo'
      && campo !== 'idP' && campo !== 'cvemun' && campo !== 'cveloc' && campo !== "cvegeo" && campo !== "cve_zm" && campo !== "corte" && campo !== "clvcuenca"
      && campo !== "clvedo" && campo !== "anioagricola" && campo !== "titulo" && campo !== "clavecuenca" && campo !== "fechadof" && campo !== "claveprestador") {
      return 0;
    } else {
      return 1;
    }

  }

  function darFormato(data) {
    //console.log(data);

    let aux1 = [];
    data.map(item => {
      let d = {};
      for (let key in item.properties) {
        d[key] = item.properties[key];
      }
      aux1.push(d);
    })

    //console.log(aux1);

    let aux = [];
    let pop;
    let headers = Object.keys(aux1[0]);

    aux1.map((item, index) => {
      //Logica para ajustar cifras a decimales 
      //console.log(headers)
      //console.log(item);
      headers.map(campo => {

        if (isNaN(item[campo])) {
          //console.log("No es numero");
        } else {
          if (item[campo] - Math.floor(item[campo]) === 0) {
            if (omitirFormato(campo) === 0) {
              item[campo] = (new Intl.NumberFormat("en-US").format(item[campo]));//formatCurrency.format(item.properties[campo])//
            }
            //console.log("Es un numero entero");
          } else {
            //console.log("Es un numero decimal");
            if (omitirFormato(campo) === 0) {
              let aux = Number(item[campo]).toFixed(2);
              //console.log(aux);
              item[campo] = (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(aux));//formatCurrency.format(item.properties[campo].toFixed(2))//item.properties[campo].toFixed(2);//new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.properties[campo].toFixed(2));//item.properties[campo].toFixed(2);
              //console.log(item[campo]);
            }
          }
        }
      });
      //console.log(item);

      pop = item;
      pop.id = index;
      aux.push(pop);
    });

    //console.log(aux);

    return aux;

  }


  //Para asignar atributos
  const [titulo, setTitulo] = useState("");
  const [tituloModalA, setTituloModalA] = useState("");
  function muestraAtributos(capa) {
    //console.log(capa)
    setTituloModalA(capa.TAtributos);
    setAtributos(capa.datos);
    setTitulo(capa.tituloT)
    let data = capa.datos;
    //console.log(data);
    let a = buscarAlias(capa.name);
    //console.log(a)



    /*
    let aux = [];
        let pop;
        let headers = Object.keys(data[0]);
    
        data.map((item, index) => {
          //Logica para ajustar cifras a decimales 
          //console.log(headers)
          //console.log(item.properties);
          headers.map(campo => {
    
            if (isNaN(item[campo])) {
              //console.log("No es numero");
            } else {
              if (item[campo] - Math.floor(item[campo]) === 0) {
                if (campo !== 'anio' && campo !== 'id' && campo !== "cvemunicipal") {
                  item[campo] = (new Intl.NumberFormat("en-US").format(item[campo]));//formatCurrency.format(item.properties[campo])//
                }
                //console.log("Es un numero entero");
              } else {
                //console.log("Es un numero decimal");
                item[campo] = (new Intl.NumberFormat("en-US").format(item[campo].toFixed(2)));//formatCurrency.format(item.properties[campo].toFixed(2))//item.properties[campo].toFixed(2);//new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.properties[campo].toFixed(2));//item.properties[campo].toFixed(2);
              }
            }
          });
    
          pop = item;
          pop.id = index;
          aux.push(pop);
        });
        */

    setFilas(data);
    setColumnas(a.alias);
    setShowModalAtributos(true);
  }

  function buscarAlias(capa) {
    let encontrado;
    aliasTablas.map((item) => {
      if (item.capa === capa) {
        encontrado = item;
      }
    });

    return encontrado;
  }

  function descargarCSV(capa) {
    //console.log(capa)

    let items;
    if (capa.name === "m07_agricoladrnumcultivos") {
      items = filtrarJson(capa.datos2, capa.name);
    } else {
      items = capa.datos2;
    }
    let alias = buscarAlias2(capa.name)
    //console.log(alias)

    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    //console.log(replacer)

    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );


    if (capa.name === "m20_vwmpresas") {
      csv.unshift(alias.join(","));
    } else {
      csv.unshift(header.join(","));
    }
    //csv.unshift(header.join(","));
    csv = csv.join("\n");
    csv = csv + "\r";
    //Download the file as CSV
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", [csv], { type: 'text/csv;charset=utf-8;' }]);
    //var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = `${capa.nombre}.csv`; //Name the file here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  function buscarAlias2(capa) {
    //console.log(capa)
    let aux;
    aliasDescargaCSV.map(item => {
      if (item.capa === capa) {
        aux = item.alias;
      }
    })
    return aux;
  }
  //funcion para filtrar json y generar cvs de la capa
  function filtrarJson(data, name) {
    //console.log(data);
    //console.log(name)
    let aux = [];
    data.map(item => {
      let j = {};
      j['id'] = item['id'];
      j['clvdr'] = item['clvdr'];
      j['dr'] = item['dr'];
      j['anioagricola'] = item['anioagricola'];
      j['numcultivo'] = item['numcultivo'];
      j['producciont'] = item['producciont'];
      j['spcosechada'] = item['spcosechada'];
      j['spsembrada'] = item['spsembrada'];
      j['valproduccion'] = item['valproduccion'];
      aux.push(j);
    })

    return aux;
  }

  //https://geosinav30.conagua.gob.mx:8080/geoserver/Sina/ows
  function descargarKML(capa) {
    let url = `https://geosinav30.conagua.gob.mx:8080/geoserver/wms/kml?layers=Sina:${capa.name}`;
    var downloadLink = document.createElement("a");
    downloadLink.href = url;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  function descargarSeleccion(capa) {
    let capasVectoriales = {};
    let features = [];
    //capasVectoriales.type = "FeatureCollection";
    sublayerSeleccionada.map((featu) => {
      if (capa.name === featu.feature.nombre_capa) {
        features.push(featu.feature);
      }
      //se agregar los features para descarga
    });

    if (features.length > 0) {
      capasVectoriales.type = "FeatureCollection";
      capasVectoriales.features = features;
      var link = document.createElement("a");
      link.download = `${capa.titulo}.json`;
      link.href = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(capasVectoriales)
      )}`;
      link.click();
    } else {
      mensaje("No hay datos seleccionados para descargas", "warning");
    }
  }

  function mensaje(cadena, icon) {
    Swal.fire({
      position: "top-end",
      icon: icon,
      title: cadena,
      showConfirmButton: false,
      timer: 1200,
    });
  }

  function mensaje2(cadena, icon) {
    let timerInterval
    Swal.fire({
      position: 'top-end',
      showCloseButton: true,
      title: "Cargando información",
      html: 'Por favor espera',
      timer: 90000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        //console.log('I was closed by the timer')
      }
    })
  }


  /*Funcion para desplegar las leyendas del mapa en leafleat*/
  function desplegarLeyendasActivas(capa, funcion) {
    //console.log(capa);
    if (capa !== undefined) {
      let nombre = capa.leyenda//`${e.target.id.toUpperCase()}_leyenda`;

      let aux2 = document.getElementById(nombre);
      if (aux2 === null) {
        let div = L.DomUtil.create("div", "info legend");
        div.setAttribute("id", `${nombre}`);
        div.innerHTML = "";
        if (capa.habilitado) {
          var legend = L.control({ position: "bottomright" });
          legend.onAdd = function (map) {
            var labels = [];
            let index = buscarIndexCat(capa.leyenda);
            let categories = categorias[index].categorias;
            // loop through our density intervals and generate a label with a colored square for each interval
            if (categories.length > 1) {
              labels.push(`${capa.nombre}`);
            }
            for (var i = 0; i < categories.length; i++) {
              if (funcion !== "" && capa.name === "m18_trayectoriasciclones") {
                div.innerHTML += labels.push(
                  `&nbsp;&nbsp;&nbsp;<i class='linea' style="background-color:${eval(
                    `Sim.${capa.funcion}('${categories[i]}')`
                  )}; stroke-dasharray:'#b2b2b2';"></i> ` +
                  (categories[i] ? categories[i] : "+")
                );
              }
              else if (funcion !== "") {
                div.innerHTML += labels.push(
                  `&nbsp;&nbsp;&nbsp;<i class='cuadrado' style="background-color:${eval(
                    `Sim.${capa.funcion}('${categories[i]}')`
                  )}; border-color:'#b2b2b2';"></i> ` +
                  (categories[i] ? categories[i] : "+")
                );
              } else {
                div.innerHTML += labels.push(
                  `&nbsp;&nbsp;&nbsp;<i class='cuadrado' style="background-color:${capa.color}; border-color:${capa.color};"></i> ` +
                  (categories[i] ? categories[i] : "+")
                );
              }
            }
            div.innerHTML = labels.join("<br>");
            return div;
          };
          legend.addTo(mapaReferencia);
        }
      }
    }
  }

  /*Funcion para desplegar las leyendas del mapa en leafleat*/
  function desplegarLeyendasActivas1(capa, funcion, tipo) {
    if (capa !== undefined) {
      let nombre = capa.leyenda;

      let aux2 = document.getElementById(nombre);
      if (aux2 === null) {
        let div = L.DomUtil.create("div", "info legend");
        div.setAttribute("id", `${nombre}`);
        div.innerHTML = "";
        if (capa.habilitado) {
          var legend = L.control({ position: "bottomright" });
          legend.onAdd = function (map) {
            var labels = [];
            let index = buscarIndexCat(capa.leyenda);
            let categories = categorias[index].categorias;
            // loop through our density intervals and generate a label with a colored square for each interval
            if (categories.length > 1) {
              labels.push(`${capa.leyenda}`);
            }
            for (var i = 0; i < categories.length; i++) {
              if (funcion !== "") {
                div.innerHTML += labels.push(
                  `&nbsp;&nbsp;&nbsp;<i class='cuadrado' style="background-color:${eval(
                    `Sim.${capa.funcion}('${tipo}','${categories[i]}')`
                  )}; border-color:'#b2b2b2';"></i> ` +
                  (categories[i] ? categories[i] : "+")
                );
              } else {
                div.innerHTML += labels.push(
                  `&nbsp;&nbsp;&nbsp;<i class='cuadrado' style="background-color:${capa.layer.options.style.fillColor}; border-color:${capa.layer.options.style.color};"></i> ` +
                  (categories[i] ? categories[i] : "+")
                );
              }
            }
            div.innerHTML = labels.join("<br>");
            return div;
          };
          legend.addTo(mapaReferencia);
        }
      }
    }
  }

  function desplegarLeyendasActivas2(capa, funcion, tipo) {
    if (capa !== undefined) {
      let nombre = capa.leyenda;

      let aux2 = document.getElementById(nombre);
      if (aux2 === null) {
        let div = L.DomUtil.create("div", "info legend");
        div.setAttribute("id", `${nombre}`);
        div.innerHTML = "";
        if (capa.habilitado) {
          var legend = L.control({ position: "bottomright" });
          legend.onAdd = function (map) {
            var labels = [];
            let index = buscarIndexCat(capa.leyenda);
            let categories = categorias[index].categorias;
            // loop through our density intervals and generate a label with a colored square for each interval
            if (categories.length > 1) {
              labels.push(`${capa.leyenda}`);
            }
            for (var i = 0; i < categories.length; i++) {
              if (funcion !== "") {
                let url = eval(`imagen.${capa.funcion}('${categories[i]}')`);
                div.innerHTML += labels.push(
                  `&nbsp;&nbsp;&nbsp;<i class='cuadradoImg' style="background-image: url('${url}'); width: 35px; heigth:35px"></i>` +
                  (categories[i] ? categories[i] : "+")
                );
              } else {
                div.innerHTML += labels.push(
                  `&nbsp;&nbsp;&nbsp;<i class='cuadrado' style="background-color:${capa.layer.options.style.fillColor}; border-color:${capa.layer.options.style.color};"></i> ` +
                  (categories[i] ? categories[i] : "+")
                );
              }
            }
            div.innerHTML = labels.join("<br>");
            return div;
          };
          legend.addTo(mapaReferencia);
        }
      }
    }
  }

  /** Funcion para quitar las leyendas del mapa */
  function quitarLeyenda(capa) {
    var element = document.getElementById(`${capa.leyenda}`);
    var element2 = document.getElementById(`${capa.name}`);
    if (element !== null) {
      element.parentNode.removeChild(element);
    }
    if (element2 !== null) {
      //element2.parentNode.removeChild(element2);
    }
  }

  /**Funcion que busca el indice del estilo de la capa a agregar */
  function buscarEstilo(name) {
    let i;
    estilosC.map((item, index) => {
      if (item.capa === name) {
        i = item;
      }
    });
    return i;
  }

  function buscarIndexCat(capa) {
    let index = -1;
    categorias.map((capaB, i) => {
      if (capaB.capa === capa) {
        index = i;
      }
    });
    return index;
  }

  //funcion para capas de calidad del agua
  var aux;
  const [paramCapa, setParamCapa] = useState();
  function mostrarCapasCalidad(event) {
    if (tipoCalidad1.value === "1") {
      //busqueda por parametro
      if (anioSelectCalidad === null || anioSelectCalidad === undefined) {
        mensaje("Selecciona año de consulta", "error");
      } else {
        if (event !== null && event.value !== 0) {
          mensaje2("Cargando informacion", "info");

          setParamCapa(event);
          aux = event.funcion;
          let capaWFS = {};

          $.ajax({
            url: `${rootApi}calidadV/sitios/${anioSelectCalidad.value}/${event.campo}`,
            async: true,
            type: 'GET',
            success: function (data) {
              //console.log(data)
              capaWFS.response = data;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["datos2"] = datosDescargaCVS(data); //datos para descargar CVS
              capaWFS["datos"] = darFormato(data);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(data); //datos que se muestran en el mapa
              capaWFS["TAtributos"] = "RENAMECA (Calidad del Agua)"
              //console.log(capaWFS)

              if (data.length > 0) {
                //crear geojson
                let geojson1 = {
                  type: "FeatureCollection",
                  features: [],
                };
                if (event.label === "Coliformes Fecales (Superficiales)") {
                  //superficiales
                  let Datos = [];
                  data.map((item) => {
                    let aux = {},
                      aux1 = {};
                    aux.type = "Feature";
                    aux1.type = "Point";
                    if (item.tipo !== "SUBTERRANEO") {
                      aux1.coordinates = [Number(item.lat), Number(item.lon)];
                      aux.geometry = aux1;
                      aux.properties = item;
                      aux.id = `${event.label}.${item.id}`;
                      delete item.lat;
                      delete item.lon;
                      Datos.push(item);
                      geojson1.features.push(aux);
                    }
                  });
                } else {
                  if (event.label === "Coliformes Fecales (Subterraneos)") {
                    //superficiales
                    let Datos = [];
                    data.map((item) => {
                      let aux = {},
                        aux1 = {};
                      aux.type = "Feature";
                      aux1.type = "Point";
                      if (item.tipo === "SUBTERRANEO") {
                        aux1.coordinates = [Number(item.lat), Number(item.lon)];
                        aux.geometry = aux1;
                        aux.properties = item;
                        aux.id = `${event.label}.${item.id}`;
                        delete item.lat;
                        delete item.lon;
                        Datos.push(item);
                        geojson1.features.push(aux);
                      }
                    });
                  } else {
                    let Datos = [];
                    data.map((item) => {
                      let aux = {},
                        aux1 = {};
                      aux.type = "Feature";
                      aux1.type = "Point";
                      aux1.coordinates = [Number(item.lat), Number(item.lon)];
                      aux.geometry = aux1;
                      aux.properties = item;
                      aux.id = `${event.label}.${item.id}`;
                      delete item.lat;
                      delete item.lon;
                      Datos.push(item);
                      geojson1.features.push(aux);
                    });
                  }
                }

                ///cargar el mapa
                capaWFS["attribution"] = "No disponible";
                capaWFS["transparencia"] = 1;
                capaWFS["tipo"] = "wfs";
                capaWFS["habilitado"] = true;
                capaWFS["format"] = "json";
                capaWFS["servicio"] = root;

                capaWFS["datos2"] = datosDescargaCVS(geojson1.features); //datos para descargar CVS
                capaWFS["datos"] = darFormato(geojson1.features);//datos a mostrar en la tabla de atributos 
                capaWFS["features"] = copiarFeatures(geojson1.features); //datos que se muestran en el mapa
                capaWFS["funcion"] = event.funcion;
                capaWFS["TAtributos"] = "RENAMECA (Calidad del Agua)"
                capaWFS["nombre"] = `${event.label}_${anioSelectCalidad.value}`;
                capaWFS["titulo"] = `${event.label}_${anioSelectCalidad.value}`;
                capaWFS["name"] = event.capa;
                capaWFS["leyenda"] = event.label;
                capaWFS.isActive = true;
                capaWFS["modulo"] = "calidad";

                zIndexCapas = zIndexCapas + 1;
                mapaReferencia.createPane(`${zIndexCapas}`);
                mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                  numeroIndex + capasVisualizadas.length;

                var clusters = L.markerClusterGroup({
                  spiderfyOnMaxZoom: false,
                  showCoverageOnHover: false,
                  zoomToBoundsOnClick: true,
                  spiderfyDistanceMultiplier: 2,
                  pane: `${zIndexCapas}`,
                });

                var geojson = new L.geoJson(geojson1, {
                  pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, {
                      fillColor: eval(
                        `Sim.${event.funcion}('${feature.properties["clasificacion"]}')`
                      ),
                      weight: 1,
                      opacity: 1,
                      color: "#b2b2b2",
                      fillOpacity: 0.9,
                      radius: 7,
                    });
                  },
                  pane: `${zIndexCapas}`,
                  style: estiloInd,
                  onEachFeature: function (feature = {}, subLayer) {
                    feature["nombre_capa"] = event.label;
                    feature["titulo"] = event.label;
                    feature["funcionC"] = event.funcion;
                    var pop = infoCapas.getEstados(
                      feature.properties,
                      event.capa
                    );
                    subLayer.bindPopup(pop, { maxHeight: 700 });
                    // eslint-disable-next-line no-unused-expressions
                    subLayer.on("dblclick", function () {
                      if (feature["seleccionada"] === true) {
                        let mycolor;
                        feature["seleccionada"] = false;
                        if (mycolor === undefined) {
                          mycolor = {
                            fillColor: eval(
                              `Sim.${feature.funcionC}('${feature.properties["clasificacion"]}')`
                            ),
                            weight: 1,
                            color: "#b2b2b2",
                            fillOpacity: 1,
                          };
                        }
                        subLayer.setStyle({
                          fillColor: mycolor.fillColor,
                          color: mycolor.color,
                        });
                        for (var i = 0; i < featureSeleccion.length; i++) {
                          if (featureSeleccion[i].feature.id === feature.id) {
                            featureSeleccion.splice(i, 1);
                          }
                        }
                      } else {
                        feature["seleccionada"] = true;
                        subLayer.setStyle({
                          fillColor: ColorSeleccion,
                          color: bordeSeleccion,
                        });
                        featureSeleccion.push(subLayer);
                      }
                      setSublayerSeleccionada(featureSeleccion);
                    });
                  },
                });

                clusters.addLayer(geojson);
                capaWFS["layer"] = clusters;
                mapaReferencia.addLayer(capaWFS.layer);
                mapaReferencia.fitBounds(capaWFS.layer.getBounds());
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                desplegarLeyendasActivas(capaWFS, event.funcion);
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                Swal.close();
              } else {
                mensaje(
                  "La consulta solicitada no contiene información",
                  "warning"
                );
              }


            },
          });

        } else {
          setParamCapa([]);
        }
      }
      //termina la carga por parametro
    } else {
      //busuqeda por tipo de cuerpo
      if (anioSelectCalidad === null || anioSelectCalidad === undefined) {
        mensaje("Selecciona año de consulta", "error");
      } else {
        let capaWFS = {};
        //console.log(event)
        //console.log(anioSelectCalidad);

        if (event !== null && event.value !== 0) {
          mensaje2("Cargando informacion", "info");

          //console.log(`${rootApi}${event.url}${anioSelectCalidad.value}`)

          ///Colsulta de base de datos
          $.ajax({
            url: `${rootApi}${event.url}${anioSelectCalidad.value}`,
            async: true,
            type: 'GET',
            success: function (data) {
              //console.log(data)
              capaWFS.response = data;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["datos2"] = datosDescargaCVS(data); //datos para descargar CVS
              capaWFS["datos"] = darFormato(data);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(data); //datos que se muestran en el mapa
              capaWFS["funcion"] = event.funcion;
              capaWFS["TAtributos"] = "RENAMECA (Calidad del Agua)"

              if (data.length > 0) {
                //crear geojson
                let geojson1 = {
                  type: "FeatureCollection",
                  features: [],
                };

                let Datos = [];
                data.map((item) => {
                  let aux = {},
                    aux1 = {};
                  aux.type = "Feature";
                  aux1.type = "Point";
                  aux1.coordinates = [Number(item.lat), Number(item.lon)];
                  aux.geometry = aux1;
                  aux.properties = item;
                  aux.id = `${event.label}.${item.id}`;
                  delete item.lat;
                  delete item.lon;
                  Datos.push(item);
                  geojson1.features.push(aux);
                });



                ///cargar el mapa
                capaWFS["attribution"] = "No disponible";
                capaWFS["transparencia"] = 1;
                capaWFS["tipo"] = "wfs";
                capaWFS["habilitado"] = true;
                capaWFS["format"] = "json";
                capaWFS["servicio"] = root;

                capaWFS["datos2"] = datosDescargaCVS(geojson1.features); //datos para descargar CVS
                capaWFS["datos"] = darFormato(geojson1.features);//datos a mostrar en la tabla de atributos 
                capaWFS["features"] = copiarFeatures(geojson1.features); //datos que se muestran en el mapa
                capaWFS["funcion"] = event.funcion;
                capaWFS["TAtributos"] = "RENAMECA (Calidad del Agua)"
                capaWFS["nombre"] = `${event.label}_${anioSelectCalidad.value}`;
                capaWFS["titulo"] = `${event.label}_${anioSelectCalidad.value}`;
                capaWFS["name"] = event.capa;
                capaWFS["leyenda"] = event.label;
                capaWFS.isActive = true;
                capaWFS["modulo"] = "calidad";

                zIndexCapas = zIndexCapas + 1;
                mapaReferencia.createPane(`${zIndexCapas}`);
                mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                  numeroIndex + capasVisualizadas.length;

                var clusters = L.markerClusterGroup({
                  spiderfyOnMaxZoom: false,
                  showCoverageOnHover: false,
                  zoomToBoundsOnClick: true,
                  spiderfyDistanceMultiplier: 2,
                  pane: `${zIndexCapas}`,
                });

                var geojson = new L.geoJson(geojson1, {
                  pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, {
                      fillColor: eval(
                        `Sim.${event.funcion}('${feature.properties["color"]}')`
                      ),
                      weight: 1,
                      opacity: 1,
                      color: "#b2b2b2",
                      fillOpacity: 0.9,
                      radius: 7,
                    });
                  },
                  pane: `${zIndexCapas}`,
                  style: estiloInd,
                  onEachFeature: function (feature = {}, subLayer) {
                    feature["nombre_capa"] = event.label;
                    feature["titulo"] = event.label;
                    feature["funcionC"] = event.funcion;
                    var pop = infoCapas.getEstados(
                      feature.properties,
                      event.capa
                    );
                    subLayer.bindPopup(pop, { maxHeight: 700 });
                    // eslint-disable-next-line no-unused-expressions
                    subLayer.on("dblclick", function () {
                      if (feature["seleccionada"] === true) {
                        let mycolor;
                        feature["seleccionada"] = false;
                        if (mycolor === undefined) {
                          mycolor = {
                            fillColor: eval(
                              `Sim.${feature.funcionC}('${feature.properties["clasificacion"]}')`
                            ),
                            weight: 1,
                            color: "#b2b2b2",
                            fillOpacity: 1,
                          };
                        }
                        subLayer.setStyle({
                          fillColor: mycolor.fillColor,
                          color: mycolor.color,
                        });
                        for (var i = 0; i < featureSeleccion.length; i++) {
                          if (featureSeleccion[i].feature.id === feature.id) {
                            featureSeleccion.splice(i, 1);
                          }
                        }
                      } else {
                        feature["seleccionada"] = true;
                        subLayer.setStyle({
                          fillColor: ColorSeleccion,
                          color: bordeSeleccion,
                        });
                        featureSeleccion.push(subLayer);
                      }
                      setSublayerSeleccionada(featureSeleccion);
                    });
                  },
                });

                clusters.addLayer(geojson);
                capaWFS["layer"] = geojson;
                mapaReferencia.addLayer(capaWFS.layer);
                mapaReferencia.fitBounds(capaWFS.layer.getBounds());
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                desplegarLeyendasActivas(capaWFS, event.funcion);
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                Swal.close();
              } else {
                mensaje(
                  "La consulta solicitada no contiene información",
                  "warning"
                );
              }


            },
          });


        } else {
          setParamCapa([]);
        }

        //termina la carga por tipo de cuerpo
      }
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setTipoCalidad1([]);
      setanioSelectCalidad([]);
      setParamCapa([]);
    }, 3000);
  }

  const [aniosCalidad, setAniosCalidad] = useState([]);
  const [anioSelectCalidad, setanioSelectCalidad] = useState();

  function selectAnioCalidad(event) {
    if (event !== null) {
      setanioSelectCalidad(event);
    } else {
      setanioSelectCalidad();
    }
  }

  function selectConsultaCalidad(event) {
    if (event !== null) {
      setTipoCalidad1(event);
    } else {
      setTipoCalidad1();
    }
  }

  function mostrarCapasPrestadores(name, titulo, funcionC, parametro, filtro) {
    if (entidadSelect !== null || titulo === "Prestadores a Nivel Nacional" || participant !== null) {
      aux = funcionC;
      let capaWFS = cap.agregarCapas(
        "11",
        capasVisualizadas,
        name,
        titulo,
        filtro,
        "",
        entidadSelect,
        "",
        "",
        participant
      );
      //console.log(capaWFS)

      if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {

        mensaje2("Cargando informacion", "info");
        $.ajax({
          url: capaWFS.urlS,
          async: true,
          type: 'GET',
          success: function (response) {
            capaWFS.response = response;
            capaWFS["attribution"] = "No disponible";
            capaWFS["transparencia"] = 1;
            capaWFS["tipo"] = "wfs";
            capaWFS["habilitado"] = true;
            capaWFS["format"] = "json";
            capaWFS["servicio"] = root;
            capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
            capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
            capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
            capaWFS["TAtributos"] = "Prestadores de Servicios"
            capaWFS["funcion"] = funcionC;
            zIndexCapas = zIndexCapas + 1;
            mapaReferencia.createPane(`${zIndexCapas}`);
            mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
              numeroIndex + capasVisualizadas.length;

            var geojson = new L.geoJson(capaWFS.response, {
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, MarkerOptions);
              },
              style: estiloInd,
              pane: `${zIndexCapas}`,
              onEachFeature: function (feature = {}, subLayer) {
                feature["nombre_capa"] = name;
                feature["titulo"] = titulo;
                var pop = infoCapas.getEstados(feature.properties, name);
                subLayer.bindPopup(pop, { maxHeight: 700 });
                // eslint-disable-next-line no-unused-expressions
                subLayer.on("dblclick", function () {
                  if (feature["seleccionada"] === true) {
                    let mycolor;
                    feature["seleccionada"] = false;
                    if (
                      feature.titulo === "Prestadores por Entidad Federativa" ||
                      feature.titulo === "Prestadores a Nivel Nacional"
                    ) {
                      mycolor = {
                        fillColor: Sim.getPrestadores(
                          `${feature.properties.tipoprestador}`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    if (feature.titulo === "Participantes en AquaRating") {
                      mycolor = {
                        fillColor: Sim.getAquaRating(
                          `${feature.properties.aquarating}`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    subLayer.setStyle({
                      fillColor: mycolor.fillColor,
                      color: mycolor.color,
                    });
                    for (var i = 0; i < featureSeleccion.length; i++) {
                      if (featureSeleccion[i].feature.id === feature.id) {
                        featureSeleccion.splice(i, 1);
                      }
                    }
                  } else {
                    feature["seleccionada"] = true;
                    subLayer.setStyle({
                      fillColor: ColorSeleccion,
                      color: bordeSeleccion,
                    });
                    featureSeleccion.push(subLayer);
                  }
                  setSublayerSeleccionada(featureSeleccion);
                }),
                  subLayer.on({
                    //mouseover: highlightFeature,
                  });
              },
            });

            capaWFS["layer"] = geojson;
            setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
            mapaReferencia.addLayer(capaWFS.layer);
            mapaReferencia.fitBounds(capaWFS.layer.getBounds());
            desplegarLeyendasActivas(capaWFS, funcionC);
            //setEntidadSelect(null);

            Swal.close();
          },
        });

      } else {
        //se manda msj de que ya esta o no tiene informacion
        mensaje("Capa cargada", "info")
      }
    } else {
      mensaje("Selecciona Entidad", "warning");
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setEntidadSelect([]);
      setSelectParticipante([]);
    }, 3000);
  }

  //funcion para generar reportes de prestadores
  const [columnas, setColumnas] = useState([]);
  const [filas, setFilas] = useState([]);
  const [entidadSelect, setEntidadSelect] = useState();
  function seleccionEntidad(e) {
    setEntidadSelect(e);
  }

  const [entidadSelectRepda, setEntidadSelectRepda] = useState();
  function selectEntidadRepda(e) {
    if (e !== null) {
      setEntidadSelectRepda(e);
    } else {
      setEntidadSelectRepda();
    }
  }
  //funcion para agregar capas del modulo de presupuesto
  function mostrarCapasPresupuesto(name, titulo, funcionC, parametro, filtro, tituloT) {
    console.log(GrupoPresupuesto)
    if (anioSPresupuesto !== null && anioSPresupuesto !== undefined) {
      if (
        (GrupoPresupuesto !== undefined && GrupoPresupuesto !== null) ||
        (MesSPresupuesto !== undefined && MesSPresupuesto !== null) ||
        titulo === "Presupuesto por región" ||
        titulo === "Presupuesto Ejercido Nacional"

      ) {
        aux = funcionC;
        let capaWFS;

        if (titulo === "Distribución por entidad") {
          capaWFS = cap.agregarCapas(
            "10",
            capasVisualizadas,
            name,
            titulo,
            "",
            anioSPresupuesto.value,
            "",
            GrupoPresupuesto.value
          );
        } else if (titulo === "Presupuesto mensual por RHA" ||
          titulo === "Presupuesto mensual por dirección local") {
          capaWFS = cap.agregarCapas(
            "10",
            capasVisualizadas,
            name,
            titulo,
            "",
            anioSPresupuesto.value,
            "",
            MesSPresupuesto.value
          );
        } else {
          capaWFS = cap.agregarCapas(
            "10",
            capasVisualizadas,
            name,
            titulo,
            "",
            anioSPresupuesto.value,
            "",
            ""
          );
        }
        //console.log(GrupoPresupuesto);

        if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
          mensaje2("Cargando informacion", "info");

          $.ajax({
            url: capaWFS.urlS,
            async: true,
            type: 'GET',
            success: function (response) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
              capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
              capaWFS['tituloT'] = tituloT
              capaWFS["TAtributos"] = "Presupuesto"

              capaWFS["funcion"] = funcionC;
              zIndexCapas = zIndexCapas + 1;
              mapaReferencia.createPane(`${zIndexCapas}`);
              mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                numeroIndex + capasVisualizadas.length;

              var geojson = new L.geoJson(capaWFS.response, {
                pointToLayer: function (feature, latlng) {
                  return L.circleMarker(latlng, MarkerOptions);
                },
                style: estiloInd,
                pane: `${zIndexCapas}`,
                onEachFeature: function (feature = {}, subLayer) {
                  feature["nombre_capa"] = name;
                  feature["titulo"] = titulo;
                  var pop = infoCapas.getEstados(feature.properties, name);
                  subLayer.bindPopup(pop, { maxHeight: 700 });
                  // eslint-disable-next-line no-unused-expressions
                  subLayer.on("dblclick", function () {
                    if (feature["seleccionada"] === true) {
                      let mycolor;
                      feature["seleccionada"] = false;
                      if (feature.titulo === "Presupuesto por región") {
                        mycolor = {
                          fillColor: Sim.getPresupuestoRegion(
                            `${feature.properties.region}`
                          ),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      if (feature.titulo === "Distribución por entidad") {
                        mycolor = {
                          fillColor: Sim.getPresupuestoEntidad(
                            `${feature.properties.mdp}`
                          ),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      if (feature.titulo === "Presupuesto Ejercido Nacional") {
                        mycolor = {
                          fillColor: Sim.getPresupuestoNacional(
                            `${feature.properties.mdp}`
                          ),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      if (feature.titulo === "Presupuesto mensual por RHA") {
                        mycolor = {
                          fillColor: Sim.getPresupuestoRHA(
                            `${feature.properties.monto}`
                          ),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      if (feature.titulo === "Presupuesto mensual por dirección locall") {
                        mycolor = {
                          fillColor: Sim.getPresupuestoDL(
                            `${feature.properties.monto}`
                          ),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      subLayer.setStyle({
                        fillColor: mycolor.fillColor,
                        color: mycolor.color,
                      });
                      for (var i = 0; i < featureSeleccion.length; i++) {
                        if (featureSeleccion[i].feature.id === feature.id) {
                          featureSeleccion.splice(i, 1);
                        }
                      }
                    } else {
                      feature["seleccionada"] = true;
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      featureSeleccion.push(subLayer);
                    }
                    setSublayerSeleccionada(featureSeleccion);
                  }),
                    subLayer.on({
                      // mouseover: highlightFeature,
                    });
                },
              });

              capaWFS["layer"] = geojson;
              setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
              mapaReferencia.addLayer(capaWFS.layer);
              mapaReferencia.fitBounds(capaWFS.layer.getBounds());
              desplegarLeyendasActivas(capaWFS, funcionC);
              setGrupoPresuppuesto();
              Swal.close();

            },
          });


        } else {
          //se manda msj de que ya esta o no tiene datos en la consulta
          mensaje("Capa cargada", "info")
        }
      } else {
        mensaje("Selecciona Grupo Presupuestario", "warning");
      }
    } else {
      mensaje("Selecciona Año y Grupo Presupuestario", "warning");
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setAnioSPresupuesto([]);
      setGrupoPresuppuesto([]);
      setMesSPresupuesto([]);
    }, 3000);
  }


  //const [aniosPresupuesto, setAniosPresupuesto] = useState([])
  const [anioSPresupuesto, setAnioSPresupuesto] = useState();
  const [progPresupuesto, setProgPresupuesto] = useState([]);
  const [GrupoPresupuesto, setGrupoPresuppuesto] = useState();
  const [MesSPresupuesto, setMesSPresupuesto] = useState();

  function selectGrupoP(event) {
    if (event !== null) {
      setGrupoPresuppuesto(event);
    } else {
      setGrupoPresuppuesto();
    }
  }

  function selectAnioPresupuesto(event) {
    if (event !== null) {
      setAnioSPresupuesto(event);
    } else {
      setAnioSPresupuesto();
    }
  }

  function selectMesPresupuesto(event) {
    if (event !== null) {
      setMesSPresupuesto(event);
    } else {
      setMesSPresupuesto();
    }
  }

  //funcion para agregar capas del modulo de recaudacion
  function mostrarCapasRecaudacion(name, titulo, funcionC, parametro, tituloT) {
    if (anioRecaudacion === null || anioRecaudacion === undefined) {
      mensaje("Selecciona año de consulta", "error");
    } else {
      aux = funcionC;
      let capaWFS = cap.agregarCapas(
        "08",
        capasVisualizadas,
        name,
        titulo,
        "",
        anioRecaudacion.value,
        "",
        ""
      );
      //console.log(Object.keys(capaWFS).length === 0)
      if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
        mensaje2("Cargando informacion", "info");
        $.ajax({
          url: capaWFS.urlS,
          async: true,
          type: 'GET',
          success: function (response) {
            capaWFS.response = response;
            capaWFS["attribution"] = "No disponible";
            capaWFS["transparencia"] = 1;
            capaWFS["tipo"] = "wfs";
            capaWFS["habilitado"] = true;
            capaWFS["format"] = "json";
            capaWFS["servicio"] = root;
            capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
            capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
            capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
            //console.log(capaWFS['features'])
            capaWFS["TAtributos"] = "Recaudación"

            capaWFS["tituloT"] = tituloT;

            capaWFS["funcion"] = funcionC;

            zIndexCapas = zIndexCapas + 1;
            mapaReferencia.createPane(`${zIndexCapas}`);
            mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
              numeroIndex + capasVisualizadas.length;

            if (name === "m08_cobroderechos") {
              const header = Object.keys(capaWFS.features[0].properties);
              for (let i = 0; i < 13; i++) {
                let total = 0;
                for (let index = 4; index < 14; index++) {
                  total = total + Number(capaWFS.features[i].properties[header[index]]);
                }
                capaWFS.features[i].properties["Total"] = total;
              }
            }
            if (name === "m08_recaudacionuso") {
              const header = Object.keys(capaWFS.features[0].properties);
              for (let i = 0; i < 13; i++) {
                let total = 0;
                for (let index = 4; index < 9; index++) {
                  total = total + Number(capaWFS.features[i].properties[header[index]]);
                }
                capaWFS.features[i].properties["Total"] = total;
              }
            }

            var geojson = new L.geoJson(capaWFS.features, {
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, {
                  fillColor: eval(
                    `${funcionC}('${feature.properties[parametro]}')`
                  ),
                  weight: 1,
                  opacity: 1,
                  color: "#b2b2b2",
                  fillOpacity: 0.9,
                  radius: 5,
                });
              },
              style: estiloInd,
              pane: `${zIndexCapas}`,
              onEachFeature: function (feature = {}, subLayer) {
                feature["nombre_capa"] = name;
                feature["titulo"] = titulo;
                var pop = infoCapas.getEstados(feature.properties, name);
                subLayer.bindPopup(pop, { maxWidth: 350, maxHeight: 700 });
                // eslint-disable-next-line no-unused-expressions
                subLayer.on("dblclick", function () {
                  if (feature["seleccionada"] === true) {
                    let mycolor;
                    feature["seleccionada"] = false;
                    if (mycolor === undefined) {
                      mycolor = {
                        fillColor: eval(
                          `Sim.${funcionC}('${feature.properties[parametro]}')`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    subLayer.setStyle({
                      fillColor: mycolor.fillColor,
                      color: mycolor.color,
                    });
                    for (var i = 0; i < featureSeleccion.length; i++) {
                      if (featureSeleccion[i].feature.id === feature.id) {
                        featureSeleccion.splice(i, 1);
                      }
                    }
                  } else {
                    feature["seleccionada"] = true;
                    subLayer.setStyle({
                      fillColor: ColorSeleccion,
                      color: bordeSeleccion,
                    });
                    featureSeleccion.push(subLayer);
                  }
                  setSublayerSeleccionada(featureSeleccion);
                }),
                  subLayer.on({
                    //mouseover: highlightFeature,
                  });
              },
            });
            capaWFS["funcion"] = funcionC;
            capaWFS["layer"] = geojson;
            setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
            mapaReferencia.addLayer(capaWFS.layer);
            mapaReferencia.fitBounds(capaWFS.layer.getBounds());
            desplegarLeyendasActivas(capaWFS, funcionC);

            Swal.close();
          },
        });
      } else {
        //se manda msj de error
        mensaje("Capa cargada", "info")
      }
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setAnioRecaudacion([]);
    }, 3000);
  }



  function buscarCapa2(name, capasVisualizadas) {
    //1- encontrada 0-noencontrada
    let siesta = null;
    capasVisualizadas.forEach((element, index) => {
      if (element.nombre === name) {
        siesta = index;
      }
    });
    return siesta;
  }

  //const [aniosPob, setAniosPob] = useState([])
  const [localidades, setLoc] = useState([]);
  const [anioSPob, setAnioSPob] = useState();
  function mostrarCapasPoblacion(name, titulo, funcionC, filtro, parametro) {

    if (name === "m04_censoLocalidad") {
      if (anioSPob !== null && anioSPob !== undefined) {
        if (entidadSelect !== null) {
          let encontrada = buscarCapa(`Censo Localidad ${entidadSelect.label} ${anioSPob.value}`, capasVisualizadas);
          //console.log(encontrada);
          if (encontrada === null) {
            //se puede agregar la capa
            mensaje2("Cargando informacion", "info");
            let capaWFS = {};

            $.ajax({
              url: `${rootApi}poblacion/filtro?entidad=${entidadSelect.value}&anio=${anioSPob.value}`,
              async: true,
              type: 'GET',
              success: function (data) {
                let geojson1 = {
                  type: "FeatureCollection",
                  features: [],
                };
                let Datos = [];
                data.map((item) => {
                  let aux = {},
                    aux1 = {};
                  aux.type = "Feature";
                  aux1.type = "Point";
                  aux1.coordinates = [Number(item.lon), Number(item.lat)];
                  aux.geometry = aux1;
                  aux.properties = item;
                  aux.id = `${name}.${item.idp}`;
                  delete item.lat;
                  delete item.lon;
                  Datos.push(item);
                  geojson1.features.push(aux);
                });
                capaWFS["modulo"] = "localidad";
                capaWFS["attribution"] = "No disponible";
                capaWFS["transparencia"] = 1;
                capaWFS["tipo"] = "wfs";
                capaWFS["habilitado"] = true;
                capaWFS["format"] = "json";
                capaWFS["servicio"] = root;

                capaWFS["datos2"] = datosDescargaCVS(geojson1.features); //datos para descargar CVS
                capaWFS["datos"] = darFormato(geojson1.features);//datos a mostrar en la tabla de atributos 
                capaWFS["features"] = copiarFeatures(geojson1.features); //datos que se muestran en el mapa
                capaWFS[
                  "nombre"
                ] = `Censo Localidad ${entidadSelect.label} ${anioSPob.value}`;
                capaWFS["TAtributos"] = "Población"
                capaWFS["titulo"] = titulo;
                capaWFS["name"] = `${name}`;
                capaWFS["layers"] = defaultParameters.typeName;
                capaWFS["leyenda"] = titulo;
                capaWFS.isActive = true;
                zIndexCapas = zIndexCapas + 1;
                mapaReferencia.createPane(`${zIndexCapas}`);
                mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                  numeroIndex + capasVisualizadas.length;

                //console.log(capaWFS);

                var geojsonL = new L.geoJson(geojson1, {
                  pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, {
                      fillColor: eval(
                        `Sim.getCensoLoc('${feature.properties["pobtot"]}')`
                      ),
                      weight: 1,
                      opacity: 1,
                      color: "#b2b2b2",
                      fillOpacity: 0.9,
                      radius: 5,
                    });
                  },
                  pane: `${zIndexCapas}`,
                  onEachFeature: function (feature = {}, subLayer) {
                    feature["nombre_capa"] = name;
                    feature["titulo"] = titulo;
                    var pop = infoCapas.getEstados(feature.properties, name);
                    subLayer.bindPopup(pop, { maxHeight: 700 });
                    // eslint-disable-next-line no-unused-expressions
                    subLayer.on("dblclick", function () {
                      if (feature["seleccionada"] === true) {
                        let mycolor;
                        feature["seleccionada"] = false;
                        if (feature.titulo === "Censo") {
                          mycolor = {
                            fillColor: eval(
                              `Sim.getCensoLoc('${feature.properties["pobtot"]}')`
                            ),
                            weight: 1,
                            color: "#b2b2b2",
                            fillOpacity: 1,
                          };
                        }
                        if (mycolor === undefined) {
                          mycolor = {
                            fillColor: eval(
                              `Sim.getCensoLoc('${feature.properties["pobtot"]}')`
                            ),
                            weight: 1,
                            color: "#b2b2b2",
                            fillOpacity: 1,
                          };
                        }
                        subLayer.setStyle({
                          fillColor: mycolor.fillColor,
                          color: mycolor.color,
                        });
                        for (var i = 0; i < featureSeleccion.length; i++) {
                          if (featureSeleccion[i].feature.id === feature.id) {
                            featureSeleccion.splice(i, 1);
                          }
                        }
                      } else {
                        feature["seleccionada"] = true;
                        subLayer.setStyle({
                          fillColor: ColorSeleccion,
                          color: bordeSeleccion,
                        });
                        featureSeleccion.push(subLayer);
                      }
                      setSublayerSeleccionada(featureSeleccion);
                    }),
                      subLayer.on({
                        //mouseover: highlightFeature,
                      });
                  },
                });
                capaWFS["funcion"] = funcionC;
                capaWFS["layer"] = geojsonL;
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                mapaReferencia.addLayer(capaWFS.layer);
                mapaReferencia.fitBounds(capaWFS.layer.getBounds());
                desplegarLeyendasActivas(capaWFS, funcionC);


                Swal.close();
              },
            });
          } else {
            //la capa ya se encuentra activa
          }
        } else {
          mensaje("Seleccion entidad de consulta", "error");
        }
      } else {
        mensaje("Selecciona año de consulta", "error");
      }
    }

    if (name === "m04_zonasatencionprioritaria") {
      if (entidadSelect !== null) {
        aux = funcionC;
        let capaWFS = cap.agregarCapas(
          "04",
          capasVisualizadas,
          name,
          titulo,
          "",
          anioSPob.value,
          entidadSelect,
          ""
        );

        //console.log(capaWFS)
        if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
          mensaje2("Cargando informacion", "info");

          $.ajax({
            url: capaWFS.urlS,
            async: true,
            type: 'GET',
            success: function (response) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
              capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
              capaWFS["TAtributos"] = "Población"
              zIndexCapas = zIndexCapas + 1;
              mapaReferencia.createPane(`${zIndexCapas}`);
              mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                numeroIndex + capasVisualizadas.length;
              var geojson = new L.geoJson(capaWFS.response, {
                pointToLayer: function (feature, latlng) {
                  return L.circleMarker(latlng, {
                    fillColor: eval(
                      `Sim.${funcionC}('${feature.properties[parametro]}')`
                    ),
                    weight: 1,
                    opacity: 1,
                    color: "#b2b2b2",
                    fillOpacity: 0.9,
                    radius: 5,
                  });
                },
                style: estiloInd,
                pane: `${zIndexCapas}`,
                onEachFeature: function (feature = {}, subLayer) {
                  feature["nombre_capa"] = name;
                  feature["titulo"] = titulo;
                  var pop = infoCapas.getEstados(feature.properties, name);
                  subLayer.bindPopup(pop, { maxHeight: 700 });
                  // eslint-disable-next-line no-unused-expressions
                  subLayer.on("dblclick", function () {
                    if (feature["seleccionada"] === true) {
                      let mycolor;
                      feature["seleccionada"] = false;
                      if (feature.titulo === "Zonas de Atención Prioritaria 2020") {
                        mycolor = {
                          fillColor: Sim.getZonasAtencion(
                            `${feature.properties.cat_indi}-${feature.properties.descrip}`
                          ),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      if (
                        feature.titulo === "Municipios Población habla indigena"
                      ) {
                        mycolor = {
                          fillColor: Sim.getPoblacionInd(
                            feature.properties.p3ym_hli
                          ),
                          weight: 1.5,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      if (feature.titulo === "Poblacion ocupada por municipio") {
                        mycolor = {
                          fillColor: Sim.getPoblacionLoc(
                            feature.properties.pocupada
                          ),
                          weight: 1.5,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      if (feature.titulo === "Índice de Marginación") {
                        mycolor = {
                          fillColor: Sim.getMarginacion(feature.properties.gm_2020),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      if (mycolor === undefined) {
                        mycolor = {
                          fillColor: eval(
                            `Sim.${funcionC}('${feature.properties[parametro]}')`
                          ),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      subLayer.setStyle({
                        fillColor: mycolor.fillColor,
                        color: mycolor.color,
                      });
                      for (var i = 0; i < featureSeleccion.length; i++) {
                        if (featureSeleccion[i].feature.id === feature.id) {
                          featureSeleccion.splice(i, 1);
                        }
                      }
                    } else {
                      feature["seleccionada"] = true;
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      featureSeleccion.push(subLayer);
                    }
                    setSublayerSeleccionada(featureSeleccion);
                  }),
                    subLayer.on({
                      //mouseover: highlightFeature,
                    });
                },
              });

              capaWFS["funcion"] = funcionC;
              capaWFS["layer"] = geojson;
              setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
              mapaReferencia.addLayer(capaWFS.layer);
              mapaReferencia.fitBounds(capaWFS.layer.getBounds());
              desplegarLeyendasActivas(capaWFS, funcionC);

              Swal.close();
            },
          });

        } else {
          //mandar msj de error o de que no tiene datos la consulta
          mensaje("Capa cargada", "info")
        }
      } else {
        mensaje("Selecciona entidad de consulta", "error");
      }
    }


    if (name === "m04_censo" || name === "m04_censomun") {
      if (anioSPob !== undefined && anioSPob !== null) {
        aux = funcionC;
        let capaWFS;
        if (entidadSelect === null && name === "m04_censomun") {
          mensaje("Selecciona entidad de consulta", "error");
        } else {
          capaWFS = cap.agregarCapas(
            "04",
            capasVisualizadas,
            `${name}${anioSPob.value}`,
            titulo,
            "",
            anioSPob.value,
            entidadSelect,
            ""
          );
          //console.log(capaWFS);

          if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
            mensaje2("Cargando informacion", "info");

            $.ajax({
              url: capaWFS.urlS,
              async: true,
              type: 'GET',
              success: function (response) {
                capaWFS.response = response;
                capaWFS["attribution"] = "No disponible";
                capaWFS["transparencia"] = 1;
                capaWFS["tipo"] = "wfs";
                capaWFS["habilitado"] = true;
                capaWFS["format"] = "json";
                capaWFS["servicio"] = root;
                //console.log(response.features)

                let features = [];
                for (let index = 0; index < response.features.length; index++) {
                  features.push(response.features[index]);
                }
                //console.log(features);

                capaWFS["datos2"] = datosDescargaCVS(features); //datos para descargar CVS
                capaWFS["datos"] = darFormato(features);//datos a mostrar en la tabla de atributos 
                capaWFS["features"] = copiarFeatures(features); //datos que se muestran en el mapa
                capaWFS["TAtributos"] = "Población"
                /*
                //quitamos duplicados de datos para atributos 
                let aux = capaWFS.datos;
                let aux2 = [];
                for (let index = 0; index < aux.length / 2; index++) {
                  aux2.push(aux[index]);
                }
                capaWFS["datos"] = aux2

                */


                zIndexCapas = zIndexCapas + 1;
                mapaReferencia.createPane(`${zIndexCapas}`);
                mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                  numeroIndex + capasVisualizadas.length;
                var geojson = new L.geoJson(capaWFS.features, {
                  pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, {
                      fillColor: eval(
                        `Sim.${funcionC}('${feature.properties[parametro]}')`
                      ),
                      weight: 1,
                      opacity: 1,
                      color: "#b2b2b2",
                      fillOpacity: 0.9,
                      radius: 5,
                    });
                  },
                  style: estiloInd,
                  pane: `${zIndexCapas}`,
                  onEachFeature: function (feature = {}, subLayer) {
                    feature["nombre_capa"] = capaWFS["name"];
                    feature["titulo"] = titulo;
                    var pop = infoCapas.getEstados(
                      feature.properties,
                      `${name}${anioSPob.value}`
                    );
                    subLayer.bindPopup(pop, { maxHeight: 700 });
                    // eslint-disable-next-line no-unused-expressions
                    subLayer.on("dblclick", function () {
                      if (feature["seleccionada"] === true) {
                        let mycolor;
                        feature["seleccionada"] = false;
                        if (
                          feature.titulo === "Zonas de Atención Prioritaria 2020"
                        ) {
                          mycolor = {
                            fillColor: Sim.getZonasAtencion(
                              `${feature.properties.cat_indi}-${feature.properties.descrip}`
                            ),
                            weight: 1,
                            color: "#232323",
                            fillOpacity: 1,
                          };
                        }
                        if (feature.titulo === "Censo Estatal") {
                          mycolor = {
                            fillColor: Sim.getCensoNacional(
                              feature.properties.pobtot
                            ),
                            weight: 1.5,
                            color: "#232323",
                            fillOpacity: 1,
                          };
                        }
                        if (feature.titulo === "Censo Municipal") {
                          mycolor = {
                            fillColor: Sim.getCensoMun(
                              feature.properties["pobtot"]
                            ),
                            weight: 1.5,
                            color: "#232323",
                            fillOpacity: 1,
                          };
                        }
                        if (mycolor === undefined) {
                          mycolor = {
                            fillColor: eval(
                              `Sim.${funcionC}('${feature.properties[parametro]}')`
                            ),
                            weight: 1,
                            color: "#232323",
                            fillOpacity: 1,
                          };
                        }
                        subLayer.setStyle({
                          fillColor: mycolor.fillColor,
                          color: mycolor.color,
                        });
                        for (var i = 0; i < featureSeleccion.length; i++) {
                          if (featureSeleccion[i].feature.id === feature.id) {
                            featureSeleccion.splice(i, 1);
                          }
                        }
                      } else {
                        feature["seleccionada"] = true;
                        subLayer.setStyle({
                          fillColor: ColorSeleccion,
                          color: bordeSeleccion,
                        });
                        featureSeleccion.push(subLayer);
                      }
                      setSublayerSeleccionada(featureSeleccion);
                    }),
                      subLayer.on({
                        //mouseover: highlightFeature,
                      });
                  },
                });

                capaWFS["funcion"] = funcionC;
                capaWFS["layer"] = geojson;
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                mapaReferencia.addLayer(capaWFS.layer);
                mapaReferencia.fitBounds(capaWFS.layer.getBounds());
                desplegarLeyendasActivas(capaWFS, funcionC);

                Swal.close();
              },
            });






          } else {
            //mandar msj de error o de que no tiene datos la consulta
            mensaje("Capa cargada", "info")
          }
        }
      } else {
        mensaje("Seleciona año de consultar", "error");
      }
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setAnioSPob([]);
      setEntidadSelect([]);
    }, 3000);



  }

  function selectAnioPob(event) {
    if (event !== null) {
      setAnioSPob(event);
    } else {
      setAnioSPob(event);
    }
  }

  //const [aniosAcuiferos, setAniosAcuiferos] = useState([]);
  const [aniosAcuif, setAniosAcuif] = useState();

  function selectAnioAcuifero(event) {
    if (event !== null) {
      setAniosAcuif(event);
    } else {
      setAniosAcuif();
    }
  }

  function mostrarCapasAcuiferos(name, titulo, filtro, funcionC) {
    if (name === "m06_acuiferos_condición") {

      aux = funcionC;
      let capaWFS = cap.agregarCapas(
        "06",
        capasVisualizadas,
        name,
        titulo,
        filtro,
        "",
        "",
        ""
      );


      if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
        mensaje2("Cargando informacion", "info");
        $.ajax({
          url: capaWFS.urlS,
          async: true,
          type: 'GET',
          success: function (response) {
            capaWFS.response = response;
            capaWFS["attribution"] = "No disponible";
            capaWFS["transparencia"] = 1;
            capaWFS["tipo"] = "wfs";
            capaWFS["habilitado"] = true;
            capaWFS["format"] = "json";
            capaWFS["servicio"] = root;
            capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
            capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
            capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
            capaWFS["TAtributos"] = "Acuiferos"
            capaWFS["funcion"] = funcionC;

            zIndexCapas = zIndexCapas + 1;
            mapaReferencia.createPane(`${zIndexCapas}`);
            mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
              numeroIndex + capasVisualizadas.length;

            var geojson = new L.geoJson(capaWFS.response, {
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, {
                  fillColor: eval(
                    `${funcionC}('${feature.properties[parametro]}')`
                  ),
                  weight: 1,
                  opacity: 1,
                  color: "#b2b2b2",
                  fillOpacity: 0.9,
                  radius: 5,
                });
              },
              style: estiloInd,
              pane: `${zIndexCapas}`,
              onEachFeature: function (feature = {}, subLayer) {
                feature["nombre_capa"] = name;
                feature["titulo"] = titulo;
                var pop = infoCapas.getEstados(feature.properties, name);
                subLayer.bindPopup(pop, { minWidth: 400, maxHeight: 700 });
                // eslint-disable-next-line no-unused-expressions
                subLayer.on("dblclick", function () {
                  if (feature["seleccionada"] === true) {
                    let mycolor;
                    feature["seleccionada"] = false;

                    mycolor = {
                      fillColor: Sim.getAcuiferosCon(
                        `${feature.properties.condicion}`
                      ),
                      weight: 1,
                      color: "#232323",
                      fillOpacity: 0.7,
                    };

                    subLayer.setStyle({
                      fillColor: mycolor.fillColor,
                      color: mycolor.color,
                    });
                    for (var i = 0; i < featureSeleccion.length; i++) {
                      if (featureSeleccion[i].feature.id === feature.id) {
                        featureSeleccion.splice(i, 1);
                      }
                    }
                  } else {
                    feature["seleccionada"] = true;
                    subLayer.setStyle({
                      fillColor: ColorSeleccion,
                      color: bordeSeleccion,
                    });
                    featureSeleccion.push(subLayer);
                  }
                  setSublayerSeleccionada(featureSeleccion);
                }),
                  subLayer.on({
                    //mouseover: highlightFeature,
                  });
              },
            });
            capaWFS["funcion"] = funcionC;
            capaWFS["layer"] = geojson;
            setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
            mapaReferencia.addLayer(capaWFS.layer);
            mapaReferencia.fitBounds(capaWFS.layer.getBounds());
            desplegarLeyendasActivas(capaWFS, funcionC);


            Swal.close();
          },
        });

      } else {
        //se manda msj de error o de que no hay datos
        mensaje("Capa cargada", "info")
      }
    }
    if ((aniosAcuif === null || aniosAcuif === undefined) && name !== "m06_acuiferos_condición") {
      mensaje("Selecciona año de consulta", "error");
    } else {


      aux = funcionC;
      let capaWFS = cap.agregarCapas(
        "06",
        capasVisualizadas,
        name,
        titulo,
        filtro,
        aniosAcuif.value,
        "",
        ""
      );


      if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
        mensaje2("Cargando informacion", "info");
        $.ajax({
          url: capaWFS.urlS,
          async: true,
          type: 'GET',
          success: function (response) {
            capaWFS.response = response;
            capaWFS["attribution"] = "No disponible";
            capaWFS["transparencia"] = 1;
            capaWFS["tipo"] = "wfs";
            capaWFS["habilitado"] = true;
            capaWFS["format"] = "json";
            capaWFS["servicio"] = root;
            capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
            capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
            capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
            capaWFS["TAtributos"] = "Acuiferos"
            capaWFS["funcion"] = funcionC;

            zIndexCapas = zIndexCapas + 1;
            mapaReferencia.createPane(`${zIndexCapas}`);
            mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
              numeroIndex + capasVisualizadas.length;

            var geojson = new L.geoJson(capaWFS.response, {
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, {
                  fillColor: eval(
                    `${funcionC}('${feature.properties[parametro]}')`
                  ),
                  weight: 1,
                  opacity: 1,
                  color: "#b2b2b2",
                  fillOpacity: 0.9,
                  radius: 5,
                });
              },
              style: estiloInd,
              pane: `${zIndexCapas}`,
              onEachFeature: function (feature = {}, subLayer) {
                feature["nombre_capa"] = name;
                feature["titulo"] = titulo;
                var pop = infoCapas.getEstados(feature.properties, name);
                subLayer.bindPopup(pop, { minWidth: 400, maxHeight: 700 });
                // eslint-disable-next-line no-unused-expressions
                subLayer.on("dblclick", function () {
                  if (feature["seleccionada"] === true) {
                    let mycolor;
                    feature["seleccionada"] = false;

                    mycolor = {
                      fillColor: Sim.getAcuiferos(
                        `${feature.properties.situacion}`
                      ),
                      weight: 1,
                      color: "#232323",
                      fillOpacity: 0.7,
                    };

                    subLayer.setStyle({
                      fillColor: mycolor.fillColor,
                      color: mycolor.color,
                    });
                    for (var i = 0; i < featureSeleccion.length; i++) {
                      if (featureSeleccion[i].feature.id === feature.id) {
                        featureSeleccion.splice(i, 1);
                      }
                    }
                  } else {
                    feature["seleccionada"] = true;
                    subLayer.setStyle({
                      fillColor: ColorSeleccion,
                      color: bordeSeleccion,
                    });
                    featureSeleccion.push(subLayer);
                  }
                  setSublayerSeleccionada(featureSeleccion);
                }),
                  subLayer.on({
                    //mouseover: highlightFeature,
                  });
              },
            });
            capaWFS["funcion"] = funcionC;
            capaWFS["layer"] = geojson;
            setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
            mapaReferencia.addLayer(capaWFS.layer);
            mapaReferencia.fitBounds(capaWFS.layer.getBounds());
            desplegarLeyendasActivas(capaWFS, funcionC);


            Swal.close();
          },
        });

      } else {
        //se manda msj de error o de que no hay datos
        mensaje("Capa cargada", "info")
      }
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setAniosAcuif([]);
    }, 3000);

  }

  function mostrarCapasCuencas(name, titulo, filtro, funcionC) {

    if (anioSCuencas === null || anioSCuencas === undefined) {
      mensaje("Selecciona año de consulta", "error");
    } else {


      aux = funcionC;
      let capaWFS = cap.agregarCapas(
        "05",
        capasVisualizadas,
        name,
        titulo,
        filtro,
        anioSCuencas.value,
        "",
        ""
      );
      //console.log(capaWFS);


      if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
        mensaje2("Cargando informacion", "info");

        $.ajax({
          url: capaWFS.urlS,
          async: true,
          type: 'GET',
          success: function (response) {
            capaWFS.response = response;
            capaWFS["attribution"] = "No disponible";
            capaWFS["transparencia"] = 1;
            capaWFS["tipo"] = "wfs";
            capaWFS["habilitado"] = true;
            capaWFS["format"] = "json";
            capaWFS["servicio"] = root;
            capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
            capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
            capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
            capaWFS["funcion"] = funcionC;
            capaWFS["TAtributos"] = "Cuencas"

            zIndexCapas = zIndexCapas + 1;
            mapaReferencia.createPane(`${zIndexCapas}`);
            mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
              numeroIndex + capasVisualizadas.length;

            var geojson = new L.geoJson(capaWFS.response, {
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, {
                  fillColor: eval(
                    `${funcionC}('${feature.properties[parametro]}')`
                  ),
                  weight: 1,
                  opacity: 1,
                  color: "#b2b2b2",
                  fillOpacity: 0.9,
                  radius: 5,
                });
              },
              style: estiloInd,
              pane: `${zIndexCapas}`,
              onEachFeature: function (feature = {}, subLayer) {
                feature["nombre_capa"] = name;
                feature["titulo"] = titulo;
                var pop = infoCapas.getEstados(feature.properties, name);
                subLayer.bindPopup(pop, { minWidth: 550, maxHeight: 700 });
                // eslint-disable-next-line no-unused-expressions
                subLayer.on("dblclick", function () {
                  if (feature["seleccionada"] === true) {
                    let mycolor;
                    feature["seleccionada"] = false;

                    if (
                      feature.titulo === "Disponibilidad Cuencas"
                    ) {
                      mycolor = {
                        fillColor: Sim.getCuencas(
                          `${feature.properties.clasificacion}`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 0.7,
                      };
                    }
                    if (feature.titulo === "Zonas de pago de derechos de agua superficial") {
                      mycolor = {
                        fillColor: Sim.getCuencasZP(
                          `${feature.properties.zona}`
                        ),
                        weight: 1.5,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    if (feature.titulo === "Ordenamiento de aguas superficial") {
                      mycolor = {
                        fillColor: Sim.getCuencasO(
                          feature.properties["tipoordenamiento"]
                        ),
                        weight: 1.5,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    if (feature.titulo === "Ordenamiento de aguas superficial") {
                      mycolor = {
                        fillColor: Sim.getCuencasV(
                          feature.properties["vedavig"]
                        ),
                        weight: 1.5,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    if (mycolor === undefined) {
                      mycolor = {
                        fillColor: eval(
                          `Sim.${funcionC}('${feature.properties[parametro]}')`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    subLayer.setStyle({
                      fillColor: mycolor.fillColor,
                      color: mycolor.color,
                    });
                    for (var i = 0; i < featureSeleccion.length; i++) {
                      if (featureSeleccion[i].feature.id === feature.id) {
                        featureSeleccion.splice(i, 1);
                      }
                    }
                  } else {
                    feature["seleccionada"] = true;
                    subLayer.setStyle({
                      fillColor: ColorSeleccion,
                      color: bordeSeleccion,
                    });
                    featureSeleccion.push(subLayer);
                  }
                  setSublayerSeleccionada(featureSeleccion);
                }),
                  subLayer.on({
                    //mouseover: highlightFeature,
                  });
              },
            });
            //console.log(filtro);
            capaWFS["funcion"] = funcionC;
            capaWFS["layer"] = geojson;
            setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
            mapaReferencia.addLayer(capaWFS.layer);
            mapaReferencia.fitBounds(capaWFS.layer.getBounds());
            desplegarLeyendasActivas(capaWFS, funcionC);

            Swal.close();
          },
        });

      } else {
        //se manda msj de error o de que no hay datos
        mensaje("Capa cargada", "info")
      }
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setAnioSCuencas([]);
    }, 3000);
  }

  //const [anioscuencas, setAnioscuencas] = useState([])
  const [anioSCuencas, setAnioSCuencas] = useState();
  function selectAnioCuencas(event) {
    if (event !== null) {
      setAnioSCuencas(event);
    } else {
      setAnioSCuencas();
    }
  }

  const [grafCuencas, setGrafCuencas] = useState("");
  function selectAnioCuencas1(event) {
    if (event !== null) {
      setAnioSCuencas(event);
      var cue = new Cuencas();
      if (grafCuencas === "grafica1") {
        let d = DatosApi(`${rootApi}cuencasrha/anual/${event.value}`);
        let aux = cue.getDatosRHA(d);
        setDatosCuencas(aux);
      }
      if (grafCuencas === "grafica2") {
        let d = DatosApi(`${rootApi}cuencasrh/anual/${event.value}`);
        let aux = cue.getDatosRH(d);
        setDatosCuencas(aux);

        //setDatosCuencas(d);
      }
      if (grafCuencas === "grafica3") {
        let d = DatosApi(`${rootApi}cuencasordenamiento/anual/${event.value}`);
        let aux = cue.getDatosOrdenamiento(d);
        setDatosCuencas(aux);
        //setDatosCuencas(d);
      }
      //termina la peticion de informacion
    } else {
      setAnioSCuencas();
    }
  }

  function selectAnioCuencasr(event) {
    if (event !== null) {
      setAnioSCuencas(event);
      var cue = new Cuencas();
      let datos;
      if (tipoRepCuenca === "RHA") {
        let d = DatosApi(`${rootApi}cuencasrha/anual/${event.value}`);
        //formatoReportes(d);
        //datos = cue.quitarNull(d);
        d.map((item, index) => {
          item.id = index;
        });
        cue.sortJSON(d, "claverha");

        setFilas(d);
      }
      if (tipoRepCuenca === "RH") {
        let d = DatosApi(`${rootApi}cuencasrh/anual/${event.value}`);
        //formatoReportes(d)
        //datos = cue.quitarNull(d);
        d.map((item, index) => {
          item.id = index;
        });
        cue.sortJSON(d, "claverha");
        setFilas(d);
      }
      setTipoRepCuenca(tipoRepCuenca);
    } else {
      setAnioSCuencas();
    }
  }

  function selectAnioCuencasZP(event) {
    if (event !== null) {
      setAnioSCuencas(event);
    } else {
      setAnioSCuencas();
    }
  }


  function selectAnioCuencasOV(event) {
    if (event !== null) {
      setAnioSCuencas(event);
    } else {
      setAnioSCuencas();
    }
  }


  function selectAnioPrecipitacion(event) {
    if (event !== null) {
      setAniosPrecipitacion(event);
    } else {
      setAniosPrecipitacion();
    }
  }


  function mostrarCapasPrecipitacion(name, titulo, filtro, funcionC) {

    if (aniosPrecipitacion === null || aniosPrecipitacion === undefined) {
      mensaje("Selecciona año de consulta", "error");
    } else {
      aux = funcionC;
      let capaWFS = cap.agregarCapas(
        "16",
        capasVisualizadas,
        name,
        titulo,
        filtro,
        aniosPrecipitacion.value,
        "",
        ""
      );
      //console.log(capaWFS);


      if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
        mensaje2("Cargando informacion", "info");

        $.ajax({
          url: capaWFS.urlS,
          async: true,
          type: 'GET',
          success: function (response) {
            capaWFS.response = response;
            capaWFS["attribution"] = "No disponible";
            capaWFS["transparencia"] = 1;
            capaWFS["tipo"] = "wfs";
            capaWFS["habilitado"] = true;
            capaWFS["format"] = "json";
            capaWFS["servicio"] = root;
            capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
            capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
            capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
            capaWFS["funcion"] = funcionC;
            capaWFS["TAtributos"] = "Precipitacion"

            zIndexCapas = zIndexCapas + 1;
            mapaReferencia.createPane(`${zIndexCapas}`);
            mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
              numeroIndex + capasVisualizadas.length;

            var geojson = new L.geoJson(capaWFS.response, {
              pointToLayer: function (feature, latlng) {
                return L.circleMarker(latlng, {
                  fillColor: eval(
                    `${funcionC}('${feature.properties[parametro]}')`
                  ),
                  weight: 1,
                  opacity: 1,
                  color: "#b2b2b2",
                  fillOpacity: 0.9,
                  radius: 5,
                });
              },
              style: estiloInd,
              pane: `${zIndexCapas}`,
              onEachFeature: function (feature = {}, subLayer) {
                feature["nombre_capa"] = name;
                feature["titulo"] = titulo;
                var pop = infoCapas.getEstados(feature.properties, name);
                subLayer.bindPopup(pop, { minWidth: 250, maxHeight: 600 });
                // eslint-disable-next-line no-unused-expressions
                subLayer.on("dblclick", function () {
                  if (feature["seleccionada"] === true) {
                    let mycolor;
                    feature["seleccionada"] = false;

                    if (
                      feature.titulo === "Distribución de la precipitación normal"
                    ) {
                      mycolor = {
                        fillColor: Sim.getPrecipitacionNormal(
                          `${feature.properties.anual}`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 0,
                      };
                    }
                    if (feature.titulo === "Distribución de la precipitación pluvial anual") {
                      mycolor = {
                        fillColor: Sim.getPrecipitacionAnual(
                          `${feature.properties.anual}`
                        ),
                        weight: 1.5,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    if (mycolor === undefined) {
                      mycolor = {
                        fillColor: eval(
                          `Sim.${funcionC}('${feature.properties[parametro]}')`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 1,
                      };
                    }
                    subLayer.setStyle({
                      fillColor: mycolor.fillColor,
                      color: mycolor.color,
                    });
                    for (var i = 0; i < featureSeleccion.length; i++) {
                      if (featureSeleccion[i].feature.id === feature.id) {
                        featureSeleccion.splice(i, 1);
                      }
                    }
                  } else {
                    feature["seleccionada"] = true;
                    subLayer.setStyle({
                      fillColor: ColorSeleccion,
                      color: bordeSeleccion,
                    });
                    featureSeleccion.push(subLayer);
                  }
                  setSublayerSeleccionada(featureSeleccion);
                }),
                  subLayer.on({
                    //mouseover: highlightFeature,
                  });
              },
            });
            //console.log(filtro);
            capaWFS["funcion"] = funcionC;
            capaWFS["layer"] = geojson;
            setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
            mapaReferencia.addLayer(capaWFS.layer);
            mapaReferencia.fitBounds(capaWFS.layer.getBounds());
            desplegarLeyendasActivas(capaWFS, funcionC);

            Swal.close();
          },
        });

      } else {
        //se manda msj de error o de que no hay datos
        mensaje("Capa cargada", "info")
      }
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setAniosPrecipitacion([]);
    }, 3000);
  }

  const [modalPrecipitacionReportes, setModalPrecipitacionReportes] = useState(false);
  const [modalPrecipitacionGraficas, setModalPrecipitacionGraficas] = useState(false);
  const [aniosPrecipitacion, setAniosPrecipitacion] = useState();
  const [tipReportePrecipitacion, setTipReportePrecipitacion] = useState("");
  const [tipGraficaPrecipitacion, setTipGraficaPrecipitacion] = useState("");

  function reportesPrecipitacion() {
    setTipReportePrecipitacion("");
    setModalPrecipitacionReportes(!modalPrecipitacionReportes);
  }

  function graficasPrecipitacion() {
    setFilas([]);
    setModalPrecipitacionGraficas(!modalPrecipitacionGraficas);
  }

  function selectAnioPrecipitacionN(event) {
    if (event != null) {
      fetch(`${rootApi}precipitacionNormal/anio/${event.value}`)
        .then(response => response.json())
        .then(data => {
          formatoReportes(data)
          setFilas(data);
        });

      setAniosPrecipitacion(event)
    } else {
      setAniosPrecipitacion()
    }
  }

  function selectAnioPrecipitacionRHA(event) {
    if (event != null) {
      fetch(`${rootApi}precipitacionRHA/anio/${event.value}`)
        .then(response => response.json())
        .then(data => {
          formatoReportes(data)
          setFilas(data);
        });

      setAniosPrecipitacion(event)
    } else {
      setAniosPrecipitacion()
    }
  }

  function selectAnioPrecipitacionAnual(event) {
    if (event != null) {
      fetch(`${rootApi}PluvialAnual/anio/${event.value}`)
        .then(response => response.json())
        .then(data => {
          formatoReportes(data)
          setFilas(data);
        });

      setAniosPrecipitacion(event)
    } else {
      setAniosPrecipitacion()
    }
  }


  function tipoReportePrecipitacion(tipo) {
    setAniosPrecipitacion();
    setFilas([]);

    if (tipo === "reporte1") {
      fetch(`${rootApi}distribucion/reporte`)
        .then(response => response.json())
        .then(data => {
          formatoReportes(data);
          //console.log(data);
          formatoReportes(data);
          setFilas(data);
        });
      setTipReportePrecipitacion("reporte1");
    }
    if (tipo === "reporte2") {

      setTipReportePrecipitacion("reporte2");
    }
    if (tipo === "reporte3") {

      setTipReportePrecipitacion("reporte3");
    }
    if (tipo === "reporte4") {

      setTipReportePrecipitacion("reporte4");
    }

  }

  function tipoGraficaPrecipitacion(tipo) {
    setFilas([]);
    let preci = new Precipitacion();

    if (tipo === "grafica1") {
      fetch(`${rootApi}PrecipitacionNormalGraf/grafica`)
        .then(response => response.json())
        .then(data => {
          let d = preci.agruparMes(data);
          //console.log(d);
          setFilas(d);
        });
      setTipGraficaPrecipitacion("grafica1");
    }
    if (tipo === "grafica2") {
      fetch(`${rootApi}PluvialAnualG/grafica`)
        .then(response => response.json())
        .then(data => {
          let d = preci.getLimpiar(data);
          setFilas(d);
        });

      setTipGraficaPrecipitacion("grafica2");
    }
    if (tipo === "grafica3") {

      fetch(`${rootApi}PluvialAnualMen/grafica`)
        .then(response => response.json())
        .then(data => {
          let d = preci.agruparMes(data);
          //console.log(d);
          setFilas(d);
        });


      //setFilas(precipitacionAnual);
      setTipGraficaPrecipitacion("grafica3");
    }
  }

  //funcion para los estilos de Ciclones
  function styleC(feature) {
    /* console.log(feature.properties["catimpacto"]);
   if(feature.properties.nombreciclon === "ZETA") {
     console.log(feature.properties);
   }*/


    if (
      feature.properties["catimpacto"] === "Depresión Tropical"
    ) {
      return {
        rotationAngle: 0.0,
        rotationOrigin: "center center",
        icon: L.icon({
          iconUrl: "images/depresion.png",
          iconSize: [30, 30],
        }),
        interactive: true,
      };
    } else
      if (
        feature.properties["catimpacto"] === "Tormenta Tropical"
      ) {
        return {
          rotationAngle: 0.0,
          rotationOrigin: "center center",
          icon: L.icon({
            iconUrl: "images/TormentaTropical.png",
            iconSize: [30, 30],
          }),
          interactive: true,
        };
      } else
        if (
          feature.properties["catimpacto"] === "Huracán categoria 1"
        ) {
          return {
            rotationAngle: 0.0,
            rotationOrigin: "center center",
            icon: L.icon({
              iconUrl: "images/huracan01.png",
              iconSize: [30, 30],
            }),
            interactive: true,
          };
        } else
          if (
            feature.properties["catimpacto"] === "Huracán categoria 2"
          ) {
            return {
              rotationAngle: 0.0,
              rotationOrigin: "center center",
              icon: L.icon({
                iconUrl: "images/huracan02.png",
                iconSize: [30, 30],
              }),
              interactive: true,
            };
          } else
            if (
              feature.properties["catimpacto"] === "Huracán categoria 3"
            ) {
              return {
                rotationAngle: 0.0,
                rotationOrigin: "center center",
                icon: L.icon({
                  iconUrl: "images/huracan03.png",
                  iconSize: [30, 30],
                }),
                interactive: true,
              };
            } else
              if (
                feature.properties["catimpacto"] === "Huracán categoria 4"
              ) {
                return {
                  rotationAngle: 0.0,
                  rotationOrigin: "center center",
                  icon: L.icon({
                    iconUrl: "images/huracan04.png",
                    iconSize: [30, 30],
                  }),
                  interactive: true,
                };
              } else
                if (
                  feature.properties["catimpacto"] === "Huracán categoria 5"
                ) {
                  return {
                    rotationAngle: 0.0,
                    rotationOrigin: "center center",
                    icon: L.icon({
                      iconUrl: "images/huracan05.png",
                      iconSize: [30, 30],
                    }),
                    interactive: true,
                  };
                }
  }

  function styleLine(feature) {
    //console.log(feature);    
    return {
      weight: 2.5,
      color: eval(
        `Sim.getTrayectorias('${feature.properties["anio"]}')`
      ),
      opacity: 1.0,
    };


  };

  const [modalCiclonesReportes, setModalCiclonesReportes] = useState(false);
  const [modalCiclonesGraficas, setModalCiclonesGraficas] = useState(false);
  const [aniosCiclones, setAniosCiclones] = useState();
  const [tipReporteCiclones, setTipReporteCiclones] = useState("");
  const [tipGraficaCiclones, setTipGraficaCiclones] = useState("");

  function reportesCiclones() {
    setTipReporteCiclones("");
    setModalCiclonesReportes(!modalCiclonesReportes);
  }

  function graficasCiclones() {
    setFilas([]);
    setModalCiclonesGraficas(!modalCiclonesGraficas);
  }

  function selectAnioCiclones(event) {
    if (event !== null) {
      setAniosCiclones(event);
    } else {
      setAniosCiclones();
    }
  }

  function selectAnioCiclonesR(event) {
    if (event !== null) {
      fetch(`${rootApi}ciclones/impactados/${event.value}`)
        .then(response => response.json())
        .then(data => {
          formatoReportes(data)
          setFilas(data);
        });

      setAniosCiclones(event);
    } else {
      setAniosCiclones();
    }
  }

  function tipoReporteCiclones(tipo) {
    setAniosCiclones();
    setFilas([]);

    if (tipo === "reporte1") {
      setTipReporteCiclones("reporte1");
    }
    if (tipo === "reporte2") {
      fetch(`${rootApi}costa/all`)
        .then(response => response.json())
        .then(data => {
          formatoReportes(data);
          //console.log(data);
          formatoReportes(data);
          setFilas(data);
        });
      setTipReporteCiclones("reporte2");
    }
  }

  function tipoGraficaCiclones(tipo) {
    setFilas([]);
    if (tipo === "grafica1") {
      fetch(`${rootApi}porCategorias/all`)
        .then(response => response.json())
        .then(data => {
          let d = ciclon.agruparPorAnio(data);
          setFilas(d);
        });
      setTipGraficaCiclones("grafica1");
    }
    if (tipo === "grafica2") {
      fetch(`${rootApi}impactoG/all`)
        .then(response => response.json())
        .then(data => {
          let d1 = ciclon.agruparPorAnioImpacto(data);
          //console.log(d1);
          setFilas(d1);
        });

      setTipGraficaCiclones("grafica2");
    }
  }

  function mostrarCapasCiclones(name, titulo, filtro, funcionC) {

    if (aniosCiclones === null || aniosCiclones === undefined) {
      mensaje("Selecciona año de consulta", "error");
    } else
      if (name === "m18_ciclonestropicales") {
        aux = funcionC;
        let capaWFS = cap.agregarCapas(
          "18",
          capasVisualizadas,
          name,
          titulo,
          filtro,
          aniosCiclones.value,
          "",
          ""
        );
        //console.log(capaWFS);


        if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
          mensaje2("Cargando informacion", "info");

          $.ajax({
            url: capaWFS.urlS,
            async: true,
            type: 'GET',
            success: function (response) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
              capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
              capaWFS["funcion"] = funcionC;
              capaWFS["TAtributos"] = "Ciclones"

              zIndexCapas = zIndexCapas + 1;
              mapaReferencia.createPane(`${zIndexCapas}`);
              mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                numeroIndex + capasVisualizadas.length;

              var geojson = new L.geoJson(response, {
                pointToLayer: function (feature, latlng) {
                  return L.marker(latlng, styleC(feature));
                },
                style: estiloInd,
                pane: `${zIndexCapas}`,
                onEachFeature: function (feature = {}, subLayer) {
                  feature["nombre_capa"] = name;
                  feature["titulo"] = titulo;
                  var pop = infoCapas.getEstados(feature.properties, name);
                  subLayer.bindPopup(pop, { minWidth: 250, maxHeight: 600 });
                  // eslint-disable-next-line no-unused-expressions
                  subLayer.on("dblclick", function () {
                    if (feature["seleccionada"] === true) {
                      let mycolor;
                      feature["seleccionada"] = false;
                      if (mycolor === undefined) {
                        mycolor = {
                          fillColor: eval(
                            `Sim.${funcionC}('${feature.properties[parametro]}')`
                          ),
                          weight: 1,
                          color: "#232323",
                          fillOpacity: 1,
                        };
                      }
                      subLayer.setStyle({
                        fillColor: mycolor.fillColor,
                        color: mycolor.color,
                      });
                      for (var i = 0; i < featureSeleccion.length; i++) {
                        if (featureSeleccion[i].feature.id === feature.id) {
                          featureSeleccion.splice(i, 1);
                        }
                      }
                    } else {
                      feature["seleccionada"] = true;
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      featureSeleccion.push(subLayer);
                    }
                    setSublayerSeleccionada(featureSeleccion);
                  }),
                    subLayer.on({
                      //mouseover: highlightFeature,
                    });
                },
              });
              //console.log(filtro);
              capaWFS["funcion"] = funcionC;
              capaWFS["layer"] = geojson;
              setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
              mapaReferencia.addLayer(capaWFS.layer);
              mapaReferencia.fitBounds(capaWFS.layer.getBounds());
              desplegarLeyendasActivas2(capaWFS, funcionC);

              Swal.close();
              setAniosCiclones([]);
            },
          });
          Swal.close();
        } else {
          //se manda msj de error o de que no hay datos
          mensaje("Capa cargada", "info")
        }
      }
      else {
        aux = funcionC;
        let capaWFS = cap.agregarCapas(
          "18",
          capasVisualizadas,
          name,
          titulo,
          filtro,
          aniosCiclones.value,
          "",
          ""
        );
        //console.log(capaWFS);


        if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
          mensaje2("Cargando informacion", "info");

          $.ajax({
            url: capaWFS.urlS,
            async: true,
            type: 'GET',
            success: function (response) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
              capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
              capaWFS["funcion"] = funcionC;
              capaWFS["TAtributos"] = "Ciclones"

              zIndexCapas = zIndexCapas + 1;
              mapaReferencia.createPane(`${zIndexCapas}`);
              mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                numeroIndex + capasVisualizadas.length;

              var geojson = new L.geoJson(capaWFS.response, {
                pointToLayer: function (feature, latlng) {
                  return L.circleMarker(latlng, {
                    fillColor: eval(
                      `${funcionC}('${feature.properties[parametro]}')`
                    ),
                    weight: 1,
                    opacity: 1,
                    color: "#b2b2b2",
                    fillOpacity: 0.9,
                    radius: 5,
                  });
                },
                style: styleLine,
                pane: `${zIndexCapas}`,
                onEachFeature: function (feature = {}, subLayer) {
                  feature["nombre_capa"] = name;
                  feature["titulo"] = titulo;
                  var pop = infoCapas.getEstados(feature.properties, name);
                  subLayer.bindPopup(pop, { minWidth: 250, maxHeight: 600 });
                  // eslint-disable-next-line no-unused-expressions
                  subLayer.on("dblclick", function () {
                    if (feature["seleccionada"] === true) {
                      let mycolor;
                      feature["seleccionada"] = false;
                      mycolor = {
                        fillColor: Sim.getTrayectorias(
                          `${feature.properties.anio}`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 0,
                      };

                      subLayer.setStyle({
                        fillColor: mycolor.fillColor,
                        color: mycolor.color,
                      });
                      for (var i = 0; i < featureSeleccion.length; i++) {
                        if (featureSeleccion[i].feature.id === feature.id) {
                          featureSeleccion.splice(i, 1);
                        }
                      }
                    } else {
                      feature["seleccionada"] = true;
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      featureSeleccion.push(subLayer);
                    }
                    setSublayerSeleccionada(featureSeleccion);
                  }),
                    subLayer.on({
                      //mouseover: highlightFeature,
                    });
                },
              });
              //console.log(filtro);
              capaWFS["funcion"] = funcionC;
              capaWFS["layer"] = geojson;
              setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
              mapaReferencia.addLayer(capaWFS.layer);
              mapaReferencia.fitBounds(capaWFS.layer.getBounds());
              desplegarLeyendasActivas(capaWFS, funcionC);

              Swal.close();
              setAniosCiclones([]);
            },
          });

        } else {
          //se manda msj de error o de que no hay datos
          mensaje("Capa cargada", "info")
        }
      }

    setTimeout(() => {
      //console.log("1 Segundo esperado")

    }, 3000);
  }


  const [anioSIH, setAnioSIH] = useState();
  function selectAniosSIH(event) {
    if (event !== null) {
      setAnioSIH(event);
      setMesSIH();
    } else {
      setAnioSIH();
      setMesSIH();
      setFilas([]);
    }
  }

  const [mesSIH, setMesSIH] = useState();
  function selectMesSIH(event) {
    if (event !== null) {
      fetch(`${rootApi1}ReporteSIH/fecha/${anioSIH.value}/${event.value}`)
        .then(response => response.json())
        .then(data => setFilas(data));
      setMesSIH(event);
    } else {
      setMesSIH();
      setFilas([]);
    }
  }

  //const [aniosDistritos, setAniosDistritos] = useState([])
  const [anioDistrito, setAnioDistrito] = useState();
  function selectAniosDistritos(event) {
    if (event !== null) {
      setAnioDistrito(event);
    } else {
      setAnioDistrito();
    }
  }

  function mostrarCapasDistritos(name, titulo, filtro, funcionC, tituloT) {
    aux = funcionC;
    let capaWFS;


    if (titulo === "Superficie sembrada" || titulo === "Superficies Físicas Regadas por Entidad" || titulo === "Valor de la Producción" || titulo === "Superficie Regada" || titulo === "Volúmenes Distribuidos") {
      //console.log(anioDistrito);
      if (anioDistrito !== undefined && anioDistrito !== null && anioDistrito.length !== 0) {
        //Se manda pedir la capa
        capaWFS = cap.agregarCapas(
          "07",
          capasVisualizadas,
          name,
          titulo,
          filtro,
          `'${anioDistrito.value}'`,
          "",
          ""
        );
        //console.log(capaWFS)

        if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {

          mensaje2("Cargando informacion", "info");

          $.ajax({
            url: capaWFS.urlS,
            async: true,
            type: 'GET',
            success: function (response) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS['tituloT'] = tituloT;
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
              capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
              capaWFS['tituloT'] = tituloT
              capaWFS["TAtributos"] = "Distritos de Riego"
              capaWFS['funcion'] = funcionC;
              //Esto es para agregar la capa
              zIndexCapas = zIndexCapas + 1;
              mapaReferencia.createPane(`${zIndexCapas}`);
              mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                numeroIndex + capasVisualizadas.length;

              var geojson = new L.geoJson(capaWFS.response, {
                pointToLayer: function (feature, latlng) {
                  return L.circleMarker(latlng, {
                    fillColor: eval(`${funcionC}('${feature.properties[parametro]}')`),
                    weight: 1,
                    opacity: 1,
                    color: "#b2b2b2",
                    fillOpacity: 0.9,
                    radius: 5,
                  });
                },
                style: estiloInd,
                pane: `${zIndexCapas}`,
                onEachFeature: function (feature = {}, subLayer) {
                  feature["nombre_capa"] = name;
                  feature["titulo"] = titulo;
                  var pop = infoCapas.getEstados(feature.properties, name);
                  subLayer.bindPopup(pop, { maxWidth: 400, maxHeight: 700 });
                  // eslint-disable-next-line no-unused-expressions
                  subLayer.on("dblclick", function () {
                    if (feature["seleccionada"] === true) {
                      let mycolor;
                      feature["seleccionada"] = false;

                      mycolor = {
                        fillColor: Sim.getSupRegada(`${feature.properties.totalgeneral}`),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 0.7,
                      };

                      subLayer.setStyle({
                        fillColor: mycolor.fillColor,
                        color: mycolor.color,
                      });
                      for (var i = 0; i < featureSeleccion.length; i++) {
                        if (featureSeleccion[i].feature.id === feature.id) {
                          featureSeleccion.splice(i, 1);
                        }
                      }
                    } else {
                      feature["seleccionada"] = true;
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      featureSeleccion.push(subLayer);
                    }
                    setSublayerSeleccionada(featureSeleccion);
                  }),
                    subLayer.on({
                      //mouseover: highlightFeature,
                    });
                },
              });

              capaWFS["layer"] = geojson;
              setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
              setLeyVisualizadas([capaWFS.leyenda, ...leyVisualizadas]);
              mapaReferencia.addLayer(capaWFS.layer);
              mapaReferencia.fitBounds(capaWFS.layer.getBounds());
              desplegarLeyendasActivas(capaWFS, funcionC);

              Swal.close();
            },
          });

        } else {
          //se manda msj de error o de que no hay datos
          //mensaje("La consulta no tiene información", "info");
          mensaje("Capa cargada", "info")
        }
      } else {
        //se manda msj
        mensaje("Selecciona año agricola", "error");
      }
    }

    if (titulo === "Superficie Sembrada por Entidad" || titulo === "Productividad del Cultivo por Entidad") {
      if (anioDistrito !== undefined && cultivoDistrito !== undefined) {
        //Se manda pedir la capa
        capaWFS = cap.agregarCapas(
          "07",
          capasVisualizadas,
          name,
          titulo,
          `cultivodr='${cultivoDistrito.value}' and anioagricola=`,
          `'${anioDistrito.value}'`,
          "",
          "",
          `${cultivoDistrito.value}`
        );
        //console.log(capaWFS)

        if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {

          mensaje2("Cargando informacion", "info");

          $.ajax({
            url: capaWFS.urlS,
            async: true,
            type: 'GET',
            success: function (response) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS['tituloT'] = tituloT;


              capaWFS['funcion'] = funcionC;
              //Esto es para agregar la capa
              zIndexCapas = zIndexCapas + 1;
              mapaReferencia.createPane(`${zIndexCapas}`);
              mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                numeroIndex + capasVisualizadas.length;

              if (titulo === "Productividad del Cultivo por Entidad") {
                capaWFS.response.features.map(item => {
                  item.properties['rendimiento'] = item.properties['rendimiento'].toFixed(2);
                })
              } else {
                capaWFS.response.features.map(item => {
                  item.properties['spsembrada'] = item.properties['spsembrada'].toFixed(2);
                })
              }
              capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
              capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
              capaWFS["TAtributos"] = "Distritos de Riego"


              var geojson = new L.geoJson(capaWFS.response, {
                pointToLayer: function (feature, latlng) {
                  return L.circleMarker(latlng, {
                    fillColor: eval(
                      `${funcionC}('${feature.properties[parametro]}')`
                    ),
                    weight: 1,
                    opacity: 1,
                    color: "#b2b2b2",
                    fillOpacity: 0.9,
                    radius: 5,
                  });
                },
                style: estiloInd,
                pane: `${zIndexCapas}`,
                onEachFeature: function (feature = {}, subLayer) {
                  feature["nombre_capa"] = name;
                  feature["titulo"] = titulo;
                  var pop = infoCapas.getEstados(feature.properties, name);
                  subLayer.bindPopup(pop, { maxHeight: 700 });
                  // eslint-disable-next-line no-unused-expressions
                  subLayer.on("dblclick", function () {
                    if (feature["seleccionada"] === true) {
                      let mycolor;
                      feature["seleccionada"] = false;

                      mycolor = {
                        fillColor: Sim.getTipoCultivo(
                          `${feature.properties.cultivodr}`, `${feature.properties.spsembrada}`
                        ),
                        weight: 1,
                        color: "#232323",
                        fillOpacity: 0.7,
                      };

                      subLayer.setStyle({
                        fillColor: mycolor.fillColor,
                        color: mycolor.color,
                      });
                      for (var i = 0; i < featureSeleccion.length; i++) {
                        if (featureSeleccion[i].feature.id === feature.id) {
                          featureSeleccion.splice(i, 1);
                        }
                      }
                    } else {
                      feature["seleccionada"] = true;
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      featureSeleccion.push(subLayer);
                    }
                    setSublayerSeleccionada(featureSeleccion);
                  }),
                    subLayer.on({
                      //mouseover: highlightFeature,
                    });
                },
              });

              capaWFS["layer"] = geojson;
              setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
              setLeyVisualizadas([capaWFS.leyenda, ...leyVisualizadas]);
              mapaReferencia.addLayer(capaWFS.layer);
              mapaReferencia.fitBounds(capaWFS.layer.getBounds());
              desplegarLeyendasActivas1(capaWFS, funcionC, cultivoDistrito.value);
              //setAnioDistrito();
              setSelectCultivo(); //cultivoDistrito

              Swal.close();
            },
          });

        } else {
          //se manda msj de error o de que no hay datos
          //mensaje("La consulta no tiene información", "info");
          mensaje("Capa cargada", "info")
        }
      } else {
        //se manda msj
        mensaje("Selecciona año agricol y Tipo de cultivo", "error");
      }
      setSelectCultivo();
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setAnioDistrito([]);
      setSelectCultivo([])

    }, 3000);

  }


  const [aniosrepda, setAniosRepda] = useState([]);
  function mostrarCapasRepda(name, titulo, filtro, funcionC, tituloT) {
    if (anioRepda === undefined || anioRepda === null) {
      mensaje("Selecciona año de consulta", "error");
    } else {
      aux = funcionC;
      if (entidadSelectRepda !== null && entidadSelectRepda !== undefined) {

        let capaWFS = cap.agregarCapas(
          "01",
          capasVisualizadas,
          name,
          titulo,
          filtro,
          anioRepda.value,
          entidadSelectRepda,
          ""
        );
        //console.log(capaWFS)

        if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {
          mensaje2("Cargando informacion", "info");

          $.ajax({
            url: capaWFS.urlS,
            async: true,
            type: 'GET',
            success: function (response) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["datos2"] = datosDescargaCVS(response.features); //datos para descargar CVS
              capaWFS["datos"] = darFormato(response.features);//datos a mostrar en la tabla de atributos 
              capaWFS["features"] = copiarFeatures(response.features); //datos que se muestran en el mapa
              capaWFS["funcion"] = funcionC;
              capaWFS["tituloT"] = tituloT;
              capaWFS["TAtributos"] = "Repda"

              //Esto es para agregar la capa
              zIndexCapas = zIndexCapas + 1;
              mapaReferencia.createPane(`${zIndexCapas}`);
              mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                numeroIndex + capasVisualizadas.length;
              var geojson = new L.geoJson(capaWFS.response, {
                pointToLayer: function (feature, latlng) {
                  return L.circleMarker(latlng, {
                    fillColor: "#D35400", //eval(`Sim.getCensoLoc('${feature.properties['pobtot']}')`),
                    weight: 1,
                    opacity: 1,
                    color: "#b2b2b2",
                    fillOpacity: 0.9,
                    radius: 5,
                  });
                },
                style: estiloInd,
                pane: `${zIndexCapas}`,
                onEachFeature: function (feature = {}, subLayer) {
                  feature["nombre_capa"] = name;
                  feature["titulo"] = titulo;
                  var popupContent = "";
                  // eslint-disable-next-line no-multi-str
                  if (
                    name === "m01_aguasresiduales" ||
                    name === "m01_aprovsubt" ||
                    name === "m01_aprovsuper" ||
                    name === "m01_zonasfederales"
                  ) {
                    popupContent =
                      '<table class="table table-borderless">\
                                    <tr>\
                                      <th scope="row">Año</th>\
                                      <td>' + feature.properties["corterepda"] + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Estado</th>\
                                      <td>' + feature.properties["estado"] + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Municipio</th>\
                                      <td>' + (feature.properties["municipio"] !== null ? feature.properties["municipio"] : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row" colspan="2" class="text-center">USOS CONSUNTIVOS</th>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Agropecuario</th>\
                                      <td>' + (feature.properties["Agropecuario"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["Agropecuario"])) : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Agua Potable</th>\
                                      <td>' + (feature.properties["Agua Potable"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["Agua Potable"])) : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Ecológico</th>\
                                      <td>' + (feature.properties["Ecológico"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["Ecológico"])) : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Industrial</th>\
                                      <td>' + (feature.properties["Industrial Integrado"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["Industrial Integrado"])) : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Termoeléctrico</th>\
                                      <td>' + (feature.properties["Termoeléctricas"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["Termoeléctricas"])) : "") + '</td>\
                                    <tr>\
                                      <th scope="row" colspan="2" class="text-center">USOS NO CONSUNTIVOS</th>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Hidroeléctrico</th>\
                                      <td>' + (feature.properties["Hidroeléctricas"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["Hidroeléctricas"])) : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <td scope="row" colspan="2" class="text-center">'+ (name === "m01_zonasfederales" ? "El área se expresa en m<sup>2</sup>" : "El volumen se expresa en hm<sup>3</sup>") + '</td>\
                                    </tr>\
                                </table>';

                    subLayer.bindPopup(popupContent, { maxHeight: 700 });
                  }
                  if (
                    name === "m01_concesionesAR" ||
                    name === "m01_consesionesSB" ||
                    name === "m01_consesionesSP"
                  ) {
                    popupContent =
                      '<table class="table table-borderless">\
                                    <tr>\
                                      <th scope="row">Año</th>\
                                      <td>' + feature.properties["corte"] + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Estado</th>\
                                      <td>' + feature.properties["estado"] + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Municipio</th>\
                                      <td>' + (feature.properties["municipio"] !== null ? feature.properties["municipio"] : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row" colspan="2" class="text-center">TOTAL DE CONCESIONES</th>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Concesiones</th>\
                                      <td>' + (feature.properties["total"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(feature.properties["total"])) : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <th scope="row">Volumen total</th>\
                                      <td>' + (feature.properties["voltot"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["voltot"])) : "") + '</td>\
                                    </tr>\
                                    <tr>\
                                      <td scope="row" colspan="2" class="text-center">El volumen se expresa en hm<sup>3</sup></td>\
                                    </tr>\
                                </table>';

                    subLayer.bindPopup(popupContent, { maxHeight: 700 });
                  }
                  if (name === "m01_consesionesZF") {
                    var popupContent =
                      '<table class="table table-borderless">\
                          <tr>\
                            <th scope="row">Año</th>\
                            <td>' + feature.properties["corte"] + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Estado</th>\
                            <td>' + feature.properties["estado"] + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Municipio</th>\
                            <td>' + (feature.properties["municipio"] !== null ? feature.properties["municipio"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row" colspan="2" class="text-center">TOTAL DE CONCESIONES</th>\
                          </tr>\
                          <tr>\
                            <th scope="row">Concesiones</th>\
                            <td>' + (feature.properties["total"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 0 }).format(feature.properties["total"])) : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Área total</th>\
                            <td>' + (feature.properties["area"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["area"])) : "") + '</td>\
                          </tr>\
                          <tr>\
                            <td scope="row" colspan="2" class="text-center">Las áreas se expresan en m<sup>2</sup></td>\
                          </tr>\
                        </table>';

                    subLayer.bindPopup(popupContent, { maxHeight: 700 });
                  }
                  if (name === "m01_aprovechamientosAR") {
                    popupContent =
                      '<table class="table table-borderless">\
                          <tr>\
                            <th scope="row">Año</th>\
                            <td>' + feature.properties["corterepda"] + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Estado</th>\
                            <td>' + feature.properties["estado"] + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Municipio</th>\
                            <td>' + (feature.properties["nonmbremun"] !== null ? feature.properties["nonmbremun"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Clave RHA</th>\
                            <td>' + (feature.properties["claverha"] !== null ? feature.properties["claverha"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">RHA</th>\
                            <td>' + (feature.properties["nomrha"] !== null ? feature.properties["nomrha"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Título</th>\
                            <td>' + (feature.properties["titulo"] !== null ? feature.properties["titulo"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Titular</th>\
                            <td>' + (feature.properties["titular"] !== null ? feature.properties["titular"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Aprovechamientos</th>\
                            <td>' + (feature.properties["numapro"] !== null ? feature.properties["numapro"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Uso</th>\
                            <td>' + (feature.properties["uso"] !== null ? feature.properties["uso"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Volumen</th>\
                            <td>' + (feature.properties["volanual"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["volanual"])) : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Sector SCIAN</th>\
                            <td>' + (feature.properties["sciansector"] !== null ? feature.properties["sciansector"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Subrama SCIAN</th>\
                            <td>' + (feature.properties["sciansubrama"] !== null ? feature.properties["sciansubrama"] : "") + '</td>\
                          </tr>\
                          <tr>\
                            <th scope="row">Usuario</th>\
                            <td>' + (feature.properties["usuario"] !== null ? feature.properties["usuario"] : "") + '</td>\
                          </tr>\
                            <tr>\
                            <td scope="row" colspan="2" class="text-center">*El volumen se expresa en m<sup>3</sup>/año</td>\
                          </tr>\
                          <tr>\
                            <td scope="row" colspan="2" class="text-center">Coordenadas proporcionadas por el titular</td>\
                          </tr>\
                          <tr>\
                            <td scope="row" colspan="2" class="text-center">Región Hidrológica-Administrativa (RHA)</td>\
                          </tr>\
                        </table>';

                    subLayer.bindPopup(popupContent, { maxHeight: 700 });
                  }
                  if (
                    name === "m01_aprovechamientosSB" ||
                    name === "m01_aprovechamientosSP"
                  ) {
                    popupContent =
                      '<table class="table table-borderless">\
                        <tr>\
                          <th scope="row">Año</th>\
                          <td>' + feature.properties["corterepda"] + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Estado</th>\
                          <td>' + feature.properties["estado"] + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Municipio</th>\
                          <td>' + (feature.properties["nonmbremun"] !== null ? feature.properties["nonmbremun"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Clave RHA</th>\
                          <td>' + (feature.properties["claverha"] !== null ? feature.properties["claverha"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">RHA</th>\
                          <td>' + (feature.properties["nomrha"] !== null ? feature.properties["nomrha"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Título</th>\
                          <td>' + (feature.properties["titulo"] !== null ? feature.properties["titulo"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Titular</th>\
                          <td>' + (feature.properties["titular"] !== null ? feature.properties["titular"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Aprovechamientos</th>\
                          <td>' + (feature.properties["numapro"] !== null ? feature.properties["numapro"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Uso 1</th>\
                          <td>' + (feature.properties["uso1"] !== null ? feature.properties["uso1"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Volumen 1</th>\
                          <td>' + (feature.properties["vol1"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["vol1"])) : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Uso 2</th>\
                          <td>' + (feature.properties["uso2"] !== null ? feature.properties["uso2"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Volumen 2</th>\
                          <td>' + (feature.properties["vol2"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["vol2"])) : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Uso 3</th>\
                          <td>' + (feature.properties["uso3"] !== null ? feature.properties["uso3"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Volumen 3</th>\
                          <td>' + (feature.properties["vol3"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["vol3"])) : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Sector SCIAN</th>\
                          <td>' + (feature.properties["sciansector"] !== null ? feature.properties["sciansector"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Subrama SCIAN</th>\
                          <td>' + (feature.properties["sciansubrama"] !== null ? feature.properties["sciansubrama"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Usuario</th>\
                          <td>' + (feature.properties["usuario"] !== null ? feature.properties["usuario"] : "") + '</td>\
                        </tr>\
                        </tr>\
                            <tr>\
                            <td scope="row" colspan="2" class="text-center">*El volumen se expresa en m<sup>3</sup>/año</td>\
                          </tr>\
                          <tr>\
                            <td scope="row" colspan="2" class="text-center">Coordenadas proporcionadas por el titular</td>\
                          </tr>\
                          <tr>\
                            <td scope="row" colspan="2" class="text-center">Región Hidrológica-Administrativa (RHA)</td>\
                          </tr>\
                      </table>';

                    subLayer.bindPopup(popupContent, { maxWidth: 400, maxHeight: 700 });
                  }
                  if (name === "m01_aprovechamientosZF") {
                    popupContent =
                      '<table class="table table-borderless">\
                      <tr>\
                          <th scope="row">Año</th>\
                          <td>' + feature.properties["corterepda"] + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Estado</th>\
                          <td>' + feature.properties["estado"] + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Municipio</th>\
                          <td>' + (feature.properties["nonmbremun"] !== null ? feature.properties["nonmbremun"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Clave RHA</th>\
                          <td>' + (feature.properties["claverha"] !== null ? feature.properties["claverha"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">RHA</th>\
                          <td>' + (feature.properties["nomrha"] !== null ? feature.properties["nomrha"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Título</th>\
                          <td>' + (feature.properties["titulo"] !== null ? feature.properties["titulo"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Titular</th>\
                          <td>' + (feature.properties["titular"] !== null ? feature.properties["titular"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Aprovechamientos</th>\
                          <td>' + (feature.properties["numapro"] !== null ? feature.properties["numapro"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Uso 1</th>\
                          <td>' + (feature.properties["uso1"] !== null ? feature.properties["uso1"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Área 1</th>\
                          <td>' + (feature.properties["area"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["area"])) : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Uso 2</th>\
                          <td>' + (feature.properties["uso2"] !== null ? feature.properties["uso2"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Área 2</th>\
                          <td>' + (feature.properties["area2"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["area2"])) : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Uso 3</th>\
                          <td>' + (feature.properties["uso3"] !== null ? feature.properties["uso3"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Área 3</th>\
                          <td>' + (feature.properties["area3"] !== null ? (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(feature.properties["area3"])) : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Sector SCIAN</th>\
                          <td>' + (feature.properties["sciansector"] !== null ? feature.properties["sciansector"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Subrama SCIAN</th>\
                          <td>' + (feature.properties["sciansubrama"] !== null ? feature.properties["sciansubrama"] : "") + '</td>\
                        </tr>\
                        <tr>\
                          <th scope="row">Usuario</th>\
                          <td>' + (feature.properties["usuario"] !== null ? feature.properties["usuario"] : "") + '</td>\
                        </tr>\
                        <tr>\
                        <td scope="row" colspan="2" class="text-center">*Las áreas se expresan en  m<sup>2</sup></td>\
                      </tr>\
                      <tr>\
                        <td scope="row" colspan="2" class="text-center">Coordenadas proporcionadas por el titular</td>\
                      </tr>\
                      <tr>\
                        <td scope="row" colspan="2" class="text-center">Región Hidrológica-Administrativa (RHA)</td>\
                      </tr>\
                      </table>';

                    subLayer.bindPopup(popupContent, { maxWidth: 400, maxHeight: 700 });
                  }

                  // eslint-disable-next-line no-unused-expressions
                  subLayer.on("dblclick", function () {
                    if (feature["seleccionada"] === true) {
                      let color;
                      feature["seleccionada"] = false;

                      if (aux === "getUsosAR") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["total"]}')`)
                      }
                      if (aux === "getUsosSB") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["total"]}')`)
                      }
                      if (aux === "getUsosSP") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["total"]}')`)
                      }
                      if (aux === "getUsosZF") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["total"]}')`)
                      }
                      if (aux === "getConcesionesAR") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["voltot"]}')`)
                      }
                      if (aux === "getConcesionesSB") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["voltot"]}')`)
                      }
                      if (aux === "getConcesionesSP") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["voltot"]}')`)
                      }
                      if (aux === "getConcesionesZF") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["area"]}')`)
                      }
                      if (aux === "getAprovechamientosAR") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["volanual"]}')`)
                      }
                      if (aux === "getAprovechamientosSB") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["volanual"]}')`)
                      }
                      if (aux === "getAprovechamientosSP") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["voltot"]}')`)
                      }
                      if (aux === "getAprovechamientosZF") {
                        color = eval(`Sim.${capaWFS.funcion}('${feature.properties["superftot"]}')`)
                      }




                      subLayer.setStyle({
                        fillColor: color,
                        color: "#232323",
                        fillOpacity: 0.6
                      });
                      for (var i = 0; i < featureSeleccion.length; i++) {
                        if (featureSeleccion[i].feature.id === feature.id) {
                          featureSeleccion.splice(i, 1);
                        }
                      }
                    } else {
                      feature["seleccionada"] = true;
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      featureSeleccion.push(subLayer);
                    }
                    setSublayerSeleccionada(featureSeleccion);
                  });
                },
              });

              capaWFS["layer"] = geojson;
              setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
              setLeyVisualizadas([capaWFS.leyenda, ...leyVisualizadas]);
              mapaReferencia.addLayer(capaWFS.layer);
              mapaReferencia.fitBounds(capaWFS.layer.getBounds());
              desplegarLeyendasActivas(capaWFS, funcionC);
              Swal.close();
            },
          });






        } else {
          //mandar msj de error
          mensaje("Capa cargada", "info")
        }
      } else {
        mensaje("Selecciona Entidad Federativa", "error");
      }
    }

    setTimeout(() => {
      //console.log("1 Segundo esperado")
      setAnioRepda([]);
      setEntidadSelectRepda([]);
    }, 3000);

  }

  //Funciones para los colores
  //funciones para regresar el estilo
  function estiloInd(feature) {
    if (aux === "getPoblacionM") {
      return {
        fillColor: Sim.getPoblacionM(feature.properties.pobmas),
        weight: 1.5,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionF") {
      return {
        fillColor: Sim.getPoblacionF(feature.properties.pobfem),
        weight: 1.5,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionT") {
      return {
        fillColor: Sim.getPoblacionT(feature.properties.pobtot),
        weight: 1.5,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMas18mas") {
      return {
        fillColor: Sim.getPoblacionMas18mas(feature.properties.p_18ymas_m),
        weight: 1.5,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFem18mas") {
      return {
        fillColor: Sim.getPoblacionFem18mas(feature.properties.p_18ymas_f),
        weight: 1.5,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe18mas") {
      return {
        fillColor: Sim.getPoblacionDe18mas(feature.properties.p_18ymas),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMas15mas") {
      return {
        fillColor: Sim.getPoblacionMas15mas(feature.properties.p_15ymas_m),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFem15mas") {
      return {
        fillColor: Sim.getPoblacionFem15mas(feature.properties.p_15ymas_f),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe15mas") {
      return {
        fillColor: Sim.getPoblacionDe15mas(feature.properties["p_15ymas"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMas12mas") {
      return {
        fillColor: Sim.getPoblacionMas12mas(feature.properties["p_12ymas_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFem12mas") {
      return {
        fillColor: Sim.getPoblacionFem12mas(feature.properties["p_12ymas_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe12mas") {
      return {
        fillColor: Sim.getPoblacionDe12mas(feature.properties["p_12ymas"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMas5mas") {
      return {
        fillColor: Sim.getPoblacionMas5mas(feature.properties["p_5ymas"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFem5mas") {
      return {
        fillColor: Sim.getPoblacionFem5mas(feature.properties["p_15ymas_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe5mas") {
      return {
        fillColor: Sim.getPoblacionDe5mas(feature.properties["p_5ymas"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMas3mas") {
      return {
        fillColor: Sim.getPoblacionMas3mas(feature.properties["p_3ymas_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe3masFem") {
      return {
        fillColor: Sim.getPoblacionDe3masFem(feature.properties["p_3ymas_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe3mas") {
      return {
        fillColor: Sim.getPoblacionDe3mas(feature.properties["p_3ymas"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFde0a2") {
      return {
        fillColor: Sim.getPoblacionFde0a2(feature.properties["p_0a2_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob0a2Mas") {
      return {
        fillColor: Sim.getPob0a2Mas(feature.properties["p_0a2_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe0a2") {
      return {
        fillColor: Sim.getPoblacionDe0a2(feature.properties["p_0a2"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe3a5") {
      return {
        fillColor: Sim.getPoblacionDe3a5(feature.properties["p_3a5"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFde3a5") {
      return {
        fillColor: Sim.getPoblacionFde3a5(feature.properties["p_3a5_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMde3a5") {
      return {
        fillColor: Sim.getPoblacionMde3a5(feature.properties["p_3a5_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe6a11") {
      return {
        fillColor: Sim.getPoblacionDe6a11(feature.properties["p_6a11"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFde6a11") {
      return {
        fillColor: Sim.getPoblacionFde6a11(feature.properties["p_6a11_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMde6a11") {
      return {
        fillColor: Sim.getPoblacionMde6a11(feature.properties["p_6a11_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.6,
      };
    }
    if (aux === "getPoblacionDe8a14") {
      return {
        fillColor: Sim.getPoblacionDe8a14(feature.properties["p_8a14"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFde8a14") {
      return {
        fillColor: Sim.getPoblacionFde8a14(feature.properties["p_8a14_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMde8a14") {
      return {
        fillColor: Sim.getPoblacionMde8a14(feature.properties["p_8a14_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe12a14") {
      return {
        fillColor: Sim.getPoblacionDe12a14(feature.properties["p_12a14"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFde121a14") {
      return {
        fillColor: Sim.getPoblacionFde121a14(feature.properties["p_12a14_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMde12a14") {
      return {
        fillColor: Sim.getPoblacionMde12a14(feature.properties["p_12a14_m>"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe15a17") {
      return {
        fillColor: Sim.getPoblacionDe15a17(feature.properties["p_15a17"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFde151a17") {
      return {
        fillColor: Sim.getPoblacionFde151a17(feature.properties["p_15a17_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMde15a17") {
      return {
        fillColor: Sim.getPoblacionMde15a17(feature.properties["p_15a17_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.6,
      };
    }
    if (aux === "getPoblacionDe18a24") {
      return {
        fillColor: Sim.getPoblacionDe18a24(feature.properties["p_18a24"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFde18a24") {
      return {
        fillColor: Sim.getPoblacionFde18a24(feature.properties["p_18a24_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMde18a24") {
      return {
        fillColor: Sim.getPoblacionMde18a24(feature.properties["p_18a24_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.6,
      };
    }
    if (aux === "getPoblacionFde15a49") {
      return {
        fillColor: Sim.getPoblacionFde15a49(feature.properties["p_15a49_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe60mas") {
      return {
        fillColor: Sim.getPoblacionDe60mas(feature.properties["p_60ymas"], feature.properties["rendimiento"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFde60mas") {
      return {
        fillColor: Sim.getPoblacionFde60mas(feature.properties["p_60ymas_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMde60mas") {
      return {
        fillColor: Sim.getPoblacionMde60mas(feature.properties["p_60ymas_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getRelacionhm") {
      return {
        fillColor: Sim.getRelacionhm(feature.properties["rel_h_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe0a14") {
      return {
        fillColor: Sim.getPoblacionDe0a14(feature.properties["p_0a4"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    //getVoldist
    if (aux === "getPoblacionDe15a64") {
      return {
        fillColor: Sim.getPoblacionDe15a64(feature.properties["pob15_64"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionDe65mas") {
      return {
        fillColor: Sim.getTrayectorias(feature.properties["pob65_mas"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPromediohnv") {
      return {
        fillColor: Sim.getPromediohnv(feature.properties["prom_hnv"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFne") {
      return {
        fillColor: Sim.getPoblacionFne(feature.properties["pnacent_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMne") {
      return {
        fillColor: Sim.getPoblacionMne(feature.properties["pnacent_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionOen") {
      return {
        fillColor: Sim.getPoblacionOen(feature.properties["pnacoe"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFOen") {
      return {
        fillColor: Sim.getPoblacionFOen(feature.properties["pnacoe_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionmOen") {
      return {
        fillColor: Sim.getPoblacionmOen(feature.properties["pnacoe_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacion5marz2015") {
      return {
        fillColor: Sim.getPoblacion5marz2015(feature.properties["pobtot"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionF5marz2015") {
      return {
        fillColor: Sim.getPoblacionF5marz2015(feature.properties["pnacoe"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionM5marz2015") {
      return {
        fillColor: Sim.getPoblacionM5marz2015(feature.properties["pres2015_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob2") {
      return {
        fillColor: Sim.getPob2(feature.properties["presoe15"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionFotra2015") {
      return {
        fillColor: Sim.getPoblacionFotra2015(feature.properties["presoe15_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionMotra2015") {
      return {
        fillColor: Sim.getPoblacionMotra2015(feature.properties["pres2015_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPoblacionne") {
      return {
        fillColor: Sim.getPoblacionne(feature.properties["pnacent"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob3indigena") {
      return {
        fillColor: Sim.getPob3indigena(feature.properties["p3ym_hli"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobF3indigena") {
      return {
        fillColor: Sim.getPobF3indigena(feature.properties["p3ym_hli_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobM3indigena") {
      return {
        fillColor: Sim.getPobM3indigena(feature.properties["p3ym_hli_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob3indigesp") {
      return {
        fillColor: Sim.getPob3indigesp(feature.properties["p3hlinhe"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobF3indigesp") {
      return {
        fillColor: Sim.getPobF3indigesp(feature.properties["p3hlinhe_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobM3indigesp") {
      return {
        fillColor: Sim.getPobM3indigesp(feature.properties["p3hlinhe_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob3indigyesp") {
      return {
        fillColor: Sim.getPob3indigyesp(feature.properties["p3hli_he"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobF3indigyesp") {
      return {
        fillColor: Sim.getPobF3indigyesp(feature.properties["p3hli_he_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobM3indigyesp") {
      return {
        fillColor: Sim.getPobM3indigyesp(feature.properties["p3hli_he_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob5indigena") {
      return {
        fillColor: Sim.getPob5indigena(feature.properties["p5_hli"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob5indigyesp") {
      return {
        fillColor: Sim.getPob5indigyesp(feature.properties["p5_hli_he"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob5indigesp") {
      return {
        fillColor: Sim.getPob5indigesp(feature.properties["p5_hli_nhe"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobcensal") {
      return {
        fillColor: Sim.getPobcensal(feature.properties["phog_ind"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobafrmex") {
      return {
        fillColor: Sim.getPobafrmex(feature.properties["pob_afro"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobaFfrmex") {
      return {
        fillColor: Sim.getPobaFfrmex(feature.properties["pob_afro_f"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobaMfrmex") {
      return {
        fillColor: Sim.getPobaMfrmex(feature.properties["pob_afro_m"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPob3a5sines") {
      return {
        fillColor: Sim.getPob3a5sines(feature.properties["p3a5_noa"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobecogral") {
      return {
        fillColor: Sim.getPobecogral(feature.properties["pobtot"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getPobsinserv") {
      return {
        fillColor: Sim.getPobsinserv(feature.properties["pobtot"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getSituacionCony") {
      return {
        fillColor: Sim.getSituacionCony(feature.properties["pobtot"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getReligion") {
      return {
        fillColor: Sim.getReligion(feature.properties["pobtot"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getHogares") {
      return {
        fillColor: Sim.getHogares(feature.properties["pobtot"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getVivienda") {
      return {
        fillColor: Sim.getVivienda(feature.properties["pobtot"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getAreasCaniba") {
      return {
        fillColor: Sim.getAreasCaniba(feature.properties["tobreak"]),
        weight: 1,
        color: "#232323",
        fillOpacity: 0.7,
      };
    }
    if (aux === "getDistancias") {
      return {
        fillColor: Sim.getDistancias(feature.properties["totalweigh"]),
        weight: 1,
        color: Sim.getDistancias(feature.properties["totalweigh"]),
        fillOpacity: 0.7,
      };
    }
  }


  const [datosGlobales, setDatosGlobales] = useState([]);
  const [datosG, setDatosG] = useState([]);
  const [opciones, setOpciones] = useState([]);
  const [tipoG, setTipoG] = useState();
  const [ser, setSer] = useState([]);
  const [indus, setIndus] = useState([]);
  const [multi, setMulti] = useState([]);
  const [pecu, setPecu] = useState([]);
  const [acua, setAcua] = useState([]);
  const [publico, setPublico] = useState([]);
  const [agric, setAgric] = useState([]);
  const [domes, setDomes] = useState([]);
  const [graficaF, setGraficaF] = useState();
  const [munis, setMunis] = useState([]);
  const [edos, setEdos] = useState([]);
  const [tituloG, setTituloG] = useState();
  const [estadoSelect, setEstadoSelect] = useState();
  const [muniSelect, setMuniSelect] = useState();
  const [paramSelect, setParamSelect] = useState();
  const [numcolor, setNumcolor] = useState();

  function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  function shuffleArray(inputArray) {
    inputArray.sort(() => Math.random() - 0.5);
  }

  const [estado, setEstado] = useState();
  const [municipiosS, setMunicipiosS] = useState([]);
  function seleccionEstado(event) {
    if (event != null) {
      setEstadoSelect(event);
      setEntidadSelect(event);
      setMuniSelect(null);
      setParamSelect(null);
      setDatosG(null);
      fetch(`${rootApi}capasBase/municipios/${event.id}`)
        .then((response) => response.json())
        .then((data) => {

          sortJSON(data, 'label', 'asc')
          //console.log(data)
          setMunicipiosS(data)
        });
    } else {
      setEstadoSelect([]);
    }
  }

  function sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === 'asc') {
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      }

      if (orden === 'desc') {
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      }
    });
  }

  const [datosMunicipio, setDatosGMunicipio] = useState([]);
  const [municipio, setMunicipio] = useState();
  function sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];
      if (orden === "asc") {
        return x < y ? -1 : x > y ? 1 : 0;
      }
      if (orden === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }

  const [anioCon, setAnioCon] = useState(2020);
  const [anioCon3, setAnioCon3] = useState(2020);
  function selectAnio3(event) {
    if (event !== null) {
      setAnioCon3(event);
    } else {
      setAnioCon3(ciclo[0]);
    }
  }
  const [anioRepda, setAnioRepda] = useState(2020);
  function selectAniosRepda(event) {
    if (event !== null) {
      setAnioRepda(event);
    } else {
      setAnioRepda();
    }
  }

  //funcion para sumar o restar dias a las fechas
  function sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    return fecha;
  }

  //funcion para los estilos de presas
  function style(feature) {
    if (
      feature.properties["llenano"] * 100 >= 0.0 &&
      feature.properties["llenano"] * 100 < 20.0
    ) {
      return {
        rotationAngle: 0.0,
        rotationOrigin: "center center",
        icon: L.icon({
          iconUrl: "images/0-20.png",
          iconSize: [30, 30],
        }),
        interactive: true,
      };
    }
    if (
      feature.properties["llenano"] * 100 >= 20.0 &&
      feature.properties["llenano"] * 100 < 40.0
    ) {
      return {
        rotationAngle: 0.0,
        rotationOrigin: "center center",
        icon: L.icon({
          iconUrl: "images/20-40.png",
          iconSize: [30, 30],
        }),
        interactive: true,
      };
    }
    if (
      feature.properties["llenano"] * 100 >= 40.0 &&
      feature.properties["llenano"] * 100 < 60.0
    ) {
      return {
        rotationAngle: 0.0,
        rotationOrigin: "center center",
        icon: L.icon({
          iconUrl: "images/40-60.png",
          iconSize: [30, 30],
        }),
        interactive: true,
      };
    }
    if (
      feature.properties["llenano"] * 100 >= 60.0 &&
      feature.properties["llenano"] * 100 < 80.0
    ) {
      return {
        rotationAngle: 0.0,
        rotationOrigin: "center center",
        icon: L.icon({
          iconUrl: "images/60-80.png",
          iconSize: [30, 30],
        }),
        interactive: true,
      };
    }
    if (
      feature.properties["llenano"] * 100 >= 80.0 &&
      feature.properties["llenano"] * 100 <= 100.0
    ) {
      return {
        rotationAngle: 0.0,
        rotationOrigin: "center center",
        icon: L.icon({
          iconUrl: "images/80-100.png",
          iconSize: [30, 30],
        }),
        interactive: true,
      };
    }
    if (feature.properties["llenano"] * 100 >= 100.0) {
      return {
        rotationAngle: 0.0,
        rotationOrigin: "center center",
        icon: L.icon({
          iconUrl: "images/100.png",
          iconSize: [30, 30],
        }),
        interactive: true,
      };
    }
  }

  const [fechaConsulta, setFechaConsulta] = useState();
  //Metodo para visulizar los cluster de Presas
  function mostrarClusters(name, titulo, aux) {
    var hoy = new Date();

    //var urlApi = "http://201.116.60.25/monitoreopresasrios/servicio/index.php";
    var urlApi = "https://sinav30.conagua.gob.mx:8080/PresasPG";
    var urlMaxDate = urlApi + "/rest/fechamaxpresas";

    let encontrada = buscarCapa(titulo);
    if (encontrada === null) {
      let capaWFS = {};
      var urlMapa1 = "";

      if (hoy.getHours() < 13) {
        let aux = sumarDias(hoy, -1);
        urlMapa1 = urlApi + "/presas/reporte/" + formatDate(aux);
      } else {
        urlMapa1 = urlApi + "/presas/reporte/" + formatDate(hoy);
      }

      mensaje2("Cargando informacion", "info");
      $.ajax({
        url: urlMapa1,
        async: true,
        type: 'GET',
      }).done(function (response) {
        console.log(response)
        //setdatosG(response);
        let capaWFS = {};
        if (response.length == 0) {
          mensaje("La fecha consultada no contiene información", "info");
        } else {
          var geojson = {
            type: "FeatureCollection",
            features: [],
          };
          let Datos = [];
          response.map((item) => {
            let aux = {},
              aux1 = {};
            aux.type = "Feature";
            aux1.type = "Point";
            aux1.coordinates = [item.longitud, item.latitud];
            aux.geometry = aux1;
            //item.busqueda = `${item.Estado} | ${item.NombrePresa} | ${item.nombrecomun}`
            aux.properties = item;
            Datos.push(item);
            geojson.features.push(aux);
          });
          var clusters = L.markerClusterGroup({
            spiderfyOnMaxZoom: false,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            spiderfyDistanceMultiplier: 2,
            pane: `${zIndexCapas}`,
          });
          capaWFS["attribution"] = "No disponible";
          capaWFS["transparencia"] = 1;
          capaWFS["tipo"] = "wfs";
          capaWFS["habilitado"] = true;
          capaWFS["format"] = "json";
          capaWFS["servicio"] = urlMapa1;
          capaWFS["Date"] = fechaConsulta;
          capaWFS["name"] = name;
          capaWFS["nombre"] = titulo;
          capaWFS["titulo"] = titulo;

          capaWFS["datos2"] = datosDescargaCVS(geojson.features); //datos para descargar CVS
          capaWFS["datos"] = darFormato(geojson.features);//datos a mostrar en la tabla de atributos 
          capaWFS["features"] = copiarFeatures(geojson.features); //datos que se muestran en el mapa
          capaWFS["modulo"] = "presas";
          capaWFS["TAtributos"] = "Presas"
          capaWFS.isActive = true;
          zIndexCapas = zIndexCapas + 1;
          mapaReferencia.createPane(`${zIndexCapas}`);
          mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
            numeroIndex + capasVisualizadas.length;
          var datos = geojson;
          var geojsonL = new L.geoJson(datos, {
            pointToLayer: function (feature, latlng) {
              return L.marker(latlng, style(feature));
            },
            pane: `${zIndexCapas}`,
            onEachFeature: infoPresas,
          });
          //console.log(capaWFS)
          clusters.addLayer(geojsonL);
          capaWFS["layer"] = clusters;
          setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
          mapaReferencia.addLayer(capaWFS.layer);
          mapaReferencia.fitBounds(capaWFS.layer.getBounds());
          Swal.close();
        }
      });
    }
  }


  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  function descargarGeoJson(capa) {
    //console.log(capa)

    mensaje("Descargando Información", "info");

    $.getJSON(capa.descarga, function (data) {

      //console.log(data);
      var downloadAnchorNode = document.createElement("a");
      let capasVectoriales = {};
      capasVectoriales.type = "FeatureCollection";
      capasVectoriales.features = data.features;
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(capasVectoriales));

      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", `${capa.titulo}.json`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();


      //console.log(data);
    });


  }

  function infoPresas(feature, layer) {
    let ind = feature.properties.llenano * 100;
    var popupContent =
      `<div>
    <div class="row">
        <div class="text-center">
            <strong>${feature.properties.nombreoficial}</strong><br>
            ${feature.properties.nombrecomun}
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center infoPop">
            Situacion Actual
        </div>
        <div class="row textoInfo">
            <div><strong>Fecha:</strong> ${feature.properties.fechamonitoreo}</div>
            <div><strong>Elevación: </strong>${feature.properties.elevacionactual
      } msnm</div>
            <div><strong>Almacenamiento: </strong> ${feature.properties.almacenaactual
      } hm³</div>
            <div><strong>Porcentaje de llenado respecto al NAMO:</strong> ${ind.toFixed(
        3
      )}%</div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center infoPop">
            Características de la presa
        </div>
        <div class="textoInfo">
            <div><strong>Nivel de aguas máximas ordinarias (NAMO)</strong></div>
            <ul>
                <li>Elevación: ${feature.properties.namoelev} msnm</li>
                <li>Almacenamiento: ${feature.properties.namoalmac} hm³</li>
            </ul>
        </div>
        <div class="textoInfo">
            <div><strong>Nivel de aguas máximas extraordinarias (NAME)</strong></div>
            <ul>
                <li>Elevación: ${feature.properties.nameelev} msnm</li>
                <li>Almacenamiento: ${feature.properties.namealmac} hm³</li>
            </ul>
            <div><strong>Uso:</strong> ${feature.properties.uso}</div>
            <div><strong>Tipo de vertedor:</strong> ${feature.properties.tipovertedor
      }</div>
            <div><strong>Fecha de inicio de operación:</strong> ${feature.properties.inicioop
      }</div>
            <div><strong>Altura de cortina:</strong> ${feature.properties.alturacortina
      } m</div>
            <div><strong>Elevación de la corona:</strong> ${feature.properties.elevcorona
      } msnm</div>
            <div><strong>Bordo libre:</strong> ${feature.properties.bordolibre
      } m</div>    
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center infoPop">
                Ubicación
        </div>
        <div class="row textoInfo">
            <div><strong>Corriente donde se ubica:</strong> ${feature.properties.corriente}</div>
            <div><strong>Municipio: </strong>${feature.properties.nommunicipio}</div>
            <div><strong>Entidad federativa: </strong> ${feature.properties.estado}</div>
            <div><strong>Región administrativa:</strong> ${feature.properties.regioncna}</div>
      </div>
    </div>   
</div>` + '<div class="row text-center"> </div>';
    layer.bindPopup(popupContent, { maxHeight: 550, minWidth: 350 });
  }


  function fecha() {
    var hoy = new Date();
    var fecha;
    if (hoy.getHours() < 13) {
      fecha = sumarDias(hoy, -1);
    } else {
      fecha = hoy;
    }
    let aux =
      fecha.getFullYear() +
      "-" +
      (fecha.getMonth() + 1) +
      "-" +
      fecha.getDate();
    if (opcion === "monitoreo") {
      mostrarClusters("Presas", "Principales Presas de México", aux);
    }
    setFechaConsulta(aux);
  }

  function descargarShape(capa) {
    let defaultParameters1 = {
      service: "WFS",
      version: "1.0.0",
      request: "GetFeature",
      outputFormat: "shape-zip",
      srsName: "EPSG:4326",
    };
    defaultParameters1.typeName = `Sina:${capa.name}`;
    var parameters = L.Util.extend(defaultParameters1);
    var urlS = root + L.Util.getParamString(parameters);
    var downloadLink = document.createElement("a");
    downloadLink.href = urlS;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  //------------------------------------------------------------------------FUNCIONES PARA RECAUDACION---------
  const [anioRecaudacion, setAnioRecaudacion] = useState();
  function selectRecaudacion(event) {
    if (event != null) {
      setAnioRecaudacion(event);
    } else {
      setAnioRecaudacion();
    }
  }

  const [showReporteRecaudacion, setShowReporteRecaudacion] = useState(false);
  function reporteRecaudacion(name, titulo) {
    setDatosReporteRec(undefined);
    let anio = anioRecaudacion;
    defaultParameters.typeName = `Sina:${name}`;
    defaultParameters.cql_filter = "anio<=" + anioRecaudacion;
    var parameters = L.Util.extend(defaultParameters);
    var urlS = root + L.Util.getParamString(parameters);
    setTipoReporteRe(null);
    setShowReporteRecaudacion(!showReporteRecaudacion);
  }

  const [datosReporteRec, setDatosReporteRec] = useState([]);
  const [tipoReporteRe, setTipoReporteRe] = useState();
  function reporteRecaudacionT(tipo) {
    setDatosReporteRec(undefined);
    let rec = new Recaudacion();
    setTipoReporteRe(tipo);
    if (tipo === "reporteR1") {
      fetch(`${rootApi}recaudacion/cobro`)
        .then((response) => response.json())
        .then((data) => {
          let dd = rec.getDatosReporte1(data);
          formatoReportes(dd);
          setDatosReporteRec(dd);
        });
    } else {
      fetch(`${rootApi}recaudacion/uso`)
        .then((response) => response.json())
        .then((data) => {
          let dd = rec.getDatosReporte2(data);
          formatoReportes(dd);
          setDatosReporteRec(dd);
        });
    }
  }

  const [showGraficaRecaudacion, setShowGraficaRecaudacion] = useState(false);
  function graficasRecaudacion(capa, titulo) {
    setShowGraficaRecaudacion(!showGraficaRecaudacion);
    setDatosGraficaRec(undefined);
  }

  const [tipoGraficaRe, setTipoRGraficaRe] = useState();
  const [datosGraficaRec, setDatosGraficaRec] = useState([]);
  function graficaRecaudacionT(tipo) {
    setDatosGraficaRec(undefined);
    let rec = new Recaudacion();
    setTipoRGraficaRe(tipo);
    if (tipo === "graficaR1") {
      fetch(`${rootApi}recaudacion/cobro`)
        .then((response) => response.json())
        .then((data) => {
          let dd = rec.getDatosGrafica1(data);
          //console.log(dd);
          setDatosGraficaRec(dd);
        });
      //let d = rec.getDatosGrafica1(cobroderechos);
      //setDatosGraficaRec(d);
    } else {
      fetch(`${rootApi}recaudacion/uso`)
        .then((response) => response.json())
        .then((data) => {
          let d = rec.getDatosGraficas2(data);
          //console.log(d)
          d.map(item => {
            item.Acuacultura = Number(item.Acuacultura)
            item.Agropecuario = Number(item.Agropecuario)
            item["Balnearios y centros recreativos"] = Number(item["Balnearios y centros recreativos"])
            item["Hidroeléctricas"] = Number(item["Hidroeléctricas"])
            item["Público Urbano"] = Number(item["Público Urbano"])
            item["Régimen General"] = Number(item["Régimen General"])

          })
          setDatosGraficaRec(d);
        });
    }
  }

  const [showModalReporteSIH, setShowModalReporteSIH] = useState(false);


  const [showModalPrestadores, setShowModalPrestadores] = useState(false);
  const [repPrestadores, setRepPrestadores] = useState();
  function mostrarReportesPrestadores(opcion, capa) {
    let datoApi;
    if (opcion === "tam") {
      datoApi = DatosApi(`${rootApi}prestadores/tipo`);
      datoApi.map((item, index) => {
        item.id = index;
      });
    }
    if (opcion === "tipo") {
      datoApi = DatosApi(`${rootApi}prestadores/municipio`);
      datoApi.map((item, index) => {
        item.id = index;
      });
    }
    if (opcion === "municipios") {
      datoApi = DatosApi(`${rootApi}prestadores/municipio`);
      datoApi.map((item, index) => {
        item.id = index;
      });
    }


    let presta = new prestadores();
    let d;
    let datos = [];
    // eslint-disable-next-line array-callback-return
    datos = datoApi;
    if (opcion === "tipo") {
      let datosF = presta.getDatosReporteTipo(datos);
      setFilas(datosF.sort());
    } else {
      if (opcion === "nacional") {
        let datosF = presta.getDatosReporteNac(datos);
        setFilas(datosF.sort());
      } else {
        if (opcion === "tam") {
          let datosF = presta.getDatosTam(datos);
          setFilas(datosF);
        } else {
          let datosF = presta.getDatosReporteMun(datos);
          let header = Object.keys(datosF[0]);
          setColumnas(header);
          setFilas(datosF);
        }
      }
    }
    setRepPrestadores(opcion);
    setShowModalPrestadores(!showModalPrestadores);
  }

  function DatosApi(urls) {
    let datos = [];
    $.ajax({
      url: urls,
      type: "GET",
      async: false,
      success: function (data) {
        data.map((item) => {
          datos.push(item);
        });
      },
      error: function (error) {
        console.log("Error " + error);
      },
    });
    return datos;
  }


  const [showModalRepPresuAcu, setShowModalRepPresuAcu] = useState(false);
  function reportesPresupuestoAcu() {
    //console.log("Presupuesto ejercido Anual")



    let datosApi = DatosApi(`${rootApi}acumulado/gral`);
    //console.log(datosApi)
    /*
    
    datoApi = DatosApi(`${rootApi}presupuestoejercido/general`);

    datoApi.map((item) => {
      // console.log((new Intl.NumberFormat("en-US").format(item.monto)));
      item.monto = (new Intl.NumberFormat("en-US").format(item.monto));
    })
    */

    //console.log(datoApi);
    //console.log(datoApi);
    /*
      datoApi.map((item, index) => {
        item.id = index;
      });
      */

    //var pre = new Presup();
    //let datos = [];
    //let datosF = pre.getDatosReporteNac(datos);
    setFilas(datosApi);
    setShowModalRepPresuAcu(!showModalRepPresuAcu);
  }

  const [showModalRepPresu, setShowModalRepPresu] = useState(false);
  function reportesPresupuesto() {
    setTipoRepPresu([])
    setShowModalRepPresu(!showModalRepPresu);
  }

  const [cicloA, setCicloA] = useState("");
  function seleccionCiclo(event) {
    setColumnas([]);
    setFilas([]);
    var general = new Generales();
    if (event !== null) {
      if (event.value !== 0) {
        if (tipoRepPresu === "capitulos") {
          let d = DatosApi(
            `${rootApi}presupuesto/capitulos?anio=${event.value}`
          );
          formatoReportes(d);
          let datos = general.quitarNull(d);
          datos.map((item, index) => {
            item.id = index;
          });
          general.sortJSON(datos, "ppgrupo", "desc");
          setFilas(datos);
          setCicloA(event);
        }

        if (tipoRepPresu === "region") {
          let d = DatosApi(
            `${rootApi}presupuesto/presupuesto?anio=${event.value}`
          );
          formatoReportes(d);
          let datos = general.quitarNull(d);
          datos.map((item, index) => {
            item.id = index;
          });
          general.sortJSON(datos, "ppgrupo", "desc");
          setFilas(datos);
          setCicloA(event);
        }
      }
    } else {
      setCicloA(ciclo[0]);
    }
  }

  function formatoReportes(data) {
    //console.log(data);


    let headers = Object.keys(data[0]);

    data.map(item => {
      headers.map(campo => {
        if (isNaN(item[campo])) {
          //no es numero no hace ningun ajuste
        } else {
          //console.log(item[campo]-Math.floor(item[campo]))
          if (item[campo] - Math.floor(item[campo]) === 0) {
            //es un numero entero 
            if (omitirFormato2(campo) !== 0) {
              //hay campos que se omiten para el formato de numeros
              item[campo] = (new Intl.NumberFormat("en-US").format(Number(item[campo])));//formatCurrency.format(item.properties[campo])//
            }
          } else {
            //es un flotante y lo formateamos a dos decimales 
            let aux = Number(item[campo]).toFixed(2);
            //console.log(aux)
            item[campo] = (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(aux))
          }
        }
      })

    })

    //console.log(data);

  }

  function omitirFormato2(dato) {
    //funcion para omitir cambio de variables en reportes 
    if (dato === "idppgrupo" || dato === "anio") {
      return 0;
    } else {
      return 1;
    }
  }


  const [tipoRepPresu, setTipoRepPresu] = useState();
  function reportePresupuesto(tipo) {
    var general = new Generales();
    setFilas([]);
    setCicloA(ciclo[0]);
    setTipoRepPresu(tipo);

    let datos;
    if (tipo === "presupuestario") {
      let d = DatosApi(`${rootApi}presupuesto/programa`);

      formatoReportes(d);
      datos = general.quitarNull(d);
      datos.map((item, index) => {
        item.id = index;
      });
      general.sortJSON(datos, "idppgrupo", "desc");

      setFilas(datos);
    }
    if (tipo === "recaudacion") {
      let d = DatosApi(`${rootApi}presupuesto/recypre`);
      formatoReportes(d)
      datos = general.quitarNull(d);
      datos.map((item, index) => {
        item.id = index;
      });
      general.sortJSON(datos, "anio", "desc");
      setFilas(datos);
    }
    if (tipo === "acumulado") {
      setAnioAcumuladoR([]);
      setMesAcumuladoR([]);
    }

    setTipoRepPresu(tipo);
  }

  const [anioAcumuladoR, setAnioAcumuladoR] = useState([])
  function selectAnioAcumulado(event) {
    //console.log(event);
    if (event !== null) {
      setAnioAcumuladoR(event);
    } else {
      setAnioAcumuladoR([]);
    }

  }

  const [mesAcumuladoR, setMesAcumuladoR] = useState([])
  function selectMesAcumulado(event) {
    if (event !== null) {
      if (tipoRepPresu === "acumulado") {
        if (anioAcumuladoR !== null && event !== null) {
          // let datos = buscarD(mensual, anioAcumuladoR.value, event.value)
          let datos1 = DatosApi(`${rootApi}acumuladoM/reporte/${anioAcumuladoR.value}/${event.value}`);
          //console.log(datos)
          setFilas(datos1);
        }
        //console.log(aux)
      }
      setMesAcumuladoR(event);
    } else {
      setMesAcumuladoR([]);
    }
  }

  const [grupoE, setGrupoE] = useState([])
  function selectGrupo(event) {

    if (event !== null) {
      //console.log(event);
      let datos = buscarD1(ejercidoG, anioAcumuladoR.value, event.value)
      //console.log(datos.length)
      setDatosPresupuesto(datos)

      setGrupoE(event)
    } else {
      setGrupoE([])
    }

  }

  function buscarD(data, anio, mes) {
    //console.log(anio,mes)
    let aux = [];
    let id = 1;
    data.map(item => {
      if (item.mes === mes && item.anio === anio) {
        item.id = id;
        aux.push(item);
        id++;
      }
    })
    return aux;
  }

  function buscarD1(data, anio, grupo) {
    let meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    //console.log(anio,mes)
    let aux = [];
    data.map(item => {
      if (item.grpejercido === grupo && item.anio === anio) {
        let aux2 = {}
        aux2.mes = item.mes;
        aux2.total = item.total;
        aux.push(aux2);
      }
    })

    //sortJSON(aux, 'mes', 'asc');
    let final = []
    for (let index = 0; index < meses.length; index++) {
      //console.log(meses[index]);
      for (let index2 = 0; index2 < aux.length; index2++) {
        //console.log(aux[index2].mes);

        if (aux[index2].mes === meses[index]) {
          final.push(aux[index2])
        }
      }
    }
    //console.log(final)
    return final;
  }

  const [
    showModalGraficasPresupuesto,
    setShowModalGraficasPresupuesto,
  ] = useState(false);
  function mostrarModalGraficasPresu() {
    setAnioAcumuladoR([]);
    setGrupoE([])
    setDatosPresupuesto([])
    setGrafPresupuesto("");
    setShowModalGraficasPresupuesto(!showModalGraficasPresupuesto);
  }
  const [grafPresupuesto, setGrafPresupuesto] = useState();
  const [datosPresupuesto, setDatosPresupuesto] = useState([]);
  const [datosP, setDatosP] = useState();
  function graficaPresupuesto(graf) {
    var pre = new Presup();
    setGrafPresupuesto(graf);

    if (graf === "grafica1") {
      let d = DatosApi(`${rootApi}presupuesto/programa`);
      //console.log(d);


      let aux = pre.getGrafica(d);
      //console.log(aux)
      setDatosP(aux);
      //setDatosPresupuesto(aux);
    }
    if (graf === "grafica2") {
      let d = DatosApi(`${rootApi}presupuesto/recypre`);
      //console.log(d)
      d.map(item => {
        //console.log(item)
        item.presupuesto = (item.presupuesto.toFixed(2))
        item.recaudacion = (item.recaudacion.toFixed(2))
      })

      setDatosPresupuesto(d);
    }
    if (graf === "grafica3") {
      setGrafPresupuesto("grafica3");
    }
  }

  const [anioGraficaPres, setAnioGraficaPres] = useState();
  function filtrarAniosPresupuesto(event) {
    var pre = new Presup();
    if (event !== null) {
      setAnioGraficaPres(event)
      //console.log(event)

      let d = pre.getFiltrarAnio(datosP, event.value)

      setDatosPresupuesto(d);
      //console.log(d)

    } else {
      setAnioGraficaPres()
    }

  }

  const [tipoRepCuenca, setTipoRepCuenca] = useState();
  const [showModalRepCuenca, setShowModalRepCuenca] = useState(false);
  function reportesCuencasm() {
    //console.log(showModalRepCuenca);
    setShowModalRepCuenca(!showModalRepCuenca);
  }

  function reportesCuencas(tipoRepCuenca) { //console.log(tipo);
    setTipoRepCuenca(tipoRepCuenca);
    setColumnas([]);
    setFilas([]);
    setAnioSCuencas([]);
    {/*
    var cue = new Cuencas();
    let datos;
    if (tipo === "RHA") {
      let d = DatosApi(`${rootApi}cuencasrha/anual/${event.value}`);
      formatoReportes(d);
      datos = cue.quitarNull(d);
      datos.map((item, index) => {
        item.id = index;
      });
      cue.sortJSON(datos, "claverha");

      setFilas(datos);
    }
    if (tipo === "RH") {
      let d = DatosApi(`${rootApi}cuencasrh/anual/${event.value}`);
      formatoReportes(d)
      datos = cue.quitarNull(d);
      datos.map((item, index) => {
        item.id = index;
      });
      cue.sortJSON(datos, "claverha");
      setFilas(datos);
    }
    setTipoRepCuenca(tipo);    
  }

*/}

  }

  ////////////////////Graficas Cuencas

  const [
    showModalGraficasCuencas,
    setShowModalGraficasCuencas,
  ] = useState(false);
  function mostrarModalGraficasCuenca() {
    setShowModalGraficasCuencas(!showModalGraficasPresupuesto);
  }

  const [datosCuencas, setDatosCuencas] = useState([]);
  function graficaCuencas(graf) {
    //console.log(graf);
    setGrafCuencas(graf);

    setDatosCuencas([]);
    setAnioSCuencas([]);


    /*
        var cue = new Cuencas();
        if (graf === "grafica1") {
          console.log(anioSCuencas);
          let d = DatosApi(`${rootApi}cuencasrha/anual/${anioSCuencas.value}`);
          //cue.getDatosRHA(d)
          let aux = cue.getDatosRHA(d);
          //console.log(aux)
          setDatosCuencas(aux);
        }
        if (graf === "grafica2") {
          let d = DatosApi(`${rootApi}cuencasrh/anual/2020`);
          let aux = cue.getDatosRH(d);
          setDatosCuencas(aux);
    
          //setDatosCuencas(d);
        }
        if (graf === "grafica3") {
          let d = DatosApi(`${rootApi}cuencasordenamiento/anual/2019`);
          let aux = cue.getDatosOrdenamiento(d);
          setDatosCuencas(aux);
          //setDatosCuencas(d);
        }*/


  }

  ////////////////////Graficas Calidad del agua
  const [showModalGrafCalidad, setshowModalGrafCalidad] = useState(false);
  const [datosGraficasCalidadG, setdatosGraficasCalidadG] = useState([]);
  function mostrarGraficasCalidad() {
    setshowModalGrafCalidad(!showModalGrafCalidad);
    setEstadoSelect();
    setdatosGraficasCalidadG([]);
    setParamCapa();
    setParametroClid("");
    setEstadoC("");
    setdatosGraficasCP([]);
    setmunSelect([]);
    setmunS("");
    setRhaS("");
    setrhaSelect([]);
    settipoGraficaCal("");
  }

  function ObtenerGrafica1Calidad(grafica) {
    setGraficaF(grafica);
    setEstadoSelect();
    setdatosGraficasCalidadG([]);
    setParamCapa();
    setParametroClid("");
    setEstadoC("");
    setdatosGraficasCP([]);
    setmunSelect([]);
    setmunS("");
    setRhaS("");
    setrhaSelect([]);
  }
  const [munSelect, setmunSelect] = useState([]);
  const [munS, setmunS] = useState("");
  function cambioMun(event) {
    if (event !== null) {
      setmunSelect(event);
      setmunS(event.label);
    } else {
      setmunSelect([]);
      setmunS("");
    }
  }

  const [parametroCalid, setParametroClid] = useState("");
  const [estadoC, setEstadoC] = useState("");
  const [datosGraficasCP, setdatosGraficasCP] = useState([]);
  function datosGraficasCalidad(event) {
    if (event !== null) {
      let calidad = new Calidad();
      setParamCapa(event);
      setParametroClid(event.label);
      if (estadoSelect !== undefined) {
        setEstadoC(estadoSelect.label);
      }

      if (tipoGraficaCal === "estado") {
        //console.log(event)
        fetch(`${rootApi}calidadV/entidad/${event.campo}/${estadoSelect.value}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.length > 0) {
              if (graficaF === "graficaAnual") {
                let datos = calidad.getDatosEntidad(data);
                let datos2 = calidad.getDatos(datos, "valor");
                setdatosGraficasCalidadG(datos2);
              }
              if (graficaF === "graficaClasiCal") {
                let datos = calidad.getDatosEntidad(data);
                let datos2 = calidad.getDatos2(datos, "clasificacion");
                let datos3 = calidad.getDatos3(datos2);
                setdatosGraficasCP(datos3);
                setdatosGraficasCalidadG(datos2);
              }
            } else {
              mensaje("La consulta solicitada no tiene información", "warning");
            }
          });
      }
      if (tipoGraficaCal === "municipio") {
        fetch(
          `${rootApi}calidadV/mun/${event.campo}/${Number(
            munSelect.id
          )}`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.length > 0) {
              if (graficaF === "graficaMunEvo") {
                let datos = calidad.getDatosEntidad(data);
                //let datos1 = calidad.getDatosMunicipios(datos,munSelect.label,event.campo);
                let datos2 = calidad.getDatos(datos, "valor");
                setdatosGraficasCalidadG(datos2);
              }

              if (graficaF === "graficaClasiMun") {
                let datos = calidad.getDatosEntidad(data);
                //let datos1 = calidad.getDatosMunicipios(datos,munSelect.label,event.campo);
                let datos2 = calidad.getDatos2(datos, "clasificacion");
                let datos3 = calidad.getDatos3(datos2);
                setdatosGraficasCP(datos3);
                setdatosGraficasCalidadG(datos2);
              }
            } else {
              mensaje("La consulta solicitada no tiene información", "warning");
            }
          });
      }

      if (tipoGraficaCal === "rha") {
        fetch(
          `${rootApi}calidadV/rha/${event.campo}/${Number(
            rhaSelect.id
          )}`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.length > 0) {
              if (graficaF === "graficaRhaEv") {
                let datos = calidad.getDatosRHA(data);
                let datos1 = calidad.getDatos(datos, "valor");
                setdatosGraficasCalidadG(datos1);
              }
              if (graficaF === "graficaClasiRha") {
                let datos = calidad.getDatosRHA(data);
                let datos1 = calidad.getDatos2(datos, "clasificacion");
                let datos2 = calidad.getDatos3(datos1);
                setdatosGraficasCP(datos2);
                setdatosGraficasCalidadG(datos1);
              }
            } else {
              mensaje("La consulta solicitada no tiene información", "warning");
            }
          });
      }
    } else {
      setParametroClid("");
      setParamCapa([]);
    }
  }

  const [tipoGraficaCal, settipoGraficaCal] = useState("");
  const [rhas, setRhas] = useState([]);
  const [rhaSelect, setrhaSelect] = useState([]);
  function tipoGraficaCalidad(tipo) {
    if (tipo === "rha") {
      fetch(`${rootApi}capasBase/rha`)
        .then((response) => response.json())
        .then((data) => setRhas(data));
    }
    settipoGraficaCal(tipo);
    setGraficaF("");
    setEstadoSelect();
    setdatosGraficasCalidadG([]);
    setParamCapa();
    setParametroClid("");
    setEstadoC("");
    setdatosGraficasCP([]);
    setmunSelect([]);
    setmunS("");
    setRhaS("");
    setrhaSelect([]);
  }

  const [rhaS, setRhaS] = useState("");
  function seleccionRHA(event) {
    if (event !== null) {
      setrhaSelect(event);
      setRhaS(event.label);
    } else {
      setrhaSelect([]);
    }
  }

  const [colapsarCB, setColapsarCB] = useState(false);
  const [colapsarPob, setColapsarPob] = useState(false);
  const [colapsarPob2, setColapsarPob2] = useState(false);
  const [colapsarPresupuesto, setColapsarPresupuesto] = useState(false);
  const [colapsarCuencas, setColapsarCuencas] = useState(false);
  const [colapsarAcuiferos, setColapsarAcuiferos] = useState(false);
  const [colapsarCalidad, setColapsarCalidad] = useState(false);
  const [colapsarSIH, setColapsarSIH] = useState(false);
  const [colapsarPrec, setColapsarPrec] = useState(false);
  const [colapsarCiclones, setColapsarCiclones] = useState(false);
  const [colapsarMonitoreo, setColapsarMonitoreo] = useState(false);
  const [colapsarDistritos, setColapsarDistritos] = useState(false);
  const [colapsarPrestadores, setColapsarPrestadores] = useState(false);
  const [colapsarRepda, setColapsarRepda] = useState(false);
  const [colapsarRecaudacion, setColapsarRecaudacion] = useState(false);
  const [colapsarCutzamala, setColapsarCutzamala] = useState(false);
  const [colapsarK111, setColapsarK111] = useState(false);

  function toogleMonitoreo() {
    fecha();
    setColapsarMonitoreo(!colapsarMonitoreo);
  }
  function encuadrar(capa) {
    let bound = capa.layer;
    mapaReferencia.fitBounds(bound.getBounds());
  }

  //Funciones para graficas de Poblacion
  const [anioPob, setanioPob] = useState({});
  function changeAnioPob(event) {
    if (event !== null) {
      setanioPob(event);
    } else {
      setanioPob();
    }
  }

  const [showReportePoblacion, setshowReportePoblacion] = useState(false);
  const [datosReportPob, setdatosReportPob] = useState([]);
  function ReportePOblacion() {
    setshowReportePoblacion(!showReportePoblacion);
    fetch(`${rootApi}poblacion/rha?anio=2020&rha=XI`)
      .then((response) => response.json())
      .then((data) => {
        let reporPob = pobla.getReporte(data);
        setdatosReportPob(reporPob);
      });
  }

  const [showGrafPoblacion, setshowGrafPoblacion] = useState(false);
  function graficasPOblacion() {
    setshowGrafPoblacion(!showGrafPoblacion);
    setdatosPoblacionG([]);
    setdatosGraficasCalidadG([]);
    setanioPob();
    setmunSelect();
    setEntidadSelect();
  }
  const [graficaPob, setgraficaPob] = useState("");
  function tipoGraficaPoblacion(tipo) {
    if (tipo === "estado") {
      setdatosPoblacionG([]);
      setdatosGraficasCalidadG([]);
      setanioPob();
      setgraficaPob(tipo);
      setmunSelect();
      setEntidadSelect();
    }
    if (tipo === "municipio") {
      setgraficaPob(tipo);
      setdatosPoblacionG([]);
      setdatosGraficasCalidadG([]);
      setanioPob();
      setmunSelect();
      setEntidadSelect();
    }
    if (tipo === "nacional") {
      setgraficaPob(tipo);
      setdatosPoblacionG([]);
      setdatosGraficasCalidadG([]);
      setanioPob();
      setmunSelect();
      setEntidadSelect();
    }
  }
  const [datosPoblacionG, setdatosPoblacionG] = useState([]);
  const [totalPob, setTotalPob] = useState();
  function obtenerDatosPoblacion(event) {
    if (event !== null) {
      if (graficaPob === "estado") {
        if (anioPob !== null) {
          fetch(
            `${rootApi}poblacion/filtro?entidad=${event.value}&anio=${anioPob.value}`
          )
            .then((response) => response.json())
            .then((data) => {
              let datos = pobla.getDatosMunicpio(data);
              let datos1 = pobla.getPorcentajes(datos);
              setTotalPob(datos1[0].mas + datos1[1].fem);
              setdatosPoblacionG(datos1);
              setdatosGraficasCalidadG(datos);
            });
        }
        setEntidadSelect(event);
      }
      if (graficaPob === "nacional") {
        if (event !== null) {
          fetch(`${rootApi}poblacion/nacional?anio=${event.value}`)
            .then((response) => response.json())
            .then((data) => {
              let datos = pobla.getDatosEstado(data);
              //let aux = pobla.getReporte(data);
              let suma = 0;
              datos.map((item) => {
                suma = suma + item.total;
              });
              //let dr = pobla.getReporte(data);
              let datos1 = pobla.getPorcentajes(datos);
              setTotalPob(suma);
              setdatosPoblacionG(datos1);
              setdatosGraficasCalidadG(datos);
            });
          setanioPob(event);
        }
      }

      if (graficaPob === "municipio") {
        if (event !== null) {
          setmunSelect(event);
          fetch(
            `${rootApi}poblacion/municipio?entidad=${entidadSelect.value}&mun=${event.value}&anio=${anioPob.value}`
          )
            .then((response) => response.json())
            .then((data) => {
              let datos = pobla.getDatosLocalidad(data);
              let datos1 = pobla.getPorcentajes(datos);

              setTotalPob(datos1[0].mas + datos1[1].fem);
              setdatosPoblacionG(datos1);
              setdatosGraficasCalidadG(datos);
            });
        }
      }
    } else {
      if (graficaPob === "estado") {
        setEntidadSelect();
      }
      if (graficaPob === "nacional") {
        setanioPob();
      }
      if (graficaPob === "municipio") {
        //setEntidadSelect();
        //setanioPob();
        setmunSelect();
      }
    }
  }

  //codigo para reportes y graficas de DISTRITOS DE RIEGO
  const [showModalReportesDR, setShowModalReportesDR] = useState(false);
  const [showModalGraficasDR, setShowModalGRaficasDR] = useState(false);
  const [tipoReporteDR, setTipoReporteDR] = useState(0);
  function reportesDR() {
    setShowModalReportesDR(!showModalReportesDR);
    setTipoReporteDR(0);


  }

  function graficasDR() {
    setShowModalGRaficasDR(!showModalGraficasDR);
  }

  function tipoReporte_DR(tipo) {
    if (tipo === 1) {
      setTipoReporteDR(tipo);
      setFilas([]);
      setAnioDistrito();
    }
    if (tipo === 2) {
      setFilas([]);
      setAnioDistrito();
      setTipoReporteDR(tipo);
    }
    if (tipo === 3) {
      setFilas([]);
      setAnioDistrito();
      setTipoReporteDR(tipo);
    }

  }

  const [datosDR, setDatosDR] = useState();
  function selectAniosDistritos2(event) {
    if (event !== null) {
      if (tipoReporteDR === 1) {
        fetch(`${rootApi}concentradoProd/rha/` + event.value)
          .then(response => response.json())
          .then(data => {
            formatoReportes(data);
            setFilas(data);
          });
        setAnioDistrito(event);
      } else {
        if (tipoGraficaDR === 1) {
          let dist = new Distritos();
          fetch(`${rootApi}modalidad/` + event.value)
            .then(response => response.json())
            .then(data => {
              let d = dist.getDatosG1(data);
              setDatosDR(d);
            });
          setAnioDistrito(event);
        } else {
          if (tipoReporteDR === 3) {
            fetch(`${rootApi}superficieSembrada/` + event.value)
              .then(response => response.json())
              .then(data => {
                formatoReportes(data);
                setFilas(data);
              });
            setAnioDistrito(event);
          } else {
            setAnioDistrito(event);
          }
        }

      }

    } else {
      setAnioDistrito();
    }
  }

  const [distritoSelect, setdistritoSelect] = useState()
  function SelectDistritoR(event) {
    if (event !== null) {

      fetch(`${rootApi}concentrado/${event.label}/${anioDistrito.label}`)
        .then(response => response.json())
        .then(data => {
          //console.log(data)
          formatoReportes(data)
          setFilas(data);
        });
      setdistritoSelect(event);
    } else {
      setdistritoSelect();
    }


  }

  const [cultivoDistrito, setSelectCultivo] = useState();
  function selectCultivo(event) {
    if (event !== null) {
      setSelectCultivo(event);
    }
  }

  const [participant, setSelectParticipante] = useState();
  function selectParticipante(event) {
    if (event !== null) {
      setSelectParticipante(event);
    }

  }

  const [tipoGraficaDR, setTipoGraficaDR] = useState(0);
  function tipoGrafica_DR(tipo) {
    if (tipo === 1) {
      setTipoGraficaDR(tipo);
      setFilas([]);
      setAnioDistrito();
    }
    if (tipo === 2) {

      setFilas([]);
      setAnioDistrito();
      setTipoGraficaDR(tipo);

      let dist = new Distritos();
      fetch(`${rootApi}historicos/all`)
        .then(response => response.json())
        .then(data => {
          let d = dist.getDatosG2(data);
          setDatosDR(d);
        });
    }

  }
  ///----------------------------------Funciones Sistema Cutzamala
  const [modalCutzamalaReportes, setModalCutzamalaReportes] = useState(false);
  const [modalCutzamalaGraficas, setModalCutzamalaGraficas] = useState(false);
  const [anioCutzamala, setAnioCutzamala] = useState([]);
  const [tipReporteCutzamala, setTipReporteCutzamala] = useState("");
  const [tipGraficaCutzamala, setTipGraficaCutzamala] = useState("");

  function mostrarCapasCutzamala(name, titulo) {

    var hoy = new Date();
    var urlApi = "https://sinav30.conagua.gob.mx:8080/PresasPG";
    let encontrada = buscarCapa(titulo);
    if (encontrada === null) {
      let capaWFS = {};
      var urlMapa1 = "";

      if (hoy.getHours() < 13) {
        let aux = sumarDias(hoy, -1);
        urlMapa1 = urlApi + "/presas/reporte/" + formatDate(aux);
      } else {
        //console.log(formatDate(hoy))
        urlMapa1 = urlApi + "/presas/reporte/" + formatDate(hoy);
      }
      //console.log("Cargando Informacion .........................")
      mensaje2("Cargando informacion", "info");

      $.ajax({
        url: urlMapa1,
        async: true,
        type: 'GET',
      }).done(function (response) {
        //console.log(response)
        let cut = [];
        response.map(item => {
          if (item.clavesih === "DBOMC" || item.clavesih === "VBRMX" || item.clavesih === "VVCMX") {
            cut.push(item);
          }
        })
        //console.log(cut)
        if (response.length === 0) {
          mensaje("La fecha consultada no contiene información", "info");
        } else {
          //se genera la capa geografica de las presas de cutzamala
          var geojson = {
            type: "FeatureCollection",
            features: [],
          };
          let Datos = [];
          cut.map((item) => {
            let aux = {},
              aux1 = {};
            aux.type = "Feature";
            aux1.type = "Point";
            aux1.coordinates = [item.longitud, item.latitud];
            aux.geometry = aux1;
            //item.busqueda = `${item.Estado} | ${item.NombrePresa} | ${item.nombrecomun}`
            aux.properties = item;
            Datos.push(item);
            geojson.features.push(aux);
          });

          capaWFS["attribution"] = "No disponible";
          capaWFS["transparencia"] = 1;
          capaWFS["tipo"] = "wfs";
          capaWFS["habilitado"] = true;
          capaWFS["format"] = "json";
          capaWFS["servicio"] = urlMapa1;
          capaWFS["Date"] = fechaConsulta;
          capaWFS["name"] = name;
          capaWFS["nombre"] = titulo;
          capaWFS["titulo"] = titulo;

          capaWFS["datos2"] = datosDescargaCVS(geojson.features); //datos para descargar CVS
          capaWFS["datos"] = darFormato(geojson.features);//datos a mostrar en la tabla de atributos 
          capaWFS["features"] = copiarFeatures(geojson.features); //datos que se muestran en el mapa
          capaWFS["modulo"] = "Cutzamala";
          capaWFS["TAtributos"] = "Sistema Cutzamala"
          capaWFS.isActive = true;
          zIndexCapas = zIndexCapas + 1;
          mapaReferencia.createPane(`${zIndexCapas}`);
          mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
            numeroIndex + capasVisualizadas.length;
          var datos = geojson;
          var geojsonL = new L.geoJson(datos, {
            pointToLayer: function (feature, latlng) {
              return L.marker(latlng, style(feature));
            },
            pane: `${zIndexCapas}`,
            onEachFeature: infoPresas,
          });
          //console.log(capaWFS)
          //clusters.addLayer(geojsonL);
          capaWFS["layer"] = geojsonL;
          setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
          mapaReferencia.addLayer(capaWFS.layer);
          mapaReferencia.fitBounds(capaWFS.layer.getBounds());
          Swal.close();

        }

      })

    }

  }

  function reportesCutzamala() {
    setTipReporteCutzamala("");
    setModalCutzamalaReportes(!modalCutzamalaReportes);
  }

  function graficasCutzamala() {
    setModalCutzamalaGraficas(!modalCutzamalaGraficas);
    setTipGraficaCutzamala("");
  }

  function tipoReporteCutzamala(tipo) {
    setAnioCutzamala([]);
    setFilas([]);
    setMesCutzamala([]);
    setDiaCutzamala([]);

    if (tipo === "reporte1") {
      fetch(`${rootApi}componentes/anio/2022`)
        .then(response => response.json())
        .then(data => {
          //formatoReportes(data);
          //console.log(data);
          formatoReportes(data);
          setFilas(data);
        });
      setTipReporteCutzamala("reporte1");
    }
    if (tipo === "reporte2") {
      fetch(`${rootApi}caudales/all`)
        .then(response => response.json())
        .then(data => {
          //formatoReportes(data);
          //console.log(data);
          formatoReportes(data);
          setFilas(data);
        });
      setTipReporteCutzamala("reporte2");
    }
    if (tipo === "reporte3") {
      fetch(`${rootApi}volumenes/all`)
        .then(response => response.json())
        .then(data => {
          //formatoReportes(data);
          console.log(data);
          formatoReportes(data);
          setFilas(data);
        });

      //console.log("reporte3")
      setTipReporteCutzamala("reporte3");
    }
    if (tipo === "reporte4") {

      //console.log("reporte4")
      setTipReporteCutzamala("reporte4");
    }

  }

  function seleccionaAnioCutzamala(event) {
    //console.log(event);
    if (event != null) {
      //console.log(rootApi + `componentes/anio/${event.value}`)
      fetch(`${rootApi}componentes/anio/${event.value}`)
        .then(response => response.json())
        .then(data => {
          formatoReportes(data)
          //console.log(data);
          //formatoReportes(data);
          setFilas(data);
        });

      setAnioCutzamala(event)
    } else {
      setAnioCutzamala()
    }
  }

  const [mesCutzamala, setMesCutzamala] = useState([])
  const [mesCutzamala2, setMesCutzamala2] = useState([])
  function seleccionaMesCutzamala(event) {
    if (event != null) {
      //console.log(event);
      setMesCutzamala(event);
      setMesCutzamala2(event);
    } else {
      setMesCutzamala([]);
      setDiaCutzamala([]);
      setFilas([]);
    }
  }


  const [diaCutzamala, setDiaCutzamala] = useState([])
  const [diaCutzamala2, setDiaCutzamala2] = useState([])
  function seleccionadiaCutzamala(event) {

    setFilas([]);
    if (event !== null) {
      //console.log(event);
      fetch(`${rootApi}presas/historico/${mesCutzamala.value}/${event.value}`)
        .then(response => response.json())
        .then(data => {
          //formatoReportes(data)
          formatoReportes(data);
          if (tipGraficaCutzamala === "grafica3") {
            let d = generarData(data);
            setFilas(d);
            setDiaCutzamala([]);
            setMesCutzamala([]);
          } else {
            setFilas(data);
            setDiaCutzamala([]);
            setMesCutzamala([]);
          }
        });
      setDiaCutzamala(event);
      setDiaCutzamala2(event);
    } else {
      setDiaCutzamala([]);
      setFilas([]);
    }
  }

  function generarData(datos) {
    let aux = []
    datos.map(item => {
      let camp = {};
      camp.anio = item.anio;
      camp.DBOMC = item.bosque;
      camp.VBRMX = item.valle;
      camp.VVCMX = item.villa;
      aux.push(camp);
    })
    return aux;
  }


  function tipoGraficaCutzamala(tipo) {
    setMesCutzamala([]);
    setDiaCutzamala([]);
    setFilas([]);
    if (tipo === "grafica1") {
      fetch(`${rootApi}volumenesG/all`)
        .then(response => response.json())
        .then(data => {
          //formatoReportes(data)
          //console.log(data);
          //formatoReportes(data);
          setFilas(data);
        });
      //console.log("grafica1")
      setTipGraficaCutzamala("grafica1");

    }
    if (tipo === "grafica2") {

      fetch(`${rootApi}volumenesSumG/all`)
        .then(response => response.json())
        .then(data => {
          //formatoReportes(data)
          //console.log(data);
          //formatoReportes(data);
          setFilas(data);
        });
      //setFilas(consumoG);
      setTipGraficaCutzamala("grafica2");
      //console.log("grafica2")

    }
    if (tipo === "grafica3") {
      //setFilas(propuesta);
      setTipGraficaCutzamala("grafica3");
      //console.log("grafica3")

    }
  }


  function descargaD(link) {
    var downloadLink = document.createElement("a");
    downloadLink.href = link;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }


  //// -----------------------------Funciones para Modernizacion de Presas
  /////--------------------------------Programa K111
  const [anioModernizacion, setAnioModernizacion] = useState([]);

  function selectAniosModernizacion(event) {
    if (event !== null) {
      //console.log(tipoReporteK111)
      if (tipoReporteK111 === "reporteObras") {
        //se hace la peticion al servidor de los datos
        let d = filtrasDatosK(obrasrehabilitadasR, event.value)
        //console.log(d)
        setFilas(d);
      }
      if (tipoReporteK111 === "reporteinversion") {
        //se hace la peticion de los datos
        let d = filtrasDatosK(inversionEstador, event.value)
        //console.log(d)
        setFilas(d);
      }

      if (tipoGraficaK111 === "inversion") {
        //este codigo equivale a una petision al servidor
        let d = filtrasDatosK(inversionEstadoG, event.value)
        let aux = [];
        d.map(item => {
          let aux1 = {}
          aux1.estado = item.estado;
          aux1.inversion = item.inversion;
          aux.push(aux1);
        })

        setFilas(aux);
      }

      setAnioModernizacion(event);
    } else {
      setAnioModernizacion([]);
      setFilas([]);
    }

  }

  function filtrasDatosK(data, anio) {
    let aux = []
    let num = 1;
    data.map(item => {
      if (item.anio === anio) {
        item.id = num;
        aux.push(item)
        num++;
      }
    })
    return aux;
  }

  function mostrarCapasModernizacion(name, titulo, filtro) {
    if (anioModernizacion.value !== undefined) {
      //se puede pedir la capa
      let capaWFS = cap.agregarCapas("20", capasVisualizadas, name, titulo, filtro, anioModernizacion.value, "", "", "", "");

      //console.log(capaWFS);
      if (capaWFS !== undefined && Object.keys(capaWFS).length !== 0) {

        //la consulta contiene informacion
        mensaje2("Cargando Informacion", "info");

        ///cuando la peticion es correcta y regresa informacion 
        //console.log(modernizacion)
        //*********************************************************************/*************/
        ///funcion que simula la peticion al servidor 
        let datos = buscarAnio(modernizacion, anioModernizacion.value);
        //console.log(datos)
        //genero un geojson
        var geojson = {
          type: "FeatureCollection",
          features: [],
        };

        datos.map(item => {
          let aux = {},
            aux1 = {};
          aux.type = "Feature";
          aux1.type = "Point";
          aux1.coordinates = [item.st_x, item.st_y]
          aux.geometry = aux1;
          aux.properties = item;
          geojson.features.push(aux);

        })
        //console.log(geojson);

        // esto va dentro del ajax una vez recibido el response
        capaWFS["attribution"] = "No disponible";
        capaWFS["transparencia"] = 1;
        capaWFS["tipo"] = "wfs";
        capaWFS["habilitado"] = true;
        capaWFS["format"] = "json";
        capaWFS["servicio"] = capaWFS.urlS;
        capaWFS["name"] = name;
        capaWFS["nombre"] = `${titulo} ${anioModernizacion.value}`;
        capaWFS["titulo"] = `${titulo} ${anioModernizacion.value}`;

        capaWFS["datos2"] = datosDescargaCVS(geojson.features); //datos para descargar CVS
        capaWFS["datos"] = darFormato(geojson.features);//datos a mostrar en la tabla de atributos 
        capaWFS["features"] = copiarFeatures(geojson.features); //datos que se muestran en el mapa
        capaWFS["modulo"] = "Modernizacion";
        capaWFS["TAtributos"] = "Programa K111"
        capaWFS.isActive = true;
        zIndexCapas = zIndexCapas + 1;
        mapaReferencia.createPane(`${zIndexCapas}`);
        mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
          numeroIndex + capasVisualizadas.length;

        //getModernizacion(d)
        var layer = new L.geoJson(geojson, {
          pointToLayer: function (feature, latlng) {
            return L.circleMarker(latlng, {
              fillColor: eval(
                `Sim.getModernizacion(${feature.properties["anio"]})`
              ),
              weight: 1,
              opacity: 1,
              color: "#b2b2b2",
              fillOpacity: 0.9,
              radius: 7,
            });
          },
          pane: `${zIndexCapas}`,
          onEachFeature: function (feature = {}, subLayer) {
            feature["nombre_capa"] = name;
            feature["titulo"] = titulo;
            //feature["funcionC"] = event.funcion;
            var pop = infoCapas.getModernizacion(feature.properties)
            subLayer.bindPopup(pop, { maxHeight: 700 });
            // eslint-disable-next-line no-unused-expressions
            subLayer.on("dblclick", function () {
              if (feature["seleccionada"] === true) {
                let mycolor;
                feature["seleccionada"] = false;
                if (mycolor === undefined) {
                  mycolor = {
                    fillColor: eval(
                      `Sim.getModernizacion(${feature.properties["anio"]})`
                    ),
                    weight: 1,
                    color: "#b2b2b2",
                    fillOpacity: 1,
                  };
                }
                subLayer.setStyle({
                  fillColor: mycolor.fillColor,
                  color: mycolor.color,
                });
                for (var i = 0; i < featureSeleccion.length; i++) {
                  if (featureSeleccion[i].feature.id === feature.id) {
                    featureSeleccion.splice(i, 1);
                  }
                }
              } else {
                feature["seleccionada"] = true;
                subLayer.setStyle({
                  fillColor: ColorSeleccion,
                  color: bordeSeleccion,
                });
                featureSeleccion.push(subLayer);
              }
              setSublayerSeleccionada(featureSeleccion);
            });
          },

        });




        capaWFS["layer"] = layer;
        mapaReferencia.addLayer(capaWFS.layer);
        mapaReferencia.fitBounds(capaWFS.layer.getBounds());
        setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
        Swal.close();

        //peticion al servidor 
        //  $.ajax({
        //    url: capaWFS.urlS,
        //    async: true,
        //    type: 'GET',
        //    crossDomain: true,
        //    success: function (response) {
        //      Swal.close();
        //      console.log(response);
        //    },
        //    error: function (error) {
        //      console.log(error)
        //    }
        //  })

      } else {
        mensaje("Capa cargada", "info");
      }
    } else {
      mensaje("Selecciona año de consulta", "error");
    }
    //Swal.close();
  }

  function buscarAnio(data, anio) {
    let aux = [];

    data.map(item => {
      if (item.anio === anio) {
        aux.push(item);
      }
    })
    return aux;

  }
  const [showModalReportesK111, setShowModalReportesK111] = useState(false);
  const [showModalGraficasK111, setShowModalGraficasK111] = useState(false);

  function reportesK111() {
    setFilas([]);
    setAnioModernizacion([]);
    setShowModalReportesK111(!showModalReportesK111)
  }
  function graficasK111() {
    setFilas([]);
    setAnioModernizacion([]);
    setShowModalGraficasK111(!showModalGraficasK111);
  }

  const [tipoReporteK111, setTipoReporteK111] = useState("");
  function selecttipoReporteK111(tipo) {
    //console.log(tipo)
    setFilas([])
    setAnioModernizacion([])
    setTipoReporteK111(tipo);
  }

  const [tipoGraficaK111, setTipoGraficaK111] = useState("");
  function selectGraficaK111(tipo) {
    setFilas([]);
    setAnioModernizacion([]);
    if (tipo === "Obras") {
      //se hace la peticion de los datos
      //let d = filtrasDatosK(obrasrehabilitadasG, event.value)
      //console.log(obrasrehabilitadasG)
      setFilas(obrasrehabilitadasG);
    }
    setTipoGraficaK111(tipo)
  }


  //////////////////////-------------------------fUNCIONES PARA deNUE

  function mostrarCapa(e) {
    let MarkerOptions1 = {
      radius: 5,
      fillColor: "#f259d9",
      color: "#000000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    };
    const root1 = "http://localhost:8080/geoserver/Geomark/ows";
    //console.log(e.target.checked)
    //console.log(e.target.name)
    //console.log(e.target.id)
    //console.log(entidadD)

    if (e.target.checked) {

      if (entidadD.length !== 0) {
        let capaWFS = {}
        mensaje2("Cargando Informacion", "info");
        //let data = bucarDatos(e.target.name);
        //console.log(data)

        defaultParameters.typeName = `Geomark:${e.target.name}`;
        defaultParameters.cql_filter = `cve_ent='${entidadD.value}'` // AND cve_mun=106

        var parameters = L.Util.extend(defaultParameters);
        var urlS = root1 + L.Util.getParamString(parameters);
        capaWFS.urls = urlS;
        //console.log(urlS)


        $.ajax({
          url: capaWFS.urls,
          async: true,
          type: 'GET',
          success: function (response) {
            if (response.features.length > 0) {
              Swal.close();
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["name"] = e.target.name

              capaWFS["titulo"] = e.target.id
              capaWFS["features"] = response.features
              //console.log(response);

              //console.log(mapaReferencia)

              //Esto es para agregar la capa
              zIndexCapas = zIndexCapas + 1;
              //var target = document.getElementById('root');
              //var spinner = new Spinner(opts).spin(target);
              if (mapaReferencia !== null) {
                mapaReferencia.createPane(`${zIndexCapas}`);
                //mapaReferencia.spin(true);
                mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                  numeroIndex + capasVisualizadas.length;

                var geojson = new L.geoJson(capaWFS.response, {
                  pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, MarkerOptions1);
                  },
                  pane: `${zIndexCapas}`,
                  onEachFeature: function (feature = {}, subLayer) {
                    var pop = infoCapas.getEstados(feature.properties, e.target.name);
                    subLayer.bindPopup(pop, { maxHeight: 700 });
                  },
                });

                capaWFS["layer"] = geojson;
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                //setLeyVisualizadas([capaWFS.leyenda, ...leyVisualizadas]);
                mapaReferencia.addLayer(capaWFS.layer);
                mapaReferencia.fitBounds(capaWFS.layer.getBounds());
                console.log(capasVisualizadas)
                //desplegarLeyendasActivas(capaWFS, "");

              }
              //https://alumnouaemex-my.sharepoint.com/:u:/g/personal/borozcog_uaemex_mx/EWSq73PcditJkJF0wlebIMYBmYYc6TaQ3uInp-mOZH2UxA?e=F27e0W







            } else {
              mensaje("La conculta solicitada no contiene información", "warning");
            }



          }
        })

      } else {
        e.target.checked = false
        mensaje("Selecciona entidad federativa", "error");
      }




    } else {
      let aux = [];
      //console.log(capasVisualizadas)
      capasVisualizadas.map(item => {
        if (item.name === e.target.name) {
          mapaReferencia.removeLayer(item.layer);
        } else {
          aux.push(item);
        }

      })

      //console.log(aux);
      setCapasVisualizadas(aux);


    }
    //console.log(capasVisualizadas)

  }

  function mostrarCapasDenue(e, name, filtro, titulo, color) {
    let MarkerOptions1 = {
      radius: 5,
      fillColor: color,
      color: "#000000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    };
    //const root1 = "http://74.208.163.195:8080/geoserver/geomark/ows"
    //const root1 = "http://localhost:8080/geoserver/Geomark/ows";
    const root1 = "http://74.208.163.195:8080/geoserver/geomark/ows"

    if (e.target.checked) {
      if (entidadD.length !== 0) {
        let capaWFS = {}
        mensaje2("Cargando Informacion", "info");
        //let data = bucarDatos(e.target.name);
        //console.log(entidadD)

        defaultParameters.typeName = `geomark:${name}`;
        defaultParameters.cql_filter = `cve_ent='${entidadD.value}' and ${filtro}` // AND cve_mun=106

        var parameters = L.Util.extend(defaultParameters);
        var urlS = root1 + L.Util.getParamString(parameters);
        capaWFS.urls = urlS;
        //console.log(capaWFS.urls)




        $.ajax({
          url: capaWFS.urls,
          async: true,
          type: 'GET',
          crossDomain: true,
          success: function (response) {
            //console.log(response)
            if (response.features.length > 0) {

              Swal.close();
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["name"] = e.target.name

              capaWFS["titulo"] = e.target.id
              capaWFS["nombre"] = e.target.id
              capaWFS["leyenda"] = `${e.target.id}_leyenda`
              capaWFS["features"] = response.features
              capaWFS["color"] = color
              //console.log(response);

              //console.log(mapaReferencia)

              //Esto es para agregar la capa
              zIndexCapas = zIndexCapas + 1;
              //var target = document.getElementById('root');
              //var spinner = new Spinner(opts).spin(target);
              if (mapaReferencia !== null) {
                mapaReferencia.createPane(`${zIndexCapas}`);
                //mapaReferencia.spin(true);
                mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                  numeroIndex + capasVisualizadas.length;

                var geojson = new L.geoJson(capaWFS.response, {
                  pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, MarkerOptions1);
                  },
                  pane: `${zIndexCapas}`,
                  onEachFeature: function (feature = {}, subLayer) {



                    var pop = infoCapas.getEstados(feature.properties, name);
                    subLayer.bindPopup(pop, { maxHeight: 700 });

                    subLayer.on("dblclick", function () {
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      //console.log(subLayer)
                      featureSeleccion.push(subLayer);
                    })
                  },
                });

                capaWFS["layer"] = geojson;
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                //setLeyVisualizadas([capaWFS.leyenda, ...leyVisualizadas]);
                mapaReferencia.addLayer(capaWFS.layer);
                mapaReferencia.fitBounds(capaWFS.layer.getBounds());
                //console.log(capasVisualizadas)
                desplegarLeyendasActivas(capaWFS, "");

              }

            } else {
              mensaje("La conculta solicitada no contiene información", "warning");
            }



          }
        })


      } else {
        e.target.checked = false
        mensaje("Selecciona entidad federativa", "error");
      }

    } else {

      let aux = [];
      capasVisualizadas.map(item => {
        if (item.name === e.target.name) {
          mapaReferencia.removeLayer(item.layer);
          quitarLeyenda(item);
        } else {
          aux.push(item);
        }

      })
      setCapasVisualizadas(aux);
    }

  }



  const [entidadD, setEntidadD] = useState([])
  const [muniss, setMuniss] = useState([])
  function selectEntidad(e) {
    if (e !== null) {
      //console.log(e);
      let aux = []
      municipios2.map(item => {
        if (item.estado === e.label) {
          aux.push(item)
        }
      })
      //console.log(aux);
      setMuniss(aux)
      setEntidadD(e);
    } else {
      setEntidadD([]);
      setMuniss([])
    }
    //console.log(e)
  }

  const [munSelectM, setMunSelectM] = useState([])
  function selectMunicipioManz(e) {
    if (e !== null) {
      setMunSelectM(e)
    } else {
      setMunSelectM([])
    }

  }

  function mostrarCapaIter(e, name, filtro, titulo, funcionC) {
    //console.log(e.target.checked);
    aux = funcionC;
    let MarkerOptions1 = {
      radius: 5,
      fillColor: "#f259d9",
      color: "#000000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    };
    const root1 = "http://74.208.163.195:8080/geoserver/geomark/ows"
    // console.log("Iter")

    if (e.target.checked) {
      if (entidadD.length !== 0) {
        let capaWFS = {}
        defaultParameters.typeName = `geomark:${entidadD.iter}`;
        if (filtro !== "") {
          defaultParameters.cql_filter = `${filtro}`
        }
        // // AND cve_mun=106

        var parameters = L.Util.extend(defaultParameters);
        var urlS = root1 + L.Util.getParamString(parameters);
        capaWFS.urls = urlS;

        //console.log(urlS)
        mensaje2("Cargando información", "info");

        $.ajax({
          url: capaWFS.urls,
          async: true,
          type: 'GET',
          success: function (response) {

            if (response.features.length > 0) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["name"] = name

              capaWFS["titulo"] = e.target.id
              capaWFS["nombre"] = e.target.id
              capaWFS["leyenda"] = `${e.target.id}_leyenda`
              capaWFS["features"] = response.features
              capaWFS["funcion"] = funcionC
              //console.log(response);

              //console.log(mapaReferencia)

              //Esto es para agregar la capa
              zIndexCapas = zIndexCapas + 1;
              //var target = document.getElementById('root');
              //var spinner = new Spinner(opts).spin(target);
              if (mapaReferencia !== null) {
                mapaReferencia.createPane(`${zIndexCapas}`);
                //mapaReferencia.spin(true);
                mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                  numeroIndex + capasVisualizadas.length;

                var geojson = new L.geoJson(capaWFS.response, {
                  pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, MarkerOptions1);
                  },
                  style: estiloInd,
                  pane: `${zIndexCapas}`,
                  onEachFeature: function (feature = {}, subLayer) {
                    //console.log(name);
                    var pop = infoCapas.getEstados(feature.properties, entidadD.iter);
                    subLayer.bindPopup(pop, { maxHeight: 700 });
                    subLayer.on("dblclick", function () {
                      subLayer.setStyle({
                        fillColor: ColorSeleccion,
                        color: bordeSeleccion,
                      });
                      //console.log(subLayer)
                      featureSeleccion.push(subLayer);
                    })
                  },
                });

                capaWFS["layer"] = geojson;
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                //setLeyVisualizadas([capaWFS.leyenda, ...leyVisualizadas]);
                mapaReferencia.addLayer(capaWFS.layer);
                mapaReferencia.fitBounds(capaWFS.layer.getBounds());
                //console.log(capasVisualizadas)
                desplegarLeyendasActivas(capaWFS, funcionC);
              }

              Swal.close();

            } else {
              mensaje("La conculta solicitada no contiene información", "warning");
            }


          }
        })
      } else {
        e.target.checked = false
        mensaje("Selecciona entidad federativa", "error");
      }
    } else {

      let aux = [];
      capasVisualizadas.map(item => {
        if (item.name === name) {
          mapaReferencia.removeLayer(item.layer);
          quitarLeyenda(item);
        } else {
          aux.push(item);
        }

      })
      setCapasVisualizadas(aux);
      /*
      let aux = [];
      capasVisualizadas.map(item => {
        if (item.name === e.target.name) {
          mapaReferencia.removeLayer(item.layer);
          quitarLeyenda(item);
        } else {
          aux.push(item);
        }

      })
      setCapasVisualizadas(aux);
      */

    }

  }

  function mostrarCapaManzanas(e, name, filtro, titulo, funcionC) {

    aux = funcionC;
    let MarkerOptions1 = {
      radius: 5,
      fillColor: "#f259d9",
      color: "#000000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    };
    const root1 = "http://74.208.163.195:8080/geoserver/geomark/ows"

    if (e.target.checked) {
      if (entidadD.length !== 0) {
        let capaWFS = {}
        defaultParameters.typeName = `geomark:${entidadD.manzanas}`;
        if (filtro !== "") {
          if (munSelectM.length !== 0) {
            //console.log(munSelectM);
            defaultParameters.cql_filter = `${filtro} and nom_mun='${munSelectM.value}'`
          } else {
            defaultParameters.cql_filter = `${filtro}`
          }

        }
        // // AND cve_mun=106

        var parameters = L.Util.extend(defaultParameters);
        var urlS = root1 + L.Util.getParamString(parameters);
        capaWFS.urls = urlS;

        //console.log(urlS)
        mensaje2("Cargando información", "info");

        $.ajax({
          url: capaWFS.urls,
          async: true,
          type: 'GET',
          success: function (response) {

            if (response.features.length > 0) {
              capaWFS.response = response;
              capaWFS["attribution"] = "No disponible";
              capaWFS["transparencia"] = 1;
              capaWFS["tipo"] = "wfs";
              capaWFS["habilitado"] = true;
              capaWFS["format"] = "json";
              capaWFS["servicio"] = root;
              capaWFS["name"] = name

              capaWFS["titulo"] = e.target.id
              capaWFS["nombre"] = e.target.id
              capaWFS["leyenda"] = `${e.target.id}_leyenda`
              capaWFS["features"] = response.features
              capaWFS["funcion"] = funcionC

              //console.log(mapaReferencia)

              //Esto es para agregar la capa
              zIndexCapas = zIndexCapas + 1;
              //var target = document.getElementById('root');
              //var spinner = new Spinner(opts).spin(target);
              if (mapaReferencia !== null) {
                mapaReferencia.createPane(`${zIndexCapas}`);
                //mapaReferencia.spin(true);
                mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                  numeroIndex + capasVisualizadas.length;

                var geojson = new L.geoJson(capaWFS.response, {
                  pointToLayer: function (feature, latlng) {
                    return L.circleMarker(latlng, MarkerOptions1);
                  },
                  style: estiloInd,
                  pane: `${zIndexCapas}`,
                  onEachFeature: function (feature = {}, subLayer) {
                    //console.log(name);
                    var pop = infoCapas.getEstados(feature.properties, entidadD.manzanas);
                    subLayer.bindPopup(pop, { maxHeight: 700 });
                  },
                });

                capaWFS["layer"] = geojson;
                setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
                //setLeyVisualizadas([capaWFS.leyenda, ...leyVisualizadas]);
                mapaReferencia.addLayer(capaWFS.layer);
                mapaReferencia.fitBounds(capaWFS.layer.getBounds());
                //console.log(capasVisualizadas)
                desplegarLeyendasActivas(capaWFS, funcionC);
              }

              Swal.close();

            } else {
              mensaje("La conculta solicitada no contiene información", "warning");
            }


          }
        })
      } else {
        e.target.checked = false
        mensaje("Selecciona entidad federativa", "error");
      }
    } else {

      let aux = [];
      capasVisualizadas.map(item => {
        if (item.name === name) {
          mapaReferencia.removeLayer(item.layer);
          quitarLeyenda(item);
        } else {
          aux.push(item);
        }

      })
      setCapasVisualizadas(aux);

    }


    //console.log(entidadD.label.toUpperCase())









  }

  function mostrarCapasGeoprocesos(e, name, filtro, titulo, funcionC) {

    aux = funcionC;
    let MarkerOptions1 = {
      radius: 5,
      fillColor: "#f259d9",
      color: "#000000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
    };
    const root1 = "http://74.208.163.195:8080/geoserver/geomark/ows"

    if (e.target.checked) {
      let capaWFS = {}
      defaultParameters.typeName = `geomark:${name}`;
      if (filtro !== "") {
        if (munSelectM.length !== 0) {
          //console.log(munSelectM);
          // defaultParameters.cql_filter = `${filtro} and nom_mun='${munSelectM.value}'`
        } else {
          //defaultParameters.cql_filter = `${filtro}`
        }
      }
      // // AND cve_mun=106

      var parameters = L.Util.extend(defaultParameters);
      var urlS = root1 + L.Util.getParamString(parameters);
      capaWFS.urls = urlS;

      //console.log(urlS)
      mensaje2("Cargando información", "info");

      $.ajax({
        url: capaWFS.urls,
        async: true,
        type: 'GET',
        success: function (response) {
          //console.log(response)

          if (response.features.length > 0) {
            capaWFS.response = response;
            capaWFS["attribution"] = "No disponible";
            capaWFS["transparencia"] = 1;
            capaWFS["tipo"] = "wfs";
            capaWFS["habilitado"] = true;
            capaWFS["format"] = "json";
            capaWFS["servicio"] = root;
            capaWFS["name"] = name

            capaWFS["titulo"] = titulo
            capaWFS["nombre"] = name
            capaWFS["leyenda"] = `${name}_leyenda`
            capaWFS["features"] = response.features
            capaWFS["funcion"] = funcionC

            //console.log(mapaReferencia)

            //Esto es para agregar la capa
            zIndexCapas = zIndexCapas + 1;
            //var target = document.getElementById('root');
            //var spinner = new Spinner(opts).spin(target);
            if (mapaReferencia !== null) {
              mapaReferencia.createPane(`${zIndexCapas}`);
              //mapaReferencia.spin(true);
              mapaReferencia.getPane(`${zIndexCapas}`).style.zIndex =
                numeroIndex + capasVisualizadas.length;

              var geojson = new L.geoJson(capaWFS.response, {
                pointToLayer: function (feature, latlng) {
                  return L.circleMarker(latlng, MarkerOptions1);
                },
                style: estiloInd,
                pane: `${zIndexCapas}`,
                onEachFeature: function (feature = {}, subLayer) {
                  //console.log(name);
                  var pop = infoCapas.getEstados(feature.properties, name);
                  subLayer.bindPopup(pop, { maxHeight: 700 });
                },
              });

              capaWFS["layer"] = geojson;
              setCapasVisualizadas([capaWFS, ...capasVisualizadas]);
              //setLeyVisualizadas([capaWFS.leyenda, ...leyVisualizadas]);
              mapaReferencia.addLayer(capaWFS.layer);
              mapaReferencia.fitBounds(capaWFS.layer.getBounds());
              //console.log(capasVisualizadas)
              desplegarLeyendasActivas(capaWFS, funcionC);
            }

            Swal.close();

          } else {
            mensaje("La conculta solicitada no contiene información", "warning");
          }


        }
      })

    } else {

      let aux = [];
      capasVisualizadas.map(item => {
        if (item.name === name) {
          mapaReferencia.removeLayer(item.layer);
          quitarLeyenda(item);
        } else {
          aux.push(item);
        }

      })
      setCapasVisualizadas(aux);

    }


    //console.log(entidadD.label.toUpperCase())









  }

  var capasBuffer=[]
  async function calcularBuffer(e) {
    
    if (e.target.checked) {
      //esta seleccionado el buffer
      //console.log(featureSeleccion)
      if (featureSeleccion.length !== 0) {

        //mensaje para pedir el radio del buffer
        const { value: text } = await Swal.fire({
          input: 'textarea',
          inputLabel: 'INGRESA EL VALOR DEL BUFFER EN KILÓMETROS',
          inputPlaceholder: '',
          inputAttributes: {
            'aria-label': 'Type your message here'
          },
          showCancelButton: true
        })

        if (text) {
          //var point = turf.point([-90.548630, 14.616599]);
          //var buffered = turf.buffer(point, 500);
          //var bufferedLayer = L.geoJSON(buffered, { interactive: false });
          //bufferedLayer.setZIndex = 0;
          //mapaReferencia.addLayer(bufferedLayer)
          //se recorre el arreglo para calcular los buffers
          featureSeleccion.map((item, index) => {
            //console.log(item.feature.geometry.coordinates)
            var point = turf.point(item.feature.geometry.coordinates);
            var buffered = turf.buffer(point, Number(text));
            var bufferedLayer = L.geoJSON(buffered, { interactive: false });
            bufferedLayer.setZIndex = 400 + index;
            mapaReferencia.addLayer(bufferedLayer)
            capasBuffer.push(bufferedLayer);
          })

        }
        featureSeleccion.length = 0

      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'info',
          title: 'Selecciona un punto para generar el buffer',
          showConfirmButton: false,
          timer: 1500
        })
      }

    }else{
      //console.log("LA OPCION NO ESTA SELECCIONADA")
      //no esta seleccionada la opcion del buffer
      capasBuffer.map(item=>{
        mapaReferencia.removeLayer(item);
      })
      capasBuffer.length=0;
    }


  }

  ///----------------------------------------------Comienza Interfaz Grafica
  return (
    <>
      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalPDF}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalPDF(!showModalPDF)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Titulo Modal</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div></div>
        </Modal.Body>
      </Modal>

      {
        //Modal Reportes K111
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalReportesK111}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalReportesK111(!showModalReportesK111)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reportes Programa K111</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            <div className="row">
              <div className="col-6">
                <h5
                  className="tituloReporte1"
                  onClick={() => selecttipoReporteK111("reporteObras")}
                >
                  Obras Rehabilitadas
                </h5>
              </div>
              <div className="col-6">
                <h5
                  className="tituloReporte1"
                  onClick={() => selecttipoReporteK111("reporteinversion")}
                >
                  Inversión Contratada por Entidad Federativa
                </h5>
              </div>
            </div>
            <div className="row">
              {
                tipoReporteK111 !== "" && (

                  <>
                    <div className="col-1">Año</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectAniosModernizacion}
                        name="año"
                        options={aniosmodernizacion}
                        value={anioModernizacion}
                      />
                    </div>
                  </>
                )
              }
            </div>
            <br></br>
            {
              tipoReporteK111 === "reporteObras" ? (
                <>
                  {
                    anioModernizacion.value !== undefined && (
                      <div className="row">
                        <div className="col-12 text-center tituloReporte">
                          <h6 className="tituloReporte">{`Obras Rehabilitadas ${anioModernizacion.value}`}</h6>
                        </div>
                      </div>
                    )
                  }
                  <div className="row">
                    <br></br>
                    <div className="col-12">
                      {
                        filas.length > 0 && (
                          <TableData
                            columns={repObras}
                            rows={filas}
                            tam="100%"
                          />
                        )
                      }
                    </div>
                  </div>
                </>
              ) : (
                tipoReporteK111 === "reporteinversion" && (

                  <>
                    {
                      anioModernizacion.value !== undefined && (
                        <div className="row">
                          <div className="col-12 text-center tituloReporte">
                            <h6 className="tituloReporte">{`Inversión por Entidad Federativa en MDP ${anioModernizacion.value}`}</h6>
                          </div>
                        </div>
                      )
                    }
                    <div className="row">
                      <br></br>
                      <div className="col-12">
                        {
                          filas.length > 0 && (
                            <TableData
                              columns={repinversion}
                              rows={filas}
                              tam="100%"
                            />
                          )
                        }
                      </div>
                    </div>
                  </>

                ))
            }
          </div>
        </Modal.Body>
      </Modal>
      {
        //Modal Graficas K111
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalGraficasK111}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalGraficasK111(!showModalGraficasK111)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Graficas Programa K111</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            <div className="row">
              <div className="col-6">
                <h5
                  className="tituloReporte1"
                  onClick={() => selectGraficaK111("Obras")}
                >
                  Obras Rehabilitadas
                </h5>
              </div>
              <div className="col-6">
                <h5
                  className="tituloReporte1"
                  onClick={() => selectGraficaK111("inversion")}
                >
                  Inversión Contratada por Entidad Federativa
                </h5>
              </div>
            </div>
            <div className="row">
              {
                tipoGraficaK111 === "inversion" && (

                  <>
                    <div className="col-1">Año</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectAniosModernizacion}
                        name="año"
                        options={aniosmodernizacion}
                        value={anioModernizacion}
                      />
                    </div>
                  </>
                )
              }
            </div>
            <br></br>
            {
              tipoGraficaK111 === "Obras" ? (
                <>
                  <div className="row">
                    <div className="col-12 text-center tituloReporte">
                      <h6 className="tituloReporte">{`Obras Rehabilitadas`}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <br></br>
                    <div className="col-12">
                      <h7 className="tituloG">{`El presupuesto de inversión esta en precios corrientes.`}</h7><br></br>
                      <h7 className="tituloG">{`En el año 2022 no hubo inversión del programa k111.`}</h7>
                      {
                        filas.length > 0 && (
                          <Graficas
                            tipo="barras2"
                            datos={filas}
                            variable="anio"
                          />
                        )
                      }
                    </div>
                  </div>
                </>
              ) : (
                tipoGraficaK111 === "inversion" && (

                  <>
                    <div className="row">
                      <div className="col-12 text-center tituloReporte">
                        {
                          anioModernizacion.value !== undefined && (
                            <h6 className="tituloReporte">{`Inversión Contratada por Entidad federativa (MDP) ${anioModernizacion.value}`}</h6>
                          )
                        }
                      </div>
                    </div>
                    <div className="row">
                      <br></br>
                      <div className="col-12">
                        <h7 className="tituloG2">{'El presupuesto de inversion esta en precios corrientes.'}</h7>
                        {
                          filas.length > 0 && (
                            <>
                              <Graficas
                                tipo="barras2"
                                datos={filas}
                                variable="estado"
                              />
                            </>
                          )
                        }
                      </div>
                    </div>
                  </>

                ))
            }
          </div>
        </Modal.Body>
      </Modal>

      {
        //Modal graficas poblacion
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showGrafPoblacion}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setshowGrafPoblacion(!showGrafPoblacion)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Población</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div classname="contenedorCalid">
            <div className="row justify-content-between">
              <div className="col-4 col-lg-4">
                <h5
                  className="tituloReporte1"
                  onClick={() => tipoGraficaPoblacion("nacional")}
                >
                  Nacional
                </h5>
              </div>
              <div className="col-4 col-lg-4">
                <h5
                  className="tituloReporte1"
                  onClick={() => tipoGraficaPoblacion("estado")}
                >
                  Estatal
                </h5>
              </div>
              <div className="col-4 col-lg-4">
                <h5
                  className="tituloReporte1"
                  onClick={() => tipoGraficaPoblacion("municipio")}
                >
                  Municipal
                </h5>
              </div>
            </div>
            <div className="row justify-content-between">
              {graficaPob === "estado" ? (
                <>
                  <div className="row">
                    <div className="col-2">Año de Consulta</div>
                    <div className="col-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={changeAnioPob}
                        name="edo"
                        options={aniosCenso}
                        value={anioPob}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <h5></h5>
                    <div className="col-2">Entidad Federativa</div>
                    <div className="col-5">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={obtenerDatosPoblacion}
                        name="edo"
                        options={entidades}
                        value={entidadSelect}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {datosGraficasCalidadG.length !== 0 &&
                      entidadSelect !== undefined ? (
                      <>
                        <Graficas
                          tipo="barras"
                          datos={datosGraficasCalidadG}
                          variable="municipio"
                          opcion="estado"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <br></br>
                  {datosPoblacionG.length !== 0 &&
                    entidadSelect !== undefined ? (
                    <>
                      <div className="row">
                        <div className="col-6">
                          <Graficas
                            tipo="pie2"
                            datos={datosPoblacionG}
                            variable="total"
                          />
                        </div>
                        <div className="col-6 informacion">
                          {entidadSelect !== null && (
                            <>
                              <h2>{`Poblacion Total de ${entidadSelect.label}`}</h2>
                              <h3>
                                {new Intl.NumberFormat("en-US").format(datosPoblacionG[0].mas + datosPoblacionG[1].fem)}
                              </h3>
                              <br />
                              <h2>{`Poblacion Masculina`}</h2>
                              <h3>{`${new Intl.NumberFormat("en-US").format(datosPoblacionG[0].mas)}`}</h3>
                              <br />
                              <h2>{`Poblacion Femenina`}</h2>
                              <h3>{`${new Intl.NumberFormat("en-US").format(datosPoblacionG[1].fem)}`}</h3>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : graficaPob === "municipio" ? (
                <>
                  <div className="row">
                    <div className="col-2">Año de Consulta</div>
                    <div className="col-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={changeAnioPob}
                        name="edo"
                        options={aniosCenso}
                        value={anioPob}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <h5></h5>
                    <div className="col-2">Entidad Federativa</div>
                    <div className="col-5">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={seleccionEstado}
                        name="edo"
                        options={entidades}
                        value={entidadSelect}
                      />
                    </div>
                  </div>
                  {entidadSelect !== undefined ? (
                    <>
                      <div className="row filas">
                        <div className="col-2">Municipio</div>
                        <div className="col-9">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            onChange={obtenerDatosPoblacion}
                            name="calidad"
                            options={municipiosS}
                            value={munSelect}
                          />
                        </div>
                      </div>
                      <div className="row">
                        {datosGraficasCalidadG.length !== 0 &&
                          munSelect !== undefined ? (
                          <>
                            <Graficas
                              tipo="barras"
                              datos={datosGraficasCalidadG}
                              variable="localidad"
                              opcion="estado"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <br></br>
                      {datosPoblacionG.length !== 0 &&
                        munSelect !== undefined ? (
                        <>
                          <div className="row">
                            <div className="col-6">
                              <Graficas
                                tipo="pie2"
                                datos={datosPoblacionG}
                                variable="total"
                              />
                            </div>
                            <div className="col-6 informacion">
                              {entidadSelect !== null &&
                                munSelect !== undefined && (
                                  <>
                                    <h2>{`Poblacion Total`}</h2>
                                    <h2>{`${entidadSelect.label} - ${munSelect.label} `}</h2>
                                    <h3>
                                      {new Intl.NumberFormat("en-US").format(datosPoblacionG[0].mas + datosPoblacionG[1].fem)}
                                    </h3>
                                    <br />
                                    <h2>{`Poblacion Masculina`}</h2>
                                    <h3>{`${new Intl.NumberFormat("en-US").format(datosPoblacionG[0].mas)}`}</h3>
                                    <br />
                                    <h2>{`Poblacion Femenina`}</h2>
                                    <h3>{`${new Intl.NumberFormat("en-US").format(datosPoblacionG[1].fem)}`}</h3>
                                  </>
                                )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                graficaPob === "nacional" && (
                  <>
                    <>
                      <div className="row">
                        <div className="col-2">Año de Consulta</div>
                        <div className="col-3">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            onChange={obtenerDatosPoblacion}
                            name="edo"
                            options={aniosCenso}
                            value={anioPob}
                          />
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        {anioPob !== undefined && (
                          <h3 className="titulosM">{`Censo Nacional ${anioPob.value}`}</h3>
                        )}
                      </div>
                      <div className="row">
                        {datosGraficasCalidadG.length !== 0 ? (
                          <>
                            <Graficas
                              tipo="barras"
                              datos={datosGraficasCalidadG}
                              variable="estado"
                              nombre="Poblacion"
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <br></br>
                      {datosPoblacionG.length !== 0 ? (
                        <>
                          <div className="row">
                            <div className="col-5 informacion">
                              <h2>{`Poblacion Total`}</h2>
                              <h3>{new Intl.NumberFormat("en-US").format(totalPob)}</h3>
                              <br />
                              <h2>{`Poblacion Masculina`}</h2>
                              <h3>{`${new Intl.NumberFormat("en-US").format(datosPoblacionG[0].mas)}`}</h3>
                              <br />
                              <h2>{`Poblacion Femenina`}</h2>
                              <h3>{`${new Intl.NumberFormat("en-US").format(datosPoblacionG[1].fem)}`}</h3>
                            </div>
                            <div className="col-lg-5">
                              <Graficas
                                tipo="pie2"
                                datos={datosPoblacionG}
                                variable="total"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </>
                )
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {
        //modal reportes poblacion
      }

      <Modal
        dialogAs={DraggableModalDialog}
        show={showReportePoblacion}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setshowReportePoblacion(!showReportePoblacion)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reporte Población</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div className="ContenedorReporteP">
            <div className="row titulosM">
              <h3>Titulo</h3>
            </div>
            <div className="row">
              <div className="col-12">
                {datosReportPob !== undefined && (
                  <>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th>Número de municipios</th>
                          <td className="text-center" colspan="4">
                            {datosReportPob.municipios}
                          </td>
                        </tr>
                        <tr>
                          <th>Número de zonas metropolitanas</th>
                          <td className="text-center" colspan="4">
                            {datosReportPob.zonas}
                          </td>
                        </tr>
                        <tr>
                          <th>Población del Censo de 2020</th>
                          <td className="text-center" colspan="2">
                            Regional
                          </td>
                          <td className="text-center" colspan="2">
                            Zonas metropolitanas
                          </td>
                        </tr>
                        <tr>
                          <th></th>
                          <td className="text-center">Habitantes</td>
                          <td className="text-center">Porcenjate</td>
                          <td className="text-center">Habitantes</td>
                          <td className="text-center">Porcenjate</td>
                        </tr>
                        <tr>
                          <th className="celda">Total</th>
                          <td className="text-center">
                            {datosReportPob.Tregional}
                          </td>
                          <td className="text-center">100%</td>
                          <td className="text-center">
                            {datosReportPob.Tzona}
                          </td>
                          <td className="text-center">
                            {(
                              (datosReportPob.Tzona /
                                datosReportPob.Tregional) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                        </tr>
                        <tr>
                          <th className="celda">Urbana</th>
                          <td className="text-center">
                            {datosReportPob.urbanaR}
                          </td>
                          <td className="text-center">
                            {(
                              (datosReportPob.urbanaR /
                                datosReportPob.Tregional) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                          <td className="text-center">
                            {datosReportPob.urbanaZ}
                          </td>
                          <td className="text-center">
                            {(
                              (datosReportPob.urbanaZ /
                                datosReportPob.urbanaR) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                        </tr>
                        <tr>
                          <th className="celda">Rural</th>
                          <td className="text-center">
                            {datosReportPob.ruralR}
                          </td>
                          <td className="text-center">
                            {(
                              (datosReportPob.ruralR /
                                datosReportPob.Tregional) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                          <td className="text-center">
                            {datosReportPob.ruralz}
                          </td>
                          <td className="text-center">
                            {(
                              (datosReportPob.ruralz / datosReportPob.ruralR) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                        </tr>
                        <tr>
                          <th className="celda">
                            Población total proyectada a 2030
                          </th>
                          <td className="text-center">
                            {Math.round(
                              datosReportPob.Tregional *
                              Math.pow(1.01621729, 10)
                            )}
                          </td>
                          <td className="text-center"></td>
                          <td className="text-center">
                            {Math.round(
                              datosReportPob.Tzona * Math.pow(1.01828614, 10)
                            )}
                          </td>
                          <td className="text-center">
                            {(
                              (Math.round(
                                datosReportPob.Tzona * Math.pow(1.01828614, 10)
                              ) /
                                Math.round(
                                  datosReportPob.Tregional *
                                  Math.pow(1.01621729, 10)
                                )) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {
        //Modal GRaficas Calidad del Agua
      }

      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalGrafCalidad}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => mostrarGraficasCalidad()}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Gráficas Calidad del Agua</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div classname="contenedorCalid">
            <div className="row justify-content-between">
              <div className="col-4 col-lg-4">
                <h5
                  className="tituloReporte1"
                  onClick={() => tipoGraficaCalidad("estado")}
                >
                  Por Estado
                </h5>
              </div>
              <div className="col-4 col-lg-4">
                <h5
                  className="tituloReporte1"
                  onClick={() => tipoGraficaCalidad("municipio")}
                >
                  Por Municipio
                </h5>
              </div>
              <div className="col-4 col-lg-4">
                <h5
                  className="tituloReporte1"
                  onClick={() => tipoGraficaCalidad("rha")}
                >
                  Por RHA
                </h5>
              </div>
            </div>

            <div className="row justify-content-between">
              {tipoGraficaCal === "estado" ? (
                <>
                  <div className="col-6 col-lg-6">
                    <h5
                      className="tituloReporte1"
                      onClick={() => ObtenerGrafica1Calidad("graficaAnual")}
                    >
                      Evolución de la Calidad del Agua
                    </h5>
                  </div>
                  <div className="col-6 col-lg-6">
                    <h5
                      className="tituloReporte1"
                      onClick={() => ObtenerGrafica1Calidad("graficaClasiCal")}
                    >
                      Calidad del agua
                    </h5>
                  </div>
                </>
              ) : tipoGraficaCal === "municipio" ? (
                <>
                  <div className="col-6 col-lg-6 ">
                    <h5
                      className="tituloReporte1"
                      onClick={() => ObtenerGrafica1Calidad("graficaMunEvo")}
                    >
                      Evolución de la calidad del Agua
                    </h5>
                  </div>
                  <div className="col-6 col-lg-6">
                    <h5
                      className="tituloReporte1"
                      onClick={() => ObtenerGrafica1Calidad("graficaClasiMun")}
                    >
                      Calidad del Agua
                    </h5>
                  </div>
                </>
              ) : (
                tipoGraficaCal === "rha" && (
                  <>
                    <>
                      <div className="col-6 col-lg-6 ">
                        <h5
                          className="tituloReporte1"
                          onClick={() => ObtenerGrafica1Calidad("graficaRhaEv")}
                        >
                          Evolución de la calidad del Agua
                        </h5>
                      </div>
                      <div className="col-6 col-lg-6">
                        <h5
                          className="tituloReporte1"
                          onClick={() =>
                            ObtenerGrafica1Calidad("graficaClasiRha")
                          }
                        >
                          Calidad del Agua
                        </h5>
                      </div>
                    </>
                  </>
                )
              )}
            </div>
            <br />

            <div className="row">
              {graficaF === "graficaAnual" && tipoGraficaCal !== "" ? (
                <>
                  <div className="contenedorSelect">
                    <div className="row filas">
                      <div className="col-2">Estado</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={seleccionEstado}
                          name="edo"
                          options={entidades}
                          value={estadoSelect}
                        />
                      </div>
                    </div>
                    <div className="row filas">
                      <div className="col-2">Parametro</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={datosGraficasCalidad}
                          name="calidad"
                          options={paramCapas}
                          value={paramCapa}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <h5 className="titulosM">
                        Evolución de la calidad el agua por sitio por estado
                      </h5>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row filas">
                    <div className="col-12">
                      {(estadoC !== "" || parametroCalid !== "") && (
                        <h5 className="titulosM">{`${estadoC} ${parametroCalid}`}</h5>
                      )}
                    </div>
                  </div>
                  <div className="row filas">
                    <div className="col-12">
                      {datosGraficasCalidadG.length > 0 &&
                        parametroCalid !== "" ? (
                        <Graficas
                          tipo="barras"
                          datos={datosGraficasCalidadG}
                          variable="sitio"
                        />
                      ) : (
                        <>{datosGraficasCalidadG.length === 0 && <></>}</>
                      )}
                    </div>
                  </div>
                </>
              ) : graficaF === "graficaClasiCal" && tipoGraficaCal !== "" ? (
                <>
                  <div className="contenedorSelect">
                    <div className="row filas">
                      <div className="col-2">Estado</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={seleccionEstado}
                          name="edo"
                          options={entidades}
                          value={estadoSelect}
                        />
                      </div>
                    </div>
                    <div className="row filas">
                      <div className="col-2">Parametro</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={datosGraficasCalidad}
                          name="calidad"
                          options={paramCapas}
                          value={paramCapa}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <h5 className="titulosM">Calidad el Agua</h5>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row filas">
                    <div className="col-12">
                      {(estadoC !== "" || parametroCalid !== "") && (
                        <h5 className="titulosM">{`${estadoC} ${parametroCalid}`}</h5>
                      )}
                    </div>
                  </div>
                  <div className="row filas">
                    <div className="col-12">
                      {datosGraficasCalidadG.length > 0 &&
                        parametroCalid !== "" ? (
                        <Graficas
                          tipo="barras2"
                          datos={datosGraficasCalidadG}
                          variable="anio"
                        />
                      ) : (
                        <>{datosGraficasCalidadG.length === 0 && <></>}</>
                      )}
                    </div>
                  </div>
                  <div className="row filas">
                    {datosGraficasCP.length > 0 && parametroCalid !== "" && (
                      <Graficas
                        tipo="pie"
                        datos={datosGraficasCP}
                        variable="total"
                      />
                    )}
                  </div>
                </>
              ) : graficaF === "graficaMunEvo" && tipoGraficaCal !== "" ? (
                <>
                  <div className="contenedorSelect">
                    <div className="row filas">
                      <div className="col-2">Estado</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={seleccionEstado}
                          name="edo"
                          options={entidades}
                          value={estadoSelect}
                        />
                      </div>
                    </div>
                    {estadoSelect !== undefined && (
                      <>
                        <div className="row filas">
                          <div className="col-2">Municipio</div>
                          <div className="col-9">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              onChange={cambioMun}
                              name="calidad"
                              options={municipiosS}
                              value={munSelect}
                            />
                          </div>
                        </div>
                        <div className="row filas">
                          <div className="col-2">Parametro</div>
                          <div className="col-9">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              onChange={datosGraficasCalidad}
                              name="calidad"
                              options={paramCapas}
                              value={paramCapa}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <h5 className="titulosM">
                        Evolucion de la Calidad el agua por Municipio por Sitio
                      </h5>
                    </div>
                  </div>
                  <br />
                  <div className="row filas">
                    <div className="col-12">
                      {(munS !== "" || parametroCalid !== "") && (
                        <h5 className="titulosM">{`${munS} ${parametroCalid}`}</h5>
                      )}
                    </div>
                  </div>
                  <div className="row filas">
                    <div className="col-12">
                      {datosGraficasCalidadG.length > 0 &&
                        parametroCalid !== "" ? (
                        <Graficas
                          tipo="barras"
                          datos={datosGraficasCalidadG}
                          variable="sitio"
                        />
                      ) : (
                        <>{datosGraficasCalidadG.length === 0 && <></>}</>
                      )}
                    </div>
                  </div>
                </>
              ) : graficaF === "graficaClasiMun" && tipoGraficaCal !== "" ? (
                <>
                  <div className="contenedorSelect">
                    <div className="row filas">
                      <div className="col-2">Estado</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={seleccionEstado}
                          name="edo"
                          options={entidades}
                          value={estadoSelect}
                        />
                      </div>
                    </div>
                    {estadoSelect !== undefined && (
                      <>
                        <div className="row filas">
                          <div className="col-2">Municipio</div>
                          <div className="col-9">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              onChange={cambioMun}
                              name="calidad"
                              options={municipiosS}
                              value={munSelect}
                            />
                          </div>
                        </div>
                        <div className="row filas">
                          <div className="col-2">Parametro</div>
                          <div className="col-9">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              onChange={datosGraficasCalidad}
                              name="calidad"
                              options={paramCapas}
                              value={paramCapa}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <h5 className="titulosM">
                        Calidad del Agua por Municipio
                      </h5>
                    </div>
                  </div>
                  <br />
                  <div className="row filas">
                    <div className="col-12">
                      {(munS !== "" || parametroCalid !== "") && (
                        <h5 className="titulosM">{`${munS} ${parametroCalid}`}</h5>
                      )}
                    </div>
                  </div>
                  <div className="row filas">
                    <div className="col-12">
                      {datosGraficasCalidadG.length > 0 &&
                        parametroCalid !== "" ? (
                        <Graficas
                          tipo="barras2"
                          datos={datosGraficasCalidadG}
                          variable="anio"
                        />
                      ) : (
                        <>{datosGraficasCalidadG.length === 0 && <></>}</>
                      )}
                    </div>
                  </div>
                  <div className="row filas">
                    {datosGraficasCP.length > 0 && parametroCalid !== "" && (
                      <Graficas
                        tipo="pie"
                        datos={datosGraficasCP}
                        variable="total"
                      />
                    )}
                  </div>
                </>
              ) : graficaF === "graficaRhaEv" && tipoGraficaCal !== "" ? (
                <>
                  <div className="contenedorSelect">
                    <div className="row filas">
                      <div className="col-3">
                        Región Hidrológica Administrativa
                      </div>
                      <div className="col-7">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={seleccionRHA}
                          name="rha"
                          options={rhas}
                          value={rhaSelect}
                        />
                      </div>
                    </div>
                    <div className="row filas">
                      <div className="col-3">Parametro</div>
                      <div className="col-7">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={datosGraficasCalidad}
                          name="calidad"
                          options={paramCapas}
                          value={paramCapa}
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12">
                      <h5 className="titulosM">
                        Evolución de la calidad el agua por sitio por RHA
                      </h5>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row filas">
                    <div className="col-12">
                      {(rhaS !== "" || parametroCalid !== "") && (
                        <h5 className="titulosM">{`${rhaS} ${parametroCalid}`}</h5>
                      )}
                    </div>
                  </div>
                  <div className="row filas">
                    <div className="col-12">
                      {datosGraficasCalidadG.length > 0 &&
                        parametroCalid !== "" ? (
                        <Graficas
                          tipo="barras"
                          datos={datosGraficasCalidadG}
                          variable="sitio"
                        />
                      ) : (
                        <>{datosGraficasCalidadG.length === 0 && <></>}</>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                graficaF === "graficaClasiRha" &&
                tipoGraficaCal !== "" && (
                  <>
                    <div className="contenedorSelect">
                      <div className="row filas">
                        <div className="col-3">
                          Región Hidrológica Administrativa
                        </div>
                        <div className="col-7">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            onChange={seleccionRHA}
                            name="rha"
                            options={rhas}
                            value={rhaSelect}
                          />
                        </div>
                      </div>
                      <div className="row filas">
                        <div className="col-3">Parametro</div>
                        <div className="col-7">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            onChange={datosGraficasCalidad}
                            name="calidad"
                            options={paramCapas}
                            value={paramCapa}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <h5 className="titulosM">Calidad el Agua</h5>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row filas">
                      <div className="col-12">
                        {(rhaS !== "" || parametroCalid !== "") && (
                          <h5 className="titulosM">{`${rhaS} ${parametroCalid}`}</h5>
                        )}
                      </div>
                    </div>
                    <div className="row filas">
                      <div className="col-12">
                        {datosGraficasCalidadG.length > 0 &&
                          parametroCalid !== "" ? (
                          <Graficas
                            tipo="barras2"
                            datos={datosGraficasCalidadG}
                            variable="anio"
                          />
                        ) : (
                          <>{datosGraficasCalidadG.length === 0 && <></>}</>
                        )}
                      </div>
                    </div>
                    <div className="row filas">
                      {datosGraficasCP.length > 0 && parametroCalid !== "" && (
                        <Graficas
                          tipo="pie"
                          datos={datosGraficasCP}
                          variable="total"
                        />
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {
        //Modal de Reportes Recaudacion
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showReporteRecaudacion}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowReporteRecaudacion(!showReporteRecaudacion)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reportes Recaudacion</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            <div className="row">
              <div className="col-6">
                <h5
                  className="tituloReporte1"
                  onClick={() => reporteRecaudacionT("reporteR1")}
                >
                  Recaudación de la Conagua por el cobro de derechos,
                  aprovechamientos y otros conceptos
                </h5>
              </div>
              {

                <div className="col-6">
                  <h5
                    className="tituloReporte1"
                    onClick={() => reporteRecaudacionT("reporteR2")}
                  >
                    Recaudación por extracción, uso o aprovechamiento de aguas
                    nacionales
                  </h5>
                </div>


              }

            </div>
            <br></br>
            <div className="row">
              {tipoReporteRe === "reporteR1" ? (
                <div>
                  <div className="row text-center">
                    <div className="col-12">
                      <strong>
                        Recaudación de la Conagua por el cobro de derechos,
                        aprovechamientos y otros conceptos<br></br>
                        (millones de pesos a precios constantes de 2021)
                      </strong>
                    </div>
                  </div>
                  <br></br>
                  {datosReporteRec !== undefined && (
                    <div>
                      <div className="row">
                        <div className="col-12">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Concepto</th>
                                <th>2011</th>
                                <th>2012</th>
                                <th>2013</th>
                                <th>2014</th>
                                <th>2015</th>
                                <th>2016</th>
                                <th>2017</th>
                                <th>2018</th>
                                <th>2019</th>
                                <th>2020</th>
                                <th>2021</th>
                              </tr>
                            </thead>
                            <tbody>
                              {datosReporteRec.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.tipo}</td>
                                    <td>{item.A2011}</td>
                                    <td>{item.A2012}</td>
                                    <td>{item.A2013}</td>
                                    <td>{item.A2014}</td>
                                    <td>{item.A2015}</td>
                                    <td>{item.A2016}</td>
                                    <td>{item.A2017}</td>
                                    <td>{item.A2018}</td>
                                    <td>{item.A2019}</td>
                                    <td>{item.A2020}</td>
                                    <td>{item.A2021}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          FUENTE: CONAGUA. 2020. SINA. Elaborado a partir de:
                          Subdirección General de Administración y Coordinación
                          General de Recaudación y Fiscalización.
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="row text-center">
                    <div className="col-12 tituloReporte">
                      Recaudación por extracción, uso o aprovechamiento de aguas
                      nacionales<br></br>
                      (millones de pesos a precios constantes de 2021)
                    </div>
                  </div>
                  <br></br>
                  {datosReporteRec !== undefined && (
                    <div>
                      <div className="row">
                        <div className="col-12">
                          <table className="table table-hover">
                            <thead>
                              <tr>
                                <th>Uso</th>
                                <th>2011</th>
                                <th>2012</th>
                                <th>2013</th>
                                <th>2014</th>
                                <th>2015</th>
                                <th>2016</th>
                                <th>2017</th>
                                <th>2018</th>
                                <th>2019</th>
                                <th>2020</th>
                                <th>2021</th>
                              </tr>
                            </thead>
                            <tbody>
                              {datosReporteRec.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.tipo}</td>
                                    <td>{item.A2011}</td>
                                    <td>{item.A2012}</td>
                                    <td>{item.A2013}</td>
                                    <td>{item.A2014}</td>
                                    <td>{item.A2015}</td>
                                    <td>{item.A2016}</td>
                                    <td>{item.A2017}</td>
                                    <td>{item.A2018}</td>
                                    <td>{item.A2019}</td>
                                    <td>{item.A2020}</td>
                                    <td>{item.A2021}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 text-center">
                          FUENTE: CONAGUA. 2020. SINA. Elaborado a partir de los
                          datos obtenidos de la Coordinación General de
                          Recaudación y Fiscalización.
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {
        //Modal de Grafcias Recaudacion
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showGraficaRecaudacion}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowGraficaRecaudacion(!showGraficaRecaudacion)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Gráficas Recaudacion</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            <div className="row">
              <div className="col-6">
                <h5
                  className="tituloReporte1"
                  onClick={() => graficaRecaudacionT("graficaR1")}
                >
                  Recaudación de la Conagua por el cobro de derechos,
                  aprovechamientos y otros conceptos
                </h5>
              </div>
              <div className="col-6">
                <h5
                  className="tituloReporte1"
                  onClick={() => graficaRecaudacionT("graficaR2")}
                >
                  Recaudación por extracción, uso o aprovechamiento de aguas
                  nacionales
                </h5>
              </div>
            </div>
            <br></br>
            <div className="row">
              {tipoGraficaRe === "graficaR1" ? (
                <div>
                  <div className="row text-center">Recaudacion
                    <div className="col-12 tituloReporte">
                      Recaudación de la Conagua por el cobro de derechos,
                      aprovechamientos y otros conceptos<br></br>
                      (millones de pesos a precios constantes de 2021)
                    </div>
                  </div>
                  <br></br>
                  {datosGraficaRec !== undefined && (
                    <div className="row text-center">
                      <div className="col-12">
                        {datosGraficaRec !== 0 && (
                          <Graficas
                            tipo="barras2"
                            datos={datosGraficaRec}
                            variable="anio"
                            name="Recaudacion"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="row text-center">
                    <div className="col-12 tituloReporte">
                      Evolución de la recaudación por extracción, uso o aprovechamiento de aguas nacionales<br></br>
                      (millones de pesos a precios constantes de 2021)
                    </div>
                  </div>
                  <br></br>
                  {datosGraficaRec !== undefined && (
                    <div className="row text-center">
                      <div className="col-12">
                        {datosGraficaRec !== 0 && (
                          <Graficas
                            tipo="barras2"
                            datos={datosGraficaRec}
                            variable="anio"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {
        //Modal de Atributos
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalAtributos}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalAtributos(!showModalAtributos)}
        className=" modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>{tituloModalA}</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          {atributos.length !== 0 && (
            <>
              <div className="atributos">
                {
                  <>
                    <div className="TitAtributos">{titulo}</div>
                    <br></br>
                  </>
                }
                <TableData columns={columnas} rows={filas} tam="95%" />
              </div>
            </>
          )}

          {/*
          {atributos.length !== 0 && (
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr className="tw-text-center">
                            <th colSpan={Object.keys(atributos[0].properties).length}>
                              {atributos[0].titulo}
                            </th>
                          </tr>
                          <tr>
                            {Object.keys(atributos[0].properties).map(
                              (valueKey, indexKey) => {
                                return <th key={indexKey}>{valueKey}</th>;
                              }
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {atributos.map((value, index) => (
                            <tr key={index}>
                              {Object.keys(value.properties).map((valueKey, indexKey) => {
                                return (
                                  <td
                                    key={indexKey}
                                    className={
                                      value.seleccionada === true
                                        ? "tw-text-yellow-900"
                                        : ""
                                    }
                                  >
                                    {value.properties[valueKey]}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
          
            */}
        </Modal.Body>
      </Modal>

      {
        //Modal de SIH
      }

      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalReporteSIH}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalReporteSIH(!showModalReporteSIH)}
        className=" modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reporte Bandas</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">

          <div className="row">
            <div className="col-1">Año</div>
            <div className="col-2">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={selectAniosSIH}
                name="año"
                options={aniosSIH}
                value={anioSIH}
              />
            </div>

            <div className="col-2">Mes</div>
            <div className="col-2">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                onChange={selectMesSIH}
                name="mes"
                options={mesesSIH}
                value={mesSIH}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">

              <div className="atributos">
                <br></br>
                {
                  (anioSIH !== undefined && mesSIH !== undefined) && (
                    <>
                      <div className="TitAtributos">{`Fecha consultada: ${mesSIH.label} del ${anioSIH.label}`}</div>
                      <TableData columns={sihbandas} rows={filas} tam="100%" />
                    </>
                  )
                }

              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      {
        //Modal de Prestadores
      }

      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalPrestadores}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalPrestadores(!showModalPrestadores)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Prestadores de Servicios</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            {repPrestadores === "tipo" ? (
              <div className="centrado">
                <div className="row text-center">
                  <div className="col-12 tituloReporte">
                    Lista de prestadores por entidad
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="row">
                  <div className="col-12">
                    <TableData
                      columns={prestadoresEntidad}
                      rows={filas}
                      tam="94%"
                    />
                  </div>
                </div>
              </div>
            ) : repPrestadores === "tam" ? (
              <div className="centrado">
                <div className="row text-center">
                  <div className="col-12 tituloReporte">
                    Prestadores por tipo y tamaño
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="row">
                  <div className="col-12">
                    <TableData
                      columns={prestadoresTamanioT}
                      rows={filas}
                      tam="95%"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="centrado">
                <div className="row text-center">
                  <div className="col-12 tituloReporte">
                    Lista de prestadores por municipio
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="row">
                  <div className="col-12">
                    {/*
                            <DataTable1 columnas={columnas} filas={filas} ></DataTable1>
                           
                          */}
                    <TableData
                      columns={prestadoresMunicipios}
                      rows={filas}
                      tam="100%"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>



      {
        //Modal Reportes Presupuesto
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalRepPresu}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalRepPresu(!showModalRepPresu)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reportes Presupuesto</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            <div className="row">
              <div className="col-3">
                <h5
                  className="tituloReporte1"
                  onClick={() => reportePresupuesto("presupuestario")}
                >
                  Presupuesto ejercido por programa presupuestario
                </h5>
              </div>
              <div className="col-3">
                <h5
                  className="tituloReporte12"
                  onClick={() => reportePresupuesto("capitulos")}
                >
                  Presupuesto ejercido por programas y capítulos
                </h5>
              </div>
              <div className="col-3">
                <h5
                  className="tituloReporte12"
                  onClick={() => reportePresupuesto("region")}
                >
                  Presupuesto ejercido por Región
                </h5>
              </div>
              <div className="col-3">
                <h5
                  className="tituloReporte12"
                  onClick={() => reportePresupuesto("recaudacion")}
                >
                  Presupuesto invertido/ejercido (Nacional)
                </h5>
              </div>
              <div className="col-3">
                <h5
                  className="tituloReporte12"
                  onClick={() => reportePresupuesto("acumulado")}
                >
                  Presupuesto ejercido mensual
                </h5>
              </div>
            </div>
            <div>
              {
                tipoRepPresu === "presupuestario" ? (
                  <div>
                    <br></br>
                    <div className="row">
                      <div className="col-12 text-center tituloReporte">
                        Por Programa Presupuestario <br></br>(millones de pesos a precios constantes de 2022)
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-12">
                        <TableData
                          columns={reportePresupuestoC}
                          rows={filas}
                          tam={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                ) : tipoRepPresu === "capitulos" ? (
                  <div>
                    <br></br>
                    <div className="row">
                      <div className="col-4 text-center">
                        Año de Consulta:
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={seleccionCiclo}
                          name="ciclo"
                          options={ciclo}
                          value={cicloA}
                        />
                      </div>
                      <div className="col-8 text-center tituloReporte">
                        Programas y capítulos <br></br> {` ${cicloA.value}`}<br></br> (millones de pesos a precios constantes de 2022)
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-12">
                        <TableData
                          columns={reporteCapitulosT}
                          rows={filas}
                          tam={"100%"}
                        />
                      </div>
                    </div>
                  </div>
                ) : tipoRepPresu === "region" ? (
                  <div>
                    <br></br>
                    <div className="row">
                      <div className="col-4 text-center">
                        Año de Consulta:
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={seleccionCiclo}
                          name="ciclo"
                          options={ciclo}
                          value={cicloA}
                        />
                      </div>
                      <div className="col-8 text-center tituloReporte">
                        Presupuesto por Región <br></br> {`   ${cicloA.value}`} <br></br>(millones de pesos a precios constantes de 2022)
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-12">
                        <TableData
                          columns={reporteRerionT}
                          rows={filas}
                          tam={"100%"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        Se consideran solamente los capitulos 4000,5000,6000,7000
                      </div>
                    </div>
                  </div>
                ) : tipoRepPresu === "acumulado" ? (
                  <>
                    <div className="row">
                      <div className="col-1">Año </div>
                      <div className="col-2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={selectAnioAcumulado}
                          name="ciclo"
                          options={aniosPresupuestoMesual}
                          value={anioAcumuladoR}
                        />
                      </div>

                      <div className="col-2">Mes</div>
                      <div className="col-2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={selectMesAcumulado}
                          name="ciclo"
                          options={MesPresupuesto}
                          value={mesAcumuladoR}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">

                        <div className="atributos">
                          <br></br>
                          {
                            (anioAcumuladoR.label !== undefined && mesAcumuladoR.label !== undefined) && (
                              <>
                                <div className="TitAtributos">{`Presupuesto ${anioAcumuladoR.label} acumulado hasta ${mesAcumuladoR.label}`}</div>
                                <TableData columns={PresupuestoAcumulado} rows={filas} tam="100%" />
                              </>
                            )
                          }

                        </div>
                      </div>
                    </div>

                  </>
                ) : (
                  tipoRepPresu === "recaudacion" && (
                    <div>
                      <br></br>
                      <div className="row">
                        <div className="col-12 text-center tituloReporte">
                          Recaudación y Presupuesto de la CONAGUA <br></br>
                          (miles de millones de pesos a precios constantes de 2022)

                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <div className="col-12 text-center tituloReporte">

                        </div>
                        <br></br>
                        <div className="row">
                          <div className="col-12">
                            <TableData
                              columns={reporteRecPreT}
                              rows={filas}
                              tam={"60%"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalRepPresuAcu}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalRepPresuAcu(!showModalRepPresuAcu)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reporte Presupuesto Acumulado</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">

          <div className="centrado">
            <div className="row text-center">
              <div className="col-12 tituloReporte">
                Presupuesto ejercido acumulado
                <br></br>Febrero 2023
              </div>
            </div>
            <br></br>
            <div className="reportepdf" >
              <div className="col-12 tituloReporte" >
                {/*<OverlayTrigger
                  key="1"
                  overlay={
                    <Tooltip>Tabla de atributos</Tooltip>
                  }
                >
                  <Button        
                  //onClick={()=>descargaD("Documentos\Presupuesto_Ejercido_2022.pdf")}            
                    variant="link"
                    className="btn2"
                    title="Tabla de atributos"
                  >
                    <a href="Documentos\Presupuesto_Ejercido_2022.pdf" target="_blank"></a>
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      size="2x"
                    />
                  </Button>
                </OverlayTrigger>*/}
                <a href="Documentos\Presupuesto_ejercido_Febrero_2023.pdf" target="_blank">Descarga Reporte PDF</a>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div className="col-12">
                <TableData
                  columns={reportePresAcu}
                  rows={filas}
                  tam="100%"
                />
              </div>
            </div>
          </div>

          {
            /*
            }
                      <div>
            
                        <div>
                          {tipoRepPresu === "presupuestario" && (
                            <div>
                              <br></br>
                              <div className="row">
                                <div className="col-12 text-center tituloReporte">
                                  Presupuesto Acumulado 2022
                                </div>
                              </div>
                              <br></br>
                              <div className="row">
                                <div className="col-12">
                                  <TableData
                                    columns={reportePresAcu}
                                    rows={filas}
                                    tam={"100%"}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                          }
                        </div>
                      </div>
            
                      {
            */
          }

        </Modal.Body>
      </Modal>

      {
        //Modal Reportes Cuencas
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalRepCuenca}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() => setShowModalRepCuenca(!showModalRepCuenca)}
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reportes Cuencas por disponibilidad</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            <div className="row">
              <div className="col-6">
                <h5
                  className="tituloReporte1"
                  onClick={() => reportesCuencas("RHA")}
                >
                  Disponibilidad de cuencas por RHA
                </h5>
              </div>
              <div className="col-6">
                <h5
                  className="tituloReporte12"
                  onClick={() => reportesCuencas("RH")}
                >
                  Disponibilidad de cuencas por RH
                </h5>
              </div>
            </div>
            <div>
              {tipoRepCuenca === "RHA" ? (
                <div>
                  <br></br>
                  <div className="row">
                    <div className="col-5">Año de publicación en DOF</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectAnioCuencasr}
                        name="ciclo"
                        options={anioscuencas}
                        value={anioSCuencas}
                      />
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-12 text-center tituloReporte">
                      <h6 className="tituloReporte">
                        Situación de disponibilidad de cuencas por RHA
                      </h6>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-12">
                      <TableData
                        columns={cuencaRHA}
                        rows={filas}
                        tam={"100%"}
                      />
                    </div>
                  </div>
                </div>
              ) : tipoRepCuenca === "RH" && (
                <div>
                  <br></br>
                  <div className="row">
                    <div className="col-5">Año de publicación en DOF</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectAnioCuencasr}
                        name="ciclo"
                        options={anioscuencas}
                        value={anioSCuencas}
                      />
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-12 text-center tituloReporte">
                      <h6 className="tituloReporte">
                        Situación de disponibilidad de cuencas por RH
                      </h6>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-12">
                      <TableData
                        columns={cuencaRH}
                        rows={filas}
                        tam={"100%"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {
        //Modal graficas Cuencas
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalGraficasCuencas}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setShowModalGraficasCuencas(!showModalGraficasCuencas)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Graficas Cuencas</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            <div className="row">
              <div className="col-6">
                <h5
                  className="tituloReporte12"
                  onClick={() => graficaCuencas("grafica1")}
                >
                  Disponibilidad por región hidrológico-administrativa
                </h5>
              </div>
              <div className="col-6">
                <h5
                  className="tituloReporte12"
                  onClick={() => graficaCuencas("grafica2")}
                >
                  Disponibilidad por región hidrológica
                </h5>
              </div>
              <div className="col-6">
                <h5
                  className="tituloReporte12"
                  onClick={() => graficaCuencas("grafica3")}
                >
                  Ordenamiento de aguas superficial
                </h5>
              </div>
            </div>
            <br></br>
            {
              //
              grafCuencas === "grafica1" ? (
                <div>
                  <div className="row">
                    <div className="col-5">Año de publicación en DOF</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectAnioCuencas1}
                        name="ciclo"
                        options={anioscuencas}
                        value={anioSCuencas}
                      />
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-12 text-center tituloReporte">
                      <h6 className="tituloReporte">
                        Situación de disponibilidad de cuencas por RHA
                      </h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {datosCuencas.length !== 0 && (
                        <Graficas
                          tipo="barras2"
                          datos={datosCuencas}
                          variable="claverha"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                grafCuencas === "grafica2" ? (
                  <div>
                    <div className="row">
                      <div className="col-5">Año de publicación en DOF</div>
                      <div className="col-2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={selectAnioCuencas1}
                          name="ciclo"
                          options={anioscuencas}
                          value={anioSCuencas}
                        />
                      </div>
                    </div>
                    <br></br>
                    <div className="row">
                      <div className="col-12 text-center tituloReporte">
                        Situación de disponibilidad de cuencas por RH

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        {datosCuencas.length !== 0 && (
                          <Graficas
                            tipo="barras2"
                            datos={datosCuencas}
                            variable="claverh"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  grafCuencas === "grafica3" && (
                    <div>
                      <div className="row">
                        <div className="col-5">Año </div>
                        <div className="col-2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            onChange={selectAnioCuencas1}
                            name="ciclo"
                            options={anioscuencasOV}
                            value={anioSCuencas}
                          />
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <div className="col-12 text-center tituloReporte">
                          Ordenamiento de agua superficial

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          {datosCuencas.length !== 0 && (
                            <Graficas
                              tipo="barras2"
                              datos={datosCuencas}
                              variable="tipoordenamiento"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )
              )
            }
          </div>
        </Modal.Body>
      </Modal>

      {
        //Modal graficas Presupuesto
      }
      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalGraficasPresupuesto}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setShowModalGraficasPresupuesto(!showModalGraficasPresupuesto)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Gráficas Presupuesto</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div>
            <div className="row">
              <div className="col-6">
                <h5
                  className="tituloReporte12"
                  onClick={() => graficaPresupuesto("grafica1")}
                >
                  Presupuesto ejercido
                </h5>
              </div>
              <div className="col-6">
                <h5
                  className="tituloReporte12"
                  onClick={() => graficaPresupuesto("grafica2")}
                >
                  Presupuesto invertido/ejercido (Nacional)
                </h5>
              </div>
              <div className="col-6">
                <h5
                  className="tituloReporte12"
                  onClick={() => graficaPresupuesto("grafica3")}
                >
                  Presupuesto ejercido mensual
                </h5>
              </div>
            </div>
            <br></br>
            {
              //
              grafPresupuesto === "grafica1" ? (
                <div>
                  <div className="row">
                    <div className="col-2">Año</div>
                    <div className="col-3">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={filtrarAniosPresupuesto}
                        name="ciclo"
                        options={aniosgrafPresup}
                        value={anioGraficaPres}
                      />
                    </div>
                  </div>
                  <br></br>
                  {
                    anioGraficaPres !== undefined && anioGraficaPres !== null && (
                      <>

                        <div className="row">
                          <div className="col-12 text-center tituloReporte">
                            <h6 className="tituloReporte">
                              Evolución del Presupuesto Ejercido de la CONAGUA <br></br>
                              (miles de millones de pesos a precios constantes de 2021)

                            </h6>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            {datosPresupuesto.length !== 0 && (
                              <Graficas
                                tipo="barras2"
                                datos={datosPresupuesto}
                                variable="programa"
                              />
                            )}
                          </div>
                        </div>


                      </>
                    )
                  }

                </div>
              ) : grafPresupuesto === "grafica3" ? (
                <div>
                  <div className="row">
                    <div className="col-1">Año</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectAnioAcumulado}
                        name="ciclo"
                        options={aniosPresupuestoMesual}
                        value={anioAcumuladoR}
                      />
                    </div>

                    <div className="col-2">Grupo ejercido</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectGrupo}
                        name="ciclo"
                        options={grupoPres}
                        value={grupoE}
                      />
                    </div>
                  </div>
                  <br></br>
                  {
                    anioAcumuladoR.label !== undefined && grupoE.label !== undefined && (
                      <>


                        <div className="row">
                          <div className="col-12 text-center tituloReporte">
                            <h6 className="tituloReporte">{`Presupuesto ${anioAcumuladoR.label} ejercido por ${grupoE.label}`}</h6>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            {datosPresupuesto.length !== 0 && (
                              <Graficas
                                tipo="barras2"
                                datos={datosPresupuesto}
                                variable="mes"
                              />
                            )}
                          </div>
                        </div>


                      </>
                    )
                  }





                </div>
              ) : (
                grafPresupuesto === "grafica2" && (
                  <div>
                    <div className="row">
                      <div className="col-12 text-center tituloReporte">
                        Recaudación y Presupuesto de la CONAGUA <br></br>
                        (miles de millones de pesos a precios constantes de 2021)

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        {datosPresupuesto !== 0 && (
                          <Graficas
                            tipo="barras2"
                            datos={datosPresupuesto}
                            variable="anio"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )
              )
            }
          </div>
        </Modal.Body>
      </Modal>

      {
        //modales distritos de riego
      }

      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalReportesDR}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setShowModalReportesDR(!showModalReportesDR)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reportes distritos de Riego</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div className="row">
            <div className="col-4">
              <h5
                className="tituloReporte1" onClick={() => tipoReporte_DR(1)}
              >
                Producción Agrícola de los Distritos de Riego por RHA
              </h5>
            </div>
            <div className="col-4">
              <h5
                className="tituloReporte1" onClick={() => tipoReporte_DR(2)}
              >
                Cultivos por Distrito de Riego
              </h5>
            </div>
            <div className="col-4">
              <h5
                className="tituloReporte1" onClick={() => tipoReporte_DR(3)}
              >
                Superficie Regada por Tipo de Aprovechamiento
              </h5>
            </div>
          </div>

          {
            tipoReporteDR === 1 ? (
              <>
                <div className="row">
                  <div className="col-1">Año Agrícola</div>
                  <div className="col-2">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={selectAniosDistritos2}
                      name="ciclo"
                      options={aniosDistritos}
                      value={anioDistrito}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">

                    <div className="atributos">
                      <br></br>
                      {
                        anioDistrito !== undefined && (
                          <>
                            <div className="TitAtributos">{`Producción Agrícola de los Distritos de Riego por RHA (${anioDistrito.label})`}</div>
                            <TableData columns={concentradoRHA} rows={filas} tam="100%" />
                          </>
                        )
                      }

                    </div>
                  </div>
                </div>
              </>
            ) : (
              tipoReporteDR === 2 ? (
                <>
                  <div className="row">
                    <div className="col-1">Año Agrícola</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectAniosDistritos2}
                        name="ciclo"
                        options={aniosDistritos}
                        value={anioDistrito}
                      />
                    </div>

                    <div className="col-2">Distrito de Riego</div>
                    <div className="col-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={SelectDistritoR}
                        name="ciclo"
                        options={dr}
                        value={distritoSelect}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">

                      <div className="atributos">
                        <br></br>
                        {
                          (anioDistrito !== undefined && distritoSelect !== undefined) && (
                            <>
                              <div className="TitAtributos">{`Cultivos por Distrito de Riego (${distritoSelect.label}) (${anioDistrito.label})`}</div>
                              <TableData columns={concentradoDR} rows={filas} tam="100%" />
                            </>
                          )
                        }

                      </div>
                    </div>
                  </div>

                </>
              ) : (
                tipoReporteDR === 3 && (
                  <>
                    <div className="row">
                      <div className="col-1">Año Agrícola</div>
                      <div className="col-2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={selectAniosDistritos2}
                          name="ciclo"
                          options={aniosDistritos}
                          value={anioDistrito}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">

                        <div className="atributos">
                          <br></br>
                          { //
                            (anioDistrito !== undefined) && (
                              <>
                                <div className="TitAtributos">{`Superficie Regada en ha por Tipo de Aprovechamiento (${anioDistrito.label})`}</div>
                                <TableData columns={supSembrada} rows={filas} tam="100%" />
                              </>
                            )
                          }

                        </div>
                      </div>
                    </div>
                  </>
                )
              )
            )
          }
        </Modal.Body>
      </Modal>

      <Modal
        dialogAs={DraggableModalDialog}
        show={showModalGraficasDR}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setShowModalGRaficasDR(!showModalGraficasDR)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Gráficas distritos de Riego</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">

          <div className="row">
            {

              <div className="col-6">
                <h5
                  className="tituloReporte1" onClick={() => tipoGrafica_DR(1)}
                >
                  Superficie Cosechada por Entidad
                </h5>
              </div>

            }

            <div className="col-6">
              <h5
                className="tituloReporte1" onClick={() => tipoGrafica_DR(2)}
              >
                Superficie Histórica Cosechada
              </h5>
            </div>
          </div>

          {
            tipoGraficaDR === 1 ? (
              <>
                <div className="row">
                  <div className="col-1">Año Agrícola</div>
                  <div className="col-2">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={selectAniosDistritos2}
                      name="ciclo"
                      options={aniosDistritos}
                      value={anioDistrito}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">

                    <div className="atributos">
                      <br></br>
                      {
                        anioDistrito !== undefined && (
                          <>
                            <div className="TitAtributos">{`Modalidad de la Superficie Cosechada en la Entidad (${anioDistrito.label})`}</div>
                            {
                              datosDR !== undefined && (
                                <>
                                  <Graficas
                                    tipo="barras3"
                                    datos={datosDR}
                                    variable="estado"
                                  />
                                </>
                              )
                            }
                          </>
                        )
                      }

                    </div>
                  </div>
                </div>
              </>
            ) : (
              tipoGraficaDR === 2 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="atributos">
                        <br></br>
                        {
                          datosDR !== undefined && (
                            <>
                              <div className="TitAtributos">{`Superficie Histórica Cosechada por Modalidad`}</div>

                              <Graficas
                                tipo="barras2"
                                datos={datosDR}
                                variable="anio"
                              />
                            </>
                          )

                        }




                      </div>
                    </div>
                  </div>

                </>
              )
            )
          }



        </Modal.Body>
      </Modal >



      {
        //modal Sistema Cutzamala
      }

      <Modal
        dialogAs={DraggableModalDialog}
        show={modalCutzamalaReportes}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setModalCutzamalaReportes(!modalCutzamalaReportes)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reportes Sistema Cutzamala</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">

          <div className="row">
            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <h5
                className="tituloReporte1" onClick={() => tipoReporteCutzamala("reporte1")}
              >
                Características de componentes
              </h5>
            </div>
            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <h5
                className="tituloReporte1" onClick={() => tipoReporteCutzamala("reporte2")}
              >
                Volúmenes y caudales suministrados
              </h5>
            </div>
            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <h5
                className="tituloReporte1" onClick={() => tipoReporteCutzamala("reporte3")}
              >
                Volúmenes suministrados y consumo de energía
              </h5>
            </div>
            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
              <h5
                className="tituloReporte1" onClick={() => tipoReporteCutzamala("reporte4")}
              >
                Evolución del almacenamiento en las presas del Sistema Cutzamala
              </h5>
            </div>
          </div>
          {
            tipReporteCutzamala === "reporte1" ? (
              <>
                {
                  /*
                  <div className="row">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Año</div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={seleccionaAnioCutzamala}
                        name="ciclo"
                        options={aniosCutzamala}
                        value={anioCutzamala}
                      />
                    </div>
                  </div>
                  */

                }

                {
                  (filas.length !== 0) && (
                    <>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <div className="TitAtributos">{`Características de Componentes del Sistema Cutzamala 2022`}</div>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        {
                          filas.length !== 0 && (
                            <TableData columns={compoenentesSC} rows={filas} tam="90%" />
                          )
                        }

                      </div>
                    </>
                  )
                }
              </>
            ) : (
              tipReporteCutzamala === "reporte2" ? (
                <>
                  {
                    filas.length !== 0 && (
                      <>
                        <div className="row justify-content-center align-items-center">
                          <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                            <div className="TitAtributos">{`Volúmenes y Caudales Suministrados por el Sistema Cutzamala, serie anual 1991-${filas[filas.length - 1]['anio']}`}</div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <TableData columns={caudales} rows={filas} tam="95%" />
                        </div>
                      </>
                    )
                  }

                </>
              ) : (
                tipReporteCutzamala === "reporte3" ? (
                  <>
                    {
                      filas.length !== 0 && (
                        <>
                          <div className="row justify-content-center align-items-center">
                            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                              <div className="TitAtributos">{`Sistema Cutzamala-Volúmenes suministrados  y consumo de energía, 2005-${filas[filas.length - 1]['anio']}`}</div>
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <TableData columns={volumenes} rows={filas} tam="95%" />
                          </div>
                        </>
                      )
                    }

                  </>
                ) : (
                  tipReporteCutzamala === "reporte4" && (
                    <>
                      <div className="row">
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Mes</div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            onChange={seleccionaMesCutzamala}
                            name="mes"
                            options={mesesSIH}
                            value={mesCutzamala}
                          />
                        </div>
                        {
                          mesCutzamala.length !== 0 && (
                            <>
                              <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Día</div>
                              <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  isClearable={true}
                                  onChange={seleccionadiaCutzamala}
                                  name="dia"
                                  options={dias}
                                  value={diaCutzamala}
                                />
                              </div>

                            </>
                          )
                        }
                      </div>
                      <br></br>
                      {
                        filas.length !== 0 && (

                          <>
                            <div className="row justify-content-center align-items-center">
                              <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                <div className="TitAtributos">{`Evolución del almacenamiento en las presas del Sistema Cutzamala (1991-${filas[filas.length - 1]['anio']}) Mes: ${mesCutzamala2.label}  Día: ${diaCutzamala2.label}`}</div>
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              <TableData columns={propuestaOcavam} rows={filas} tam="90%" />
                            </div>
                          </>

                        )
                      }



                    </>
                  )
                )
              )
            )
          }
        </Modal.Body>
      </Modal >

      <Modal
        dialogAs={DraggableModalDialog}
        show={modalCutzamalaGraficas}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setModalCutzamalaGraficas(!modalCutzamalaGraficas)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Gráficas Sistema Cutzamala</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">

          <div className="row">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <h5
                className="tituloReporte1" onClick={() => tipoGraficaCutzamala("grafica1")}
              >
                Volúmenes y caudales suministrados
              </h5>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <h5
                className="tituloReporte1" onClick={() => tipoGraficaCutzamala("grafica2")}
              >
                Volúmenes suministrados y consumo de energía
              </h5>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <h5
                className="tituloReporte1" onClick={() => tipoGraficaCutzamala("grafica3")}
              >
                Evolución del almacenamiento en las presas del Sistema Cutzamala
              </h5>
            </div>
          </div>
          {
            tipGraficaCutzamala === "grafica1" ? (
              <>
                {
                  filas.length !== 0 && (
                    <>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                          <div className="TitAtributos">{`Volumen suministrado por el Sistema Cutzamala, serie anual (2000-${filas[filas.length - 1]['anio']})`}</div>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <Graficas
                          tipo="mixta"
                          datos={filas}
                          variable="anio"
                          consumo=""
                        />
                      </div>
                    </>
                  )
                }

              </>
            ) : (
              tipGraficaCutzamala === "grafica2" ? (
                <>
                  {
                    filas.length !== 0 && (
                      <>
                        <div className="row justify-content-center align-items-center">
                          <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                            <div className="TitAtributos">{`Sistema Cutzamala-Volúmenes suministrados y consumo de energía (2005-${filas[filas.length - 1]['anio']})`}</div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <Graficas
                            tipo="mixta"
                            datos={filas}
                            variable="anio"
                            consumo="consumo"
                          />
                        </div>
                      </>
                    )
                  }
                </>
              ) : (
                tipGraficaCutzamala === "grafica3" && (
                  <>
                    <div className="row">
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Mes</div>
                      <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={seleccionaMesCutzamala}
                          name="mes"
                          options={mesesSIH}
                          value={mesCutzamala}
                        />
                      </div>
                      {
                        mesCutzamala.length !== 0 && (
                          <>
                            <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Día</div>
                            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                onChange={seleccionadiaCutzamala}
                                name="mes"
                                options={dias}
                                value={diaCutzamala}
                              />
                            </div>

                          </>
                        )
                      }
                    </div>
                    <br></br>
                    {
                      filas.length !== 0 && (
                        <>

                          <div className="row justify-content-center align-items-center">
                            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                              <div className="TitAtributos">{`Evolución del almacenamiento en las presas del Sistema Cutzamala (1991-${filas[filas.length - 1]['anio']}) Mes: ${mesCutzamala2.label}  Día: ${diaCutzamala2.label}`}</div>
                            </div>
                          </div>
                          <div className="row justify-content-center align-items-center">
                            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                              <div className="">{`Volumen en millones de metros cúbicos`}</div>
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <Graficas
                              tipo="barras"
                              datos={filas}
                              variable="anio"
                              opcion="evolucion"
                            />
                          </div>
                        </>
                      )
                    }
                  </>
                )
              )
            )
          }





        </Modal.Body>
      </Modal >

      {
        //modal Precipitación
      }

      <Modal
        dialogAs={DraggableModalDialog}
        show={modalPrecipitacionReportes}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setModalPrecipitacionReportes(!modalPrecipitacionReportes)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reportes Precipitacion</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div className="row">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5 className="tituloReporte1" onClick={() => tipoReportePrecipitacion("reporte1")}>
                Precipitación pluvial normal mensual en México
              </h5>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5 className="tituloReporte1" onClick={() => tipoReportePrecipitacion("reporte2")} >
                Precipitación pluvial normal
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5 className="tituloReporte1" onClick={() => tipoReportePrecipitacion("reporte3")}>
                Precipitación pluvial normal mensual por RHA
              </h5>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5 className="tituloReporte1" onClick={() => tipoReportePrecipitacion("reporte4")}>
                Precipitación pluvial anual
              </h5>
            </div>
          </div>
          {
            tipReportePrecipitacion === "reporte1" ? (
              <>
                {
                  filas.length !== 0 && (
                    <>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                          <div className="TitAtributos">{`Precipitación pluvial normal mensual en México`}</div>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <TableData columns={precipitacionNM} rows={filas} tam="95%" />
                      </div>
                    </>
                  )
                }
              </>
            ) : (
              tipReportePrecipitacion === "reporte2" ? (
                <>
                  <div className="row">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Periodo</div>
                    <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        onChange={selectAnioPrecipitacionN}
                        name="ciclo"
                        options={aniosprecnormal}
                        value={aniosPrecipitacion}
                      />
                    </div>
                  </div>

                  {
                    aniosPrecipitacion !== undefined && (
                      <>
                        <div className="row justify-content-center align-items-center">
                          <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div className="TitAtributos">{`Precipitación pluvial normal ${aniosPrecipitacion.value} (mm)`}</div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          {
                            filas.length !== 0 && (
                              <TableData columns={precipitacionN} rows={filas} tam="90%" />
                            )
                          }

                        </div>
                      </>
                    )
                  }
                </>
              ) : (
                tipReportePrecipitacion === "reporte3" ? (
                  <>
                    <div className="row">
                      <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Periodo</div>
                      <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={selectAnioPrecipitacionRHA}
                          name="ciclo"
                          options={aniosprecnormal}
                          value={aniosPrecipitacion}
                        />
                      </div>
                    </div>
                    {
                      aniosPrecipitacion !== undefined && (
                        <>
                          <div className="row justify-content-center align-items-center">
                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                              <div className="TitAtributos">{`Precipitación pluvial normal mensual ${aniosPrecipitacion.value} (mm)`}</div>
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            {
                              filas.length !== 0 && (
                                <TableData columns={precipitacionRHA} rows={filas} tam="90%" />
                              )
                            }

                          </div>
                        </>
                      )
                    }

                  </>
                ) : (
                  tipReportePrecipitacion === "reporte4" && (
                    <>
                      <div className="row">
                        <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Periodo</div>
                        <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable={true}
                            onChange={selectAnioPrecipitacionAnual}
                            name="ciclo"
                            options={aniosprecanual}
                            value={aniosPrecipitacion}
                          />
                        </div>
                      </div>
                      {
                        aniosPrecipitacion !== undefined && (
                          <>
                            <div className="row justify-content-center align-items-center">
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                <div className="TitAtributos">{`Precipitación pluvial anual ${aniosPrecipitacion.value} (mm)`}</div>
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              {
                                filas.length !== 0 && (
                                  <TableData columns={precipitacionRHAAnual} rows={filas} tam="90%" />
                                )
                              }

                            </div>
                          </>
                        )
                      }
                    </>
                  )
                )
              )
            )
          }


        </Modal.Body>
      </Modal >

      <Modal
        dialogAs={DraggableModalDialog}
        show={modalPrecipitacionGraficas}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setModalPrecipitacionGraficas(!modalPrecipitacionGraficas)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Graficas Precipitación</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">

          <div className="row">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5
                className="tituloReporte1" onClick={() => tipoGraficaPrecipitacion("grafica1")}
              >
                Precipitación pluvial normal mensual en México
              </h5>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5
                className="tituloReporte1" onClick={() => tipoGraficaPrecipitacion("grafica2")}
              >
                Precipitación pluvial anual nacional (2004-2021)
              </h5>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5
                className="tituloReporte1" onClick={() => tipoGraficaPrecipitacion("grafica3")}
              >
                Precipitación pluvial anual nacional (1991-2020) y anual nacional (2021)<br></br> Desplegado Mensual
              </h5>
            </div>
          </div>
          {
            tipGraficaPrecipitacion === "grafica1" ? (
              <>
                {
                  filas.length !== 0 && (
                    <>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                          <div className="TitAtributos">{`Precipitación pluvial normal mensual en México`}</div>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <Graficas
                          tipo="barras2"
                          datos={filas}
                          variable="mes"
                        />
                      </div>
                    </>
                  )
                }

              </>
            ) : (
              tipGraficaPrecipitacion === "grafica2" ? (
                <>
                  {
                    filas.length !== 0 && (
                      <>
                        <div className="row justify-content-center align-items-center">
                          <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div className="TitAtributos">{`Precipitación pluvial anual nacional (2004-2021)`}</div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <Graficas
                            tipo="barras"
                            datos={filas}
                            variable="anio"
                          />
                        </div>
                      </>
                    )
                  }
                </>
              ) : (
                tipGraficaPrecipitacion === "grafica3" && (
                  <>
                    {
                      filas.length !== 0 && (
                        <>
                          <div className="row justify-content-center align-items-center">
                            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                              <div className="TitAtributos">{`Precipitación pluvial normal (1991-2020) y anual nacional (2021)`}</div>
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <Graficas
                              tipo="barras2"
                              datos={filas}
                              variable="mes"
                            />
                          </div>
                        </>
                      )
                    }
                  </>
                )
              )
            )
          }
        </Modal.Body>
      </Modal >

      {
        //modal Ciclones
      }

      <Modal
        dialogAs={DraggableModalDialog}
        show={modalCiclonesReportes}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setModalCiclonesReportes(!modalCiclonesReportes)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Reportes Ciclones</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">
          <div className="row">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5 className="tituloReporte1" onClick={() => tipoReporteCiclones("reporte1")}>
                Ciclones que han impactado a México
              </h5>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5 className="tituloReporte1" onClick={() => tipoReporteCiclones("reporte2")} >
                Número de ciclones por costa
              </h5>
            </div>
          </div>
          {
            tipReporteCiclones === "reporte1" ? (
              <>
                <div className="row">
                  <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">Año</div>
                  <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      onChange={selectAnioCiclonesR}
                      name="ciclo"
                      options={aniosciclones}
                      value={aniosCiclones}
                    />
                  </div>
                </div>
                {
                  aniosCiclones !== undefined && (
                    <>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                          <div className="TitAtributos">{`Ciclones que han impactado a México ${aniosCiclones.value} `}</div>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        {
                          filas.length !== 0 && (
                            <TableData columns={ciclonesMexico} rows={filas} tam="90%" />
                          )
                        }

                      </div>
                    </>
                  )
                }
              </>
            ) : (
              tipReporteCiclones === "reporte2" && (
                <>
                  {
                    <>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                          <div className="TitAtributos">{`Número de ciclones por costa (2015 - 2021)`}</div>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        {
                          filas.length !== 0 && (
                            <TableData columns={ciclonesCosta} rows={filas} tam="90%" />
                          )
                        }

                      </div>
                    </>
                  }
                </>
              )
            )
          }


        </Modal.Body>
      </Modal >

      <Modal
        dialogAs={DraggableModalDialog}
        show={modalCiclonesGraficas}
        backdrop={false}
        keyboard={false}
        contentClassName="modal-redimensionable"
        onHide={() =>
          setModalCiclonesGraficas(!modalCiclonesGraficas)
        }
        className="tw-pointer-events-none modal-analisis"
        id="vistaModal"
      >
        <Modal.Header className="tw-cursor-pointer modal-movible" closeButton>
          <Modal.Title>
            <b>Graficas Ciclones</b>
          </Modal.Title>
          <button
            className="boton-minimizar-modal"
            onClick={(e) => minimizaModal(e)}
          >
            <FontAwesomeIcon icon={faWindowRestore} />
          </button>
        </Modal.Header>
        <Modal.Body className="modal-movible">

          <div className="row">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5
                className="tituloReporte1" onClick={() => tipoGraficaCiclones("grafica1")}
              >
                Número de ciclones por año y categoría en México
              </h5>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-6">
              <h5
                className="tituloReporte1" onClick={() => tipoGraficaCiclones("grafica2")}
              >
                Número de impactos de ciclones por año en México
              </h5>
            </div>
          </div>
          {
            tipGraficaCiclones === "grafica1" ? (
              <>
                {
                  filas.length !== 0 && (
                    <>
                      <div className="row justify-content-center align-items-center">
                        <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                          <div className="TitAtributos">{`Número de ciclones por año y categoría en México`}</div>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <Graficas
                          tipo="barras"
                          datos={filas}
                          variable="anio"
                          opcion="ciclones"
                        />
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="reportepdf">{`*Huracán moderado (H1 y H2)`}</div>
                        <div className="reportepdf">{`*Huracán intenso (H3 a H5)`}</div>
                      </div>
                    </>
                  )
                }

              </>
            ) : (
              tipGraficaCiclones === "grafica2" && (
                <>
                  {
                    filas.length !== 0 && (
                      <>
                        <div className="row justify-content-center align-items-center">
                          <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                            <div className="TitAtributos">{`Número de impactos de ciclones por año en México`}</div>
                          </div>
                        </div>
                        <br></br>
                        <div className="row">
                          <Graficas
                            tipo="barras"
                            datos={filas}
                            variable="anio"
                            opcion="ciclones"
                          />
                        </div>
                      </>
                    )
                  }
                </>
              )
            )
          }
        </Modal.Body>
      </Modal >


      {
        /////////////////////////////////////////////////////
      }
      <div>
        <div
          id="sidebar"
          className={"sidebar " + (opcion === null && "collapsed")}
        >
          {
            //<!-- Nav tabs PRINCIPAL-->
          }
          <div className="sidebar-tabs">
            <ul role="tablist">
              <li
                className={
                  "" +
                  (opcion === "base" ||
                    opcion === "poblacion" ||
                    opcion === "presupuesto" ||
                    opcion === "recaudacion"
                    ? "active"
                    : opcion === null && "")
                }
              >
                <a href="#contexto" role="tab">
                  <PublicOutlinedIcon className="icon" />
                </a>
              </li>




              {
                /*
                <li
                className={
                  "" +
                  (opcion === "calidad" ||
                    opcion === "cuencas" ||
                    opcion === "acuiferos"
                    ? "active"
                    : opcion === null && "")
                }
              >
                <a href="#recursosh" role="tab">
                  <BloodtypeOutlinedIcon className="icon" />
                </a>
              </li>
                <li
                className={
                  "" + (opcion === "repda" ? "active" : opcion === null && "")
                }
              >
                <a href="#usos" role="tab">
                  <HouseboatOutlinedIcon className="icon" />
                </a>
              </li>

                <li
                className={
                  "" +
                  (opcion === "distritosr" || opcion === "monitoreo"
                    ? "active"
                    : opcion === null && "")
                }
              >
                <a href="#infrah" role="tab">
                  <AccountTreeOutlinedIcon className="icon" />
                </a>
              </li>

                <li
                className={
                  "" +
                  (opcion === "prestadores" ? "active" : opcion === null && "")
                }
              >
                <a href="#instrumentos" role="tab">
                  <ManageHistoryOutlinedIcon className="icon" />
                </a>
              </li>
              <ul role="tablist">
              <li>
                <a href="#capas">
                  <PreviewOutlinedIcon className="icon" />
                </a>
              </li>
            </ul>
                */

              }

            </ul>


          </div>
          {
            //<!-- Tab panes -->
          }
          <div className="sidebar-content">
            <div
              className={
                "sidebar-pane " +
                (opcion === "base" ||
                  opcion === "poblacion"
                  ? "active"
                  : opcion === null && "")
              }
              id="contexto"
            >
              <h1 className="sidebar-header">
                CAPAS DISPONIBLES
                <span className="sidebar-close">
                  <i className="fa fa-caret-left"></i>
                </span>
              </h1>
              <br />

              <Button
                className="btnS3 btn-secondary"
                onClick={() => {
                  setColapsarCB(!colapsarCB);
                }}
                style={{ marginBottom: "1rem" }}
              >
                Unidades Economicas
              </Button>
              <Collapse isOpen={colapsarCB}>
                <Card>
                  <CardBody className="cardM">

                    <div className="row">
                      <div className="col-3">Entidad</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={selectEntidad}
                          name="entidad"
                          options={estadosD}
                        //value={anioModernizacion}
                        />
                      </div>
                    </div>
                    <br></br>
                    <ul id="menu">
                      <li>
                        <input id="Otros servicios excepto actividades gubernamentales" type="checkbox" name="OtrosServiciosExceptoActividadesGubernamentales" /><label>Otros servicios excepto actividades gubernamentales</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Administracion de cementerios pertenecientes al sector privado" type="checkbox" name="Administracion de cementerios pertenecientes al sector privado" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=812321", "Administracion de cementerios pertenecientes al sector privado", "#7e0de1")} /><label>Administracion de cementerios pertenecientes al sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Administracion de cementerios pertenecientes al sector publico" type="checkbox" name="Administracion de cementerios pertenecientes al sector publico" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=812322", "Administracion de cementerios pertenecientes al sector público", "#d58171")} /><label>Administracion de cementerios pertenecientes al sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Alineacion y balanceo de automoviles y camiones" type="checkbox" name="Alineacion y balanceo de automoviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811116", "Alineacion y balanceo de automoviles y camiones", "#7dd2a8")} /><label>Alineación y balanceo de automóviles y camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Asociaciones regulatorias de actividades recreativas" type="checkbox" name="Asociaciones regulatorias de actividades recreativas" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=813140", "Asociaciones regulatorias de actividades recreativas", "#e27ebf")} /><label>Asociaciones regulatorias de actividades recreativas</label>
                            </div>
                            <div className="opcion">
                              <input id="Asociaciones y organizaciones civiles" type="checkbox" name="Asociaciones y organizaciones civiles" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=813230", "Asociaciones y organizaciones civiles", "#7bd134")} /><label>Asociaciones y organizaciones civiles</label>
                            </div>
                            <div className="opcion">
                              <input id="Asociaciones y organizaciones de profesionistas" type="checkbox" name="Asociaciones y organizaciones de profesionistas" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=813130", "Asociaciones y organizaciones de profesionistas", "#e92351")} /><label>Asociaciones y organizaciones de profesionistas</label>
                            </div>
                            <div className="opcion">
                              <input id="Asociaciones y organizaciones laborales y sindicales" type="checkbox" name="Asociaciones y organizaciones laborales y sindicales" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=813120", "Asociaciones y organizaciones laborales y sindicales", "#79b1de")} /><label>Asociaciones y organizaciones laborales y sindicales</label>
                            </div>
                            <div className="opcion">
                              <input id="Asociaciones y organizaciones religiosas" type="checkbox" name="Asociaciones y organizaciones religiosas" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=813210", "Asociaciones y organizaciones religiosas", "#49cbbe")} /><label>Asociaciones y organizaciones religiosas</label>
                            </div>
                            <div className="opcion">
                              <input id="Asociaciones, organizaciones y cámaras de productores, comerciantes y prestadores de servicios" type="checkbox" name="Asociaciones, organizaciones y cámaras de productores, comerciantes y prestadores de servicios" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=813110", "Asociaciones, organizaciones y cámaras de productores, comerciantes y prestadores de servicios", "#5cc7cd")} /><label>Asociaciones, organizaciones y cámaras de productores, comerciantes y prestadores de servicios</label>
                            </div>
                            <div className="opcion">
                              <input id="Baños públicos" type="checkbox" name="Baños públicos" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=812120", "Baños públicos", "#ce5d97")} /><label>Baños públicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Cerrajerías" type="checkbox" name="Cerrajerías" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811491", "Cerrajerías", "#d012c0")} /><label>Cerrajerías</label>
                            </div>
                            <div className="opcion">
                              <input id="Estacionamientos y pensiones para vehículos automotores" type="checkbox" name="Estacionamientos y pensiones para vehículos automotores" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=812410", "Estacionamientos y pensiones para vehículos automotores", "#0fa6c8")} /><label>Estacionamientos y pensiones para vehículos automotores</label>
                            </div>
                            <div className="opcion">
                              <input id="Hojalatería y pintura de automóviles y camiones" type="checkbox" name="Hojalatería y pintura de automóviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811121", "Hojalatería y pintura de automóviles y camiones", "#d91fb1")} /><label>Hojalatería y pintura de automóviles y camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Instalación de cristales y otras reparaciones a la carrocería de automóviles y camiones" type="checkbox" name="Instalación de cristales y otras reparaciones a la carrocería de automóviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811129", "Instalación de cristales y otras reparaciones a la carrocería de automóviles y camiones", "#1fd5ab")} /><label>Instalación de cristales y otras reparaciones a la carrocería de automóviles y camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Lavado y lubricado de automóviles y camiones" type="checkbox" name="Lavado y lubricado de automóviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811192", "Lavado y lubricado de automóviles y camiones", "#4ce283")} /><label>Lavado y lubricado de automóviles y camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Lavanderías y tintorerías" type="checkbox" name="Lavanderías y tintorerías" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=812210", "Lavanderías y tintorerías", "#cb79dd")} /><label>Lavanderías y tintorerías</label>
                            </div>
                            <div className="opcion">
                              <input id="Otras reparaciones mecánicas de automóviles y camiones" type="checkbox" name="Otras reparaciones mecánicas de automóviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811119", "Otras reparaciones mecánicas de automóviles y camiones", "#6774cb")} /><label>Otras reparaciones mecánicas de automóviles y camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios de reparación y mantenimiento de automóviles y camiones" type="checkbox" name="Otros servicios de reparación y mantenimiento de automóviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811199", "Otros servicios de reparación y mantenimiento de automóviles y camiones", "#df67e5")} /><label>Otros servicios de reparación y mantenimiento de automóviles y camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios personales" type="checkbox" name="Otros servicios personales" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=812990", "Otros servicios personales", "#55aed8")} /><label>Otros servicios personales</label>
                            </div>
                            <div className="opcion">
                              <input id="Rectificación de partes de motor de automóviles y camiones" type="checkbox" name="Rectificación de partes de motor de automóviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811113", "Rectificación de partes de motor de automóviles y camiones", "#54e510")} /><label>Rectificación de partes de motor de automóviles y camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Reparación de calzado y otros artículos de piel y cuero" type="checkbox" name="Reparación de calzado y otros artículos de piel y cuero" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811430", "Reparación de calzado y otros artículos de piel y cuero", "#e1b97d")} /><label>Reparación de calzado y otros artículos de piel y cuero</label>
                            </div>
                            <div className="opcion">
                              <input id="Reparación de suspensiones de automóviles y camiones" type="checkbox" name="Reparación de suspensiones de automóviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811115", "Reparación de suspensiones de automóviles y camiones", "#c7d668")} /><label>Reparación de suspensiones de automóviles y camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Reparación de tapicería de muebles para el hogar" type="checkbox" name="Reparación de tapicería de muebles para el hogar" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811420", "Reparación de tapicería de muebles para el hogar", "#eb706c")} /><label>Reparación de tapicería de muebles para el hogar</label>
                            </div>
                            <div className="opcion">
                              <input id="Reparación de transmisiones de automóviles y camiones" type="checkbox" name="Reparación de transmisiones de automóviles y camiones" onClick={(e) => mostrarCapasDenue(e, "OtrosServiciosExceptoActividadesGubernamentales", "codigo_act=811114", "Reparación de transmisiones de automóviles y camiones", "#7332d5")} /><label>Reparación de transmisiones de automóviles y camiones</label>
                            </div>
                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="alojamiento_temporal" type="checkbox" name="alojamiento_temporal" /><label>Servicios de alojamiento temporal y de preparación de alimentos y bebidas</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Bares, cantinas y similares" type="checkbox" name="Bares, cantinas y similares" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722412", "Bares, cantinas y similares", "#7e0de1")} /><label>Bares, cantinas y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Cabañas, villas y similares" type="checkbox" name="Cabañas, villas y similares" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=721190", "Cabañas, villas y similares", "#d58171")} /><label>Cabañas, villas y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Cafeterías, fuentes de sodas, neverías, refresquerías y similares" type="checkbox" name="Cafeterías, fuentes de sodas, neverías, refresquerías y similares" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722515", "Cafeterías, fuentes de sodas, neverías, refresquerías y similares", "#7dd2a8")} /><label>Cafeterías, fuentes de sodas, neverías, refresquerías y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Campamentos y albergues recreativos" type="checkbox" name="Campamentos y albergues recreativos" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=721210", "Campamentos y albergues recreativos", "#e27ebf")} /><label>Campamentos y albergues recreativos</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros nocturnos, discotecas y similares" type="checkbox" name="Centros nocturnos, discotecas y similares" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722411", "Centros nocturnos, discotecas y similaress", "#7bd134")} /><label>Centros nocturnos, discotecas y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Departamentos y casas amueblados con servicios de hotelería" type="checkbox" name="Departamentos y casas amueblados con servicios de hotelería" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=721312", "Departamentos y casas amueblados con servicios de hotelería", "#e92351")} /><label>Departamentos y casas amueblados con servicios de hotelería</label>
                            </div>
                            <div className="opcion">
                              <input id="Hoteles con otros servicios integrados" type="checkbox" name="Hoteles con otros servicios integrados" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=721111", "Hoteles con otros servicios integrados", "#79b1de")} /><label>Hoteles con otros servicios integrados</label>
                            </div>
                            <div className="opcion">
                              <input id="Hoteles sin otros servicios integrados" type="checkbox" name="Hoteles sin otros servicios integrados" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=721112", "Hoteles sin otros servicios integrados", "#49cbbe")} /><label>Hoteles sin otros servicios integrados</label>
                            </div>
                            <div className="opcion">
                              <input id="Moteles" type="checkbox" name="Moteles" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=721113", "Moteles", "#5cc7cd")} /><label>Moteles</label>
                            </div>
                            <div className="opcion">
                              <input id="Pensiones y casas de huéspedes" type="checkbox" name="Pensiones y casas de huéspedes" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=721311", "Pensiones y casas de huéspedes", "#ce5d97")} /><label>Pensiones y casas de huéspedes</label>
                            </div>
                            <div className="opcion">
                              <input id="Restaurantes con servicio de preparación de alimentos a la carta o de comida corrida" type="checkbox" name="Restaurantes con servicio de preparación de alimentos a la carta o de comida corrida" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722511", "Restaurantes con servicio de preparación de alimentos a la carta o de comida corrida", "#d012c0")} /><label>Restaurantes con servicio de preparación de alimentos a la carta o de comida corrida</label>
                            </div>
                            <div className="opcion">
                              <input id="Restaurantes con servicio de preparación de antojitos" type="checkbox" name="Restaurantes con servicio de preparación de antojitos" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722513", "Restaurantes con servicio de preparación de antojitos", "#0fa6c8")} /><label>Restaurantes con servicio de preparación de antojitos</label>
                            </div>
                            <div className="opcion">
                              <input id="Restaurantes con servicio de preparación de pescados y mariscos" type="checkbox" name="Restaurantes con servicio de preparación de pescados y mariscos" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722512", "Restaurantes con servicio de preparación de pescados y mariscos", "#d91fb1")} /><label>Restaurantes con servicio de preparación de pescados y mariscos</label>
                            </div>
                            <div className="opcion">
                              <input id="Restaurantes con servicio de preparación de pizzas, hamburguesas, hot dogs y pollos rostizados para llevar" type="checkbox" name="Restaurantes con servicio de preparación de pizzas, hamburguesas, hot dogs y pollos rostizados para llevar" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722517", "Restaurantes con servicio de preparación de pizzas, hamburguesas, hot dogs y pollos rostizados para llevar", "#1fd5ab")} /><label>Restaurantes con servicio de preparación de pizzas, hamburguesas, hot dogs y pollos rostizados para llevar</label>
                            </div>
                            <div className="opcion">
                              <input id="Restaurantes con servicio de preparación de tacos y tortas" type="checkbox" name="Restaurantes con servicio de preparación de tacos y tortas" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722514", "Restaurantes con servicio de preparación de tacos y tortas", "#4ce283")} /><label>Restaurantes con servicio de preparación de tacos y tortas</label>
                            </div>
                            <div className="opcion">
                              <input id="Restaurantes de autoservicio" type="checkbox" name="Restaurantes de autoservicio" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722516", "Restaurantes de autoservicio", "#cb79dd")} /><label>Restaurantes de autoservicio</label>
                            </div>
                            <div className="opcion">
                              <input id="Restaurantes que preparan otro tipo de alimentos para llevar" type="checkbox" name="Restaurantes que preparan otro tipo de alimentos para llevar" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722518", "Restaurantes que preparan otro tipo de alimentos para llevar", "#6774cb")} /><label>Restaurantes que preparan otro tipo de alimentos para llevar</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de comedor para empresas e instituciones" type="checkbox" name="Servicios de comedor para empresas e instituciones" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722310", "Servicios de comedor para empresas e instituciones", "#df67e5")} /><label>Servicios de comedor para empresas e instituciones</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de preparación de alimentos en unidades móviles" type="checkbox" name="Servicios de preparación de alimentos en unidades móviles" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722330", "Servicios de preparación de alimentos en unidades móviles", "#55aed8")} /><label>Servicios de preparación de alimentos en unidades móviles</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de preparación de alimentos para ocasiones especiales" type="checkbox" name="Servicios de preparación de alimentos para ocasiones especiales" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722320", "Servicios de preparación de alimentos para ocasiones especiales", "#54e510")} /><label>Servicios de preparación de alimentos para ocasiones especiales</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de preparación de otros alimentos para consumo inmediato" type="checkbox" name="Servicios de preparación de otros alimentos para consumo inmediato" onClick={(e) => mostrarCapasDenue(e, "alojamiento_temporal", "codigo_act=722519", "Servicios de preparación de otros alimentos para consumo inmediato", "#e1b97d")} /><label>Servicios de preparación de otros alimentos para consumo inmediato</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="esparcimiento_culturales_deportivos" type="checkbox" name="esparcimiento_culturales_deportivos" /><label>Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Agentes y representantes de artistas, deportistas y similares" type="checkbox" name="Agentes y representantes de artistas, deportistas y similares" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711410", "Agentes y representantes de artistas, deportistas y similares", "#7e0de1")} /><label>Agentes y representantes de artistas, deportistas y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Artistas, escritores y técnicos independientes" type="checkbox" name="Artistas, escritores y técnicos independientes" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711510", "Artistas, escritores y técnicos independientes", "#d58171")} /><label>Artistas, escritores y técnicos independientes</label>
                            </div>
                            <div className="opcion">
                              <input id="Billares" type="checkbox" name="Billares" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713991", "Billares", "#7dd2a8")} /><label>Billares</label>
                            </div>
                            <div className="opcion">
                              <input id="Boliches" type="checkbox" name="Boliches" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713950", "Boliches", "#e27ebf")} /><label>Boliches</label>
                            </div>
                            <div className="opcion">
                              <input id="Campos de golf" type="checkbox" name="Campos de golf" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713910", "Campos de golf", "#7bd134")} /><label>Campos de golf</label>
                            </div>
                            <div className="opcion">
                              <input id="Cantantes y grupos musicales del sector privado" type="checkbox" name="Cantantes y grupos musicales del sector privado" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711131", "Cantantes y grupos musicales del sector privado", "#e92351")} /><label>Cantantes y grupos musicales del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Casas de juegos electrónicos" type="checkbox" name="Casas de juegos electrónicos" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713120", "Casas de juegos electrónicos", "#79b1de")} /><label>Casas de juegos electrónicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Casinos" type="checkbox" name="Casinos" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713210", "Casinos", "#49cbbe")} /><label>Casinos</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros de acondicionamiento físico del sector privado" type="checkbox" name="Centros de acondicionamiento físico del sector privado" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713943", "Centros de acondicionamiento físico del sector privado", "#5cc7cd")} /><label>Centros de acondicionamiento físico del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros de acondicionamiento físico del sector público" type="checkbox" name="Centros de acondicionamiento físico del sector público" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713944", "Centros de acondicionamiento físico del sector público", "#ce5d97")} /><label>Centros de acondicionamiento físico del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Clubes deportivos del sector privado" type="checkbox" name="Clubes deportivos del sector privado" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713941", "Clubes deportivos del sector privado", "#d012c0")} /><label>Clubes deportivos del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Clubes deportivos del sector público" type="checkbox" name="Clubes deportivos del sector público" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713942", "Clubes deportivos del sector público", "#0fa6c8")} /><label>Clubes deportivos del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Clubes o ligas de aficionados" type="checkbox" name="Clubes o ligas de aficionados" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=713992", "Clubes o ligas de aficionados", "#d91fb1")} /><label>Clubes o ligas de aficionados</label>
                            </div>
                            <div className="opcion">
                              <input id="Compañías de danza del sector privado" type="checkbox" name="Compañías de danza del sector privado" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711121", "Compañías de danza del sector privado", "#1fd5ab")} /><label>Compañías de danza del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Compañías de danza del sector público" type="checkbox" name="Compañías de danza del sector público" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711122", "Compañías de danza del sector público", "#4ce283")} /><label>Compañías de danza del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Compañías de teatro del sector privado" type="checkbox" name="Compañías de teatro del sector privado" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711111", "Compañías de teatro del sector privado", "#cb79dd")} /><label>Compañías de teatro del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Compañías de teatro del sector público" type="checkbox" name="Compañías de teatro del sector público" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivosv", "codigo_act=711112", "Compañías de teatro del sector público", "#6774cb")} /><label>Compañías de teatro del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Deportistas profesionales" type="checkbox" name="Deportistas profesionales" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711211", "Deportistas profesionales", "#df67e5")} /><label>Deportistas profesionales</label>
                            </div>
                            <div className="opcion">
                              <input id="Equipos deportivos profesionales" type="checkbox" name="Equipos deportivos profesionales" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711212", "Equipos deportivos profesionales", "#55aed8")} /><label>Equipos deportivos profesionales</label>
                            </div>
                            <div className="opcion">
                              <input id="Grupos musicales del sector público" type="checkbox" name="Grupos musicales del sector público" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=711132", "Grupos musicales del sector público", "#54e510")} /><label>Grupos musicales del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Grutas, parques naturales y otros sitios del patrimonio cultural de la nación" type="checkbox" name="Grutas, parques naturales y otros sitios del patrimonio cultural de la nación" onClick={(e) => mostrarCapasDenue(e, "esparcimiento_culturales_deportivos", "codigo_act=712190", "Grutas, parques naturales y otros sitios del patrimonio cultural de la nación", "#e1b97d")} /><label>Grutas, parques naturales y otros sitios del patrimonio cultural de la nación</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="salud_asistencia_social" type="checkbox" name="salud_asistencia_social" /><label>Servicios de salud y de asistencia social</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Agrupaciones de autoayuda para alcohólicos y personas con otras adicciones" type="checkbox" name="Agrupaciones de autoayuda para alcohólicos y personas con otras adicciones" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=624191", "Agrupaciones de autoayuda para alcohólicos y personas con otras adicciones", "#7e0de1")} /><label>Agrupaciones de autoayuda para alcohólicos y personas con otras adicciones</label>
                            </div>
                            <div className="opcion">
                              <input id="Asilos y otras residencias del sector privado para el cuidado de ancianos" type="checkbox" name="Asilos y otras residencias del sector privado para el cuidado de ancianos" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=623311", "Asilos y otras residencias del sector privado para el cuidado de ancianos", "#d58171")} /><label>Asilos y otras residencias del sector privado para el cuidado de ancianos</label>
                            </div>
                            <div className="opcion">
                              <input id="Asilos y otras residencias del sector público para el cuidado de ancianos" type="checkbox" name="Asilos y otras residencias del sector público para el cuidado de ancianos" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=623312", "Asilos y otras residencias del sector público para el cuidado de ancianos", "#7dd2a8")} /><label>Asilos y otras residencias del sector público para el cuidado de ancianos</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros de planificación familiar del sector privado" type="checkbox" name="Centros de planificación familiar del sector privado" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621411", "Centros de planificación familiar del sector privado", "#e27ebf")} /><label>Centros de planificación familiar del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros de planificación familiar del sector público" type="checkbox" name="Centros de planificación familiar del sector público" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621412", "Centros de planificación familiar del sector público", "#7bd134")} /><label>Centros de planificación familiar del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros del sector privado de atención médica externa para enfermos mentales y adictos" type="checkbox" name="Centros del sector privado de atención médica externa para enfermos mentales y adictos" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621421", "Centros del sector privado de atención médica externa para enfermos mentales y adictos", "#e92351")} /><label>Centros del sector privado de atención médica externa para enfermos mentales y adictos</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros del sector privado dedicados a la atención y cuidado diurno de ancianos y discapacitados" type="checkbox" name="Centros del sector privado dedicados a la atención y cuidado diurno de ancianos y discapacitados" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=624121", "Centros del sector privado dedicados a la atención y cuidado diurno de ancianos y discapacitados", "#79b1de")} /><label>Centros del sector privado dedicados a la atención y cuidado diurno de ancianos y discapacitados</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros del sector público de atención médica externa para enfermos mentales y adictos" type="checkbox" name="Centros del sector público de atención médica externa para enfermos mentales y adictos" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621422", "Centros del sector público de atención médica externa para enfermos mentales y adictos", "#49cbbe")} /><label>Centros del sector público de atención médica externa para enfermos mentales y adictos</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros del sector público dedicados a la atención y cuidado diurno de ancianos y discapacitados" type="checkbox" name="Centros del sector público dedicados a la atención y cuidado diurno de ancianos y discapacitados" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=624122", "Centros del sector público dedicados a la atención y cuidado diurno de ancianos y discapacitados", "#5cc7cd")} /><label>Centros del sector público dedicados a la atención y cuidado diurno de ancianos y discapacitados</label>
                            </div>
                            <div className="opcion">
                              <input id="Clínicas de consultorios médicos del sector privado" type="checkbox" name="Clínicas de consultorios médicos del sector privado" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621115", "Clínicas de consultorios médicos del sector privado", "#ce5d97")} /><label>Clínicas de consultorios médicos del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Clínicas de consultorios médicos del sector público" type="checkbox" name="Clínicas de consultorios médicos del sector público" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621116", "Clínicas de consultorios médicos del sector público", "#d012c0")} /><label>Clínicas de consultorios médicos del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de medicina especializada del sector privado" type="checkbox" name="Consultorios de medicina especializada del sector privado" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621113", "Consultorios de medicina especializada del sector privado", "#0fa6c8")} /><label>Consultorios de medicina especializada del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de medicina especializada del sector público" type="checkbox" name="Consultorios de medicina especializada del sector público" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621114", "Consultorios de medicina especializada del sector público", "#d91fb1")} /><label>Consultorios de medicina especializada del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de medicina general del sector privado" type="checkbox" name="Consultorios de medicina general del sector privado" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621111", "Consultorios de medicina general del sector privado", "#1fd5ab")} /><label>Consultorios de medicina general del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de medicina general del sector público" type="checkbox" name="Consultorios de medicina general del sector público" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621112", "Consultorios de medicina general del sector público", "#4ce283")} /><label>Consultorios de medicina general del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de nutriólogos y dietistas del sector privado" type="checkbox" name="Consultorios de nutriólogos y dietistas del sector privado" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621391", "Consultorios de nutriólogos y dietistas del sector privado", "#cb79dd")} /><label>Consultorios de nutriólogos y dietistas del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de nutriólogos y dietistas del sector público" type="checkbox" name="Consultorios de nutriólogos y dietistas del sector público" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621392", "Consultorios de nutriólogos y dietistas del sector público", "#6774cb")} /><label>Consultorios de nutriólogos y dietistas del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de optometría" type="checkbox" name="Consultorios de optometría" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621320", "Consultorios de optometría", "#df67e5")} /><label>Consultorios de optometría</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de psicología del sector privado" type="checkbox" name="Consultorios de psicología del sector privado" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621331", "Consultorios de psicología del sector privado", "#55aed8")} /><label>Consultorios de psicología del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de psicología del sector público" type="checkbox" name="Consultorios de psicología del sector público" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621332", "Consultorios de psicología del sector público", "#54e510")} /><label>Consultorios de psicología del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Consultorios de quiropráctica del sector privado" type="checkbox" name="Consultorios de quiropráctica del sector privado" onClick={(e) => mostrarCapasDenue(e, "salud_asistencia_social", "codigo_act=621311", "Consultorios de quiropráctica del sector privado", "#e1b97d")} /><label>Consultorios de quiropráctica del sector privado</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="servicios_educativos" type="checkbox" name="servicios_educativos" /><label>Servicios educativos</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Escuelas comerciales y secretariales del sector público" type="checkbox" name="Escuelas comerciales y secretariales del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611412", "Escuelas comerciales y secretariales del sector público", "#7e0de1")} /><label>Escuelas comerciales y secretariales del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas comerciales y secretariales del sector privado" type="checkbox" name="Escuelas comerciales y secretariales del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611411", "Escuelas comerciales y secretariales del sector privado", "#d58171")} /><label>Escuelas comerciales y secretariales del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de arte del sector público" type="checkbox" name="Escuelas de arte del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611612", "Escuelas de arte del sector público", "#7dd2a8")} /><label>Escuelas de arte del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de arte del sector privado" type="checkbox" name="Escuelas de arte del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611611", "Escuelas de arte del sector privado", "#e27ebf")} /><label>Escuelas de arte del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de computación del sector público" type="checkbox" name="Escuelas de computación del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611422", "Escuelas de computación del sector público", "#7bd134")} /><label>Escuelas de computación del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de computación del sector privado" type="checkbox" name="Escuelas de computación del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611421", "Escuelas de computación del sector privado", "#e92351")} /><label>Escuelas de computación del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de deporte del sector público" type="checkbox" name="Escuelas de deporte del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611622", "Escuelas de deporte del sector público", "#79b1de")} /><label>Escuelas de deporte del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de deporte del sector privado" type="checkbox" name="Escuelas de deporte del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611621", "Escuelas de deporte del sector privado", "#49cbbe")} /><label>Escuelas de deporte del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación media superior del sector público" type="checkbox" name="Escuelas de educación media superior del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611162", "Escuelas de educación media superior del sector público", "#5cc7cd")} /><label>Escuelas de educación media superior del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación media superior del sector privado" type="checkbox" name="Escuelas de educación media superior del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611161", "Escuelas de educación media superior del sector privado", "#ce5d97")} /><label>Escuelas de educación media superior del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación media técnica terminal del sector público" type="checkbox" name="Escuelas de educación media técnica terminal del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611152", "Escuelas de educación media técnica terminal del sector público", "#d012c0")} /><label>Escuelas de educación media técnica terminal del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación media técnica terminal del sector privado" type="checkbox" name="Escuelas de educación media técnica terminal del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611151", "Escuelas de educación media técnica terminal del sector privado", "#0fa6c8")} /><label>Escuelas de educación media técnica terminal del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación preescolar del sector público" type="checkbox" name="Escuelas de educación preescolar del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611112", "Escuelas de educación preescolar del sector público", "#d91fb1")} /><label>Escuelas de educación preescolar del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación preescolar del sector privado" type="checkbox" name="Escuelas de educación preescolar del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611111", "Escuelas de educación preescolar del sector privado", "#1fd5ab")} /><label>Escuelas de educación preescolar del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación primaria del sector público" type="checkbox" name="Escuelas de educación primaria del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611122", "Escuelas de educación primaria del sector público", "#4ce283")} /><label>Escuelas de educación primaria del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación primaria del sector privado" type="checkbox" name="Escuelas de educación primaria del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611121", "Escuelas de educación primaria del sector privado", "#cb79dd")} /><label>Escuelas de educación primaria del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación secundaria general del sector público" type="checkbox" name="Escuelas de educación secundaria general del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611132", "Escuelas de educación secundaria general del sector público", "#6774cb")} /><label>Escuelas de educación secundaria general del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación secundaria general del sector privado" type="checkbox" name="Escuelas de educación secundaria general del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611131", "Escuelas de educación secundaria general del sector privado", "#df67e5")} /><label>Escuelas de educación secundaria general del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación secundaria técnica del sector público" type="checkbox" name="Escuelas de educación secundaria técnica del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611142", "Escuelas de educación secundaria técnica del sector público", "#55aed8")} /><label>Escuelas de educación secundaria técnica del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación secundaria técnica del sector privado" type="checkbox" name="Escuelas de educación secundaria técnica del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611141", "Escuelas de educación secundaria técnica del sector privado", "#54e510")} /><label>Escuelas de educación secundaria técnica del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación superior del sector público" type="checkbox" name="Escuelas de educación superior del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611312", "Escuelas de educación superior del sector público", "#e1b97d")} /><label>Escuelas de educación superior del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación superior del sector privado" type="checkbox" name="Escuelas de educación superior del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611311", "Escuelas de educación superior del sector privado", "#4c1ee6")} /><label>Escuelas de educación superior del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación técnica superior del sector público" type="checkbox" name="Escuelas de educación técnica superior del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611212", "Escuelas de educación técnica superior del sector público", "#c4e647")} /><label>Escuelas de educación técnica superior del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de educación técnica superior del sector privado" type="checkbox" name="Escuelas de educación técnica superior del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611211", "Escuelas de educación técnica superior del sector privado", "#ca4c78")} /><label>Escuelas de educación técnica superior del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de idiomas del sector público" type="checkbox" name="Escuelas de idiomas del sector público" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611632", "Escuelas de idiomas del sector público", "#59bce4")} /><label>Escuelas de idiomas del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Escuelas de idiomas del sector privado" type="checkbox" name="Escuelas de idiomas del sector privado" onClick={(e) => mostrarCapasDenue(e, "servicios_educativos", "codigo_act=611631", "Escuelas de idiomas del sector privado", "#d7cb5d")} /><label>Escuelas de idiomas del sector privado</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="apoyo_negocios" type="checkbox" name="apoyo_negocios" /><label>Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Agencias de cobranza" type="checkbox" name="Agencias de cobranza" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561440", "Agencias de cobranza", "#7e0de1")} /><label>Agencias de cobranza</label>
                            </div>
                            <div className="opcion">
                              <input id="Agencias de colocación" type="checkbox" name="Agencias de colocación" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561310", "Agencias de colocación", "#d58171")} /><label>Agencias de colocación</label>
                            </div>
                            <div className="opcion">
                              <input id="Agencias de empleo temporal" type="checkbox" name="Agencias de empleo temporal" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561320", "Agencias de empleo temporal", "#7dd2a8")} /><label>Agencias de empleo temporal</label>
                            </div>
                            <div className="opcion">
                              <input id="Agencias de viajes" type="checkbox" name="Agencias de viajes" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561510", "Agencias de viajes", "#e27ebf")} /><label>Agencias de viajes</label>
                            </div>
                            <div className="opcion">
                              <input id="Despachos de investigación de solvencia financiera" type="checkbox" name="Despachos de investigación de solvencia financiera" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561450", "Despachos de investigación de solvencia financiera", "#7bd134")} /><label>Despachos de investigación de solvencia financiera</label>
                            </div>
                            <div className="opcion">
                              <input id="Manejo de desechos no peligrosos y servicios de remediación a zonas dañadas por desechos no peligrosos" type="checkbox" name="Manejo de desechos no peligrosos y servicios de remediación a zonas dañadas por desechos no peligrosos" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=562112", "Manejo de desechos no peligrosos y servicios de remediación a zonas dañadas por desechos no peligrosos", "#e92351")} /><label>Manejo de desechos no peligrosos y servicios de remediación a zonas dañadas por desechos no peligrosos</label>
                            </div>
                            <div className="opcion">
                              <input id="Manejo de residuos peligrosos y servicios de remediación a zonas dañadas por materiales o residuos peligrosos" type="checkbox" name="Manejo de residuos peligrosos y servicios de remediación a zonas dañadas por materiales o residuos peligrosos" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=562111", "Manejo de residuos peligrosos y servicios de remediación a zonas dañadas por materiales o residuos peligrosos", "#79b1de")} /><label>Manejo de residuos peligrosos y servicios de remediación a zonas dañadas por materiales o residuos peligrosos</label>
                            </div>
                            <div className="opcion">
                              <input id="Organización de excursiones y paquetes turísticos para agencias de viajes" type="checkbox" name="Organización de excursiones y paquetes turísticos para agencias de viajes" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561520", "Organización de excursiones y paquetes turísticos para agencias de viajes", "#49cbbe")} /><label>Organización de excursiones y paquetes turísticos para agencias de viajes</label>
                            </div>
                            <div className="opcion">
                              <input id="Organizadores de convenciones y ferias comerciales e industriales" type="checkbox" name="Organizadores de convenciones y ferias comerciales e industriales" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561920", "Organizadores de convenciones y ferias comerciales e industriales", "#5cc7cd")} /><label>Organizadores de convenciones y ferias comerciales e industriales</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios de apoyo a los negocios" type="checkbox" name="Otros servicios de apoyo a los negocios" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561990", "Otros servicios de apoyo a los negocios", "#ce5d97")} /><label>Otros servicios de apoyo a los negocios</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios de apoyo secretarial y similares" type="checkbox" name="Otros servicios de apoyo secretarial y similares" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561490", "Otros servicios de apoyo secretarial y similares", "#d012c0")} /><label>Otros servicios de apoyo secretarial y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios de limpieza" type="checkbox" name="Otros servicios de limpieza" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561790", "Otros servicios de limpieza", "#0fa6c8")} /><label>Otros servicios de limpieza</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios de manejo de residuos por el sector privado" type="checkbox" name="Otros servicios de manejo de residuos por el sector privado" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=562998", "Otros servicios de manejo de residuos por el sector privado", "#d91fb1")} /><label>Otros servicios de manejo de residuos por el sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios de manejo de residuos por el sector público" type="checkbox" name="Otros servicios de manejo de residuos por el sector público" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=562999", "Otros servicios de manejo de residuos por el sector público", "#1fd5ab")} /><label>Otros servicios de manejo de residuos por el sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios de reservaciones" type="checkbox" name="Otros servicios de reservaciones" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561590", "Otros servicios de reservaciones", "#4ce283")} /><label>Otros servicios de reservaciones</label>
                            </div>
                            <div className="opcion">
                              <input id="Recolección de residuos no peligrosos por el sector privado" type="checkbox" name="Recolección de residuos no peligrosos por el sector privado" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=562121", "Recolección de residuos no peligrosos por el sector privado", "#cb79dd")} /><label>Recolección de residuos no peligrosos por el sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Recolección de residuos no peligrosos por el sector público" type="checkbox" name="Recolección de residuos no peligrosos por el sector público" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=562122", "Recolección de residuos no peligrosos por el sector público", "#6774cb")} /><label>Recolección de residuos no peligrosos por el sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Recuperación de residuos por el sector privado" type="checkbox" name="Recuperación de residuos por el sector privado" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=562921", "Recuperación de residuos por el sector privado", "#df67e5")} /><label>Recuperación de residuos por el sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Recuperación de residuos por el sector público" type="checkbox" name="Recuperación de residuos por el sector público" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=562922", "Recuperación de residuos por el sector público", "#55aed8")} /><label>Recuperación de residuos por el sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios combinados de apoyo en instalaciones" type="checkbox" name="Servicios combinados de apoyo en instalaciones" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561210", "Servicios combinados de apoyo en instalaciones", "#54e510")} /><label>Servicios combinados de apoyo en instalaciones</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de acceso a computadoras" type="checkbox" name="Servicios de acceso a computadoras" onClick={(e) => mostrarCapasDenue(e, "apoyo_negocios", "codigo_act=561432", "Servicios de acceso a computadoras", "#e1b97d")} /><label>Servicios de acceso a computadoras</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="corporativos" type="checkbox" name="corporativos" /><label>Corporativos</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Corporativos" type="checkbox" name="Corporativos" onClick={(e) => mostrarCapasDenue(e, "corporativos", "codigo_act=551111", "Corporativos", "#7e0de1")} /><label>Corporativos</label>
                            </div>
                            <div className="opcion">
                              <input id="Tenedoras de acciones" type="checkbox" name="Tenedoras de acciones" onClick={(e) => mostrarCapasDenue(e, "corporativos", "codigo_act=551112", "Tenedoras de acciones", "#d58171")} /><label>Tenedoras de acciones</label>
                            </div>
                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="servicios_profesionales" type="checkbox" name="servicios_profesionales" /><label>Servicios profesionales, científicos y técnicos</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Agencias de anuncios publicitarios" type="checkbox" name="Agencias de anuncios publicitarios" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541850", "Agencias de anuncios publicitarios", "#7e0de1")} /><label>Agencias de anuncios publicitarios</label>
                            </div>
                            <div className="opcion">
                              <input id="Agencias de compra de medios a petición del cliente" type="checkbox" name="Agencias de compra de medios a petición del cliente" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541830", "Agencias de compra de medios a petición del cliente", "#d58171")} /><label>Agencias de compra de medios a petición del cliente</label>
                            </div>
                            <div className="opcion">
                              <input id="Agencias de correo directo" type="checkbox" name="Agencias de correo directo" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541860", "Agencias de correo directo", "#7dd2a8")} /><label>Agencias de correo directo</label>
                            </div>
                            <div className="opcion">
                              <input id="Agencias de publicidad" type="checkbox" name="Agencias de publicidad" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541810", "Agencias de publicidad", "#e27ebf")} /><label>Agencias de publicidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Agencias de relaciones públicas" type="checkbox" name="Agencias de relaciones públicas" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541820", "Agencias de relaciones públicas", "#7bd134")} /><label>Agencias de relaciones públicas</label>
                            </div>
                            <div className="opcion">
                              <input id="Agencias de representación de medios" type="checkbox" name="Agencias de representación de medios" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541840", "Agencias de representación de medios", "#e92351")} /><label>Agencias de representación de medios</label>
                            </div>
                            <div className="opcion">
                              <input id="Bufetes jurídicos" type="checkbox" name="Bufetes jurídicos" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541110", "Bufetes jurídicos", "#79b1de")} /><label>Bufetes jurídicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Diseño de modas y otros diseños especializados" type="checkbox" name="Diseño de modas y otros diseños especializados" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541490", "Diseño de modas y otros diseños especializados", "#49cbbe")} /><label>Diseño de modas y otros diseños especializados</label>
                            </div>
                            <div className="opcion">
                              <input id="Diseño gráfico" type="checkbox" name="Diseño gráfico" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541430", "Diseño gráfico", "#5cc7cd")} /><label>Diseño gráfico</label>
                            </div>
                            <div className="opcion">
                              <input id="Diseño industrial" type="checkbox" name="Diseño industrial" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541420", "Diseño industrial", "#ce5d97")} /><label>Diseño industrial</label>
                            </div>
                            <div className="opcion">
                              <input id="Diseño y decoración de interiores" type="checkbox" name="Diseño y decoración de interiores" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541410", "Diseño y decoración de interiores", "#d012c0")} /><label>Diseño y decoración de interiores</label>
                            </div>
                            <div className="opcion">
                              <input id="Distribución de material publicitario" type="checkbox" name="Distribución de material publicitario" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541870", "Distribución de material publicitario", "#0fa6c8")} /><label>Distribución de material publicitario</label>
                            </div>
                            <div className="opcion">
                              <input id="Laboratorios de pruebas" type="checkbox" name="Laboratorios de pruebas" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541380", "Laboratorios de pruebas", "#d91fb1")} /><label>Laboratorios de pruebas</label>
                            </div>
                            <div className="opcion">
                              <input id="Notarías públicas" type="checkbox" name="Notarías públicas" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541120", "Notarías públicas", "#1fd5ab")} /><label>Notarías públicas</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios de consultoría científica y técnica" type="checkbox" name="Otros servicios de consultoría científica y técnica" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541690", "Otros servicios de consultoría científica y técnica", "#4ce283")} /><label>Otros servicios de consultoría científica y técnica</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios profesionales, científicos y técnicos" type="checkbox" name="Otros servicios profesionales, científicos y técnicos" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541990", "Otros servicios profesionales, científicos y técnicos", "#cb79dd")} /><label>Otros servicios profesionales, científicos y técnicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios relacionados con la contabilidad" type="checkbox" name="Otros servicios relacionados con la contabilidad" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541219", "Otros servicios relacionados con la contabilidad", "#6774cb")} /><label>Otros servicios relacionados con la contabilidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de apoyo para efectuar trámites legales" type="checkbox" name="Servicios de apoyo para efectuar trámites legales" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541190", "Servicios de apoyo para efectuar trámites legales", "#df67e5")} /><label>Servicios de apoyo para efectuar trámites legales</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de arquitectura" type="checkbox" name="Servicios de arquitectura" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541310", "Servicios de arquitectura", "#55aed8")} /><label>Servicios de arquitectura</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de arquitectura de paisaje y urbanismo" type="checkbox" name="Servicios de arquitectura de paisaje y urbanismo" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541320", "Servicios de arquitectura de paisaje y urbanismo", "#54e510")} /><label>Servicios de arquitectura de paisaje y urbanismo</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de consultoría en administración" type="checkbox" name="Servicios de consultoría en administración" onClick={(e) => mostrarCapasDenue(e, "servicios_profesionales", "codigo_act=541610", "Servicios de consultoría en administración", "#e1b97d")} /><label>Servicios de consultoría en administración</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="servicios_inmobiliarios_alquiler" type="checkbox" name="servicios_inmobiliarios_alquiler" /><label>Servicios inmobiliarios y de alquiler de bienes muebles e intangibles</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Alquiler de aparatos eléctricos y electrónicos para el hogar y personales" type="checkbox" name="Alquiler de aparatos eléctricos y electrónicos para el hogar y personales" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532210", "Alquiler de aparatos eléctricos y electrónicos para el hogar y personales", "#7e0de1")} /><label>Alquiler de aparatos eléctricos y electrónicos para el hogar y personales</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de autobuses, minibuses y remolques sin chofer" type="checkbox" name="Alquiler de autobuses, minibuses y remolques sin chofer" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532122", "Alquiler de autobuses, minibuses y remolques sin chofer", "#d58171")} /><label>Alquiler de autobuses, minibuses y remolques sin chofer</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de automóviles sin chofer" type="checkbox" name="Alquiler de automóviles sin chofer" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532110", "Alquiler de automóviles sin chofer", "#7dd2a8")} /><label>Alquiler de automóviles sin chofer</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de camiones de carga sin chofer" type="checkbox" name="Alquiler de camiones de carga sin chofer" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532121", "Alquiler de camiones de carga sin chofer", "#e27ebf")} /><label>Alquiler de camiones de carga sin chofer</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de equipo de cómputo y de otras máquinas y mobiliario de oficina" type="checkbox" name="Alquiler de equipo de cómputo y de otras máquinas y mobiliario de oficina" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532420", "Alquiler de equipo de cómputo y de otras máquinas y mobiliario de oficina", "#7bd134")} /><label>Alquiler de equipo de cómputo y de otras máquinas y mobiliario de oficina</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de equipo de transporte, excepto terrestre" type="checkbox" name="Alquiler de equipo de transporte, excepto terrestre" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532412", "Alquiler de equipo de transporte, excepto terrestre", "#e92351")} /><label>Alquiler de equipo de transporte, excepto terrestre</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de maquinaria y equipo agropecuario, pesquero y para la industria manufacturera" type="checkbox" name="Alquiler de maquinaria y equipo agropecuario, pesquero y para la industria manufacturera" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532491", "Alquiler de maquinaria y equipo agropecuario, pesquero y para la industria manufacturera", "#79b1de")} /><label>Alquiler de maquinaria y equipo agropecuario, pesquero y para la industria manufacturera</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de maquinaria y equipo comercial y de servicios" type="checkbox" name="Alquiler de maquinaria y equipo comercial y de servicios" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532493", "Alquiler de maquinaria y equipo comercial y de servicios", "#49cbbe")} /><label>Alquiler de maquinaria y equipo comercial y de servicios</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de maquinaria y equipo para construcción, minería y actividades forestales" type="checkbox" name="Alquiler de maquinaria y equipo para construcción, minería y actividades forestales" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532411", "Alquiler de maquinaria y equipo para construcción, minería y actividades forestales", "#5cc7cd")} /><label>Alquiler de maquinaria y equipo para construcción, minería y actividades forestales</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de maquinaria y equipo para mover, levantar y acomodar materiales" type="checkbox" name="Alquiler de maquinaria y equipo para mover, levantar y acomodar materiales" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532492", "Alquiler de maquinaria y equipo para mover, levantar y acomodar materiales", "#ce5d97")} /><label>Alquiler de maquinaria y equipo para mover, levantar y acomodar materiales</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de mesas, sillas, vajillas y similares" type="checkbox" name="Alquiler de mesas, sillas, vajillas y similares" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532282", "Alquiler de mesas, sillas, vajillas y similares", "#d012c0")} /><label>Alquiler de mesas, sillas, vajillas y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de otros artículos para el hogar y personales" type="checkbox" name="Alquiler de otros artículos para el hogar y personales" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532289", "Alquiler de otros artículos para el hogar y personales", "#0fa6c8")} /><label>Alquiler de otros artículos para el hogar y personales</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de prendas de vestir" type="checkbox" name="Alquiler de prendas de vestir" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532281", "Alquiler de prendas de vestir", "#d91fb1")} /><label>Alquiler de prendas de vestir</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler sin intermediación de edificios industriales dentro de un parque industrial" type="checkbox" name="Alquiler sin intermediación de edificios industriales dentro de un parque industrial" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531116", "Alquiler sin intermediación de edificios industriales dentro de un parque industrial", "#1fd5ab")} /><label>Alquiler sin intermediación de edificios industriales dentro de un parque industrial</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler sin intermediación de oficinas y locales comerciales" type="checkbox" name="Alquiler sin intermediación de oficinas y locales comerciales" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531114", "Alquiler sin intermediación de oficinas y locales comerciales", "#4ce283")} /><label>Alquiler sin intermediación de oficinas y locales comerciales</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler sin intermediación de otros bienes raíces" type="checkbox" name="Alquiler sin intermediación de otros bienes raíces" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531119", "Alquiler sin intermediación de otros bienes raíces", "#cb79dd")} /><label>Alquiler sin intermediación de otros bienes raíces</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler sin intermediación de salones para fiestas y convenciones" type="checkbox" name="Alquiler sin intermediación de salones para fiestas y convenciones" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531113", "Alquiler sin intermediación de salones para fiestas y convenciones", "#6774cb")} /><label>Alquiler sin intermediación de salones para fiestas y convenciones</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler sin intermediación de teatros, estadios, auditorios y similares" type="checkbox" name="Alquiler sin intermediación de teatros, estadios, auditorios y similares" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531115", "Alquiler sin intermediación de teatros, estadios, auditorios y similares", "#df67e5")} /><label>Alquiler sin intermediación de teatros, estadios, auditorios y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler sin intermediación de viviendas amuebladas" type="checkbox" name="Alquiler sin intermediación de viviendas amuebladas" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531111", "Alquiler sin intermediación de viviendas amuebladas", "#55aed8")} /><label>Alquiler sin intermediación de viviendas amuebladas</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler sin intermediación de viviendas no amuebladas" type="checkbox" name="Alquiler sin intermediación de viviendas no amuebladas" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=", "Alquiler sin intermediación de viviendas no amuebladas", "#54e510")} /><label>Alquiler sin intermediación de viviendas no amuebladas</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros generales de alquiler" type="checkbox" name="Centros generales de alquiler" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=532310", "Centros generales de alquiler", "#e1b97d")} /><label>Centros generales de alquiler</label>
                            </div>
                            <div className="opcion">
                              <input id="Inmobiliarias y corredores de bienes raíces" type="checkbox" name="Inmobiliarias y corredores de bienes raíces" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531210", "Inmobiliarias y corredores de bienes raíces", "#72d374")} /><label>Inmobiliarias y corredores de bienes raíces</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios relacionados con los servicios inmobiliarios" type="checkbox" name="Otros servicios relacionados con los servicios inmobiliarios" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531319", "Otros servicios relacionados con los servicios inmobiliarios", "#3bb993")} /><label>Otros servicios relacionados con los servicios inmobiliarios</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de administración de bienes raíces" type="checkbox" name="Servicios de administración de bienes raíces" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=531311", "Servicios de administración de bienes raíces", "#ec4779")} /><label>Servicios de administración de bienes raíces</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de alquiler de marcas registradas, patentes y franquicias" type="checkbox" name="Servicios de alquiler de marcas registradas, patentes y franquicias" onClick={(e) => mostrarCapasDenue(e, "servicios_inmobiliarios_alquiler", "codigo_act=533110", "Servicios de alquiler de marcas registradas, patentes y franquicias", "#e2864d")} /><label>Servicios de alquiler de marcas registradas, patentes y franquicias</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="servicios_financieros" type="checkbox" name="servicios_financieros" />
                        <label>Servicios financieros y de seguros</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Administración de cajas de pensión y de seguros independientes" type="checkbox" name="Administración de cajas de pensión y de seguros independientes" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=524220", "Administración de cajas de pensión y de seguros independientes", "#7e0de1")} /><label>Administración de cajas de pensión y de seguros independientes</label>
                            </div>
                            <div className="opcion">
                              <input id="Agentes, ajustadores y gestores de seguros y fianzas" type="checkbox" name="Agentes, ajustadores y gestores de seguros y fianzas" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=524210", "Agentes, ajustadores y gestores de seguros y fianzas", "#d58171")} /><label>Agentes, ajustadores y gestores de seguros y fianzas</label>
                            </div>
                            <div className="opcion">
                              <input id="Asesoría en inversiones" type="checkbox" name="Asesoría en inversiones" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=523910", "Asesoría en inversiones", "#7dd2a8")} /><label>Asesoría en inversiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Banca central" type="checkbox" name="Banca central" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=521110", "Banca central", "#e27ebf")} /><label>Banca central</label>
                            </div>
                            <div className="opcion">
                              <input id="Banca de desarrollo" type="checkbox" name="Banca de desarrollo" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522210", "Banca de desarrollo", "#7bd134")} /><label>Banca de desarrollo</label>
                            </div>
                            <div className="opcion">
                              <input id="Banca múltiple" type="checkbox" name="Banca múltiple" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522110", "Banca múltiple", "#e92351")} /><label>Banca múltiple</label>
                            </div>
                            <div className="opcion">
                              <input id="Bolsa de valores" type="checkbox" name="Bolsa de valores" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=523210", "Bolsa de valores", "#79b1de")} /><label>Bolsa de valores</label>
                            </div>
                            <div className="opcion">
                              <input id="Cajas de ahorro popular" type="checkbox" name="Cajas de ahorro popular" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522320", "Cajas de ahorro popular", "#49cbbe")} /><label>Cajas de ahorro popular</label>
                            </div>
                            <div className="opcion">
                              <input id="Casas de bolsa" type="checkbox" name="Casas de bolsa" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=523110", "Casas de bolsa", "#5cc7cd")} /><label>Casas de bolsa</label>
                            </div>
                            <div className="opcion">
                              <input id="Casas de cambio" type="checkbox" name="Casas de cambio" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=523121", "Casas de cambio", "#ce5d97")} /><label>Casas de cambio</label>
                            </div>
                            <div className="opcion">
                              <input id="Casas de empeño" type="checkbox" name="Casas de empeño" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522452", "Casas de empeño", "#d012c0")} /><label>Casas de empeño</label>
                            </div>
                            <div className="opcion">
                              <input id="Centros cambiarios" type="checkbox" name="Centros cambiarios" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=523122", "Centros cambiarios", "#0fa6c8")} /><label>Centros cambiarios</label>
                            </div>
                            <div className="opcion">
                              <input id="Compañías afianzadoras" type="checkbox" name="Compañías afianzadoras" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=524130", "Compañías afianzadoras", "#d91fb1")} /><label>Compañías afianzadoras</label>
                            </div>
                            <div className="opcion">
                              <input id="Compañías de autofinanciamiento" type="checkbox" name="Compañías de autofinanciamiento" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522440", "Compañías de autofinanciamiento", "#1fd5ab")} /><label>Compañías de autofinanciamiento</label>
                            </div>
                            <div className="opcion">
                              <input id="Compañías de seguros" type="checkbox" name="Compañías de seguros" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=524110", "Compañías de seguros", "#4ce283")} /><label>Compañías de seguros</label>
                            </div>
                            <div className="opcion">
                              <input id="Fondos de aseguramiento campesino" type="checkbox" name="Fondos de aseguramiento campesino" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=524120", "Fondos de aseguramiento campesino", "#cb79dd")} /><label>Fondos de aseguramiento campesino</label>
                            </div>
                            <div className="opcion">
                              <input id="Fondos de inversión" type="checkbox" name="Fondos de inversión" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=525210", "Fondos de inversión", "#6774cb")} /><label>Fondos de inversión</label>
                            </div>
                            <div className="opcion">
                              <input id="Fondos y fideicomisos financieros" type="checkbox" name="Fondos y fideicomisos financieros" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522220", "Fondos y fideicomisos financieros", "#df67e5")} /><label>Fondos y fideicomisos financieros</label>
                            </div>
                            <div className="opcion">
                              <input id="Montepíos" type="checkbox" name="Montepíos" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522451", "Montepíos", "#55aed8")} /><label>Montepíos</label>
                            </div>
                            <div className="opcion">
                              <input id="Otras instituciones de ahorro y préstamo" type="checkbox" name="Otras instituciones de ahorro y préstamo" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522390", "Otras instituciones de ahorro y préstamo", "#54e510")} /><label>Otras instituciones de ahorro y préstamo</label>
                            </div>
                            <div className="opcion">
                              <input id="Otras instituciones de intermediación crediticia y financiera no bursátil" type="checkbox" name="Otras instituciones de intermediación crediticia y financiera no bursátil" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522490", "Otras instituciones de intermediación crediticia y financiera no bursátil", "#e1b97d")} /><label>Otras instituciones de intermediación crediticia y financiera no bursátil</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios relacionados con la intermediación bursátil" type="checkbox" name="Otros servicios relacionados con la intermediación bursátil" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=523990", "Otros servicios relacionados con la intermediación bursátil", "#83f081")} /><label>Otros servicios relacionados con la intermediación bursátil</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios relacionados con la intermediación crediticia no bursátil" type="checkbox" name="Servicios relacionados con la intermediación crediticia no bursátil" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522510", "Servicios relacionados con la intermediación crediticia no bursátil", "#d6905a")} /><label>Servicios relacionados con la intermediación crediticia no bursátil</label>
                            </div>
                            <div className="opcion">
                              <input id="Sociedades de inversión especializadas en fondos para el retiro" type="checkbox" name="Sociedades de inversión especializadas en fondos para el retiro" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=525110", "Sociedades de inversión especializadas en fondos para el retiro", "#9b2ad3")} /><label>Sociedades de inversión especializadas en fondos para el retiro</label>
                            </div>
                            <div className="opcion">
                              <input id="Sociedades financieras de objeto múltiple" type="checkbox" name="Sociedades financieras de objeto múltiple" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522460", "Sociedades financieras de objeto múltiple", "#eadc80")} /><label>Sociedades financieras de objeto múltiple</label>
                            </div>
                            <div className="opcion">
                              <input id="Uniones de crédito" type="checkbox" name="Uniones de crédito" onClick={(e) => mostrarCapasDenue(e, "servicios_financieros", "codigo_act=522310", "Uniones de crédito", "#bdc94c")} /><label>Uniones de crédito</label>
                            </div>
                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="medios_masivos" type="checkbox" name="medios_masivos" />
                        <label>Información en medios masivos</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Agencias noticiosas" type="checkbox" name="Agencias noticiosas" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=519110", "Agencias noticiosas", "#7e0de1")} /><label>Agencias noticiosas</label>
                            </div>
                            <div className="opcion">
                              <input id="Bibliotecas y archivos del sector privado" type="checkbox" name="Bibliotecas y archivos del sector privado" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=519121", "Bibliotecas y archivos del sector privado", "#d58171")} /><label>Bibliotecas y archivos del sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Bibliotecas y archivos del sector público" type="checkbox" name="Bibliotecas y archivos del sector público" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=519122", "Bibliotecas y archivos del sector público", "#7dd2a8")} /><label>Bibliotecas y archivos del sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Distribución de películas y de otros materiales audiovisuales" type="checkbox" name="Distribución de películas y de otros materiales audiovisuales" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=512120", "Distribución de películas y de otros materiales audiovisuales", "#e27ebf")} /><label>Distribución de películas y de otros materiales audiovisuales</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de directorios y de listas de correo" type="checkbox" name="Edición de directorios y de listas de correo" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511141", "Edición de directorios y de listas de correo", "#7bd134")} /><label>Edición de directorios y de listas de correo</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de directorios y de listas de correo integrada con la impresión" type="checkbox" name="Edición de directorios y de listas de correo integrada con la impresión" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511142", "Edición de directorios y de listas de correo integrada con la impresión", "#e92351")} /><label>Edición de directorios y de listas de correo integrada con la impresión</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de libros" type="checkbox" name="Edición de libros" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511131", "Edición de libros", "#79b1de")} /><label>Edición de libros</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de libros integrada con la impresión" type="checkbox" name="Edición de libros integrada con la impresión" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511132", "Edición de libros integrada con la impresión", "#49cbbe")} /><label>Edición de libros integrada con la impresión</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de otros materiales" type="checkbox" name="Edición de otros materiales" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511191", "Edición de otros materiales", "#5cc7cd")} /><label>Edición de otros materiales</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de otros materiales integrada con la impresión" type="checkbox" name="Edición de otros materiales integrada con la impresión" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511192", "Edición de otros materiales integrada con la impresión", "#ce5d97")} /><label>Edición de otros materiales integrada con la impresión</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de periódicos" type="checkbox" name="Edición de periódicos" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511111", "Edición de periódicos", "#d012c0")} /><label>Edición de periódicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de periódicos integrada con la impresión" type="checkbox" name="Edición de periódicos integrada con la impresión" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511112", "Edición de periódicos integrada con la impresión", "#0fa6c8")} /><label>Edición de periódicos integrada con la impresión</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de revistas y otras publicaciones periódicas" type="checkbox" name="Edición de revistas y otras publicaciones periódicas" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511121", "Edición de revistas y otras publicaciones periódicas", "#d91fb1")} /><label>Edición de revistas y otras publicaciones periódicas</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de revistas y otras publicaciones periódicas integrada con la impresión" type="checkbox" name="Edición de revistas y otras publicaciones periódicas integrada con la impresión" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511122", "Edición de revistas y otras publicaciones periódicas integrada con la impresión", "#1fd5ab")} /><label>Edición de revistas y otras publicaciones periódicas integrada con la impresión</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición de software y edición de software integrada con la reproducción" type="checkbox" name="Edición de software y edición de software integrada con la reproducción" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=511210", "Edición de software y edición de software integrada con la reproducción", "#4ce283")} /><label>Edición de software y edición de software integrada con la reproducción</label>
                            </div>
                            <div className="opcion">
                              <input id="Edición y difusión de contenido exclusivamente a través de Internet y servicios de búsqueda en la red" type="checkbox" name="Edición y difusión de contenido exclusivamente a través de Internet y servicios de búsqueda en la red" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=519130", "Edición y difusión de contenido exclusivamente a través de Internet y servicios de búsqueda en la red", "#cb79dd")} /><label>Edición y difusión de contenido exclusivamente a través de Internet y servicios de búsqueda en la red</label>
                            </div>
                            <div className="opcion">
                              <input id="Editoras de música" type="checkbox" name="Editoras de música" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=512230", "Editoras de música", "#6774cb")} /><label>Editoras de música</label>
                            </div>
                            <div className="opcion">
                              <input id="Exhibición de películas y otros materiales audiovisuales" type="checkbox" name="Exhibición de películas y otros materiales audiovisuales" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=512130", "Exhibición de películas y otros materiales audiovisuales", "#df67e5")} /><label>Exhibición de películas y otros materiales audiovisuales</label>
                            </div>
                            <div className="opcion">
                              <input id="Grabación de discos compactos (CD) y de video digital (DVD) o casetes musicales" type="checkbox" name="Grabación de discos compactos (CD) y de video digital (DVD) o casetes musicales" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=512240", "Grabación de discos compactos (CD) y de video digital (DVD) o casetes musicales", "#55aed8")} /><label>Grabación de discos compactos (CD) y de video digital (DVD) o casetes musicales</label>
                            </div>
                            <div className="opcion">
                              <input id="Operadores de servicios de telecomunicaciones alámbricas" type="checkbox" name="Operadores de servicios de telecomunicaciones alámbricas" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=517311", "Operadores de servicios de telecomunicaciones alámbricas", "#54e510")} /><label>Operadores de servicios de telecomunicaciones alámbricas</label>
                            </div>
                            <div className="opcion">
                              <input id="Operadores de servicios de telecomunicaciones inalámbricas" type="checkbox" name="Operadores de servicios de telecomunicaciones inalámbricas" onClick={(e) => mostrarCapasDenue(e, "medios_masivos", "codigo_act=517312", "Operadores de servicios de telecomunicaciones inalámbricas", "#e1b97d")} /><label>Operadores de servicios de telecomunicaciones inalámbricas</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="transportes" type="checkbox" name="transportes" />
                        <label>Transportes, correos y almacenamiento</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Administración de aeropuertos y helipuertos" type="checkbox" name="Administración de aeropuertos y helipuertos" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=488112", "Administración de aeropuertos y helipuertos", "#7e0de1")} /><label>Administración de aeropuertos y helipuertos</label>
                            </div>
                            <div className="opcion">
                              <input id="Administración de puertos y muelles" type="checkbox" name="Administración de puertos y muelles" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=488310", "Administración de puertos y muelles", "#d58171")} /><label>Administración de puertos y muelles</label>
                            </div>
                            <div className="opcion">
                              <input id="Almacenamiento con refrigeración" type="checkbox" name="Almacenamiento con refrigeración" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=493120", "Almacenamiento con refrigeración", "#7dd2a8")} /><label>Almacenamiento con refrigeración</label>
                            </div>
                            <div className="opcion">
                              <input id="Almacenamiento de productos agrícolas que no requieren refrigeración" type="checkbox" name="Almacenamiento de productos agrícolas que no requieren refrigeración" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=493130", "Almacenamiento de productos agrícolas que no requieren refrigeración", "#e27ebf")} /><label>Almacenamiento de productos agrícolas que no requieren refrigeración</label>
                            </div>
                            <div className="opcion">
                              <input id="Almacenes generales de depósito" type="checkbox" name="Almacenes generales de depósito" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=493111", "Almacenes generales de depósito", "#7bd134")} /><label>Almacenes generales de depósito</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de autobuses con chofer" type="checkbox" name="Alquiler de autobuses con chofer" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=485510", "Alquiler de autobuses con chofer", "#e92351")} /><label>Alquiler de autobuses con chofer</label>
                            </div>
                            <div className="opcion">
                              <input id="Alquiler de automóviles con chofer" type="checkbox" name="Alquiler de automóviles con chofer" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=485320", "Alquiler de automóviles con chofer", "#79b1de")} /><label>Alquiler de automóviles con chofer</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte foráneo con refrigeración" type="checkbox" name="Autotransporte foráneo con refrigeración" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484233", "Autotransporte foráneo con refrigeración", "#49cbbe")} /><label>Autotransporte foráneo con refrigeración</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte foráneo de madera" type="checkbox" name="Autotransporte foráneo de madera" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484234", "Autotransporte foráneo de madera", "#5cc7cd")} /><label>Autotransporte foráneo de madera</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte foráneo de materiales para la construcción" type="checkbox" name="Autotransporte foráneo de materiales para la construcción" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484231", "Autotransporte foráneo de materiales para la construcción", "#ce5d97")} /><label>Autotransporte foráneo de materiales para la construcción</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte foráneo de materiales y residuos peligrosos" type="checkbox" name="Autotransporte foráneo de materiales y residuos peligrosos" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484232", "Autotransporte foráneo de materiales y residuos peligrosos", "#d012c0")} /><label>Autotransporte foráneo de materiales y residuos peligrosos</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte foráneo de productos agrícolas sin refrigeración" type="checkbox" name="Autotransporte foráneo de productos agrícolas sin refrigeración" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484121", "Autotransporte foráneo de productos agrícolas sin refrigeración", "#0fa6c8")} /><label>Autotransporte foráneo de productos agrícolas sin refrigeración</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte local con refrigeración" type="checkbox" name="Autotransporte local con refrigeración" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484223", "Autotransporte local con refrigeración", "#d91fb1")} /><label>Autotransporte local con refrigeración</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte local de madera" type="checkbox" name="Autotransporte local de madera" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484224", "Autotransporte local de madera", "#1fd5ab")} /><label>Autotransporte local de madera</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte local de materiales para la construcción" type="checkbox" name="Autotransporte local de materiales para la construcción" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484221", "Autotransporte local de materiales para la construcción", "#4ce283")} /><label>Autotransporte local de materiales para la construcción</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte local de materiales y residuos peligrosos" type="checkbox" name="Autotransporte local de materiales y residuos peligrosos" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484222", "Autotransporte local de materiales y residuos peligrosos", "#cb79dd")} /><label>Autotransporte local de materiales y residuos peligrosos</label>
                            </div>
                            <div className="opcion">
                              <input id="Autotransporte local de productos agrícolas sin refrigeración" type="checkbox" name="Autotransporte local de productos agrícolas sin refrigeración" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484111", "Autotransporte local de productos agrícolas sin refrigeración", "#6774cb")} /><label>Autotransporte local de productos agrícolas sin refrigeración</label>
                            </div>
                            <div className="opcion">
                              <input id="Otro autotransporte foráneo de carga especializado" type="checkbox" name="Otro autotransporte foráneo de carga especializado" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484239", "Otro autotransporte foráneo de carga especializado", "#df67e5")} /><label>Otro autotransporte foráneo de carga especializado</label>
                            </div>
                            <div className="opcion">
                              <input id="Otro autotransporte foráneo de carga general" type="checkbox" name="Otro autotransporte foráneo de carga general" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484129", "Otro autotransporte foráneo de carga general", "#55aed8")} /><label>Otro autotransporte foráneo de carga general</label>
                            </div>
                            <div className="opcion">
                              <input id="Otro autotransporte local de carga especializado" type="checkbox" name="Otro autotransporte local de carga especializado" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484229", "Otro autotransporte local de carga especializado", "#54e510")} /><label>Otro autotransporte local de carga especializado</label>
                            </div>
                            <div className="opcion">
                              <input id="Otro autotransporte local de carga general" type="checkbox" name="Otro autotransporte local de carga general" onClick={(e) => mostrarCapasDenue(e, "transportes", "codigo_act=484119", "Otro autotransporte local de carga general", "#e1b97d")} /><label>Otro autotransporte local de carga general</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="comercioalpormenor" type="checkbox" name="comercioalpormenor" />
                        <label>Comercio al por menor</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Comercio al por menor de aceites y grasas lubricantes, aditivos y similares para vehículos de motor" type="checkbox" name="Comercio al por menor de aceites y grasas lubricantes, aditivos y similares para vehículos de motor" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=468420", "Comercio al por menor de aceites y grasas lubricantes, aditivos y similares para vehículos de motor", "#7e0de1")} /><label>Comercio al por menor de aceites y grasas lubricantes, aditivos y similares para vehículos de motor</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de alfombras, cortinas, tapices y similares" type="checkbox" name="Comercio al por menor de alfombras, cortinas, tapices y similares" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=466311", "Comercio al por menor de alfombras, cortinas, tapices y similares", "#d58171")} /><label>Comercio al por menor de alfombras, cortinas, tapices y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de antigüedades y obras de arte" type="checkbox" name="Comercio al por menor de antigüedades y obras de arte" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=466313", "Comercio al por menor de antigüedades y obras de arte", "#7dd2a8")} /><label>Comercio al por menor de antigüedades y obras de arte</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos de joyería y relojes" type="checkbox" name="Comercio al por menor de artículos de joyería y relojes" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=465112", "Comercio al por menor de artículos de joyería y relojes", "#e27ebf")} /><label>Comercio al por menor de artículos de joyería y relojes</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos de mercería y bonetería" type="checkbox" name="Comercio al por menor de artículos de mercería y bonetería" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=463113", "Comercio al por menor de artículos de mercería y bonetería", "#7bd134")} /><label>Comercio al por menor de artículos de mercería y bonetería</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos de papelería" type="checkbox" name="Comercio al por menor de artículos de papelería" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=465311", "Comercio al por menor de artículos de papelería", "#e92351")} /><label>Comercio al por menor de artículos de papelería</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos de perfumería y cosméticos" type="checkbox" name="Comercio al por menor de artículos de perfumería y cosméticos" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=465111", "Comercio al por menor de artículos de perfumería y cosméticos", "#79b1de")} /><label>Comercio al por menor de artículos de perfumería y cosméticos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos desechables" type="checkbox" name="Comercio al por menor de artículos desechables" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=465914", "Comercio al por menor de artículos desechables", "#49cbbe")} /><label>Comercio al por menor de artículos desechables</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos ortopédicos" type="checkbox" name="Comercio al por menor de artículos ortopédicos" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=464122", "Comercio al por menor de artículos ortopédicos", "#5cc7cd")} /><label>Comercio al por menor de artículos ortopédicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos para albercas y otros artículos" type="checkbox" name="Comercio al por menor de artículos para albercas y otros artículos" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=467117", "Comercio al por menor de artículos para albercas y otros artículos", "#ce5d97")} /><label>Comercio al por menor de artículos para albercas y otros artículos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos para la limpieza" type="checkbox" name="Comercio al por menor de artículos para la limpieza" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=467115", "Comercio al por menor de artículos para la limpieza", "#d012c0")} /><label>Comercio al por menor de artículos para la limpieza</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos religiosos" type="checkbox" name="Comercio al por menor de artículos religiosos" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=465913", "Comercio al por menor de artículos religiosos", "#0fa6c8")} /><label>Comercio al por menor de artículos religiosos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos usados" type="checkbox" name="Comercio al por menor de artículos usados" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=466410", "Comercio al por menor de artículos usados", "#d91fb1")} /><label>Comercio al por menor de artículos usados</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de artículos y aparatos deportivos" type="checkbox" name="Comercio al por menor de artículos y aparatos deportivos" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=465215", "Comercio al por menor de artículos y aparatos deportivos", "#1fd5ab")} /><label>Comercio al por menor de artículos y aparatos deportivos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de automóviles y camionetas nuevos" type="checkbox" name="Comercio al por menor de automóviles y camionetas nuevos" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=468111", "Comercio al por menor de automóviles y camionetas nuevos", "#4ce283")} /><label>Comercio al por menor de automóviles y camionetas nuevos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de automóviles y camionetas usados" type="checkbox" name="Comercio al por menor de automóviles y camionetas usados" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=468112", "Comercio al por menor de automóviles y camionetas usados", "#cb79dd")} /><label>Comercio al por menor de automóviles y camionetas usados</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de bebidas no alcohólicas y hielo" type="checkbox" name="Comercio al por menor de bebidas no alcohólicas y hielo" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=461213", "Comercio al por menor de bebidas no alcohólicas y hielo", "#6774cb")} /><label>Comercio al por menor de bebidas no alcohólicas y hielo</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de bicicletas" type="checkbox" name="Comercio al por menor de bicicletas" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=465213", "Comercio al por menor de bicicletas", "#df67e5")} /><label>Comercio al por menor de bicicletas</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por menor de bisutería y accesorios de vestir" type="checkbox" name="Comercio al por menor de bisutería y accesorios de vestir" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=463215", "Comercio al por menor de bisutería y accesorios de vestir", "#55aed8")} /><label>Comercio al por menor de bisutería y accesorios de vestir</label>
                            </div>
                            <div className="opcion">
                              <input id="Farmacias con minisúper" type="checkbox" name="Farmacias con minisúper" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=464112", "Farmacias con minisúper", "#54e510")} /><label>Farmacias con minisúper</label>
                            </div>
                            <div className="opcion">
                              <input id="Farmacias sin minisúper" type="checkbox" name="Farmacias sin minisúper" onClick={(e) => mostrarCapasDenue(e, "comercioalpormenor", "codigo_act=464111", "Farmacias sin minisúper", "#e1b97d")} /><label>Farmacias sin minisúper</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="comercio_al_mayor" type="checkbox" name="comercio_al_mayor" />
                        <label>Comercio al por mayor</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Comercio al por mayor de abarrotes" type="checkbox" name="Comercio al por mayor de abarrotes" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=431110", "Comercio al por mayor de abarrotes", "#7e0de1")} /><label>Comercio al por mayor de abarrotes</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de artículos de joyería y relojes" type="checkbox" name="Comercio al por mayor de artículos de joyería y relojes" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=433220", "Comercio al por mayor de artículos de joyería y relojes", "#d58171")} /><label>Comercio al por mayor de artículos de joyería y relojes</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de artículos de papelería" type="checkbox" name="Comercio al por mayor de artículos de papelería" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=433410", "Comercio al por mayor de artículos de papelería", "#7dd2a8")} /><label>Comercio al por mayor de artículos de papelería</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de artículos de perfumería y cosméticos" type="checkbox" name="Comercio al por mayor de artículos de perfumería y cosméticos" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=433210", "Comercio al por mayor de artículos de perfumería y cosméticos", "#e27ebf")} /><label>Comercio al por mayor de artículos de perfumería y cosméticos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de artículos desechables" type="checkbox" name="Comercio al por mayor de artículos desechables" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=434240", "Comercio al por mayor de artículos desechables", "#7bd134")} /><label>Comercio al por mayor de artículos desechables</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de artículos y accesorios para diseño y pintura artística" type="checkbox" name="Comercio al por mayor de artículos y accesorios para diseño y pintura artística" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=", "Comercio al por mayor de artículos y accesorios para diseño y pintura artística", "#e92351")} /><label>Comercio al por mayor de artículos y accesorios para diseño y pintura artística</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de artículos y aparatos deportivos" type="checkbox" name="Comercio al por mayor de artículos y aparatos deportivos" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=433313", "Comercio al por mayor de artículos y aparatos deportivos", "#79b1de")} /><label>Comercio al por mayor de artículos y aparatos deportivos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de bebidas no alcohólicas y hielo" type="checkbox" name="Comercio al por mayor de bebidas no alcohólicas y hielo" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=431211", "Comercio al por mayor de bebidas no alcohólicas y hielo", "#49cbbe")} /><label>Comercio al por mayor de bebidas no alcohólicas y hielo</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de blancos" type="checkbox" name="Comercio al por mayor de blancos" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=432112", "Comercio al por mayor de blancos", "#5cc7cd")} /><label>Comercio al por mayor de blancos</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de botanas y frituras" type="checkbox" name="Comercio al por mayor de botanas y frituras" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=431192", "Comercio al por mayor de botanas y frituras", "#ce5d97")} /><label>Comercio al por mayor de botanas y frituras</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de calzado" type="checkbox" name="Comercio al por mayor de calzado" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=432130", "Comercio al por mayor de calzado", "#d012c0")} /><label>Comercio al por mayor de calzado</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de camiones" type="checkbox" name="Comercio al por mayor de camiones" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=436111", "Comercio al por mayor de camiones", "#0fa6c8")} /><label>Comercio al por mayor de camiones</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de carne de aves" type="checkbox" name="Comercio al por mayor de carne de aves" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=431122", "Comercio al por mayor de carne de aves", "#d91fb1")} /><label>Comercio al por mayor de carne de aves</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de carnes rojas" type="checkbox" name="Comercio al por mayor de carnes rojas" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=431121", "Comercio al por mayor de carnes rojas", "#1fd5ab")} /><label>Comercio al por mayor de carnes rojas</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de cemento, tabique y grava" type="checkbox" name="Comercio al por mayor de cemento, tabique y grava" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=434211", "Comercio al por mayor de cemento, tabique y grava", "#4ce283")} /><label>Comercio al por mayor de cemento, tabique y grava</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de cerveza" type="checkbox" name="Comercio al por mayor de cerveza" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=431213", "Comercio al por mayor de cerveza", "#cb79dd")} /><label>Comercio al por mayor de cerveza</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de cigarros, puros y tabaco" type="checkbox" name="Comercio al por mayor de cigarros, puros y tabaco" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=431220", "Comercio al por mayor de cigarros, puros y tabaco", "#6774cb")} /><label>Comercio al por mayor de cigarros, puros y tabaco</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de combustibles de uso industrial" type="checkbox" name="Comercio al por mayor de combustibles de uso industrial" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=434230", "Comercio al por mayor de combustibles de uso industrial", "#df67e5")} /><label>Comercio al por mayor de combustibles de uso industrial</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de conservas alimenticias" type="checkbox" name="Comercio al por mayor de conservas alimenticias" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=431193", "Comercio al por mayor de conservas alimenticias", "#55aed8")} /><label>Comercio al por mayor de conservas alimenticias</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de cueros y pieles" type="checkbox" name="Comercio al por mayor de cueros y pieles" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=432113", "Comercio al por mayor de cueros y pieles", "#54e510")} /><label>Comercio al por mayor de cueros y pieles</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercio al por mayor de desechos de papel y de cartón" type="checkbox" name="Comercio al por mayor de desechos de papel y de cartón" onClick={(e) => mostrarCapasDenue(e, "comercio_al_mayor", "codigo_act=434312", "Comercio al por mayor de desechos de papel y de cartón", "#e1b97d")} /><label>Comercio al por mayor de desechos de papel y de cartón</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="industrias_manufactureras" type="checkbox" name="industrias_manufactureras" />
                        <label>Industrias manufactureras</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Acabado de productos textiles" type="checkbox" name="Acabado de productos textiles" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=313310", "Acabado de productos textiles", "#7e0de1")} /><label>Acabado de productos textiles</label>
                            </div>
                            <div className="opcion">
                              <input id="Acuñación e impresión de monedas" type="checkbox" name="Acuñación e impresión de monedas" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=339911", "Acuñación e impresión de monedas", "#d58171")} /><label>Acuñación e impresión de monedas</label>
                            </div>
                            <div className="opcion">
                              <input id="Aserraderos integrados" type="checkbox" name="Aserraderos integrados" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=321111", "Aserraderos integrados", "#7dd2a8")} /><label>Aserraderos integrados</label>
                            </div>
                            <div className="opcion">
                              <input id="Aserrado de tablas y tablones" type="checkbox" name="Aserrado de tablas y tablones" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=321112", "Aserrado de tablas y tablones", "#e27ebf")} /><label>Aserrado de tablas y tablones</label>
                            </div>
                            <div className="opcion">
                              <input id="Beneficio del arroz" type="checkbox" name="Beneficio del arroz" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=311211", "Beneficio del arroz", "#7bd134")} /><label>Beneficio del arroz</label>
                            </div>
                            <div className="opcion">
                              <input id="Beneficio del café" type="checkbox" name="Beneficio del café" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=311921", "Beneficio del café", "#e92351")} /><label>Beneficio del café</label>
                            </div>
                            <div className="opcion">
                              <input id="Beneficio del tabaco" type="checkbox" name="Beneficio del tabaco" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=312210", "Beneficio del tabaco", "#79b1de")} /><label>Beneficio del tabaco</label>
                            </div>
                            <div className="opcion">
                              <input id="Complejos siderúrgicos" type="checkbox" name="Complejos siderúrgicos" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=331111", "Complejos siderúrgicos", "#49cbbe")} /><label>Complejos siderúrgicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección de cortinas, blancos y similares" type="checkbox" name="Confección de cortinas, blancos y similares" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=314120", "Confección de cortinas, blancos y similares", "#5cc7cd")} /><label>Confección de cortinas, blancos y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección de costales" type="checkbox" name="Confección de costales" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=314911", "Confección de costales", "#ce5d97")} /><label>Confección de costales</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección de otros accesorios y prendas de vestir no clasificados en otra parte" type="checkbox" name="Confección de otros accesorios y prendas de vestir no clasificados en otra parte" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315999", "Confección de otros accesorios y prendas de vestir no clasificados en otra parte", "#d012c0")} /><label>Confección de otros accesorios y prendas de vestir no clasificados en otra parte</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección de prendas de vestir de cuero, piel y de materiales sucedáneos" type="checkbox" name="Confección de prendas de vestir de cuero, piel y de materiales sucedáneos" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315210", "Confección de prendas de vestir de cuero, piel y de materiales sucedáneos", "#0fa6c8")} /><label>Confección de prendas de vestir de cuero, piel y de materiales sucedáneos</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección de prendas de vestir sobre medida" type="checkbox" name="Confección de prendas de vestir sobre medida" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315225", "Confección de prendas de vestir sobre medida", "#d91fb1")} /><label>Confección de prendas de vestir sobre medida</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección de productos de textiles recubiertos y de materiales sucedáneos" type="checkbox" name="Confección de productos de textiles recubiertos y de materiales sucedáneos" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=314912", "Confección de productos de textiles recubiertos y de materiales sucedáneos", "#1fd5ab")} /><label>Confección de productos de textiles recubiertos y de materiales sucedáneos</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección de sombreros y gorras" type="checkbox" name="Confección de sombreros y gorras" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315991", "Confección de sombreros y gorras", "#4ce283")} /><label>Confección de sombreros y gorras</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección en serie de camisas" type="checkbox" name="Confección en serie de camisas" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315222", "Confección en serie de camisas", "#cb79dd")} /><label>Confección en serie de camisas</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección en serie de disfraces y trajes típicos" type="checkbox" name="Confección en serie de disfraces y trajes típicos" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315224", "Confección en serie de disfraces y trajes típicos", "#6774cb")} /><label>Confección en serie de disfraces y trajes típicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección en serie de otra ropa exterior de materiales textiles" type="checkbox" name="Confección en serie de otra ropa exterior de materiales textiles" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315229", "Confección en serie de otra ropa exterior de materiales textiles", "#df67e5")} /><label>Confección en serie de otra ropa exterior de materiales textiles</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección en serie de ropa interior y de dormir" type="checkbox" name="Confección en serie de ropa interior y de dormir" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315221", "Confección en serie de ropa interior y de dormir", "#55aed8")} /><label>Confección en serie de ropa interior y de dormir</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección en serie de uniformes" type="checkbox" name="Confección en serie de uniformes" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=315223", "Confección en serie de uniformes", "#54e510")} /><label>Confección en serie de uniformes</label>
                            </div>
                            <div className="opcion">
                              <input id="Confección, bordado y deshilado de productos textiles" type="checkbox" name="Confección, bordado y deshilado de productos textiles" onClick={(e) => mostrarCapasDenue(e, "industrias_manufactureras", "codigo_act=314991", "Confección, bordado y deshilado de productos textiles", "#e1b97d")} /><label>Confección, bordado y deshilado de productos textiles</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="construccion" type="checkbox" name="construccion" />
                        <label>Construcción</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Colocación de muros falsos y aislamiento" type="checkbox" name="Colocación de muros falsos y aislamiento" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=238311", "Colocación de muros falsos y aislamiento", "#7e0de1")} /><label>Colocación de muros falsos y aislamiento</label>
                            </div>
                            <div className="opcion">
                              <input id="Colocación de pisos cerámicos y azulejos" type="checkbox" name="Colocación de pisos cerámicos y azulejos" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=238340", "Colocación de pisos cerámicos y azulejos", "#d58171")} /><label>Colocación de pisos cerámicos y azulejos</label>
                            </div>
                            <div className="opcion">
                              <input id="Colocación de pisos flexibles y de madera" type="checkbox" name="Colocación de pisos flexibles y de madera" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=238330", "Colocación de pisos flexibles y de madera", "#7dd2a8")} /><label>Colocación de pisos flexibles y de madera</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de carreteras, puentes y similares" type="checkbox" name="Construcción de carreteras, puentes y similares" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237312", "Construcción de carreteras, puentes y similares", "#e27ebf")} /><label>Construcción de carreteras, puentes y similares</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de obras de generación y conducción de energía eléctrica" type="checkbox" name="Construcción de obras de generación y conducción de energía eléctrica" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237131", "Construcción de obras de generación y conducción de energía eléctrica", "#7bd134")} /><label>Construcción de obras de generación y conducción de energía eléctrica</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de obras de urbanizaciónConstrucción de obras de urbanización" type="checkbox" name="Construcción de obras de urbanizaciónConstrucción de obras de urbanización" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237212", "Construcción de obras de urbanización", "#e92351")} /><label>Construcción de obras de urbanización</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de obras marítimas, fluviales y subacuáticas" type="checkbox" name="Construcción de obras marítimas, fluviales y subacuáticas" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237992", "Construcción de obras marítimas, fluviales y subacuáticas", "#79b1de")} /><label>Construcción de obras marítimas, fluviales y subacuáticas</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de obras para el tratamiento, distribución y suministro de agua y drenaje" type="checkbox" name="Construcción de obras para el tratamiento, distribución y suministro de agua y drenaje" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237111", "Construcción de obras para el tratamiento, distribución y suministro de agua y drenaje", "#49cbbe")} /><label>Construcción de obras para el tratamiento, distribución y suministro de agua y drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de obras para telecomunicaciones" type="checkbox" name="Construcción de obras para telecomunicaciones" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237132", "Construcción de obras para telecomunicaciones", "#5cc7cd")} /><label>Construcción de obras para telecomunicaciones</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de obras para transporte eléctrico y ferroviario" type="checkbox" name="Construcción de obras para transporte eléctrico y ferroviario" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237993", "Construcción de obras para transporte eléctrico y ferroviario", "#ce5d97")} /><label>Construcción de obras para transporte eléctrico y ferroviario</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de plantas de refinería y petroquímica" type="checkbox" name="Construcción de plantas de refinería y petroquímica" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237122", "Construcción de plantas de refinería y petroquímica", "#d012c0")} /><label>Construcción de plantas de refinería y petroquímica</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de presas y represas" type="checkbox" name="Construcción de presas y represas" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237991", "Construcción de presas y represas", "#0fa6c8")} /><label>Construcción de presas y represas</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de sistemas de distribución de petróleo y gas" type="checkbox" name="Construcción de sistemas de distribución de petróleo y gas" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237121", "Construcción de sistemas de distribución de petróleo y gas", "#d91fb1")} /><label>Construcción de sistemas de distribución de petróleo y gas</label>
                            </div>
                            <div className="opcion">
                              <input id="Construcción de sistemas de riego agrícola" type="checkbox" name="Construcción de sistemas de riego agrícola" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237112", "Construcción de sistemas de riego agrícola", "#1fd5ab")} /><label>Construcción de sistemas de riego agrícola</label>
                            </div>
                            <div className="opcion">
                              <input id="División de terrenos" type="checkbox" name="División de terrenos" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237211", "División de terrenos", "#4ce283")} /><label>División de terrenos</label>
                            </div>
                            <div className="opcion">
                              <input id="Edificación de inmuebles comerciales y de servicios, excepto la supervisión" type="checkbox" name="Edificación de inmuebles comerciales y de servicios, excepto la supervisión" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=236221", "Edificación de inmuebles comerciales y de servicios, excepto la supervisión", "#cb79dd")} /><label>Edificación de inmuebles comerciales y de servicios, excepto la supervisión</label>
                            </div>
                            <div className="opcion">
                              <input id="Edificación de naves y plantas industriales, excepto la supervisión" type="checkbox" name="Edificación de naves y plantas industriales, excepto la supervisión" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=236211", "Edificación de naves y plantas industriales, excepto la supervisión", "#6774cb")} /><label>Edificación de naves y plantas industriales, excepto la supervisión</label>
                            </div>
                            <div className="opcion">
                              <input id="Edificación de vivienda multifamiliar" type="checkbox" name="Edificación de vivienda multifamiliar" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=236112", "Edificación de vivienda multifamiliar", "#df67e5")} /><label>Edificación de vivienda multifamiliar</label>
                            </div>
                            <div className="opcion">
                              <input id="Edificación de vivienda unifamiliar" type="checkbox" name="Edificación de vivienda unifamiliar" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=236111", "Edificación de vivienda unifamiliar", "#55aed8")} /><label>Edificación de vivienda unifamiliar</label>
                            </div>
                            <div className="opcion">
                              <input id="Instalación de señalamientos y protecciones en obras viales" type="checkbox" name="Instalación de señalamientos y protecciones en obras viales" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=237311", "Instalación de señalamientos y protecciones en obras viales", "#54e510")} /><label>Instalación de señalamientos y protecciones en obras viales</label>
                            </div>
                            <div className="opcion">
                              <input id="Instalaciones de sistemas centrales de aire acondicionado y calefacción" type="checkbox" name="Instalaciones de sistemas centrales de aire acondicionado y calefacción" onClick={(e) => mostrarCapasDenue(e, "construccion", "codigo_act=238222", "Instalaciones de sistemas centrales de aire acondicionado y calefacción", "#e1b97d")} /><label>Instalaciones de sistemas centrales de aire acondicionado y calefacción</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="energia_electrica" type="checkbox" name="energia_electrica" />
                        <label>Generación, transmisión y distribución de energía eléctrica, suministro de agua y de gas por ductos al consumidor final</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Captación, tratamiento y suministro de agua realizados por el sector privado" type="checkbox" name="Captación, tratamiento y suministro de agua realizados por el sector privado" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221311", "Captación, tratamiento y suministro de agua realizados por el sector privado", "#7e0de1")} /><label>Captación, tratamiento y suministro de agua realizados por el sector privado</label>
                            </div>
                            <div className="opcion">
                              <input id="Captación, tratamiento y suministro de agua realizados por el sector público" type="checkbox" name="Captación, tratamiento y suministro de agua realizados por el sector público" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221312", "Captación, tratamiento y suministro de agua realizados por el sector público", "#d58171")} /><label>Captación, tratamiento y suministro de agua realizados por el sector público</label>
                            </div>
                            <div className="opcion">
                              <input id="Comercialización de energía eléctrica" type="checkbox" name="Comercialización de energía eléctrica" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221123", "Comercialización de energía eléctrica", "#7dd2a8")} /><label>Comercialización de energía eléctrica</label>
                            </div>
                            <div className="opcion">
                              <input id="Distribución de energía eléctrica" type="checkbox" name="Distribución de energía eléctrica" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221122", "Distribución de energía eléctrica", "#e27ebf")} /><label>Distribución de energía eléctrica</label>
                            </div>
                            <div className="opcion">
                              <input id="Generación de electricidad a partir de combustibles fósiles" type="checkbox" name="Generación de electricidad a partir de combustibles fósiles" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221111", "Generación de electricidad a partir de combustibles fósiles", "#7bd134")} /><label>Generación de electricidad a partir de combustibles fósiles</label>
                            </div>
                            <div className="opcion">
                              <input id="Generación de electricidad a partir de energía eólica" type="checkbox" name="Generación de electricidad a partir de energía eólica" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221114", "Generación de electricidad a partir de energía eólica", "#e92351")} /><label>Generación de electricidad a partir de energía eólica</label>
                            </div>
                            <div className="opcion">
                              <input id="Generación de electricidad a partir de energía hidráulica" type="checkbox" name="Generación de electricidad a partir de energía hidráulica" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221112", "Generación de electricidad a partir de energía hidráulica", "#79b1de")} /><label>Generación de electricidad a partir de energía hidráulica</label>
                            </div>
                            <div className="opcion">
                              <input id="Generación de electricidad a partir de energía solar" type="checkbox" name="Generación de electricidad a partir de energía solar" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221113", "Generación de electricidad a partir de energía solar", "#49cbbe")} /><label>Generación de electricidad a partir de energía solar</label>
                            </div>
                            <div className="opcion">
                              <input id="Generación de electricidad a partir de otro tipo de energía" type="checkbox" name="Generación de electricidad a partir de otro tipo de energía" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221119", "Generación de electricidad a partir de otro tipo de energía", "#5cc7cd")} /><label>Generación de electricidad a partir de otro tipo de energía</label>
                            </div>
                            <div className="opcion">
                              <input id="Suministro de gas natural por ductos al consumidor final" type="checkbox" name="Suministro de gas natural por ductos al consumidor final" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221210", "Suministro de gas natural por ductos al consumidor final", "#ce5d97")} /><label>Suministro de gas natural por ductos al consumidor final</label>
                            </div>
                            <div className="opcion">
                              <input id="Transmisión de energía eléctrica" type="checkbox" name="Transmisión de energía eléctrica" onClick={(e) => mostrarCapasDenue(e, "energia_electrica", "codigo_act=221121", "Transmisión de energía eléctrica", "#d012c0")} /><label>Transmisión de energía eléctrica</label>
                            </div>


                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="mineria" type="checkbox" name="mineria" />
                        <label>Minería</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Extracción de gas natural no asociado" type="checkbox" name="Extracción de gas natural no asociado" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=211112", "Extracción de gas natural no asociado", "#7e0de1")} /><label>Extracción de gas natural no asociado</label>
                            </div>
                            <div className="opcion">
                              <input id="Extracción de petróleo y gas natural asociado" type="checkbox" name="Extracción de petróleo y gas natural asociado" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=211111", "Extracción de petróleo y gas natural asociado", "#d58171")} /><label>Extracción de petróleo y gas natural asociado</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de arena y grava para la construcción" type="checkbox" name="Minería de arena y grava para la construcción" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212321", "Minería de arena y grava para la construcción", "#7dd2a8")} /><label>Minería de arena y grava para la construcción</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de azufre" type="checkbox" name="Minería de azufre" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212397", "Minería de azufre", "#e27ebf")} /><label>Minería de azufre</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de barita" type="checkbox" name="Minería de barita" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212393", "Minería de barita", "#7bd134")} /><label>Minería de barita</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de caolín" type="checkbox" name="Minería de caolín" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212325", "Minería de caolín", "#e92351")} /><label>Minería de caolín</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de carbón mineral" type="checkbox" name="Minería de carbón mineral" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212110", "Minería de carbón mineral", "#79b1de")} /><label>Minería de carbón mineral</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de cobre" type="checkbox" name="Minería de cobre" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212231", "Minería de cobre", "#49cbbe")} /><label>Minería de cobre</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de feldespato" type="checkbox" name="Minería de feldespato" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212323", "Minería de feldespato", "#5cc7cd")} /><label>Minería de feldespato</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de fluorita" type="checkbox" name="Minería de fluorita" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212395", "Minería de fluorita", "#ce5d97")} /><label>Minería de fluorita</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de grafito" type="checkbox" name="Minería de grafito" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=", "Minería de grafito", "#d012c0")} /><label>Minería de grafito</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de hierro" type="checkbox" name="Minería de hierro" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212210", "Minería de hierro", "#0fa6c8")} /><label>Minería de hierro</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de manganeso" type="checkbox" name="Minería de manganeso" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212291", "Minería de manganeso", "#d91fb1")} /><label>Minería de manganeso</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de mármol" type="checkbox" name="Minería de mármol" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212312", "Minería de mármol", "#1fd5ab")} /><label>Minería de mármol</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de mercurio y antimonio" type="checkbox" name="Minería de mercurio y antimonio" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212292", "Minería de mercurio y antimonio", "#4ce283")} /><label>Minería de mercurio y antimonio</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de minerales no metálicos para productos químicos" type="checkbox" name="Minería de minerales no metálicos para productos químicos" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212398", "Minería de minerales no metálicos para productos químicos", "#cb79dd")} /><label>Minería de minerales no metálicos para productos químicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de oro" type="checkbox" name="Minería de oro" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212221", "Minería de oro", "#6774cb")} /><label>Minería de oro</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de otras arcillas y de otros minerales refractarios" type="checkbox" name="Minería de otras arcillas y de otros minerales refractarios" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212329", "Minería de otras arcillas y de otros minerales refractarios", "#df67e5")} /><label>Minería de otras arcillas y de otros minerales refractarios</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de otras piedras dimensionadas" type="checkbox" name="Minería de otras piedras dimensionadas" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212319", "Minería de otras piedras dimensionadas", "#55aed8")} /><label>Minería de otras piedras dimensionadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de otros minerales metálicos" type="checkbox" name="Minería de otros minerales metálicos" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212299", "Minería de otros minerales metálicos", "#54e510")} /><label>Minería de otros minerales metálicos</label>
                            </div>
                            <div className="opcion">
                              <input id="Minería de otros minerales no metálicos" type="checkbox" name="Minería de otros minerales no metálicos" onClick={(e) => mostrarCapasDenue(e, "mineria", "codigo_act=212399", "Minería de otros minerales no metálicos", "#e1b97d")} /><label>Minería de otros minerales no metálicos</label>
                            </div>

                          </li>
                        </ul>

                      </li>
                      <li>
                        <input id="agricultura_caza_pezca" type="checkbox" name="agricultura_caza_pezca" />
                        <label>Agricultura, cría y explotación de animales, aprovechamiento forestal, pesca y caza.</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Beneficio de productos agrícolas" type="checkbox" name="Beneficio de productos agrícolas" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=115113", "Beneficio de productos agrícolas", "#7e0de1")} /><label>Beneficio de productos agrícolas</label>
                            </div>
                            <div className="opcion">
                              <input id="Camaronicultura" type="checkbox" name="Camaronicultura" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=112511", "Camaronicultura", "#d58171")} /><label>Camaronicultura</label>
                            </div>
                            <div className="opcion">
                              <input id="Despepite de algodón" type="checkbox" name="Despepite de algodón" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=115112", "Despepite de algodón", "#7dd2a8")} /><label>Despepite de algodón</label>
                            </div>
                            <div className="opcion">
                              <input id="Otros servicios relacionados con la agricultura" type="checkbox" name="Otros servicios relacionados con la agricultura" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=115119", "Otros servicios relacionados con la agricultura", "#e27ebf")} /><label>Otros servicios relacionados con la agricultura</label>
                            </div>
                            <div className="opcion">
                              <input id="Pesca de camarón" type="checkbox" name="Pesca de camarón" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=114111", "Pesca de camarón", "#7bd134")} /><label>Pesca de camarón</label>
                            </div>
                            <div className="opcion">
                              <input id="Pesca de sardina y anchoveta" type="checkbox" name="Pesca de sardina y anchoveta" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=114113", "Pesca de sardina y anchoveta", "#e92351")} /><label>Pesca de sardina y anchoveta</label>
                            </div>
                            <div className="opcion">
                              <input id="Pesca de túnidos" type="checkbox" name="Pesca de túnidos" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=114112", "Pesca de túnidos", "#79b1de")} /><label>Pesca de túnidos</label>
                            </div>
                            <div className="opcion">
                              <input id="Pesca y captura de peces, crustáceos, moluscos y otras especies" type="checkbox" name="Pesca y captura de peces, crustáceos, moluscos y otras especies" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=114119", "Pesca y captura de peces, crustáceos, moluscos y otras especies", "#49cbbe")} /><label>Pesca y captura de peces, crustáceos, moluscos y otras especies</label>
                            </div>
                            <div className="opcion">
                              <input id="Piscicultura y otra acuicultura, excepto camaronicultura" type="checkbox" name="Piscicultura y otra acuicultura, excepto camaronicultura" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=112512", "Piscicultura y otra acuicultura, excepto camaronicultura", "#5cc7cd")} /><label>Piscicultura y otra acuicultura, excepto camaronicultura</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios de fumigación agrícola" type="checkbox" name="Servicios de fumigación agrícola" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=115111", "Servicios de fumigación agrícola", "#ce5d97")} /><label>Servicios de fumigación agrícola</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios relacionados con el aprovechamiento forestal" type="checkbox" name="Servicios relacionados con el aprovechamiento forestal" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=115310", "Servicios relacionados con el aprovechamiento forestal", "#d012c0")} /><label>Servicios relacionados con el aprovechamiento forestal</label>
                            </div>
                            <div className="opcion">
                              <input id="Servicios relacionados con la cría y explotación de animales" type="checkbox" name="Servicios relacionados con la cría y explotación de animales" onClick={(e) => mostrarCapasDenue(e, "agricultura_caza_pezca", "codigo_act=115210", "Servicios relacionados con la cría y explotación de animales", "#0fa6c8")} /><label>Servicios relacionados con la cría y explotación de animales</label>
                            </div>
                          </li>
                        </ul>

                      </li>


                    </ul>








                  </CardBody>
                </Card>
              </Collapse>

              <Button
                className="btnS3 btn-secondary"
                onClick={() => {
                  setColapsarPob(!colapsarPob)
                  //setColapsarCB(!colapsarCB);
                }}
                style={{ marginBottom: "1rem" }}
              >
                Información Sociodemografica
              </Button>
              <Collapse isOpen={colapsarPob}>
                <Card>
                  <CardBody className="cardM">


                    <div className="row">
                      <div className="col-3">Entidad</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={selectEntidad}
                          name="entidad"
                          options={estadosD}
                        //value={anioModernizacion}
                        />
                      </div>
                    </div>

                    <br></br>

                    <ul id="menu">
                      <li>
                        <input id="poblacion" type="checkbox" name="poblacion" /><label>Población</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion Masculina" type="checkbox" name="PoblacionM" onClick={(e) => mostrarCapaIter(e, "", "pobmas>0", "Poblacion Masculina", "getPoblacionM")} /><label>Población Masculina</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion Femenina" type="checkbox" name="PoblacionF" onClick={(e) => mostrarCapaIter(e, "", "pobfem>0", "Poblacion Femenina", "getPoblacionF")} /><label>Población Femenina</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion Total" type="checkbox" name="PoblacionT" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Población Total", "getPoblacionT")} /><label>Población Total</label>
                            </div>

                          </li>
                        </ul>
                      </li>


                      <li>
                        <input id="estructura" type="checkbox" name="estructura" /><label>Estructura por Edad y Sexo</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion masculina de 18 años y mas" type="checkbox" name="PoblacionMas18mas" onClick={(e) => mostrarCapaIter(e, "", "p_18ymas_m>0", "Poblacion masculina de 18 años y mas", "getPoblacionMas18mas")} /><label>Población masculina de 18 años y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 18 años y mas" type="checkbox" name="PoblacionFem18mas" onClick={(e) => mostrarCapaIter(e, "", "p_18ymas_f>0", "Poblacion femenina de 18 años y mas", "getPoblacionFem18mas")} /><label>Población femenina de 18 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 18 años y mas" type="checkbox" name="PoblacionDe18mas" onClick={(e) => mostrarCapaIter(e, "", "p_18ymas>0", "Poblacion de 18 años y mas", "getPoblacionDe18mas")} /><label>Población de 18 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas" type="checkbox" name="PoblacionMas15mas" onClick={(e) => mostrarCapaIter(e, "", "p_15ymas_m>0", " Poblacion masculina de 15 años y mas", "getPoblacionMas15mas")} /><label>Población masculina de 15 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas" type="checkbox" name="PoblacionFem15mas" onClick={(e) => mostrarCapaIter(e, "", "p_15ymas_f>0", "Poblacion femenina de 15 años y mas ", "getPoblacionFem15mas")} /><label>Población femenina de 15 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas" type="checkbox" name="PoblacionDe15mas" onClick={(e) => mostrarCapaIter(e, "", "p_15ymas>0", "Poblacion de 15 años y mas", "getPoblacionDe15mas")} /><label>Población de 15 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas" type="checkbox" name="PoblacionMas12mas" onClick={(e) => mostrarCapaIter(e, "", "p_12ymas_m>0", "Poblacion masculina de 12 años y mas", "getPoblacionMas12mas")} /><label>Población masculina de 12 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas" type="checkbox" name="PoblacionFem12mas" onClick={(e) => mostrarCapaIter(e, "", "p_12ymas_f>0", "Poblacion femenina de 12 años y mas", "getPoblacionFem12mas")} /><label>Población femenina de 12 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas" type="checkbox" name="PoblacionDe12mas" onClick={(e) => mostrarCapaIter(e, "", "p_12ymas>0", "Poblacion de 12 años y mas", "getPoblacionDe12mas")} /><label>Población de 12 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 5 años y mas" type="checkbox" name="PoblacionMas5mas" onClick={(e) => mostrarCapaIter(e, "", "p_5ymas>0", "Poblacion masculina de 5 años y mas", "getPoblacionMas5mas")} /><label>Población masculina de 5 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 5 años y mas" type="checkbox" name="PoblacionFem5mas" onClick={(e) => mostrarCapaIter(e, "", "p_15ymas_f>0", "Poblacion femenina de 5 años y mas", "getPoblacionFem5mas")} /><label>Población femenina de 5 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas" type="checkbox" name="PoblacionDe5mas" onClick={(e) => mostrarCapaIter(e, "", "p_5ymas>0 ", "Poblacion de 5 años y mas", "getPoblacionDe5mas")} /><label>Población de 5 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 años y mas" type="checkbox" name="PoblacionMas3mas" onClick={(e) => mostrarCapaIter(e, "", "p_3ymas_m>0", "Poblacion masculina de 3 años y mas", "getPoblacionMas3mas")} /><label>Población masculina de 3 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 años y mas" type="checkbox" name="PoblacionFem3mas" onClick={(e) => mostrarCapaIter(e, "", "p_3ymas_f>0", " Poblacion femenina de 3 años y mas", "getPoblacionDe3masFem")} /><label>Población femenina de 3 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 3 años y mas" type="checkbox" name="PoblacionDe3mas" onClick={(e) => mostrarCapaIter(e, "", "p_3ymas>0", "Poblacion de 3 años y mas", "getPoblacionDe3mas")} /><label>Población de 3 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 0 a 2" type="checkbox" name="PoblacionFde0a2" onClick={(e) => mostrarCapaIter(e, "", "p_0a2_f>0", "Poblacion femenina de 0 a 2", "getPoblacionFde0a2")} /><label>Población femenina de 0 a 2</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 0 a 2" type="checkbox" name="PoblacionMde0a2" onClick={(e) => mostrarCapaIter(e, "", "p_0a2_m>0", "Poblacion masculina de 0 a 2", "getPob0a2Mas")} /><label>Población masculina de 0 a 2</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 0 a 2" type="checkbox" name="PoblacionDe0a2" onClick={(e) => mostrarCapaIter(e, "", "p_0a2>0", "Poblacion de 0 a 2", "getPoblacionDe0a2")} /><label>Población de 0 a 2</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 3 a 5" type="checkbox" name="PoblacionDe3a5" onClick={(e) => mostrarCapaIter(e, "", "p_3a5>0", "Poblacion de 3 a 5", "getPoblacionDe3a5")} /><label>Población de 3 a 5</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 a 5" type="checkbox" name="PoblacionFde3a5" onClick={(e) => mostrarCapaIter(e, "", "p_3a5_f>0", "Poblacion femenina de 3 a 5", "getPoblacionFde3a5")} /><label>Población femenina de 3 a 5</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 a 5" type="checkbox" name="PoblacionMde3a5" onClick={(e) => mostrarCapaIter(e, "", "p_3a5_m>0", "Poblacion masculina de 3 a 5", "getPoblacionMde3a5")} /><label>Población masculina de 3 a 5</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 6 a 11" type="checkbox" name="PoblacionDe6a11" onClick={(e) => mostrarCapaIter(e, "", "p_6a11>0", "Poblacion de 6 a 11", "getPoblacionDe6a11")} /><label>Población de 6 a 11</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 6 a 11" type="checkbox" name="PoblacionFde6a11" onClick={(e) => mostrarCapaIter(e, "", "p_6a11_f>0", "Poblacion femenina de 6 a 11", "getPoblacionFde6a11")} /><label>Población femenina de 6 a 11</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 6 a 11" type="checkbox" name="PoblacionMde6a11" onClick={(e) => mostrarCapaIter(e, "", "p_6a11_m>0", "Poblacion masculina de 6 a 11", "getPoblacionMde6a11")} /><label>Población masculina de 6 a 11</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 8 a 14" type="checkbox" name="PoblacionDe8a14" onClick={(e) => mostrarCapaIter(e, "", "p_8a14>0", "Poblacion de 4 a 14", "getPoblacionDe8a14")} /><label>Población de 8 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 8 a 14" type="checkbox" name="PoblacionFde8a14" onClick={(e) => mostrarCapaIter(e, "", "p_8a14_f>0", "Poblacion femenina de 8 a 14", "getPoblacionFde8a14")} /><label>Población femenina de 8 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 8 a 14" type="checkbox" name="PoblacionMde4a14" onClick={(e) => mostrarCapaIter(e, "", "p_8a14_m>0", "Poblacion masculina de 8 a 14", "getPoblacionMde8a14")} /><label>Población masculina de 8 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 a 14" type="checkbox" name="PoblacionDe12a14" onClick={(e) => mostrarCapaIter(e, "", "p_12a14>0", "Poblacion de 12 a 14", "getPoblacionDe12a14")} /><label>Población de 12 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 a 14" type="checkbox" name="PoblacionFde12a14" onClick={(e) => mostrarCapaIter(e, "", "p_12a14_f>0", "Poblacion femenina de 12 a 14", "getPoblacionFde121a14")} /><label>Población femenina de 12 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 a 14" type="checkbox" name="PoblacionMde12a14" onClick={(e) => mostrarCapaIter(e, "", "p_12a14_m>0", "Poblacion masculina de 12 a 14", "getPoblacionMde12a14")} /><label>Población masculina de 12 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 a 17" type="checkbox" name="PoblacionDe15a17" onClick={(e) => mostrarCapaIter(e, "", "p_15a17>0", "Poblacion de 15 a 17", "getPoblacionDe15a17")} /><label>Población de 15 a 17</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 a 17" type="checkbox" name="PoblacionFde15a17" onClick={(e) => mostrarCapaIter(e, "", "p_15a17_f>0", "Poblacion femenina de 15 a 17", "getPoblacionFde151a17")} /><label>Población femenina de 15 a 17</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 a 17" type="checkbox" name="PoblacionMde15a17" onClick={(e) => mostrarCapaIter(e, "", "p_15a17_m>0", "Poblacion masculina de 15 a 17", "getPoblacionMde15a17")} /><label>Población masculina de 15 a 17</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 18 a 24" type="checkbox" name="PoblacionDe18a24" onClick={(e) => mostrarCapaIter(e, "", "p_18a24>0", "Poblacion de 18 a 24", "getPoblacionDe18a24")} /><label>Población de 18 a 24</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 18 a 24" type="checkbox" name="PoblacionFde18a24" onClick={(e) => mostrarCapaIter(e, "", "p_18a24_f>0", "Poblacion femenina de 18 a 24", "getPoblacionFde18a24")} /><label>Población femenina de 18 a 24</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 18 a 24" type="checkbox" name="PoblacionMde18a24" onClick={(e) => mostrarCapaIter(e, "", "p_18a24_m>0", "Poblacion masculina de 18 a 24", "getPoblacionMde18a24")} /><label>Población masculina de 18 a 24</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 a 49" type="checkbox" name="PoblacionFde15a49" onClick={(e) => mostrarCapaIter(e, "", "p_15a49_f>0", "Poblacion femenina de 15 a 49", "getPoblacionFde15a49")} /><label>Población femenina de 15 a 49</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 60 años y mas" type="checkbox" name="PoblacionDe60mas" onClick={(e) => mostrarCapaIter(e, "", "p_60ymas>0", "Poblacion de 60 años y mas", "getPoblacionDe60mas")} /><label>Población de 60 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 60 años y mas" type="checkbox" name="PoblacionFde60mas" onClick={(e) => mostrarCapaIter(e, "", "p_60ymas_f>0", "Poblacion femenina de 60 años y mas", "getPoblacionFde60mas")} /><label>Poblacion femenina de 60 años y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 60 años y mas" type="checkbox" name="PoblacionMde60mas" onClick={(e) => mostrarCapaIter(e, "", "p_60ymas_m>0", "Poblacion masculina 60 años y mas", "getPoblacionMde60mas")} /><label>Población masculina de 60 años y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Relacion hombres-mujeres" type="checkbox" name="Relacionhm" onClick={(e) => mostrarCapaIter(e, "", "rel_h_m>0", "Relacion hombres-mujeres", "getRelacionhm")} /><label>Relación hombres-mujeres</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 0 a 14 años" type="checkbox" name="PoblacionDe0a14" onClick={(e) => mostrarCapaIter(e, "", "p_0a4>0", "Poblacion de 0 a 14 años", "getPoblacionDe0a14")} /><label>Población de 0 a 4 años </label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 a 64 años" type="checkbox" name="PoblacionDe15a64" onClick={(e) => mostrarCapaIter(e, "", "pob15_64>0", "Poblacion de 15 a 64 años", "getPoblacionDe15a64")} /><label>Población de 15 a 64 años </label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 65 años y mas" type="checkbox" name="PoblacionDe65mas" onClick={(e) => mostrarCapaIter(e, "", "pob65_mas>0", "Poblacion de 65 años y mas", "getPoblacionDe65mas")} /><label>Poblacion de 65 años y mas</label>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <input id="Fecundidad" type="checkbox" name="Fecundidad" /><label>Fecundidad</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Promedio de hijas e hijos nacidos vivos" type="checkbox" name="Promediohnv" onClick={(e) => mostrarCapaIter(e, "", "prom_hnv>0", "Promedio de hijas e hijos nacidos vivos", "getPromediohnv")} /><label>Promedio de hijas e hijos nacidos vivos</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Migracion" type="checkbox" name="Migracion" /><label>Migración</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion nacida en la entidad" type="checkbox" name="Poblacionne" onClick={(e) => mostrarCapaIter(e, "", "pnacent>0", "Poblacion nacida en la entidad", "getPoblacionne")} /><label>Población nacida en la entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion fenenina nacida en la entidad" type="checkbox" name="PoblacionFne" onClick={(e) => mostrarCapaIter(e, "", "pnacent_f>0", "Poblacion femenina nacida en la entidad", "getPoblacionFne")} /><label>Población femenina nacida en la entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina nacida en la entidad" type="checkbox" name="PoblacionMne" onClick={(e) => mostrarCapaIter(e, "", "pnacent_m>0", "Poblacion masculina nacida en la entidad", "getPoblacionMne")} /><label>Población masculina nacida en la entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion nacida en otra entidad" type="checkbox" name="PoblacionOen" onClick={(e) => mostrarCapaIter(e, "", "pnacoe>0", "Poblacion nacida en otra entidad", "getPoblacionOen")} /><label>Población nacida en otra entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina nacida en otra entidad" type="checkbox" name="PoblacionFoen" onClick={(e) => mostrarCapaIter(e, "", "pnacoe_f>0", "Poblacion femenina nacida en otra entidad", "getPoblacionFOen")} /><label>Población femenina nacida en otra entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina nacida en otra entidad" type="checkbox" name="PoblacionMoen" onClick={(e) => mostrarCapaIter(e, "", "pnacoe_m>0", "Poblacion masculina nacida en otra entidad", "getPoblacionmOen")} /><label>Población masculina nacida en otra entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas residente en la entidad en marzo de 2015 " type="checkbox" name="Poblacion5marz2015" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 5 años y mas residente en la entidad en marzo de 2015", "getPoblacion5marz2015")} /><label>Población de 5 años y más residente en la entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 5 años y mas residente en la entidad en marzo de 2015" type="checkbox" name="PoblacionF5marz2015" onClick={(e) => mostrarCapaIter(e, "", "pnacoe>0", "Poblacion femenina de 5 años y mas residente en la entidad en marzo de 2015", "getPoblacionF5marz2015")} /><label>Población femenina de 5 años y mas residente en la entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 5 años y mas residente en la entidad en marzo de 2015" type="checkbox" name="PoblacionM5marz2015" onClick={(e) => mostrarCapaIter(e, "", "pres2015_m>0", "Poblacion masculina de 5 años y mas residente en la entidad en marzo de 2015", "getPoblacionM5marz2015")} /><label>Población masculina de 5 años y mas residente en la entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas residente en otra entidad en marzo de 2015 " type="checkbox" name="PoblacionOtra2015" onClick={(e) => mostrarCapaIter(e, "", "presoe15>0", "Poblacion de 5 años y mas residente en otra entidad en marzo de 2015", "getPob2")} /><label>Población de 5 años y más residente en otra entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 5 años y mas residente en otra entidad en marzo de 2015" type="checkbox" name="PoblacionFotra2015" onClick={(e) => mostrarCapaIter(e, "", "presoe15_f>0", "Poblacion femenina de 5 años y mas residente en otra entidad en marzo de 2015", "getPoblacionFotra2015")} /><label>Población femenina de 5 años y mas residente en otra entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 5 años y mas residente en otra entidad en marzo de 2015" type="checkbox" name="PoblacionMotra2015" onClick={(e) => mostrarCapaIter(e, "", "pres2015_m>0", "Poblacion masculina de 5 años y mas residente en otra entidad en marzo de 2015", "getPoblacionMotra2015")} /><label>Población masculina de 5 años y mas residente en otra entidad en marzo de 2015</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Etnicidad" type="checkbox" name="Etnicidad" /><label>Etnicidad</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion de 3 años y mas que habla alguna lengua indigena" type="checkbox" name="Pob3indigena" onClick={(e) => mostrarCapaIter(e, "", "p3ym_hli>0", "Poblacion de 3 años y mas que habla alguna lengua indigena", "getPob3indigena")} /><label>Población de 3 años y más que habla alguna lengua indígena</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 años y mas que habla alguna lengua indigena" type="checkbox" name="PobF3indigena" onClick={(e) => mostrarCapaIter(e, "", "p3ym_hli_f>0", "Poblacion femenina de 3 años y mas que habla alguna lengua indigena", "getPobF3indigena")} /><label>Población femenina de 3 años y más que habla alguna lengua indígena</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 años y mas que habla alguna lengua indigena" type="checkbox" name="PobM3indigena" onClick={(e) => mostrarCapaIter(e, "", "p3ym_hli_m>0", "Poblacion masculina de 3 años y mas que habla alguna lengua indigena", "getPobM3indigena")} /><label>Población masculina de 3 años y más que habla alguna lengua indígena</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 3 años y mas que habla alguna lengua indigena y no habla español" type="checkbox" name="Pob3indigesp" onClick={(e) => mostrarCapaIter(e, "", "p3hlinhe>0", "Poblacion de 3 años y mas que habla alguna lengua indigena y no habla español", "getPob3indigesp")} /><label>Población de 3 años y más que habla alguna lengua indígena y no habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 años y mas que habla alguna lengua indigena y no habla español" type="checkbox" name="PobF3indigesp" onClick={(e) => mostrarCapaIter(e, "", "p3hlinhe_f>0", "Poblacion femenina de 3 años y mas que habla alguna lengua indigena no habla español", "getPobF3indigesp")} /><label>Población femenina de 3 años y más que habla alguna lengua indígena y no habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 años y mas que habla alguna lengua indigena y no habla español" type="checkbox" name="PobM3indigesp" onClick={(e) => mostrarCapaIter(e, "", "p3hlinhe_m>0", "Poblacion masculina de 3 años y mas que habla alguna lengua indigena y no habla español", "getPobM3indigesp")} /><label>Población masculina de 3 años y más que habla alguna lengua indígena y no habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 3 años y mas que habla alguna lengua indigena y habla español" type="checkbox" name="Pob3indigyesp" onClick={(e) => mostrarCapaIter(e, "", "p3hli_he>0", "Poblacion de 3 años y mas que habla alguna lengua indigena y habla español", "getPob3indigyesp")} /><label>Población de 3 años y más que habla alguna lengua indígena y habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 años y mas que habla alguna lengua indigena y habla español" type="checkbox" name="PobF3indigyesp" onClick={(e) => mostrarCapaIter(e, "", "p3hli_he_f>0", "Poblacion femenina de 3 años y mas que habla alguna lengua indigena y habla español", "getPobF3indigyesp")} /><label>Población femenina de 3 años y más que habla alguna lengua indígena y habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 años y mas que habla alguna lengua indigena y habla español" type="checkbox" name="PobM3indigyesp" onClick={(e) => mostrarCapaIter(e, "", "p3hli_he_m>0", "Poblacion masculina de 3 años y mas que habla alguna lengua indigena y habla español", "getPobM3indigyesp")} /><label>Población masculina de 3 años y más que habla alguna lengua indígena y habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas que habla alguna lengua indigena" type="checkbox" name="Pob5indigena" onClick={(e) => mostrarCapaIter(e, "", "p5_hli>0", "Poblacion de 5 años y mas que habla alguna lengua indigena", "getPob5indigena")} /><label>Población de 5 años y más que habla alguna lengua indígena</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas que habla alguna lengua indigena y no habla español" type="checkbox" name="Pob5indigesp" onClick={(e) => mostrarCapaIter(e, "", "p5_hli_nhe>0", "Poblacion de 5 años y mas que habla alguna lengua indigena y no habla español", "getPob5indigesp")} /><label>Población de 5 años y más que habla alguna lengua indígena y no habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas que habla alguna lengua indigena y habla español" type="checkbox" name="Pob5indigyesp" onClick={(e) => mostrarCapaIter(e, "", "p5_hli_he>0", "Poblacion de 5 años y mas que habla alguna lengua indigena y habla español", "getPob5indigyesp")} /><label>Población de 5 años y más que habla alguna lengua indígena y habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion en hogares censales indigenas" type="checkbox" name="Pobcensal" onClick={(e) => mostrarCapaIter(e, "", "phog_ind>0", "Poblacion en hogares censales indigenas", "getPobcensal")} /><label>Población en hogares censales indígenas</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion que se considera afromexicana o afrodescendiente" type="checkbox" name="Pobafrmex" onClick={(e) => mostrarCapaIter(e, "", "pob_afro>0", "Poblacion que se considera afromexicana o afrodescendiente", "getPobafrmex")} /><label>Población que se considera afromexicana o afrodescendiente</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina que se considera afromexicana o afrodescendiente" type="checkbox" name="PobaFfrmex" onClick={(e) => mostrarCapaIter(e, "", "pob_afro_f>0", "Poblacion femenina que se considera afromexicana o afrodescendiente", "getPobaFfrmex")} /><label>Población femenina que se considera afromexicana o afrodescendiente</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina que se considera afromexicana o afrodescendiente" type="checkbox" name="PobaMfrmex" onClick={(e) => mostrarCapaIter(e, "", "pob_afro_m>0", "Poblacion matutina que se considera afromexicana o afrodescendiente", "getPobaMfrmex")} /><label>Población matutina que se considera afromexicana o afrodescendiente</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Discapacidad" type="checkbox" name="Discapacidad" /><label>Discapacidad</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad" type="checkbox" name="Pobcondis" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con discapacidad", "getPoblacionT")} /><label>Población con discapacidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para caminar, subir o bajar" type="checkbox" name="Pobcaminar" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con discapacidad para caminar, subir o bajar", "getPoblacionT")} /><label>Población con discapacidad para caminar, subir o bajar</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para ver, aun usando lentes" type="checkbox" name="Poblentes" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con discapacidad para ver, aun usando lentes", "getPoblacionT")} /><label>Población con discapacidad para ver, aun usando lentes</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para hablar o comunicarse" type="checkbox" name="Pobdishablar" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con discapacidad para hablar o comunicarse", "getPoblacionT")} /><label>Población con discapacidad para hablar o comunicarse</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para oir, aun usando aparato auditivo" type="checkbox" name="Pobauditivo" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con discapacidad para oir, aun usando aparato auditivo", "getPoblacionT")} /><label>Población con discapacidad para oir, aun usando aparato auditivo</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para vestirse, bañarse o comer" type="checkbox" name="Pobdivestir" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con discapacidad para vestirse, bañarse o comer", "getPoblacionT")} /><label>Población con discapacidad para vestirse, bañarse o comer</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para recordar o concentrarse" type="checkbox" name="Pobrecordar" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con discapacidad para recordar o concentrarse", "getPoblacionT")} /><label>Población con discapacidad para recordar o concentrarse</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion" type="checkbox" name="Poblimitacion" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con limitacion", "getPoblacionT")} /><label>Población con limitación</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para caminar, subir o bajar" type="checkbox" name="Poblimcaminar" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con limitacion para caminar, subir o bajar", "getPoblacionT")} /><label>Población con limitación para caminar, subir o bajar</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para ver, aun usando lentes" type="checkbox" name="Poblimlentes" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con limitacion para ver, aun usando lentes", "getPoblacionT")} /><label>Población con limitación para ver, aun usando lentes</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para hablar o comunicarse" type="checkbox" name="Poblimhablar" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con limitacion para hablar o comunicarse", "getPoblacionT")} /><label>Población con limitación para hablar o comunicarse</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para oir, aun usando aparato auditivo" type="checkbox" name="Poblimauditivo" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con limitacion para oir, aun usando aparato auditivo", "getPoblacionT")} /><label>Población con limitación para oir, aun usando aparato auditivo</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para vestirse, bañarse o comer" type="checkbox" name="Poblimives" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con limitacion para vestirse, bañarse o comer", "getPoblacionT")} /><label>Población con limitación para vestirse, bañarse o comer</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para recordar o concentrarse" type="checkbox" name="Poblimrec" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con limitacion para recordar o concentrarse", "getPoblacionT")} /><label>Población con limitación para recordar o concentrarse</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con algun problema o condicion metal" type="checkbox" name="Pobmental" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con algun problema o condicion metal", "getPoblacionT")} /><label>Población con algun problema o condición metal</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion sin discapacidad, limitacion, problema o condicion mental" type="checkbox" name="Pobsindis" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion sin discapacidad, limitacion, problema o condicion mental", "getPoblacionT")} /><label>Población sin discapacidad, limitación, problema o condición mental</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Educacion" type="checkbox" name="Educacion" /><label>Educación</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion de 3 a 5 años que no asiste a la escuela" type="checkbox" name="Pob3a5sines" onClick={(e) => mostrarCapaIter(e, "", "p3a5_noa>0", "Poblacion de 3 a 5 años que no asiste a la escuela", "getPob3a5sines")} /><label>Población de 3 a 5 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 a 5 años que no asiste a la escuela" type="checkbox" name="PobF3a5sines" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 3 a 5 años que no asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 3 a 5 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 a 5 años que no asiste a la escuela" type="checkbox" name="PobM3a5sines" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 3 a 5 años que no asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 3 a 5 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 6 a 11 años que no asiste a la escuela" type="checkbox" name="Pob6a11sines" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 6 a 11 años que no asiste a la escuela", "getPob3a5sines")} /><label>Población de 6 a 11 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 6 a 11 años que no asiste a la escuela" type="checkbox" name="PobF6a11sines" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 6 a 11 años que no asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 6 a 11 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 6 a 11 años que no asiste a la escuela" type="checkbox" name="PobM6a11sines" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 6 a 11 años que no asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 6 a 11 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 a 14 años que no asiste a la escuela" type="checkbox" name="Pob12a14sines" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 12 a 14 años que no asiste a la escuela", "getPob3a5sines")} /><label>Población de 12 a 14 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 a 14 años que no asiste a la escuela" type="checkbox" name="PobF12a14sines" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 12 a 14 años que no asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 12 a 14 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 a 14 años que no asiste a la escuela" type="checkbox" name="PobM12a14sines" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 12 a 14 años que no asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 12 a 14 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 a 17 años que asiste a la escuela" type="checkbox" name="Pob15a17asiste" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 15 a 17 años que asiste a la escuela", "getPob3a5sines")} /><label>Población de 15 a 17 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 a 17 años que asiste a la escuela" type="checkbox" name="PobF15a17as" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 15 a 17 años que no asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 15 a 17 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 a 17 años que asiste a la escuela" type="checkbox" name="PobM15a17as" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 15 a 17 años que no asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 15 a 17 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 18 a 24 años que asiste a la escuela" type="checkbox" name="Pob18a24as" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 18 a 24 años que asiste a la escuela", "getPob3a5sines")} /><label>Población de 18 a 24 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 18 a 24 años que asiste a la escuela" type="checkbox" name="PobF18a24as" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 18 a 24 años que asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 18 a 24 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 18 a 24 años que asiste a la escuela" type="checkbox" name="PobM18a24as" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 18 a 24 años que asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 18 a 24 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 8 a 14 años que no sabe leer y escribir" type="checkbox" name="Pob8a14sin" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 8 a 14 años que no sabe leer y escribir", "getPob3a5sines")} /><label>Población de 8 a 14 años que no sabe leer y escribir</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 8 a 14 años que no sabe leer y escribir" type="checkbox" name="PobF8a14sin" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 8 a 14 años que no sabe leer y escribir ", "getPoblacionF")} /><label>Población femenina de 18 a 24 años que no sabe leer y escribir</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 8 a 14 años que no sabe leer y escribir" type="checkbox" name="PobM8a14sin" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 8 a 14 años que no sabe leer y escribir ", "getPoblacionM")} /><label>Población masculina de 18 a 24 años que no sabe leer y escribir</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas analfebeta" type="checkbox" name="Pob15sinedu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 15 años y mas analfebeta", "getPob3a5sines")} /><label>Población de 15 años y más analfebeta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas analfebeta" type="checkbox" name="PobF15sinedu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas analfebeta", "getPoblacionF")} /><label>Población Femenina de 15 años y más analfebeta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas analfebeta" type="checkbox" name="PobM15sinedu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas analfebeta", "getPoblacionM")} /><label>Población Masculina de 15 años y más analfebeta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas sin escolaridad" type="checkbox" name="Pob15sinesc" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 15 años y mas sin escolaridad", "getPob3a5sines")} /><label>Población de 15 años y más sin escolaridad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas sin escolaridad" type="checkbox" name="PobF15sinesc" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas sin escolaridad", "getPoblacionF")} /><label>Población Femenina de 15 años y más sin escolaridad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas sin escolaridad" type="checkbox" name="PobM15sinesc" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas sin escolaridad", "getPoblacionM")} /><label>Población Masculina de 15 años y más sin escolaridad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas con primaria incompleta" type="checkbox" name="Pob15incpri" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 15 años y mas con primaria incompleta", "getPob3a5sines")} /><label>Población de 15 años y más con primaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas con primaria incompleta" type="checkbox" name="PobF15incpri" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas con primaria incompleta", "getPoblacionF")} /><label>Población femenina de 15 años y más con primaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas con primaria incompleta" type="checkbox" name="PobM15incpri" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas con primaria incompleta", "getPoblacionM")} /><label>Población masculina de 15 años y más con primaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas con primaria completa" type="checkbox" name="Pob15prima" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 15 años y mas con primaria completa", "getPob3a5sines")} /><label>Población de 15 años y más con primaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas con primaria completa" type="checkbox" name="PobF15prima" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas con primaria completa", "getPoblacionF")} /><label>Población femenina de 15 años y más con primaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas con primaria completa" type="checkbox" name="PobM15prima" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas con primaria completa", "getPoblacionM")} /><label>Población masculina de 15 años y más con primaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas con secundaria incompleta" type="checkbox" name="Pob15insecu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 15 años y mas con secundariaa incompleta", "getPob3a5sines")} /><label>Población de 15 años y más con secundaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas con secundaria incompleta" type="checkbox" name="PobF15insecu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas con secundaria incompleta", "getPoblacionF")} /><label>Población femenina de 15 años y más con secundaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas con secundaria incompleta" type="checkbox" name="PobM15insecu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas con secundaria incompleta", "getPoblacionM")} /><label>Población masculina de 15 años y más con secundaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas con secundaria completa" type="checkbox" name="Pob15secu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 15 años y mas con secundaria completa", "getPob3a5sines")} /><label>Población de 15 años y más con secundaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas con secundaria completa" type="checkbox" name="PobF15secu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas con secundaria completa", "getPoblacionF")} /><label>Población femenina de 15 años y más con secundaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas con secundaria completa" type="checkbox" name="PobM15secu" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas con secundaria completa", "getPoblacionM")} /><label>Población masculina de 15 años y más con secundaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 18 años y mas con educacion posbasica" type="checkbox" name="Pob18posbas" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 18 años y mas con educacion posbasica", "getPob3a5sines")} /><label>Población de 18 años y más con educacion posbasica</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 18 años y mas con educacion posbasica" type="checkbox" name="PobF18posbas" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 18 años y mas con educacion posbasica", "getPoblacionF")} /><label>Población femenina de 18 años y más con educacion posbasica</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 18 años y mas con educacion posbasica" type="checkbox" name="PobM18posbas" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 18 años y mas con educacion posbasica", "getPoblacionM")} /><label>Población masculina de 18 años y más con educacion posbasica</label>
                            </div>
                            <div className="opcion">
                              <input id="Grado promedio de escolaridad" type="checkbox" name="Gradoesc" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Grado promedio de escolaridad", "getPob3a5sines")} /><label>Grado promedio de escolaridad</label>
                            </div>
                            <div className="opcion">
                              <input id="Grado promedio de escolaridad de la poblacion femenina" type="checkbox" name="GradoescF" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Grado promedio de escolaridad de la poblacion femenina", "getPoblacionF")} /><label>Grado promedio de escolaridad de la poblacion femenina</label>
                            </div>
                            <div className="opcion">
                              <input id="Grado promedio de escolaridad de la poblacion masculina" type="checkbox" name="GradoescM" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Grado promedio de escolaridad de la poblacion masculina", "getPoblacionM")} /><label>Grado promedio de escolaridad de la poblacion masculina</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Caracterisicas Economicas" type="checkbox" name="Economicas" /><label>Características Económicas</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas economicamente activa" type="checkbox" name="Pobeco" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 12 años y mas economicamente activa", "getPobecogral")} /><label>Población de 12 años y más económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas economicamente activa" type="checkbox" name="PobFeco" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 12 años y mas economicamente activa", "getPoblacionF")} /><label>Población femenina de 12 años y más económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas economicamente activa" type="checkbox" name="PobMeco" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 12 años y mas economicamente activa", "getPoblacionM")} /><label>Población masculina de 12 años y más económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas no economicamente activa" type="checkbox" name="Pobneco" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 12 años y mas no economicamente activa", "getPobecogral")} /><label>Población de 12 años y más no económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas no economicamente activa" type="checkbox" name="PobnFeco" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 12 años y mas no economicamente activa", "getPoblacionF")} /><label>Población femenina de 12 años y más no económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas no economicamente activa" type="checkbox" name="PobnMeco" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 12 años y mas no economicamente activa", "getPoblacionM")} /><label>Población masculina de 12 años y más no económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas ocupada" type="checkbox" name="Pob12ocupa" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 12 años y mas ocupada", "getPobecogral")} /><label>Población de 12 años y más ocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas ocupada" type="checkbox" name="Pob12Focupa" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 12 años y mas ocupada", "getPoblacionF")} /><label>Población femenina de 12 años y más ocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas ocupada" type="checkbox" name="Pob12Mocupa" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 12 años y mas ocupada", "getPoblacionM")} /><label>Población masculina de 12 años y más ocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas desocupada" type="checkbox" name="Pob12des" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 12 años y mas desocupada", "getPobecogral")} /><label>Población de 12 años y más desocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas desocupada" type="checkbox" name="Pob12Fdes" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion femenina de 12 años y mas desocupada", "getPoblacionF")} /><label>Población femenina de 12 años y más desocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas desocupada" type="checkbox" name="Pob12Mdes" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion masculina de 12 años y mas desocupada", "getPoblacionM")} /><label>Población masculina de 12 años y más desocupada</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Servicios de salud" type="checkbox" name="Salud" /><label>Servicios de Salud</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion sin afiliacion a servicios de salud" type="checkbox" name="Pobsinserv" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion sin afiliacion a servicios de salud", "getPobsinserv")} /><label>Población sin afiliación a servicios de salud</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud" type="checkbox" name="Pafiliada" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud", "getPobsinserv")} /><label>Población afiliada a servicios de salud</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el IMSS" type="checkbox" name="Pimss" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el IMSS", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el IMSS</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el ISSSTE" type="checkbox" name="Pissste" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el ISSSTE", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el ISSSTE</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el ISSSTE estatal" type="checkbox" name="PistEs" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el ISSSTE estatal", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el ISSSTE estatal</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en PEMEX Defensa o Marina" type="checkbox" name="Ppemex" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en PEMEX Defensa o Marina", "getPobsinserv")} /><label>Población afiliada a servicios de salud en PEMEX Defensa o Marina</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el Instituto de Salud para el Bienestar" type="checkbox" name="Pbienestar" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el Instituto de Salud para el Bienestar", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el Instituto de Salud para el Bienestar</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el IMSS BIENESTAR" type="checkbox" name="PimssB" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el IMSS BIENESTAR", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el IMSS BIENESTAR</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en una institucion privada" type="checkbox" name="Pspriva" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud", "getPobsinserv")} /><label>Población afiliada a servicios de salud en una institucion privada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en otra Institucion" type="checkbox" name="getPoinst" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en otra Institucion", "getPobsinserv")} /><label>Población afiliada a servicios de salud en otra Institucion</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Situacion Conyugal" type="checkbox" name="Conyugal" />
                        <label>Servicios financieros y de seguros</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas soltera o nunca unida" type="checkbox" name="Psoltera" onClick={(e) => mostrarCapaIter(e, "", " pobtot>0", "Poblacion de 12 años y mas soltera o nunca unida", "getSituacionCony")} /><label>Población de 12 años y más soltera o nunca unida</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas casada o unida" type="checkbox" name="Pcasada" onClick={(e) => mostrarCapaIter(e, "", " pobtot>0", "Poblacion de 12 años y mas casada o unida", "getSituacionCony")} /><label>Población de 12 años y más casada o unida</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas que estuvo casada o unida" type="checkbox" name="Pcasada" onClick={(e) => mostrarCapaIter(e, "", " pobtot>0", "Poblacion de 12 años y mas casada o unida", "getSituacionCony")} /><label>Población de 12 años y más casada o unida</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="religion" type="checkbox" name="religion" />
                        <label>Religión</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion con religion catolica" type="checkbox" name="Pcatolica" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con religion catolica", "getReligion")} /><label>Población con religión católica</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con grupo religioso protestante/cristiano evangelico" type="checkbox" name="PprotestaC" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con grupo religioso protestante/cristiano evangelico", "getReligion")} /><label>Población con grupo religioso protestante/cristiano evangélico</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con otras religiones diferentes a las anteriores" type="checkbox" name="Prdiferent" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion con otras religiones diferentes a las anteriores", "getReligion")} /><label>Población con otras religiones diferentes a las anteriores</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion sin religion o sin adscripcion religiosa" type="checkbox" name="PsinRel" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion sin religion o sin adscripcion religiosa", "getReligion")} /><label>Población sin religión o sin adscripción religiosa</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Hogares Censales" type="checkbox" name="Hogares" /><label>Hogares Censales</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Total de hogares censales" type="checkbox" name="Thogares" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Total de hogares censales", "getHogares")} /><label>Total de hogares censales</label>
                            </div>
                            <div className="opcion">
                              <input id="Hogares censales con persona de referencia mujer" type="checkbox" name="HogaresM" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Hogares censales con persona de referencia mujer", "getHogares")} /><label>Hogares censales con persona de referencia mujer</label>
                            </div>
                            <div className="opcion">
                              <input id="Hogares censales con persona de referencia hombre" type="checkbox" name="HogaresH" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Hogares censales con persona de referencia hombre", "getHogares")} /><label>Hogares censales con persona de referencia hombre</label>
                            </div>
                            <div className="opcion">
                              <input id="Hogares censales con persona" type="checkbox" name="HogaresP" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Hogares censales con persona", "getHogares")} /><label>Hogares censales con persona</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion en hogares censales con persona de referencia mujer" type="checkbox" name="PhogaresF" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion en hogares censales con persona de referencia mujer", "getHogares")} /><label>Población en hogares censales con persona de referencia mujer</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion hogares censales con persona de referencia hombre" type="checkbox" name="OogaresM" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion en hogares censales con persona de referencia hombre", "getHogares")} /><label>Población en hogares censales con persona de referencia hombre</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Vivienda" type="checkbox" name="Vivienda" /><label>Vivienda</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Total de viviendas" type="checkbox" name="Totvivienda" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Total de viviendas", "getVivienda")} /><label>Total de viviendas</label>
                            </div>
                            <div className="opcion">
                              <input id="Total de viviendas habitadas" type="checkbox" name="TotHabit" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Total de viviendas", "getVivienda")} /><label>Total de viviendas habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Total de viviendas particulares" type="checkbox" name="TotPart" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Total de viviendas particulares", "getVivienda")} /><label>Total de viviendas particulares</label>
                            </div>
                            <div className="opcion">
                              <input id="Total de viviendas particulares habitadas con caracteristicas" type="checkbox" name="Tpcaract" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Total de viviendas particulares habitadas con caracteristicas", "getVivienda")} /><label>Total de viviendas particulares habitadas con características</label>
                            </div>
                            <div className="opcion">
                              <input id="Total de viviendas particulares habitadas" type="checkbox" name="TotParth" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Total de viviendas particulares habitadas", "getVivienda")} /><label>Total de viviendas particulares habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares deshabitadas" type="checkbox" name="Totdesp" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares deshabitadas", "getVivienda")} /><label>Viviendas particulares deshabitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares de uso temporal" type="checkbox" name="Vivtemp" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares de uso temporal", "getVivienda")} /><label>Viviendas particulares de uso temporal</label>
                            </div>
                            <div className="opcion">
                              <input id="Ocupantes en viviendas particulares habitadas" type="checkbox" name="OcupHab" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Ocupantes en viviendas particulares habitadas", "getVivienda")} /><label>Ocupantes en viviendas particulares habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Promedio de ocupantes en viviendas particulares habitadas" type="checkbox" name="Promed" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Promedio de ocupantes en viviendas particulares habitadas", "getVivienda")} /><label>Promedio de ocupantes en viviendas particulares habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Promedio de ocupantes por cuarto en viviendas particulares habitadas" type="checkbox" name="Procuarto" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Promedio de ocupantes por cuarto en viviendas particulares habitadas", "getVivienda")} /><label>Promedio de ocupantes por cuarto en viviendas particulares habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con piso de material diferente de tierra" type="checkbox" name="Dtierra" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas con piso de material diferente de tierra", "getVivienda")} /><label>Viviendas particulares habitadas con piso de material diferente de tierra</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con piso de tierra" type="checkbox" name="Ptierra" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas con piso de tierra", "getVivienda")} /><label>Viviendas particulares habitadas con piso de tierra</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con un dormitorio" type="checkbox" name="Dormitorio" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas con un dormitorio", "getVivienda")} /><label>Viviendas particulares habitadas con un dormitorio</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con dos dormitorios y mas" type="checkbox" name="Masdormi" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas con dos dormitorios y mas", "getVivienda")} /><label>Viviendas particulares habitadas con dos dormitorios y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con un solo cuarto" type="checkbox" name="Uncuarto" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas con un solo cuarto", "getVivienda")} /><label>Viviendas particulares habitadas con un sólo cuarto</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con dos cuartos" type="checkbox" name="Doscuart" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas con dos cuartos", "getVivienda")} /><label>Viviendas particulares habitadas con dos cuartos</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con 3 cuartos y mas" type="checkbox" name="Trescua" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas con 3 cuartos y mas", "getVivienda")} /><label>Viviendas particulares habitadas con 3 cuartos y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de energia electrica" type="checkbox" name="Electrica" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de energia electrica", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de energía eléctrica</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de energia electrica" type="checkbox" name="NoElec" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de energia electrica", "getVivienda")} /><label>Viviendas particulares habitadas que no disponen de energía eléctrica</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de agua entubada en el ambito de la vivienda" type="checkbox" name="Entubada" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de agua entubada en el ambito de la vivienda", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de agua entubada en el ámbito de la vivienda</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de agua entubada y se abastecen del servicio publico de agua" type="checkbox" name="SerPub" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de agua entubada y se abastecen del servicio publico de agua", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de agua entubada y se abastecen del servicio público de agua</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de agua entubada en el ambito de la vivienda" type="checkbox" name="Noagua" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de agua entubada en el ambito de la vivienda", "getVivienda")} /><label>Viviendas particulares habitadas que no disponen de agua entubada en el ámbito de la vivienda</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de tinaco" type="checkbox" name="Tinaco" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de tinaco", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de tinaco</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de cisterna o aljibe" type="checkbox" name="Cisterna" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de cisterna o aljibe", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de cisterna o aljibe</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de excusado o sanitario" type="checkbox" name="Sanitario" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de excusado o sanitario", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de excusado o sanitario</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de letrina (pozo y hoyo)" type="checkbox" name="Letrina" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de letrina (pozo y hoyo)", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de letrina (pozo y hoyo)</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de drenaje" type="checkbox" name="Drenaje" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de drenaje", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de drenaje" type="checkbox" name="Nodrenaje" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de drenaje", "getVivienda")} /><label>Viviendas particulares habitadas que no disponen de drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de energia electrica, agua entubada de la red publica y drenaje" type="checkbox" name="Totredpub" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de energia electrica, agua entubada de la red publica y drenaje", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de energía eléctrica, agua entubada de la red pública y drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de energia electrica, agua entubada ni drenaje" type="checkbox" name="Ndagua" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de energia electrica, agua entubada ni drenaje", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de energía eléctrica, agua entubada de la red pública y drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares que disponen de drenaje y sanitario con admision de agua" type="checkbox" name="Admagua" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de drenaje y sanitario con admision de agua", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de de drenaje y sanitario con admisión de agua</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de automivil o camioneta, ni de motocicleta o motoneta" type="checkbox" name="Noauto" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de automivil o camioneta, ni de motocicleta o motoneta", "getVivienda")} /><label>Viviendas particulares habitadas que no disponen de automivil o camioneta, ni de motocicleta o motoneta</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin ningun bien" type="checkbox" name="Sinbien" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas sin ningun bien", "getVivienda")} /><label>Viviendas particulares habitadas sin ningun bien</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de refrigerador" type="checkbox" name="Refri" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de refrigerador", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de Refrigerador</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de lavadora" type="checkbox" name="Lavadora" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de lavadora", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de Lavadora</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de horno de microondas" type="checkbox" name="Horno" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de horno de microondas", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de horno de microondas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de automivil o camioneta" type="checkbox" name="Camioneta" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de automivil o camioneta ", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de automivil o camioneta</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de bicicleta como medio de transporte" type="checkbox" name="Bici" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de bicicleta como medio de transporte ", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de bicicleta como medio de transporte</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de radio" type="checkbox" name="Radio" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de radio", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de radio</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de television" type="checkbox" name="Tele" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de television", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de televisión</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de computadora, laptop o tablet" type="checkbox" name="laptop" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de computadora, laptop o tablet", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de computadora, laptop o tablet</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de linea telefonica fija" type="checkbox" name="Telefono" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de linea telefonica fija", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de linea telefonica fija</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de telefono celular" type="checkbox" name="Celular" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de telefono celular", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de telefono celular</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de Internet" type="checkbox" name="Internet" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de Internet", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de Internet</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de servicio de television de paga" type="checkbox" name="Tvpaga" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de servicio de television de paga", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de servicio de television de paga</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de servicio de peliculas, musica o video de paga por Internet" type="checkbox" name="Pelipaga" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de servicio de peliculas, musica o video de paga por Internet ", "getVivienda")} /><label>Viviendas particulares habitadas que disponen deservicio de peliculas, musica o video de paga por Internet </label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de consola de videojuegos" type="checkbox" name="Consola" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de consola de videojuegos", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de consola de videojuegos</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin radio ni television" type="checkbox" name="Sinradio" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas sin radio ni television", "getVivienda")} /><label>Viviendas particulares habitadas sin radio ni television</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin linea telefonica fija ni telefono celular" type="checkbox" name="Sinlinea" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas sin  linea telefonica fija ni telefono celular", "getVivienda")} /><label>Viviendas particulares habitadas sin de linea telefonica fija ni telefono celular</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin computadoras e Internet" type="checkbox" name="SinInter" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas sin computadoras e Internet", "getVivienda")} /><label>Viviendas particulares habitadas sin computadoras e Internet</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin tecnologias de la informacion y comunicacion (TIC)" type="checkbox" name="Sintic" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Viviendas particulares habitadas sin tecnologias de la informacion y comunicacion (TIC)", "getVivienda")} /><label>Viviendas particulares habitadas sin tecnologias de la informacion y comunicacion (TIC)</label>
                            </div>
                          </li>
                        </ul>

                      </li>





                    </ul>


                  </CardBody>
                </Card>
              </Collapse>

              <Button
                className="btnS3 btn-secondary"
                onClick={() => {
                  setColapsarPob2(!colapsarPob2)
                }}
                style={{ marginBottom: "1rem" }}
              >
                Información de Manzanas
              </Button>
              <Collapse isOpen={colapsarPob2}>
                <Card>
                  <CardBody className="cardM">


                    <div className="row">
                      <div className="col-3">Entidad</div>
                      <div className="col-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isClearable={true}
                          onChange={selectEntidad}
                          name="entidad"
                          options={estadosD}
                        //value={anioModernizacion}
                        />
                      </div>
                    </div>
                    <br></br>
                    {
                      muniss.length > 0 && (
                        <div className="row">
                          <div className="col-3">Municipio</div>
                          <div className="col-9">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              onChange={selectMunicipioManz}
                              name="municipios"
                              options={muniss}
                            //value={anioModernizacion}
                            />
                          </div>
                        </div>
                      )
                    }


                    <ul id="menu">
                      <li>
                        <input id="poblacion" type="checkbox" name="poblacion" /><label>Población</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion Masculina" type="checkbox" name="PoblacionM" onClick={(e) => mostrarCapaManzanas(e, "", "pobmas>0", "Poblacion Masculina", "getPoblacionM")} /><label>Población Masculina</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion Femenina" type="checkbox" name="PoblacionF" onClick={(e) => mostrarCapaManzanas(e, "", "pobfem>0", "Poblacion Femenina", "getPoblacionF")} /><label>Población Femenina</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion Total" type="checkbox" name="PoblacionT" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Población Total", "getPoblacionT")} /><label>Población Total</label>
                            </div>

                          </li>
                        </ul>
                      </li>


                      <li>
                        <input id="estructura" type="checkbox" name="estructura" /><label>Estructura por Edad y Sexo</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion masculina de 18 años y mas" type="checkbox" name="PoblacionMas18mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_18ymas_m>0", "Poblacion masculina de 18 años y mas", "getPoblacionMas18mas")} /><label>Población masculina de 18 años y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 18 años y mas" type="checkbox" name="PoblacionFem18mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_18ymas_f>0", "Poblacion femenina de 18 años y mas", "getPoblacionFem18mas")} /><label>Población femenina de 18 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 18 años y mas" type="checkbox" name="PoblacionDe18mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_18ymas>0", "Poblacion de 18 años y mas", "getPoblacionDe18mas")} /><label>Población de 18 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas" type="checkbox" name="PoblacionMas15mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_15ymas_m>0", " Poblacion masculina de 15 años y mas", "getPoblacionMas15mas")} /><label>Población masculina de 15 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas" type="checkbox" name="PoblacionFem15mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_15ymas_f>0", "Poblacion femenina de 15 años y mas ", "getPoblacionFem15mas")} /><label>Población femenina de 15 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas" type="checkbox" name="PoblacionDe15mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_15ymas>0", "Poblacion de 15 años y mas", "getPoblacionDe15mas")} /><label>Población de 15 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas" type="checkbox" name="PoblacionMas12mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_12ymas_m>0", "Poblacion masculina de 12 años y mas", "getPoblacionMas12mas")} /><label>Población masculina de 12 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas" type="checkbox" name="PoblacionFem12mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_12ymas_f>0", "Poblacion femenina de 12 años y mas", "getPoblacionFem12mas")} /><label>Población femenina de 12 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas" type="checkbox" name="PoblacionDe12mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_12ymas>0", "Poblacion de 12 años y mas", "getPoblacionDe12mas")} /><label>Población de 12 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 5 años y mas" type="checkbox" name="PoblacionMas5mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_5ymas>0", "Poblacion masculina de 5 años y mas", "getPoblacionMas5mas")} /><label>Población masculina de 5 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 5 años y mas" type="checkbox" name="PoblacionFem5mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_15ymas_f>0", "Poblacion femenina de 5 años y mas", "getPoblacionFem5mas")} /><label>Población femenina de 5 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas" type="checkbox" name="PoblacionDe5mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_5ymas>0 ", "Poblacion de 5 años y mas", "getPoblacionDe5mas")} /><label>Población de 5 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 años y mas" type="checkbox" name="PoblacionMas3mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_3ymas_m>0", "Poblacion masculina de 3 años y mas", "getPoblacionMas3mas")} /><label>Población masculina de 3 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 años y mas" type="checkbox" name="PoblacionFem3mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_3ymas_f>0", " Poblacion femenina de 3 años y mas", "getPoblacionDe3masFem")} /><label>Población femenina de 3 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 3 años y mas" type="checkbox" name="PoblacionDe3mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_3ymas>0", "Poblacion de 3 años y mas", "getPoblacionDe3mas")} /><label>Población de 3 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 0 a 2" type="checkbox" name="PoblacionFde0a2" onClick={(e) => mostrarCapaManzanas(e, "", "p_0a2_f>0", "Poblacion femenina de 0 a 2", "getPoblacionFde0a2")} /><label>Población femenina de 0 a 2</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 0 a 2" type="checkbox" name="PoblacionMde0a2" onClick={(e) => mostrarCapaManzanas(e, "", "p_0a2_m>0", "Poblacion masculina de 0 a 2", "getPob0a2Mas")} /><label>Población masculina de 0 a 2</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 0 a 2" type="checkbox" name="PoblacionDe0a2" onClick={(e) => mostrarCapaManzanas(e, "", "p_0a2>0", "Poblacion de 0 a 2", "getPoblacionDe0a2")} /><label>Población de 0 a 2</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 3 a 5" type="checkbox" name="PoblacionDe3a5" onClick={(e) => mostrarCapaManzanas(e, "", "p_3a5>0", "Poblacion de 3 a 5", "getPoblacionDe3a5")} /><label>Población de 3 a 5</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 a 5" type="checkbox" name="PoblacionFde3a5" onClick={(e) => mostrarCapaManzanas(e, "", "p_3a5_f>0", "Poblacion femenina de 3 a 5", "getPoblacionFde3a5")} /><label>Población femenina de 3 a 5</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 a 5" type="checkbox" name="PoblacionMde3a5" onClick={(e) => mostrarCapaManzanas(e, "", "p_3a5_m>0", "Poblacion masculina de 3 a 5", "getPoblacionMde3a5")} /><label>Población masculina de 3 a 5</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 6 a 11" type="checkbox" name="PoblacionDe6a11" onClick={(e) => mostrarCapaManzanas(e, "", "p_6a11>0", "Poblacion de 6 a 11", "getPoblacionDe6a11")} /><label>Población de 6 a 11</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 6 a 11" type="checkbox" name="PoblacionFde6a11" onClick={(e) => mostrarCapaManzanas(e, "", "p_6a11_f>0", "Poblacion femenina de 6 a 11", "getPoblacionFde6a11")} /><label>Población femenina de 6 a 11</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 6 a 11" type="checkbox" name="PoblacionMde6a11" onClick={(e) => mostrarCapaManzanas(e, "", "p_6a11_m>0", "Poblacion masculina de 6 a 11", "getPoblacionMde6a11")} /><label>Población masculina de 6 a 11</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 8 a 14" type="checkbox" name="PoblacionDe8a14" onClick={(e) => mostrarCapaManzanas(e, "", "p_8a14>0", "Poblacion de 4 a 14", "getPoblacionDe8a14")} /><label>Población de 8 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 8 a 14" type="checkbox" name="PoblacionFde8a14" onClick={(e) => mostrarCapaManzanas(e, "", "p_8a14_f>0", "Poblacion femenina de 8 a 14", "getPoblacionFde8a14")} /><label>Población femenina de 8 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 8 a 14" type="checkbox" name="PoblacionMde4a14" onClick={(e) => mostrarCapaManzanas(e, "", "p_8a14_m>0", "Poblacion masculina de 8 a 14", "getPoblacionMde8a14")} /><label>Población masculina de 8 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 a 14" type="checkbox" name="PoblacionDe12a14" onClick={(e) => mostrarCapaManzanas(e, "", "p_12a14>0", "Poblacion de 12 a 14", "getPoblacionDe12a14")} /><label>Población de 12 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 a 14" type="checkbox" name="PoblacionFde12a14" onClick={(e) => mostrarCapaManzanas(e, "", "p_12a14_f>0", "Poblacion femenina de 12 a 14", "getPoblacionFde121a14")} /><label>Población femenina de 12 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 a 14" type="checkbox" name="PoblacionMde12a14" onClick={(e) => mostrarCapaManzanas(e, "", "p_12a14_m>0", "Poblacion masculina de 12 a 14", "getPoblacionMde12a14")} /><label>Población masculina de 12 a 14</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 a 17" type="checkbox" name="PoblacionDe15a17" onClick={(e) => mostrarCapaManzanas(e, "", "p_15a17>0", "Poblacion de 15 a 17", "getPoblacionDe15a17")} /><label>Población de 15 a 17</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 a 17" type="checkbox" name="PoblacionFde15a17" onClick={(e) => mostrarCapaManzanas(e, "", "p_15a17_f>0", "Poblacion femenina de 15 a 17", "getPoblacionFde151a17")} /><label>Población femenina de 15 a 17</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 a 17" type="checkbox" name="PoblacionMde15a17" onClick={(e) => mostrarCapaManzanas(e, "", "p_15a17_m>0", "Poblacion masculina de 15 a 17", "getPoblacionMde15a17")} /><label>Población masculina de 15 a 17</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 18 a 24" type="checkbox" name="PoblacionDe18a24" onClick={(e) => mostrarCapaManzanas(e, "", "p_18a24>0", "Poblacion de 18 a 24", "getPoblacionDe18a24")} /><label>Población de 18 a 24</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 18 a 24" type="checkbox" name="PoblacionFde18a24" onClick={(e) => mostrarCapaManzanas(e, "", "p_18a24_f>0", "Poblacion femenina de 18 a 24", "getPoblacionFde18a24")} /><label>Población femenina de 18 a 24</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 18 a 24" type="checkbox" name="PoblacionMde18a24" onClick={(e) => mostrarCapaManzanas(e, "", "p_18a24_m>0", "Poblacion masculina de 18 a 24", "getPoblacionMde18a24")} /><label>Población masculina de 18 a 24</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 a 49" type="checkbox" name="PoblacionFde15a49" onClick={(e) => mostrarCapaManzanas(e, "", "p_15a49_f>0", "Poblacion femenina de 15 a 49", "getPoblacionFde15a49")} /><label>Población femenina de 15 a 49</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 60 años y mas" type="checkbox" name="PoblacionDe60mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_60ymas>0", "Poblacion de 60 años y mas", "getPoblacionDe60mas")} /><label>Población de 60 años y más</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 60 años y mas" type="checkbox" name="PoblacionFde60mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_60ymas_f>0", "Poblacion femenina de 60 años y mas", "getPoblacionFde60mas")} /><label>Poblacion femenina de 60 años y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 60 años y mas" type="checkbox" name="PoblacionMde60mas" onClick={(e) => mostrarCapaManzanas(e, "", "p_60ymas_m>0", "Poblacion masculina 60 años y mas", "getPoblacionMde60mas")} /><label>Población masculina de 60 años y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Relacion hombres-mujeres" type="checkbox" name="Relacionhm" onClick={(e) => mostrarCapaManzanas(e, "", "rel_h_m>0", "Relacion hombres-mujeres", "getRelacionhm")} /><label>Relación hombres-mujeres</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 0 a 14 años" type="checkbox" name="PoblacionDe0a14" onClick={(e) => mostrarCapaManzanas(e, "", "p_0a4>0", "Poblacion de 0 a 14 años", "getPoblacionDe0a14")} /><label>Población de 0 a 4 años </label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 a 64 años" type="checkbox" name="PoblacionDe15a64" onClick={(e) => mostrarCapaManzanas(e, "", "pob15_64>0", "Poblacion de 15 a 64 años", "getPoblacionDe15a64")} /><label>Población de 15 a 64 años </label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 65 años y mas" type="checkbox" name="PoblacionDe65mas" onClick={(e) => mostrarCapaManzanas(e, "", "pob65_mas>0", "Poblacion de 65 años y mas", "getPoblacionDe65mas")} /><label>Poblacion de 65 años y mas</label>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <input id="Fecundidad" type="checkbox" name="Fecundidad" /><label>Fecundidad</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Promedio de hijas e hijos nacidos vivos" type="checkbox" name="Promediohnv" onClick={(e) => mostrarCapaManzanas(e, "", "prom_hnv>0", "Promedio de hijas e hijos nacidos vivos", "getPromediohnv")} /><label>Promedio de hijas e hijos nacidos vivos</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Migracion" type="checkbox" name="Migracion" /><label>Migración</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion nacida en la entidad" type="checkbox" name="Poblacionne" onClick={(e) => mostrarCapaManzanas(e, "", "pnacent>0", "Poblacion nacida en la entidad", "getPoblacionne")} /><label>Población nacida en la entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion fenenina nacida en la entidad" type="checkbox" name="PoblacionFne" onClick={(e) => mostrarCapaManzanas(e, "", "pnacent_f>0", "Poblacion femenina nacida en la entidad", "getPoblacionFne")} /><label>Población femenina nacida en la entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina nacida en la entidad" type="checkbox" name="PoblacionMne" onClick={(e) => mostrarCapaManzanas(e, "", "pnacent_m>0", "Poblacion masculina nacida en la entidad", "getPoblacionMne")} /><label>Población masculina nacida en la entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion nacida en otra entidad" type="checkbox" name="PoblacionOen" onClick={(e) => mostrarCapaManzanas(e, "", "pnacoe>0", "Poblacion nacida en otra entidad", "getPoblacionOen")} /><label>Población nacida en otra entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina nacida en otra entidad" type="checkbox" name="PoblacionFoen" onClick={(e) => mostrarCapaManzanas(e, "", "pnacoe_f>0", "Poblacion femenina nacida en otra entidad", "getPoblacionFOen")} /><label>Población femenina nacida en otra entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina nacida en otra entidad" type="checkbox" name="PoblacionMoen" onClick={(e) => mostrarCapaManzanas(e, "", "pnacoe_m>0", "Poblacion masculina nacida en otra entidad", "getPoblacionmOen")} /><label>Población masculina nacida en otra entidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas residente en la entidad en marzo de 2015" type="checkbox" name="Poblacion5marz2015" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 5 años y mas residente en la entidad en marzo de 2015", "getPoblacion5marz2015")} /><label>Población de 5 años y más residente en la entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 5 años y mas residente en la entidad en marzo de 2015" type="checkbox" name="PoblacionF5marz2015" onClick={(e) => mostrarCapaManzanas(e, "", "pnacoe>0", "Poblacion femenina de 5 años y mas residente en la entidad en marzo de 2015", "getPoblacionF5marz2015")} /><label>Población femenina de 5 años y mas residente en la entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 5 años y mas residente en la entidad en marzo de 2015" type="checkbox" name="PoblacionM5marz2015" onClick={(e) => mostrarCapaManzanas(e, "", "pres2015_m>0", "Poblacion masculina de 5 años y mas residente en la entidad en marzo de 2015", "getPoblacionM5marz2015")} /><label>Población masculina de 5 años y mas residente en la entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas residente en otra entidad en marzo de 2015" type="checkbox" name="PoblacionOtra2015" onClick={(e) => mostrarCapaManzanas(e, "", "presoe15>0", "Poblacion de 5 años y mas residente en otra entidad en marzo de 2015", "getPob2")} /><label>Población de 5 años y más residente en otra entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 5 años y mas residente en otra entidad en marzo de 2015" type="checkbox" name="PoblacionFotra2015" onClick={(e) => mostrarCapaManzanas(e, "", "presoe15_f>0", "Poblacion femenina de 5 años y mas residente en otra entidad en marzo de 2015", "getPoblacionFotra2015")} /><label>Población femenina de 5 años y mas residente en otra entidad en marzo de 2015</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 5 años y mas residente en otra entidad en marzo de 2015" type="checkbox" name="PoblacionMotra2015" onClick={(e) => mostrarCapaManzanas(e, "", "pres2015_m>0", "Poblacion masculina de 5 años y mas residente en otra entidad en marzo de 2015", "getPoblacionMotra2015")} /><label>Población masculina de 5 años y mas residente en otra entidad en marzo de 2015</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Etnicidad" type="checkbox" name="Etnicidad" /><label>Etnicidad</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion de 3 años y mas que habla alguna lengua indigena" type="checkbox" name="Pob3indigena" onClick={(e) => mostrarCapaManzanas(e, "", "p3ym_hli>0", "Poblacion de 3 años y mas que habla alguna lengua indigena", "getPob3indigena")} /><label>Población de 3 años y más que habla alguna lengua indígena</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 años y mas que habla alguna lengua indigena" type="checkbox" name="PobF3indigena" onClick={(e) => mostrarCapaManzanas(e, "", "p3ym_hli_f>0", "Poblacion femenina de 3 años y mas que habla alguna lengua indigena", "getPobF3indigena")} /><label>Población femenina de 3 años y más que habla alguna lengua indígena</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 años y mas que habla alguna lengua indigena" type="checkbox" name="PobM3indigena" onClick={(e) => mostrarCapaManzanas(e, "", "p3ym_hli_m>0", "Poblacion masculina de 3 años y mas que habla alguna lengua indigena", "getPobM3indigena")} /><label>Población masculina de 3 años y más que habla alguna lengua indígena</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 3 años y mas que habla alguna lengua indigena y no habla español" type="checkbox" name="Pob3indigesp" onClick={(e) => mostrarCapaManzanas(e, "", "p3hlinhe>0", "Poblacion de 3 años y mas que habla alguna lengua indigena y no habla español", "getPob3indigesp")} /><label>Población de 3 años y más que habla alguna lengua indígena y no habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 años y mas que habla alguna lengua indigena y no habla español" type="checkbox" name="PobF3indigesp" onClick={(e) => mostrarCapaManzanas(e, "", "p3hlinhe_f>0", "Poblacion femenina de 3 años y mas que habla alguna lengua indigena no habla español", "getPobF3indigesp")} /><label>Población femenina de 3 años y más que habla alguna lengua indígena y no habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 años y mas que habla alguna lengua indigena y no habla español" type="checkbox" name="PobM3indigesp" onClick={(e) => mostrarCapaManzanas(e, "", "p3hlinhe_m>0", "Poblacion masculina de 3 años y mas que habla alguna lengua indigena y no habla español", "getPobM3indigesp")} /><label>Población masculina de 3 años y más que habla alguna lengua indígena y no habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 3 años y mas que habla alguna lengua indigena y habla español" type="checkbox" name="Pob3indigyesp" onClick={(e) => mostrarCapaManzanas(e, "", "p3hli_he>0", "Poblacion de 3 años y mas que habla alguna lengua indigena y habla español", "getPob3indigyesp")} /><label>Población de 3 años y más que habla alguna lengua indígena y habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 años y mas que habla alguna lengua indigena y habla español" type="checkbox" name="PobF3indigyesp" onClick={(e) => mostrarCapaManzanas(e, "", "p3hli_he_f>0", "Poblacion femenina de 3 años y mas que habla alguna lengua indigena y habla español", "getPobF3indigyesp")} /><label>Población femenina de 3 años y más que habla alguna lengua indígena y habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 años y mas que habla alguna lengua indigena y habla español" type="checkbox" name="PobM3indigyesp" onClick={(e) => mostrarCapaManzanas(e, "", "p3hli_he_m>0", "Poblacion masculina de 3 años y mas que habla alguna lengua indigena y habla español", "getPobM3indigyesp")} /><label>Población masculina de 3 años y más que habla alguna lengua indígena y habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas que habla alguna lengua indigena" type="checkbox" name="Pob5indigena" onClick={(e) => mostrarCapaManzanas(e, "", "p5_hli>0", "Poblacion de 5 años y mas que habla alguna lengua indigena", "getPob5indigena")} /><label>Población de 5 años y más que habla alguna lengua indígena</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas que habla alguna lengua indigena y no habla español" type="checkbox" name="Pob5indigesp" onClick={(e) => mostrarCapaManzanas(e, "", "p5_hli_nhe>0", "Poblacion de 5 años y mas que habla alguna lengua indigena y no habla español", "getPob5indigesp")} /><label>Población de 5 años y más que habla alguna lengua indígena y no habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 5 años y mas que habla alguna lengua indigena y habla español" type="checkbox" name="Pob5indigyesp" onClick={(e) => mostrarCapaManzanas(e, "", "p5_hli_he>0", "Poblacion de 5 años y mas que habla alguna lengua indigena y habla español", "getPob5indigyesp")} /><label>Población de 5 años y más que habla alguna lengua indígena y habla español</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion en hogares censales indigenas" type="checkbox" name="Pobcensal" onClick={(e) => mostrarCapaManzanas(e, "", "phog_ind>0", "Poblacion en hogares censales indigenas", "getPobcensal")} /><label>Población en hogares censales indígenas</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion que se considera afromexicana o afrodescendiente" type="checkbox" name="Pobafrmex" onClick={(e) => mostrarCapaManzanas(e, "", "pob_afro>0", "Poblacion que se considera afromexicana o afrodescendiente", "getPobafrmex")} /><label>Población que se considera afromexicana o afrodescendiente</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina que se considera afromexicana o afrodescendiente" type="checkbox" name="PobaFfrmex" onClick={(e) => mostrarCapaManzanas(e, "", "pob_afro_f>0", "Poblacion femenina que se considera afromexicana o afrodescendiente", "getPobaFfrmex")} /><label>Población femenina que se considera afromexicana o afrodescendiente</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina que se considera afromexicana o afrodescendiente" type="checkbox" name="PobaMfrmex" onClick={(e) => mostrarCapaManzanas(e, "", "pob_afro_m>0", "Poblacion matutina que se considera afromexicana o afrodescendiente", "getPobaMfrmex")} /><label>Población matutina que se considera afromexicana o afrodescendiente</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Discapacidad" type="checkbox" name="Discapacidad" /><label>Discapacidad</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad" type="checkbox" name="Pobcondis" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con discapacidad", "getPoblacionT")} /><label>Población con discapacidad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para caminar, subir o bajar" type="checkbox" name="Pobcaminar" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con discapacidad para caminar, subir o bajar", "getPoblacionT")} /><label>Población con discapacidad para caminar, subir o bajar</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para ver, aun usando lentes" type="checkbox" name="Poblentes" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con discapacidad para ver, aun usando lentes", "getPoblacionT")} /><label>Población con discapacidad para ver, aun usando lentes</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para hablar o comunicarse" type="checkbox" name="Pobdishablar" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con discapacidad para hablar o comunicarse", "getPoblacionT")} /><label>Población con discapacidad para hablar o comunicarse</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para oir, aun usando aparato auditivo" type="checkbox" name="Pobauditivo" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con discapacidad para oir, aun usando aparato auditivo", "getPoblacionT")} /><label>Población con discapacidad para oir, aun usando aparato auditivo</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para vestirse, bañarse o comer" type="checkbox" name="Pobdivestir" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con discapacidad para vestirse, bañarse o comer", "getPoblacionT")} /><label>Población con discapacidad para vestirse, bañarse o comer</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con discapacidad para recordar o concentrarse" type="checkbox" name="Pobrecordar" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con discapacidad para recordar o concentrarse", "getPoblacionT")} /><label>Población con discapacidad para recordar o concentrarse</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion" type="checkbox" name="Poblimitacion" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con limitacion", "getPoblacionT")} /><label>Población con limitación</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para caminar, subir o bajar" type="checkbox" name="Poblimcaminar" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con limitacion para caminar, subir o bajar", "getPoblacionT")} /><label>Población con limitación para caminar, subir o bajar</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para ver, aun usando lentes" type="checkbox" name="Poblimlentes" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con limitacion para ver, aun usando lentes", "getPoblacionT")} /><label>Población con limitación para ver, aun usando lentes</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para hablar o comunicarse" type="checkbox" name="Poblimhablar" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con limitacion para hablar o comunicarse", "getPoblacionT")} /><label>Población con limitación para hablar o comunicarse</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para oir, aun usando aparato auditivo" type="checkbox" name="Poblimauditivo" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con limitacion para oir, aun usando aparato auditivo", "getPoblacionT")} /><label>Población con limitación para oir, aun usando aparato auditivo</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para vestirse, bañarse o comer" type="checkbox" name="Poblimives" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con limitacion para vestirse, bañarse o comer", "getPoblacionT")} /><label>Población con limitación para vestirse, bañarse o comer</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con limitacion para recordar o concentrarse" type="checkbox" name="Poblimrec" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con limitacion para recordar o concentrarse", "getPoblacionT")} /><label>Población con limitación para recordar o concentrarse</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con algun problema o condicion metal" type="checkbox" name="Pobmental" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con algun problema o condicion metal", "getPoblacionT")} /><label>Población con algun problema o condición metal</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion sin discapacidad, limitacion, problema o condicion mental" type="checkbox" name="Pobsindis" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion sin discapacidad, limitacion, problema o condicion mental", "getPoblacionT")} /><label>Población sin discapacidad, limitación, problema o condición mental</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Educacion" type="checkbox" name="Educacion" /><label>Educación</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion de 3 a 5 años que no asiste a la escuela" type="checkbox" name="Pob3a5sines" onClick={(e) => mostrarCapaManzanas(e, "", "p3a5_noa>0", "Poblacion de 3 a 5 años que no asiste a la escuela", "getPob3a5sines")} /><label>Población de 3 a 5 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 3 a 5 años que no asiste a la escuela" type="checkbox" name="PobF3a5sines" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 3 a 5 años que no asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 3 a 5 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 3 a 5 años que no asiste a la escuela" type="checkbox" name="PobM3a5sines" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 3 a 5 años que no asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 3 a 5 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 6 a 11 años que no asiste a la escuela" type="checkbox" name="Pob6a11sines" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 6 a 11 años que no asiste a la escuela", "getPob3a5sines")} /><label>Población de 6 a 11 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 6 a 11 años que no asiste a la escuela" type="checkbox" name="PobF6a11sines" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 6 a 11 años que no asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 6 a 11 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 6 a 11 años que no asiste a la escuela" type="checkbox" name="PobM6a11sines" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 6 a 11 años que no asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 6 a 11 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 a 14 años que no asiste a la escuela" type="checkbox" name="Pob12a14sines" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 12 a 14 años que no asiste a la escuela", "getPob3a5sines")} /><label>Población de 12 a 14 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 a 14 años que no asiste a la escuela" type="checkbox" name="PobF12a14sines" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 12 a 14 años que no asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 12 a 14 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 a 14 años que no asiste a la escuela" type="checkbox" name="PobM12a14sines" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 12 a 14 años que no asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 12 a 14 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 a 17 años que asiste a la escuela" type="checkbox" name="Pob15a17asiste" onClick={(e) => mostrarCapaIter(e, "", "pobtot>0", "Poblacion de 15 a 17 años que asiste a la escuela", "getPob3a5sines")} /><label>Población de 15 a 17 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 a 17 años que asiste a la escuela" type="checkbox" name="PobF15a17as" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 15 a 17 años que no asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 15 a 17 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 a 17 años que asiste a la escuela" type="checkbox" name="PobM15a17as" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 15 a 17 años que no asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 15 a 17 años que no asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 18 a 24 años que asiste a la escuela" type="checkbox" name="Pob18a24as" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 18 a 24 años que asiste a la escuela", "getPob3a5sines")} /><label>Población de 18 a 24 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 18 a 24 años que asiste a la escuela" type="checkbox" name="PobF18a24as" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 18 a 24 años que asiste a la escuela", "getPoblacionF")} /><label>Población femenina de 18 a 24 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 18 a 24 años que asiste a la escuela" type="checkbox" name="PobM18a24as" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 18 a 24 años que asiste a la escuela", "getPoblacionM")} /><label>Población masculina de 18 a 24 años que asiste a la escuela</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 8 a 14 años que no sabe leer y escribir" type="checkbox" name="Pob8a14sin" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 8 a 14 años que no sabe leer y escribir", "getPob3a5sines")} /><label>Población de 8 a 14 años que no sabe leer y escribir</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 8 a 14 años que no sabe leer y escribir" type="checkbox" name="PobF8a14sin" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 8 a 14 años que no sabe leer y escribir ", "getPoblacionF")} /><label>Población femenina de 18 a 24 años que no sabe leer y escribir</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 8 a 14 años que no sabe leer y escribir" type="checkbox" name="PobM8a14sin" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 8 a 14 años que no sabe leer y escribir ", "getPoblacionM")} /><label>Población masculina de 18 a 24 años que no sabe leer y escribir</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas analfebeta" type="checkbox" name="Pob15sinedu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 15 años y mas analfebeta", "getPob3a5sines")} /><label>Población de 15 años y más analfebeta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas analfebeta" type="checkbox" name="PobF15sinedu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas analfebeta", "getPoblacionF")} /><label>Población Femenina de 15 años y más analfebeta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas analfebeta" type="checkbox" name="PobM15sinedu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas analfebeta", "getPoblacionM")} /><label>Población Masculina de 15 años y más analfebeta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas sin escolaridad" type="checkbox" name="Pob15sinesc" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 15 años y mas sin escolaridad", "getPob3a5sines")} /><label>Población de 15 años y más sin escolaridad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas sin escolaridad" type="checkbox" name="PobF15sinesc" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas sin escolaridad", "getPoblacionF")} /><label>Población Femenina de 15 años y más sin escolaridad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas sin escolaridad" type="checkbox" name="PobM15sinesc" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas sin escolaridad", "getPoblacionM")} /><label>Población Masculina de 15 años y más sin escolaridad</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas con primaria incompleta" type="checkbox" name="Pob15incpri" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 15 años y mas con primaria incompleta", "getPob3a5sines")} /><label>Población de 15 años y más con primaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas con primaria incompleta" type="checkbox" name="PobF15incpri" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas con primaria incompleta", "getPoblacionF")} /><label>Población femenina de 15 años y más con primaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas con primaria incompleta" type="checkbox" name="PobM15incpri" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas con primaria incompleta", "getPoblacionM")} /><label>Población masculina de 15 años y más con primaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas con primaria completa" type="checkbox" name="Pob15prima" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 15 años y mas con primaria completa", "getPob3a5sines")} /><label>Población de 15 años y más con primaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas con primaria completa" type="checkbox" name="PobF15prima" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas con primaria completa", "getPoblacionF")} /><label>Población femenina de 15 años y más con primaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas con primaria completa" type="checkbox" name="PobM15prima" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas con primaria completa", "getPoblacionM")} /><label>Población masculina de 15 años y más con primaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas con secundaria incompleta" type="checkbox" name="Pob15insecu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 15 años y mas con secundariaa incompleta", "getPob3a5sines")} /><label>Población de 15 años y más con secundaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas con secundaria incompleta" type="checkbox" name="PobF15insecu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas con secundaria incompleta", "getPoblacionF")} /><label>Población femenina de 15 años y más con secundaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas con secundaria incompleta" type="checkbox" name="PobM15insecu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas con secundaria incompleta", "getPoblacionM")} /><label>Población masculina de 15 años y más con secundaria incompleta</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 15 años y mas con secundaria completa" type="checkbox" name="Pob15secu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 15 años y mas con secundaria completa", "getPob3a5sines")} /><label>Población de 15 años y más con secundaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 15 años y mas con secundaria completa" type="checkbox" name="PobF15secu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 15 años y mas con secundaria completa", "getPoblacionF")} /><label>Población femenina de 15 años y más con secundaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 15 años y mas con secundaria completa" type="checkbox" name="PobM15secu" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 15 años y mas con secundaria completa", "getPoblacionM")} /><label>Población masculina de 15 años y más con secundaria completa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 18 años y mas con educacion posbasica" type="checkbox" name="Pob18posbas" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 18 años y mas con educacion posbasica", "getPob3a5sines")} /><label>Población de 18 años y más con educacion posbasica</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 18 años y mas con educacion posbasica" type="checkbox" name="PobF18posbas" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 18 años y mas con educacion posbasica", "getPoblacionF")} /><label>Población femenina de 18 años y más con educacion posbasica</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 18 años y mas con educacion posbasica" type="checkbox" name="PobM18posbas" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 18 años y mas con educacion posbasica", "getPoblacionM")} /><label>Población masculina de 18 años y más con educacion posbasica</label>
                            </div>
                            <div className="opcion">
                              <input id="Grado promedio de escolaridad" type="checkbox" name="Gradoesc" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Grado promedio de escolaridad", "getPob3a5sines")} /><label>Grado promedio de escolaridad</label>
                            </div>
                            <div className="opcion">
                              <input id="Grado promedio de escolaridad de la poblacion femenina" type="checkbox" name="GradoescF" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Grado promedio de escolaridad de la poblacion femenina", "getPoblacionF")} /><label>Grado promedio de escolaridad de la poblacion femenina</label>
                            </div>
                            <div className="opcion">
                              <input id="Grado promedio de escolaridad de la poblacion masculina" type="checkbox" name="GradoescM" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Grado promedio de escolaridad de la poblacion masculina", "getPoblacionM")} /><label>Grado promedio de escolaridad de la poblacion masculina</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Caracterisicas Economicas" type="checkbox" name="Economicas" /><label>Características Económicas</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas economicamente activa" type="checkbox" name="Pobeco" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 12 años y mas economicamente activa", "getPobecogral")} /><label>Población de 12 años y más económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas economicamente activa" type="checkbox" name="PobFeco" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 12 años y mas economicamente activa", "getPoblacionF")} /><label>Población femenina de 12 años y más económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas economicamente activa" type="checkbox" name="PobMeco" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 12 años y mas economicamente activa", "getPoblacionM")} /><label>Población masculina de 12 años y más económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas no economicamente activa" type="checkbox" name="Pobneco" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 12 años y mas no economicamente activa", "getPobecogral")} /><label>Población de 12 años y más no económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas no economicamente activa" type="checkbox" name="PobnFeco" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 12 años y mas no economicamente activa", "getPoblacionF")} /><label>Población femenina de 12 años y más no económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas no economicamente activa" type="checkbox" name="PobnMeco" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 12 años y mas no economicamente activa", "getPoblacionM")} /><label>Población masculina de 12 años y más no económicamente activa</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas ocupada" type="checkbox" name="Pob12ocupa" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 12 años y mas ocupada", "getPobecogral")} /><label>Población de 12 años y más ocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas ocupada" type="checkbox" name="Pob12Focupa" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 12 años y mas ocupada", "getPoblacionF")} /><label>Población femenina de 12 años y más ocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas ocupada" type="checkbox" name="Pob12Mocupa" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 12 años y mas ocupada", "getPoblacionM")} /><label>Población masculina de 12 años y más ocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas desocupada" type="checkbox" name="Pob12des" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion de 12 años y mas desocupada", "getPobecogral")} /><label>Población de 12 años y más desocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion femenina de 12 años y mas desocupada" type="checkbox" name="Pob12Fdes" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion femenina de 12 años y mas desocupada", "getPoblacionF")} /><label>Población femenina de 12 años y más desocupada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion masculina de 12 años y mas desocupada" type="checkbox" name="Pob12Mdes" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion masculina de 12 años y mas desocupada", "getPoblacionM")} /><label>Población masculina de 12 años y más desocupada</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Servicios de salud" type="checkbox" name="Salud" /><label>Servicios de Salud</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion sin afiliacion a servicios de salud" type="checkbox" name="Pobsinserv" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion sin afiliacion a servicios de salud", "getPobsinserv")} /><label>Población sin afiliación a servicios de salud</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud" type="checkbox" name="Pafiliada" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud", "getPobsinserv")} /><label>Población afiliada a servicios de salud</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el IMSS" type="checkbox" name="Pimss" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el IMSS", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el IMSS</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el ISSSTE" type="checkbox" name="Pissste" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el ISSSTE", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el ISSSTE</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el ISSSTE estatal" type="checkbox" name="PistEs" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el ISSSTE estatal", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el ISSSTE estatal</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en PEMEX Defensa o Marina" type="checkbox" name="Ppemex" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en PEMEX Defensa o Marina", "getPobsinserv")} /><label>Población afiliada a servicios de salud en PEMEX Defensa o Marina</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el Instituto de Salud para el Bienestar" type="checkbox" name="Pbienestar" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el Instituto de Salud para el Bienestar", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el Instituto de Salud para el Bienestar</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en el IMSS BIENESTAR" type="checkbox" name="PimssB" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en el IMSS BIENESTAR", "getPobsinserv")} /><label>Población afiliada a servicios de salud en el IMSS BIENESTAR</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en una institucion privada" type="checkbox" name="Pspriva" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud", "getPobsinserv")} /><label>Población afiliada a servicios de salud en una institucion privada</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion afiliada a servicios de salud en otra Institucion" type="checkbox" name="getPoinst" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion afiliada a servicios de salud en otra Institucion", "getPobsinserv")} /><label>Población afiliada a servicios de salud en otra Institucion</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Situacion Conyugal" type="checkbox" name="Conyugal" />
                        <label>Servicios financieros y de seguros</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas soltera o nunca unida" type="checkbox" name="Psoltera" onClick={(e) => mostrarCapaManzanas(e, "", " pobtot>0", "Poblacion de 12 años y mas soltera o nunca unida", "getSituacionCony")} /><label>Población de 12 años y más soltera o nunca unida</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas casada o unida" type="checkbox" name="Pcasada" onClick={(e) => mostrarCapaManzanas(e, "", " pobtot>0", "Poblacion de 12 años y mas casada o unida", "getSituacionCony")} /><label>Población de 12 años y más casada o unida</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion de 12 años y mas que estuvo casada o unida" type="checkbox" name="Pcasada" onClick={(e) => mostrarCapaManzanas(e, "", " pobtot>0", "Poblacion de 12 años y mas casada o unida", "getSituacionCony")} /><label>Población de 12 años y más casada o unida</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="religion" type="checkbox" name="religion" />
                        <label>Religión</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Poblacion con religion catolica" type="checkbox" name="Pcatolica" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con religion catolica", "getReligion")} /><label>Población con religión católica</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con grupo religioso protestante/cristiano evangelico" type="checkbox" name="PprotestaC" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con grupo religioso protestante/cristiano evangelico", "getReligion")} /><label>Población con grupo religioso protestante/cristiano evangélico</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion con otras religiones diferentes a las anteriores" type="checkbox" name="Prdiferent" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion con otras religiones diferentes a las anteriores", "getReligion")} /><label>Población con otras religiones diferentes a las anteriores</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion sin religion o sin adscripcion religiosa" type="checkbox" name="PsinRel" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion sin religion o sin adscripcion religiosa", "getReligion")} /><label>Población sin religión o sin adscripción religiosa</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Hogares Censales" type="checkbox" name="Hogares" /><label>Hogares Censales</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Total de hogares censales" type="checkbox" name="Thogares" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Total de hogares censales", "getHogares")} /><label>Total de hogares censales</label>
                            </div>
                            <div className="opcion">
                              <input id="Hogares censales con persona de referencia mujer" type="checkbox" name="HogaresM" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Hogares censales con persona de referencia mujer", "getHogares")} /><label>Hogares censales con persona de referencia mujer</label>
                            </div>
                            <div className="opcion">
                              <input id="Hogares censales con persona de referencia hombre" type="checkbox" name="HogaresH" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Hogares censales con persona de referencia hombre", "getHogares")} /><label>Hogares censales con persona de referencia hombre</label>
                            </div>
                            <div className="opcion">
                              <input id="Hogares censales con persona" type="checkbox" name="HogaresP" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Hogares censales con persona", "getHogares")} /><label>Hogares censales con persona</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion en hogares censales con persona de referencia mujer" type="checkbox" name="PhogaresF" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion en hogares censales con persona de referencia mujer", "getHogares")} /><label>Población en hogares censales con persona de referencia mujer</label>
                            </div>
                            <div className="opcion">
                              <input id="Poblacion hogares censales con persona de referencia hombre" type="checkbox" name="OogaresM" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Poblacion en hogares censales con persona de referencia hombre", "getHogares")} /><label>Población en hogares censales con persona de referencia hombre</label>
                            </div>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <input id="Vivienda" type="checkbox" name="Vivienda" /><label>Vivienda</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="Total de viviendas" type="checkbox" name="Totvivienda" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Total de viviendas", "getVivienda")} /><label>Total de viviendas</label>
                            </div>
                            <div className="opcion">
                              <input id="Total de viviendas habitadas" type="checkbox" name="TotHabit" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Total de viviendas", "getVivienda")} /><label>Total de viviendas habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Total de viviendas particulares" type="checkbox" name="TotPart" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Total de viviendas particulares", "getVivienda")} /><label>Total de viviendas particulares</label>
                            </div>
                            <div className="opcion">
                              <input id="Total de viviendas particulares habitadas con caracteristicas" type="checkbox" name="Tpcaract" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Total de viviendas particulares habitadas con caracteristicas", "getVivienda")} /><label>Total de viviendas particulares habitadas con características</label>
                            </div>
                            <div className="opcion">
                              <input id="Total de viviendas particulares habitadas" type="checkbox" name="TotParth" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Total de viviendas particulares habitadas", "getVivienda")} /><label>Total de viviendas particulares habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares deshabitadas" type="checkbox" name="Totdesp" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares deshabitadas", "getVivienda")} /><label>Viviendas particulares deshabitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares de uso temporal" type="checkbox" name="Vivtemp" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares de uso temporal", "getVivienda")} /><label>Viviendas particulares de uso temporal</label>
                            </div>
                            <div className="opcion">
                              <input id="Ocupantes en viviendas particulares habitadas" type="checkbox" name="OcupHab" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Ocupantes en viviendas particulares habitadas", "getVivienda")} /><label>Ocupantes en viviendas particulares habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Promedio de ocupantes en viviendas particulares habitadas" type="checkbox" name="Promed" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Promedio de ocupantes en viviendas particulares habitadas", "getVivienda")} /><label>Promedio de ocupantes en viviendas particulares habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Promedio de ocupantes por cuarto en viviendas particulares habitadas" type="checkbox" name="Procuarto" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Promedio de ocupantes por cuarto en viviendas particulares habitadas", "getVivienda")} /><label>Promedio de ocupantes por cuarto en viviendas particulares habitadas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con piso de material diferente de tierra" type="checkbox" name="Dtierra" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas con piso de material diferente de tierra", "getVivienda")} /><label>Viviendas particulares habitadas con piso de material diferente de tierra</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con piso de tierra" type="checkbox" name="Ptierra" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas con piso de tierra", "getVivienda")} /><label>Viviendas particulares habitadas con piso de tierra</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con un dormitorio" type="checkbox" name="Dormitorio" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas con un dormitorio", "getVivienda")} /><label>Viviendas particulares habitadas con un dormitorio</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con dos dormitorios y mas" type="checkbox" name="Masdormi" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas con dos dormitorios y mas", "getVivienda")} /><label>Viviendas particulares habitadas con dos dormitorios y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con un solo cuarto" type="checkbox" name="Uncuarto" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas con un solo cuarto", "getVivienda")} /><label>Viviendas particulares habitadas con un sólo cuarto</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con dos cuartos" type="checkbox" name="Doscuart" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas con dos cuartos", "getVivienda")} /><label>Viviendas particulares habitadas con dos cuartos</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas con 3 cuartos y mas" type="checkbox" name="Trescua" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas con 3 cuartos y mas", "getVivienda")} /><label>Viviendas particulares habitadas con 3 cuartos y mas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de energia electrica" type="checkbox" name="Electrica" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de energia electrica", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de energía eléctrica</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de energia electrica" type="checkbox" name="NoElec" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de energia electrica", "getVivienda")} /><label>Viviendas particulares habitadas que no disponen de energía eléctrica</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de agua entubada en el ambito de la vivienda" type="checkbox" name="Entubada" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de agua entubada en el ambito de la vivienda", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de agua entubada en el ámbito de la vivienda</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de agua entubada y se abastecen del servicio publico de agua" type="checkbox" name="SerPub" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de agua entubada y se abastecen del servicio publico de agua", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de agua entubada y se abastecen del servicio público de agua</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de agua entubada en el ambito de la vivienda" type="checkbox" name="Noagua" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de agua entubada en el ambito de la vivienda", "getVivienda")} /><label>Viviendas particulares habitadas que no disponen de agua entubada en el ámbito de la vivienda</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de tinaco" type="checkbox" name="Tinaco" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de tinaco", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de tinaco</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de cisterna o aljibe" type="checkbox" name="Cisterna" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de cisterna o aljibe", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de cisterna o aljibe</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de excusado o sanitario" type="checkbox" name="Sanitario" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de excusado o sanitario", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de excusado o sanitario</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de letrina (pozo y hoyo)" type="checkbox" name="Letrina" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de letrina (pozo y hoyo)", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de letrina (pozo y hoyo)</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de drenaje" type="checkbox" name="Drenaje" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de drenaje", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de drenaje" type="checkbox" name="Nodrenaje" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de drenaje", "getVivienda")} /><label>Viviendas particulares habitadas que no disponen de drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de energia electrica, agua entubada de la red publica y drenaje" type="checkbox" name="Totredpub" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de energia electrica, agua entubada de la red publica y drenaje", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de energía eléctrica, agua entubada de la red pública y drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de energia electrica, agua entubada ni drenaje" type="checkbox" name="Ndagua" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de energia electrica, agua entubada ni drenaje", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de energía eléctrica, agua entubada de la red pública y drenaje</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares que disponen de drenaje y sanitario con admision de agua" type="checkbox" name="Admagua" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de drenaje y sanitario con admision de agua", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de de drenaje y sanitario con admisión de agua</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que no disponen de automivil o camioneta, ni de motocicleta o motoneta" type="checkbox" name="Noauto" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que no disponen de automivil o camioneta, ni de motocicleta o motoneta", "getVivienda")} /><label>Viviendas particulares habitadas que no disponen de automivil o camioneta, ni de motocicleta o motoneta</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin ningun bien" type="checkbox" name="Sinbien" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas sin ningun bien", "getVivienda")} /><label>Viviendas particulares habitadas sin ningun bien</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de refrigerador" type="checkbox" name="Refri" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de refrigerador", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de Refrigerador</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de lavadora" type="checkbox" name="Lavadora" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de lavadora", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de Lavadora</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de horno de microondas" type="checkbox" name="Horno" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de horno de microondas", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de horno de microondas</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de automivil o camioneta" type="checkbox" name="Camioneta" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de automivil o camioneta ", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de automivil o camioneta</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de bicicleta como medio de transporte" type="checkbox" name="Bici" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de bicicleta como medio de transporte ", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de bicicleta como medio de transporte</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de radio" type="checkbox" name="Radio" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de radio", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de radio</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de television" type="checkbox" name="Tele" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de television", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de televisión</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de computadora, laptop o tablet" type="checkbox" name="laptop" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de computadora, laptop o tablet", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de computadora, laptop o tablet</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de linea telefonica fija" type="checkbox" name="Telefono" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de linea telefonica fija", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de linea telefonica fija</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de telefono celular" type="checkbox" name="Celular" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de telefono celular", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de telefono celular</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de Internet" type="checkbox" name="Internet" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de Internet", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de Internet</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de servicio de television de paga" type="checkbox" name="Tvpaga" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de servicio de television de paga", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de servicio de television de paga</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de servicio de peliculas, musica o video de paga por Internet" type="checkbox" name="Pelipaga" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de servicio de peliculas, musica o video de paga por Internet ", "getVivienda")} /><label>Viviendas particulares habitadas que disponen deservicio de peliculas, musica o video de paga por Internet </label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas que disponen de consola de videojuegos" type="checkbox" name="Consola" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas que disponen de consola de videojuegos", "getVivienda")} /><label>Viviendas particulares habitadas que disponen de consola de videojuegos</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin radio ni television" type="checkbox" name="Sinradio" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas sin radio ni television", "getVivienda")} /><label>Viviendas particulares habitadas sin radio ni television</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin linea telefonica fija ni telefono celular" type="checkbox" name="Sinlinea" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas sin  linea telefonica fija ni telefono celular", "getVivienda")} /><label>Viviendas particulares habitadas sin de linea telefonica fija ni telefono celular</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin computadoras e Internet" type="checkbox" name="SinInter" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas sin computadoras e Internet", "getVivienda")} /><label>Viviendas particulares habitadas sin computadoras e Internet</label>
                            </div>
                            <div className="opcion">
                              <input id="Viviendas particulares habitadas sin tecnologias de la informacion y comunicacion (TIC)" type="checkbox" name="Sintic" onClick={(e) => mostrarCapaManzanas(e, "", "pobtot>0", "Viviendas particulares habitadas sin tecnologias de la informacion y comunicacion (TIC)", "getVivienda")} /><label>Viviendas particulares habitadas sin tecnologias de la informacion y comunicacion (TIC)</label>
                            </div>
                          </li>
                        </ul>

                      </li>





                    </ul>


                  </CardBody>
                </Card>
              </Collapse>

              <Button
                className="btnS3 btn-secondary"
                onClick={() => {
                  setColapsarPresupuesto(!colapsarPresupuesto)
                }}
                style={{ marginBottom: "1rem" }}
              >
                Geoprocesos
              </Button>
              <Collapse isOpen={colapsarPresupuesto}>
                <Card>
                  <CardBody className="cardM">
                    <br></br>
                    {
                      /*
                      muniss.length > 0 && (
                        <div className="row">
                          <div className="col-3">Municipio</div>
                          <div className="col-9">
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              isClearable={true}
                              onChange={selectMunicipioManz}
                              name="municipios"
                              options={muniss}
                            />
                          </div>
                        </div>
                      )
                      */
                    }


                    <ul id="menu">
                      <li>
                        <input id="poblacion" type="checkbox" name="poblacion" /><label>Geoprocesos</label>
                        <ul className="interior">
                          <li>
                            <div className="opcion">
                              <input id="AREAS_INFLUENCIA_CANIBALIZACION" type="checkbox" name="AREAS_INFLUENCIA_CANIBALIZACION" onClick={(e) => mostrarCapasGeoprocesos(e, "AREAS_INFLUENCIA_CANIBALIZACION", "", "AREAS DE INFLUENCIA(CANIBALIZACION)", "getAreasCaniba")} /><label>AREAS DE INFLUENCIA(CANIBALIZACION)</label>
                            </div>
                            <div className="opcion">
                              <input id="AREAS_RUVALCABA_SERVICE" type="checkbox" name="AREAS_RUVALCABA_SERVICE" onClick={(e) => mostrarCapasGeoprocesos(e, "AREAS_RUVALCABA_SERVICE", "", "AREAS(RUVALCABA SERVICE)", "getAreasCaniba")} /><label>AREAS(RUVALCABA SERVICE)</label>
                            </div>
                            <div className="opcion">
                              <input id="CLIENTES_ATENDIDOS_Y_NO_ATENDIDOS" type="checkbox" name="CLIENTES_ATENDIDOS_Y_NO_ATENDIDOS" onClick={(e) => mostrarCapasGeoprocesos(e, "CLIENTES_ATENDIDOS_Y_NO_ATENDIDOS", "", "CLIENTES(ATENDIDOS Y NO ATENDIDOS)", "getDistancias")} /><label>CLIENTES(ATENDIDOS Y NO ATENDIDOS)</label>
                            </div>
                            <div className="opcion">
                              <input id="buffer" type="checkbox" name="buffer" onClick={(e) => calcularBuffer(e)} /><label>Obtener Buffer</label>
                            </div>

                          </li>
                        </ul>
                      </li>
                    </ul>


                  </CardBody>
                </Card>
              </Collapse>



            </div>

          </div>
        </div>
        <MapContainer
          //20.68924342376687, -88.85523520325107
          //20.817975372868176, -88.66845180043808
          //20.250724780155917, -88.77620730149292
          /*
          maxBounds={[
            [34, -86],
            [14, -120],
          ]}*/
          center={[24.26825996870948, -102.88361673036671]}
          zoom={5}
          zoomDelta={0.25}
          zoomSnap={0.25}
          zoomControl={false}
          doubleClickZoom={false}
          whenCreated={setmapaReferencia}
          minZoom={5.75}
          maxBoundsViscosity={0.7}
        >
          <ZoomControl
            position="topright"
            zoomInTitle="Acercar"
            zoomOutTitle="Alejar"
          />
          <ScaleControl maxWidth="100" position={"bottomright"} />
          <LayersControl>
            <BaseLayer checked name="Open street map">
              <TileLayer
                attribution='&copy; Powered by Geoapify | © OpenMapTiles © OpenStreetMap contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="INEGI">
              <WMSTileLayer
                url="http://gaiamapas2.inegi.org.mx/mdmCache/service/wms?"
                layers="MapaBaseTopograficov61_sinsombreado_gris"
              />
            </BaseLayer>
            <BaseLayer name="ESRI calles">
              <TileLayer
                attribution='&copy; <a href="#">ESRI</a> contributors'
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <BaseLayer name="ESRI Satélite">
              <TileLayer
                attribution='&copy; <a href="#">WorldImagery</a> contributors'
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <BaseLayer name="Topográfico">
              <TileLayer
                attribution='&copy; <a href="#">opentopomap</a> contributors'
                url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
          </LayersControl>
          <FeatureGroup ref={(e) => grupoDibujos(e)}>
            <EditControl
              position='topright'
              draw={{
                circlemarker: false,
              }}></EditControl>

          </FeatureGroup>

        </MapContainer>
      </div>
    </>
  );
}

export default React.memo(MapView);
