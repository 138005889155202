import Simbologia from "./Simbologia";
import estilosC from "../../json/estilos.json";
import $ from "jquery";
import L from "leaflet";

const root = "https://geosinav30.conagua.gob.mx:8080/geoserver/Sina/ows";
//const root = "http://localhost:8080/geoserver/Sina/ows";

let defaultParameters = {
  service: "WFS",
  version: "1.0.0",
  request: "GetFeature",
  outputFormat: "application/json",
  srsName: "EPSG:4326",
};

var colorF = 0;
var opcion;
var Sim = new Simbologia();
var featureSeleccion = [];
var datosCapasPeticion;

var MarkerOptions = {
  radius: 1,
  fillColor: colorF,
  color: "#000000",
  weight: 1,
  opacity: 1,
  fillOpacity: 0.8,
};

class Capas {
  agregarCapas(
    modulo,
    capasVisualizadas,
    name,
    titulo,
    filtro,
    anioC,
    entidadSelect,
    grupo,
    cultivo,
    participant
  ) {
    //console.log(name)
    //console.log(titulo)
    //console.log(filtro)
    //console.log(anioC)
    //console.log(entidadSelect}9;
    //console.log(grupo);

    //pasos generales para todas las capas
    delete defaultParameters.cql_filter;
    var estilos;
    let capaWFS = {};
    let indexEstilo = this.buscarEstilo(name); //buscamos es estilo de la capa para asignarlos
    if (indexEstilo !== undefined) {
      estilos = {
        color: indexEstilo.colorBorde,
        fillColor: indexEstilo.colorRelleno,
        opacity: "1",
        fillOpacity: indexEstilo.fillOpacity,
        radius: "5",
        weight: "2",
      };
      MarkerOptions = {
        radius: 1,
        fillColor: indexEstilo.colorRelleno,
        color: indexEstilo.colorBorde,
        weight: 1,
        opacity: 1,
        fillOpacity: indexEstilo.fillOpacity,
      };
    } else {
      estilos = {
        color: "#000000",
        fillColor: colorF,
        opacity: "1",
        fillOpacity: "1",
        radius: "5",
        weight: "2",
      };
      MarkerOptions = {
        radius: 1,
        fillColor: colorF,
        color: "#000000",
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      };
    }
    let encontrada;
    //buscamos que la capa no este ya desplegada
    if (modulo === "10") {
      if (titulo === "Distribución por entidad" || titulo === "Presupuesto mensual por RHA" ||
        titulo === "Presupuesto mensual por dirección local") {
        encontrada = this.buscarCapa(`${titulo}_${anioC}_${grupo}`, capasVisualizadas);
      } else {
        encontrada = this.buscarCapa(`${titulo}_${anioC}`, capasVisualizadas);
      }
    } else {
      if (modulo === "16") {
        encontrada = this.buscarCapa(`${titulo}_${anioC}`, capasVisualizadas);
      } else {
        if (modulo === "08") {
          encontrada = this.buscarCapa(`${titulo}_${anioC}`, capasVisualizadas);
        } else {
          if (modulo === "04") {
            if (titulo === "Censo Estatal") {
              encontrada = this.buscarCapa(`${titulo} ${anioC}`, capasVisualizadas);
            } else {
              if (titulo === "Censo Municipal") {
                encontrada = this.buscarCapa(`${titulo} ${entidadSelect.label} ${anioC}`, capasVisualizadas);
              } else {
                encontrada = this.buscarCapa(`${titulo}`, capasVisualizadas);
              }

            }
          } else {
            if (modulo === "05" || modulo === "06") {
              if (titulo === "Superficie Sembrada por Entidad" || titulo === "Productividad del Cultivo por Entidad") {
                encontrada = this.buscarCapa(`${titulo}_${anioC}_${cultivo}`, capasVisualizadas);
              } else {
                encontrada = this.buscarCapa(`${titulo}_${anioC}`, capasVisualizadas);
              }
            } else {
              if (modulo === "01") {
                encontrada = this.buscarCapa(`${titulo} ${entidadSelect.label} ${anioC}`, capasVisualizadas);
              } else {
                if (modulo === "11") {
                  if (titulo === "Prestadores a Nivel Nacional") {
                    encontrada = this.buscarCapa(`${titulo}`, capasVisualizadas);
                  } else if (titulo === "Participantes en AquaRating") {
                    encontrada = this.buscarCapa(`${titulo} ${participant.label} ${anioC}`, capasVisualizadas);
                  } else {
                    encontrada = this.buscarCapa(`${titulo} ${entidadSelect.label} ${anioC}`, capasVisualizadas);
                  }
                } else {
                  if (modulo === "07") {
                    //console.log("modulo 07")
                    if (titulo === "Superficie Sembrada por Entidad" || titulo === "Productividad del Cultivo por Entidad") {
                      encontrada = this.buscarCapa(`${titulo}_${anioC}_${cultivo}`, capasVisualizadas);
                    } else {
                      encontrada = this.buscarCapa(`${titulo} (${anioC})`, capasVisualizadas);
                    }
                  } else {
                    if (modulo === "20") {
                      encontrada = this.buscarCapa(`${titulo} ${anioC}`, capasVisualizadas);
                      console.log(encontrada);
                    } else {
                      encontrada = this.buscarCapa(`${titulo}`, capasVisualizadas);
                    }
                  }

                }

              }

            }

          }

        }
      }
    }

    //console.log(encontrada)
    if (encontrada === null) {
      if (modulo === "16" || modulo === "20") {
        defaultParameters.cql_filter = `${filtro}'${anioC}'`;
      }
      //si es null indica que la capa no esta en el arreglo de capas visualizadas y se puede agregar
      if (modulo === "00") {
        //se agrega la capa
        if (filtro !== "") {
          defaultParameters.cql_filter = filtro;
        }
      }
      if (modulo === "01") {
        if (
          modulo === "01" &&
          (titulo === "Concesiones por municipio para Aguas Residuales" ||
            titulo ===
            "Concesiones por municipio para Aprovechamientos Subterráneos" ||
            titulo ===
            "Concesiones por municipio para Aprovechamientos Superficiales" ||
            titulo === "Concesiones por municipio para Zonas Federales" ||
            titulo === "Concesiones para Descarga de Aguas Residuales" ||
            titulo === "Concesiones para Aprovechamientos Subterráneos" ||
            titulo === "Concesiones para Aprovechamientos Superficiales" ||
            titulo === "Concesiones para Zonas Federales")
        ) {
          defaultParameters.cql_filter = `estado='${entidadSelect.label}' and corte=${anioC}`;
        } else {
          defaultParameters.cql_filter = `estado='${entidadSelect.label}' and corterepda=${anioC}`;
        }
      }

      if (modulo === "02") {
        if (filtro !== "anio=") {
          defaultParameters.cql_filter = `${filtro} AND anio=${anioC}`;
        } else {
          defaultParameters.cql_filter = `${filtro}${anioC}`;
        }
      }
      if (modulo === "11") {
        if (titulo === "Participantes en AquaRating") {
          defaultParameters.cql_filter = `${filtro} acuarating='${participant.value}'`;
        } else
          if (titulo === "Prestadores por Entidad Federativa") {
            defaultParameters.cql_filter = `${filtro} entidad='${entidadSelect.label}'`;
          } else {
            defaultParameters.cql_filter = `idprestadorserv>1`;
          }
      }
      if (modulo === "10") {
        if (
          titulo === "Presupuesto por región" ||
          titulo === "Presupuesto Ejercido Nacional"
        ) {
          defaultParameters.cql_filter = `ciclo='${anioC}'`;
        } else if (titulo === "Presupuesto mensual por RHA" ||
          titulo === "Presupuesto mensual por dirección local") {
          defaultParameters.cql_filter = `anio='${anioC}' and mes='${grupo}'`;
        } else {
          defaultParameters.cql_filter = `ciclo='${anioC}' and ppgrupo='${grupo}'`;
        }
      }
      if (modulo === "08") {
        if (name === "m08_recaudacionuso" || name === "m08_cobroderechos") {
          //mensaje("Cargando información por favor espere.", "info");
          defaultParameters.cql_filter = "anio=" + anioC;
        }
      }
      if (modulo === "04") {
        if (filtro !== "") {
          if (entidadSelect !== null && entidadSelect.label !== "") {
            defaultParameters.cql_filter = `entidad='${entidadSelect.label}'`;
          }
        }
        if (name === `m04_zonasatencionprioritaria`) {
          if (entidadSelect !== null && entidadSelect.label !== "") {
            defaultParameters.cql_filter = `entidad='${entidadSelect.label}'`;
          }
        }
        if (name === `m04_censomun${anioC}`) {
          if (entidadSelect !== null && entidadSelect.label !== "") {
            defaultParameters.cql_filter = `entidad='${entidadSelect.label}'`;
          }
        }
      }
      if (modulo === "06" || modulo === "07" || modulo === "05" || modulo === "18") {
        defaultParameters.cql_filter = `${filtro}${anioC}`;
      }

      defaultParameters.typeName = `Sina:${name}`;

      var parameters = L.Util.extend(defaultParameters);
      var urlS = root + L.Util.getParamString(parameters);

      //console.log(urlS)
      //se piden las capas al geoserverrCapasRepda
      //capaWFS = this.pedirCapas(urlS);
      capaWFS['urlS'] = urlS;



      //if (capaWFS.features.length !== 0) {
      //La capa tiene informacion y se agrega al mapa
      if (name === "m08_recaudacionuso" || name === "m08_cobroderechos") {
        capaWFS["nombre"] = titulo + "_" + anioC;
        capaWFS["titulo"] = titulo;
        capaWFS["name"] = `${name}`;
      } else {
        if (modulo === "01") { //modulo === "04" ||
          if (entidadSelect !== null && entidadSelect.label !== undefined) {
            capaWFS["nombre"] = `${titulo} ${entidadSelect.label} ${anioC}`;
            capaWFS["titulo"] = `${titulo} ${entidadSelect.label} ${anioC}`;
            capaWFS["name"] = name;
          }
        } else {
          if (modulo === "02" || modulo === "06" || modulo === "05" || modulo === "16" || modulo === "18") {
            capaWFS["nombre"] = `${titulo}_${anioC}`;
            capaWFS["titulo"] = `${titulo}_${anioC}`;
            capaWFS["name"] = name;
          } else {
            if (modulo === "07") {
              if (titulo === "Superficie sembrada" || titulo === "Superficies Físicas Regadas por Entidad" || titulo === "Valor de la Producción" || titulo === "Superficie Regada" || titulo === "Volúmenes Distribuidos") {
                capaWFS["nombre"] = `${titulo} (${anioC})`;
                capaWFS["titulo"] = `${titulo} (${anioC})`;
                capaWFS["name"] = name;
              } else {
                capaWFS["nombre"] = `${titulo}_${anioC}_${cultivo}`;
                capaWFS["titulo"] = `${titulo}_${anioC}_${cultivo}`;
                capaWFS["name"] = name;
              }
            } else {
              if (modulo === "10") {
                if (titulo === "Presupuesto Ejercido Nacional" || titulo === "Presupuesto por región") {
                  capaWFS["nombre"] = `${titulo}_${anioC}`;
                  capaWFS["titulo"] = `${titulo}_${anioC}`;
                  capaWFS["name"] = name;
                } else {
                  capaWFS["nombre"] = `${titulo}_${anioC}_${grupo}`;
                  capaWFS["titulo"] = `${titulo}_${anioC}_${grupo}`;
                  capaWFS["name"] = name;
                }
              } else {

                if (modulo === "04") {
                  if (titulo === "Censo Estatal") {
                    capaWFS["nombre"] = `${titulo} ${anioC}`;
                    capaWFS["titulo"] = `${titulo} ${anioC}`;
                    capaWFS["name"] = name;
                  } else {
                    if (titulo === "Censo Municipal") {
                      capaWFS["nombre"] = `${titulo} ${entidadSelect.label} ${anioC}`;
                      capaWFS["titulo"] = `${titulo} ${entidadSelect.label} ${anioC}`;
                      capaWFS["name"] = name;
                    } else {
                      capaWFS["nombre"] = `${titulo}`;
                      capaWFS["titulo"] = `${titulo}`;
                      capaWFS["name"] = name;

                    }
                  }
                } else {
                  if (modulo === "11") {
                    if (titulo === "Participantes en AquaRating") {
                      capaWFS["nombre"] = `${titulo} ${participant.label} ${anioC}`;
                      capaWFS["titulo"] = `${titulo} ${participant.label} ${anioC}`;
                      capaWFS["name"] = name;
                    } else if (titulo === "Prestadores por Entidad Federativa") {
                      capaWFS["nombre"] = `${titulo} ${entidadSelect.label} ${anioC}`;
                      capaWFS["titulo"] = `${titulo} ${entidadSelect.label} ${anioC}`;
                      capaWFS["name"] = name;
                    } else {
                      capaWFS["nombre"] = titulo;
                      capaWFS["titulo"] = titulo;
                      capaWFS["name"] = name;
                    }
                  }
                  else {
                    //capas base
                    capaWFS["nombre"] = titulo;
                    capaWFS["titulo"] = titulo;
                    capaWFS["name"] = name;

                  }
                }
              }
            }

          }
        }
      }
      capaWFS["descarga"] = urlS;
      capaWFS["layers"] = defaultParameters.typeName;
      capaWFS["estilo"] = estilos;
      if (modulo === "07") {
        if (titulo === "Superficie sembrada" || titulo === "Superficies Físicas Regadas por Entidad" || titulo === "Valor de la Producción" || titulo === "Superficie Regada" || titulo === "Volúmenes Distribuidos") {
          capaWFS["leyenda"] = `${titulo}`;
        } else {
          capaWFS["leyenda"] = `${titulo} (${cultivo})`;
        }
      } else {
        capaWFS["leyenda"] = titulo;
      }
      if (modulo === "11") {
        if (entidadSelect === null && entidadSelect === null && participant === null) {
          capaWFS["nombre"] = titulo;
          capaWFS["titulo"] = titulo;
          capaWFS["name"] = name;
        }
      }
      if (modulo === "20") {
        capaWFS["nombre"] = `${titulo} ${anioC}`;
        capaWFS["titulo"] = `${titulo} ${anioC}`;
        capaWFS["name"] = name;
        capaWFS["leyenda"] = `${titulo} ${anioC}`;
      }

      capaWFS.isActive = true;


      //console.log(capaWFS);

      //} else {
      //  return null;
      // }
    }



    return capaWFS;
  }

  /**Funcion que busca el indice del estilo de la capa a agregar */
  buscarEstilo(name) {
    let i;
    estilosC.map((item, index) => {
      if (item.capa === name) {
        i = item;
      }
    });
    return i;
  }

  buscarCapa(name, capasVisualizadas) {
    //1- encontrada 0-noencontrada
    let siesta = null;
    capasVisualizadas.forEach((element, index) => {
      if (element.nombre === name) {
        siesta = index;
      }
    });
    return siesta;
  }

  //funcion que hace la peticion al geoserver
  pedirCapas(url) {
    var capa = {};
    capa["url"] = url;
    $.ajax({
      url: url,
      async: true,
      type: 'GET',
      success: function (response) {
        //console.log(response);
        capa.response = response;
        capa["attribution"] = "No disponible";
        capa["transparencia"] = 1;
        capa["tipo"] = "wfs";
        capa["habilitado"] = true;
        capa["format"] = "json";
        capa["servicio"] = root;
        capa["features"] = response.features;
      },
    });
    return capa;


    /*
    this.peticion(url);
    //console.log(datosCapasPeticion);
    if (datosCapasPeticion !== undefined) {
      capa.response = datosCapasPeticion;
      capa["attribution"] = "No disponible";
      capa["transparencia"] = 1;
      capa["tipo"] = "wfs";
      capa["habilitado"] = true;
      capa["format"] = "json";
      capa["servicio"] = root;
      capa["features"] = datosCapasPeticion.features;

      return capa;
    }
    */

  }
  /*
     peticion(url) {
      //
  
      const request = new XMLHttpRequest();
      request.open("GET", url, false); // `false` makes the request synchronous
      request.send(null);
  
      if (request.status === 200) {
        datosCapasPeticion = JSON.parse(request.responseText);
        //console.log(request.responseText);
      }
  
      /*
      var xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          //alert(xhr.responseText);
          datosCapasPeticion = JSON.parse(this.responseText);
          //console.log(datosCapasPeticion);
        }
      };
      xhr.open("GET", url, true);
      xhr.send(null);
       }
      */




}

export default Capas;
