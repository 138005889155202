class Imagen{
    getImagen(categoria){
        let img;
        if (categoria === "Depresión Tropical"){
            img="images/depresion.png"
        }
        if (categoria === "Tormenta Tropical"){
            img="images/TormentaTropical.png"
        }
        if (categoria === "Huracán categoria 1"){
            img="images/huracan01.png"
        }
        if (categoria === "Huracán categoria 2"){
            img="images/huracan02.png"
        }
        if (categoria === "Huracán categoria 3"){
            img="images/huracan03.png"
        }
        if (categoria === "Huracán categoria 4"){
            img="images/huracan04.png"
        }
        if (categoria === "Huracán categoria 5"){
            img="images/huracan05.png"
        }
        return img;
    }

}

export default Imagen;