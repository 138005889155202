class Presupuesto {
    getGrafica(data) {
        //console.log(data)
        let header = Object.keys(data[0]);
        
        header.splice(0,2);
        //console.log(header);
        let aux = [];
        data.map(item => {
            let aux2=[];
            let anio=2012;
            for (let index = 0; index < header.length; index++){
                let v = {};
                v.x=anio;
                v.y=Number(item[header[index]]).toFixed(2);
                v.z=item['idppgrupo'];
                anio++;
                aux2.push(v);
            }
            aux.push(aux2);
        });
        
        //console.log(aux);

        return aux;
    }

    getDatosReporteNac(data) {
        //console.log(data);
         let aux = [];
         data.map(item => {
             if (item.unidad !== "") {
                 let valores = {};
                 valores.id=item.id;
                 valores.unidad = item.unidad;
                 valores.monto = item.monto;
                 aux.push(valores);
             }
         })
         aux.sort();
         //console.log(aux);
         return aux;
     }

    getFiltrarAnio(data,anio){
        //console.log(data);
        //console.log(anio);

        let final=[]
        data.map(item=>{
            let aux={}
            item.map(it=>{
                if(it.x===anio){
                    aux.programa=it.z
                    aux.presupuesto=Number(it.y)
                    final.push(aux);
                }

            })
            
        })

        //console.log(final);
        return final;

    }



}

export default Presupuesto;