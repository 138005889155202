class Generales{

    quitarNull(data){
        let header = Object.keys(data[0]);
        data.map(item=>{
            for (let index = 0; index < header.length; index++) {
                if(item[header[index]]==="NULL"){
                    item[header[index]]='';
                }                
            }

        });
        //data.sort().reverse();
        //this.sortJSON(data, 'idppgrupo', 'desc');
        return data;

    }

    sortJSON(data, key, orden) {
        return data.sort(function (a, b) {
            var x = a[key],
            y = b[key];
    
            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
    
            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }
}

export default Generales;