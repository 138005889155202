import React from "react";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="row border-top">
                        <div className="col-md-2 padding_bottom1">
                            <img
                                src="imgs/logob.svg"
                                alt="#"
                                className="img-fluid"
                                width={150}
                            />
                        </div>
                        <div className="col-md-2 padding_bottom1">
                            <h3>EMPRESA</h3>
                            <ul className="cont">
                                <Link to={"/about"} className={({ isActive }) => isActive ? 'active' : ''}>
                                    <li className="nav-item">
                                        <a className="nav-link itemH" aria-current="page">
                                            Acerca de
                                        </a>
                                    </li>
                                </Link>
                                <Link to={"/clientes"} className={({ isActive }) => isActive ? 'active' : ''}>
                                    <li className="nav-item">
                                        <a className="nav-link itemH" href="#">
                                            Soluciones
                                        </a>
                                    </li>
                                </Link>
                            </ul>
                        </div>
                        <div className="col-md-3 padding_bottom1">
                            <h3>CONTACTO</h3>
                            <ul className="cont">
                                <li>
                                    7291400075
                                </li>
                                <li>
                                    7225856709
                                </li>
                                <li>
                                    e-mail: geomarket.223@gmail.com
                                </li>
                                <li>
                                    geomark.contacto@gmail.com
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 padding_bottom1">
                            <h3>PRODUCTOS Y SERVICIOS</h3>
                            <ul className="cont">
                                <li>
                                    Creación de catálogos web
                                </li>
                                <li>
                                    Paginas web
                                </li>
                                <li>
                                    Portales web geoespaciales
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-2 padding_bottom1">
                            <h3>PRIVACIDAD</h3>
                            <ul className="cont">
                                <li data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    Información Legal
                                </li>
                                <li>
                                    Condiciones de Uso
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <p>© 2022 All Rights Reserved.</p>
                            </div>
                            <div className="col-md-1">
                                <a href="https://instagram.com/geomark.mx?igshid=MzRlODBiNWFlZA==" target="_blank">
                                    <img
                                        src="imgs/inst.png"
                                        alt="#"
                                        className="img-fluid"
                                        width={"60%"}
                                    />
                                </a>

                            </div>
                            <div className="col-md-1">
                                <a href="https://www.facebook.com/GeoMark.mx?mibextid=ZbWKwL" target="_blank">
                                    <img
                                        src="imgs/facebook.png"
                                        alt="#"
                                        className="img-fluid"
                                        width={"60%"}
                                    />
                                </a>
                            </div>
                            <div className="col-md-1">
                                <img
                                    src="imgs/link.png"
                                    alt="#"
                                    className="img-fluid"
                                    width={"60%"}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">AVISO DE PRIVACIDAD INTEGRAL</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5> <strong>IDENTIDAD Y DOMICILIO DEL RESPONSABLE DEL TRATAMIENTO</strong></h5>
                            <p>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su reglamento, los Lineamientos del Aviso de Privacidad y demás legislación aplicable, GeoMark, en su carácter de Responsable, con domicilio para oír y recibir cualquier tipo de notificaciones, ubicado en Hermenegildo Galeana, No. 100, C.P. 51380, San Antonio Acahualco, Zinacantepec, Estado de México; teléfono celular 729-140-0075; correo electrónico geomark.223@gmail.com, sitio web https://geomark.com.mx/; está obligado a observar los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad, previstos en la Ley. </p>
                            <p>Asimismo, <strong>GeoMark</strong> será Responsable del uso que se le dé a los mismos y de su protección, a través de la implementación de medidas de seguridad técnicas, administrativas y físicas que permitan proteger sus Datos Personales contra cualquier daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado por parte del Titular.</p>
                            <p>Por lo anterior, GeoMark pone a su disposición el presente Aviso de Privacidad, con la finalidad de que el Titular de los Datos Personales, conozca los alcances y condiciones del Tratamiento de sus Datos Personales; el cual podrá consultar en cualquier momento en la página de internet: https://geomark.com.mx/  y/o redes sociales: @geomark.mx en Instagram y Facebook además de @geomark_mx en twitter finalmente @geomark mx en linkedin.</p>
                            <h5>TÉRMINOS</h5>
                            <p>De conformidad, con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, debe entenderse por:</p>
                            <ol type="I">
                                <li>I.      Aviso de Privacidad: Documento físico, electrónico o en cualquier otro formato generado por el responsable que es puesto a disposición del Titular, previo al tratamiento de sus datos personales, de conformidad con el artículo 15 de la presente Ley.</li>
                                <li>II.     Consentimiento: Manifestación de la voluntad del Titular de los datos mediante la cual se efectúa el tratamiento de los mismos. </li>
                                <li>III.	Datos personales: Cualquier información concerniente a una persona física identificada o identificable. </li>
                                <li>IV.	Datos personales sensibles: Aquellos datos personales que afecten a la esfera más íntima de su Titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste.
                                    En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.
                                </li>
                                <li>V.	Derechos ARCO: Son los derechos de Acceso, Rectificación, Cancelación y Oposición que cada Titular tiene, en relación con sus Datos Personales recabados por el Responsable y/o sus Encargados.
                                    <ul>
                                        <li>a)	Derecho de Acceso: Es el derecho que tiene el Titular de conocer sobre los Datos Personales relacionados a su persona que obren en poder del Responsable o de sus Encargados, así como, del tratamiento que se le dé a los mismos.</li>
                                        <li>b)	Derecho a la Rectificación: Cada Titular tiene derecho a que sus Datos Personales sean rectificados cuando éstos sean inexactos o incompletos. </li>
                                        <li>c)	Derecho de Cancelación: Es el derecho que tiene cada Titular a solicitar en cualquier momento que se supriman sus Datos Personales. La cancelación se llevará a cabo una vez que transcurra el periodo de bloqueo. El bloqueo implica la identificación y conservación de los Datos Personales, una vez que se cumpla la finalidad para la cual fueron recabados, con el propósito de determinar las posibles responsabilidades en relación con su tratamiento, hasta el plazo de prescripción legal o contractual de éstas. Durante dicho periodo, sus Datos Personales no serán objeto de tratamiento y transcurrido éste, se procederá con su cancelación en la base de datos o archivo correspondiente. Una vez cancelado el dato correspondiente, el Responsable le dará al Titular el aviso correspondiente. </li>
                                        <li>d)	Derecho de Oposición: El Titular tiene el derecho a solicitar, siempre que tenga una causa legítima, que el Responsable deje de tratar sus Datos Personales. </li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                </li>
                                <li></li>
                            </ol>
                            <h5>CATEGORÍAS DE DATOS PERSONALES SUJETOS A TRATAMIENTO</h5>
                            <p>Para cumplir con las finalidades del tratamiento señaladas en el presente Aviso de Privacidad, es necesario que GeoMark como Responsable trate los siguientes datos personales: datos de identificación y contacto (nombre completo, ocupación), Registro Federal de Contribuyentes (RFC) y datos de localización (domicilio particular, teléfono fijo, teléfono móvil, correo electrónico), así como información técnica y características (atributos, operaciones, identificadores, señales, configuración, red y conexiones y cookies) para acceder a nuestros servicios.</p>
                            <h5>DATOS PERSONALES SENSIBLES</h5>
                            <p>Para cumplir con las finalidades del tratamiento señaladas en el presente Aviso de Privacidad, es necesario que GeoMark requiera los siguientes Datos Personales Sensibles: imagen en fotografía, datos patrimoniales o financieros, como: nombre del Titular de tarjeta de débito y/o crédito y número de tarjeta de débito y/o crédito.</p>
                            <h5>¿DE QUÉ MANERA RECABAMOS TUS DATOS PERSONALES?</h5>
                            <p>GeoMark recolectará los Datos Personales del respectivo Titular, a través del empleo de medios electrónicos en los siguientes supuestos:</p>
                            <ul>
                                <li>•	Solicite información a través de medios tecnológicos a nuestros representantes comerciales o Encargados autorizados;</li>
                                <li>•	Intercambie correos electrónicos, llamadas o mensajes por redes sociales con Encargados autorizados;</li>
                                <li>•	Cuando ejerce sus Derechos A.R.C.O;</li>
                                <li>•	A través de un incidente relacionado con los servicios que ofrecemos;</li>
                                <li>•	En el supuesto de que el Titular visite el sitio web y redes sociales de GeoMark; </li>
                                <li>•	Cuando el Titular previo consentimiento otorgado al Responsable proporciona sus Datos Personales para suscribirse a un plan o modelo de suscripción;</li>
                                <li>•	Por medio de la recolección de Datos Personales a través de la tecnología “cookies”, misma que nos permite personalizar los anuncios que aparecen en nuestro sitio web, así como recabar registro del mismo.</li>
                            </ul>
                            <h5>MEDIOS AUTOMÁTICOS PARA RECABAR DATOS PERSONALES</h5>
                            <p>Le informamos que al acceder al sitio web de GeoMark, es posible que situemos en su dispositivo algunos medios de rastreo tales como “cookies”, lo anterior permitirá a GeoMark mejorar la eficacia de sus sitios web y demás aplicaciones móviles. </p>
                            <p>Usted como Titular podrá deshabilitar el uso de estos medios automáticos siguiendo el procedimiento establecido para su navegador de internet.</p>

                            <h5>PROTECCIÓN A MENORES</h5>
                            <p>GeoMark, no recabará información directamente de niños, niñas u adolescentes, por lo que, de ser necesario, dicha información deberá ser proporcionada, en su caso, por el padre, madre, tutor y/o persona con patria potestad. En aquellos casos en los que GeoMark logre identificar que un niño, niña u adolescente haya proporcionado su información personal sin el consentimiento de la persona que ejerce su patria potestad o tutela procederá a la cancelación de dicha información y de su plan de suscripción.</p>

                            <h5>FINALIDADES PRIMARIAS DEL TRATAMIENTO</h5>
                            <p>GeoMark tratará los Datos Personales descritos en el presente Aviso para llevar a cabo alguna o todas las actividades necesarias para el desarrollo y cumplimiento de las obligaciones que se originen con usted, que incluyen las siguientes finalidades:</p>

                            <ul>
                                <li>•	Realizar un análisis geoespacial a partir del domicilio del almacén, punto de distribución o local que usted proporcione a GeoMark con el propósito de determinar la disponibilidad de vehículos en la zona para así crear rutas optimas.</li>
                                <li>•	Efectuar estudios sobre datos demográficos, intereses y comportamiento de sus clientes, consumidores, proveedores, y de aquellos terceros con los que trate.</li>
                                <li>•	Procesar su registro y suscripción en cualquiera de las modalidades previstas por GeoMark</li>
                                <li>•	Realizar actividades de facturación y procesar pagos a través de las formas y mecanismos habilitados por GeoMark relacionadas con el uso de nuestros servicios.</li>
                                <li>•	Procesamiento de solicitudes de información, dudas, aclaraciones, sugerencias, quejas y dar seguimiento a las mismas vía correo electrónico geomark.223@gmail.com. Asimismo, llevar un histórico de sus servicios.</li>
                                <li>•	Mantener la seguridad de las redes y servicios de comunicaciones electrónicas, detectar fallos o errores técnicos en la transmisión de las comunicaciones electrónicas, así como cualquier tratamiento que sea necesario para la correcta prestación de nuestro servicio.</li>
                                <li>•	Generar reportes y balances del estado de GeoMark de carácter interno y/o requeridos de conformidad con nuestros lineamientos y procedimientos internos.</li>
                                <li>•	Permitir a GeoMark contactarle a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos para hacer de su conocimiento cualquier modificación a los términos y condiciones aplicables a la prestación de servicios propios como pudieran ser paquetes, precios, condiciones de pago, facturación, características de los servicios y funcionalidades.</li>
                                <li>•	Dar cumplimiento a obligaciones previstas en la normatividad aplicable y atender requerimientos que formulen las autoridades competentes.</li>

                            </ul>
                            <h5>FINALIDADES SECUNDARIAS DEL TRATAMIENTO</h5>
                            <p>GeoMark tratará sus Datos Personales para llevar a cabo alguna o todas las finalidades secundarias que se mencionan a continuación:</p>
                            <ul>
                                <li>•	Realizar actividades de mercadotecnia, publicidad y prospección comercial.</li>
                                <li>•	Permitir a GeoMark contactarle a través de llamadas telefónicas, envío de comunicaciones a través de correos electrónicos, mensajes cortos y demás medios de comunicación físicos y/o electrónicos para compartir promociones, planes de suscripción de temporada y otros servicios novedosos acorde con las finalidades del Aviso.</li>
                                <li>•	Conservar los datos que nos hubiere proporcionado con el fin de contactarlo en seguimiento a las solicitudes iniciadas para contratación de nuestros productos y/o servicios de los cuales usted ha expresado interés.</li>
                                <li>•	Realizar encuestas de calidad sobre el sitio web y la atención al cliente de GeoMark.</li>
                            </ul>

                            <p>En el supuesto que el Titular o su representante debidamente acreditado, no se encuentre de acuerdo con que el Responsable trate sus Datos Personales para alguna de las finalidades primarias descritas, deberá enviar su solicitud vía correo electrónico en la dirección geomark.223@gmail.com, en donde exprese la no aceptación del tratamiento de sus Datos Personales, la cual será atendida por el Lic. en G. A. y R. H. Diego Armando Serrano Piedra, responsable de atender las mismas</p>
                            <p>No obstante, en el supuesto que el Titular no realice manifestación referente a la oposición para las finalidades del tratamiento de sus Datos y no sean considerados Datos Sensibles, patrimoniales o financieros; se considerará acordado y consentido su contenido, con base en lo dispuesto por el artículo 8°, párrafo tercero, de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
                            <p>Asimismo, el consentimiento del Titular podrá ser revocado en cualquier momento por éste, sin que se le atribuyan efectos retroactivos, en los términos y conforme a los procedimientos establecidos en el presente Aviso de Privacidad. </p>
                            <p>De igual manera, el Titular reconoce que no se requerirá de su consentimiento para el tratamiento de sus Datos Personales por parte del Responsable respaldado en los supuestos establecidos en el artículo 10 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.</p>
                            <p>En caso de que usted haya autorizado el uso de sus datos para las finalidades secundarias señaladas, le recordamos que GeoMark podra tratar sus Datos para dichos fines hasta en tanto usted no revoque dicha autorización, según lo señalado en los párrafos anteriores. La negativa para el uso de sus Datos Personales para fines secundarios, no podrá ser un motivo para negarle los servicios solicitados.</p>

                            <h5>TRANSFERENCIAS</h5>
                            <p>GeoMark no podrá transferir Datos Personales del respectivo Titular a terceros sin su consentimiento, salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como, a realizar esta transferencia en los términos que fija esa ley.</p>

                            <h5>TRANSFERENCIAS NO SUJETAS A SU CONSENTIMIENTO</h5>
                            <p>Con base en lo dispuesto por el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, GeoMark podrá transferir sus datos personales tanto a entidades nacionales como extranjeras sin requerir su consentimiento, especialmente en los siguientes supuestos:</p>
                            <ul>
                                <li>•	Cuando la transferencia sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la procuración o administración de justicia.</li>
                                <li>•	Cuando la transferencia sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.</li>
                                <li>•	Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el Responsable y el Titular.</li>

                            </ul>

                            <h5>PROCEDIMIENTO PARA EJERCER LOS DERECHOS ARCO</h5>
                            <p>El Titular de los Datos Personales o su representante debidamente acreditado, cuando proceda, podrán ejercer los derechos de Acceso, Rectificación, Cancelación u Oposición (A.R.C.O.) y revocación del consentimiento que la Ley Federal de Protección de Datos Personales en Posesión de los Particulares prevé, mediante un escrito libre que contenga la siguiente información:</p>
                            <ul>
                                <li>a)	Nombre del Titular domicilio y/o dirección de correo electrónico para comunicarle la respuesta a su solicitud. </li>
                                <li>b)	Documentos que acrediten la identidad del Titular de los Datos Personales (identificación oficial con fotografía, cédula profesional); o, en su caso, la representación legal del Titular de los datos personales, en términos de la legislación común, y cualquier otro elemento o documento que facilite la localización de los datos personales.</li>
                                <li>c)	La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados. </li>
                                <li></li>
                            </ul>

                            <p>En el caso de solicitudes de rectificación se deberán indicar las modificaciones a realizarse y aportar la documentación que sustente la petición.</p>
                            <p>La solicitud y los documentos antes mencionados se deberán presentar vía correo electrónico a la dirección geomark.223@gmail.com, dirigido al Lic. en G. A. y R. H. Diego Armando Serrano Piedra, responsable de atender las mismas y a su vez, de dar trámite a las solicitudes de los Titulares de Datos Personales para el ejercicio de los derechos A.R.C.O. y de revocación del consentimiento en un plazo no mayor a 20 (veinte) días hábiles contados a partir de su recepción. </p>
                            <p>Es importante mencionar que el ejercicio de cualquiera de los derechos A.R.C.O. y de revocación del consentimiento no es requisito previo ni impide el ejercicio de otro derecho. Si resulta procedente su solicitud, ésta se hará efectiva dentro de los 15 (quince) días hábiles siguientes a la fecha en que se comunique la respuesta. Los plazos antes referidos podrían ser ampliados una sola vez por un periodo igual, si así lo justifican las circunstancias.</p>
                            <p>La respuesta a las solicitudes de derechos A.R.C.O. y de revocación de consentimiento que cumplan con los requisitos antes mencionados podrá ser notificada al Titular de los Datos Personales o a su representante vía correo electrónico (por lo cual es indispensable que nos proporcione un correo electrónico) a la dirección geomark.223@gmail.com. </p>
                            <p>Así también, le informamos que usted tiene derecho a iniciar un Procedimiento de Protección de Derechos ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (http://www.inai.org.mx), dentro de los 15 días siguientes a la fecha en que reciba la respuesta o a partir de que concluya el plazo de 20 (veinte) días hábiles contados a partir de la fecha de recepción de su solicitud de ejercicio de derechos.</p>

                            <h5>MEDIOS PARA LIMITAR EL ALCANCE RESPECTO DEL USO O DIVULGACIÓN DE SUS DATOS PERSONALES</h5>
                            <p>En el supuesto de que usted desee limitar el uso o divulgación de sus datos personales podrá hacerlo a través del procedimiento aplicable al ejercicio de los Derechos ARCO y revocación del consentimiento señalado en el apartado anterior. </p>
                            <p>En caso de que su solicitud resulte procedente, se le registrará en el listado de exclusión interno de GeoMark</p>

                            <h5>CONSERVACIÓN DE LOS DATOS PERSONALES</h5>
                            <p>El Responsable y/o sus Encargados podrán conservar los datos personales del respectivo Titular durante el tiempo que sea necesario para realizar estudios sobre datos demográficos, intereses y comportamiento de sus clientes, consumidores y proveedores o cualquier otra responsabilidad relacionada con el tratamiento , así como para mantener los registros contables, financieros y de auditoría en términos de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y demás disposiciones legislativas aplicables.</p>

                            <h5>COOKIES</h5>
                            <p>GeoMark utiliza cookies para ayudar a obtener información que nos permite mejorar la experiencia de usuario y brindar un mejor servicio. En cualquier momento, dichas cookies pueden ser eliminadas accediendo a las preferencias de configuración del navegador en uso.</p>

                            <h5>MODIFICACIONES AL AVISO DE PRIVACIDAD</h5>
                            <p>El presente Aviso de Privacidad podrá sufrir modificaciones, cambios, alteraciones o actualizaciones derivadas de nuevos requerimientos legales; de necesidades propias de GeoMark, por los servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.</p>
                            <p>Cualquier modificación al presente Aviso de Privacidad le será notificada a través de cualquiera de los siguientes medios: un mensaje enviado a su correo electrónico, un comunicado en el sitio web: https://geomark.com.mx/; o bien, a través de mensajes publicados en redes sociales: @geomark.mx en Instagram y Facebook además de @geomark_mx en twitter finalmente @geomark mx en linkedin.</p>

                            <p>Fecha de actualización: 27 junio 2023</p>
                            <p>Usted otorga su consentimiento para el Tratamiento de sus Datos Personales en el momento en que GeoMark recaba sus Datos Personales, para cumplir con las finalidades establecidas en el presente Aviso de Privacidad y/o al momento de manifestar su voluntad a través de los medios físicos o electrónicos que en su caso correspondan, atento a lo anterior, usted reconoce que se le ha dado a conocer el presente Aviso de Privacidad y otorga su consentimiento expreso para el tratamiento de sus Datos Personales, incluidos los Datos Personales Sensibles.</p>


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
