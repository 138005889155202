
class Calidad {
    getDatosRHA(data, rha, campo) {
        //let aux = [];
        data.map(item => {
            item.valor = Number(item.valor);
        });
        return data;
    }

    getDatosMunicipios(data, municipio, campo) {
        let aux = [];
        data.map(item => {
            if (item.municipio === municipio && item[campo] !== "") {
                aux.push(item);
            }
        });

        return aux;
    }

    getDatosEntidad(data) {
        data.map(item => {

            item.valor = Number(item.valor);
        });
        return data;
    }


    //grafica de pie con porcentajes
    getDatos3(datos) {
        let dt = Object.keys(datos[0]);
        let aux = [], t = 0;
        for (let i = 1; i < dt.length; i++) {
            let js = {};
            js.total = 0;
            for (let j = 0; j < datos.length; j++) {
                js.valor = dt[i];
                js.total = js.total + datos[j][dt[i]];
                t = t + datos[j][dt[i]]
            }
            aux.push(js);
        }
        aux.map(item => {
            item.porcentaje = (item.total / t) * 100;
        })
        return aux;
    }

    //CAlidad del agua barras 
    getDatos2(datos, campo1) {
        let sitios = [], anios = [];
        // eslint-disable-next-line array-callback-return
        datos.map(item => {
            if (sitios.length === 0) {
                sitios.push(item[campo1]);
                anios.push(item['anio']);
            } else {
                if (!sitios.includes(item[campo1])) {
                    sitios.push(item[campo1]);
                }
                if (!anios.includes(item['anio'])) {
                    anios.push(item['anio']);
                }
            }
        });
        //let index = sitios.indexOf(null);
        anios.sort();
        let val = sitios.filter((item) => item !== null);

        let final = [];
        for (let i = 0; i < anios.length; i++) {
            let aux = {};
            for (let index = 0; index < val.length; index++) {
                aux['anio'] = anios[i];
                aux[val[index]] = 0;
                for (let j = 0; j < datos.length; j++) {
                    if (datos[j][campo1] === val[index]) {
                        if (datos[j].anio === anios[i]) {
                            aux[val[index]] = aux[val[index]] + 1;
                        }
                    }
                }
            }
            final.push(aux);
        }
        return final;
    }

    //grafica de Barras de la evolucion de la calidad
    getDatos(datos, campo) {
        //obtenemos el vector de sitios
        let sitios = [], anios = [];
        // eslint-disable-next-line array-callback-return
        datos.map(item => {
            if (sitios.length === 0) {
                sitios.push(item['clavesitio']);
                anios.push(item['anio']);
            } else {
                if (!sitios.includes(item['clavesitio'])) {
                    sitios.push(item['clavesitio']);
                }
                if (!anios.includes(item['anio'])) {
                    anios.push(item['anio']);
                }
            }
        });
        anios.sort();
        let final = [];
        for (let index = 0; index < sitios.length; index++) {
            let aux = {};
            for (let i = 0; i < anios.length; i++) {
                aux[anios[i]] = 0;
                for (let j = 0; j < datos.length; j++) {
                    if (datos[j].clavesitio === sitios[index]) {
                        if (datos[j].anio === anios[i]) {
                            aux[anios[i]] += Number(datos[j][campo]);
                            aux.sitio = datos[j].clavesitio;
                        }
                    }
                }
            }
            final.push(aux);
        }

        return final;

    }


    sortJSON(data, key, orden) {
        // eslint-disable-next-line array-callback-return
        return data.sort(function (a, b) {
            var x = a[key],
                y = b[key];

            if (orden === 'asc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }

            if (orden === 'desc') {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });
    }

}

export default Calidad;