import React, { useEffect, useState } from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import './datatable.scss'
import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faTrashAlt,
  faTable,
  faClone,
  faGlobeAmericas,
  faInfo,
  faShapes,
  faMoneyCheck,
  faMoneyBillAlt,
  faDollarSign,
  faLeaf,
  faVial,
  faArrowUp,
  faArrowCircleUp,
  faTint,
  faCloudRain,
  faIndustry,
  faHotTub,
  faMagnet,
  faCompressArrowsAlt,
  faBorderStyle,
  faFileAlt,
  faDownload,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import { jsPDF } from "jspdf";


const DataTable = ({ columns, rows, tam }) => {

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <div className="cellAction">
              <Button onClick={() => descargarDatos(params.row.link)}>
                <FontAwesomeIcon
                  icon={faDownload}
                  size="1x"
                />
              </Button>
            </div>
            <div className="cellAction">
              <Button onClick={() => descargarFicha(params.row.clavebandas)}>
                <FontAwesomeIcon
                  icon={faFilePdf}
                  size="1x"
                />
              </Button>
            </div>
          </>

        );
      },
    },
  ];


  useEffect(() => {
    //console.log(columns);
    if (columns[1].field === "clavebandas") {
      //console.log("Se agregan los botones de descarga")
    }

  }, rows);
  //actionColumn.concat(columns)

  function descargarDatos(link) {
    var downloadLink = document.createElement("a");
    downloadLink.href = link;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  function getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/jpeg");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }


  function descargarFicha(id) {

    //console.log(id);
    const doc = new jsPDF({
      orientation: "p",
      unit: "px",
      format: 'letter'
    });

    var img = new Image();
    img.onload = function () {
      var dataURI2 = getBase64Image(img);
      return dataURI2;
    };

    img.src = "images/encabezado2.jpg";

    fetch(`https://sinav30.conagua.gob.mx:8080/SINA3/fichaSIH/${id}`)
      .then(response => response.json())
      .then(data => {
        //console.log(data)
        let linea = 50;

        doc.addImage(img.onload(), "JPEG", 30, 5, 370, 30);

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Clave: `, 50, linea);
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].clavebandas}`, 200, linea);

        doc.setFont("helvetica", "bold");
        doc.text(`Nombre: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].stationnam}`, 200, linea + (15 * 1));

        doc.setFont("helvetica", "bold");
        doc.text(`Nombre de canal: `, 50, linea + (15 * 2));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].nombrecanal}`, 200, linea + (15 * 2));

        doc.setFont("helvetica", "bold");
        doc.text(`Corriente: `, 50, linea + (15 * 3));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].secondaryn}`, 200, linea + (15 * 3));

        doc.setFont("helvetica", "bold");
        doc.text(`Cuenca: `, 50, linea + (15 * 4));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].cuenca}`, 200, linea + (15 * 4));

        doc.setFont("helvetica", "bold");
        doc.text(`Estado: `, 50, linea + (15 * 5));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].estado}`, 200, linea + (15 * 5));

        doc.setFont("helvetica", "bold");
        doc.text(`Descripcion corriente: `, 50, linea + (15 * 6));
        doc.setFont("helvetica", "normal");
        const splitDescription = doc.splitTextToSize(
          data[0].descripcioncorriente,
          230
        );
        doc.text(splitDescription, 200, linea + (15 * 6));

        doc.setFont("helvetica", "bold");
        doc.text(`Area drenada(m2): `, 50, linea + (15 * 7) + splitDescription.length * 6);
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].areadrenada}`, 200, linea + (15 * 7) + splitDescription.length * 6);

        linea = linea + (15 * 7) + splitDescription.length * 6;

        doc.setFont("helvetica", "bold");
        doc.text(`Longitud: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].longitud}`, 200, linea + (15 * 1));

        doc.setFont("helvetica", "bold");
        doc.text(`Latitud: `, 50, linea + (15 * 2));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].latitud}`, 200, linea + (15 * 2));

        doc.setFont("helvetica", "bold");
        doc.text(`Ubicación: `, 50, linea + (15 * 3));
        doc.setFont("helvetica", "normal");
        const splitDescription1 = doc.splitTextToSize(
          data[0].ubicacion,
          230
        );
        doc.text(splitDescription1, 200, linea + (15 * 3));

        doc.setFont("helvetica", "bold");
        doc.text(`Acceso: `, 50, linea + (15 * 4) + splitDescription1.length * 6);
        doc.setFont("helvetica", "normal");
        const splitDescription2 = doc.splitTextToSize(
          data[0].acceso,
          230
        );
        doc.text(splitDescription2, 200, linea + (15 * 4) + splitDescription1.length * 6);
        //console.log(linea + (15 * 4) + splitDescription1.length * 6)

        linea = (linea + (15 * 5) + splitDescription2.length * 7) + 3;

        doc.setFont("helvetica", "bold");
        doc.text(`Objeto instalación: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        const splitDescription3 = doc.splitTextToSize(
          data[0].objetoinstalacion,
          230
        );
        doc.text(splitDescription3, 200, linea + (15 * 1));

        doc.setFont("helvetica", "bold");
        doc.text(`Condiciones Tramo: `, 50, linea + (15 * 2) + splitDescription3.length * 6);
        doc.setFont("helvetica", "normal");
        const splitDescription4 = doc.splitTextToSize(
          data[0].condicionestramo,
          230
        );
        doc.text(splitDescription4, 200, linea + (15 * 2) + splitDescription3.length * 6);

        linea = linea + (15 * 2) + splitDescription3.length * 6;

        doc.setFont("helvetica", "bold");
        doc.text(`Seccion aforos: `, 50, linea + (15 * 1) + splitDescription4.length * 6);
        doc.setFont("helvetica", "normal");
        const splitDescription5 = doc.splitTextToSize(
          data[0].seccionaforos,
          230
        );
        doc.text(splitDescription5, 200, linea + (15 * 1) + splitDescription4.length * 6);

        linea = linea + (15 * 2) + splitDescription5.length * 6;

        doc.setFont("helvetica", "bold");
        doc.text(`Escala: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        const splitDescription6 = doc.splitTextToSize(
          data[0].escala,
          230
        );
        doc.text(splitDescription6, 200, linea + (15 * 1));

        linea = linea + (15 * 1) + splitDescription6.length * 6;

        doc.setFont("helvetica", "bold");
        doc.text(`Estructura de aforos: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        const splitDescription7 = doc.splitTextToSize(
          data[0].estructuraaforos,
          230
        );
        doc.text(splitDescription7, 200, linea + (15 * 1));

        linea = linea + (15 * 1) + splitDescription7.length * 6;

        doc.setFont("helvetica", "bold");
        doc.text(`Aforos: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        const splitDescription8 = doc.splitTextToSize(
          data[0].aforos,
          230
        );
        doc.text(splitDescription8, 200, linea + (15 * 1));

        linea = linea + (15 * 1) + splitDescription8.length * 6;

        doc.setFont("helvetica", "bold");
        doc.text(`Registro niveles: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        const splitDescription9 = doc.splitTextToSize(
          data[0].registroniveles,
          230
        );
        doc.text(splitDescription9, 200, linea + (15 * 1));

        doc.addPage()
        doc.addImage(img.onload(), "JPEG", 30, 5, 370, 30);

        linea = 50;

        doc.setFont("helvetica", "bold");
        doc.text(`Solidos en suspensión: `, 50, linea);
        doc.setFont("helvetica", "normal");
        const splitDescription11 = doc.splitTextToSize(
          data[0].solidosensuspension,
          230
        );
        doc.text(splitDescription11, 200, linea);

        doc.setFont("helvetica", "bold");
        doc.text(`Maximo aforado: `, 50, linea + (15 * 1) + splitDescription11.length * 6);
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].maximoaforado}`, 200, linea + (15 * 1) + splitDescription11.length * 6);

        linea = linea + (15 * 1) + splitDescription11.length * 6;

        doc.setFont("helvetica", "bold");
        doc.text(`Escala para maximo aforado: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].escalamaximoaforado}`, 200, linea + (15 * 1));

        doc.setFont("helvetica", "bold");
        doc.text(`Velocidad media de maximo aforado: `, 50, linea + (15 * 2));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].mediademaximoaforado}`, 200, linea + (15 * 2));

        doc.setFont("helvetica", "bold");
        doc.text(`Velocidad media de maximo aforado: `, 50, linea + (15 * 3));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].mediademaximoaforado}`, 200, linea + (15 * 3));

        doc.setFont("helvetica", "bold");
        doc.text(`Profundidad maxima de gasto: `, 50, linea + (15 * 4));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].maximadegastomaximo}`, 200, linea + (15 * 4));

        doc.setFont("helvetica", "bold");
        doc.text(`Maximo aforado: `, 50, linea + (15 * 5));
        doc.setFont("helvetica", "normal");
        doc.text(`${data[0].maximoaforado}`, 200, linea + (15 * 5));

        doc.setFont("helvetica", "bold");
        doc.text(`Calculo hidrometrico: `, 50, linea + (15 * 6));
        doc.setFont("helvetica", "normal");
        const splitDescription10 = doc.splitTextToSize(
          data[0].calculohridrometrico,
          230
        );
        doc.text(splitDescription10, 200, linea + (15 * 6));

        doc.setFont("helvetica", "bold");
        doc.text(`Climatologica en Sitio: `, 50, linea + (15 * 7) + splitDescription10.length * 6);
        doc.setFont("helvetica", "normal");
        const splitDescription12 = doc.splitTextToSize(
          data[0].climatologicaensitio,
          230
        );
        doc.text(splitDescription12, 200, linea + (15 * 7) + splitDescription10.length * 6);

        linea = (linea + (15 * 8) + splitDescription12.length * 6)+10;

        doc.setFont("helvetica", "bold");
        doc.text(`Notas: `, 50, linea + (15 * 1));
        doc.setFont("helvetica", "normal");
        const splitDescription13 = doc.splitTextToSize(
          data[0].notas,
          230
        );
        doc.text(splitDescription13, 200, linea + (15 * 1));

        doc.save("Ficha Tecnica.pdf");


      });








  }

  return (
    columns[1].field === "clavebanda" ? (
      <>

        <div style={{ height: 600, width: tam }} className="datatable">
          <DataGrid
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            className="datagrid"
            rows={rows}
            columns={columns.concat(actionColumn)}
            pageSize={10}
            rowsPerPageOptions={[10]}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}

          />
        </div>
      </>
    ) : (
      <div style={{ height: 600, width: tam }} className="datatable">
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          className="datagrid"
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}

        />
      </div>
    )

  );
}

export default DataTable;