import React from 'react'
import Footer from '../../componentes/Footer'
import Header from '../../componentes/Header'
import './precios.css'
import { Link, NavLink } from "react-router-dom";

const Precios = () => {
    return (
        <div className="container-fluid">
            <Header></Header>
            {
                //<!-- Precios -->
            }

            <div id="about" className="about" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/ab_bg.png'})` }}>
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-12">
                            <div >
                                <h2>Precios</h2>
                                <span></span>
                                <form>
                                    <div class="row">
                                        <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000" data-aos-once="true" class="col-md-4 text-center py-4 mt-5 precios">
                                            <input type="radio" id="estandar" name="drone" value="estandar" class="radioB" />
                                            <label for="estandar">
                                                <h3 class="my-4">GEOMARK ESTANDAR</h3>
                                                <p class="font-weight-bold">$ <span class="display-2 font-weight-bold">10</span> / MO.</p>
                                                <ul class="list-unstyled">
                                                    <li>Consulta de información de indicadores sociales,
                                                        económicos actualizados.</li>
                                                    <li>Identificación de competencia.</li>
                                                    <li>Identificación de clientes potenciales.</li>
                                                    <li>Segmentación de acuerdo a factores demográficos.</li>
                                                    <li>Tablero de control (dashboard)</li>
                                                    <li>2 usuarios</li>
                                                </ul>
                                                <br />
                                                <br />
                                                <br />
                                                <br />
                                                <Link to={'/Registro'}>
                                                    <button type="button" class="btn btn-light">Comprar</button>
                                                </Link>

                                            </label>
                                        </div>
                                        <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" class="col-md-4 text-center py-4 mt-5 rounded" id="price-table__premium">
                                            <input type="radio" id="avanzado" name="drone" value="avanzado" checked />
                                            <label for="avanzado">
                                                <h3 class="my-4">GEOMARK AVANZADO</h3>
                                                <p class="font-weight-bold">$ <span class="display-2 font-weight-bold">30</span> / MO.</p>
                                                <ul class="list-unstyled">
                                                    <li>Consulta de información de indicadores sociales,económicos actualizados</li>
                                                    <li>Identificación de competencia.</li>
                                                    <li>Identificacioón de clientes potenciales.</li>
                                                    <li>Segmentación de acuerdo a factores demográficos</li>
                                                    <li>Tablero de control (dashboard)</li>
                                                    <li>Visualización de zonas de venta (localidad, unidad de análisis más pequeña)</li>
                                                    <li>Zonas de influencia</li>
                                                    <li>Asesoría</li>
                                                    <li>4 usuarios</li>
                                                </ul>

                                                <Link to={'/Registro'}>
                                                    <button type="button" class="btn btn-light">Comprar</button>
                                                </Link>

                                            </label>
                                        </div>
                                        <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000" data-aos-once="true" class="col-md-4 text-center py-4 mt-5">
                                            <input type="radio" id="plus" name="drone" value="plus" />
                                            <label for="plus">
                                                <h3 class="my-4">GEOMARK PLUS</h3>
                                                <p class="font-weight-bold">$ <span class="display-2 font-weight-bold">60</span> / MO.</p>
                                                <ul class="list-unstyled">
                                                    <li>Consulta de información de indicadores sociales, económicos actualizados.</li>
                                                    <li>Identificación de competencia.</li>
                                                    <li>Identificación de clientes potenciales.</li>
                                                    <li>Segmentación de acuerdo a factores demográficos.</li>
                                                    <li>Tablero de control (dashboard).</li>
                                                    <li>Visualización de zonas de venta (municipal).</li>
                                                    <li>Zonas de influencia.</li>
                                                    <li>Asesoría personalizada</li>
                                                    <li>6 usuarios</li>
                                                </ul>
                                                <br />
                                                <Link to={'/Registro'}>
                                                    <button type="button" class="btn btn-light">Comprar</button>
                                                </Link>

                                            </label>
                                        </div>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {
                //<!--  footer -->
            }
            <Footer></Footer>

        </div>
    )
}

export default Precios