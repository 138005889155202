import React from 'react'
import './pago.css'
import Cookie from 'universal-cookie';


const PlanEstandar = () => {

    const cookie = new Cookie();
    
    return (
        <div className='contenidoPago'>
            <stripe-pricing-table pricing-table-id="prctbl_1Mq3rVEqH9zAWlhA9JMUxJLp"
                publishable-key="pk_test_51MEdFWEqH9zAWlhARwcIzCBfgtlUcvJGfKd6h3XEyHFwg5FN2vUUHxJNmzFmZVN4QhlUm7EVXvM0RVfP3FEmJYWF00SiXDJTtp">
            </stripe-pricing-table>
        </div>
    )
}

export default PlanEstandar