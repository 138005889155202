export const reportePresupuestoC = [
    { field: 'idppgrupo', headerName: 'Grupo', width: 100 },
    { field: 'ppgrupo', headerName: 'Descripción de Grupo', width: 600 },
    { field: 'i2012', headerName: '2012', width: 105 },
    { field: 'i2013', headerName: '2013', width: 105 },
    { field: 'i2014', headerName: '2014', width: 105 },
    { field: 'i2015', headerName: '2015', width: 105 },
    { field: 'i2016', headerName: '2016', width: 105 },
    { field: 'i2017', headerName: '2017', width: 105 },
    { field: 'i2018', headerName: '2018', width: 105 },
    { field: 'i2019', headerName: '2019', width: 105 },
    { field: 'i2020', headerName: '2020', width: 105 },
    { field: 'i2021', headerName: '2021', width: 105 }
];

export const reporteCapitulosT=[
    { field: 'ppgrupo', headerName: 'Grupo', width: 100 },
    { field: 'descppgrupo', headerName: 'Descripción de Grupo', width: 600 },
    { field: 'v1000', headerName: '1000', width: 100 },
    { field: 'v2000', headerName: '2000', width: 100 },
    { field: 'v3000', headerName: '3000', width: 100 },
    { field: 'v4000', headerName: '4000', width: 100 },
    { field: 'v5000', headerName: '5000', width: 100 },
    { field: 'v6000', headerName: '6000', width: 100 },
    { field: 'v7000', headerName: '7000', width: 100 }
];

export const reporteRerionT=[
    { field: 'ppgrupo', headerName: 'Grupo', width: 100 },
    { field: 'descppgrupo', headerName: 'Descripción de Grupo', width: 600 },
    { field: 'norte', headerName: 'Norte', width: 100 },
    { field: 'sur', headerName: 'Sur', width: 100 },
    { field: 'valle', headerName: 'Valle de Mexico', width: 150 },
];

export const reporteRecPreT=[
    { field: 'anio', headerName: 'Año', width: 300 },
    { field: 'recaudacion', headerName: 'Recaudación', width: 300 },
    { field: 'presupuesto', headerName: 'Presupuesto Ejercido', width: 300 }
];

export const reportePresAcu=[
    { field: 'id', headerName: '#', width: 100 },
    { field: 'unidad', headerName: 'Unidad ejecutora', width: 600 },
    { field: 'monto', headerName: 'Importe ejercido', width: 200 }
];

export const prestadoresEntidad=[
    { field: 'prestador', headerName: 'Prestador', width: 500 },
    { field: 'abreviatura', headerName: 'Abreviatura', width: 200 },
    { field: 'habitantes', headerName: 'Miles habitantes', width: 200 },
    { field: 'tipoprestador', headerName: 'Tipo Prestador', width: 200 },
    { field: 'entidad', headerName: 'Entidad', width: 150 }
];


export const prestadoresTamanioT=[
    { field: 'descripciontipo', headerName: 'Tipo de prestador', width: 350 },
    { field: 'menosde10', headerName: 'Menos de 10', width: 115 },
    { field: 'entre10y50', headerName: 'Entre 10 y 50', width: 115 },
    { field: 'entre50y100', headerName: 'Entre 50 y 100', width: 115 },
    { field: 'entre100y200', headerName: 'Entre 100 y 200', width: 115 },
    { field: 'entre200y300', headerName: 'Entre 200 y 300', width: 115 },
    { field: 'entre300y500', headerName: 'Entre 300 y 500', width: 115 },
    { field: 'entre500y900', headerName: 'Entre 500 y 900', width: 115 },
    { field: 'másde900', headerName: 'Más de 900', width: 110 }
];

export const prestadoresMunicipios=[
    { field: 'tipoprestador', headerName: 'Tipo Prestador', width: 180 },
    { field: 'abreviatura', headerName: 'Abreviatura', width: 260 },
    { field: 'prestador', headerName: 'Prestador', width: 480 },
    { field: 'entidad', headerName: 'Municipio', width: 150 },
    { field: 'tomasmiles', headerName: 'Tomas', width: 120 },
    { field: 'habitantesmiles', headerName: 'Población Atendida', width: 150 }
];

export const estados=[
    { field: 'id', headerName: '#', width: 100 },
    { field: 'clave', headerName: 'Clave', width: 150 },
    { field: 'nombre', headerName: 'Nombre', width: 500 },
    { field: 'anio', headerName: 'Año', width: 100 }
];

//id, clvrha, rha, anioagricola, spsembrada, spcosechada, producciont, valproduccion
export const concentradoRHA = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
    { field: 'rha', headerName: 'RHA', width: 250 },
    { field: 'anioagricola', headerName: 'Año Agricola', width: 150 },
    { field: 'spsembrada', headerName: 'Superficie Sembrada (ha)', width: 200 },
    { field: 'spcosechada', headerName: 'Superficie Cosechada (ha)', width: 200 },
    { field: 'producciont', headerName: 'Produccion Total (miles de ton)', width: 300 },
    { field: 'valproduccion', headerName: 'Valor de la Producción (miles de pesos)', width: 300 }
]

//id, cicloc, modalidadc, clvdr, dr, cultivodr, anioagricola, spsembrada, spcosechada, rendimientot, producciont, pmediorural, valproduccion
export const concentradoDR = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'cicloc', headerName: 'Ciclo', width: 120 },
    { field: 'modalidadc', headerName: 'Modalidad', width: 150 },
    { field: 'clvdr', headerName: 'Clave DR', width: 150 },
    { field: 'dr', headerName: 'Distrito de Riego', width: 200 },
    { field: 'cultivodr', headerName: 'Cultivo', width: 200 },
    { field: 'anioagricola', headerName: 'Año agricola', width: 200 },
    { field: 'spsembrada', headerName: 'Superficie Sembrada (ha)', width: 200 },
    { field: 'spcosechada', headerName: 'Superficie Cosechada (ha)', width: 200 },
    { field: 'rendimiento', headerName: 'Rendimiento (ton/ha)', width: 200 },
    { field: 'producciont', headerName: 'Producción (ton)', width: 200 },
    { field: 'pmediorural', headerName: 'P.M.R ($/ton)', width: 200 },
    { field: 'valproduccion', headerName: 'Valor de la producción (miles de pesos)', width: 300 }
]

//id, clvdr, dr, anioagricola, gravedadpresas, gravedaderivacion, bombeopozos, bombeocorrientes, totalgeneral
export const supSembrada = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'clvdr', headerName: 'Clave DR', width: 100 },
    { field: 'dr', headerName: 'Distrito Riego', width: 250 },
    { field: 'anioagricola', headerName: 'Año Agricola', width: 150 },
    { field: 'gravedadpresas', headerName: 'Gravedad Presas', width: 200 },
    { field: 'gravedaderivacion', headerName: 'Gravedad Derivación', width: 200 },
    { field: 'bombeopozos', headerName: 'Bombeo Pozos', width: 200 },
    { field: 'bombeocorrientes', headerName: 'Bombeo Corrientes', width: 200 },
    { field: 'totalgeneral', headerName: 'Total', width: 200 }
]
//clvdr 	dr 	anioagricola 	spsembrada 	spcosechada 	producciont 	valproduccion


export const machote=[
    { field: '', headerName: '', width: 150 },
    { field: '', headerName: '', width: 150 },
    { field: '', headerName: '', width: 150 },
    { field: '', headerName: '', width: 150 },
    { field: '', headerName: '', width: 150 },
    { field: '', headerName: '', width: 150 },
    { field: '', headerName: '', width: 150 },
    { field: '', headerName: '', width: 150 },
    { field: '', headerName: '', width: 150 },
]


export const sihbandas=[
    { field: 'station', headerName: 'Estación', width: 200 },
    { field: 'anio', headerName: 'Año', width: 100 },
    { field: 'mes', headerName: 'Mes', width: 100 },
    { field: 'd1', headerName: 'Día 1', width: 150 },
    { field: 'd2', headerName: 'Día 2', width: 150 },
    { field: 'd3', headerName: 'Día 3', width: 150 },
    { field: 'd4', headerName: 'Día 4', width: 150 },
    { field: 'd5', headerName: 'Día 5', width: 150 },
    { field: 'd6', headerName: 'Día 6', width: 150 },
    { field: 'd7', headerName: 'Día 7', width: 150 },
    { field: 'd8', headerName: 'Día 8', width: 150 },
    { field: 'd9', headerName: 'Día 9', width: 150 },
    { field: 'd10', headerName: 'Día 10', width: 150 },
    { field: 'd11', headerName: 'Día 11', width: 150 },
    { field: 'd12', headerName: 'Día 12', width: 150 },
    { field: 'd13', headerName: 'Día 13', width: 150 },
    { field: 'd14', headerName: 'Día 14', width: 150 },
    { field: 'd15', headerName: 'Día 15', width: 150 },
    { field: 'd16', headerName: 'Día 16', width: 150 },
    { field: 'd17', headerName: 'Día 17', width: 150 },
    { field: 'd18', headerName: 'Día 18', width: 150 },
    { field: 'd19', headerName: 'Día 19', width: 150 },
    { field: 'd20', headerName: 'Día 20', width: 150 },
    { field: 'd21', headerName: 'Día 21', width: 150 },
    { field: 'd22', headerName: 'Día 22', width: 150 },
    { field: 'd23', headerName: 'Día 23', width: 150 },
    { field: 'd24', headerName: 'Día 24', width: 150 },
    { field: 'd25', headerName: 'Día 25', width: 150 },
    { field: 'd26', headerName: 'Día 26', width: 150 },
    { field: 'd27', headerName: 'Día 27', width: 150 },
    { field: 'd28', headerName: 'Día 28', width: 150 },
    { field: 'd29', headerName: 'Día 29', width: 150 },
    { field: 'd30', headerName: 'Día 30', width: 150 },
    { field: 'd31', headerName: 'Día 31', width: 150 }
]

export const cuencaRHA=[
    { field: 'claverha', headerName: 'Clave Región Hidrológico-administrativa', width: 300 },
    { field: 'con_disponibilidad', headerName: 'Cuencas con disponibilidad', width: 300 },
    { field: 'sin_disponibilidad', headerName: 'Cuencas Sin disponibilidad', width: 300 },
    { field: 'total_cuencas', headerName: 'Total de cuencas', width: 300 }
]

export const cuencaRH=[
    { field: 'claverh', headerName: 'Clave Región Hidrológica', width: 300 },
    { field: 'con_disponibilidad', headerName: 'Cuencas con disponibilidad', width: 300 },
    { field: 'sin_disponibilidad', headerName: 'Cuencas Sin disponibilidad', width: 300 },
    { field: 'total_cuencas', headerName: 'Total de cuencas', width: 300 }
];

export const compoenentesSC=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'elemento', headerName: 'Elemento', width: 300 },
    { field: 'tipo', headerName: 'Tipo', width: 300 },
    { field: 'capacidad', headerName: 'Capacidad', width: 300 },
    { field: 'elevacionmsnm', headerName: 'Elevacion (msnm)', width: 300 },
    { field: 'observaciones', headerName: 'Observaciones', width: 300 }
];

export const caudales=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'anio', headerName: 'Año', width: 100 },
    { field: 'cdmxvol', headerName: 'Ciudad de México Volumen entregado (hm³/año)', width: 300 },
    { field: 'cdmxgasto', headerName: 'Ciudad de México Gasto medio (m³/s)', width: 300 },
    { field: 'mexicovol', headerName: 'Edo. de México Volumen entregado (hm³/año)', width: 300 },
    { field: 'mexicogasto', headerName: 'Edo. de México Gasto medio (m³/s)', width: 300 },
    { field: 'totalvol', headerName: 'Total Volumen entregado (hm³/año)', width: 300 },
    { field: 'totalgasto', headerName: 'Total Gasto medio (m³/s)', width: 300 }
];

export const volumenes=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'anio', headerName: 'Año', width: 100 },
    { field: 'cdmxentrega', headerName: 'Entrega a la Ciudad de México (hm³/año)', width: 300 },
    { field: 'edomexicoentrega', headerName: 'Entrega al Estado de México (hm³/año)', width: 300 },
    { field: 'consumo', headerName: 'Consumo de energía (kWh)', width: 300 },
    { field: 'totalentrega', headerName: 'Total entregado (hm³/año)', width: 300 }
];

export const precipitacionNM=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'mes', headerName: 'Mes', width: 100 },
    { field: 'periodo1', headerName: 'Precipitación (mm) 1971-2000', width: 300 },
    { field: 'periodo2', headerName: 'Precipitación (mm) 1981-2010', width: 300 },
    { field: 'periodo3', headerName: 'Precipitación (mm) 1991-2020', width: 300 }
];

export const precipitacionN=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'clvedo', headerName: 'Clave estado', width: 100 },
    { field: 'estado', headerName: 'Estado', width: 200 },
    { field: 'anio', headerName: 'Periodo', width: 100 },
    { field: 'ene', headerName: 'Enero', width: 100 },
    { field: 'feb', headerName: 'Febrero', width: 100 },
    { field: 'mar', headerName: 'Marzo', width: 100 },
    { field: 'abr', headerName: 'Abril', width: 100 },
    { field: 'may', headerName: 'Mayo', width: 100 },
    { field: 'jun', headerName: 'Junio', width: 100 },
    { field: 'jul', headerName: 'Julio', width: 100 },
    { field: 'ago', headerName: 'Agosto', width: 100 },
    { field: 'sep', headerName: 'Septiembre', width: 100 },
    { field: 'oct', headerName: 'Octubre', width: 100 },
    { field: 'nov', headerName: 'Noviembre', width: 100 },
    { field: 'dic', headerName: 'Diciembre', width: 100 },
    { field: 'anual', headerName: 'Anual', width: 100 }
];

export const precipitacionRHA=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
    { field: 'rha', headerName: 'RHA', width: 250 },
    { field: 'anio', headerName: 'Periodo', width: 100 },
    { field: 'ene', headerName: 'Enero', width: 100 },
    { field: 'feb', headerName: 'Febrero', width: 100 },
    { field: 'mar', headerName: 'Marzo', width: 100 },
    { field: 'abr', headerName: 'Abril', width: 100 },
    { field: 'may', headerName: 'Mayo', width: 100 },
    { field: 'jun', headerName: 'Junio', width: 100 },
    { field: 'jul', headerName: 'Julio', width: 100 },
    { field: 'ago', headerName: 'Agosto', width: 100 },
    { field: 'sep', headerName: 'Septiembre', width: 100 },
    { field: 'oct', headerName: 'Octubre', width: 100 },
    { field: 'nov', headerName: 'Noviembre', width: 100 },
    { field: 'dic', headerName: 'Diciembre', width: 100 },
    { field: 'anual', headerName: 'Anual', width: 100 }
];

export const precipitacionRHAAnual=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'clvrha', headerName: 'Clave RHA', width: 100 },
    { field: 'rha', headerName: 'RHA', width: 250 },
    { field: 'anio', headerName: 'Periodo', width: 100 },
    { field: 'ene', headerName: 'Enero', width: 100 },
    { field: 'feb', headerName: 'Febrero', width: 100 },
    { field: 'mar', headerName: 'Marzo', width: 100 },
    { field: 'abr', headerName: 'Abril', width: 100 },
    { field: 'may', headerName: 'Mayo', width: 100 },
    { field: 'jun', headerName: 'Junio', width: 100 },
    { field: 'jul', headerName: 'Julio', width: 100 },
    { field: 'ago', headerName: 'Agosto', width: 100 },
    { field: 'sep', headerName: 'Septiembre', width: 100 },
    { field: 'oct', headerName: 'Octubre', width: 100 },
    { field: 'nov', headerName: 'Noviembre', width: 100 },
    { field: 'dic', headerName: 'Diciembre', width: 100 },
    { field: 'anual', headerName: 'Anual', width: 100 }
];

export const propuestaOcavam=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'anio', headerName: 'Año', width: 100 },
    { field: 'bosque', headerName: 'El Bosque, Mich.(DBOMC)', width: 200 },
    { field: 'valle', headerName: 'Valle de Bravo, Méx.(VBRMX)', width: 200 },
    { field: 'villa', headerName: 'Villa Victoria, Méx.(VVCMX)', width: 200 },
    { field: 'total', headerName: 'Total', width: 200 },
];

export const ciclonesMexico=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'nombre', headerName: 'Nombre', width: 100 },
    { field: 'impactoentierra', headerName: 'Impacto en tierra', width: 300 },
    { field: 'fechadeimpacto', headerName: 'Fecha de impacto', width: 200 },
    { field: 'categoriamaxima', headerName: 'Categoría máxima', width: 200 },
    { field: 'categoriaimpacto', headerName: 'Categoría en impacto', width: 200 },
    { field: 'vietosmax', headerName: 'Velocidad en impacto (km/h)', width: 200 },
    { field: 'costa', headerName: 'Costa', width: 200 }
];

export const ciclonesCosta=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'costa', headerName: 'Costa', width: 100 },
    { field: 'depresiont', headerName: 'Depresión Tropical (DT)', width: 200 },
    { field: 'tormentat', headerName: 'Tormenta Tropical (TT)', width: 200 },
    { field: 'moderado', headerName: 'Huracán moderado (H1 y H2)', width: 200 },
    { field: 'intenso', headerName: 'Huracán intenso (H3 a H5)', width: 200 },
    { field: 'tot', headerName: 'Total', width: 200 }
];

export const PresupuestoAcumulado=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'unidad', headerName: 'Unidad ejecutora', width: 300 },
    { field: 'anio', headerName: 'Año', width: 100 },
    { field: 'mes', headerName: 'Mes', width: 200 },
    { field: 'monto', headerName: 'Monto', width: 200 },
    { field: 'grupo', headerName: 'Grupo', width: 300 }

];

export const repObras=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'anio', headerName: 'Año', width: 100 },
    { field: 'nomoficial', headerName: 'Nombre Oficial', width: 300 },
    { field: 'nomcomun', headerName: 'Nombre Común', width: 200 },
    { field: 'municipio', headerName: 'Municipio', width: 200 },
    { field: 'estado', headerName: 'Entidad Federativa', width: 300 },
    { field: 'importe', headerName: 'Importe Contratado', width: 200 },
    { field: 'trabajos', headerName: 'Trabajos realizados', width: 500 },
    { field: 'comentarios', headerName: 'Comentarios', width: 300 }

];

export const repinversion=[
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'anio', headerName: 'Año', width: 100 },
    { field: 'estado', headerName: 'Entidad Federativa', width: 300 },
    { field: 'obras', headerName: 'Obras', width: 200 },
    { field: 'importe', headerName: 'Importe en MDP', width: 200 }

];
