class Ciclones {

    agruparPorAnio(datos) {

        let anios = [];
        // eslint-disable-next-line array-callback-return
        datos.map(item => {
            if (anios.length === 0) {
                anios.push(item['anio']);
            } else {
                if (!anios.includes(item['anio'])) {
                    anios.push(item['anio']);
                }
            }
        });
        anios.sort()
        //console.log(anios);

        let finales = [];

        for (let index = 0; index < anios.length; index++) {

            let aux = {};
            aux.anio=anios[index];
            datos.map(item=>{
                if(item.anio === anios[index]){
                    aux[item.intensidad]=item.totalciclones
                }
            })
            finales.push(aux);
            
        }

        //console.log(finales);


        return finales;


    }

    agruparPorAnioImpacto(datos) {
        

        let anios = [];
        // eslint-disable-next-line array-callback-return
        datos.map(item => {
            if (anios.length === 0) {
                anios.push(item['anio']);
            } else {
                if (!anios.includes(item['anio'])) {
                    anios.push(item['anio']);
                }
            }
        });
        anios.sort()
        //console.log(anios);

        let finales = [];

        for (let index = 0; index < anios.length; index++) {

            let aux = {};
            aux.anio=anios[index];
            datos.map(item=>{
                if(item.anio === anios[index]){
                    aux[item.intensidad]=item.totalimpactos
                }
            })
            finales.push(aux);
            
        }

        //console.log(finales);


        return finales;


    }


}

export default Ciclones;