import React, { useEffect, useState } from "react";
import "./grafica.scss";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Label,
  ZAxis,
  Scatter,
  ComposedChart,
  Line
} from "recharts";
import randomColor from "randomcolor";
import Loading from "../Loader/Loader";

const Grafica = ({ tipo, datos, variable, nombre, opcion, consumo, name }) => {
  //console.log(datos)
  //console.log(variable);

  const [colores, setColores] = useState([]);
  const [loadingg, setLoadingg] = useState(false);
  const hed = ["anio","Ciclon Tropical Potencial","Depresión Tropical","Intenso","Moderado","Tormenta Tropical"]
                        

  const colores1 = [
    "blue",
    "pink",
    "green",
    "red",
    "orange",
    "purple",
    "brown",
  ];
  let header = Object.keys(datos[0]);
  const c2 = ["#2e1f54", "#52057f", "#bf033b", "#598c14", "#f00a36", "#b0a696", "#ed3b21", "#0eb24e", "#ff6908", "#ffc719", "#335238", "#4a8594", "#051736", "#000000", "#f38020", "#bb1e10"]
  //console.log(header);
  const coloresRec = ["#6d6e70", "#0099cc", "#ff9933"];
  const coloresRec1 = [0, 1, 2];


  useEffect(() => {
    let c1 = getRandomArbitrary(0, 6);
    let c = randomColor({ count: 8, hue: colores1[c1] });
    //console.log()
    setColores(c2);
    cambiaEstadoLoading();
  }, []);

  const getRandomArbitrary = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  };

  const cambiaEstadoLoading = () => {
    setLoadingg(true);
    setTimeout(() => {
      setLoadingg(false);
    }, 2000);
  };

  const dame_color_aleatorio = () => {
    let hexadecimal = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
    ];
    let color_aleatorio = "#";
    for (let i = 0; i < 6; i++) {
      let posarray = Math.floor(Math.random() * 16 + 0);
      color_aleatorio += hexadecimal[posarray];
    }
    return color_aleatorio;
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    let x = cx + radius * Math.cos(-midAngle * RADIAN);
    let y = cy + radius * Math.sin(-midAngle * RADIAN);
    x = 100;

    return (
      <text
        x={x}
        y={y}
        fill="#00000"
        dominantBaseline="central"
        className="labelP"
      >
        {`${(percent * 100).toFixed(0)}% ${datos[index].valor}`}
      </text>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      if (opcion === "estado") {
        return (
          <div className="custom-tooltip">
            <p className="label">{`${label}`}</p>
            <p className="intro">{`Población masculina: ${new Intl.NumberFormat(
              "en-US"
            ).format(payload[0].payload.masculina)}`}</p>
            <p className="intro">{`Población femenina: ${new Intl.NumberFormat(
              "en-US"
            ).format(payload[0].payload.femenina)}`}</p>
          </div>
        );
      } else {
        if (opcion === "evolucion") {
          return (
            <div className="custom-tooltip">
              <p className="label">{`${label}`}</p>
              <p className="intro">{`El Bosque, Mich.(DBOMC): ${payload[0].payload.DBOMC}`}</p>
              <p className="intro">{`Valle de Bravo, Méx.(VBRMX): ${payload[0].payload.VBRMX}`}</p>
              <p className="intro">{`Villa Victoria, Méx.(VVCMX): ${payload[0].payload.VVCMX}`}</p>
            </div>
          )

        } else {
          return (
            <div className="custom-tooltip">
              <p className="label">{`${label}`}</p>
              <p className="intro">{`Población total: ${new Intl.NumberFormat(
                "en-US"
              ).format(payload[0].payload.total)}`}</p>
              <p className="intro">{`Población masculina: ${new Intl.NumberFormat(
                "en-US"
              ).format(payload[0].payload.masculina)}`}</p>
              <p className="intro">{`Población femenina: ${new Intl.NumberFormat(
                "en-US"
              ).format(payload[0].payload.femenina)}`}</p>
            </div>
          );
        }
      }
    }

    return null;
  };

  if (loadingg) {
    return <Loading />;
  } else {
    return (
      <div className="contendorGrafica">
        {tipo === "barras" ? (
          datos.length > 0 &&
          (nombre === "Poblacion" ? (
            <>
              <div className="ContenedorGrafica">
                <ResponsiveContainer width="100%" aspect={3 / 1}>
                  <BarChart
                    width={1050}
                    height={500}
                    data={datos}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={variable} />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                      verticalAlign="top"
                      height={36}
                      iconSize="10"
                      margin={{ botom: 200 }}
                    />
                    {header.map((item, index) => {
                      if (item !== variable && item !== "total") {
                        return (
                          <Bar
                            dataKey={`${item}`}
                            stackId="a"
                            fill={`${colores[getRandomArbitrary(0, 15)]}`}
                          />
                        );
                      }
                    })}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </>
          ) : (
            <>
              <div className="ContenedorGrafica">
                <ResponsiveContainer width="100%" aspect={3 / 1}>
                  <BarChart
                    width={1050}
                    height={500}
                    data={datos}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={variable} />
                    <YAxis />
                    {(opcion === "estado" || opcion === "evolucion") ? <Tooltip content={<CustomTooltip />} /> : <Tooltip />}

                    <Legend
                      verticalAlign="top"
                      height={35}
                      iconSize="10"
                      margin={{ botom: 200 }}
                    />
                    {
                      opcion !== "ciclones" ? (
                        header.map((item, index) => {
                          if (item !== variable) {
                            return (
                              <Bar
                                dataKey={`${item}`}
                                stackId="a"
                                fill={`${colores[getRandomArbitrary(0, 15)]}`}
                              />
                            );
                          }
                        })
                      ) : (
                        hed.map((item, index) => {
                          if (item !== variable) {
                            return (
                              <Bar
                                dataKey={`${item}`}
                                stackId="a"
                                fill={`${c2[index]}`}
                              />
                            );
                          }
                        })
                      )

                    }
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </>
          ))
        ) : tipo === "barras2" ? (
          <>
            <div className="ContenedorGrafica">
              <ResponsiveContainer width="100%" aspect={3 / 1}>
                <BarChart
                  width={650}
                  height={400}
                  data={datos}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={variable} />
                  <YAxis />
                  <Tooltip />
                  <Legend
                    verticalAlign="top"
                    height={36}
                    iconSize="10"
                    margin={{ botom: 200 }}
                  />

                  {

                    header.map((item, index) => {
                      //if (item !== variable && item !== "total") {
                      if (item !== variable) {//index !== 2) {
                        if (name === "Recaudacion") {
                          return (
                            <Bar dataKey={`${item}`} fill={`${coloresRec[coloresRec1[index]]}`} />
                          );
                        } else {
                          return (
                            <Bar dataKey={`${item}`} fill={`${colores[index]}`} />
                          );
                        }

                      }
                    })}
                </BarChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : tipo === "pie" ? (
          <>
            <div className="ContenedorGrafica">
              <div className="row pie">
                <div className="col-6 col-lg-6 graf">
                  <ResponsiveContainer width="100%" aspect={1 / 1}>
                    <PieChart width={900} height={400}>
                      <Pie
                        data={datos}
                        cx={160}
                        cy={160}
                        innerRadius={30}
                        outerRadius={150}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey={variable}
                      >
                        {header.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={`${colores[index]}`}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="col-6 col-lg-6">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Total</th>
                        <th scope="col">Porcentaje</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datos.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <div
                                style={{
                                  backgroundColor: colores[index],
                                  width: "30px",
                                  height: "30px",
                                  textAlign: "center",
                                }}
                              >
                                {index}
                              </div>
                            </td>
                            <td scope="row">
                              <p>{item.valor}</p>
                            </td>
                            <td>
                              <p>{item.total}</p>
                            </td>
                            <td>
                              <p>{item.porcentaje}</p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        ) : tipo === "puntos" ? (
          <>
            <div className="ContenedorGrafica">
              <ResponsiveContainer width="100%" aspect={3 / 2}>
                <ScatterChart
                  width={1400}
                  height={1000}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid />
                  <XAxis
                    type="number"
                    dataKey="x"
                    name="año"
                    domain={[2011, 2021]}
                    interval={0}
                    dx={20}
                  />
                  <YAxis
                    dataKey="y"
                    name="presupuesto"
                    label={{
                      value: "Miles de millones de pesos",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <ZAxis dataKey="z" range={[60, 400]} name="grupo" />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Legend
                    verticalAlign="top"
                    height={36}
                    layout="vertical"
                    align="right"
                    iconSize="10"
                    margin={{ left: 30 }}
                  />
                  {datos.map((item, index) => {
                    if (item[0] !== undefined) {
                      return (
                        <Scatter
                          name={item[0].z}
                          data={item}
                          fill={`${colores[getRandomArbitrary(0, 15)]}`}
                        />
                      );
                    }
                  })}
                </ScatterChart>
              </ResponsiveContainer>
            </div>
          </>
        ) : tipo === "pie2" ? (
          <>
            <div className="ContenedorGrafica">
              <div className="row pie">
                <ResponsiveContainer width="85%" aspect={2 / 1.3}>
                  <PieChart width={100} height={300}>
                    <Pie
                      data={datos}
                      cx={160}
                      cy={160}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey={variable}
                    >
                      {header.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={`${colores[index]}`}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </>
        ) : (
          tipo === "barras3" ? (
            <>
              <div className="ContenedorGrafica">
                <ResponsiveContainer width="100%" aspect={1 / 1}>
                  <BarChart
                    width={500}
                    height={300}
                    data={datos}
                    layout="vertical"
                    margin={{
                      top: 20,
                      right: 30,
                      left: 100,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis
                      dataKey="estado"
                      type="category"
                      position="insideStart"
                      width={200}
                    />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="riego" stackId="a" fill={`${colores[getRandomArbitrary(0, 15)]}`} />
                    <Bar dataKey="temporal" stackId="a" fill={`${colores[getRandomArbitrary(0, 15)]}`} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </>
          ) : (
            tipo === "mixta" && (
              <>
                <ResponsiveContainer width="100%" aspect={2 / 1}>
                  <ComposedChart
                    width={500}
                    height={400}
                    data={datos}
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                      //value='Volumen en (hm³/año)' angle={-90} position='insideLeft' <YAxis yAxisId="left" type="number" dataKey={header[2]}  name="weight" unit="" stroke="#8884d8" label={{value:'Volumen en (hm³/año)', angle:-90 ,position: 'insideLeft'}}  />
                    }}
                  >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey={variable} scale="band" >
                      <Label value="Año" offset={0} position="bottom" />
                    </XAxis>
                    {
                      consumo !== "" && (
                        <YAxis
                          yAxisId="right"
                          type="number"
                          dataKey={header[4]}
                          name="weight"
                          unit=""
                          orientation="right"
                          stroke="#4D455D"
                          label={{ value: 'Consumo de energía (Millones de kWh)', angle: 90, position: 'insideRight' }}
                        />
                      )
                    }

                    <YAxis label={{ value: 'Volumen (hm³/año)', angle: -90, position: 'insideLeft' }} />



                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                    <Bar dataKey={header[2]} barSize={20} fill={`${colores[getRandomArbitrary(0, 15)]}`} />
                    <Bar dataKey={header[3]} barSize={20} fill={`${colores[getRandomArbitrary(0, 15)]}`} />
                    <Line type="monotone" dataKey={header[4]} stroke={`#4D455D`} />
                  </ComposedChart>
                </ResponsiveContainer>
              </>
            )
          )
        )}
      </div>
    );
  }
};

export default Grafica;
