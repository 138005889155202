import React, { useState } from "react";
import Footer from "../../componentes/Footer";
import Header from "../../componentes/Header";
import './clientes.css'

const Clientes = () => {

    const [imagenM, setImagenM] = useState("");
    function imagenMostrar(opcion) {
        //console.log(opcion)
        setImagenM(opcion)
    }
    return (
        <div className="container-fluid">
            <Header></Header>
            {
                //<!-- about -->
            }

            <div class="clients">
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>Soluciones</h2>
                                <p>Al adquirir <strong>GeoMark</strong> se tiene acceso a novedosas y potenciales herramientas que permiten:</p>

                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9 offset-md-1">
                            <div className="titlepage">
                                <h2>Visualice su información en un solo sitio</h2>
                                <span></span>
                                <p>Sintetiza y analiza cantidades de datos, al adquirir GeoMark se tiene acceso a novedosas y potenciales herramientas que permiten:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">

                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" onClick={() => imagenMostrar("conoce")}>
                                            Conoce el perfil micro-espacial, local o global de tus clientes para tomar decisiones estratégicas oportunas.
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Además, identifica a tus competidores directos, a través, de herramientas estadísticas y de sistemas de información geográfica, llevando a cabo un análisis de la competencia de forma ágil, realista y visualizando el mercado no atendido.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo" onClick={() => imagenMostrar("mayor")}>
                                            Análisis empresarial unificado de sus datos financieros
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Convierta datos sin procesar en informes y graficas o paneles detallados. A través de un panel de control interactivo para mejorar la eficiencia y calidad del trabajo, manteniendo el nivel de productividad de la organización con el fin de acelerar resultados y obtener ventajas competitivas en el mercado., garantizando la toma de decisiones certeras y oportunas.  GeoMark garantiza la protección y la administración continua de sus datos.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree" onClick={() => imagenMostrar("ayuda")}>
                                            Bases de datos demográficos, sociales, económicos actualizadas
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            A través de una página web que no requiere descargas ni actualizaciones, podrás acceder a información sociodemográfica y económica a nivel manzana, localidad, municipio, entidad, o de una zona de influencia especifica, análisis de redes, hotspot de clientes, áreas potenciales de expansión, entre otras.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour" onClick={() => imagenMostrar("visualizacion")}>
                                            Visualización de datos de manera grafica
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Al utilizar elementos visuales las herramientas de visualización de datos proporcionan una fácil y sin complicaciones, para comprender tendencias, valores atípicos y patrones en los datos. Estos los podrás descargar fácilmente en diferentes formatos.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive" onClick={() => imagenMostrar("conoce")}>
                                            Conoce en profundidad las condiciones del mercado actual
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            Concede a la empresa la información que necesita para apuntar a las audiencias con las que quiere conectar, permite a las empresas tomar decisiones bien informadas, eliminar las conjeturas y canalizar recursos en ideas y proyectos que tienen el mayor potencial.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingSix">
                                        <button className="accordion-button collapsed titltB" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix" onClick={() => imagenMostrar("reconoce")}>
                                            Reconoce puntos de localización de clientes
                                        </button>
                                    </h2>
                                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            El geoposicionamiento ayuda a determinar o eliminar la posición geográfica en este caso de la competencia y posibles clientes, abre puertas de personalización de estrategias de marketing y conseguir que el cliente nos encuentre por su emplazamiento y necesidades concretas.
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-5">
                            {
                                imagenM === "" ? (
                                    <img src="imgs/img/2.png" alt="Soluciones" className="img-fluid imgSolu" />
                                ) : (
                                    imagenM === "conoce" ? (
                                        <img src="imgs/img/3.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                    ) : (
                                        imagenM === "mayor" ? (
                                            <img src="imgs/img/4.png" alt="Mayor visibilidad" className="img-fluid imgSolu" />
                                        ) : (
                                            imagenM === "ayuda" ? (
                                                <img src="imgs/img/5.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                            ) : (
                                                imagenM === "visualizacion" ? (
                                                    <img src="imgs/img/6.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                ) : (
                                                    imagenM === "conoce" ? (
                                                        <img src="imgs/img/7.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                    ) : (
                                                        imagenM === "reconoce" && (
                                                            <img src="imgs/img/8.png" alt="Competencia y clientes potenciales" className="img-fluid imgSolu" />
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )

                                )
                            }


                        </div>
                    </div>
                </div>
            </div>
            <br />

            {
                //<!--  footer -->
            }
            <Footer></Footer>
        </div>
    );
};

export default Clientes;
