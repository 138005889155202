import React from 'react'
import Footer from '../../componentes/Footer'
import Header from '../../componentes/Header'

const About = () => {
    return (
        <div className="container-fluid">
            <Header></Header>
            {
                //<!-- about -->
            }

            <div id="about" className="about" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/ab_bg.png'})` }}>
                <div className="container">
                    <div className="row d_flex">
                        <div className="col-md-12">
                            <div className="titlepage">
                                <h2>¿Qué es el Geomarketing?</h2>
                                <span></span>
                                <p>Es un sistema integrado por programas informáticos y métodos estadísticos que analiza grandes cantidades de datos representándolos a través de cartografía digital, gráficos y tablas. Permite conocer y gestionar los factores espaciales o geográficos que influyen y condicionan el proceso de marketing, útil para la identificación de necesidades y planeación comercial a corto y largo plazo.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row d_flex">
                        <div className="col-md-7">
                            <div className="titlepage">
                                <h2>ACERCA DE</h2>
                                <span></span>
                                <p><strong>GeoMark</strong> Geomark es una herramienta que permite el uso y análisis de datos geocodificados para simplificar procesos y tomar decisiones inteligentes sobre: la planificación del mercado, la evaluación de sitios potenciales de expansión, recorridos comerciales óptimos, aunado a la localización de clientes y competidores, entre otros. Además, posee un sitio para análisis financiero a través de un tablero de control.</p>
                                <p>Con GeoMark podrás combinar datos geoespaciales demográficos, económicos, sociales, entre otros de forma fácil y accesible, orientando a tu empresa o negocio hacia el conocimiento global del cliente (visión 360°), sus necesidades y comportamientos que ayuden a los tomadores de decisiones a evaluar y analizar las condiciones y oportunidades para la efectiva planeación comercial.</p>
                                <p>GeoMark funge como una ventaja competitiva rentable.</p>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="about_img img-fluid">
                                <figure><img src="imgs/img/1.png" alt="#" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                //<!--  footer -->
            }
            <Footer></Footer>

        </div>
    )
}

export default About