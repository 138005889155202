export const anioscuencas = [
    { id: 1, value: 2020, label: 2020 },
    { id: 1, value: 2016, label: 2016 },
    { id: 1, value: 2013, label: 2013 },
    { id: 1, value: 2012, label: 2012 },
    { id: 1, value: 2011, label: 2011 },
    { id: 1, value: 2010, label: 2010 },
];

export const anioscuencasOV = [   
    { id: 4, value: 2019, label: 2019 },
    { id: 3, value: 2018, label: 2018 },
    { id: 2, value: 2017, label: 2017 },
    { id: 1, value: 2016, label: 2016 }
];

export const anioscuencasZP = [
    { id: 8, value: 2021, label: 2021 },
    { id: 7, value: 2020, label: 2020 },
    { id: 6, value: 2019, label: 2019 },
    { id: 5, value: 2018, label: 2018 },
    { id: 4, value: 2017, label: 2017 },
    { id: 3, value: 2016, label: 2016 },
    { id: 2, value: 2015, label: 2015 },
    { id: 1, value: 2014, label: 2014 }
];

export const aniosprecnormal = [
    { id: 3, value: "1991-2020", label: "1991-2020" },
    { id: 2, value: "1981-2010", label: "1981-2010" },
    { id: 1, value: "1971-2000", label: "1971-2000" }
];

export const aniosprecanual = [
    { id: 18, value: 2021, label: 2021 },
    { id: 17, value: 2020, label: 2020 },
    { id: 16, value: 2019, label: 2019 },
    { id: 15, value: 2018, label: 2018 },
    { id: 14, value: 2017, label: 2017 },
    { id: 13, value: 2016, label: 2016 },
    { id: 12, value: 2015, label: 2015 },
    { id: 11, value: 2014, label: 2014 },
    { id: 10, value: 2013, label: 2013 },
    { id: 9, value: 2012, label: 2012 },
    { id: 8, value: 2011, label: 2011 },
    { id: 7, value: 2010, label: 2010 },
    { id: 6, value: 2009, label: 2009 },
    { id: 5, value: 2008, label: 2008 },
    { id: 4, value: 2007, label: 2007 },
    { id: 3, value: 2006, label: 2006 },
    { id: 2, value: 2005, label: 2005 },
    { id: 1, value: 2004, label: 2004 }
];


export const aniosCalidad2 = [
    { id: 10, value: 2021, label: 2021 },
    { id: 9, value: 2020, label: 2020 },
    { id: 8, value: 2019, label: 2019 },
    { id: 7, value: 2018, label: 2018 },
    { id: 6, value: 2017, label: 2017 },
    { id: 5, value: 2016, label: 2016 },
    { id: 4, value: 2015, label: 2015 },
    { id: 3, value: 2014, label: 2014 },
    { id: 2, value: 2013, label: 2013 },
    { id: 1, value: 2012, label: 2012 }
];


export const aniosacuif = [
    { id: 1, value: 2020, label: 2020 },
    { id: 1, value: 2018, label: 2018 },
    { id: 1, value: 2015, label: 2015 },
    { id: 1, value: 2013, label: 2013 },
    { id: 1, value: 2011, label: 2011 },
    { id: 1, value: 2010, label: 2010 },
    { id: 1, value: 2009, label: 2009 },
    { id: 1, value: 2008, label: 2008 },
    { id: 1, value: 2007, label: 2007 },
    { id: 1, value: 2003, label: 2003 },
];
export const aniosDistritos = [
    { id: 1, value: "2019 - 2020", label: "2019 - 2020" },
    { id: 1, value: "2018 - 2019", label: "2018 - 2019" },
    { id: 1, value: "2017 - 2018", label: "2017 - 2018" },
    { id: 1, value: "2016 - 2017", label: "2016 - 2017" },
    { id: 1, value: "2015 - 2016", label: "2015 - 2016" },
];

export const aniosPob = [
    {
        id: 1,
        value: 2020,
        label: 2020,
    },
    {
        id: 2,
        value: 2010,
        label: 2010,
    },
    {
        id: 3,
        value: 2000,
        label: 2000,
    },
];

export const aniosPresupuesto = [
    {
        id: 1,
        value: 2020,
        label: 2020,
    },
    {
        id: 2,
        value: 2019,
        label: 2019,
    },
    {
        id: 3,
        value: 2018,
        label: 2018,
    },
    {
        id: 4,
        value: 2017,
        label: 2017,
    },
    {
        id: 5,
        value: 2016,
        label: 2016,
    },
    {
        id: 6,
        value: 2015,
        label: 2015,
    },
    {
        id: 7,
        value: 2014,
        label: 2014,
    },
    {
        id: 8,
        value: 2013,
        label: 2013,
    },
    {
        id: 9,
        value: 2012,
        label: 2012,
    },
];

export const aniosPresupuestoMesual = [
    {
        id: 1,
        value: 2023,
        label: 2023,
    },
    {
        id: 2,
        value: 2022,
        label: 2022,
    }
];

export const MesPresupuesto = [
    {
        id: 1,
        value: "Febrero",
        label: "Febrero",
    },
    {
        id: 2,
        value: "Junio",
        label: "Junio",
    },
    {
        id: 3,
        value: "Julio",
        label: "Julio",
    },
    {
        id: 4,
        value: "Agosto",
        label: "Agosto",
    },
    {
        id: 5,
        value: "Septiembre",
        label: "Septiembre",
    },
    {
        id: 6,
        value: "Octubre",
        label: "Octubre",
    },
    {
        id: 7,
        value: "Noviembre",
        label: "Noviembre",
    },
    {
        id: 8,
        value: "Diciembre",
        label: "Diciembre",
    }
];
export const aniosRecaudacion = [
    {
        id: 0,
        value: 2021,
        label: 2021,
    },
    {
        id: 1,
        value: 2020,
        label: 2020,
    },
    {
        id: 2,
        value: 2019,
        label: 2019,
    },
    {
        id: 3,
        value: 2018,
        label: 2018,
    },
    {
        id: 4,
        value: 2017,
        label: 2017,
    },
    {
        id: 5,
        value: 2016,
        label: 2016,
    },
    {
        id: 6,
        value: 2015,
        label: 2015,
    },
    {
        id: 7,
        value: 2014,
        label: 2014,
    },
    {
        id: 8,
        value: 2013,
        label: 2013,
    },
    {
        id: 9,
        value: 2012,
        label: 2012,
    },
];

export const aniosSIH = [
    {
        id: 1,
        value: 1999,
        label: 1999,
    },
    {
        id: 2,
        value: 1998,
        label: 1998,
    },
    {
        id: 3,
        value: 1997,
        label: 1997,
    },
    {
        id: 4,
        value: 1996,
        label: 1996,
    },
    {
        id: 5,
        value: 1992,
        label: 1992,
    },
    {
        id: 6,
        value: 1991,
        label: 1991,
    },
    {
        id: 7,
        value: 1990,
        label: 1990,
    },
    {
        id: 8,
        value: 1989,
        label: 1989,
    },
    {
        id: 9,
        value: 1988,
        label: 1988,
    },
    {
        id: 10,
        value: 1987,
        label: 1987,
    },
    {
        id: 11,
        value: 1986,
        label: 1986,
    },
    {
        id: 12,
        value: 1985,
        label: 1985,
    },
    {
        id: 13,
        value: 1984,
        label: 1984,
    },
    {
        id: 14,
        value: 1983,
        label: 1983,
    },
    {
        id: 15,
        value: 1982,
        label: 1982,
    },
    {
        id: 16,
        value: 1981,
        label: 1981,
    },
    {
        id: 17,
        value: 1980,
        label: 1980,
    },
    {
        id: 18,
        value: 1979,
        label: 1979,
    },
    {
        id: 19,
        value: 1978,
        label: 1978,
    },
    {
        id: 20,
        value: 1977,
        label: 1977,
    },
    {
        id: 21,
        value: 1976,
        label: 1976,
    },
    {
        id: 22,
        value: 1975,
        label: 1975,
    },
    {
        id: 23,
        value: 1974,
        label: 1974,
    },
    {
        id: 24,
        value: 1973,
        label: 1973,
    },
    {
        id: 25,
        value: 1972,
        label: 1972,
    },
    {
        id: 26,
        value: 1971,
        label: 1971,
    },
    {
        id: 27,
        value: 1970,
        label: 1970,
    },
    {
        id: 28,
        value: 1969,
        label: 1969,
    },
    {
        id: 29,
        value: 1968,
        label: 1968,
    },
    {
        id: 30,
        value: 1967,
        label: 1967,
    },
    {
        id: 31,
        value: 1966,
        label: 1966,
    },
    {
        id: 32,
        value: 1965,
        label: 1965,
    },
    {
        id: 33,
        value: 1964,
        label: 1964,
    },
    {
        id: 34,
        value: 1963,
        label: 1963,
    },
    {
        id: 35,
        value: 1962,
        label: 1962,
    },
    {
        id: 36,
        value: 1961,
        label: 1961,
    },
    {
        id: 37,
        value: 1960,
        label: 1960,
    },
    {
        id: 38,
        value: 1959,
        label: 1959,
    },
    {
        id: 39,
        value: 1958,
        label: 1958,
    },
    {
        id: 40,
        value: 1957,
        label: 1957,
    },
    {
        id: 41,
        value: 1956,
        label: 1956,
    },
    {
        id: 42,
        value: 1955,
        label: 1955,
    },
    {
        id: 43,
        value: 1954,
        label: 1954,
    },
    {
        id: 44,
        value: 1953,
        label: 1953,
    },
    {
        id: 45,
        value: 1952,
        label: 1952,
    },
    {
        id: 46,
        value: 1951,
        label: 1951,
    },
    {
        id: 47,
        value: 1950,
        label: 1950,
    },
    {
        id: 48,
        value: 1949,
        label: 1949,
    },
    {
        id: 49,
        value: 1948,
        label: 1948,
    },
];

export const mesesSIH = [
    {
        id: 1,
        value: 1,
        label: 'Enero',
    },
    {
        id: 2,
        value: 2,
        label: 'Febrero',
    },
    {
        id: 3,
        value: 3,
        label: 'Marzo',
    },
    {
        id: 4,
        value: 4,
        label: 'Abril',
    },
    {
        id: 5,
        value: 5,
        label: 'Mayo',
    },
    {
        id: 6,
        value: 6,
        label: 'Junio',
    },
    {
        id: 7,
        value: 7,
        label: 'Julio',
    },
    {
        id: 8,
        value: 8,
        label: 'Agosto',
    },
    {
        id: 9,
        value: 9,
        label: 'Septiembre',
    },
    {
        id: 10,
        value: 10,
        label: 'Octubre',
    },
    {
        id: 11,
        value: 11,
        label: 'Noviembre',
    },
    {
        id: 12,
        value: 12,
        label: 'Diciembre',
    },
];


export const aniosgrafPresup = [
    { id: 9, value: 2020, label: 2020 },
    { id: 8, value: 2019, label: 2019 },
    { id: 7, value: 2018, label: 2018 },
    { id: 6, value: 2017, label: 2017 },
    { id: 5, value: 2016, label: 2016 },
    { id: 4, value: 2015, label: 2015 },
    { id: 3, value: 2014, label: 2014 },
    { id: 2, value: 2013, label: 2013 },
    { id: 1, value: 2012, label: 2012 }
];

export const aniosCutzamala = [
    { id: 16, value: 2021, label: 2021 },
    { id: 15, value: 2020, label: 2020 },
    { id: 14, value: 2019, label: 2019 },
    { id: 13, value: 2018, label: 2018 },
    { id: 12, value: 2017, label: 2017 },
    { id: 11, value: 2016, label: 2016 },
    { id: 10, value: 2015, label: 2015 },
    { id: 9, value: 2013, label: 2013 },
    { id: 8, value: 2012, label: 2012 },
    { id: 7, value: 2011, label: 2011 },
    { id: 6, value: 2010, label: 2010 },
    { id: 5, value: 2009, label: 2009 },
    { id: 4, value: 2008, label: 2008 },
    { id: 3, value: 2007, label: 2007 },
    { id: 2, value: 2006, label: 2006 },
    { id: 1, value: 2005, label: 2005 }
];

export const aniosciclones = [
    { id: 8, value: 2022, label: 2022 },
    { id: 7, value: 2021, label: 2021 },
    { id: 6, value: 2020, label: 2020 },
    { id: 5, value: 2019, label: 2019 },
    { id: 4, value: 2018, label: 2018 },
    { id: 3, value: 2017, label: 2017 },
    { id: 2, value: 2016, label: 2016 },
    { id: 1, value: 2015, label: 2015 }
];

export const dias=[
    {
        "id": 1,
        "value": 1,
        "label": 1
    },
    {
        "id": 2,
        "value": 2,
        "label": 2
    },
    {
        "id": 3,
        "value": 3,
        "label": 3
    },
    {
        "id": 4,
        "value": 4,
        "label": 4
    },
    {
        "id": 5,
        "value": 5,
        "label": 5
    },
    {
        "id": 6,
        "value": 6,
        "label": 6
    },
    {
        "id": 7,
        "value": 7,
        "label": 7
    },
    {
        "id": 8,
        "value": 8,
        "label": 8
    },
    {
        "id": 9,
        "value": 9,
        "label": 9
    },
    {
        "id": 10,
        "value": 10,
        "label": 10
    },
    {
        "id": 11,
        "value": 11,
        "label": 11
    },
    {
        "id": 12,
        "value": 12,
        "label": 12
    },
    {
        "id": 13,
        "value": 13,
        "label": 13
    },
    {
        "id": 14,
        "value": 14,
        "label": 14
    },
    {
        "id": 15,
        "value": 15,
        "label": 15
    },
    {
        "id": 16,
        "value": 16,
        "label": 16
    },
    {
        "id": 17,
        "value": 17,
        "label": 17
    },
    {
        "id": 18,
        "value": 18,
        "label": 18
    },
    {
        "id": 19,
        "value": 19,
        "label": 19
    },
    {
        "id": 20,
        "value": 20,
        "label": 20
    },
    {
        "id": 21,
        "value": 21,
        "label": 21
    },
    {
        "id": 22,
        "value": 22,
        "label": 22
    },
    {
        "id": 23,
        "value": 23,
        "label": 23
    },
    {
        "id": 24,
        "value": 24,
        "label": 24
    },
    {
        "id": 25,
        "value": 25,
        "label": 25
    },
    {
        "id": 26,
        "value": 26,
        "label": 26
    },
    {
        "id": 27,
        "value": 27,
        "label": 27
    },
    {
        "id": 28,
        "value": 28,
        "label": 28
    },
    {
        "id": 29,
        "value": 29,
        "label": 29
    },
    {
        "id": 30,
        "value": 30,
        "label": 30
    },
    {
        "id": 31,
        "value": 31,
        "label": 31
    }
]

export const aniosmodernizacion = [
    { id: 3, value: 2021, label: 2021 },
    { id: 2, value: 2020, label: 2020 },
    { id: 1, value: 2019, label: 2019 }
];