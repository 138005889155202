import React from 'react'
import { Spinner } from 'reactstrap'
import './loader.scss'

function Loader() {
    return (
        <div>
            <div className='divPadre'>
                <div className='divHijo'>
                    <Spinner color="secondary" className='spinner' />
                </div>
            </div>
        </div>
    )
}

export default React.memo(Loader);