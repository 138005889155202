import React, { useState, useEffect } from "react";
import Header from "../../componentes/Header";
import { Link } from "react-router-dom";
import './modelo.css'
import $ from "jquery";

var TotalMoviliario = 0;

const Modelo = () => {
    const [totalFijas, setTotalFijas] = useState(0.0);


    function calcularTotales(idTabla) {
        const tabla = document.getElementById(idTabla).getElementsByTagName("tbody")[0];
        const filas = tabla.getElementsByTagName("tr");

        for (let i = 0; i < filas.length; i++) {
            const celdas = filas[i].getElementsByTagName("td");
            const cantidad = parseFloat(celdas[1].textContent);
            const precioUnitario = parseFloat(celdas[2].textContent);
            const total = isNaN(cantidad) || isNaN(precioUnitario) ? 0 : cantidad * precioUnitario;
            celdas[3].textContent = total.toFixed(2);
            actualizarTotal(idTabla)
        }

    }

    function actualizarTotal(idTabla) {
        // console.log("actualiza total")
        const filas = document.getElementById(idTabla).getElementsByTagName("tbody")[0].getElementsByTagName("tr");
        let totalCompra = 0;

        for (let i = 0; i < filas.length - 1; i++) {
            const total = parseFloat(filas[i].cells[3].textContent);
            totalCompra += isNaN(total) ? 0 : total;
        }
        //console.log(totalCompra.toFixed(2))

        // celdas[3].textContent = total.toFixed(2);
        document.getElementById(`total${idTabla}`).textContent = totalCompra.toFixed(2);
        document.getElementById(`total${idTabla}1`).textContent = totalCompra.toFixed(2);
        document.getElementById(`totalFijas`).textContent = (Number(document.getElementById(`totalMobiliario1`).textContent) + Number(document.getElementById(`totalEquipo1`).textContent) + Number(document.getElementById(`totalOficina1`).textContent) + Number(document.getElementById(`totalComputo1`).textContent)).toFixed(2)
        document.getElementById(`totalDiferidas`).textContent = (Number(document.getElementById(`totalInstalacion1`).textContent) + Number(document.getElementById(`totalPreoperativos1`).textContent)).toFixed(2)
        document.getElementById(`totalPresupuesto`).textContent = (Number(document.getElementById(`totalFijas`).textContent) + Number(document.getElementById(`totalDiferidas`).textContent) + Number(document.getElementById(`totalCapital`).textContent)).toFixed(2)
    }

    function actualizarTotal2() {
        document.getElementById(`totalCapital`).textContent = (Number(document.getElementById(`tcaja`).textContent) + Number(document.getElementById(`tbanco`).textContent)).toFixed(2)
        document.getElementById(`totalPresupuesto`).textContent = (Number(document.getElementById(`totalFijas`).textContent) + Number(document.getElementById(`totalDiferidas`).textContent) + Number(document.getElementById(`totalCapital`).textContent)).toFixed(2)
    }





    return (
        <div className="container-fluid">
            <Header></Header>
            <br />
            <section className="vh-99">
                <div className="container-fluid h-custom">
                    <div className="row justify-content-end">
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Modelo'}>
                                <button type="button" className="btn btn-secondary">M. Evaluación Financiera</button>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Reportes'}>
                                <button type="button" className="btn btn-secondary">Panel de Control</button>
                            </Link>
                        </div>
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2'>
                            <Link to={'/Visualizador'}>
                                <button type="button" className="btn btn-secondary">Mapa</button>
                            </Link>
                        </div>
                    </div>
                    <br />
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                            <h2>Mobiliario y equipo</h2>
                            <table id="Mobiliario" className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Cantidad</th>
                                        <th>Costo unitario</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Mobiliario")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Mobiliario")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Mobiliario")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Mobiliario")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Mobiliario")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Mobiliario")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" className='totalTabla'>Total</td>
                                        <td id='totalMobiliario' className='T'></td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2>Equipo y Trasporte</h2>
                            <table id="Equipo" className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Cantidad</th>
                                        <th>Costo unitario</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Equipo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Equipo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Equipo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Equipo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Equipo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Equipo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" className='totalTabla'>Total</td>
                                        <td id='totalEquipo' className='T'></td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2>Equipo de oficina</h2>
                            <table id="Oficina" className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Cantidad</th>
                                        <th>Costo unitario</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Oficina")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Oficina")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Oficina")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Oficina")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Oficina")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Oficina")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" className='totalTabla'>Total</td>
                                        <td id='totalOficina' className='T'></td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2>Equipo de cómputo</h2>
                            <table id="Computo" className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Cantidad</th>
                                        <th>Costo unitario</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Computo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Computo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Computo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Computo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Computo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Computo")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" className='totalTabla'>Total</td>
                                        <td id='totalComputo' className='T'></td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2>Gastos de instalación</h2>
                            <table id="Instalacion" className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Cantidad</th>
                                        <th>Costo unitario</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Instalacion")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Instalacion")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Instalacion")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Instalacion")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Instalacion")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Instalacion")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" className='totalTabla'>Total</td>
                                        <td id='totalInstalacion' className='T'></td>
                                    </tr>
                                </tbody>
                            </table>

                            <h2>Gastos preoperativos</h2>
                            <table id="Preoperativos" className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th>Cantidad</th>
                                        <th>Costo unitario</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Preoperativos")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Preoperativos")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Preoperativos")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Preoperativos")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Preoperativos")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true"></td>
                                        <td contenteditable="true" onBlur={(e) => calcularTotales("Preoperativos")}></td>
                                        <td>0.0</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" className='totalTabla'>Total</td>
                                        <td id='totalPreoperativos' className='T'></td>
                                    </tr>
                                </tbody>
                            </table>




                        </div>
                        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                            <h2>Presupuesto de Inversiones</h2>
                            <table id="Presupuesto" className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th><h4>Inversiones Fijas</h4></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Mobiliario y equipo</td>
                                        <td id='totalMobiliario1'></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Equipo de transporte</td>
                                        <td id='totalEquipo1'></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Equipo de oficina</td>
                                        <td id='totalOficina1'></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Equipo de cómputo</td>
                                        <td id='totalComputo1'></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th><h4>Total de Inversiones Fijas</h4></th>
                                        <td></td>
                                        <td id='totalFijas' className="Totales">0.0</td>
                                    </tr>
                                    <tr>
                                        <th><h4>Inversiones Diferidas</h4></th>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Gastos de instalación</td>
                                        <td id='totalInstalacion1'></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Gastos preoperativos</td>
                                        <td id='totalPreoperativos1'></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th><h4>Total de Inversiones Diferidas</h4></th>
                                        <td></td>
                                        <td id='totalDiferidas' className="Totales">0.0</td>
                                    </tr>
                                    <tr>
                                        <th><h4>Capital de Trabajo</h4></th>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Caja</td>
                                        <td contenteditable="true" id='tcaja' className="editable" onBlur={actualizarTotal2}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Bancos</td>
                                        <td contenteditable="true" id='tbanco' className="editable" onBlur={actualizarTotal2}></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th><h4>Total de Capital de Trabajo</h4></th>
                                        <td></td>
                                        <td id='totalCapital' className="Totales">0.0</td>
                                    </tr>
                                    <tr>
                                        <th><h4>Total de Presupuesto de Inversiones</h4></th>
                                        <td></td>
                                        <td id='totalPresupuesto' className="Totales">0.0</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>


                </div>
            </section>

        </div>
    )
}

export default Modelo