import React from 'react'
import env from 'react-dotenv'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import axios from 'axios'
import './pago.css'
import Cookie from 'universal-cookie';
import Swal from 'sweetalert2'


const stripePromise = loadStripe(env.PUBLIC_KEY)




const Checkoutform = () => {
  const cookie = new Cookie();

  const stripe = useStripe();
  const element = useElements();



  const handleSubmit = async (e) => {
    console.log("Se paga")
    //console.log(cookie.get('usuario'))
    e.preventDefault() //evita que se refresque la pantalla

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: element.getElement(CardElement)
    })

    if (!error) {
      //console.log(paymentMethod)
      const { id } = paymentMethod;

      const { data } = await axios.post('http://192.168.100.34:4000/api/usuarios/pago', {
        id,
        amount: 10000,
      })

      if (data.message === "Succesfull payment") {
        //el pago fue exitoso entonces se manda guardar el usuario
        console.log("el pago se efectuo correctamente")
        let aux = cookie.get('usuario');
        aux.pagado = 1;
        console.log(aux);
        const msj = await axios.post('http://192.168.100.34:4000/api/usuarios/guardar', aux)
        console.log(msj.status);
        element.getElement(CardElement).clear();
        if (msj.status === 200) {
          //se registro correctamente el usuario
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Usuario Registrado correctamente',
            showConfirmButton: false,
            timer: 1500
          })
          setTimeout(() => {
            console.log("1 Segundo esperado")
            var anchor = document.createElement('a');
            anchor.href = "#/mapa";
            anchor.click();
          }, 1600);
        } else {
          //el usuario no se registro correctamente
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Error en el registro',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }

    }



  }


  return <form onClick={handleSubmit} className="cardP card-body">
    <img src="imgs/logo.svg" alt="#" className="img-fluid" width={100} />
    <br />
    <div className="form-group">
      <CardElement className='form-control'></CardElement>
    </div>
    <br />
    <button className='btn btn-success boton'>Pagar</button>
  </form>

}

function Pago() {



  return (
    <>
      <Elements stripe={stripePromise}>
        <div className='container p-3'>
          <div className="row">
            <div className="col-md-4 offset-md-4 my-2">
              <Checkoutform></Checkoutform>
            </div>
          </div>
        </div>

      </Elements>
    </>

  )
}

export default Pago