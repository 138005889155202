class Simbologia {

    getPoblacionM(d) {
        //console.log(d)
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }
    getPoblacionF(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") < 921 ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionT(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "196-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionMas18mas(d) {
        return ((d > 0 && d < 18) || d === "0-18 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 18 && d < 67) || d === "19-67 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 67 && d < 178) || d === "68-178 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 178 && d < 621) || d === "179-621 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionFem18mas(d) {
        return ((d > 0 && d < 39) || d === "0-39 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 39 && d < 143) || d === "40-143 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 143 && d < 380) || d === "144-380 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 380 && d < 1319) || d === "381-1319 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionDe18mas(d) {
        return ((d > 0 && d < 39) || d === "0-39 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 39 && d < 143) || d === "40-143 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 143 && d < 380) || d === "144-380 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 380 && d < 1319) || d === "381-1319 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionMas15mas(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionFem15mas(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionDe15mas(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionMas12mas(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionFem12mas(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionDe12mas(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionMas5mas(d) {
        console.log(d);
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionFem5mas(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }
    getPoblacionDe5mas(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionMas3mas(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionDe3masFem(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionDe3mas(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }


    getPoblacionFde0a2(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPob0a2Mas(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionDe0a2(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionDe3a5(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionFde3a5(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }
    getPoblacionMde3a5(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }
    getPoblacionDe6a11(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionFde6a11(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionMde6a11(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionDe8a14(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPoblacionFde8a14(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionMde8a14(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }
    getPoblacionDe12a14(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPoblacionFde121a14(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }
    getPoblacionMde12a14(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionDe15a17(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPoblacionFde151a17(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }
    getPoblacionMde15a17(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }
    getPoblacionDe18a24(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionFde18a24(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionMde18a24(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacionFde15a49(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionDe60mas(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPoblacionFde60mas(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionMde60mas(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }
    getRelacionhm(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPoblacionDe0a14(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPoblacionDe15a64(d) {
        return ((d > 0 && d < 37) || d === "0-37 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 37 && d < 136) || d === "38-136 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 136 && d < 366) || d === "137-366 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 366 && d < 1318) || d === "366-1318 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionDe65mas(d) {
        return ((d > 0 && d < 4) || d === "0-4 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 4 && d < 16) || d === "5-16 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 16 && d < 38) || d === "17-38 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 38 && d < 142) || d === "39-142 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPromediohnv(d) {
        return (d === 0 || d === "0 hijos") ? this.convertRGBHexadeciaml(247, 251, 255) :
            (d === 1 || d === "1 hijo") ? this.convertRGBHexadeciaml(247, 198, 192) :
                (d === 2 || d === "2 hijos") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    (d === 3 || d === "3 hijo") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionFne(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionMne(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }
    getPoblacionOen(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPoblacionFOen(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPoblacionmOen(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPoblacion5marz2015(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionF5marz2015(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }
    getPoblacionM5marz2015(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPob2(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPoblacionFotra2015(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }
    getPoblacionMotra2015(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }
    getPoblacionne(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPob3indigena(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPobF3indigena(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }

    getPobM3indigena(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPob3indigesp(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPobF3indigesp(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }
    getPobM3indigesp(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }
    getPob3indigyesp(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPobF3indigyesp(d) {
        return ((d > 0 && d < 27) || d === "0-27 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 27 && d < 99) || d === "28-99 habitantes") ? this.convertRGBHexadeciaml(246, 190, 244) :
                ((d >= 99 && d < 263) || d === "100-263 habitantes") ? this.convertRGBHexadeciaml(244, 128, 234) :
                    ((d >= 263 && d < 921) || d === "264-921 habitantes") ? this.convertRGBHexadeciaml(243, 67, 223) :
                        this.convertRGBHexadeciaml(242, 5, 212);
    }
    getPobM3indigyesp(d) {
        return ((d > 0 && d < 26) || d === "0-26 habitantes") ? this.convertRGBHexadeciaml(247, 255, 255) :
            ((d >= 26 && d < 94) || d === "27-94 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                ((d >= 94 && d < 248) || d === "95-248 habitantes") ? this.convertRGBHexadeciaml(186, 220, 246) :
                    ((d >= 248 && d < 872) || d === "249-872 habitantes") ? this.convertRGBHexadeciaml(63, 160, 227) :
                        this.convertRGBHexadeciaml(2, 129, 217);
    }

    getPob5indigena(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPob5indigyesp(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPob5indigesp(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPobcensal(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPobafrmex(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPobaFfrmex(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPobaMfrmex(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPob3a5sines(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getPobecogral(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getPobsinserv(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getSituacionCony(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getReligion(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }
    getHogares(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }

    getVivienda(d) {
        return ((d > 0 && d < 54) || d === "0-54 habitantes") ? this.convertRGBHexadeciaml(247, 251, 255) :
            ((d >= 54 && d < 195) || d === "55-195 habitantes") ? this.convertRGBHexadeciaml(247, 198, 192) :
                ((d >= 195 && d < 516) || d === "195-516 habitantes") ? this.convertRGBHexadeciaml(246, 145, 129) :
                    ((d >= 516 && d < 1812) || d === "517-1812 habitantes") ? this.convertRGBHexadeciaml(246, 92, 65) :
                        this.convertRGBHexadeciaml(245, 39, 2);
    }





    getZonasAtencion(d) {
        return d === "A-MUNICIPIOS INDÍGENAS" ?
            this.convertRGBHexadeciaml(84, 24, 59) :
            d === "B-MUNICIPIOS INDÍGENAS" ?
                this.convertRGBHexadeciaml(100, 45, 87) :
                d === "C-PRESENCIA INDÍGENA" ?
                    this.convertRGBHexadeciaml(111, 37, 80) :
                    d === "D-PRESENCIA INDÍGENA" ?
                        this.convertRGBHexadeciaml(125, 76, 116) :
                        d === "E-PRESENCIA INDÍGENA DISPERSA" ?
                            this.convertRGBHexadeciaml(171, 116, 163) :
                            d === "F-SIN POBLACIÓN INDÍGENA" ?
                                this.convertRGBHexadeciaml(212, 146, 193) :
                                d === "SD-SIN DATO" && this.convertRGBHexadeciaml(184, 182, 184);
    }

    getMarginacion(d) {
        return d === "Muy bajo" ?
            this.convertRGBHexadeciaml(204, 148, 145) :
            d === "Bajo" ?
                this.convertRGBHexadeciaml(184, 118, 118) :
                d === "Medio" ?
                    this.convertRGBHexadeciaml(158, 102, 107) :
                    d === "Alto" ?
                        this.convertRGBHexadeciaml(150, 77, 89) :
                        d === "Muy alto" && this.convertRGBHexadeciaml(123, 37, 70);
    }

    getMarginacionN(d) {
        return d === "MUY BAJO" ?
            this.convertRGBHexadeciaml(255, 245, 240) :
            d === "BAJO" ?
                this.convertRGBHexadeciaml(252, 190, 165) :
                d === "MEDIO" ?
                    this.convertRGBHexadeciaml(251, 112, 80) :
                    d === "ALTO" ?
                        this.convertRGBHexadeciaml(211, 32, 32) :
                        d === "MUY ALTO" && this.convertRGBHexadeciaml(103, 0, 13);
    }

    getPoblacionLoc(d) {
        return d >= 20819 || d === "20819 - 1038460" ?
            this.convertRGBHexadeciaml(148, 184, 186) :
            (d > 8445 && d < 20819) || d === "8445 - 20819" ?
                this.convertRGBHexadeciaml(116, 158, 153) :
                (d > 3582 && d < 8445) || d === "3582 - 8445" ?
                    this.convertRGBHexadeciaml(88, 161, 142) :
                    (d > 1151 && d < 33582) || d === "1151 - 3582" ?
                        this.convertRGBHexadeciaml(77, 127, 117) :
                        ((d > 42 && d < 1151) || d === "42 - 1151") && this.convertRGBHexadeciaml(53, 113, 112);
    }

    getPoblacionInd(d) {
        return d >= 70000 || d === "70000-158867" ?
            this.convertRGBHexadeciaml(118, 87, 148) :
            (d < 70000 && d > 36000) || d === "36000–70000" ?
                this.convertRGBHexadeciaml(136, 93, 140) :
                (d < 36000 && d > 15000) || d === "15000-36000" ?
                    this.convertRGBHexadeciaml(168, 107, 149) :
                    (d < 15000 && d > 4000) || d === "4000-15000" ?
                        this.convertRGBHexadeciaml(235, 161, 202) :
                        (d < 4000 || d === "0-4000") && this.convertRGBHexadeciaml(222, 188, 222);
    }

    getSuperficial(d) {
        return (d === "rojo" || d === "incumplimiento-rojo") ?
            this.convertRGBHexadeciaml(207, 0, 0) :
            (d === "amarillo" || d === "incumplimiento-amarillo") ?
                this.convertRGBHexadeciaml(240, 201, 41) :
                (d === "verde" || d === "cumplimiento-verde") && this.convertRGBHexadeciaml(129, 178, 20);
    }

    getColorAlcalinidad(d) {
        return d === "BAJA" ?
            this.convertRGBHexadeciaml(3, 90, 166) //'#035aa6'
            :
            d === "MEDIA" ?
                this.convertRGBHexadeciaml(129, 178, 20) //'#81b214' :
                :
                d === "ALTA" ?
                    this.convertRGBHexadeciaml(240, 201, 41) ///'#f0c929' :240 201 41
                    :
                    d === "INDESEABLE" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //'#cf0000' :207 0 0
                        :
                        d === "INDESEABLE COMO FAAP" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126	//'#72147e';
    }

    getColorArsenico(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "APTA COMO FAAP" ?
                this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                :
                d === "NO APTA COMO FAAP" ?
                    this.convertRGBHexadeciaml(114, 20, 126) :
                    ""; //114 20 126 '#72147E' : '';
    }

    getColorCadmio(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "APTA COMO FAAP" ?
                this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                :
                d === "NO APTA COMO FAAP" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getcolorColiFec(d) {
        return d === "EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "ACEPTABLE" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "CONTAMINADA" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                        :
                        d === "FUERTEMENTE CONTAMINADA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getcolorColiFec1(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(129, 178, 20) // 129 178 20
                :
                d === "ACEPTABLE" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "CONTAMINADA" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                        :
                        d === "FUERTEMENTE CONTAMINADA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getcolorConductividad(d) {
        return d === "EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "BUENA PARA RIEGO" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "PERMISIBLE PARA RIEGO" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "DUDOSA PARA RIEGO" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                        :
                        d === "INDESEABLE PARA RIEGO" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getcolorCromo(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "NO APTA COMO FAAP" && this.convertRGBHexadeciaml(207, 0, 0); //207 0 0
    }

    getColorDBO(d) {
        return d === "EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "ACEPTABLE" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "CONTAMINADA" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                        :
                        d === "FUERTEMENTE CONTAMINADA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }
    getColorDQO(d) {
        return d === "EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "ACEPTABLE" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "CONTAMINADA" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                        :
                        d === "FUERTEMENTE CONTAMINADA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getColordureza(d) {
        return d === "POTABLE - SUAVE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "POTABLE - MODERADAMENTE SUAVE" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "POTABLE - DURA" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "MUY DURA E INDESEABLE USOS INDISTRIAL Y DOMESTICO" &&
                    this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getColorEsc(d) {
        return d === "EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "ACEPTABLE" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //207 0 0
                    :
                    d === "CONTAMINADA" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                        :
                        d === "FUERTEMENTE CONTAMINADA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }
    getcolorEntero(d) {
        return d === "EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "ACEPTABLE" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //207 0 0
                    :
                    d === "CONTAMINADA" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                        :
                        d === "FUERTEMENTE CONTAMINADA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getColorHierro(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "SIN EFECTOS EN LA SALUD - PUEDE DAR COLOR AL AGUA" &&
            this.convertRGBHexadeciaml(207, 0, 0); //207 0 0
    }

    getColorFlour(d) {
        return d === "POTABLE-OPTIMA" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "MEDIA" ?
                this.convertRGBHexadeciaml(129, 178, 20) // 129 178 20
                :
                d === "BAJA" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "ALTA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }
    getcolorMercurio(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "NO APTA COMO FAAP" && this.convertRGBHexadeciaml(207, 0, 0); // 207 0 0
    }
    getColorMagnesio(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "SIN EFECTOS EN LA SALUD - PUEDE DAR COLOR AL AGUA" ?
                this.convertRGBHexadeciaml(240, 201, 41) // 240 201 41
                :
                d === "PUEDE AFECTAR LA SALUD" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }
    getColorNitrogeno(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "POTABLE - BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                :
                d === "NO APTA COMO FAAP" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }
    getColorOxigeno(d) {
        return d === "EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(129, 178, 20) // 129 178 20
                :
                d === "ACEPTABLE" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "CONTAMINADA" ?
                        this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                        :
                        d === "FUERTEMENTE CONTAMINADA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getColorPlomo(d) {
        return d === "POTABLE - EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "NO APTA COMO FAAP" && this.convertRGBHexadeciaml(207, 0, 0); // 207 0 0
    }
    getColorSDT(d) {
        return d === "EXCELENTE PARA RIEGO" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "CULTIVOS CON MANEJO ESPECIAL" ?
                this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                :
                d === "CULTIVOS TOLERANTES" ?
                    this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                    :
                    d === "INDESEABLE PARA RIEGO" ?
                        this.convertRGBHexadeciaml(114, 20, 126) //114 20 126
                        :
                        d === "CULTIVOS SENCIBLES" && this.convertRGBHexadeciaml(129, 178, 20); // 129 178 20
    }
    getColorSDT1(d) {
        return d === "POTABLE - DULCE" ?
            this.convertRGBHexadeciaml(3, 90, 166) //3 90 166
            :
            d === "LIGERAMENTE SALOBRE" ?
                this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                :
                d === "SALOBRES" ?
                    this.convertRGBHexadeciaml(207, 0, 0) //207 0 0
                    :
                    d === "SALINAS" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }
    getColorSST(d) {
        return d === "EXCELENTE" ?
            this.convertRGBHexadeciaml(3, 90, 166) // 3 90 166
            :
            d === "BUENA CALIDAD" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "ACEPTABLE" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "FUERTEMENTE CONTAMINADA" ?
                        this.convertRGBHexadeciaml(114, 20, 126) //114 20 126
                        :
                        d === "CONTAMINADA" && this.convertRGBHexadeciaml(207, 0, 0); //207 0 0
    }
    getColorTD(d) {
        return d === "NO TOXICO" ?
            this.convertRGBHexadeciaml(3, 90, 166) // 3 90 166
            :
            d === "TOXICIDAD BAJA" ?
                this.convertRGBHexadeciaml(129, 178, 20) //129 178 20
                :
                d === "TOXICIDAD MODERADA" ?
                    this.convertRGBHexadeciaml(240, 201, 41) //240 201 41
                    :
                    d === "TOXICIDAD ALTA" && this.convertRGBHexadeciaml(114, 20, 126); //114 20 126
    }

    getPrestadores(d) {
        return d === "Centralizado" ? this.convertRGBHexadeciaml(179, 88, 6) :
            d === "Concesionario privado" ? this.convertRGBHexadeciaml(224, 130, 20) :
                d === "Desconcentrado de la entidad" ? this.convertRGBHexadeciaml(253, 184, 99) :
                    d === "Desconcentrado del ayuntamiento" ? this.convertRGBHexadeciaml(254, 224, 182) :
                        d === "Empresa público privada" ? this.convertRGBHexadeciaml(216, 218, 235) :
                            d === "OPD paraestatal" ? this.convertRGBHexadeciaml(178, 171, 210) :
                                d === "OPD paramunicipal" ? this.convertRGBHexadeciaml(128, 115, 172) :
                                    d === "Operador federal" ? this.convertRGBHexadeciaml(84, 39, 136) : this.convertRGBHexadeciaml(247, 247, 247);


    }
    getAquaRating(d) {
        return d === "Cetificado" || d === "Certificado" ? this.convertRGBHexadeciaml(140, 81, 10) :
            d === "Evaluado" ? this.convertRGBHexadeciaml(216, 179, 101) :
                d === "Programado" ? this.convertRGBHexadeciaml(246, 232, 195) :
                    d === "Requerido PRODI" ? this.convertRGBHexadeciaml(199, 234, 229) :
                        d === "Sin información" ? this.convertRGBHexadeciaml(90, 180, 172) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getPresupuestoRegion(d) {
        return d === "Norte" ? this.convertRGBHexadeciaml(247, 104, 161) :
            d === "Sur" ? this.convertRGBHexadeciaml(254, 235, 226) :
                d === "Valle de Mexico" ? this.convertRGBHexadeciaml(122, 1, 119) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getPresupuestoEntidad(d) {
        return d >= 0.7 || d === "Presupuesto mayor a 0.7 mdp" ? this.convertRGBHexadeciaml(122, 1, 119) :
            (d < 0.7 && d > 0.2) || d === "Presupuesto de 0.2 a 0.7 mdp" ? this.convertRGBHexadeciaml(247, 104, 161) :
                d <= 0.2 || d === "Presupuesto menor a 0.2 mdp" ? this.convertRGBHexadeciaml(254, 235, 226) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getPresupuestoNacional(d) {
        return d >= 600 || d === "Presupuesto mayor a 600 mdp" ? this.convertRGBHexadeciaml(122, 1, 119) :
            (d < 600 && d > 200) || d === "Presupuesto de 200 a 600 mdp" ? this.convertRGBHexadeciaml(247, 104, 161) :
                d <= 200 || d === "Presupuesto menor a 200 mdp" ? this.convertRGBHexadeciaml(254, 235, 226) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getPresupuestoRHA(d) {
        return d > 1700 || d === "Presupuesto mayor a 1700 mdp" ? this.convertRGBHexadeciaml(8, 48, 107) :
            (d <= 1700 && d > 900) || d === "Presupuesto de 900 a 1700 mdp" ? this.convertRGBHexadeciaml(41, 121, 185) :
                (d <= 900 && d > 400) || d === "Presupuesto de 400 a 900 mdp" ? this.convertRGBHexadeciaml(115, 178, 216) :
                    (d <= 400 && d > 150) || d === "Presupuesto de 150 a 400 mdp" ? this.convertRGBHexadeciaml(200, 220, 240) :
                        d <= 150 || d === "Presupuesto menor a 150 mdp" ? this.convertRGBHexadeciaml(247, 251, 255) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getPresupuestoDL(d) {
        return d > 350 || d === "Presupuesto mayor a 350 mdp" ? this.convertRGBHexadeciaml(0, 68, 27) :
            (d <= 350 && d > 200) || d === "Presupuesto de 200 a 350 mdp" ? this.convertRGBHexadeciaml(42, 146, 75) :
                (d <= 200 && d > 110) || d === "Presupuesto de 110 a 200 mdp" ? this.convertRGBHexadeciaml(123, 199, 124) :
                    (d <= 110 && d > 40) || d === "Presupuesto de 40 a 110 mdp" ? this.convertRGBHexadeciaml(201, 234, 194) :
                        d <= 40 || d === "Presupuesto menor a 40 mdp" ? this.convertRGBHexadeciaml(247, 252, 245) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getAcuiferos(d) {
        return d === "Con Disponibilidad" ? this.convertRGBHexadeciaml(166, 189, 219) :
            d === "Sin Disponibilidad" ? this.convertRGBHexadeciaml(215, 48, 31) : this.convertRGBHexadeciaml(255, 255, 255);
    }

    getAcuiferosCon(d) {
        return d === "SUBEXPLOTADO" || d === "Subexplotado" ? this.convertRGBHexadeciaml(111, 189, 70) :
            d === "SOBREEXPLOTADO" || d === "Sobreexplotado" ? this.convertRGBHexadeciaml(255, 0, 0) : this.convertRGBHexadeciaml(255, 255, 255);
    }


    getCuencas(d) {
        return d === "Con disponibilidad" ? this.convertRGBHexadeciaml(116, 196, 118) :
            d === "Sin disponibilidad" ? this.convertRGBHexadeciaml(215, 48, 31) : this.convertRGBHexadeciaml(255, 255, 255);
    }

    getCuencasZP(d) { //console.log(d);
        return d === 1 || d === "Zona 1" ? this.convertRGBHexadeciaml(249, 211, 205) :
            d === 2 || d === "Zona 2" ? this.convertRGBHexadeciaml(234, 123, 159) :
                d === 3 || d === "Zona 3" ? this.convertRGBHexadeciaml(251, 219, 160) :
                    d === 4 || d === "Zona 4" ? this.convertRGBHexadeciaml(242, 157, 79) :
                        d === null || d === "Zona sin corrientes perennes" ? this.convertRGBHexadeciaml(191, 191, 191) : this.convertRGBHexadeciaml(255, 255, 255);
    }

    getCuencasO(d) {
        return d === "Reserva" ? this.convertRGBHexadeciaml(106, 187, 65) :
            d === "Veda" ? this.convertRGBHexadeciaml(225, 228, 62) :
                d === "Veda, Reglamento" || d === "Veda y reglamento" ? this.convertRGBHexadeciaml(165, 72, 188) :
                    d === "Veda, Reserva" || d === "Veda y reserva" ? this.convertRGBHexadeciaml(243, 148, 218) :
                        d === "Veda, Reserva, Reglamento" || d === "Veda, reserva y reglamento" ? this.convertRGBHexadeciaml(234, 179, 71) :
                            d === "Sin ordenamiento superficial" ? this.convertRGBHexadeciaml(191, 191, 191) : this.convertRGBHexadeciaml(255, 255, 255);
    }

    getCuencasV(d) {
        return d === "No" || d === "Cuenca sin veda" ? this.convertRGBHexadeciaml(230, 229, 228) :
            d === "Si" || d === "Cuenca con veda" ? this.convertRGBHexadeciaml(251, 192, 71) : this.convertRGBHexadeciaml(255, 255, 255);
    }

    getPrecipitacionNormal(d) {
        return d === 0 || d === "0 mm" ? this.convertRGBHexadeciaml(246, 232, 195) :
            (d <= 0.5 && d > 0) || d === "0.5 mm" ? this.convertRGBHexadeciaml(245, 245, 245) :
                (d <= 10 && d > 0.5) || d === "10 mm" ? this.convertRGBHexadeciaml(196, 255, 189) :
                    (d <= 20 && d > 10) || d === "20 mm" ? this.convertRGBHexadeciaml(133, 245, 121) :
                        (d <= 30 && d > 20) || d === "30 mm" ? this.convertRGBHexadeciaml(54, 172, 0) :
                            (d <= 50 && d > 30) || d === "50 mm" ? this.convertRGBHexadeciaml(0, 125, 77) :
                                (d <= 100 && d > 50) || d === "100 mm" ? this.convertRGBHexadeciaml(1, 82, 50) :
                                    (d <= 200 && d > 100) || d === "200 mm" ? this.convertRGBHexadeciaml(163, 255, 252) :
                                        (d <= 300 && d > 200) || d === "300 mm" ? this.convertRGBHexadeciaml(5, 195, 253) :
                                            (d <= 400 && d > 300) || d === "400 mm" ? this.convertRGBHexadeciaml(0, 120, 255) :
                                                (d <= 500 && d > 400) || d === "500 mm" ? this.convertRGBHexadeciaml(0, 77, 167) :
                                                    (d <= 600 && d > 500) || d === "600 mm" ? this.convertRGBHexadeciaml(8, 48, 107) :
                                                        (d <= 800 && d > 600) || d === "800 mm" ? this.convertRGBHexadeciaml(255, 255, 0) :
                                                            (d <= 1000 && d > 800) || d === "1000 mm" ? this.convertRGBHexadeciaml(255, 170, 1) :
                                                                (d <= 1200 && d > 1000) || d === "1200 mm" ? this.convertRGBHexadeciaml(255, 69, 0) :
                                                                    (d <= 1400 && d > 1200) || d === "1400 mm" ? this.convertRGBHexadeciaml(254, 0, 0) :
                                                                        (d <= 1600 && d > 1400) || d === "1600 mm" ? this.convertRGBHexadeciaml(139, 1, 1) :
                                                                            (d <= 2000 && d > 1600) || d === "2000 mm" ? this.convertRGBHexadeciaml(255, 20, 148) :
                                                                                (d <= 2500 && d > 2000) || d === "2500 mm" ? this.convertRGBHexadeciaml(205, 16, 118) :
                                                                                    (d <= 3000 && d > 2500) || d === "3000 mm" ? this.convertRGBHexadeciaml(140, 10, 80) :
                                                                                        (d <= 3500 && d > 3000) || d === "3500 mm" ? this.convertRGBHexadeciaml(255, 102, 255) :
                                                                                            (d <= 4000 && d > 3500) || d === "4000 mm" ? this.convertRGBHexadeciaml(180, 82, 205) :
                                                                                                (d > 4000) || d === ">4000 mm" ? this.convertRGBHexadeciaml(123, 55, 140) : this.convertRGBHexadeciaml(255, 255, 255);
    }

    getPrecipitacionAnual(d) {
        return d === 0 || d === "0 mm" ? this.convertRGBHexadeciaml(246, 232, 195) :
            (d <= 0.5 && d > 0) || d === "0.5 mm" ? this.convertRGBHexadeciaml(245, 245, 245) :
                (d <= 10 && d > 0.5) || d === "10 mm" ? this.convertRGBHexadeciaml(196, 255, 189) :
                    (d <= 20 && d > 10) || d === "20 mm" ? this.convertRGBHexadeciaml(133, 245, 121) :
                        (d <= 30 && d > 20) || d === "30 mm" ? this.convertRGBHexadeciaml(54, 172, 0) :
                            (d <= 50 && d > 30) || d === "50 mm" ? this.convertRGBHexadeciaml(0, 125, 77) :
                                (d <= 100 && d > 50) || d === "100 mm" ? this.convertRGBHexadeciaml(1, 82, 50) :
                                    (d <= 200 && d > 100) || d === "200 mm" ? this.convertRGBHexadeciaml(163, 255, 252) :
                                        (d <= 300 && d > 200) || d === "300 mm" ? this.convertRGBHexadeciaml(5, 195, 253) :
                                            (d <= 400 && d > 300) || d === "400 mm" ? this.convertRGBHexadeciaml(0, 120, 255) :
                                                (d <= 500 && d > 400) || d === "500 mm" ? this.convertRGBHexadeciaml(0, 77, 167) :
                                                    (d <= 600 && d > 500) || d === "600 mm" ? this.convertRGBHexadeciaml(8, 48, 107) :
                                                        (d <= 800 && d > 600) || d === "800 mm" ? this.convertRGBHexadeciaml(255, 255, 0) :
                                                            (d <= 1000 && d > 800) || d === "1000 mm" ? this.convertRGBHexadeciaml(255, 170, 1) :
                                                                (d <= 1200 && d > 1000) || d === "1200 mm" ? this.convertRGBHexadeciaml(255, 69, 0) :
                                                                    (d <= 1400 && d > 1200) || d === "1400 mm" ? this.convertRGBHexadeciaml(254, 0, 0) :
                                                                        (d <= 1600 && d > 1400) || d === "1600 mm" ? this.convertRGBHexadeciaml(139, 1, 1) :
                                                                            (d <= 2000 && d > 1600) || d === "2000 mm" ? this.convertRGBHexadeciaml(255, 20, 148) :
                                                                                (d <= 2500 && d > 2000) || d === "2500 mm" ? this.convertRGBHexadeciaml(205, 16, 118) :
                                                                                    (d <= 3000 && d > 2500) || d === "3000 mm" ? this.convertRGBHexadeciaml(140, 10, 80) :
                                                                                        (d <= 3500 && d > 3000) || d === "3500 mm" ? this.convertRGBHexadeciaml(255, 102, 255) :
                                                                                            (d <= 4000 && d > 3500) || d === "4000 mm" ? this.convertRGBHexadeciaml(180, 82, 205) :
                                                                                                (d > 4000) || d === ">4000 mm" ? this.convertRGBHexadeciaml(123, 55, 140) : this.convertRGBHexadeciaml(255, 255, 255);
    }

    getCensoNacional(d) {
        return d > 4000000 || d === "Población mayor a 4,000,000" ? this.convertRGBHexadeciaml(63, 0, 125) :
            (d > 2700000 && d <= 4000000) || d === "Población entre 4,000,000 y 2,700,000" ? this.convertRGBHexadeciaml(106, 81, 163) :
                (d > 1900000 && d <= 2700000) || d === "Población entre 2,700,000 y 1,900,000" ? this.convertRGBHexadeciaml(163, 159, 203) :
                    d <= 1900000 || d === "Población menor a 1,900,000" ? this.convertRGBHexadeciaml(220, 219, 236) : this.convertRGBHexadeciaml(252, 251, 253);
    }
    getCensoMun(d) {
        return d > 40000 || d === "Población mayor a 40,000" ? this.convertRGBHexadeciaml(255, 234, 70) :
            (d > 15000 && d <= 40000) || d === "Población entre 40,000 y 15,000" ? this.convertRGBHexadeciaml(190, 175, 111) :
                (d > 5000 && d <= 15000) || d === "Población entre 15,000 y 5,000" ? this.convertRGBHexadeciaml(125, 124, 120) :
                    d <= 5000 || d === "Población menor a 5,000" ? this.convertRGBHexadeciaml(65, 77, 107) : this.convertRGBHexadeciaml(0, 32, 77);
    }
    getCensoLoc(d) {
        return d > 2500 || d === "Localidad Urbana" ? this.convertRGBHexadeciaml(230, 85, 13) :
            d <= 2500 || d === "Localidad Rural" ? this.convertRGBHexadeciaml(253, 174, 107) : this.convertRGBHexadeciaml(0, 32, 77);
    }

    recaudacionderechos(d) {
        //console.log(d)
        return (d > 500 || d === "mayor a 500 mdp") ? this.convertRGBHexadeciaml(255, 0, 0) :
            (d > 200 && d <= 500) || d === "200-500 mdp" ? this.convertRGBHexadeciaml(255, 64, 64) :
                (d > 150 && d <= 200) || d === "150-200 mdp" ? this.convertRGBHexadeciaml(255, 128, 128) :
                    (d > 70 && d <= 150) || d === "70-150 mdp" ? this.convertRGBHexadeciaml(255, 191, 191) :
                        (d <= 70 || d === "menor a 70 mdp") ? this.convertRGBHexadeciaml(255, 255, 255) : this.convertRGBHexadeciaml(0, 32, 77);
    }

    recaudacionuso(d) {
        return d > 2300 || d === "mayor a 2300 mdp" ? this.convertRGBHexadeciaml(179, 0, 0) :
            (d > 1300 && d <= 2300) || d === "1300-2300 mdp" ? this.convertRGBHexadeciaml(227, 74, 51) :
                (d > 400 && d <= 1300) || d === "400-1300 mdp" ? this.convertRGBHexadeciaml(252, 141, 89) :
                    (d > 160 && d <= 400) || d === "160-400 mdp" ? this.convertRGBHexadeciaml(253, 204, 138) :
                        d <= 160 || d === "menor a 160 mdp" ? this.convertRGBHexadeciaml(254, 240, 217) : this.convertRGBHexadeciaml(0, 32, 77);
    }

    getUsosAR(d) {
        return d > 1.7 || d === "Mayores a 1.7 hm³" ? this.convertRGBHexadeciaml(153, 52, 4) :
            (d > 0.370 && d <= 1.7) || d === "Entre 0.370 hm³ y 1.7 hm³" ? this.convertRGBHexadeciaml(179, 103, 56) :
                (d > 0.115 && d <= 0.370) || d === "Entre 0.115 hm³ y 0.370 hm³" ? this.convertRGBHexadeciaml(204, 154, 108) :
                    (d > 0.027 && d <= 0.115) || d === "Entre 0.027 hm³ y 0.115 hm³" ? this.convertRGBHexadeciaml(230, 204, 160) :
                        d <= 0.027 || d === "Menores a 0.027 hm³" ? this.convertRGBHexadeciaml(255, 255, 212) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getUsosSB(d) {
        return d > 16.5 || d === "Mayores a 16.5 hm³" ? this.convertRGBHexadeciaml(8, 81, 156) :
            (d > 5.0 && d <= 16.5) || d === "Entre 5.0 hm³ y 16.5 hm³" ? this.convertRGBHexadeciaml(66, 122, 181) :
                (d > 1.5 && d <= 5.0) || d === "Entre 1.5 hm³ y 5.0 hm³" ? this.convertRGBHexadeciaml(124, 162, 206) :
                    (d > 0.27 && d <= 1.5) || d === "Entre 0.27 hm³ y 1.5 hm³" ? this.convertRGBHexadeciaml(181, 203, 230) :
                        d <= 0.27 || d === "Menores a 0.27 hm³" ? this.convertRGBHexadeciaml(239, 243, 255) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getUsosSP(d) {
        return d > 9.0 || d === "Mayores a 9.0 hm³" ? this.convertRGBHexadeciaml(0, 109, 44) :
            (d > 2.5 && d <= 9.0) || d === "Entre 2.5 hm³ y 9.0 hm³" ? this.convertRGBHexadeciaml(59, 144, 91) :
                (d > 0.76 && d <= 2.5) || d === "Entre 0.76 hm³ y 2.5 hm³" ? this.convertRGBHexadeciaml(119, 179, 139) :
                    (d > 0.18 && d <= 0.76) || d === "Entre 0.18 hm³ y 0.76 hm³" ? this.convertRGBHexadeciaml(178, 213, 186) :
                        d <= 10.18 || d === "Menores a 0.18 hm³" ? this.convertRGBHexadeciaml(237, 248, 233) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getUsosZF(d) {
        return d > 350000 || d === "Mayores a 350,000 m²" ? this.convertRGBHexadeciaml(84, 39, 143) :
            (d > 32000 && d <= 350000) || d === "Entre 32,000 m² y 350,000 m²" ? this.convertRGBHexadeciaml(123, 89, 169) :
                (d > 2500 && d <= 32000) || d === "Entre 2,500 m² y 32,000 m²" ? this.convertRGBHexadeciaml(163, 140, 195) :
                    (d > 350 && d <= 2500) || d === "Entre 350 m² y 2,500 m²" ? this.convertRGBHexadeciaml(203, 190, 221) :
                        d <= 350 || d === "Menores a 350 m²" ? this.convertRGBHexadeciaml(242, 240, 247) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getConcesionesAR(d) {
        return d > 1.7 || d === "Mayores a 1.7 hm³" ? this.convertRGBHexadeciaml(153, 52, 4) :
            (d > 0.37 && d <= 1.7) || d === "Entre 0.37 hm³ y 1.7hm³" ? this.convertRGBHexadeciaml(179, 103, 56) :
                (d > 0.12 && d <= 0.37) || d === "Entre 0.12 hm³ y 0.37 hm³" ? this.convertRGBHexadeciaml(204, 154, 108) :
                    (d > 0.03 && d <= 0.12) || d === "Entre 0.03 hm³ y 0.12 hm³" ? this.convertRGBHexadeciaml(230, 204, 160) :
                        d <= 0.03 || d === "Menores a 0.03 hm³" ? this.convertRGBHexadeciaml(255, 255, 212) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getConcesionesSB(d) {
        return d > 17.0 || d === "Mayores a 17.0 hm³" ? this.convertRGBHexadeciaml(8, 81, 156) :
            (d > 5.0 && d <= 17.0) || d === "Entre 5.0 hm³ y 17.0 hm³" ? this.convertRGBHexadeciaml(66, 122, 181) :
                (d > 1.5 && d <= 5.0) || d === "Entre 1.5 hm³ y 5.0 hm³" ? this.convertRGBHexadeciaml(124, 162, 206) :
                    (d > 0.27 && d <= 1.5) || d === "Entre 0.27 hm³ y 1.5 hm³" ? this.convertRGBHexadeciaml(181, 203, 230) :
                        d <= 0.27 || d === "Menores a 0.27 hm³" ? this.convertRGBHexadeciaml(239, 243, 255) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getConcesionesSP(d) {
        return d > 9.0 || d === "Mayores a 9.0 hm³" ? this.convertRGBHexadeciaml(0, 109, 44) :
            (d > 2.5 && d <= 9.0) || d === "Entre 2.5 hm³ y 9.0 hm³" ? this.convertRGBHexadeciaml(59, 144, 91) :
                (d > 0.76 && d <= 2.5) || d === "Entre 0.76 hm³ y 2.5 hm³" ? this.convertRGBHexadeciaml(119, 179, 139) :
                    (d > 0.18 && d <= 0.76) || d === "Entre 0.18 hm³ y 0.76 hm³" ? this.convertRGBHexadeciaml(178, 213, 186) :
                        d <= 0.18 || d === "Menores a 0.18 hm³" ? this.convertRGBHexadeciaml(237, 248, 233) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getConcesionesZF(d) {
        return d > 350000 || d === "Mayores a 350,000 m²" ? this.convertRGBHexadeciaml(84, 39, 143) :
            (d > 32000 && d <= 350000) || d === "Entre 32,000 m² y 350,000 m²" ? this.convertRGBHexadeciaml(123, 89, 169) :
                (d > 2500 && d <= 32000) || d === "Entre 2,500 m² y 32,000 m²" ? this.convertRGBHexadeciaml(163, 140, 195) :
                    (d > 350 && d <= 2500) || d === "Entre 350 m² y 2,500 m²" ? this.convertRGBHexadeciaml(203, 190, 221) :
                        d <= 350 || d === "Menores a 350 m²" ? this.convertRGBHexadeciaml(242, 240, 247) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getAprovechamientosAR(d) {
        return d >= 1000000 || d === "Mayores a 1,000,000 m³" ? this.convertRGBHexadeciaml(128, 0, 0) :
            (d < 1000000 && d > 250000) || d === "Entre 250,000 m³ y 1,000,000 m³" ? this.convertRGBHexadeciaml(179, 74, 35) :
                d <= 250000 || d === "Menores a 250,000 m³" ? this.convertRGBHexadeciaml(231, 148, 71) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getAprovechamientosSB(d) {
        return d >= 1000000 || d === "Mayores a 1,000,000 m³" ? this.convertRGBHexadeciaml(11, 35, 244) :
            (d < 1000000 && d > 250000) || d === "Entre 250,000 m³ y 1,000,000 m³" ? this.convertRGBHexadeciaml(117, 140, 237) :
                d <= 250000 || d === "Menores a 250,000 m³" ? this.convertRGBHexadeciaml(222, 245, 229) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getAprovechamientosSP(d) {
        return d >= 1000000 || d === "Mayores a 1,000,000 m³" ? this.convertRGBHexadeciaml(15, 255, 11) :
            (d < 1000000 && d > 250000) || d === "Entre 250,000 m³ y 1,000,000 m³" ? this.convertRGBHexadeciaml(83, 200, 62) :
                d <= 250000 || d === "Menores a 250,000 m³" ? this.convertRGBHexadeciaml(178, 223, 138) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getAprovechamientosZF(d) {
        return d >= 100 || d === "Mayores a 100 m²" ? this.convertRGBHexadeciaml(84, 39, 143) :
            (d < 100 && d > 10) || d === "Entre 10 m² y 100 m²" ? this.convertRGBHexadeciaml(163, 140, 195) :
                d <= 10 || d === "Menores a 10 m²" ? this.convertRGBHexadeciaml(242, 240, 247) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getTipoCultivo(tipo, d) {
        //console.log(tipo)
        if (tipo === "Sorgo") {
            return d > 100000 || d === "Mayores a 100000 ha" ? this.convertRGBHexadeciaml(214, 72, 1) :
                (d > 10000 && d <= 100000) || d === "Entre 10000 y 100000 ha" ? this.convertRGBHexadeciaml(241, 105, 19) :
                    (d > 1000 && d <= 10000) || d === "Entre 1000 y 10000 ha" ? this.convertRGBHexadeciaml(253, 174, 107) :
                        (d > 100 && d <= 1000) || d === "Entre 100 y 1000 ha" ? this.convertRGBHexadeciaml(254, 230, 206) :
                            d <= 100 || d === "Menores a 100 ha" ? this.convertRGBHexadeciaml(255, 245, 235) : this.convertRGBHexadeciaml(255, 245, 235);
        }
        if (tipo === "Arróz") {
            return d > 5000 || d === "Mayores a 5000 ha" ? this.convertRGBHexadeciaml(116, 189, 219) :
                (d > 1000 && d <= 5000) || d === "Entre 1000 y 5000 ha" ? this.convertRGBHexadeciaml(166, 189, 219) :
                    (d > 500 && d <= 1000) || d === "Entre 500 y 1000 ha" ? this.convertRGBHexadeciaml(208, 209, 230) :
                        d <= 500 || d === "Menores a 500 ha" ? this.convertRGBHexadeciaml(236, 226, 240) : this.convertRGBHexadeciaml(247, 247, 247);
        }
        if (tipo === "Trigo") {
            return d > 100000 || d === "Mayores a 100000 Miles de ha" ? this.convertRGBHexadeciaml(173, 221, 142) :
                (d > 50000 && d <= 100000) || d === "Entre 50000 y 100000 ha" ? this.convertRGBHexadeciaml(217, 240, 163) :
                    (d > 10000 && d <= 50000) || d === "Entre 10000 y 50000 ha" ? this.convertRGBHexadeciaml(237, 248, 117) :
                        (d > 1000 && d <= 10000) || d === "Entre 1000 y 10000 ha" ? this.convertRGBHexadeciaml(247, 252, 185) :
                            d <= 1000 || d === "Menores a 1000 ha" ? this.convertRGBHexadeciaml(255, 255, 229) : this.convertRGBHexadeciaml(247, 247, 247);
        }
        if (tipo === "Maíz") {
            return d > 400000 || d === "Mayores a 400000 ha" ? this.convertRGBHexadeciaml(255, 255, 51) :
                (d > 100000 && d <= 400000) || d === "Entre 100000 y 400000 ha" ? this.convertRGBHexadeciaml(246, 232, 195) :
                    (d > 50000 && d <= 100000) || d === "Entre 50000 y 100000 ha" ? this.convertRGBHexadeciaml(255, 247, 188) :
                        (d > 10000 && d <= 50000) || d === "Entre 10000 y 50000 ha" ? this.convertRGBHexadeciaml(237, 248, 177) :
                            d <= 10000 || d === "Menores a 10000 ha" ? this.convertRGBHexadeciaml(255, 255, 217) : this.convertRGBHexadeciaml(247, 247, 247);
        }
        if (tipo === "Frijol") {
            return d > 10000 || d === "Mayores a 10000 Miles de ha" ? this.convertRGBHexadeciaml(140, 81, 10) :
                (d > 1000 && d <= 10000) || d === "Entre 1000 y 10000 ha" ? this.convertRGBHexadeciaml(191, 129, 45) :
                    (d > 200 && d <= 1000) || d === "Entre 200 y 1000 ha" ? this.convertRGBHexadeciaml(223, 194, 125) :
                        (d > 100 && d <= 200) || d === "Entre 100 y 200 ha" ? this.convertRGBHexadeciaml(246, 232, 195) :
                            d <= 100 || d === "Menores a 100 ha" ? this.convertRGBHexadeciaml(245, 245, 245) : this.convertRGBHexadeciaml(247, 247, 247);
        }

    }
    getTipoPriductividad(tipo, d) {
        //console.log(tipo)
        if (tipo === "Sorgo") {
            return d > 10.1 || d === "Mayores a 10.1 (ton/ha)" ? this.convertRGBHexadeciaml(241, 105, 19) :
                (d > 8.3 && d <= 10.1) || d === "Entre 8.3 y 10.1 (ton/ha)" ? this.convertRGBHexadeciaml(253, 141, 60) :
                    (d > 6.5 && d <= 8.3) || d === "Entre 6.5 y 8.3 (ton/ha)" ? this.convertRGBHexadeciaml(253, 174, 107) :
                        (d > 4.7 && d <= 6.5) || d === "Entre 4.7 y 6.5 (ton/ha)" ? this.convertRGBHexadeciaml(253, 208, 162) :
                            d <= 4.7 || d === "Menores a 4.7 (ton/ha)" ? this.convertRGBHexadeciaml(254, 230, 206) : this.convertRGBHexadeciaml(247, 247, 247);
        }
        if (tipo === "Frijol") {
            return d > 3.0 || d === "Mayores a 3.0 (ton/ha)" ? this.convertRGBHexadeciaml(140, 81, 10) :
                (d > 2.5 && d <= 3.0) || d === "Entre 2.5 y 3.0 (ton/ha)" ? this.convertRGBHexadeciaml(191, 129, 45) :
                    (d > 1.95 && d <= 2.5) || d === "Entre 1.95 y 2.5 (ton/ha)" ? this.convertRGBHexadeciaml(223, 194, 125) :
                        (d > 1.45 && d <= 1.95) || d === "Entre 1.45 y 1.95 (ton/ha)" ? this.convertRGBHexadeciaml(246, 232, 195) :
                            d <= 1.45 || d === "Menores a 1.45 (ton/ha)" ? this.convertRGBHexadeciaml(245, 245, 245) : this.convertRGBHexadeciaml(247, 247, 247);
        }
        if (tipo === "Maíz") {
            return d > 10.0 || d === "Mayores a 10.0 (ton/ha)" ? this.convertRGBHexadeciaml(255, 255, 51) :
                (d > 7.0 && d <= 10.0) || d === "Entre 7.0 y 10.0 (ton/ha)" ? this.convertRGBHexadeciaml(246, 232, 195) :
                    (d > 4.0 && d <= 7.0) || d === "Entre 4.0 y 10.0 (ton/ha)" ? this.convertRGBHexadeciaml(255, 247, 188) :
                        d <= 4.0 || d === "Menores a 4.0 (ton/ha)" ? this.convertRGBHexadeciaml(237, 248, 177) : this.convertRGBHexadeciaml(247, 247, 247);
        }
        if (tipo === "Arróz") {
            return d > 11.0 || d === "Mayores a 11.0 (ton/ha)" ? this.convertRGBHexadeciaml(116, 189, 219) :
                (d > 9.0 && d <= 11.0) || d === "Entre  9.0 y 11.0 (ton/ha)" ? this.convertRGBHexadeciaml(166, 189, 219) :
                    (d > 7.0 && d <= 9.0) || d === "Entre 7.0 y 9.0 (ton/ha)" ? this.convertRGBHexadeciaml(208, 209, 230) :
                        d <= 5.0 || d === "Menores a 5.0 (ton/ha)" ? this.convertRGBHexadeciaml(236, 226, 240) : this.convertRGBHexadeciaml(247, 247, 247);
        }
        if (tipo === "Trigo") {
            return d > 5.7 || d === "Mayores a 5.7 (ton/ha)" ? this.convertRGBHexadeciaml(173, 221, 142) :
                (d > 4.7 && d <= 5.7) || d === "Entre 4.7 y 5.7 (ton/ha)" ? this.convertRGBHexadeciaml(217, 240, 163) :
                    (d > 3.8 && d <= 4.7) || d === "Entre 3.8 y 4.7 (ton/ha)" ? this.convertRGBHexadeciaml(237, 248, 117) :
                        (d > 2.8 && d <= 3.8) || d === "Entre 2.8 y 3.8 (ton/ha)" ? this.convertRGBHexadeciaml(247, 252, 185) :
                            d <= 2.8 || d === "Menores a 2.8 (ton/ha)" ? this.convertRGBHexadeciaml(255, 255, 229) : this.convertRGBHexadeciaml(247, 247, 247);
        }

    }
    getSupRegada(d) {
        return d > 371917 || d === "Mayores a 5.7 ha" ? this.convertRGBHexadeciaml(8, 48, 107) :
            (d > 190053 && d <= 371917) || d === "Entre 190053 y 371917 ha" ? this.convertRGBHexadeciaml(41, 121, 185) :
                (d > 82946 && d <= 190053) || d === "Entre 82946 y 190053 ha" ? this.convertRGBHexadeciaml(115, 178, 216) :
                    (d > 18415 && d <= 82946) || d === "Entre 18415 y 82946 ha" ? this.convertRGBHexadeciaml(200, 220, 240) :
                        d <= 18415 || d === "Menores a 18415 ha" ? this.convertRGBHexadeciaml(247, 251, 255) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getOtroP(d) {
        return d <= 0.24 ? this.convertRGBHexadeciaml(250, 235, 221) :
            (d > 0.24 && d <= 0.76) ? this.convertRGBHexadeciaml(245, 136, 96) :
                (d > 0.76 && d <= 1.87) ? this.convertRGBHexadeciaml(203, 27, 79) :
                    (d > 1.87 && d <= 4.97) ? this.convertRGBHexadeciaml(97, 31, 83) : this.convertRGBHexadeciaml(211, 84, 0);
    }
    getSupSemb(d) {
        return d > 50000 || d === "Mayores a 50,000 ha" ? this.convertRGBHexadeciaml(0, 68, 27) :
            (d > 19000 && d <= 50000) || d === "Entre 19,000  y 50,000 ha" ? this.convertRGBHexadeciaml(34, 117, 60) :
                (d > 10000 && d <= 19000) || d === "Entre 10,000  y 19,000 ha" ? this.convertRGBHexadeciaml(69, 159, 69) :
                    (d > 4500 && d <= 10000) || d === "Entre 4,500  y 10,000 ha" ? this.convertRGBHexadeciaml(129, 205, 113) :
                        d <= 4500 || d === "Menores a 4,500 ha" ? this.convertRGBHexadeciaml(191, 253, 164) : this.convertRGBHexadeciaml(247, 247, 247);
    }
    getValorProd(d) {
        return d > 50000 || d === "Mayores a 50000 (miles de pesos)" ? this.convertRGBHexadeciaml(194, 82, 60) :
            (d > 10000 && d <= 50000) || d === "Entre 10000  y 50000 (miles de pesos)" ? this.convertRGBHexadeciaml(247, 215, 8) :
                (d > 5000 && d <= 10000) || d === "Entre 5000  y 10000 (miles de pesos)" ? this.convertRGBHexadeciaml(16, 195, 67) :
                    d <= 5000 || d === "Menores a 5000 (miles de pesos)" ? this.convertRGBHexadeciaml(11, 44, 123) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getSupRegada2(d) {
        return d > 26325 || d === "Mayores a 26325 (Ha)" ? this.convertRGBHexadeciaml(106, 0, 0) :
            (d > 7448 && d <= 26325) || d === "Entre 7448  y 26325 (Ha)" ? this.convertRGBHexadeciaml(173, 83, 20) :
                (d > 2998 && d <= 7448) || d === "Entre 2998  y 7448 (Ha)" ? this.convertRGBHexadeciaml(242, 168, 47) :
                    (d > 1623 && d <= 2998) || d === "Entre 1623  y 2998 (Ha)" ? this.convertRGBHexadeciaml(250, 209, 85) :
                        d <= 1623 || d === "Menores a 1623 (Ha)" ? this.convertRGBHexadeciaml(255, 255, 129) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getVoldist(d) {
        return d > 66619 || d === "Mayores a 66619 (miles de m³)" ? this.convertRGBHexadeciaml(245, 0, 245) :
            (d > 50000 && d <= 66619) || d === "Entre 50000  y 66619 (miles de m³)" ? this.convertRGBHexadeciaml(148, 0, 246) :
                (d > 25000 && d <= 50000) || d === "Entre 25000  y 50000 (miles de m³)" ? this.convertRGBHexadeciaml(0, 0, 244) :
                    (d > 10000 && d <= 25000) || d === "Entre 10000  y 25000 (miles de m³)" ? this.convertRGBHexadeciaml(57, 127, 248) :
                        d <= 10000 || d === "Menores a 10000 (miles de m³)" ? this.convertRGBHexadeciaml(0, 246, 244) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getHidrom(d) {
        return d === "Estacion Automatica (Pcd)" ? this.convertRGBHexadeciaml(123, 163, 160) :
            d === "Estacion Convencional" ? this.convertRGBHexadeciaml(246, 119, 19) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getTrayectorias(d) {
        return d === 2015 || d === "2015" ? this.convertRGBHexadeciaml(0, 0, 0) :
            d === 2016 || d === "2016" ? this.convertRGBHexadeciaml(0, 232, 0) :
                d === 2017 || d === "2017" ? this.convertRGBHexadeciaml(0, 0, 255) :
                    d === 2018 || d === "2018" ? this.convertRGBHexadeciaml(128, 122, 238) :
                        d === 2019 || d === "2019" ? this.convertRGBHexadeciaml(226, 26, 176) :
                            d === 2020 || d === "2020" ? this.convertRGBHexadeciaml(246, 0, 0) :
                                d === 2021 || d === "2021" ? this.convertRGBHexadeciaml(251, 154, 153) :
                                    d === 2022 || d === "2022" ? this.convertRGBHexadeciaml(194, 155, 97) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getAreasCaniba(d) {
        return d === 350 || d === "350" ? this.convertRGBHexadeciaml(11, 142, 114) :
            (d > 350 && d <= 700) || d === "350-700" ? this.convertRGBHexadeciaml(255, 233, 35) :
                (d > 700 && d <= 1100) || d === "700-1100" ? this.convertRGBHexadeciaml(255, 143, 24) :
                    d <= 1500 || d === "1100-1500" ? this.convertRGBHexadeciaml(255, 7, 36) : this.convertRGBHexadeciaml(247, 247, 247);
    }

    getDistancias(d) {
        return ((d > 0.12 && d < 3.64) || d === "0.12-3.64") ? this.convertRGBHexadeciaml(215,25,28) :
            ((d >= 3.65 && d < 4.57) || d === "3.65-4.57") ? this.convertRGBHexadeciaml(253,174,97) :
                ((d >= 4.58 && d < 5.69) || d === "4.58-5.69") ? this.convertRGBHexadeciaml(255,255,191) :
                    ((d >= 5.70 && d < 7.42) || d === "5.70-7.42") ? this.convertRGBHexadeciaml(171,221,164) :
                        this.convertRGBHexadeciaml(43,131,186);
    }



    getModernizacion(d) {

        return d === 2021 ? this.convertRGBHexadeciaml(28, 144, 153) :
            d === 2020 ? this.convertRGBHexadeciaml(49, 163, 84) :
                d === 2019 && this.convertRGBHexadeciaml(161, 217, 155);
    }

    convertRGBHexadeciaml(red, green, blue) {
        //console.log(red+"-"+green+"-"+blue);
        //console.log("#"+colorToHex(red)+ colorToHex(green)+colorToHex(blue))
        return "#" + this.colorToHex(red) + this.colorToHex(green) + this.colorToHex(blue);
    }

    colorToHex(color) {
        var hexadecimal = color.toString(16);
        //console.log(hexadecimal .length == 1 ? "0" +hexadecimal : hexadecimal)
        return hexadecimal.length === 1 ? "0" + hexadecimal : hexadecimal;
    }



}



export default Simbologia;