import React, { useState } from 'react'
import './registro.css'
import Header from "../../componentes/Header";
import Cookie from 'universal-cookie';
import md5 from 'md5';
import Form from 'react-bootstrap/Form';


const Registro = () => {
    const colores = ["74d2e7", "48a9c5", "0085ad", "8db9ca", "4298b5", "005670", "00205b", "009f4d", "84bd00", "efdf00", "fe5000", "e4002b", "da1884", "da1884", "a51890", "0077c8", "008eaa"];

    const cookie = new Cookie();
    const [imagenA, setImagenA] = useState("imgs/usuario.png")
    let avatar;

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }

    function generarAvatar() {
        let nombre = document.getElementById("nombres").value
        let paterno = document.getElementById("paterno").value
        avatar = `https://ui-avatars.com/api/?name=${nombre}+${paterno}&background=${colores[getRandomInt(0, 16)]}&color=fff&size=512`
        //console.log(avatar)

        setImagenA(avatar)
    }

    function registrarUsuario() {
        let user = {}

        let plan = document.getElementsByName('drone');
        //console.log(plan[0].checked);

        if (plan[0].checked) {
            user.plan = "estandar"
        }
        if (plan[1].checked) {
            user.plan = "avanzado"
        }
        if (plan[2].checked) {
            user.plan = "plus"
        }



        //console.log("Registrar Usuario")
        user.nombre = document.getElementById("nombres").value.toUpperCase()
        user.paterno = document.getElementById("paterno").value.toUpperCase()
        user.materno = document.getElementById("materno").value.toUpperCase()
        user.email = document.getElementById("correo").value
        user.empresa = document.getElementById("empresa").value.toUpperCase()

        user.avatar = imagenA;
        //user.tamanio = document.getElementById("tamanio").value
        //user.pais = document.getElementById("pais").value
        user.pasword = md5(document.getElementById("password").value);

        //console.log(user)
        cookie.set('usuario', user, { path: "/" });

        if (plan[0].checked) {
            var anchor = document.createElement('a');
            anchor.href = "#/PEstandar";
            anchor.click();
        }
        if (plan[1].checked) {
            var anchor1 = document.createElement('a');
            anchor1.href = "#/PAvanzado";
            anchor1.click();
        }
        if (plan[2].checked) {
            var anchor2 = document.createElement('a');
            anchor2.href = "#/PPlus";
            anchor2.click();
        }
        //var anchor = document.createElement('a');
        //anchor.href = "#/Pago";
        //anchor.click();

    }

    return (
        <div className="container-fluid">
            <Header></Header>
            <br />
            <section className="vh-90 seccion">
                <div className="container-fluid h-custom">
                    <div className="row d-flex justify-content-center align-items-center h-100">

                        <div className="col-12 col-md-12 col-lg-3 col-xl-3">
                            <form>
                                <div className="form-outline mb-4">
                                    <input type="text" id="nombres" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="nombres">Nombre(s)</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <input type="text" id="paterno" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="paterno">Apellido Paterno</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <input type="text" id="materno" className="form-control form-control-lg"
                                        placeholder="" onFocus={generarAvatar} />
                                    <label className="form-label" for="materno">Apellido Materno</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <input type="email" id="correo" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="correo">Correo electrónico</label>
                                </div>
                                <div className="form-outline mb-4">
                                    <input type="password" id="password" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="password">Contraseña</label>
                                </div>

                                <div className="form-outline mb-4">
                                    <input type="text" id="empresa" className="form-control form-control-lg"
                                        placeholder="" />
                                    <label className="form-label" for="empresa">Nombre de la Empresa</label>
                                </div>

                                <div className="text-center text-lg-start mt-4 pt-2">
                                    <button type="button" className="btn btn-primary btn-lg estilo" onClick={registrarUsuario}>Registrarse</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-12 col-md-12 col-lg-8 col-xl-8 offset-xl-1">
                            <form>
                                <div class="row">
                                    <div data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000" data-aos-once="true" class="col-md-4 text-center py-4 mt-5 precios">
                                        <input type="radio" id="estandar" name="drone" value="estandar"  class="radioB"/>
                                        <label for="estandar">
                                            <h4 class="my-4">GEOMARK ESTANDAR</h4>
                                            <p class="font-weight-bold">$ <span class="display-2 font-weight-bold">10</span> / MO.</p>
                                            <ul class="list-unstyled">
                                                <li>Consulta de información de indicadores sociales,
                                                    económicos actualizados.</li>
                                                <li>Identificación de competencia.</li>
                                                <li>Identificación de clientes potenciales.</li>
                                                <li>Segmentación de acuerdo a factores demográficos.</li>
                                                <li>Tablero de control (dashboard)</li>
                                                <li>2 usuarios</li>
                                            </ul>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            
                                        </label>
                                    </div>
                                    <div data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" class="col-md-4 text-center py-4 mt-5 rounded" id="price-table__premium">
                                        <input type="radio" id="avanzado" name="drone" value="avanzado" checked />
                                        <label for="avanzado">
                                            <h4 class="my-4">GEOMARK AVANZADO</h4>
                                            <p class="font-weight-bold">$ <span class="display-2 font-weight-bold">30</span> / MO.</p>
                                            <ul class="list-unstyled">
                                                <li>Consulta de información de indicadores sociales,económicos actualizados</li>
                                                <li>Identificación de competencia.</li>
                                                <li>Identificacioón de clientes potenciales.</li>
                                                <li>Segmentación de acuerdo a factores demográficos</li>
                                                <li>Tablero de control (dashboard)</li>
                                                <li>Visualización de zonas de venta (localidad, unidad de análisis más pequeña)</li>
                                                <li>Zonas de influencia</li>
                                                <li>Asesoría</li>
                                                <li>4 usuarios</li>
                                            </ul>
                                            
                                        </label>
                                    </div>
                                    <div data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000" data-aos-once="true" class="col-md-4 text-center py-4 mt-5">
                                        <input type="radio" id="plus" name="drone" value="plus"  />
                                        <label for="plus">
                                            <h4 class="my-4">GEOMARK PLUS</h4>
                                            <p class="font-weight-bold">$ <span class="display-2 font-weight-bold">60</span> / MO.</p>
                                            <ul class="list-unstyled">
                                                <li>Consulta de información de indicadores sociales, económicos actualizados.</li>
                                                <li>Identificación de competencia.</li>
                                                <li>Identificación de clientes potenciales.</li>
                                                <li>Segmentación de acuerdo a factores demográficos.</li>
                                                <li>Tablero de control (dashboard).</li>
                                                <li>Visualización de zonas de venta (municipal).</li>
                                                <li>Zonas de influencia.</li>
                                                <li>Asesoría personalizada</li>
                                                <li>6 usuarios</li>
                                            </ul>
                                            
                                        </label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Registro