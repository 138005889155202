import datosM from "../../json/datosM.json";

class PopUpCapas {

  getEstados(data, capa, titulo) {
    //console.log(data)
    //console.log(capa);
    let aux = this.buscarAlias(capa, data, titulo);

    let tit = Object.keys(aux);
    let pop = "<div class='ContenedorDiv'>"
    tit.map((item, index) => {

      if (capa === "m12_bandas" && item === "Descarga de datos diarios") {
        pop = pop + `<div class='contenidoPop'><a href='${aux[tit[index]] !== null ? aux[tit[index]] : ""}'><strong>${item}</strong> </a> </div>`;
      } else {
        if (capa === "m07_agricoladrspsembradaedoscul" && item === "Superficie Sembrada") {
          pop = pop + `<div class='contenidoPop'><strong>${item}:</strong> ${aux[tit[index]] !== null && aux[tit[index]] !== undefined ? `${this.darFormato(aux[tit[index]], tit[index])} ha` : ""} </div>`;
        } else {
          if (capa === "m07_agricoladrproductividadedoscul" && item === "Rendimiento") {
            pop = pop + `<div class='contenidoPop'><strong>${item}:</strong> ${aux[tit[index]] !== null && aux[tit[index]] !== undefined ? `${this.darFormato(aux[tit[index]], tit[index])} (ton/ha)` : ""} </div>`;
          } else {
            pop = pop + `<div class='contenidoPop'><strong>${item}:</strong> ${aux[tit[index]] !== null && aux[tit[index]] !== undefined ? this.darFormato(aux[tit[index]], tit[index]) : ""} </div>`;
          }

        }

      }
    })

    if (capa === "m06_acuiferos_disponibilidad" || capa === "m05_cuencas_disponibilidad") {
      pop = pop + `<br/><div class='contenidoPop'>Todos los volúmenes se presentan en millones de metros cúbicos (hm<sup>3</sup>) anuales  </div>`;
    }
    if (capa === "m07_agricoladrnumcultivos") {
      pop = pop + `<br/><div class='contenidoPop'>*La superficie se expresa en ha</div>`;
    }
    if (capa === "m07_supregadadportipoaprovedom") {
      pop = pop + `<br/><div class='contenidoPop'>*La superficie se expresa en ha</div>`;
    }
    if (capa === "m08_cobroderechos" || capa === "m08_recaudacionuso") {
      pop = pop + `<br/><div class='contenidoPop'>*Los montos se expresan en millones de pesos
      </div>`;
    }
    if (capa === "m05_zonaspago") {
      pop = pop + `<br/><div class='contenidoPop'>*Los montos se expresan en centavos/m³
      </div>`;
    }
    if (capa === "m16_PrecipitacionNormal" || capa === "m16_PrecipitacionAnual") {
      pop = pop + `<br/><div class='contenidoPop'>*Todos los valores mensuales y anuales se presentan en milímetros
      </div>`;
    }
    pop = pop + '</div>';
    return pop;
  }


  buscarAlias(capa, data) {
    //console.log(capa)
    let index = this.buscarIndex(capa);
    let longitud = Object.keys(datosM[index]).length;
    let proper = {};
    let datos = datosM[index];
    for (let i = 1; i < longitud; i++) {
      let f1 = datos[`campo${i}`].split("-");
      proper[f1[1]] = data[f1[0]];
    }
    return proper;
  }

  //Funcion para buscar el index de una capa
  buscarIndex(capa) {
    let index = -1;
    datosM.map((capaB, i) => {
      if (capaB.capa === capa) {
        index = i;
      }
    });
    return index;
  }

  darFormato(data, campo) {
    if (isNaN(data)) {
    } else {
      if (data - Math.floor(data) === 0) {
        if (this.omitirFormato(campo) === 0) {
          data = (new Intl.NumberFormat("en-US").format(data));//formatCurrency.format(item.properties[campo])//
        }
      } else {
        data = (new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(data));//formatCurrency.format(item.properties[campo].toFixed(2))//item.properties[campo].toFixed(2);//new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(item.properties[campo].toFixed(2));//item.properties[campo].toFixed(2);
      }
    }


    return data;

  }

  omitirFormato(campo) {
    if (campo !== 'Año' && campo !== 'id' && campo !== "Clave" && campo !== 'Clave geoestadística' && campo !== 'inicioper' && campo !== 'finper'
      && campo !== 'cveentidad' && campo !== 'clavemun' && campo !== 'claveloc' && campo !== 'clvgeo' && campo !== 'idm' && campo !== 'cvemunicipal' && campo !== 'clave_unic' && campo !== 'clave_esta' && campo !== 'clave_muni'
      && campo !== 'clave_nucl' && campo !== 'clavedo' && campo !== 'corterepda' && campo !== 'claveestado' && campo !== 'clavelocal' && campo !== 'latitud' && campo !== 'longitud' && campo !== 'idestado' && campo !== 'idmunicipio' && campo !== 'londecimal' && campo !== 'latdecimal' && campo !== 'clavecuenca' && campo !== 'ciclo' && campo!=="codigo_act") {
      return 0;
    } else {
      return 1;
    }

  }

  getModernizacion(data) {
    let popupContent =
      `<div>
    <div class="row">
        <div class="text-center">
            <strong>${data.nomoficial}</strong><br>
            ${data.nomcomun}
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center infoPop">
            Información
        </div>
        <div class="row textoInfo">
            <div><strong>Año:</strong> ${data.anio}</div>
            <div><strong>Importe contratado: </strong>${data.importe
      } MDP</div>
            <div><strong>Trabajos realizados: </strong> ${data.trabajos
      }</div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center infoPop">
                Ubicación
        </div>
        <div class="row textoInfo">
            <div><strong>Municipio: </strong>${data.municipio}</div>
            <div><strong>Entidad federativa: </strong> ${data.estado}</div>
      </div>
    </div>   
</div>` + '<div class="row text-center"> </div>';
    return popupContent;
  }


}

export default PopUpCapas;
