class Poblacion {

    getDatosLocalidad(data) {
        let final = [];

        let localidad = [];
        data.map(item => {
            if (localidad.length == 0) {
                localidad.push(item['localidad']);
            } else {
                if (!localidad.includes(item['localidad'])) {
                    localidad.push(item['localidad']);
                }
            }
        });

        localidad.map(loc => {
            let aux = {};
            aux.localidad = loc;
            //aux.total = 0
            aux.masculina = 0
            aux.femenina = 0
            data.map(item => {
                if (item.localidad === loc) {
                    // aux.total = aux.total + Number(item.pobtot);
                    aux.masculina = aux.masculina + Number(item.pobmas);
                    aux.femenina = aux.femenina + Number(item.pobfem);
                }
            });
            final.push(aux);
        })

        return final;
    }

    getDatosMunicpio(data) {
        let final = [];

        let municicpios = [];
        data.map(item => {
            if (municicpios.length == 0) {
                municicpios.push(item['municipio']);
            } else {
                if (!municicpios.includes(item['municipio'])) {
                    municicpios.push(item['municipio']);
                }
            }
        });

        municicpios.map(mun => {
            let aux = {};
            aux.municipio = mun;
            //aux.total = 0
            aux.masculina = 0
            aux.femenina = 0
            data.map(item => {
                if (item.municipio === mun) {
                    // aux.total = aux.total + Number(item.pobtot);
                    aux.masculina = aux.masculina + Number(item.pobmas);
                    aux.femenina = aux.femenina + Number(item.pobfem);
                }
            });
            final.push(aux);
        })

        return final;
    }

    getDatosEstado(data) {
        
        let final = [];

        let estados = [];
        data.map(item => {
            if (estados.length == 0) {
                estados.push(item['entidad']);
            } else {
                if (!estados.includes(item['entidad'])) {
                    estados.push(item['entidad']);
                }
            }
        });


        estados.map(ent => {
            let aux = {};
            aux.estado = ent;
            aux.total = 0
            aux.masculina = 0
            aux.femenina = 0
            data.map(item => {
                if (item.entidad === ent) {
                    aux.total = aux.total + Number(item.pobtot);
                    aux.masculina = aux.masculina + Number(item.pobmas);
                    aux.femenina = aux.femenina + Number(item.pobfem);
                }
            });
            final.push(aux);
        })

        return final;
    }

    getPorcentajes(data) {
        let aux = {}, final = []
        aux.total = 0
        aux.masculina = 0
        aux.femenina = 0
        data.map(item => {
            
            aux.masculina = aux.masculina + item.masculina;
            aux.femenina = aux.femenina + item.femenina;
        })
        aux.total = aux.masculina + aux.femenina;
        //console.log(aux);
        let aux1 = {};
        aux1.valor = "masculina";
        aux1.mas = aux.masculina;
        aux1.total = Math.round((aux.masculina / aux.total) * 100);
        final.push(aux1);
        aux1 = {}
        aux1.valor = "femenina";
        aux1.fem = aux.femenina;
        aux1.total = Math.round((aux.femenina / aux.total) * 100);
        final.push(aux1);
        return final

    }

    getReporte(data) {

        //console.log(data[0]);
        let municipios = [], zm = [];
        let info={};
        let regionalT = 0, zm_t = 0, urbanaR = 0, ruralR = 0, urbanaZ = 0, ruralZ = 0;
        // eslint-disable-next-line array-callback-return
        data.map(item => {
            if (municipios.length === 0) {
                municipios.push(`${item['cveentidad']}${item['cvemun']}`);
                if (item['cve_zm'] !== 'NULL') {
                    zm.push(item['cve_zm']);
                }
            } else {
                if (!municipios.includes(`${item['cveentidad']}${item['cvemun']}`)) {
                    municipios.push(`${item['cveentidad']}${item['cvemun']}`);
                }
                if (!zm.includes(item['cve_zm']) && item['cve_zm'] !== 'NULL') {
                    zm.push(item['cve_zm']);
                }
            }
            regionalT = regionalT + Number(item['pobtot'])
            if (item['cve_zm'] !== "NULL") {
                zm_t = zm_t + Number(item['pobtot'])
                if (item['clasificacion'] === "URBANA") {
                    urbanaZ = urbanaZ + Number(item['pobtot'])
                }
                if (item['clasificacion'] === "RURAL") {
                    ruralZ = ruralZ + Number(item['pobtot'])
                }
            }
            if (item['clasificacion'] === "URBANA") {
                urbanaR = urbanaR + Number(item['pobtot'])
            }
            if (item['clasificacion'] === "RURAL") {
                ruralR = ruralR + Number(item['pobtot'])
            }
        });

        info.municipios=municipios.length;
        info.zonas=zm.length;
        info.Tregional=regionalT;
        info.Tzona=zm_t;
        info.urbanaR=urbanaR;
        info.ruralR=ruralR;
        info.urbanaZ=urbanaZ;
        info.ruralz=ruralZ;

        return info;
    }



}

export default Poblacion;